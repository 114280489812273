import { Order, OrderWorkflowType, Workflow } from "@app/provider/order";
import { TestType } from "@app/provider/testData/types";

export const isComboOrder = (order: Order): boolean => {
  return order.items.length > 1;
};

export const isHorizonOrder = (order: Order): boolean => {
  return order.items.some((item) => item.testType === TestType.HORIZON);
};

export const isPanoramaOrder = (order: Order): boolean => {
  return order.items.some((item) => item.testType === TestType.PANORAMA);
};

export const isHorizonOrPanorama = (testType: TestType): boolean => {
  return [TestType.HORIZON, TestType.PANORAMA].includes(testType);
};

export const getTestTypeForHeap = (order: Order): string => {
  let testType = "";
  if (order) {
    if (isComboOrder(order)) {
      if (
        order.items.some((item) => item.testType === TestType.HORIZON) &&
        order.items.some((item) => item.testType === TestType.PANORAMA)
      ) {
        testType = "Horizon and Panorama";
      }
    } else if (isPanoramaOrder(order)) {
      testType = TestType.PANORAMA;
    } else if (isHorizonOrder(order)) {
      testType = TestType.HORIZON;
    }
  }
  return testType;
};

export const getSimpleOrderWorkflow = (workflows: Workflow[]) =>
  workflows.find(
    (workflow) => workflow.type === OrderWorkflowType.SIMPLE_ORDER
  );

export const getSampleCollectionWorkflow = (workflows: Workflow[]) =>
  workflows.find(
    (workflow) => workflow.type === OrderWorkflowType.SAMPLE_COLLECTION
  );
