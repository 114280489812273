import React, { FC, useContext } from "react";
import { useIntl } from "react-intl";
import { ConfigContext } from "@app/provider";
import { Form } from "@natera/platform/lib/components/form";
import { Textfield } from "@natera/platform/lib/components/form/field";
import { useErrorController } from "@natera/platform/lib/hooks";
import { PersonalInformation } from "@app/provider/profile";
import CommonFormActions from "../commonFormActions";
import { messages } from "./messages";
import { FormField } from "@app/components";

interface Props {
  personalInformation: PersonalInformation;
  handleSubmit: (personalInformation: PersonalInformation) => void;
  handleCancel: () => void;
}

const UpdatePersonalInformationForm: FC<Props> = ({
  personalInformation,
  handleSubmit,
  handleCancel,
}) => {
  const intl = useIntl();
  const { config } = useContext(ConfigContext);

  const {
    clearErrors,
    getValidationError,
    setValidationError,
  } = useErrorController();

  const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    clearErrors();

    const formData = new FormData(e.currentTarget);

    const firstName = formData.get("firstName")?.toString().trim() ?? "";
    const middleName = formData.get("middleName")?.toString().trim();
    const lastName = formData.get("lastName")?.toString().trim() ?? "";
    const alternativeLastName = formData
      .get("alternativeLastName")
      ?.toString()
      .trim();

    const updatePersonalInformationForm = [
      { title: "firstName", value: firstName },
      { title: "middleName", value: middleName },
      { title: "lastName", value: lastName },
      { title: "alternativeLastName", value: alternativeLastName },
    ];

    for (const field of updatePersonalInformationForm) {
      if (
        !["middleName", "alternativeLastName"].includes(field.title) &&
        !field.value
      ) {
        return setValidationError(
          field.title,
          intl.formatMessage(messages.updatePersonalInformationFormRequired)
        );
      }
    }

    const updatedPersonalInformation: PersonalInformation = {
      firstName,
      middleName,
      lastName,
      alternativeLastName,
    };

    handleSubmit(updatedPersonalInformation);
  };

  return (
    <Form className="info-card-form" noValidate onSubmit={handleSubmitForm}>
      <FormField
        label={intl.formatMessage(
          messages.updatePersonalInformationFormFirstName
        )}
        error={getValidationError("firstName")}
        required
        htmlFor="firstName"
        withPadding
      >
        <Textfield
          defaultValue={personalInformation.firstName}
          outline
          required
          id="firstName"
          name="firstName"
          placeholder={intl.formatMessage(
            messages.updatePersonalInformationFormFirstNamePlaceholder
          )}
          maxLength={config.NAME_FIELD_MAX_LENGTH}
          autoComplete="given-name"
        />
      </FormField>
      <FormField
        label={intl.formatMessage(
          messages.updatePersonalInformationFormMiddleName
        )}
        error={getValidationError("middleName")}
        htmlFor="middleName"
        withPadding
      >
        <Textfield
          defaultValue={personalInformation?.middleName}
          outline
          id="middleName"
          name="middleName"
          placeholder={intl.formatMessage(
            messages.updatePersonalInformationFormMiddleNamePlaceholder
          )}
          maxLength={config.NAME_FIELD_MAX_LENGTH}
          autoComplete="additional-name"
        />
      </FormField>
      <FormField
        label={intl.formatMessage(
          messages.updatePersonalInformationFormLastName
        )}
        error={getValidationError("lastName")}
        required
        htmlFor="lastName"
        withPadding
      >
        <Textfield
          defaultValue={personalInformation.lastName}
          outline
          required
          id="lastName"
          name="lastName"
          placeholder={intl.formatMessage(
            messages.updatePersonalInformationFormLastNamePlaceholder
          )}
          maxLength={config.NAME_FIELD_MAX_LENGTH}
          autoComplete="family-name"
        />
      </FormField>
      <FormField
        label={intl.formatMessage(
          messages.updatePersonalInformationFormAlternativeLastName
        )}
        error={getValidationError("alternativeLastName")}
        htmlFor="alternativeLastName"
        withPadding
      >
        <Textfield
          defaultValue={personalInformation?.alternativeLastName}
          outline
          id="alternativeLastName"
          name="alternativeLastName"
          placeholder={intl.formatMessage(
            messages.updatePersonalInformationFormAlternativeLastNamePlaceholder
          )}
          maxLength={config.NAME_FIELD_MAX_LENGTH}
        />
      </FormField>
      <CommonFormActions
        submitButtonText={intl.formatMessage(
          messages.updatePersonalInformationFormUpdateButton
        )}
        handleCancel={handleCancel}
      />
    </Form>
  );
};

export default UpdatePersonalInformationForm;
