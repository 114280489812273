import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";

import { DrawRequestContext } from "@app/provider";
import { DrawRequestTimeslots } from "@app/provider/drawRequest";
import TimeslotsForm, {
  TimeslotsFormData,
} from "@app/components/forms/timeslotsForm/timeslotsForm";

import "./modifyAppointment.scss";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress/stepperHeaderProgress";
import { StepperContent } from "@app/components/ui/layout";
import { TestType } from "@app/provider/testData/types";

const messages = defineMessages({
  mobilePhlebotomyTimeslotsTitle: {
    id: "mobilePhlebotomyTimeslotsTitle",
    defaultMessage: "Mobile Blood Draw",
  },
  mobilePhlebotomyTimeslotsDescriptionTitle: {
    id: "mobilePhlebotomyTimeslotsDescriptionTitle",
    defaultMessage: "When are some good times for your blood draw?",
  },
  mobilePhlebotomyTimeslotsDescription: {
    id: "mobilePhlebotomyTimeslotsDescription",
    defaultMessage:
      "Please select three time slots across three different dates to ensure that we can match you with an appointment.",
  },
  mobilePhlebotomySubmitAppointmentRequest: {
    id: "mobilePhlebotomySubmitAppointmentRequest",
    defaultMessage: "Submit",
  },
});

export interface ModifyAppointmentProps {
  handleBackToTimeConfirmation: () => void;
  testType?: TestType;
  orderUid?: string;
  testUid?: string;
  token?: string;
}

const ModifyAppointment: FC<ModifyAppointmentProps> = ({
  handleBackToTimeConfirmation,
  ...props
}) => {
  const intl = useIntl();
  const {
    isLoading,
    updateDrawRequest,
    openUpdateDrawErrorDialog,
  } = useContext(DrawRequestContext);

  const handleModifyTimeslots = async (data: TimeslotsFormData) => {
    try {
      await updateDrawRequest({
        timeZone: data.timeZone,
        timeSlots: data.timeSlots as DrawRequestTimeslots,
        ...props,
      });
      handleBackToTimeConfirmation();
    } catch {
      openUpdateDrawErrorDialog();
    }
  };

  return (
    <StepperContent
      title={intl.formatMessage(messages.mobilePhlebotomyTimeslotsTitle)}
      subTitle={intl.formatMessage(
        messages.mobilePhlebotomyTimeslotsDescriptionTitle
      )}
      description={intl.formatMessage(
        messages.mobilePhlebotomyTimeslotsDescription
      )}
    >
      <>
        <StepperHeaderProgress />
        <TimeslotsForm
          handleConfirm={handleModifyTimeslots}
          handleBack={handleBackToTimeConfirmation}
          confirmButtonLabel={intl.formatMessage(
            messages.mobilePhlebotomySubmitAppointmentRequest
          )}
          isLoading={isLoading}
        />
      </>
    </StepperContent>
  );
};

export default ModifyAppointment;
