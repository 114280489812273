import React, { FC, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Menu, MenuController, MenuItem } from "@natera/material/lib/menu";
import Svg from "@natera/material/lib/svg";
import {
  IntlContext,
  Language,
  LanguageKeys,
  UppAuthContext,
  UserContext,
} from "@app/provider";
import DropDownSvg from "@assets/svg/icons/down-arrow.svg";
import UpArrow from "@assets/svg/icons/up-arrow.svg";
import { defineMessages, useIntl } from "react-intl";
import { routes } from "@app/routing";

import "./languageSelector.scss";

const messages = defineMessages({
  languageSelectorMenuAriaLabel: {
    id: "languageSelectorMenuAriaLabel",
    defaultMessage: "Select language",
  },
  languageSelectorMenuItemEnglishAriaLabel: {
    id: "languageSelectorMenuItemEnglishAriaLabel",
    defaultMessage: "English",
  },
  languageSelectorMenuItemSpanishAriaLabel: {
    id: "languageSelectorMenuItemSpanishAriaLabel",
    defaultMessage: "Spanish",
  },
});

const LanguageSelector: FC = () => {
  const intl = useIntl();
  const location = useLocation();

  const { updateUser, updateUserLanguageNonAuth } = useContext(UserContext);
  const { profile } = useContext(UppAuthContext);
  const { changeLanguage, currentLanguage } = useContext(IntlContext);
  const menuRef = React.useRef<HTMLDivElement | null>(null);
  const { signUpEmail } = useContext(UppAuthContext);

  const isMPGuestPage = location.pathname === routes.scheduleBloodDraw;
  const isOnActivationPage = location.pathname === routes.activation;

  const switchLanguage = (code: Language) => () => {
    if (!isMPGuestPage) {
      if (isOnActivationPage) {
        updateUserLanguageNonAuth({ email: signUpEmail, language: code });
      } else if (profile) {
        updateUser({
          language: code,
        });
      }
    }
    changeLanguage(code);
  };

  const handleMenuControllerKeyPress = (controller: MenuController) => (
    event: React.KeyboardEvent
  ) => {
    if (event.key === "Enter") {
      controller.toggleMenu();
    }

    if (event.key === "Escape") {
      controller.closeMenu();
    }
  };

  const getItemLabel = (code: LanguageKeys) => {
    return code === "EN"
      ? intl.formatMessage(messages.languageSelectorMenuItemEnglishAriaLabel)
      : intl.formatMessage(messages.languageSelectorMenuItemSpanishAriaLabel);
  };

  return (
    <Menu
      className="language-container_menu"
      menuButtonRef={menuRef}
      menu={
        <>
          {Object.keys(Language).map((code: LanguageKeys) => (
            <MenuItem
              onItemSelect={switchLanguage(Language[code])}
              key={code}
              aria-label={getItemLabel(code)}
              className="language-container_menu__item"
            >
              {code}
            </MenuItem>
          ))}
        </>
      }
    >
      {(controller) => (
        <div
          ref={menuRef}
          className="language-container"
          onClick={controller.toggleMenu}
          onKeyDown={handleMenuControllerKeyPress(controller)}
          aria-label={intl.formatMessage(
            messages.languageSelectorMenuAriaLabel
          )}
          role="menu"
          tabIndex={0}
        >
          <span className="language-container_current">{currentLanguage}</span>
          <Svg content={controller.isOpen() ? UpArrow : DropDownSvg} />
        </div>
      )}
    </Menu>
  );
};

export default LanguageSelector;
