import React, { FC, useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  CommonAddressForm,
  CommonErrorNotification,
  InfoCard,
  SpinnerView,
} from "@app/components";
import { routes } from "@app/routing";
import { BlueHeaderContent } from "@app/components/ui/layout";
import { Address } from "@app/provider/profile";
import { useHistory } from "react-router-dom";
import { ProfileContext } from "@app/provider";
import { HeapEventLocation } from "@app/provider/types";
import { checkFirstEmptyAddress } from "@app/utils/checkFirstEmptyAddress";
import { messages as addressFormMessages } from "@app/components/commonAddressForm/commonAddressForm";

const messages = defineMessages({
  createOrUpdateAddressBackButton: {
    id: "createOrUpdateAddressBackButton",
    defaultMessage: "Contact Details",
  },
  createOrUpdateAddressTitle: {
    id: "createOrUpdateAddressTitle",
    defaultMessage: "Address",
  },
});

const CreateOrUpdateAddress: FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const {
    currentPatientAddress,
    addPatientAddress,
    addPatientAddressIsLoading,
    addPatientAddressError,
    updatePatientAddress,
    updatePatientAddressIsLoading,
    updatePatientAddressError,
    profileData: patientProfileData,
  } = useContext(ProfileContext);

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (address: Partial<Address>) => {
    setSubmitted(true);
    currentPatientAddress
      ? await updatePatientAddress(address)
      : await addPatientAddress(address, HeapEventLocation.patientProfile);
    history.goBack();
  };

  const handleCancel = () => history.push(routes.contactDetails);

  const notification =
    submitted && (addPatientAddressError || updatePatientAddressError) ? (
      <CommonErrorNotification />
    ) : null;

  const isFirstEmptyAddress =
    patientProfileData?.addresses &&
    checkFirstEmptyAddress(patientProfileData?.addresses);

  const submitButtonText = isFirstEmptyAddress
    ? intl.formatMessage(addressFormMessages.commonAddressFormSave)
    : undefined;

  return (
    <BlueHeaderContent
      blueHeaderTitle={intl.formatMessage(
        messages.createOrUpdateAddressBackButton
      )}
      backArrowHeaderRoute={routes.contactDetails}
    >
      <InfoCard
        titleText={intl.formatMessage(messages.createOrUpdateAddressTitle)}
        notification={notification}
      >
        <CommonAddressForm
          address={currentPatientAddress}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          submitButtonText={submitButtonText}
        />
      </InfoCard>
      <SpinnerView
        isLoading={addPatientAddressIsLoading || updatePatientAddressIsLoading}
      />
    </BlueHeaderContent>
  );
};

export default CreateOrUpdateAddress;
