import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { InfoCard, InfoCardField, SpinnerView } from "@app/components";
import { ProfileContext } from "@app/provider/profile";
import { routes } from "@app/routing";
import { BlueHeaderContent } from "@app/components/ui/layout";

const messages = defineMessages({
  personalInformationPageBackButton: {
    id: "personalInformationPageBackButton",
    defaultMessage: "Personal Information",
  },
  personalInformationPagePrimaryTitle: {
    id: "personalInformationPagePrimaryTitle",
    defaultMessage: "Personal Information",
  },
  personalInformationPageFirstName: {
    id: "personalInformationPageFirstName",
    defaultMessage: "First Name",
  },
  personalInformationPageMiddleName: {
    id: "personalInformationPageMiddleName",
    defaultMessage: "Middle Name / Initial",
  },
  personalInformationPageLastName: {
    id: "personalInformationPageLastName",
    defaultMessage: "Last Name",
  },
  personalInformationPageAlternativeLastName: {
    id: "personalInformationPageAlternativeLastName",
    defaultMessage: "Alternate Last Name",
  },
  personalInformationPageDateOfBirth: {
    id: "personalInformationPageDateOfBirth",
    defaultMessage: "Date of Birth",
  },
});

const PersonalInformationPage: FC = () => {
  const intl = useIntl();

  const { profileData: patientProfileData, profileDataIsLoading } = useContext(
    ProfileContext
  );

  return (
    <BlueHeaderContent
      blueHeaderTitle={intl.formatMessage(
        messages.personalInformationPageBackButton
      )}
      backArrowHeaderRoute={routes.profile}
    >
      {!profileDataIsLoading && patientProfileData && (
        <>
          <InfoCard
            titleText={intl.formatMessage(
              messages.personalInformationPagePrimaryTitle
            )}
            titleEditButtonLinkRoute={routes.updatePersonalInformation}
          >
            <InfoCardField
              label={intl.formatMessage(
                messages.personalInformationPageFirstName
              )}
              value={patientProfileData?.firstName}
            />
            {patientProfileData?.middleName &&
              patientProfileData?.middleName.length > 0 && (
                <InfoCardField
                  label={intl.formatMessage(
                    messages.personalInformationPageMiddleName
                  )}
                  value={patientProfileData?.middleName}
                />
              )}
            <InfoCardField
              label={intl.formatMessage(
                messages.personalInformationPageLastName
              )}
              value={patientProfileData?.lastName}
            />
            {patientProfileData?.alternativeLastName &&
              patientProfileData?.alternativeLastName.length > 0 && (
                <InfoCardField
                  label={intl.formatMessage(
                    messages.personalInformationPageAlternativeLastName
                  )}
                  value={patientProfileData?.alternativeLastName}
                />
              )}
          </InfoCard>
          <InfoCard>
            <InfoCardField
              label={intl.formatMessage(
                messages.personalInformationPageDateOfBirth
              )}
              value={patientProfileData?.dateOfBirth}
            />
          </InfoCard>
        </>
      )}
      <SpinnerView isLoading={profileDataIsLoading} />
    </BlueHeaderContent>
  );
};

export default PersonalInformationPage;
