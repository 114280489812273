import { Address } from "@app/provider";
import R from "ramda";

export const getListOfAddresses = (patientAddresses: Address[]): Address[] =>
  patientAddresses
    .filter(
      ({ city, state, street1, zipCode }: Address) =>
        city && state && street1 && zipCode
    )
    .map((address: Address) => R.omit(["__typename"], address));
