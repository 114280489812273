import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { IconButton } from "@natera/material/lib/button";
import ArrowIcon from "@assets/svg/icons/arrow.svg";
import { HeapVideoContentType, HeapVideoOrderData } from "@app/provider/types";
import { VideoController } from "@app/hooks/useVideo";
import VideoPlayer, { IVideo, VideoType } from "../videoPlayer";
import "./videoStepper.scss";

export interface VideoStepperProps {
  videoTypes: VideoType[];
  currentVideo: VideoType;
  setCurrentVideo: (type: VideoType) => void;
  videoController: VideoController;
  heapVideoOrderData?: HeapVideoOrderData;
}

const messages = defineMessages({
  videoStepperPrevVideoBtn: {
    id: "videoStepperPrevVideoBtn",
    defaultMessage: "Previous video",
  },
  videoStepperNextVideoBtn: {
    id: "videoStepperNextVideoBtn",
    defaultMessage: "Next video",
  },
});

const VideoStepper: React.FC<VideoStepperProps> = ({
  videoTypes,
  currentVideo,
  setCurrentVideo,
  videoController,
  heapVideoOrderData,
}) => {
  const intl = useIntl();
  const [isPlaying, setIsPlaying] = React.useState(false);

  React.useEffect(() => {
    window._wq = window._wq || [];
    const bindObj = {
      _all: (video$: IVideo) => {
        video$.bind("play", () => {
          setIsPlaying(true);
        });

        video$.bind("pause", () => {
          setIsPlaying(false);
        });

        video$.bind("end", () => {
          setIsPlaying(false);
        });
      },
    };
    window._wq.push(bindObj);
    return () => {
      window._wq.push({ revoke: bindObj });
    };
  }, []);

  const currentVideoIdx = videoTypes.indexOf(currentVideo);

  const handlePrevVideo = () => {
    setCurrentVideo(videoTypes[currentVideoIdx - 1]);
  };

  const handleNextVideo = () => {
    setCurrentVideo(videoTypes[currentVideoIdx + 1]);
  };

  const showPrevBtn = !isPlaying && currentVideoIdx !== 0;
  const showNextBtn = !isPlaying && currentVideoIdx !== videoTypes.length - 1;

  const videoInfo = React.useMemo(
    () => videoController.getVideoInfo(currentVideo),
    [videoTypes, currentVideo]
  );

  const heapVideoContentType = React.useMemo(() => {
    switch (currentVideo) {
      case VideoType.HORIZON:
        return HeapVideoContentType.horizonSimpleOrder;
      case VideoType.PANORAMA:
        return HeapVideoContentType.panoramaSimpleOrder;
      default:
        return;
    }
  }, [videoTypes, currentVideo]);

  return (
    <div className="video-stepper">
      <div className="video-stepper-content">
        <VideoPlayer
          id={videoInfo.videoHashId}
          thumbnailAltText={videoInfo.thumbnailAltText}
          heapVideoContentType={heapVideoContentType}
          heapVideoOrderData={heapVideoOrderData}
        />
      </div>
      {showPrevBtn && (
        <IconButton
          data-testid="video-stepper__prev-btn"
          className="video-stepper__prev-btn"
          onClick={handlePrevVideo}
          aria-label={intl.formatMessage(messages.videoStepperPrevVideoBtn)}
        >
          {ArrowIcon}
        </IconButton>
      )}
      {showNextBtn && (
        <IconButton
          data-testid="video-stepper__next-btn"
          className="video-stepper__next-btn"
          onClick={handleNextVideo}
          aria-label={intl.formatMessage(messages.videoStepperNextVideoBtn)}
        >
          {ArrowIcon}
        </IconButton>
      )}
    </div>
  );
};

export default VideoStepper;
