import React, { useMemo, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";

import { getTimeSlot } from "@app/utils";
import { DrawRequestTimeslots } from "@app/provider/drawRequest";
import { MobilePhlebotomyStatus } from "@app/provider/types";
import { IntlContext } from "@app/provider";

import "./appointmentTimeslots.scss";
import { TestCardStatus } from "@app/provider/testData/types";

const messages = defineMessages({
  appointmentTimeslotsSelectedTimeSlotsConfirmation: {
    id: "appointmentTimeslotsSelectedTimeSlotsConfirmation",
    defaultMessage: "Preferred time slots",
  },
  appointmentTimeslotsSelectedTimeSlots: {
    id: "appointmentTimeslotsSelectedTimeSlots",
    defaultMessage: "Selected time slots",
  },
  appointmentTimeslotsUpcomingAppointment: {
    id: "appointmentTimeslotsUpcomingAppointment",
    defaultMessage: "Upcoming Appointment",
  },
});

type AppointmentTimeslotsDrawRequest = DrawRequestTimeslots & {
  jobStatus?: MobilePhlebotomyStatus;
  jobEnd?: string;
  jobStart?: string;
};

type Props = {
  drawRequest: AppointmentTimeslotsDrawRequest;
  selectedTimeZone?: string;
  isConfirmationStep?: boolean;
  status?: TestCardStatus;
};

const AppointmentTimeslots: React.FC<Props> = ({
  drawRequest,
  selectedTimeZone,
  isConfirmationStep,
  status,
}) => {
  const intl = useIntl();
  const { currentLanguage } = useContext(IntlContext);
  const isApptConfirmed = status === TestCardStatus.APPT_CONFIRMED;

  const timeSlotsTitle = useMemo(() => {
    if (isConfirmationStep) {
      return intl.formatMessage(
        messages.appointmentTimeslotsSelectedTimeSlotsConfirmation
      );
    }

    return intl.formatMessage(
      isApptConfirmed
        ? messages.appointmentTimeslotsUpcomingAppointment
        : messages.appointmentTimeslotsSelectedTimeSlots
    );
  }, [drawRequest, isConfirmationStep, currentLanguage]);

  const renderTimeSlot = () => {
    if (isApptConfirmed) {
      return (
        <p>
          {drawRequest.jobStart && drawRequest.jobEnd && (
            <span>
              {getTimeSlot(
                drawRequest.jobStart,
                drawRequest.jobEnd,
                selectedTimeZone
              )}
            </span>
          )}
        </p>
      );
    }
    return (
      <p>
        <span>
          {getTimeSlot(drawRequest.start1, drawRequest.end1, selectedTimeZone)}
        </span>
        {drawRequest.start2 && drawRequest.end2 && (
          <>
            <br />
            <span>
              {getTimeSlot(
                drawRequest.start2,
                drawRequest.end2,
                selectedTimeZone
              )}
            </span>
          </>
        )}
        {drawRequest.start3 && drawRequest.end3 && (
          <>
            <br />
            <span>
              {getTimeSlot(
                drawRequest.start3,
                drawRequest.end3,
                selectedTimeZone
              )}
            </span>
          </>
        )}
      </p>
    );
  };

  return (
    <div className="appointment-timeslots">
      <h4>{timeSlotsTitle}</h4>
      {drawRequest && renderTimeSlot()}
    </div>
  );
};

export default AppointmentTimeslots;
