import { TestType } from "@app/provider/testData/types";

export const isOrganHealthPortal = (testType: TestType): boolean =>
  testType === TestType.RENASIGHT || testType === TestType.PROSPERA;

export const isConnectPortal = (testType: TestType): boolean =>
  testType === TestType.HORIZON ||
  testType === TestType.PANORAMA ||
  testType === TestType.VISTARA ||
  testType === TestType.EMPOWER;

export const isOncologyPortal = (testType: TestType): boolean =>
  testType === TestType.ALTERA || testType === TestType.SIGNATERA;
