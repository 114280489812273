import { MultipleInsurance } from "@app/components/ui/inputs/multipleInsurancesSelector/multipleInsurancesSelector";
import {
  SubSteps,
  PaymentInfo,
  PaymentType,
} from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { StepState, StepId, StepData } from "@natera/stepper";
import { PredefinedCompanyName } from "@app/components/companySelector/companySelector";

export const getDataFromMultipleFirstStep = (
  steps: StepState[]
): PaymentInfo => {
  const stepsMap: Record<StepId, StepData> = {};
  steps.forEach((step) => {
    stepsMap[step.stepId] = step.data;
  });
  if (stepsMap[SubSteps.MULTIPLE_FIRST] === MultipleInsurance.Government) {
    const companyName =
      stepsMap[SubSteps.SELECT_GOVERNMENT_COMPANY_FIRST].companyName ===
      PredefinedCompanyName.Other
        ? stepsMap[SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY_FIRST]
            .otherCompanyName
        : stepsMap[SubSteps.SELECT_GOVERNMENT_COMPANY_FIRST].companyName;

    const companyGroupId =
      stepsMap[SubSteps.SELECT_GOVERNMENT_COMPANY_FIRST].companyName ===
      PredefinedCompanyName.Other
        ? stepsMap[SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY_FIRST]
            .otherCompanyGroupId
        : stepsMap[SubSteps.SELECT_GOVERNMENT_COMPANY_FIRST].companyGroupId;

    return {
      paymentMethod: PaymentType.GOVERNMENT_ISSUED,
      paymentMethodInfo: {
        companyName,
        insuranceNumber:
          stepsMap[SubSteps.PROVIDE_GOVERNMENT_INSURANCE_FIRST].insuranceNumber,
        companyGroupId,
      },
    };
  } else {
    const companyName =
      stepsMap[SubSteps.SELECT_COMMERCIAL_COMPANY_FIRST].companyName ===
      PredefinedCompanyName.Other
        ? stepsMap[SubSteps.ENTER_OTHER_COMMERCIAL_COMPANY_FIRST]
            .otherCompanyName
        : stepsMap[SubSteps.SELECT_COMMERCIAL_COMPANY_FIRST].companyName;

    const companyGroupId =
      stepsMap[SubSteps.SELECT_COMMERCIAL_COMPANY_FIRST].companyName ===
      PredefinedCompanyName.Other
        ? stepsMap[SubSteps.ENTER_OTHER_COMMERCIAL_COMPANY_FIRST]
            .otherCompanyGroupId
        : stepsMap[SubSteps.SELECT_COMMERCIAL_COMPANY_FIRST].companyGroupId;

    return {
      paymentMethod: PaymentType.COMMERCIAL,
      paymentMethodInfo: {
        companyName,
        memberId:
          stepsMap[SubSteps.PROVIDE_COMMERCIAL_INSURANCE_FIRST].memberId,
        companyGroupId,
      },
    };
  }
};
