import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Address } from "@app/provider";
import { CommonFormActions } from "@app/components";
import { AddressForm } from "@app/components/forms";

export const messages = defineMessages({
  commonAddressFormSave: {
    id: "commonAddressFormSave",
    defaultMessage: "Save",
  },
  commonAddressFormUpdate: {
    id: "commonAddressFormUpdate",
    defaultMessage: "Update",
  },
});

interface Props {
  address?: Address;
  handleSubmit: (address: Partial<Address>) => void;
  handleCancel: () => void;
  submitButtonText?: string;
}

const CommonAddressForm: FC<Props> = ({
  address,
  handleSubmit,
  handleCancel,
  submitButtonText,
}) => {
  const intl = useIntl();

  const defaultSubmitButtonText = address
    ? intl.formatMessage(messages.commonAddressFormUpdate)
    : intl.formatMessage(messages.commonAddressFormSave);

  return (
    <AddressForm
      address={address}
      onSubmit={handleSubmit}
      actions={
        <CommonFormActions
          submitButtonText={submitButtonText || defaultSubmitButtonText}
          handleCancel={handleCancel}
        />
      }
    />
  );
};

export default CommonAddressForm;
