import React, { FC, useEffect } from "react";
import { Step, Stepper } from "@natera/stepper";
import { OrderContext, ProfileContext, UserContext } from "@app/provider";
import PersonalInfo from "@app/components/personalInfo";
import KitShippingDetails from "@app/pages/private/simpleOrder/kitShippingDetails";
import { isOlderThan24Hours } from "@app/utils/isDateOlderThan24Hours";
import { LoadingContext } from "@natera/platform/lib/components";
import PaymentMethod from "@app/pages/private/simpleOrder/paymentMethod";
import FetalSexInResults from "@app/components/fetalSexInResults/fetalSexInResults";
import PatientAcknowledgmentController from "./patientAcknowledgment/patientAcknowledgmentController";
import EthnicityStep from "@app/components/selectEthnicity/ethnicityStep";
import { isHorizonOrder, isPanoramaOrder } from "@app/utils";
import PartnerInfoController from "./partnerInfo/partnerInfoController";

import "./simpleOrderStepper.scss";

export enum Steps {
  PERSONAL_INFO = "PERSONAL_INFO",
  SELECT_ETHNICITY = "SELECT_ETHNICITY",
  KIT_SHIPPING_DETAILS = "KIT_SHIPPING_DETAILS",
  PARTNER_INFO = "PARTNER_INFO",
  FETAL_SEX = "FETAL_SEX",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  ACKNOWLEDGMENT = "ACKNOWLEDGMENT",
}

const SimpleOrderStepper: FC = () => {
  const { uppUser, isLoading } = React.useContext(UserContext);
  const { orderData, orderIsLoading } = React.useContext(OrderContext);
  const { getProfile } = React.useContext(ProfileContext);

  const showPersonalInfoStep = Boolean(
    uppUser?.createdAt && isOlderThan24Hours(new Date(uppUser.createdAt))
  );

  const isPanorama = orderData && isPanoramaOrder(orderData);
  const isHorizon = orderData && isHorizonOrder(orderData);

  const getInitialStep = () => {
    if (showPersonalInfoStep) {
      return Steps.PERSONAL_INFO;
    } else if (isHorizon) {
      return Steps.SELECT_ETHNICITY;
    } else {
      return Steps.KIT_SHIPPING_DETAILS;
    }
  };

  const firstUppUserLoading = !uppUser && isLoading;

  useEffect(() => {
    getProfile();
  }, []);

  const initialStep = getInitialStep();

  return (
    <LoadingContext isLoading={firstUppUserLoading || orderIsLoading}>
      {orderData && (
        <Stepper
          initialStep={initialStep}
          progressStepper
          className="simple-order-stepper stepper"
        >
          {showPersonalInfoStep && (
            <Step stepId={Steps.PERSONAL_INFO}>
              <PersonalInfo />
            </Step>
          )}
          {isHorizon && (
            <Step stepId={Steps.SELECT_ETHNICITY}>
              <EthnicityStep
                isInitialStep={initialStep === Steps.SELECT_ETHNICITY}
              />
            </Step>
          )}
          <Step stepId={Steps.KIT_SHIPPING_DETAILS}>
            <KitShippingDetails
              isInitialStep={initialStep === Steps.KIT_SHIPPING_DETAILS}
            />
          </Step>
          {isHorizon && (
            <Step stepId={Steps.PARTNER_INFO}>
              <PartnerInfoController />
            </Step>
          )}
          {isPanorama && (
            <Step stepId={Steps.FETAL_SEX}>
              <FetalSexInResults />
            </Step>
          )}
          <Step stepId={Steps.PAYMENT_METHOD}>
            <PaymentMethod />
          </Step>
          <Step stepId={Steps.ACKNOWLEDGMENT}>
            <PatientAcknowledgmentController orderData={orderData} />
          </Step>
        </Stepper>
      )}
    </LoadingContext>
  );
};

export default SimpleOrderStepper;
