import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import { useQuery } from "@app/utils";
import WelcomeGuestScreen from "./welcome";
import WelcomeDobVerificationFailedScreen from "./welcome/welcomeDobVerificationFailedScreen";
import WelcomeDobVerificationScreen from "./welcome/welcomeDobVerificationScreen";
import {
  BloodDrawProvider,
  DrawRequestProvider,
  HeapAnalyticDataProvider,
  KitProvider,
} from "@app/provider";
import OrderInWrongStateScreen from "@app/pages/public/scheduleBloodDraw/orderInWrongState/OrderInWrongStateScreen";
import { PersonalInformation } from "./personalInformation/personalInformation";
import {
  ScheduleBloodDrawStepper,
  CreateBloodDrawFailedScreen,
} from "./scheduleBloodDrawStepper";
import { KitDetails } from "./kitDetails";
import { BusinessUnits } from "@app/provider/drawRequest";
import { ViewBloodDrawDetails } from "./viewBloodDrawDetails";

export enum ScheduleBloodDrawScreens {
  WELCOME_GUEST_SCREEN = "WELCOME_GUEST_SCREEN",
  WELCOME_DOB_VERIFY_SCREEN = "WELCOME_DOB_VERIFY_SCREEN",
  WELCOME_DOB_VERIFY_FAILED_SCREEN = "WELCOME_DOB_VERIFY_FAILED_SCREEN",
  SCHEDULE_BLOOD_DRAW_FAILED_SCREEN = "SCHEDULE_BLOOD_DRAW_FAILED_SCREEN",
  PERSONAL_INFO_SCREEN = "PERSONAL_INFO_SCREEN",
  KIT_DETAILS_SCREEN = "KIT_DETAILS_SCREEN",
  MOBILE_PHLEBOTOMY_STEPPER_SCREEN = "MOBILE_PHLEBOTOMY_STEPPER_SCREEN",
  CREATE_BLOOD_DRAW_FAILED_SCREEN = "CREATE_BLOOD_DRAW_FAILED_SCREEN",
  VIEW_BLOOD_DRAW_DETAILS = "VIEW_BLOOD_DRAW_DETAILS",
}

const ScheduleBloodDraw: FC = () => {
  const history = useHistory();
  const query = useQuery();
  const token = query.get("token");

  if (!token) {
    history.replace(routes.home);
  }

  const [bloodDrawScreen, setBloodDrawScreen] = React.useState<
    ScheduleBloodDrawScreens
  >(ScheduleBloodDrawScreens.WELCOME_GUEST_SCREEN);

  const [businessUnit, setBusinessunit] = React.useState<
    BusinessUnits | undefined
  >(undefined);
  const [
    isSeparateMobilePhlebotomyFlow,
    setIsSeparateMobilePhlebotomyFlow,
  ] = React.useState(false);

  const renderScreen = () => {
    switch (bloodDrawScreen) {
      case ScheduleBloodDrawScreens.WELCOME_DOB_VERIFY_SCREEN:
        return (
          <WelcomeDobVerificationScreen
            token={token as string}
            setBloodDrawScreen={setBloodDrawScreen}
            setBusinessUnit={setBusinessunit}
          />
        );
      case ScheduleBloodDrawScreens.WELCOME_DOB_VERIFY_FAILED_SCREEN:
        return <WelcomeDobVerificationFailedScreen />;
      case ScheduleBloodDrawScreens.SCHEDULE_BLOOD_DRAW_FAILED_SCREEN:
        return <OrderInWrongStateScreen />;
      case ScheduleBloodDrawScreens.PERSONAL_INFO_SCREEN:
        return <PersonalInformation setBloodDrawScreen={setBloodDrawScreen} />;
      case ScheduleBloodDrawScreens.KIT_DETAILS_SCREEN:
        return (
          <KitDetails
            token={token as string}
            setBloodDrawScreen={setBloodDrawScreen}
            setIsSeparateMobilePhlebotomyFlow={
              setIsSeparateMobilePhlebotomyFlow
            }
            businessUnit={businessUnit}
          />
        );
      case ScheduleBloodDrawScreens.MOBILE_PHLEBOTOMY_STEPPER_SCREEN:
        return (
          <ScheduleBloodDrawStepper
            token={token as string}
            setBloodDrawScreen={setBloodDrawScreen}
            isSeparateMobilePhlebotomyFlow={isSeparateMobilePhlebotomyFlow}
          />
        );
      case ScheduleBloodDrawScreens.CREATE_BLOOD_DRAW_FAILED_SCREEN:
        return <CreateBloodDrawFailedScreen />;
      case ScheduleBloodDrawScreens.VIEW_BLOOD_DRAW_DETAILS:
        return <ViewBloodDrawDetails token={token as string} />;
      default:
        return (
          <WelcomeGuestScreen
            token={token as string}
            setBloodDrawScreen={setBloodDrawScreen}
          />
        );
    }
  };

  return renderScreen();
};

const ScheduleBloodDraw$: FC = () => {
  return (
    <HeapAnalyticDataProvider>
      <BloodDrawProvider>
        <DrawRequestProvider>
          <KitProvider>
            <ScheduleBloodDraw />
          </KitProvider>
        </DrawRequestProvider>
      </BloodDrawProvider>
    </HeapAnalyticDataProvider>
  );
};

export default ScheduleBloodDraw$;
