import React, { FC, KeyboardEvent, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import Svg from "@natera/material/lib/svg";
import { ConditionCardType, ConditionType, KeyTypes } from "@app/neva/models";
import ConditionStatusIcon from "@app/neva/assets/svg/condition-status-icon.svg";
import ConditionStatusDoneIcon from "@app/neva/assets/svg/condition-status-done-icon.svg";
import "./conditionCard.scss";
import { EventsContext } from "@app/neva/provider";
import { generatePostbackUserEvent } from "@app/neva/helpers";

interface Props {
  conditionCards: ConditionCardType[];
  isLastMessage: boolean;
}

const messages = defineMessages({
  conditionCard: {
    id: "conditionCard",
    defaultMessage: "Conditions to review",
  },
});

const ConditionCard: FC<Props> = ({ conditionCards, isLastMessage }) => {
  const { userEventHandler } = useContext(EventsContext);
  const intl = useIntl();
  const conditionStatusIcon = (isPassed: boolean): JSX.Element =>
    isPassed ? (
      <Svg content={ConditionStatusDoneIcon} />
    ) : (
      <Svg content={ConditionStatusIcon} />
    );

  const clickHandler = (condition: ConditionType): void => {
    if (!isLastMessage || condition.passed || condition.disabled) return;

    userEventHandler(
      generatePostbackUserEvent({
        title: condition.name,
        payload: condition.payload,
      })
    );
  };

  const keyDownHandler = (
    e: KeyboardEvent<HTMLButtonElement>,
    condition: ConditionType
  ) => {
    const key = e.key.toLowerCase();
    switch (key) {
      case KeyTypes.ENTER:
      case KeyTypes.SPACE:
        clickHandler(condition);
        break;
    }
  };

  return (
    <ul
      className="condition-card__wrapper"
      aria-label={intl.formatMessage(messages.conditionCard)}
    >
      {conditionCards?.map((conditionCard, index) => (
        <li key={index} className="condition-card__container">
          {conditionCard.title && (
            <h2 className="condition-card__title">{conditionCard.title}</h2>
          )}
          <ul>
            {conditionCard.conditions.map((condition, index) => (
              <li
                key={index}
                className="condition-card__condition-container"
                data-testid="condition-container"
              >
                <button
                  className="condition-card__condition-button"
                  disabled={
                    !isLastMessage || condition.disabled || condition.passed
                  }
                  onClick={() => clickHandler(condition)}
                  onKeyDown={(e) => keyDownHandler(e, condition)}
                >
                  <div className="condition-status__icon-container">
                    {conditionStatusIcon(condition.passed)}
                  </div>
                  <div className="condition-status__condition-name">
                    {condition.name}
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default ConditionCard;
