import { HEAP_EVENTS } from "@app/provider/types";

const VIDEO_PERC = {
  oneQuarter: 0.25,
  twoQuarters: 0.5,
  threeQuarters: 0.75,
  fourQuarters: 1,
};

export const videoPercentageToHeapName = (
  value: number
): keyof typeof HEAP_EVENTS | undefined => {
  let eventName: keyof typeof HEAP_EVENTS | undefined = undefined;

  switch (value) {
    case VIDEO_PERC.oneQuarter:
      eventName = HEAP_EVENTS.upp_video25perc;
      break;
    case VIDEO_PERC.twoQuarters:
      eventName = HEAP_EVENTS.upp_video50perc;
      break;
    case VIDEO_PERC.threeQuarters:
      eventName = HEAP_EVENTS.upp_video75perc;
      break;
    case VIDEO_PERC.fourQuarters:
      eventName = HEAP_EVENTS.upp_video100perc;
      break;
  }

  return eventName;
};
