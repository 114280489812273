import React from "react";
import { defineMessages, useIntl } from "react-intl";

import {
  ConfigContext,
  Language,
  NotificationContext,
  VerificationContext,
} from "@app/provider";
import { ResultCodes } from "@app/service/resultCodes";

const messages = defineMessages({
  activationTimeoutError: {
    id: "activationTimeoutError",
    defaultMessage: "You can send once a {timeout} seconds",
  },
});

interface ActivationController {
  handleActivation: (email: string, language: Language) => void;
  isLoading: boolean;
  resendIsDisabled: boolean;
}

const useSendActivation = (): ActivationController => {
  const intl = useIntl();
  const { config } = React.useContext(ConfigContext);
  const { addNotification } = React.useContext(NotificationContext);
  const { verificationError, isLoading, activation } = React.useContext(
    VerificationContext
  );

  const [resendIsDisabled, setResendIsDisabled] = React.useState(false);

  const resendTimeoutRef = React.useRef<number | null>(null);

  React.useEffect(
    () => () => {
      if (resendTimeoutRef.current) {
        clearTimeout(resendTimeoutRef.current);
      }
    },
    []
  );

  React.useEffect(() => {
    if (verificationError) {
      if (verificationError === ResultCodes.ACTIVATION_TIMEOUT_ERROR) {
        addNotification({
          message: intl.formatMessage(messages.activationTimeoutError, {
            timeout: config.timeouts.resendTimeout / 1000,
          }),
          type: "warning",
        });
      }
    }
  }, [verificationError]);

  const handleActivation = (email: string, language: Language) => {
    activation(email, language).then(() => {
      setResendIsDisabled(true);

      resendTimeoutRef.current = window.setTimeout(() => {
        setResendIsDisabled(false);

        if (resendTimeoutRef.current) {
          clearTimeout(resendTimeoutRef.current);
        }
      }, config.timeouts.resendTimeout);
    });
  };

  const controller = {
    handleActivation,
    isLoading,
    resendIsDisabled,
  };

  return controller;
};

export default useSendActivation;
