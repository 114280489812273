import React, { FC } from "react";
import { Spinner } from "@natera/material/lib/progress";

import "./style.scss";

interface SpinnerViewProps extends React.HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
}

const SpinnerView: FC<SpinnerViewProps> = (props) => {
  const { isLoading } = props;

  if (!isLoading) return null;

  return (
    <div className="spinner-view">
      <Spinner indeterminate />
    </div>
  );
};

export default SpinnerView;
