import { IntlShape, MessageDescriptor } from "react-intl";
import { messages } from "../messages";
import { SecondaryActionCardType, SecondaryCard } from "../types";

const typeToText: { [key in SecondaryActionCardType]: MessageDescriptor } = {
  [SecondaryActionCardType.SALIVA]: messages.salivaSecondaryActionCardTitle,
  [SecondaryActionCardType.COMPASSIONATE_CARE]:
    messages.testCardSecondaryTitleCompassionateCare,
  [SecondaryActionCardType.PROSPERA_HEART]:
    messages.prosperaHeartSecondaryActionCardTitle,
  [SecondaryActionCardType.PROSPERA_KIDNEY]:
    messages.prosperaKidneySecondaryActionCardTitle,
  [SecondaryActionCardType.PROSPERA_SPK]:
    messages.prosperaKidneySecondaryActionCardTitle,
  [SecondaryActionCardType.PROSPERA_LUNG]:
    messages.prosperaLungSecondaryActionCardTitle,
  [SecondaryActionCardType.PROSPERA_LIVER]:
    messages.prosperaLiverSecondaryActionCardTitle,
  [SecondaryActionCardType.PROSPERA_OTHER]:
    messages.prosperaOtherSecondaryActionCardTitle,
  [SecondaryActionCardType.RENASIGHT_KIDNEY]:
    messages.renasightKidneySecondaryActionCardTitle,
  [SecondaryActionCardType.EMPOWER]: messages.empowerInfoCardTitle,
  [SecondaryActionCardType.HORIZON]: messages.horizonInfoCardTitle,
  [SecondaryActionCardType.PANORAMA]: messages.panoramaInfoCardTitle,
  [SecondaryActionCardType.VISTARA]: messages.vistaraInfoCardTitle,
  [SecondaryActionCardType.GENETIC_TESTING]:
    messages.geneticTestingInfoCardTitle,
  [SecondaryActionCardType.WOMENS_HEALTH_PRICING_BILLING]:
    messages.pricingBillingInfoCardTitle,
  [SecondaryActionCardType.ONCOLOGY_PRICING_BILLING]:
    messages.pricingBillingInfoCardTitle,
  [SecondaryActionCardType.PANORAMA_HORIZON]: messages.panoramaHorizonTitle,
  [SecondaryActionCardType.ALTERA]: messages.alteraInfoCardTitle,
  [SecondaryActionCardType.SIGNATERA]: messages.signateraInfoCardTitle,
};

export const getSecondaryCardTitle = (
  intl: IntlShape,
  secondaryCard: SecondaryCard
): string => {
  return intl.formatMessage(typeToText[secondaryCard.type]);
};
