import { IVideo } from "@app/components/videoPlayer";
import { HeapVideoContentType, HeapVideoOrderData } from "@app/provider/types";
import { trackVideoPlaysInHeap } from "@app/utils/trackVideoPlaysInHeap";
import R from "ramda";

const HEAP_TRACK_VIDEO_TYPES = [
  HeapVideoContentType.renasightCarrier,
  HeapVideoContentType.renasightNegative,
  HeapVideoContentType.renasightPositive,
  HeapVideoContentType.horizonSimpleOrder,
  HeapVideoContentType.panoramaSimpleOrder,
  HeapVideoContentType.prosperaDecreasedRisk,
  HeapVideoContentType.prosperaIncreasedRisk,
  HeapVideoContentType.prosperaHeartOrLung,
  HeapVideoContentType.sampleCollectionSaliva,
  HeapVideoContentType.horizonCysticFibrosis,
  HeapVideoContentType.horizonTaySachs,
  HeapVideoContentType.horizonDBMuscularDystrophy,
  HeapVideoContentType.horizonFragileX,
  HeapVideoContentType.horizonSMA,
];

const VIDEO_PERC = {
  oneQuarter: 0.25,
  twoQuarters: 0.5,
  threeQuarters: 0.75,
  fourQuarters: 1,
};

export const bindHeapVideo = (
  video$: IVideo,
  heapVideoContentType?: HeapVideoContentType,
  heapVideoOrderData?: HeapVideoOrderData
): void => {
  if (
    heapVideoContentType &&
    R.includes(heapVideoContentType, HEAP_TRACK_VIDEO_TYPES)
  ) {
    const oneQuarterPlayed = Math.floor(video$.duration() / 4);
    const twoQuartersPlayed = Math.floor(video$.duration() / 2);
    const threeQuartersPlayed = oneQuarterPlayed * 3;

    video$.bind("secondchange", (videoProgress) => {
      if (videoProgress === oneQuarterPlayed) {
        trackVideoPlaysInHeap(
          VIDEO_PERC.oneQuarter,
          heapVideoContentType,
          heapVideoOrderData
        );
      }
      if (videoProgress === twoQuartersPlayed) {
        trackVideoPlaysInHeap(
          VIDEO_PERC.twoQuarters,
          heapVideoContentType,
          heapVideoOrderData
        );
      }
      if (videoProgress === threeQuartersPlayed) {
        trackVideoPlaysInHeap(
          VIDEO_PERC.threeQuarters,
          heapVideoContentType,
          heapVideoOrderData
        );
      }
    });

    video$.bind("end", () => {
      trackVideoPlaysInHeap(
        VIDEO_PERC.fourQuarters,
        heapVideoContentType,
        heapVideoOrderData
      );
    });
  }
};
