import { DocumentNode, gql } from "@apollo/client";

class EthnicityService {
  public static QUERY_WRAPPER_NAME = {
    PATIENT: "GetEthnicityQuery",
  };

  public static getEthnicities = (): DocumentNode => gql`
    query ethnicities {
      ethnicities {
        code
        description
      }
    }
  `;
}

export default EthnicityService;
