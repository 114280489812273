import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Notification } from "@app/components";

const messages = defineMessages({
  mobilePhlebotomyNotificationTitle: {
    id: "mobilePhlebotomyNotificationTitle",
    defaultMessage: "Please select three date/time slots",
  },
  mobilePhlebotomyNotificationText: {
    id: "mobilePhlebotomyNotificationText",
    defaultMessage:
      "We need you to select three different date/times to continue with scheduling.",
  },
});

const TimeslotsWarning: React.FC = () => {
  const intl = useIntl();

  return (
    <Notification
      titleElem={intl.formatMessage(messages.mobilePhlebotomyNotificationTitle)}
      className="timeslots-notification"
      type="warning"
      data-testid="timezone_warning"
    >
      <span>
        {intl.formatMessage(messages.mobilePhlebotomyNotificationText)}
      </span>
    </Notification>
  );
};

export default TimeslotsWarning;
