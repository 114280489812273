import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/platform/lib/components/form";
import { Svg } from "@natera/material/lib/svg";
import AccountCreatedSvg from "@assets/svg/account-created.svg";
import "./setUpProfileDialog.scss";

const messages = defineMessages({
  setupProfileDialogTitle: {
    id: "setupProfileDialogTitle",
    defaultMessage: "Welcome to Natera's Patient Portal",
  },
  setupProfileDialogYouActivatedAccount: {
    id: "setupProfileDialogYouActivatedAccount",
    defaultMessage: "You have successfully activated your account",
  },
  setupProfileDialogLetsSetupProfile: {
    id: "setupProfileDialogLetsSetupProfile",
    defaultMessage: "Let's set up your profile",
  },
  setupProfileDialogNotice: {
    id: "setupProfileDialogNotice",
    defaultMessage:
      "We’ll use this information to fetch your test details and set up your security preferences",
  },
  setupProfileDialogContinue: {
    id: "setupProfileDialogContinue",
    defaultMessage: "Continue",
  },
});

interface SetUpProfileDialogProps {
  isLoading: boolean;
  onSubmit: () => void;
}

const SetUpProfileDialog: FC<SetUpProfileDialogProps> = ({
  isLoading,
  onSubmit,
}) => {
  const intl = useIntl();

  const handleClick = () => {
    onSubmit();
  };

  return (
    <Dialog
      className="setup-profile-dialog"
      title={intl.formatMessage(messages.setupProfileDialogTitle)}
      onClose={handleClick}
      actions={
        <Button
          className="setup-profile-dialog__continue-button"
          raised
          loading={isLoading}
          onClick={handleClick}
        >
          {intl.formatMessage(messages.setupProfileDialogContinue)}
        </Button>
      }
    >
      <section className="setup-profile-dialog__content">
        <p>
          {intl.formatMessage(messages.setupProfileDialogYouActivatedAccount)}
        </p>
        <Svg content={AccountCreatedSvg} />
        <h3>
          {intl.formatMessage(messages.setupProfileDialogLetsSetupProfile)}
        </h3>
        <p className="notice-text">
          {intl.formatMessage(messages.setupProfileDialogNotice)}
        </p>
      </section>
    </Dialog>
  );
};

export default SetUpProfileDialog;
