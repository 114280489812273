import { routes } from "@app/routing";
import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/platform/lib/components/form";

const messages = defineMessages({
  personalInfoDialogTitle: {
    id: "personalInfoDialogTitle",
    defaultMessage: "After updating your personal information",
  },
  personalInfoDialogContent: {
    id: "personalInfoDialogContent",
    defaultMessage:
      "To complete your order after updating your personal information, please go back to the homepage and click the ‘complete order’ button on the testcard.",
  },
  personalInfoDialogContinue: {
    id: "personalInfoDialogContinue",
    defaultMessage: "Continue",
  },
  personalInfoDialogBack: {
    id: "personalInfoDialogBack",
    defaultMessage: "Back",
  },
});

const PersonalInfoUpdateDialog: FC<{ onCloseDialog: () => void }> = ({
  onCloseDialog,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const handleContinue = () => {
    history.replace(routes.profile);
  };

  return (
    <Dialog
      className="personal-info-modal"
      onClose={onCloseDialog}
      title={intl.formatMessage(messages.personalInfoDialogTitle)}
      showCrossButton
      actions={
        <>
          <Button raised onClick={handleContinue}>
            {intl.formatMessage(messages.personalInfoDialogContinue)}
          </Button>
          <Button outlined onClick={onCloseDialog}>
            {intl.formatMessage(messages.personalInfoDialogBack)}
          </Button>
        </>
      }
    >
      <div className="personal-info-modal__container">
        <p>{intl.formatMessage(messages.personalInfoDialogContent)}</p>
      </div>
    </Dialog>
  );
};

export default PersonalInfoUpdateDialog;
