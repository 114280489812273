import React, { FC } from "react";
import classNames from "classnames";
import { RatingItemType } from "@app/neva/models";
import "./rating.scss";

interface Props {
  item: RatingItemType;
  isDisabled: boolean;
  rating: number;
  onClickHandler: (props: RatingItemType) => void;
}

const ScoresRating: FC<Props> = ({
  item,
  isDisabled,
  rating,
  onClickHandler,
}) => {
  return (
    <li className="scores-rating__item">
      <button
        className={classNames("scores-rating__button", {
          "selected-item": item.scoreNumber === rating,
        })}
        role="checkbox"
        aria-label={item.ariaLabel}
        aria-checked={item.scoreNumber === rating}
        onClick={() => onClickHandler(item)}
        disabled={isDisabled}
      >
        {item.scoreNumber}
      </button>
    </li>
  );
};

export default ScoresRating;
