import * as React from "react";

import { useQuery } from "@app/utils";
import { routes } from "@app/routing";
import { useHistory } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";
import { NotificationContext } from "@app/provider";

export enum RoutingType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

const messages = defineMessages({
  privateUpdateEmailNotificationTitle: {
    id: "privateUpdateEmailNotificationTitle",
    defaultMessage: "Email has been verified",
  },
  privateUpdateEmailNotification: {
    id: "privateUpdateEmailNotification",
    defaultMessage:
      "Your email has been successfully updated. Please use updated email to log in.",
  },
});

export interface RedirectionController {
  redirectionTrigger: string | undefined;
  setRedirection: (routingFrom: RoutingType) => void;
  handleRedirection: () => void;
}

export const Context = React.createContext<RedirectionController>({
  redirectionTrigger: undefined,
  setRedirection: () => undefined,
  handleRedirection: () => undefined,
});

const allRedirectionTriggers: Record<string, string> = {
  EMAIL_VERIFIED: "email_verified",
  EMAIL_VERIFIED_PRIVATE: "email_verified_private",
};

const RedirectionProvider: React.FunctionComponent = ({ children }) => {
  const query = useQuery();
  const history = useHistory();
  const intl = useIntl();
  const { addNotification } = React.useContext(NotificationContext);

  const [redirectionTrigger, setRedirectionTrigger] = React.useState<
    string | undefined
  >();

  const setRedirection = (routingFrom: RoutingType) => {
    for (const key in allRedirectionTriggers) {
      const trigger = allRedirectionTriggers[key];
      const checkTrigger = query.get(trigger);

      if (checkTrigger) {
        trigger === allRedirectionTriggers.EMAIL_VERIFIED &&
        routingFrom === RoutingType.PRIVATE
          ? setRedirectionTrigger(allRedirectionTriggers.EMAIL_VERIFIED_PRIVATE)
          : setRedirectionTrigger(trigger);

        return;
      }
    }
  };

  const handleRedirection = () => {
    if (redirectionTrigger === allRedirectionTriggers.EMAIL_VERIFIED_PRIVATE) {
      history.push(routes.contactDetails);
      addNotification({
        title: intl.formatMessage(messages.privateUpdateEmailNotificationTitle),
        message: intl.formatMessage(messages.privateUpdateEmailNotification),
        type: "success",
      });
    }

    if (redirectionTrigger === allRedirectionTriggers.EMAIL_VERIFIED) {
      history.push(routes.contactDetails);
    }

    setRedirectionTrigger(undefined);
  };

  return (
    <Context.Provider
      value={{
        redirectionTrigger,
        setRedirection,
        handleRedirection,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default RedirectionProvider;
