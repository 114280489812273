import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Factor, FACTORS_TO_SHOW } from "@app/service/user";
import {
  RadioGroup,
  RadioButton,
} from "@natera/platform/lib/components/form/field";
import { Profile } from "@app/provider/profile";
import { maskPhone } from "@app/utils";
import { FactorsObject } from "@app/provider/mfaSetup";
import "./factorList.scss";

const messages = defineMessages({
  factorListSmsTitle: {
    id: "factorListSmsTitle",
    defaultMessage: "Text Message",
  },
  factorListSmsDescription: {
    id: "factorListSmsDescription",
    defaultMessage:
      "You will receive the security code via text message. Text or data rates may apply.",
  },
  factorListEmailTitle: {
    id: "factorListEmailTitle",
    defaultMessage: "Email",
  },
  factorListEmailDescription: {
    id: "factorListEmailDescription",
    defaultMessage: "You will receive the security code via email.",
  },
  factorListCallTitle: {
    id: "factorListCallTitle",
    defaultMessage: "Phone Call",
  },
  factorListCallDescription: {
    id: "factorListCallDescription",
    defaultMessage: "You will receive a phone call to {phone}",
  },
});

interface FactorList {
  [key: string]: {
    title: { id: string; defaultMessage: string };
    description: { id: string; defaultMessage: string };
  };
}

export const FACTOR_LIST: FactorList = {
  call: {
    title: messages.factorListCallTitle,
    description: messages.factorListCallDescription,
  },
  email: {
    title: messages.factorListEmailTitle,
    description: messages.factorListEmailDescription,
  },
  sms: {
    title: messages.factorListSmsTitle,
    description: messages.factorListSmsDescription,
  },
};

interface Props {
  profile?: Profile;
  factorsObject: FactorsObject;
  isForcedViaPhone: boolean;
  setSelectedFactor: (prop: Factor | undefined) => void;
  phone?: string;
}

const FactorList: React.FunctionComponent<Props> = ({
  profile,
  factorsObject,
  isForcedViaPhone,
  setSelectedFactor,
  phone,
}) => {
  const intl = useIntl();
  const userPhone = profile?.phone ? profile.phone : phone;

  const handleFactorSelect = (factorType: FACTORS_TO_SHOW) => {
    setSelectedFactor(factorsObject[factorType]);
  };

  const renderItem = (factorType: FACTORS_TO_SHOW) => {
    const factorText = factorType in FACTOR_LIST && FACTOR_LIST[factorType];

    if (!factorText) return null;

    if (isForcedViaPhone && factorType === FACTORS_TO_SHOW.email) return null;

    return (
      <RadioButton
        key={factorType}
        className="factor-item"
        label={
          <>
            <div className="factor-item-title">
              {intl.formatMessage(factorText.title)}
            </div>
            <div className="factor-item-desc">
              {intl.formatMessage(factorText.description, {
                phone: maskPhone(userPhone),
              })}
            </div>
          </>
        }
        value={factorType}
      />
    );
  };

  const renderFactors = () => {
    if (Object.values(factorsObject).length > 0) {
      return Object.keys(factorsObject).map((factorType: FACTORS_TO_SHOW) =>
        renderItem(factorType)
      );
    }
  };

  return (
    <div className="factor-list">
      <RadioGroup name="factorList" onChange={handleFactorSelect}>
        {renderFactors()}
      </RadioGroup>
    </div>
  );
};

export default FactorList;
