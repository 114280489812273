import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/platform/lib/components/dialog";
import { Button } from "@natera/platform/lib/components/form";
import "./factorList.scss";

const messages = defineMessages({
  factorDisableMessageTitle: {
    id: "factorDisableMessageTitle",
    defaultMessage:
      "Are you sure you want to turn off two - factor authentication?",
  },
  factorDisableMessage: {
    id: "factorDisableMessage",
    defaultMessage:
      "Please confirm you want to turn off two - factor authentication.",
  },
  factorDisableCancelButton: {
    id: "factorDisableCancelButton",
    defaultMessage: "Cancel",
  },
  factorDisableSubmitButton: {
    id: "factorDisableSubmitButton",
    defaultMessage: "Turn Off",
  },
});

interface FactorDisableDialogProps {
  onClose: () => void;
  onSubmit: () => void;
}

const FactorDisableModal: FC<FactorDisableDialogProps> = ({
  onClose,
  onSubmit,
}) => {
  const intl = useIntl();

  return (
    <Dialog
      title={intl.formatMessage(messages.factorDisableMessageTitle)}
      className="factor-disable-modal"
      actions={
        <div className="factor-disable-modal__buttons">
          <Button outlined onClick={onClose}>
            {intl.formatMessage(messages.factorDisableCancelButton)}
          </Button>
          <Button raised onClick={onSubmit}>
            {intl.formatMessage(messages.factorDisableSubmitButton)}
          </Button>
        </div>
      }
      onClose={onClose}
      showCrossButton
    >
      <span className="factor-disable-modal__text">
        {intl.formatMessage(messages.factorDisableMessage)}
      </span>
    </Dialog>
  );
};

export default FactorDisableModal;
