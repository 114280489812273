import React, { FC } from "react";
import Neva from "@app/neva";
import { NevaProvider } from "@app/neva/provider";

export interface NevaWrapperProps {
  hideIfResultViewed?: boolean;
}

export const NevaWrapper: FC<NevaWrapperProps> = ({
  hideIfResultViewed = false,
}) => {
  return (
    <NevaProvider hideIfResultViewed={hideIfResultViewed}>
      <Neva />
    </NevaProvider>
  );
};
