import React, {
  FC,
  KeyboardEvent,
  MouseEvent,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import classNames from "classnames";
import { hasAttributeValue } from "@app/neva/helpers";
import {
  AttributeNames,
  AttributeValues,
  KeyTypes,
  QuickReplyType,
} from "@app/neva/models";
import "./quickReply.scss";
import { EventsContext } from "@app/neva/provider";
import { generatePostbackUserEvent } from "@app/neva/helpers";

interface Props {
  replies: QuickReplyType[];
}

const QuickReply: FC<Props> = ({ replies }) => {
  const { userEventHandler, textMessageRef, quickReplyRef } = useContext(
    EventsContext
  );
  const [showAnimation, setShowAnimation] = useState(false);
  const [showReplies, setShowReplies] = useState(true);
  const [hideReplies, setHideReplies] = useState(false);

  useImperativeHandle(quickReplyRef, () => ({
    removeQuickReplies() {
      setTimeout(() => {
        setShowReplies(false);
        setHideReplies(true);
      }, 650);
      setShowAnimation(true);
    },
  }));

  const quickReplyButtonClasses = (payload: string) => {
    const hasQRSecondaryType = hasAttributeValue(
      payload,
      AttributeNames.QUICK_REPLY_TYPE,
      AttributeValues.SECONDARY
    );

    return classNames("quick-reply__button", {
      "quick-reply__button-secondary": hasQRSecondaryType,
    });
  };

  const quickReplyContainerClasses = classNames("quick-reply__list-container", {
    "hidden-reply": !showReplies,
    "without-reply": hideReplies,
    "animation-reply": showAnimation,
  });

  const onClickHandler = (
    e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
    reply: QuickReplyType
  ) => {
    e.currentTarget.disabled = true;

    const hasUpdateMessages = hasAttributeValue(
      reply.payload,
      AttributeNames.UPDATE_MESSAGES,
      AttributeValues.FALSE
    );

    const handlePostbackEvent = () => {
      userEventHandler(
        generatePostbackUserEvent({
          title: reply.title,
          payload: reply.payload,
        })
      );
    };

    if (hasUpdateMessages) {
      e.currentTarget.disabled = false;
      handlePostbackEvent();
    } else {
      setTimeout(() => {
        setShowReplies(false);
        handlePostbackEvent();
        textMessageRef?.current?.disableRatingAndDropdown();
      }, 650);
      setShowAnimation(true);
    }
  };

  const onKeyDownHandler = (
    e: KeyboardEvent<HTMLButtonElement>,
    reply: QuickReplyType
  ) => {
    const currentKey = e.key.toLowerCase();
    if (currentKey === KeyTypes.ENTER) {
      e.preventDefault();
      onClickHandler(e, reply);
    }
  };

  return (
    <ul className={quickReplyContainerClasses}>
      {replies.map((reply, index) => (
        <li key={index} className="quick-reply__list-item">
          <button
            data-testid="quick-reply"
            className={quickReplyButtonClasses(reply.payload)}
            onClick={(e) => onClickHandler(e, reply)}
            onKeyDown={(e) => onKeyDownHandler(e, reply)}
          >
            <span className="quick-reply__button-title">{reply.title}</span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default QuickReply;
