import React from "react";

import { DrawRequestContext } from "@app/provider";
import TimeslotsForm, {
  TimeslotsFormData,
} from "@app/components/forms/timeslotsForm/timeslotsForm";

import "./modify.scss";
import { useHistory, useParams } from "react-router-dom";
import { DrawRequestTimeslots } from "@app/provider/drawRequest";
import { routes } from "@app/routing";
import { defineMessages, useIntl } from "react-intl";
import { StepperContent } from "@app/components/ui/layout";

const messages = defineMessages({
  mobilePhlebotomyTimeslotsTitle: {
    id: "mobilePhlebotomyTimeslotsTitle",
    defaultMessage: "Mobile Blood Draw",
  },
  mobilePhlebotomyTimeslotsDescriptionTitle: {
    id: "mobilePhlebotomyTimeslotsDescriptionTitle",
    defaultMessage: "When are some good times for your blood draw?",
  },
  mobilePhlebotomyTimeslotsDescription: {
    id: "mobilePhlebotomyTimeslotsDescription",
    defaultMessage:
      "Please select three time slots across three different dates to ensure that we can match you with an appointment.",
  },
  modifySubmit: {
    id: "modifySubmit",
    defaultMessage: "Submit",
  },
});

const ModifyMobilePhlebotomy: React.FC = () => {
  const intl = useIntl();
  const { orderUid, testUid } = useParams<{
    orderUid: string;
    testUid: string;
  }>();
  const history = useHistory();
  const {
    updateDrawRequest,
    isLoading,
    openUpdateDrawErrorDialog,
  } = React.useContext(DrawRequestContext);

  const goBack = () => history.goBack();

  const handleSubmit = async (data: TimeslotsFormData) => {
    try {
      await updateDrawRequest({
        timeZone: data.timeZone,
        timeSlots: data.timeSlots as DrawRequestTimeslots,
      });
      history.push(routes.testDetailsPage(orderUid, testUid));
    } catch {
      openUpdateDrawErrorDialog();
    }
  };

  return (
    <div className="modify_mp_page">
      <StepperContent
        title={intl.formatMessage(messages.mobilePhlebotomyTimeslotsTitle)}
        subTitle={intl.formatMessage(
          messages.mobilePhlebotomyTimeslotsDescriptionTitle
        )}
        description={intl.formatMessage(
          messages.mobilePhlebotomyTimeslotsDescription
        )}
      >
        <TimeslotsForm
          handleBack={goBack}
          handleConfirm={handleSubmit}
          confirmButtonLabel={intl.formatMessage(messages.modifySubmit)}
          isLoading={isLoading}
          isModify
        />
      </StepperContent>
    </div>
  );
};

export default ModifyMobilePhlebotomy;
