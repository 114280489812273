import React, { FC, createContext } from "react";
import { useLazyQuery, useMutation, ApolloError } from "@apollo/client";
import { ResultCodes } from "@app/service/resultCodes";
import UserService from "@app/service/user";
import { Language } from "./intlWrapper";
import { ErrorBody } from "@app/provider/user";

export interface ResetPasswordData {
  email?: string;
}

export interface ResetPasswordController {
  resetPassword: (email: string) => Promise<ResetPasswordData | undefined>;
  resetPasswordIsLoading: boolean;
  resetPasswordData?: ResetPasswordData | null;
  resetPasswordError?: string;
  checkResetToken: (token: string) => void;
  resetTokenData?: { email: string; language?: Language };
  resetTokenError?: string;
  setPassword: (props: { token: string; password: string }) => void;
  setPasswordIsLoading: boolean;
  setPasswordData?: { success: boolean } | null;
  setPasswordError: ApolloError | undefined;
  clearResetPasswordError: () => void;
}

export const Context = createContext<ResetPasswordController>({
  resetPassword: async () => ({}),
  checkResetToken: () => undefined,
  setPassword: async () => undefined,
  resetPasswordIsLoading: false,
  setPasswordIsLoading: false,
  setPasswordError: undefined,
  clearResetPasswordError: () => undefined,
});

Context.displayName = "ResetPasswordContext";

const ResetPasswordProvider: FC = ({ children }) => {
  const [
    resetPassword,
    {
      loading: resetPasswordIsLoading,
      data: resetPasswordData,
      error: resetPasswordErrorData,
    },
  ] = useLazyQuery<{ resetPassword: ResetPasswordData }>(
    UserService.ResetPassword(),
    {
      fetchPolicy: "cache-and-network",
    }
  );

  React.useEffect(() => {
    setResetPasswordError(
      (resetPasswordErrorData?.graphQLErrors[0].extensions
        ?.exception as ErrorBody)?.code as ResultCodes
    );
  }, [resetPasswordErrorData]);

  const [resetPasswordError, setResetPasswordError] = React.useState<
    ResultCodes | undefined
  >(undefined);

  const [
    checkResetToken,
    { data: resetTokenData, error: resetTokenError },
  ] = useLazyQuery<{
    checkResetToken: { email: string; language?: Language };
  }>(UserService.CheckResetToken(), { fetchPolicy: "no-cache" });

  const [
    setPassword,
    {
      loading: setPasswordIsLoading,
      data: setPasswordData,
      error: setPasswordError,
    },
  ] = useMutation<{ setPassword: { success: boolean } }>(
    UserService.SetPassword()
  );

  const handleResetPassword = async (email: string) => {
    const response = await resetPassword({ variables: { email } });
    return response.data?.resetPassword;
  };

  const handleCheckResetToken = (token: string) => {
    checkResetToken({ variables: { token } });
  };

  const handleSetPassword = ({
    token,
    password,
  }: {
    token: string;
    password: string;
  }) => {
    setPassword({ variables: { props: { token, password } } });
  };

  const handleClearResetPasswordError = () => {
    setResetPasswordError(undefined);
  };

  return (
    <Context.Provider
      value={{
        resetPassword: handleResetPassword,
        resetPasswordIsLoading,
        resetPasswordData: resetPasswordData?.resetPassword,
        resetPasswordError,
        checkResetToken: handleCheckResetToken,
        resetTokenData: resetTokenData?.checkResetToken,
        resetTokenError: (resetTokenError?.graphQLErrors[0].extensions
          ?.exception as ErrorBody)?.code,
        setPassword: handleSetPassword,
        setPasswordIsLoading,
        setPasswordData: setPasswordData?.setPassword,
        setPasswordError,
        clearResetPasswordError: handleClearResetPasswordError,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ResetPasswordProvider;
