import React, {
  FC,
  useState,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useApolloClient } from "@apollo/client";

import UserService, { UppUser } from "@app/service/user";
import { IntlContext, ServiceContext } from "@app/provider";

export interface UppAuthController {
  profile: UppUser | null;
  signInByAuthenticationProviderInProgress: boolean;
  startSignInByAuthenticationProvider: () => void;
  endSignInByAuthenticationProvider: () => void;
  setProfile: (profile: UppUser) => void;
  setGuestProfileUid: (uid: string) => void;
  logout: () => Promise<void>;
  signUpEmail: string | null;
  setSignUpEmail: (email: string) => void;
}

export const Context = createContext<UppAuthController>({
  profile: null,
  signInByAuthenticationProviderInProgress: false,
  startSignInByAuthenticationProvider: () => undefined,
  endSignInByAuthenticationProvider: () => undefined,
  logout: async () => undefined,
  setProfile: () => undefined,
  setGuestProfileUid: () => undefined,
  signUpEmail: null,
  setSignUpEmail: () => undefined,
});

Context.displayName = "UppAuthContext";

const UppAuthProvider: FC = ({ children }) => {
  const { sessionService, storageServiceBuilder } = useContext(ServiceContext);
  const { changeLanguage } = useContext(IntlContext);
  const client = useApolloClient();
  const profileStorage = storageServiceBuilder
    .withProfileKey()
    .build<UppUser>();
  const [profile, setProfile] = useState<UppUser | null>(profileStorage.get());
  const [guestProfileUid, setGuestProfileUid] = useState<string | null>(null);
  const [signUpEmail, setSignUpEmail] = useState<string | null>(null);
  const [
    signInByAuthenticationProviderInProgress,
    setSignInByAuthenticationProviderInProgress,
  ] = useState(false);

  const setUser = (user: UppUser) => {
    profileStorage.set(user);
    setProfile(user);
  };

  useEffect(() => {
    if (profile?.language) {
      changeLanguage(profile.language);
    }

    profile?.patientUID ?? guestProfileUid
      ? heap.identify(
          profile?.patientUID ? profile.patientUID : (guestProfileUid as string)
        )
      : heap.resetIdentity();
  }, [profile, guestProfileUid]);

  useEffect(() => {
    const subscription = sessionService.getTokenSubject().subscribe((token) => {
      if (!token) {
        profileStorage.remove();
        setProfile(null);
        return false;
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  const logout = async () => {
    try {
      client.writeQuery({
        query: UserService.loadProfile(),
        data: {
          profile: null,
        },
      });
      await sessionService.logout();
      profileStorage.remove();
      client.cache.reset();
      client.clearStore();
      setProfile(null);
    } catch (error) {
      console.log(error);
    }
  };

  const startSignInByAuthenticationProvider = () =>
    setSignInByAuthenticationProviderInProgress(true);

  const endSignInByAuthenticationProvider = () =>
    setSignInByAuthenticationProviderInProgress(false);

  const uppAuthController: UppAuthController = useMemo(
    () => ({
      profile,
      signInByAuthenticationProviderInProgress,
      startSignInByAuthenticationProvider,
      endSignInByAuthenticationProvider,
      setProfile: setUser,
      setGuestProfileUid,
      logout,
      signUpEmail: signUpEmail,
      setSignUpEmail: setSignUpEmail,
    }),
    [
      profile,
      signInByAuthenticationProviderInProgress,
      startSignInByAuthenticationProvider,
      endSignInByAuthenticationProvider,
      setUser,
      setGuestProfileUid,
      logout,
      signUpEmail,
      setSignUpEmail,
    ]
  );

  return (
    <Context.Provider value={uppAuthController}>{children}</Context.Provider>
  );
};

export default UppAuthProvider;
