import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import "./stepperHeaderProgress.scss";
import { StepperContext } from "@natera/stepper";

const messages = defineMessages({
  stepperHeaderStep: {
    id: "stepperHeaderStep",
    defaultMessage: "Step {number} of {count}",
  },
});

const StepperHeaderProgress: FC = () => {
  const intl = useIntl();
  const { getNumberOfResolvedSteps, getSteps } = React.useContext(
    StepperContext
  );

  return (
    <h2 className="stepper-header-progress">
      {intl.formatMessage(messages.stepperHeaderStep, {
        number: getNumberOfResolvedSteps(),
        count: getSteps().length,
      })}
    </h2>
  );
};

export default StepperHeaderProgress;
