import React from "react";
import { Government, SubSteps } from "./paymentMethod";
import { MultipleInsurance } from "@app/components/ui/inputs/multipleInsurancesSelector/multipleInsurancesSelector";
import {
  EnterOtherInsuranceCompanyStep,
  GovernmentStep,
  InsuranceCostEstimationStep,
  ProvideGovernmentStep,
} from "@app/components/simpleOrder/payment/steps";
import {
  stepsMapper,
  MultipleStep,
  MultipleStepsIds,
  onConfirmType,
} from "./multipleStepsMapper";
import { PredefinedCompanyName } from "@app/components/companySelector/companySelector";

export const getMultipleGovernmentSteps = (
  multipleStepId: MultipleStepsIds,
  initIdx: number,
  multipleStepData: Government,
  onConfirm: onConfirmType
): MultipleStep[] => {
  const otherCompanyStep = !Object.values(PredefinedCompanyName).includes(
    multipleStepData.paymentMethodInfo.companyName as PredefinedCompanyName
  );
  const isSecondMultipleStep = multipleStepId === SubSteps.MULTIPLE_SECOND;
  const confirmSubstepsHandler = () => undefined;

  const governmentSteps: MultipleStep[] = [
    {
      stepId: multipleStepId,
      index: initIdx,
      childComponent: stepsMapper(multipleStepId).multipleStepChildComponent(
        onConfirm
      ),
      data: MultipleInsurance.Government,
    },
    {
      stepId: stepsMapper(multipleStepId).selectGovernmentCompanyStepId,
      index: initIdx + 1,
      childComponent: <GovernmentStep onConfirm={confirmSubstepsHandler} />,
      data: {
        companyName: otherCompanyStep
          ? PredefinedCompanyName.Other
          : multipleStepData.paymentMethodInfo.companyName,
        companyGroupId: otherCompanyStep
          ? undefined
          : multipleStepData.paymentMethodInfo.companyGroupId,
      },
    },
    {
      stepId: stepsMapper(multipleStepId).provideGovernmentInsuranceStepId,
      index: initIdx + 3,
      childComponent: (
        <ProvideGovernmentStep onConfirm={confirmSubstepsHandler} />
      ),
      data: {
        insuranceNumber: multipleStepData.paymentMethodInfo.insuranceNumber,
      },
    },
  ];

  if (otherCompanyStep) {
    governmentSteps.push({
      stepId: stepsMapper(multipleStepId).enterGovernmentOtherCompanyStepId,
      index: initIdx + 2,
      childComponent: (
        <EnterOtherInsuranceCompanyStep
          previousStepId={
            stepsMapper(multipleStepId).selectGovernmentCompanyStepId
          }
        />
      ),
      data: {
        otherCompanyName: multipleStepData.paymentMethodInfo.companyName,
        otherCompanyGroupId: multipleStepData.paymentMethodInfo.companyGroupId,
      },
    });
  }

  if (isSecondMultipleStep) {
    governmentSteps.push({
      stepId: SubSteps.COST_ESTIMATION,
      index: initIdx + 4,
      childComponent: <InsuranceCostEstimationStep onSubmit={onConfirm} />,
      data: {},
    });
  }

  return governmentSteps;
};
