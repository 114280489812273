import React, { FC, createContext } from "react";
import { useMutation } from "@apollo/client";
import KitService from "@app/service/kit";
import { Address } from "./profile";

export interface KitController {
  addKit: (
    orderUid: string,
    testUid: string,
    address: Partial<Address>,
    token?: string
  ) => Promise<boolean>;
  addKitIsLoading: boolean;
}
export const Context = createContext<KitController>({
  addKit: () => Promise.reject(),
  addKitIsLoading: false,
});

Context.displayName = "KitContext";

const KitProvider: FC = ({ children }) => {
  const [addKit, { loading }] = useMutation<{
    placeKitOrder: {
      result: boolean;
    };
  }>(KitService.addKit(), {
    fetchPolicy: "no-cache",
  });

  const handleAddKit = async (
    orderUid: string,
    testUid: string,
    address: Partial<Address>,
    token?: string
  ) => {
    try {
      const result = await addKit({
        variables: {
          orderUid,
          testUid,
          address,
          token,
        },
      });

      return Boolean(result.data?.placeKitOrder?.result);
    } catch (error) {
      return false;
    }
  };

  return (
    <Context.Provider
      value={{
        addKit: handleAddKit,
        addKitIsLoading: loading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default KitProvider;
