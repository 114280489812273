import React, { FC, useContext } from "react";

import { AuthProvider } from "@natera/platform/lib/provider/auth";
import { Context as ServiceContext } from "@app/provider/service";

const AuthProviderWrapper: FC = ({ children }) => {
  const { sessionService } = useContext(ServiceContext);

  return (
    <AuthProvider sessionService={sessionService}>{children}</AuthProvider>
  );
};

export default AuthProviderWrapper;
