import { IDP_TYPE } from "@app/service/user";
import Cookies from "universal-cookie";

const cookies = new Cookies(null, { path: "/" });

export const saveLoginMethodToCookie = (loginMethod: IDP_TYPE) => {
  const existingCookies: IDP_TYPE[] = cookies.get("loginMethod")
    ? cookies.get("loginMethod")
    : [];

  existingCookies.push(loginMethod);

  const uniqLoginMethodCookies = new Set(existingCookies);

  cookies.set(
    "loginMethod",
    JSON.stringify(Array.from(uniqLoginMethodCookies))
  );
};

export const getLoginMethodFromCookie = () => {
  const loginMethod: IDP_TYPE[] = cookies.get("loginMethod") ?? [];

  if (loginMethod.length !== 1) {
    return undefined;
  }

  return loginMethod[0];
};
