import classNames from "classnames";
import R from "ramda";
import React from "react";
import "./heapPHIDecorator.scss";

interface Props {
  protectText?: boolean;
  protectAttr?: string[];
  noWrap?: boolean;
  inline?: boolean;
}

interface HeapRedactData {
  "data-heap-redact-attributes"?: string;
  "data-heap-redact-text"?: string;
}

export const HeapPHIDecorator: React.FC<React.PropsWithChildren<Props>> = ({
  protectText = true,
  protectAttr = [],
  noWrap = false,
  inline = false,
  children,
}) => {
  const data: HeapRedactData = R.reject(R.isNil, {
    "data-heap-redact-attributes": protectAttr.join(",") || null,
    "data-heap-redact-text": protectText ? String(protectText) : null,
  });

  if (noWrap) {
    const elements = React.Children.map(children, (child: React.ReactElement) =>
      React.cloneElement<HeapRedactData>(child, data)
    );
    return <>{elements}</>;
  }

  return (
    <div
      className={classNames("heap-phi-decorator", {
        "heap-phi-decorator--inline": inline,
      })}
      {...data}
    >
      {children}
    </div>
  );
};
