import React, { FC, KeyboardEvent, useContext, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  Menu,
  MenuItem,
  Corner,
  MenuController,
} from "@natera/material/lib/menu";
import { IconButton } from "@natera/material/lib/button";
import PrevIconSvg from "@app/neva/assets/svg/prev-icon.svg";
import PersistentMenuSvg from "@app/neva/assets/svg/persistent-menu.svg";
import "./header.scss";
import { APIContext, DialogsContext, EventsContext } from "@app/neva/provider";
import { CallToActionType } from "@app/neva/models/apiControllerModel";
import {
  generatePostbackUserEvent,
  generatePostbackUserEventWithType,
} from "@app/neva/helpers";
import {
  ButtonTypes,
  iframeDefaultConfig,
  KeyTypes,
  PostMessageEventTypes,
} from "@app/neva/models";
import { IntlContext } from "@app/provider";
import { useUrlActivityEventSender } from "@app/neva/hooks";

const messages = defineMessages({
  persistentMenuButtonLabel: {
    id: "persistentMenuButtonLabel",
    defaultMessage: "Open menu",
  },
  backButtonLabel: {
    id: "backButtonLabel",
    defaultMessage: "Back to session",
  },
});

const Header: FC = () => {
  const intl = useIntl();
  const { eventsProviderState, userEventHandler, textMessageRef } = useContext(
    EventsContext
  );
  const {
    iframeSettings: {
      opportunityToManualClose,
      isShowIframe,
      isQuestionnaireIframeOpened,
      widgetIframeSource,
    },
    setIframeSettings,
  } = useContext(DialogsContext);
  const isShowBackButton = useMemo(
    () => opportunityToManualClose && isShowIframe,
    [opportunityToManualClose, isShowIframe]
  );
  const {
    apiProviderState: { widgetSettings },
  } = useContext(APIContext);
  const { currentLanguage } = useContext(IntlContext);
  const { sendActivityEventFromUrl } = useUrlActivityEventSender();
  const settings = widgetSettings?.languageConfigs[currentLanguage];
  const headerRef = React.useRef<HTMLHeadElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = (item: CallToActionType) => {
    const { type, url } = item;
    switch (type) {
      case ButtonTypes.WEB_URL:
        sendActivityEventFromUrl(url);
        handleWebUrl(url);
        break;
      case ButtonTypes.POSTBACK:
        setIframeSettings(iframeDefaultConfig);
        userEventHandler(
          generatePostbackUserEvent({
            title: item.title,
            payload: item.payload,
          })
        );
        textMessageRef?.current?.disableRatingAndDropdown();
        break;
      default:
        userEventHandler(
          generatePostbackUserEventWithType(
            { title: item.title, payload: item.payload },
            item.type
          )
        );
        break;
    }
  };

  const backButtonClickHandler = () => {
    if (isQuestionnaireIframeOpened && widgetIframeSource) {
      setIframeSettings((prevState) => ({
        ...prevState,
        isQuestionnaireIframeOpened: false,
      }));

      widgetIframeSource.postMessage(
        {
          type: PostMessageEventTypes.QUESTIONNAIRE_IFRAME_STATE,
          isQuestionnaireIframeOpened: false,
        },
        "*" as WindowPostMessageOptions
      );
    } else {
      setIframeSettings((prevState) => ({
        ...prevState,
        isLoading: true,
        isOpenIframe: false,
        isShowIframe: false,
      }));
    }
  };

  const handleWebUrl = (url: string) => {
    setIframeSettings(iframeDefaultConfig);
    setTimeout(() => {
      setIframeSettings((prevState) => ({
        ...prevState,
        isOpenIframe: true,
        url,
      }));
    }, 0);
  };

  const handleKeyboardClick = (
    e: KeyboardEvent<HTMLLIElement>,
    item: CallToActionType
  ) => {
    const currentKey = e.key.toLowerCase();
    if (currentKey === KeyTypes.ENTER || currentKey === KeyTypes.SPACE) {
      handleMenuClick(item);
    }
  };

  const menu = (
    <>
      {eventsProviderState.persistentMenu?.callToActions.map((callToAction) => (
        <MenuItem
          key={callToAction.key}
          data-testid="menu-item"
          onClick={() => handleMenuClick(callToAction)}
          onKeyDown={(e) => handleKeyboardClick(e, callToAction)}
        >
          {callToAction.title}
        </MenuItem>
      ))}
    </>
  );

  const rightHolderClick = () => {
    isMenuOpen && headerRef.current?.click();
  };

  return (
    <header ref={headerRef} className="widget-header">
      <div className="widget-header-wrapper">
        <div className="widget-header__left-holder">
          {isShowBackButton && (
            <IconButton
              data-testid="menu-back-button"
              onClick={backButtonClickHandler}
              aria-label={intl.formatMessage(messages.backButtonLabel)}
            >
              {PrevIconSvg}
            </IconButton>
          )}
        </div>
        <div className="widget-header__bot-info-holder">
          <div className="widget-header__text-container">
            <h1 className="widget-header__title">{settings?.widgetName}</h1>
            <div className="widget-header__sub-title">
              {settings?.widgetSubName}
            </div>
          </div>
        </div>
        <div
          data-testid="right-holder"
          className="widget-header__right-holder"
          onClick={rightHolderClick}
        >
          <Menu
            menu={menu}
            floating={false}
            corner={Corner.BOTTOM_START}
            onOpen={() => setIsMenuOpen(true)}
            onClose={() => setIsMenuOpen(false)}
          >
            {(controller: MenuController) => (
              <IconButton
                data-testid="menu-button"
                className="widget-header__menu-button"
                onClick={controller.toggleMenu}
                aria-label={intl.formatMessage(
                  messages.persistentMenuButtonLabel
                )}
              >
                {PersistentMenuSvg}
              </IconButton>
            )}
          </Menu>
        </div>
        <div className="widget-header__fix-holder"></div>
      </div>
    </header>
  );
};

export default Header;
