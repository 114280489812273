import React, { FC } from "react";
import { LinkHeap } from "@app/components";
import Svg from "@natera/material/lib/svg";
import { HeapEventProps } from "@app/components/linkHeap/linkHeap";
import "./listItem.scss";

interface ListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  to: string | { pathname: string };
  icon?: string;
  target?: string;
  rel?: string;
  activePrefix?: string;
  isMobileMenu?: boolean;
  heapEventName?: string;
  heapEventProps?: HeapEventProps;
}
const ListItem: FC<ListItemProps> = ({
  className,
  children,
  activePrefix,
  to,
  icon,
  target,
  rel,
  isMobileMenu,
  heapEventName,
  heapEventProps,
  ...props
}) => {
  return (
    <li className="list-item">
      <LinkHeap
        target={target}
        rel={rel}
        to={to}
        heapEventName={heapEventName}
        heapEventProps={heapEventProps}
      >
        <div className="list-item__card">
          <span className="list-item__icon">
            {icon && <Svg content={icon} />}
          </span>

          <span className="list-item__text">{props.title}</span>
          <p className="list-item__description">{children}</p>
        </div>
      </LinkHeap>
    </li>
  );
};

export default ListItem;
