import {
  convertSampleCollectionDate,
  getEstimatedResultsDeliveryDate,
  isConnectPortal,
  isOncologyPortal,
  isOrganHealthPortal,
} from "@app/utils";
import { IntlShape } from "react-intl";
import { messages } from "../messages";
import {
  ManagedBy,
  SampleType,
  TestCard,
  TestCardStatus,
  TestStatus,
  TestType,
} from "../types";
import { SimpleOrderWorkflowState } from "@app/provider/order";
import { isHorizonOrPanorama } from "@app/utils/orderHelpers";
import { Utils } from "@natera/material/lib/date";

export const getDescriptionForEmpowerTest = (
  intl: IntlShape,
  testCard: TestCard
): string | undefined => {
  if (
    testCard.sampleType === SampleType.SALIVA &&
    testCard.managedBy === ManagedBy.NATERA
  ) {
    return intl.formatMessage(messages.testCardDescSubmitKit);
  } else if (
    testCard.sampleType === SampleType.BLOOD &&
    testCard.managedBy === ManagedBy.NATERA
  ) {
    return intl.formatMessage(messages.testCardBloodDrawTestOrdered);
  } else if (testCard.managedBy === ManagedBy.CLINIC) {
    return intl.formatMessage(messages.testCardDescOrderedConnect);
  }
};

export const getDescription = (
  intl: IntlShape,
  testCard: TestCard
): string | undefined => {
  switch (testCard.status) {
    case TestCardStatus.UPCOMING_TEST:
      return getDescriptionForUpcomingTest(intl, testCard);
    case TestCardStatus.TEST_ORDERED:
      if (testCard.testType === TestType.EMPOWER) {
        return getDescriptionForEmpowerTest(intl, testCard);
      }
      return getDescriptionForTestOrdered(intl, testCard);
    case TestCardStatus.KIT_SHIPPED:
      return getDescriptionForKitShipped(intl, testCard);
    case TestCardStatus.APPT_REQUESTED:
      return intl.formatMessage(messages.testCardDescApptRequested);
    case TestCardStatus.APPT_CONFIRMED:
      return getDescriptionForAppointmentConfirmed(intl, testCard);
    case TestCardStatus.SAMPLE_RECEIVED:
      return getDescriptionForSampleReceived(intl, testCard);
    case TestCardStatus.SAMPLES_RECEIVED:
      return getDescriptionForSamplesReceived(intl, testCard);
    case TestCardStatus.RESULT_READY:
      return getDescriptionForResultReady(intl, testCard);
    case TestCardStatus.TEST_COMPLETE:
      return getDescriptionForTestComplete(intl, testCard);
    case TestCardStatus.CANCELLED_TEST:
      return intl.formatMessage(messages.testCardDescCancelled);
    case TestCardStatus.EXPIRED_TEST:
      return intl.formatMessage(messages.testCardDescExpired);
  }
};

const getDescriptionForUpcomingTest = (
  intl: IntlShape,
  testCard: TestCard
): string => {
  if (isOncologyPortal(testCard.testType)) {
    return getDescriptionForOncologyUpcomingTest(intl, testCard);
  }
  if (testCard.managedBy === ManagedBy.NATERA) {
    if (testCard.isProReach) {
      return intl.formatMessage(messages.testProsperaCardDescOrderedNatera);
    } else {
      return intl.formatMessage(messages.testCardBloodDrawTestOrdered);
    }
  }
  return intl.formatMessage(messages.testCardDescOrderedClinic);
};

const getDescriptionForTestOrdered = (
  intl: IntlShape,
  testCard: TestCard
): string | undefined => {
  if (isOrganHealthPortal(testCard.testType)) {
    return getDescriptionForOrganHealthTestOrdered(intl, testCard);
  }

  if (isConnectPortal(testCard.testType)) {
    return getDescriptionForConnectTestOrdered(intl, testCard);
  }
  if (isOncologyPortal(testCard.testType)) {
    return getDescriptionForOncologyTestOrdered(intl, testCard);
  }
};

const getDescriptionForKitShipped = (
  intl: IntlShape,
  testCard: TestCard
): string | undefined => {
  if (testCard.sampleType === SampleType.BLOOD) {
    return intl.formatMessage(messages.testCardDescShipped);
  }
  if (testCard.sampleType === SampleType.SALIVA) {
    return intl.formatMessage(messages.testCardDescSubmitKit);
  }
  return undefined;
};

const getDescriptionForAppointmentConfirmed = (
  intl: IntlShape,
  testCard: TestCard
): string | undefined => {
  if (isOncologyPortal(testCard.testType) && !testCard.isSignateraSubsequent) {
    return intl.formatMessage(messages.testCardOncologyApptConfirmedDesc);
  }
  return intl.formatMessage(messages.testCardDescApptConfirmed);
};

const getDescriptionForSampleReceived = (
  intl: IntlShape,
  testCard: TestCard
): string | undefined => {
  if (
    [
      TestType.EMPOWER,
      TestType.HORIZON,
      TestType.PANORAMA,
      TestType.RENASIGHT,
      TestType.PROSPERA,
      TestType.VISTARA,
    ].includes(testCard.testType) &&
    testCard.sampleReceivedDate
  ) {
    const estimatedResultsDeliveryDate = Utils.getUSLocaleDate(
      getEstimatedResultsDeliveryDate(
        testCard,
        new Date(testCard.sampleReceivedDate)
      )
    );
    return intl.formatMessage(messages.testCardDescSampleReceived, {
      date: estimatedResultsDeliveryDate,
    });
  }
  return intl.formatMessage(messages.testCardDescSample);
};

const getDescriptionForSamplesReceived = (
  intl: IntlShape,
  testCard: TestCard
): string | undefined => {
  if (testCard.sampleReceivedDate) {
    const estimatedResultsDeliveryDate = Utils.getUSLocaleDate(
      getEstimatedResultsDeliveryDate(
        testCard,
        new Date(testCard.sampleReceivedDate)
      )
    );
    if (testCard.testType === TestType.ALTERA) {
      return intl.formatMessage(messages.testCardDescSampleReceived, {
        date: estimatedResultsDeliveryDate,
      });
    }
    if (testCard.testType === TestType.SIGNATERA) {
      const estimatedResultsDeliveryDateSubsequentDate = Utils.getUSLocaleDate(
        getEstimatedResultsDeliveryDate(
          testCard,
          new Date(testCard.sampleReceivedDate),
          true
        )
      );
      return intl.formatMessage(messages.testCardDescSignateraSamplesReceived, {
        date: estimatedResultsDeliveryDate,
        subsequentDate: estimatedResultsDeliveryDateSubsequentDate,
      });
    }
  }
};

const getDescriptionForOrganHealthTestOrdered = (
  intl: IntlShape,
  testCard: TestCard
): string => {
  if (testCard.managedBy !== ManagedBy.NATERA) {
    return intl.formatMessage(messages.testCardDescOrderedClinic);
  }

  if (
    testCard.testType === TestType.RENASIGHT &&
    testCard.sampleType === SampleType.BLOOD
  ) {
    return intl.formatMessage(messages.testCardBloodDrawTestOrdered);
  }

  return intl.formatMessage(messages.testCardDescOrderedNatera);
};

const getDescriptionForConnectTestOrdered = (
  intl: IntlShape,
  testCard: TestCard
): string => {
  if (
    testCard.simpleOrderWorkflowState &&
    isHorizonOrPanorama(testCard.testType) &&
    testCard.managedBy === ManagedBy.CLINIC &&
    ((testCard.simpleOrderWorkflowState ===
      SimpleOrderWorkflowState.PATIENT_ACCESSED &&
      testCard.isOrderInSignedOrdersTable) ||
      [
        SimpleOrderWorkflowState.PATIENT_SIGNED,
        SimpleOrderWorkflowState.KIT_ORDERED,
        SimpleOrderWorkflowState.COMPLETED,
      ].includes(testCard.simpleOrderWorkflowState))
  ) {
    return intl.formatMessage(messages.testCardDescOrderedConnectPatientSigned);
  }
  if (testCard.nextStatus === TestCardStatus.SAMPLE_RECEIVED) {
    return intl.formatMessage(messages.testCardDescOrderedConnect);
  }
  return intl.formatMessage(messages.testCardDescOrderedConnectDraft);
};

const getDescriptionForOncologyTestOrdered = (
  intl: IntlShape,
  testCard: TestCard
): string | undefined => {
  if (testCard.managedBy === ManagedBy.CLINIC) {
    return intl.formatMessage(
      messages.testCardOncologyManagedByClinicTestOrdered
    );
  }
  if (testCard.managedBy === ManagedBy.NATERA) {
    return intl.formatMessage(
      messages.testCardOncologyManagedByNateraTestOrdered
    );
  }
};

const getDescriptionForOncologyUpcomingTest = (
  intl: IntlShape,
  testCard: TestCard
): string => {
  if (testCard.testType === TestType.SIGNATERA) {
    if (testCard.managedBy === ManagedBy.CLINIC) {
      return testCard.isSignateraSubsequent
        ? intl.formatMessage(
            messages.testCardSignateraOrderedByClinicSubsequentTestDesc
          )
        : intl.formatMessage(
            messages.testCardSignateraOrderedByClinicFirstTestDesc
          );
    }
    if (
      testCard.managedBy === ManagedBy.NATERA &&
      testCard.sampleCollectionDate
    ) {
      return intl.formatMessage(
        messages.testCardOncologyManagedByNateraTestOrderedSampleCollectionDate,
        {
          sampleCollectionDate: convertSampleCollectionDate(
            testCard.sampleCollectionDate.toString()
          ),
        }
      );
    }
  }
  return intl.formatMessage(
    messages.testCardOncologyManagedByNateraTestOrdered
  );
};

const getDescriptionForResultReady = (
  intl: IntlShape,
  testCard: TestCard
): string => {
  const resultReadyReviewMessageTestTypes = [
    TestType.PROSPERA,
    TestType.HORIZON,
    TestType.PANORAMA,
    TestType.VISTARA,
    TestType.EMPOWER,
  ];

  if (resultReadyReviewMessageTestTypes.includes(testCard.testType)) {
    return intl.formatMessage(messages.testCardDescResultReadyReview);
  }
  return intl.formatMessage(messages.testCardDescResultReady);
};

const getDescriptionForTestComplete = (
  intl: IntlShape,
  testCard: TestCard
): string => {
  if (testCard.viewStatus === TestStatus.VIEW_WITH_PHYSICIAN) {
    return intl.formatMessage(messages.testCardDescComplete);
  }
  return intl.formatMessage(messages.testCardDescResultReadyReview);
};
