import R from "ramda";

interface PatientAddress {
  uid: string;
  countryCode: string | null;
  city: string | null;
  state: string | null;
  street1: string | null;
  street2: string | null;
  zipCode: string | null;
}

export const checkFirstEmptyAddress = (
  addresses: PatientAddress[]
): boolean => {
  if (addresses?.length === 1) {
    const { uid, ...addressWithoutUid } = addresses[0];

    return R.whereEq({
      city: null,
      countryCode: null,
      state: null,
      street1: null,
      street2: null,
      zipCode: null,
    })(addressWithoutUid);
  }
  return false;
};
