import { ConfigContext, IntlContext, Language } from "@app/provider";
import React, { useMemo, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { VideoType } from "@app/components/videoPlayer";
import useIsMobileDimension from "./useIsMobileDimension";

const messages = defineMessages({
  salivaTitle: {
    id: "videoSalivaTitle",
    defaultMessage: "Saliva Sample Collection",
  },
  salivaThumbnailAltText: {
    id: "videoSalivaThumbnailAltText",
    defaultMessage: "Saliva collection instruction video",
  },
  salivaDescriptionTitle: {
    id: "videoSalivaDescriptionTitle",
    defaultMessage:
      "Please watch this short video before you collect your saliva.",
  },
  salivaDescriptionText: {
    id: "videoSalivaDescriptionText",
    defaultMessage: "Watch this short video to collect your sample properly.",
  },
  horizonTitle: {
    id: "horizonTitle",
    defaultMessage: "About Your Test",
  },
  simpleOrderDescriptionTitle: {
    id: "simpleOrderDescriptionTitle",
    defaultMessage: "Learn about your test included in this order",
  },
  simpleOrderDescriptionText: {
    id: "simpleOrderDescriptionText",
    defaultMessage:
      "When it comes to deciding about testing for hereditary genetic conditions or other genetic conditions like Down Syndrome, you have lots of options. The video {location} was designed to review the different choices you can make.",
  },
  simpleOrderDescriptionTextLocationAbove: {
    id: "simpleOrderDescriptionTextLocationAbove",
    defaultMessage: "above",
  },
  simpleOrderDescriptionTextLocationBelow: {
    id: "simpleOrderDescriptionTextLocationBelow",
    defaultMessage: "below",
  },
  simpleOrderHorizonVideoThumbnailAltText: {
    id: "simpleOrderHorizonVideoThumbnailAltText",
    defaultMessage: "Informational video about Horizon test",
  },
  simpleOrderPanoramaVideoThumbnailAltText: {
    id: "simpleOrderPanoramaVideoThumbnailAltText",
    defaultMessage: "Informational video about Panorama test",
  },
  nextVideoBtnTitle: {
    id: "nextVideoBtnTitle",
    defaultMessage: "Watch next video",
  },
});

export interface VideoController {
  getVideoInfo: (videoType: VideoType | string) => VideoParam;
}

export interface VideoParam {
  pageTitle: string;
  videoDescTitle: string;
  videoDescText: string;
  videoHashId: string;
  thumbnailAltText: string;
  posterLink?: string;
  skip?: boolean;
  schedule?: boolean;
  next?: boolean;
}

const useVideo = (): VideoController => {
  const intl = useIntl();
  const { config } = React.useContext(ConfigContext);
  const { currentLanguage } = React.useContext(IntlContext);
  const isMobile = useIsMobileDimension();

  const getSimpleOrderDescriptionText = (isMobile: boolean) =>
    intl.formatMessage(messages.simpleOrderDescriptionText, {
      location: isMobile
        ? intl.formatMessage(messages.simpleOrderDescriptionTextLocationAbove)
        : intl.formatMessage(messages.simpleOrderDescriptionTextLocationBelow),
    });

  const [simpleOrderDescriptionText, setSimpleOrderDescriptionText] = useState(
    getSimpleOrderDescriptionText(isMobile)
  );

  useEffect(() => {
    setSimpleOrderDescriptionText(getSimpleOrderDescriptionText(isMobile));
  }, [isMobile, currentLanguage]);

  const videoParams = useMemo(
    () => ({
      [VideoType.SALIVA]: {
        pageTitle: intl.formatMessage(messages.salivaTitle),
        thumbnailAltText: intl.formatMessage(messages.salivaThumbnailAltText),
        videoDescTitle: intl.formatMessage(messages.salivaDescriptionTitle),
        videoDescText: intl.formatMessage(messages.salivaDescriptionText),
        videoHashId:
          currentLanguage == Language.EN
            ? config.videoHashId.SALIVA.EN
            : config.videoHashId.SALIVA.ES,
        posterLink:
          "https://content.cdntwrk.com/mediaproxy?url=https%3A%2F%2Fembed-ssl.wistia.com%2Fdeliveries%2F0aa27afa20dcbf46b1a1e6bdebadadee4be455d8.jpg&size=1&version=1658947470&sig=be2827bd59a39ead0d3d11c2e16f82d1&default=hubs%2Ftilebg-videos.jpg",
      },
      [VideoType.HORIZON]: {
        pageTitle: intl.formatMessage(messages.horizonTitle),
        thumbnailAltText: intl.formatMessage(
          messages.simpleOrderHorizonVideoThumbnailAltText
        ),
        videoDescTitle: intl.formatMessage(
          messages.simpleOrderDescriptionTitle
        ),
        videoDescText: simpleOrderDescriptionText,
        videoHashId:
          currentLanguage == Language.EN
            ? config.videoHashId.HORIZON.EN
            : config.videoHashId.HORIZON.ES,
        next: true,
      },
      [VideoType.PANORAMA]: {
        pageTitle: intl.formatMessage(messages.horizonTitle),
        thumbnailAltText: intl.formatMessage(
          messages.simpleOrderPanoramaVideoThumbnailAltText
        ),
        videoDescTitle: intl.formatMessage(
          messages.simpleOrderDescriptionTitle
        ),
        videoDescText: simpleOrderDescriptionText,
        videoHashId:
          currentLanguage == Language.EN
            ? config.videoHashId.PANORAMA.EN
            : config.videoHashId.PANORAMA.ES,
        next: true,
      },
    }),
    [simpleOrderDescriptionText, intl.locale]
  );

  const getVideoInfo = (videoType: VideoType) => {
    const videoParam: VideoParam = videoParams[videoType];

    return videoParam;
  };

  const controller = {
    getVideoInfo,
  };

  return controller;
};

export default useVideo;
