import { useContext } from "react";
import { DialogsContext } from "@app/neva/provider";
import { KeyTypes } from "@app/neva/models";
import { useLogEvent, useUrlActivityEventSender } from "@app/neva/hooks";
import { ExternalLinkIcon } from "@app/neva/helpers";
import { defineMessages, useIntl } from "react-intl";

type UseLinkHandlerType = {
  linkEditorListener: (
    output: React.RefObject<HTMLDivElement>,
    onClose?: () => void,
    shouldAddExternalLinkIcon?: boolean
  ) => void;
};

const messages = defineMessages({
  chatMessageDialogExternalLink: {
    id: "chatMessageDialogExternalLink",
    defaultMessage: "Open the external resource link",
  },
});

export const useLinkHandler = (): UseLinkHandlerType => {
  const {
    chatMessageDialog,
    setIframeSettings,
    patientReviewDialog,
  } = useContext(DialogsContext);
  const { sendActivityEventFromUrl } = useUrlActivityEventSender();
  const intl = useIntl();
  const { logEvent } = useLogEvent();

  const linkEditorListener = (
    output: React.RefObject<HTMLDivElement>,
    onClose?: () => void,
    shouldAddExternalLinkIcon = false
  ) => {
    const links =
      output.current && output.current.querySelectorAll(".linkEdited");
    links &&
      !!links.length &&
      links.forEach((link: HTMLLinkElement) => {
        const extension = JSON.parse(link.getAttribute("data-extension") || "");
        const url = link.getAttribute("data-href") || "";
        const event = link.getAttribute("activity-event") || "";
        if (shouldAddExternalLinkIcon) addExternalLinkIcon(link);
        link.onkeydown = (e) => {
          const currentKey = e.key.toLowerCase();
          if (currentKey === KeyTypes.ENTER || currentKey === KeyTypes.SPACE) {
            e.preventDefault();
            linkClickHandler(extension, url, onClose, event);
          }
        };
        link.onclick = (e) => {
          e.preventDefault();
          linkClickHandler(extension, url, onClose, event);
        };
      });
  };

  const linkClickHandler = (
    extension: boolean,
    url: string,
    onClose?: () => void,
    event?: string
  ) => {
    sendActivityEventFromUrl(url);
    event &&
      logEvent({
        eventAction: event,
        eventLabel: "User Action",
      });
    extension ? handleInternal(url, onClose) : handleExternal(url);
  };

  const handleInternal = (url: string, onClose?: () => void) => {
    const isWebviewUrl = url.includes("/webview");
    const isPatientReviewUrl = url.includes("/patient_review");

    if (isWebviewUrl) {
      chatMessageDialog.open({
        onClose: chatMessageDialog.close,
        url,
      });
    } else if (isPatientReviewUrl) {
      patientReviewDialog.open({
        onClose: patientReviewDialog.close,
      });
    } else {
      onClose?.();
      setIframeSettings((prevStata) => ({
        ...prevStata,
        isOpenIframe: true,
        url,
      }));
    }
  };

  const handleExternal = (url: string) => {
    window.open(url, "_blank");
  };

  const addExternalLinkIcon = (link: HTMLLinkElement) => {
    link.innerHTML = `${link.innerHTML}${ExternalLinkIcon(
      intl.formatMessage(messages.chatMessageDialogExternalLink)
    )}`;
  };

  return { linkEditorListener };
};
