import { DocumentNode, gql } from "@apollo/client";

class Verification {
  public static activation = (): DocumentNode => gql`
    query Activation($verificationData: VerificationDTO!) {
      activation(verificationData: $verificationData) {
        success
      }
    }
  `;
}

export default Verification;
