import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Button } from "@natera/platform/lib/components/form";
import { routes } from "@app/routing";
import { DrawRequestContext } from "@app/provider";
import { AppointmentAddress } from "@app/components/appointmentAddress";
import { AppointmentTimeslots } from "@app/components/appointmentTimeslots";

import "./appointmentTimeConfirmationStep.scss";
import { StepperContent } from "@app/components/ui/layout";

const messages = defineMessages({
  appointmentTimeConfirmationTitle: {
    id: "appointmentTimeConfirmationTitle",
    defaultMessage: "Mobile Blood Draw",
  },
  appointmentTimeConfirmationDescriptionContent: {
    id: "appointmentTimeConfirmationDescriptionContent",
    defaultMessage:
      "The scheduling team will call you to confirm your appointment.",
  },
  appointmentTimeConfirmationModifyAppointment: {
    id: "appointmentTimeConfirmationModifyAppointment",
    defaultMessage: "Change Appointment",
  },
  appointmentTimeConfirmationBackHome: {
    id: "appointmentTimeConfirmationBackHome",
    defaultMessage: "Home",
  },
});

interface Props {
  handleModifyAppointment: () => void;
  onBack?: () => void;
}

const AppointmentTimeConfirmationStep: FC<Props> = ({
  handleModifyAppointment,
  onBack,
}) => {
  const intl = useIntl();
  const { drawRequestData: drawRequest, selectedTimeZone } = useContext(
    DrawRequestContext
  );

  return (
    <div className="stepper__container">
      <StepperContent
        title={intl.formatMessage(messages.appointmentTimeConfirmationTitle)}
        subTitle={intl.formatMessage(
          messages.appointmentTimeConfirmationDescriptionContent
        )}
      >
        <>
          <div className="content">
            {drawRequest && (
              <AppointmentTimeslots
                drawRequest={drawRequest}
                selectedTimeZone={selectedTimeZone}
                isConfirmationStep
              />
            )}
            {drawRequest && (
              <AppointmentAddress
                shippingAddress={drawRequest.shippingAddress}
              />
            )}
          </div>

          <div className="form-buttons">
            <Button outlined onClick={handleModifyAppointment}>
              {intl.formatMessage(
                messages.appointmentTimeConfirmationModifyAppointment
              )}
            </Button>
            {onBack && (
              <Button raised onClick={onBack}>
                {intl.formatMessage(
                  messages.appointmentTimeConfirmationBackHome
                )}
              </Button>
            )}
          </div>
        </>
      </StepperContent>
    </div>
  );
};
export default AppointmentTimeConfirmationStep;
