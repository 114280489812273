import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IntlContext } from "@app/provider";
import { APIContext, NevaContext } from "@app/neva/provider";
import { generateActionUserEvent, hasAttributeValue } from "@app/neva/helpers";
import {
  HandleUserEventInterface,
  HandleUserEventPayloadInterface,
  useApiController,
} from "@app/neva/hooks/useApiController";
import {
  ActionTypes,
  AttributeNames,
  AttributeValues,
  EventDataActionType,
  EventDataMessageType,
  MessageTypes,
  PersistentMenuDTOType,
  UserInputType,
  WidgetClosePopupType,
} from "@app/neva/models";
import { useActionProcessor } from "@app/neva/hooks/useActionProcessor";
import { usePrevious } from "@app/neva/hooks";

export type EventsProviderState = {
  widgetClosePopup: WidgetClosePopupType;
  userInput: UserInputType;
  messages: EventDataMessageType[];
  showTyping: boolean;
  persistentMenu: PersistentMenuDTOType | null;
  passedOrSkippedFlowComponentsId: string[];
  isEndOfConversation: boolean;
};

export interface EventController {
  eventsProviderState: EventsProviderState;
  userEventHandler: (payload: HandleUserEventInterface) => void;
  updateMessagesState: (
    id: string,
    value: string | number,
    target: string
  ) => void;
  textMessageRef: React.RefObject<TextMessageRef> | null;
  quickReplyRef: React.RefObject<QuickReplyRef> | null;
  setPassedOrSkippedFlowComponentsId: (passedComponentId: string) => void;
}

export interface TextMessageRef {
  disableRatingAndDropdown: () => void;
}

export interface QuickReplyRef {
  removeQuickReplies: () => void;
}

export const Context = createContext<EventController>({
  eventsProviderState: {
    widgetClosePopup: {
      isOpen: false,
      shouldShowClosePopupScheduleGCText: false,
    },
    userInput: {
      defaultText: "",
      isInputDisabled: true,
    },
    messages: [],
    showTyping: false,
    persistentMenu: null,
    passedOrSkippedFlowComponentsId: [],
    isEndOfConversation: false,
  },
  textMessageRef: null,
  quickReplyRef: null,
  userEventHandler: () => undefined,
  updateMessagesState: () => undefined,
  setPassedOrSkippedFlowComponentsId: () => undefined,
});

Context.displayName = "EventsContext";

const EventsProvider: FC = ({ children }) => {
  const { currentLanguage } = useContext(IntlContext);
  const { isWidgetOpen } = useContext(NevaContext);
  const {
    apiProviderState: { chatId },
  } = useContext(APIContext);
  const [eventsProviderState, setEventsProviderState] = useState<
    EventsProviderState
  >({
    widgetClosePopup: {
      isOpen: false,
      shouldShowClosePopupScheduleGCText: false,
    },
    userInput: {
      defaultText: "",
      isInputDisabled: true,
    },
    messages: [],
    showTyping: false,
    persistentMenu: null,
    passedOrSkippedFlowComponentsId: [],
    isEndOfConversation: false,
  });
  const { handleUserEvent } = useApiController();
  const { processAction } = useActionProcessor(setEventsProviderState);
  const textMessageRef = useRef<TextMessageRef>(null);
  const quickReplyRef = useRef<QuickReplyRef>(null);
  const isWidgetOpenPrevious = usePrevious<boolean>(isWidgetOpen);

  useEffect(() => {
    if (chatId) {
      userEventHandler(
        generateActionUserEvent(ActionTypes.NATERA_START_CONVERSATION)
      );
    }
  }, [chatId]);

  useEffect(() => {
    if (
      chatId &&
      isWidgetOpen &&
      isWidgetOpen !== isWidgetOpenPrevious &&
      eventsProviderState.isEndOfConversation
    ) {
      userEventHandler(generateActionUserEvent(ActionTypes.OPEN_WIDGET));
    }
  }, [chatId, isWidgetOpen, eventsProviderState.isEndOfConversation]);

  useEffect(() => {
    if (chatId && currentLanguage) {
      userEventHandler(
        generateActionUserEvent(ActionTypes.BOT_LOADER_LANGUAGE_SWITCHED, {
          languageCode: currentLanguage,
        })
      );
      //send QUESTIONNAIRE_SWITCH_LANGUAGE to questionnaire iframe
    }
  }, [currentLanguage]);

  const processMessages = async (messages: EventDataMessageType[]) => {
    for (const message of messages) {
      const { type, duration } = message;

      if (type === MessageTypes.TYPING) {
        setEventsProviderState((prevState) => ({
          ...prevState,
          showTyping: true,
        }));
        duration &&
          (await new Promise((resolve) =>
            setTimeout(resolve, duration * 1000)
          ));
        setEventsProviderState((prevState) => ({
          ...prevState,
          showTyping: false,
        }));
      } else {
        setEventsProviderState((prevState) => ({
          ...prevState,
          messages: [...prevState.messages, message],
        }));
      }
    }
  };

  const processActions = (actions: EventDataActionType[]) => {
    actions.forEach((action) => {
      setTimeout(() => processAction(action), 0);
    });
  };

  const userEventHandler = (userEvent: HandleUserEventInterface) => {
    if (!chatId) {
      return;
    }
    const userEventRequest = Object.assign<
      HandleUserEventPayloadInterface,
      HandleUserEventInterface
    >({ chatId }, userEvent);

    processUserMessage(userEvent);
    handleUserEvent(userEventRequest).then((response) => {
      const { actions, messages } = response;

      actions.length && processActions(actions);
      messages.length && processMessages(messages);
    });
  };

  const processUserMessage = (userEvent: HandleUserEventInterface) => {
    const { message } = userEvent;

    if (message && message.type === "postback") {
      const hasUpdateMessages =
        message.postback?.payload &&
        hasAttributeValue(
          message.postback?.payload,
          AttributeNames.UPDATE_MESSAGES,
          AttributeValues.FALSE
        );

      if (hasUpdateMessages) {
        return;
      }

      const userMessage = {
        id: message.id,
        type: MessageTypes.TEXT,
        text: message.postback?.title,
        isSendByUser: true,
      };
      processMessages([userMessage]);
    }
  };

  const updateMessagesState = (
    id: string,
    value: string | number,
    target: string
  ) => {
    const { messages } = eventsProviderState;

    const newMessages = messages.map((message) => {
      if (message.id === id) {
        (message[target] as EventDataMessageType).selectedValue = value;
        return message;
      } else {
        return message;
      }
    });

    setEventsProviderState((prevState) => ({
      ...prevState,
      messages: newMessages,
    }));
  };

  const setPassedOrSkippedFlowComponentsId = (passedComponentId: string) => {
    setEventsProviderState((prevState) => ({
      ...prevState,
      passedOrSkippedFlowComponentsId: [
        ...prevState.passedOrSkippedFlowComponentsId,
        passedComponentId,
      ],
    }));
  };

  const value = useMemo(
    () => ({
      eventsProviderState,
      userEventHandler,
      updateMessagesState,
      setPassedOrSkippedFlowComponentsId,
      textMessageRef,
      quickReplyRef,
    }),
    [eventsProviderState]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default EventsProvider;
