import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ApolloError,
  useLazyQuery,
  isApolloError,
  useMutation,
} from "@apollo/client";
import * as R from "ramda";
import ProfileService from "@app/service/profile";
import PatientService from "@app/service/patient";
import { NotificationContext, UppAuthContext } from "@app/provider";
import { HeapEventLocation } from "@app/provider/types";
import {
  Address,
  ChannelValue,
  Communication,
  PatientEthnicityDTO,
  PersonalInformation,
  Profile,
  SetupProfileData,
} from "@app/provider/profile/types";
import { SuccessStatusResponse } from "@app/service/user";
import { ErrorBody } from "@app/provider/user";
import { routes } from "@app/routing";
import { useHistory } from "react-router-dom";
import { ResultCodes } from "@app/service/resultCodes";

interface VerifyProfileData {
  isVerified: boolean;
  profile?: Profile;
}

export interface ProfileController {
  isLoading: boolean;
  setupProfileIsLoading: boolean;
  setupProfileData?: Profile | undefined;
  setupProfileError?: string;
  confirmProfileIsLoading: boolean;
  confirmProfileData?: Profile | undefined;
  confirmProfileError?: string;
  getProfileIsLoading: boolean;
  getProfileData?: Profile | undefined;
  getProfileError?: string;
  verifyProfileIsLoading: boolean;
  verifyProfileData?: VerifyProfileData;
  verifyProfileError?: ApolloError;
  verifyProfile: (
    dateOfBirth: string
  ) => Promise<VerifyProfileData | undefined>;
  getProfile: () => Promise<Profile | undefined>;
  setupProfile: (props: SetupProfileData) => Promise<Profile | undefined>;
  confirmProfile: (props: Profile) => Promise<Profile | undefined>;
  profileData?: Profile | undefined;
  profileDataIsLoading: boolean;
  updatePersonalInformation: (
    personalInformation: PersonalInformation
  ) => Promise<Profile | undefined>;
  updatePhone: (phoneNumber: string) => Promise<Profile | undefined>;
  updatePatientIsLoading: boolean;
  updatePatientError?: string;
  currentPatientAddress?: Address;
  setCurrentPatientAddress: Dispatch<SetStateAction<Address | undefined>>;
  addPatientAddress: (
    address: Partial<Address>,
    heapEventLocation?: HeapEventLocation
  ) => Promise<Address | undefined>;
  addPatientEthnicities: (
    patientEthnicities: PatientEthnicityDTO
  ) => Promise<PatientEthnicityDTO | undefined>;
  addPatientAddressIsLoading: boolean;
  addPatientEthnicitiesIsLoading: boolean;
  addPatientEthnicitiesError?: ApolloError;
  addPatientAddressError?: ApolloError;
  updatePatientAddress: (
    address: Partial<Address>
  ) => Promise<Address | undefined>;
  updatePatientAddressIsLoading: boolean;
  updatePatientAddressError?: ApolloError;
  updatePatientCommunication: (
    communication: Communication[],
    heapEventLocation?: HeapEventLocation
  ) => Promise<Communication[] | undefined>;
  updatePatientCommunicationIsLoading: boolean;
  updatePatientCommunicationError?: ApolloError;
  skipCommunicationPreferencesStep: boolean;
  sendPhoneNumberVerificationCode: (phoneNumber: string) => void;
  updatePatientPhoneNumber: (
    verificationCode: string
  ) => Promise<SuccessStatusResponse | undefined>;
}

export const Context = createContext<ProfileController>({
  isLoading: false,
  setupProfileIsLoading: false,
  verifyProfileIsLoading: false,
  getProfileIsLoading: false,
  confirmProfileIsLoading: false,
  verifyProfile: async () => undefined,
  getProfile: async () => undefined,
  setupProfile: async () => undefined,
  confirmProfile: async () => undefined,
  profileData: undefined,
  profileDataIsLoading: false,
  updatePersonalInformation: async () => undefined,
  updatePhone: async () => undefined,
  updatePatientIsLoading: false,
  setCurrentPatientAddress: () => undefined,
  addPatientAddress: async () => undefined,
  addPatientEthnicities: async () => undefined,
  addPatientAddressIsLoading: false,
  addPatientEthnicitiesIsLoading: false,
  updatePatientAddress: async () => undefined,
  updatePatientAddressIsLoading: false,
  updatePatientCommunication: async () => undefined,
  updatePatientCommunicationIsLoading: false,
  skipCommunicationPreferencesStep: false,
  sendPhoneNumberVerificationCode: async () => undefined,
  updatePatientPhoneNumber: async () => undefined,
});

Context.displayName = "ProfileContext";

const ProfileProvider: FC = ({ children }) => {
  const history = useHistory();

  const { profile: uppProfile, setProfile } = useContext(UppAuthContext);
  const { addNotification, clear } = useContext(NotificationContext);
  const [profileData, setProfileData] = useState<Profile | undefined>();
  const [profileDataIsLoading, setProfileDataIsLoading] = useState(false);

  const [currentPatientAddress, setCurrentPatientAddress] = useState<
    Address | undefined
  >(undefined);

  const [
    setupProfile,
    {
      loading: setupProfileIsLoading,
      data: setupProfileData,
      error: setupProfileError,
    },
  ] = useMutation<{
    setupProfile: Profile;
  }>(ProfileService.setupProfile());

  const [
    confirmProfile,
    {
      loading: confirmProfileIsLoading,
      data: confirmProfileData,
      error: confirmProfileError,
    },
  ] = useMutation<{
    confirmProfile: Profile;
  }>(ProfileService.confirmProfile());

  const [
    verifyProfile,
    {
      loading: verifyProfileIsLoading,
      data: verifyProfileData,
      error: verifyProfileError,
    },
  ] = useMutation<{
    verifyProfile: { isVerified: boolean };
  }>(ProfileService.verifyProfile());

  const [
    getProfile,
    {
      loading: getProfileIsLoading,
      data: getProfileData,
      error: getProfileError,
    },
  ] = useLazyQuery<{
    getProfile: Profile;
  }>(ProfileService.getProfile(), {
    fetchPolicy: "network-only",
  });

  const [
    updatePatient,
    {
      loading: updatePatientIsLoading,
      error: updatePatientError,
      data: updatePatientData,
    },
  ] = useMutation<{ updatePatient: Profile }>(PatientService.updatePatient());

  const [
    addPatientAddress,
    {
      loading: addPatientAddressIsLoading,
      data: addPatientAddressData,
      error: addPatientAddressError,
    },
  ] = useMutation<{ addPatientAddress: Address }>(
    ProfileService.addPatientAddress()
  );

  const [
    addPatientEthnicities,
    {
      loading: addPatientEthnicitiesIsLoading,
      data: addPatientEthnicitiesData,
      error: addPatientEthnicitiesError,
    },
  ] = useMutation<{ addPatientEthnicities: PatientEthnicityDTO }>(
    ProfileService.addPatientEthnicities()
  );

  const [
    updatePatientAddress,
    {
      loading: updatePatientAddressIsLoading,
      data: updatePatientAddressData,
      error: updatePatientAddressError,
    },
  ] = useMutation<{ updatePatientAddress: Address }>(
    ProfileService.updatePatientAddress()
  );

  const [
    updatePatientCommunication,
    {
      loading: updatePatientCommunicationIsLoading,
      data: updatePatientCommunicationData,
      error: updatePatientCommunicationError,
    },
  ] = useMutation<{ updatePatientCommunication: Communication[] }>(
    ProfileService.updatePatientCommunication(),
    {
      onError: (error) => {
        console.error(error);
        addNotification({ type: "error" });
        handleGetProfile();
      },
    }
  );

  const [
    sendPhoneNumberVerificationCode,
    { loading: sendPhoneNumberVerificationCodeIsLoading },
  ] = useMutation<{
    sendPhoneNumberVerificationCode: SuccessStatusResponse;
  }>(ProfileService.sendPhoneNumberVerificationCode());

  const [
    updatePatientPhoneNumber,
    { loading: updatePatientPhoneNumberIsLoading },
  ] = useMutation<{
    updatePatientPhoneNumber: SuccessStatusResponse;
  }>(ProfileService.updatePatientPhoneNumber());

  const handleSetupProfile = async (
    profileData: Profile
  ): Promise<Profile | undefined> => {
    const profile = await setupProfile({ variables: { profileData } });

    return profile.data?.setupProfile;
  };

  const handleConfirmProfile = async (
    profileData: Profile
  ): Promise<Profile | undefined> => {
    const profile = await confirmProfile({ variables: { profileData } });

    return profile.data?.confirmProfile;
  };

  const handleVerifyProfile = async (
    dateOfBirth: string
  ): Promise<VerifyProfileData | undefined> => {
    const profile = await verifyProfile({ variables: { dateOfBirth } });

    return profile.data?.verifyProfile;
  };

  const handleGetProfile = async (): Promise<Profile | undefined> => {
    if (uppProfile?.patientUID) {
      const profile = await getProfile();

      if (profile.error) {
        console.error(profile.error);
        addNotification({ type: "error" });
      }
      if (
        profile.data?.getProfile.uid &&
        profile.data.getProfile.uid !== uppProfile.patientUID
      ) {
        setProfile({ ...uppProfile, patientUID: profile.data?.getProfile.uid });
      }
      return profile.data?.getProfile;
    }
    return undefined;
  };

  const mergeProfileData = React.useCallback(
    (sourceProfile: Profile | undefined, newProfile: Profile | undefined) => {
      if (!sourceProfile) {
        return newProfile;
      }

      return newProfile
        ? (R.mergeDeepRight(sourceProfile, newProfile) as Profile)
        : sourceProfile;
    },
    []
  );

  useEffect(() => {
    setProfileData(
      mergeProfileData(
        getProfileData?.getProfile,
        confirmProfileData?.confirmProfile
      )
    );
  }, [getProfileData, confirmProfileData]);

  useEffect(() => {
    handleGetProfile();
  }, [
    addPatientAddressData,
    addPatientEthnicitiesData,
    updatePatientAddressData,
    updatePatientCommunicationData,
    updatePatientData,
  ]);

  useEffect(() => {
    const isLoading = getProfileIsLoading || confirmProfileIsLoading;
    setProfileDataIsLoading(isLoading);
  }, [getProfileIsLoading, confirmProfileIsLoading]);

  useEffect(() => {
    if (
      uppProfile &&
      !getProfileData?.getProfile &&
      !getProfileIsLoading &&
      uppProfile.patientUID
    ) {
      handleGetProfile();
    }
  }, [uppProfile]);

  const handleUpdatePersonalInformation = async (
    personalInformation: PersonalInformation
  ) => {
    const response = await updatePatient({
      variables: { patientData: personalInformation },
    });
    return response.data?.updatePatient;
  };

  const handleUpdatePhone = async (
    phoneNumber: string
  ): Promise<Profile | undefined> => {
    const patientData: Profile = { phone: phoneNumber };
    const response = await updatePatient({
      variables: { patientData },
    });
    return response.data?.updatePatient;
  };

  const handleAddPatientAddress = async (
    patientAddress: Partial<Address>,
    heapEventLocation?: HeapEventLocation
  ) => {
    const response = await addPatientAddress({
      variables: {
        patientAddress,
        heapEventLocation,
      },
    });

    return response.data?.addPatientAddress;
  };

  const handleAddPatientEthnicities = async (
    patientEthnicities: PatientEthnicityDTO
  ) => {
    const response = await addPatientEthnicities({
      variables: {
        patientEthnicities,
      },
    });

    return response.data?.addPatientEthnicities;
  };

  const handleUpdatePatientAddress = async (patientAddress: Address) => {
    const response = await updatePatientAddress({
      variables: {
        patientAddress,
      },
    });
    setCurrentPatientAddress(undefined);
    return response.data?.updatePatientAddress;
  };

  const handleUpdatePatientCommunication = async (
    patientCommunication: Communication[],
    heapEventLocation?: HeapEventLocation
  ) => {
    const response = await updatePatientCommunication({
      variables: {
        patientCommunication,
        heapEventLocation,
      },
    });
    return response.data?.updatePatientCommunication;
  };

  const skipCommunicationPreferencesStep = useMemo(() => {
    const preferences = profileData?.communicationPreferences;
    if (!preferences) return false;

    const hasOptInPreferences = R.find(
      (pref) => pref.value === ChannelValue.OPT_IN,
      preferences
    );

    return Boolean(hasOptInPreferences);
  }, [profileData?.communicationPreferences]);

  const handleSendPhoneNumberVerificationCode = async (phoneNumber: string) => {
    try {
      clear();
      await sendPhoneNumberVerificationCode({
        variables: {
          phoneNumber,
        },
      });
    } catch (error) {
      addNotification({
        type: "error",
      });
    }
  };

  const handleUpdatePatientPhoneNumber = async (verificationCode: string) => {
    clear();
    try {
      const response = await updatePatientPhoneNumber({
        variables: {
          verificationCode,
        },
      });
      if (response.data?.updatePatientPhoneNumber.success) {
        await getProfile();
        history.replace(routes.contactDetails, {
          phoneUpdated: true,
        });
      }
      return response.data?.updatePatientPhoneNumber;
    } catch (error) {
      if (isApolloError(error) && error.graphQLErrors?.length > 0) {
        const parsedErrors = error.graphQLErrors.map(
          (e) => e.extensions?.exception
        );
        const incorrectActivationCodeError = parsedErrors.find(
          (e: ErrorBody) => e.code === ResultCodes.INCORRECT_ACTIVATION_CODE
        );

        // throw it to show in verificationPhoneModal
        if (incorrectActivationCodeError) {
          throw incorrectActivationCodeError;
        } else {
          addNotification({
            type: "error",
          });
        }
      } else {
        addNotification({
          type: "error",
        });
      }
    }
  };

  const isLoading =
    sendPhoneNumberVerificationCodeIsLoading ||
    updatePatientPhoneNumberIsLoading;

  return (
    <Context.Provider
      value={{
        isLoading,
        profileData,
        profileDataIsLoading,
        getProfileIsLoading: getProfileIsLoading,
        getProfileData: getProfileData?.getProfile,
        getProfileError: (getProfileError?.graphQLErrors[0]?.extensions
          ?.exception as ErrorBody)?.code,
        setupProfileIsLoading: setupProfileIsLoading,
        setupProfileData: setupProfileData?.setupProfile,
        setupProfileError: (setupProfileError?.graphQLErrors[0]?.extensions
          ?.exception as ErrorBody)?.code,
        confirmProfileIsLoading: confirmProfileIsLoading,
        confirmProfileData: confirmProfileData?.confirmProfile,
        confirmProfileError: (confirmProfileError?.graphQLErrors[0]?.extensions
          ?.exception as ErrorBody)?.code,
        verifyProfileIsLoading: verifyProfileIsLoading,
        verifyProfileData: verifyProfileData?.verifyProfile,
        verifyProfileError: verifyProfileError,
        setupProfile: handleSetupProfile,
        verifyProfile: handleVerifyProfile,
        getProfile: handleGetProfile,
        confirmProfile: handleConfirmProfile,
        updatePersonalInformation: handleUpdatePersonalInformation,
        updatePhone: handleUpdatePhone,
        updatePatientIsLoading,
        updatePatientError: (updatePatientError?.graphQLErrors?.[0]?.extensions
          ?.exception as ErrorBody)?.code,
        currentPatientAddress,
        setCurrentPatientAddress,
        addPatientAddress: handleAddPatientAddress,
        addPatientEthnicities: handleAddPatientEthnicities,
        addPatientAddressIsLoading,
        addPatientEthnicitiesIsLoading,
        addPatientAddressError,
        addPatientEthnicitiesError,
        updatePatientAddress: handleUpdatePatientAddress,
        updatePatientAddressIsLoading,
        updatePatientAddressError,
        updatePatientCommunication: handleUpdatePatientCommunication,
        updatePatientCommunicationIsLoading,
        updatePatientCommunicationError,
        skipCommunicationPreferencesStep,
        sendPhoneNumberVerificationCode: handleSendPhoneNumberVerificationCode,
        updatePatientPhoneNumber: handleUpdatePatientPhoneNumber,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ProfileProvider;
