import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Form, Button } from "@natera/platform/lib/components/form";
import { DisplayField } from "@natera/platform/lib/components/form/field";
import { Profile } from "@app/provider/profile";
import { UppUser } from "@app/service/user";
import { FormField } from "@app/components";

import "./personalInfo.scss";
import { getFormattedDOB, getProfileFullName } from "@app/utils";

const messages = defineMessages({
  personalInfoFormFullName: {
    id: "personalInfoFormFullName",
    defaultMessage: "Full Name",
  },
  personalInfoFormDateOfBirth: {
    id: "personalInfoFormDateOfBirth",
    defaultMessage: "Date of Birth",
  },
  personalInfoFormMobileNumber: {
    id: "personalInfoFormMobileNumber",
    defaultMessage: "Mobile Number",
  },
  personalInfoFormEmail: {
    id: "personalInfoFormEmail",
    defaultMessage: "Email",
  },
  personalInfoNext: {
    id: "personalInfoNext",
    defaultMessage: "Next",
  },
  personalInfoBack: {
    id: "personalInfoBack",
    defaultMessage: "Back",
  },
});

interface PersonalInfoProps {
  onSubmit: () => void;
  onBack: () => void;
  profileData?: Profile;
  uppUser?: UppUser;
}

const PersonalInfoForm: FC<PersonalInfoProps> = ({
  onSubmit,
  onBack,
  profileData,
  uppUser,
}) => {
  const intl = useIntl();

  const dateOfBirth = getFormattedDOB(profileData?.dateOfBirth);

  return (
    <Form
      className="personal-info-form__container"
      noValidate
      onSubmit={onSubmit}
    >
      <FormField
        label={intl.formatMessage(messages.personalInfoFormFullName)}
        htmlFor="fullname"
        withPadding
      >
        <HeapPHIDecorator protectText>
          <DisplayField id="fullname" value={getProfileFullName(profileData)} />
        </HeapPHIDecorator>
      </FormField>
      <FormField
        label={intl.formatMessage(messages.personalInfoFormDateOfBirth)}
        htmlFor="dateOfBirth"
        withPadding
      >
        <HeapPHIDecorator protectText>
          <DisplayField id="dateOfBirth" value={dateOfBirth} />
        </HeapPHIDecorator>
      </FormField>
      <FormField
        label={intl.formatMessage(messages.personalInfoFormMobileNumber)}
        htmlFor="mobileNumber"
        withPadding
      >
        <HeapPHIDecorator protectText>
          <DisplayField
            id="mobileNumber"
            value={profileData?.phone || <>&mdash;</>}
          />
        </HeapPHIDecorator>
      </FormField>
      <FormField
        label={intl.formatMessage(messages.personalInfoFormEmail)}
        htmlFor="email"
      >
        <HeapPHIDecorator protectText>
          <DisplayField id="email" value={uppUser?.email || <>&mdash;</>} />
        </HeapPHIDecorator>
      </FormField>
      <div className="stepper__actions">
        <Button type="submit" data-testid="submit" raised>
          {intl.formatMessage(messages.personalInfoNext)}
        </Button>
        <Button type="button" onClick={onBack}>
          {intl.formatMessage(messages.personalInfoBack)}
        </Button>
      </div>
    </Form>
  );
};

export default PersonalInfoForm;
