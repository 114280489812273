import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button, Form } from "@natera/platform/lib/components/form";
import { PhoneField } from "@app/components";
import {
  useErrorController,
  usePhoneValidation,
} from "@natera/platform/lib/hooks";
import { getPhoneValidationErrorMessage } from "@app/utils/getPhoneValidationErrorMessage";

import "./updateMobileNumberDialog.scss";
import { UserContext } from "@app/provider";

const messages = defineMessages({
  updateMobileNumberDialogTitle: {
    id: "updateMobileNumberDialogTitle",
    defaultMessage: "Update Mobile Number",
  },
  updateMobileNumberDialogSaveButton: {
    id: "updateMobileNumberDialogSaveButton",
    defaultMessage: "Save",
  },
  updateMobileNumberDialogBackButton: {
    id: "updateMobileNumberDialogBackButton",
    defaultMessage: "Back",
  },
});

interface Props {
  currentPhone: string;
  handleSave: (newPhoneNumber: string) => void;
  handleBack: () => void;
}

const UpdateMobileNumberDialog: FC<Props> = ({
  currentPhone,
  handleSave,
  handleBack,
}) => {
  const intl = useIntl();

  const {
    clearErrors,
    getValidationError,
    setValidationError,
  } = useErrorController();
  const phoneValidation = usePhoneValidation();
  const { validatePhoneNumber: validateFromPS } = React.useContext(UserContext);

  const validatePhone = async (mobileNumber: string) => {
    const errorMessage = await getPhoneValidationErrorMessage({
      isMandatory: true,
      mobileNumber,
      intl,
      phoneValidation,
      validateFromPS,
    });
    if (errorMessage) {
      setValidationError("mobileNumber", errorMessage);
      return false;
    }

    return true;
  };

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearErrors();

    const newPhoneNumber = e.currentTarget.mobileNumber?.value || "";

    const isValidatePhone = await validatePhone(newPhoneNumber);
    if (!isValidatePhone) {
      return;
    }

    if (newPhoneNumber === currentPhone.substring(1)) {
      handleBack();
    }

    handleSave(newPhoneNumber);
  };

  return (
    <div className="update-mobile-number-dialog">
      <Dialog
        data-testid="updateMobileNumberDialogId"
        title={
          <span>
            {intl.formatMessage(messages.updateMobileNumberDialogTitle)}
          </span>
        }
        onClose={handleBack}
      >
        <Form
          className="update-phone-form"
          noValidate
          onSubmit={handleSubmitForm}
          data-testid="updateMobileNumberFormId"
        >
          <PhoneField
            getValidationError={getValidationError}
            required={false}
          />
          <Button data-testid="submit" raised type="submit">
            {intl.formatMessage(messages.updateMobileNumberDialogSaveButton)}
          </Button>
          <Button outlined onClick={handleBack}>
            {intl.formatMessage(messages.updateMobileNumberDialogBackButton)}
          </Button>
        </Form>
      </Dialog>
    </div>
  );
};

export default UpdateMobileNumberDialog;
