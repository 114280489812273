import {
  getTestTypeForHeap,
  isComboOrder,
  snakeCaseToCapitalizedWords,
} from "@app/utils";
import { Order } from "@app/provider/order";

export const getTestNameForHeap = (
  orderData: Order | undefined
): string | undefined => {
  const testType = orderData && getTestTypeForHeap(orderData);
  const isCombo = orderData && isComboOrder(orderData);

  return isCombo ? testType : snakeCaseToCapitalizedWords(testType);
};
