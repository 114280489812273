import { StepperContext } from "@natera/stepper";
import React from "react";
import {
  PaymentInfo,
  PaymentMethodData,
  PaymentType,
  SubSteps,
} from "./paymentMethod";
import { SelfPayStep } from "@app/components/simpleOrder/payment/steps";
import { getMultipleSteps } from "./getMultipleSteps";
import { getCommercialSteps } from "./getCommercialSteps";
import { getGovernmentSteps } from "./getGovernmentSteps";
import { MultipleStep } from "./multipleStepsMapper";

type Props = {
  value: PaymentMethodData;
  onConfirm: (data: PaymentInfo) => void;
  children: React.ReactElement;
};

const PaymentStepperController: React.FC<Props> = ({
  value,
  onConfirm,
  children,
}) => {
  const { goAt, createStep } = React.useContext(StepperContext);

  const createPaymentSteps = (steps: MultipleStep[]) => {
    steps.forEach((step) => {
      const { stepId, index, childComponent, data } = step;
      createStep(stepId, {
        index,
        children: childComponent,
        data,
      });
    });
    if (!value?.firstStep) {
      const lastStep = steps.reduce(
        (maxIndexValue, currentValue) =>
          maxIndexValue.index < currentValue.index
            ? currentValue
            : maxIndexValue,
        steps[0]
      );
      lastStep && goAt(lastStep.stepId);
    }
  };

  React.useEffect(() => {
    if (value?.paymentMethod === PaymentType.COMMERCIAL) {
      const commercialSteps = getCommercialSteps(value, onConfirm);
      createPaymentSteps(commercialSteps);
    }

    if (value?.paymentMethod === PaymentType.GOVERNMENT_ISSUED) {
      const governmentSteps = getGovernmentSteps(value, onConfirm);
      createPaymentSteps(governmentSteps);
    }

    if (value?.paymentMethod === PaymentType.MULTIPLE) {
      const multipleSteps = getMultipleSteps(
        value?.paymentMethodInfo,
        onConfirm
      );
      createPaymentSteps(multipleSteps);
    }

    if (value?.paymentMethod === PaymentType.DO_NOT_HAVE) {
      createStep(SubSteps.DO_NOT_HAVE, {
        index: 1,
        children: (
          <SelfPayStep
            paymentType={value.paymentMethod}
            onConfirm={onConfirm}
          />
        ),
      });
      if (!value?.firstStep) {
        goAt(SubSteps.DO_NOT_HAVE);
      }
    }

    if (value?.paymentMethod === PaymentType.SELF_PAY) {
      createStep(SubSteps.SELF_PAY, {
        index: 1,
        children: (
          <SelfPayStep
            paymentType={value.paymentMethod}
            onConfirm={onConfirm}
          />
        ),
      });
      if (!value?.firstStep) {
        goAt(SubSteps.SELF_PAY);
      }
    }
  }, [value]);

  return <>{children}</>;
};

export default PaymentStepperController;
