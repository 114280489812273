import React, { createContext, FC } from "react";
import {
  ParsedTestCard,
  SecondaryActionCardType,
  SecondaryCard,
  TestCard,
  TestCardStatus,
  TestType,
} from "@app/provider/testData/types";
import { useIntl } from "react-intl";
import {
  getSecondaryCardDescription,
  getPrimaryAction,
  getDescription,
  getSecondaryCardTitle,
} from "./utils";
import { getSecondaryCardDescriptionNote } from "@app/provider/testData/utils/getSecondaryCardDescriptionNote";

export interface TestCardUtilsController {
  getTestCardData: (testCard: TestCard) => ParsedTestCard;
  getSecondaryActionCardData: (secondaryCard: SecondaryCard) => SecondaryCard;
}

export const Context = createContext<TestCardUtilsController>({
  getTestCardData: () => ({
    uid: "uid",
    orderUid: "orderUid",
    testType: TestType.PROSPERA,
    createdAt: new Date(),
    status: TestCardStatus.TEST_ORDERED,
    resultReleasedAt: null,
    clinic: { limsId: 1 },
  }),
  getSecondaryActionCardData: () => ({
    type: SecondaryActionCardType.HORIZON,
  }),
});

const TestCardUtilsProvider: FC = ({ children }) => {
  const intl = useIntl();

  const getTestCardData = (testCard: TestCard): ParsedTestCard => ({
    uid: testCard.uid,
    orderUid: testCard.orderUid,
    testType: testCard.testType,
    createdAt: testCard.createdAt,
    accessedResultAt: testCard.accessedResultAt,
    status: testCard.status,
    nextStatus: testCard.nextStatus,
    description: getDescription(intl, testCard),
    primaryAction: getPrimaryAction(testCard),
    organType: testCard.organType,
    sampleType: testCard.sampleType,
    sampleReceivedDate: testCard.sampleReceivedDate,
    trackingNumber: testCard.trackingNumber,
    reportGender: testCard.reportGender,
    caPnsSupplemental: testCard.caPnsSupplemental,
    resultCode: testCard.resultCode,
    resultReleasedAt: testCard.resultReleasedAt,
    latestResultDocumentUid: testCard.latestResultDocumentUid,
    cancelledAt: testCard.cancelledAt,
    isProReach: testCard.isProReach,
    clinic: testCard.clinic,
    isComboOrder: testCard.isComboOrder,
    managedBy: testCard.managedBy,
    isNevaEnabled: testCard.isNevaEnabled,
    isOrderInSignedOrdersTable: testCard.isOrderInSignedOrdersTable,
    simpleOrderWorkflowState: testCard.simpleOrderWorkflowState,
    documentReferenceId: testCard.documentReferenceId,
    isSignateraSubsequent: testCard.isSignateraSubsequent,
  });

  const getSecondaryActionCardData = (secondaryCard: SecondaryCard) => ({
    type: secondaryCard.type,
    title: getSecondaryCardTitle(intl, secondaryCard),
    description: getSecondaryCardDescription(intl, secondaryCard),
    note: getSecondaryCardDescriptionNote(intl, secondaryCard),
    limsClinicId: secondaryCard.limsClinicId,
  });

  return (
    <Context.Provider
      value={{
        getTestCardData,
        getSecondaryActionCardData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default TestCardUtilsProvider;
