import React, { FC } from "react";

import { RuntimeConfigurationProvider } from "@natera/platform/lib/provider/runtimeConfiguration";
import {
  ConfigProvider,
  ServiceProvider,
  UppAuthProvider,
  IntlProvider,
  NotificationProvider,
  IdleWrapper,
  TestResultProvider,
  UnifiedPatientPortalServerProvider,
  UserProvider,
} from "@app/provider";
import AuthProviderWrapper from "@app/provider/authProviderWrapper";
import SecurityProviderWrapper from "@app/provider/securityProviderWrapper";
import config from "@etc/config.json";
import oktaConfig from "@etc/okta.json";
import Routing from "./routing";

const App: FC = () => {
  return (
    <IntlProvider>
      <RuntimeConfigurationProvider>
        <ConfigProvider config={{ ...config, okta: oktaConfig }}>
          <ServiceProvider>
            <NotificationProvider>
              <AuthProviderWrapper>
                <SecurityProviderWrapper>
                  <UnifiedPatientPortalServerProvider>
                    <UppAuthProvider>
                      <UserProvider>
                        <IdleWrapper>
                          <TestResultProvider>
                            <Routing />
                          </TestResultProvider>
                        </IdleWrapper>
                      </UserProvider>
                    </UppAuthProvider>
                  </UnifiedPatientPortalServerProvider>
                </SecurityProviderWrapper>
              </AuthProviderWrapper>
            </NotificationProvider>
          </ServiceProvider>
        </ConfigProvider>
      </RuntimeConfigurationProvider>
    </IntlProvider>
  );
};

export default App;
