import React, { FC } from "react";
import MobilePhlebotomyStepper, {
  MobilePhlebotomyStepperProps,
} from "@app/pages/private/sampleDraw/create/mobilePhlebotomyStepper";
import { DrawRequestContext } from "@app/provider";

import "./scheduleBloodDrawStepper.scss";
import { ScheduleBloodDrawScreens } from "../scheduleBloodDraw";

interface ScheduleBloodDrawStepperProps {
  token: string;
  setBloodDrawScreen: React.Dispatch<
    React.SetStateAction<ScheduleBloodDrawScreens>
  >;
  isSeparateMobilePhlebotomyFlow: boolean;
}

const ScheduleBloodDrawStepper: FC<ScheduleBloodDrawStepperProps> = ({
  token,
  setBloodDrawScreen,
  isSeparateMobilePhlebotomyFlow,
}) => {
  const { verifiedSampleDrawData } = React.useContext(DrawRequestContext);

  const mobilePhlebotomyStepperProps: MobilePhlebotomyStepperProps = {
    onBackPreferenceStep: () =>
      setBloodDrawScreen(ScheduleBloodDrawScreens.PERSONAL_INFO_SCREEN),
    handleCreateDrawRequestError: () =>
      setBloodDrawScreen(
        ScheduleBloodDrawScreens.CREATE_BLOOD_DRAW_FAILED_SCREEN
      ),
    testType: verifiedSampleDrawData?.sampleInfo.sample?.testType,
    orderUid: verifiedSampleDrawData?.sampleInfo.sample?.orderUid,
    testUid: verifiedSampleDrawData?.sampleInfo.sample?.testUid,
    profileAddresses: verifiedSampleDrawData?.profileInfo.profile?.addresses,
    token,
    isSeparateMobilePhlebotomyFlow: isSeparateMobilePhlebotomyFlow,
  };

  return (
    <div className="guest-schedule-blood-draw">
      <div className="guest-schedule-blood-draw__content">
        <MobilePhlebotomyStepper {...mobilePhlebotomyStepperProps} />
      </div>
    </div>
  );
};

export default ScheduleBloodDrawStepper;
