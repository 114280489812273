import React, { FC, useContext, useEffect } from "react";
import { DateRange, Pagination, SpinnerView, TestCard } from "@app/components";
import { BlueHeaderContent } from "@app/components/ui/layout";
import { Spinner } from "@natera/material/lib/progress";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/platform/lib/components/form";
import SortIcon from "@assets/svg/icons/sort.svg";
import { TestHistoryContext, UserContext } from "@app/provider";
import { routes } from "@app/routing";
import { useIsMobileDimension } from "@app/hooks";
import { SORT_BY } from "@app/provider/testHistory";
import "./testHistory.scss";
import { DateRange as RangeModifier } from "@natera/material/lib/date";

const messages = defineMessages({
  testHistoryTitle: {
    id: "testHistoryTitle",
    defaultMessage: "Test History",
  },
  testHistoryNewest: {
    id: "testHistoryNewest",
    defaultMessage: "Newest",
  },
  testHistoryOldest: {
    id: "testHistoryOldest",
    defaultMessage: "Oldest",
  },
  testHistoryLoadMore: {
    id: "testHistoryLoadMore",
    defaultMessage: "Load More",
  },
});

const TestHistory: FC = () => {
  const intl = useIntl();
  const {
    isLoading,
    getTestHistory,
    testCards,
    sortBy,
    toggleSort,
    loadPage,
    pageNum,
    reset,
    currentPage,
  } = useContext(TestHistoryContext);
  const { uppUser, loadProfile } = useContext(UserContext);

  const isMobile = useIsMobileDimension();

  const [rangeDate, setRangeDate] = React.useState<RangeModifier>();
  const getRangeDate = (date$?: RangeModifier) => setRangeDate(date$);

  useEffect(() => {
    loadProfile();
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (!isLoading && !testCards && !rangeDate) {
      getTestHistory();
    }
  }, [isMobile, testCards, rangeDate]);

  useEffect(() => {
    if (testCards && rangeDate?.from && rangeDate.to) {
      testCards.length = 0;
      getTestHistory(rangeDate.from, rangeDate.to);
    }
  }, [isMobile, rangeDate]);

  useEffect(() => {
    if (uppUser?.patientUID && !isLoading) {
      getTestHistory();
    }
  }, [uppUser?.patientUID]);

  const handleLoadMore = () => {
    loadPage(undefined, rangeDate?.from, rangeDate?.to, true);
  };

  const renderSorting = () => {
    toggleSort(rangeDate?.from, rangeDate?.to);
  };

  const renderOrderBtn = () => {
    const btnText =
      sortBy === SORT_BY.DESC
        ? intl.formatMessage(messages.testHistoryNewest)
        : intl.formatMessage(messages.testHistoryOldest);
    return (
      <Button icon={SortIcon} onClick={renderSorting}>
        {btnText}
      </Button>
    );
  };

  const renderTestCards = () => (
    <div className="home__test-cards-column">
      {testCards &&
        testCards.map((testCard) => (
          <TestCard
            isHistory
            key={testCard.uid}
            testCard={testCard}
            leftSideClickable
          />
        ))}
    </div>
  );

  const renderPagination = () => {
    if (isMobile) {
      return (
        !isLoading &&
        pageNum !== 0 &&
        pageNum !== currentPage + 1 && (
          <Button outlined onClick={handleLoadMore}>
            {intl.formatMessage(messages.testHistoryLoadMore)}
          </Button>
        )
      );
    }
    return (
      <Pagination
        total={pageNum}
        currentPage={currentPage}
        handlePage={loadPage}
        rangeDate={rangeDate}
      />
    );
  };

  return (
    <div className="test-history">
      {!isMobile && <SpinnerView isLoading={isLoading} />}
      <BlueHeaderContent
        blueHeaderTitle={intl.formatMessage(messages.testHistoryTitle)}
        backArrowHeaderRoute={routes.home}
      >
        <section className="test-history__section">
          <div className="test-history__container">
            <div className="test-history__filters">
              <DateRange rangeDate={getRangeDate} />
              {renderOrderBtn()}
            </div>
            <div className="test-history__cards">
              {testCards && renderTestCards()}
            </div>
            <div className="test-history__pagination">
              {renderPagination()}
              {isMobile && isLoading && <Spinner indeterminate />}
            </div>
          </div>
        </section>
      </BlueHeaderContent>
    </div>
  );
};

export default TestHistory;
