import { StepperContent } from "@app/components/ui/layout/stepperContent";
import React, { useContext } from "react";
import EthnicityForm from "./ethnicityForm/ethnicityForm";
import { defineMessages, useIntl } from "react-intl";
import { StepperContext } from "@natera/stepper";
import { NotificationContext, ProfileContext } from "@app/provider";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";

export interface Ethnicity {
  code: string;
  description: string;
}

export interface PatientEthnicityOutput {
  ethnicityTypes: Ethnicity[];
  subEthnicity: string;
}

export interface PatientEthnicityInput {
  codes: string[];
  subEthnicity?: string;
}

const messages = defineMessages({
  selectEthnicityTitle: {
    id: "selectEthnicityTitle",
    defaultMessage: "Select Ethnicity",
  },
  selectEthnicityUpdateError: {
    id: "selectEthnicityUpdateError",
    defaultMessage: "We're sorry. Something went wrong.",
  },
});

interface EthnicityStepProps {
  isInitialStep?: boolean;
}

const EthnicityStep: React.FC<EthnicityStepProps> = ({ isInitialStep }) => {
  const intl = useIntl();
  const history = useHistory();

  const { addNotification } = useContext(NotificationContext);
  const { goBack, resolve, getData, getCurrentStep } = React.useContext(
    StepperContext
  );
  const {
    profileData,
    addPatientEthnicities,
    addPatientEthnicitiesIsLoading,
  } = React.useContext(ProfileContext);
  const profileEthnicities = profileData?.ethnicities;
  const currentStepId = getCurrentStep()?.stepId;
  const ethnicitiesToUpdate =
    currentStepId && getData(currentStepId)?.ethnicitiesToUpdate;
  if (
    ethnicitiesToUpdate?.codes &&
    Array.isArray(ethnicitiesToUpdate?.codes) &&
    ethnicitiesToUpdate?.codes.includes("OTH")
  ) {
    ethnicitiesToUpdate?.codes.push(ethnicitiesToUpdate?.subEthnicity);
    ethnicitiesToUpdate?.codes.splice(
      ethnicitiesToUpdate?.codes.indexOf("OTH"),
      1
    );
  }
  const data = ethnicitiesToUpdate?.codes;

  const handleSubmit = async (ethnicitiesToUpdate: PatientEthnicityInput) => {
    try {
      await addPatientEthnicities({
        uid: profileData?.uid ? profileData?.uid : "",
        ethnicities: ethnicitiesToUpdate,
      });
      resolve({
        ethnicitiesToUpdate,
      });
    } catch (error) {
      addNotification({
        message: intl.formatMessage(messages.selectEthnicityUpdateError),
        type: "error",
      });
    }
  };

  const handleNext = (value: PatientEthnicityInput) => handleSubmit(value);

  const routerBack = () => {
    history.length > 1 ? history.goBack() : history.push(routes.home);
  };

  const handleBack = () => {
    isInitialStep ? routerBack() : goBack();
  };

  return (
    <>
      <StepperHeaderProgress />
      <StepperContent title={intl.formatMessage(messages.selectEthnicityTitle)}>
        <EthnicityForm
          profileEthnicities={profileEthnicities}
          defaultValue={data}
          onSubmit={handleNext}
          goBack={handleBack}
          loading={addPatientEthnicitiesIsLoading}
        />
      </StepperContent>
    </>
  );
};

export default EthnicityStep;
