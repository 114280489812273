import React, { FC, useCallback, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { InfoCard, InfoCardField, SpinnerView } from "@app/components";
import { routes } from "@app/routing";
import { BlueHeaderContent } from "@app/components/ui/layout";
import { ProfileContext } from "@app/provider/profile";
import {
  ChannelType,
  ChannelValue,
  Communication,
} from "@app/provider/profile/types";
import R from "ramda";

const messages = defineMessages({
  communicationPageBackButton: {
    id: "communicationPageBackButton",
    defaultMessage: "Communication Preferences",
  },
  communicationPagePrimaryTitle: {
    id: "communicationPagePrimaryTitle",
    defaultMessage: "Communication Preferences",
  },
  communicationPageEmail: {
    id: "communicationPageEmail",
    defaultMessage: "Email",
  },
  communicationPageSMS: {
    id: "communicationPageSMS",
    defaultMessage: "SMS",
  },
  communicationPageEmailOptIn: {
    id: "communicationPageEmailOptIn",
    defaultMessage: "Opt-in to receive Email communications",
  },
  communicationPageSmsOptIn: {
    id: "communicationPageSmsOptIn",
    defaultMessage: "Opt-in to receive SMS communications",
  },
  communicationPageEmailOptOut: {
    id: "communicationPageEmailOptOut",
    defaultMessage: "Opt-out from Email communications",
  },
  communicationPageSmsOptOut: {
    id: "communicationPageSmsOptOut",
    defaultMessage: "Opt-out from SMS communications",
  },
  communicationPageMakeSelection: {
    id: "communicationPageMakeSelection",
    defaultMessage: "Make a selection",
  },
});

const CommunicationPreferencesPage: FC = () => {
  const intl = useIntl();
  const { profileData, profileDataIsLoading } = useContext(ProfileContext);

  const communicationData = profileData?.communicationPreferences;

  const getInitialCommunication = (type: ChannelType) => {
    if (!communicationData) return undefined;
    return R.find<Communication>(R.propEq("channelType", type))(
      communicationData
    );
  };

  const getEmailValue = useCallback(() => {
    const emailCommunication = getInitialCommunication(ChannelType.EMAIL);

    if (
      !emailCommunication?.value ||
      emailCommunication?.value === ChannelValue.UNKNOWN
    ) {
      return intl.formatMessage(messages.communicationPageMakeSelection);
    }
    return intl.formatMessage(
      emailCommunication.value === ChannelValue.OPT_IN
        ? messages.communicationPageEmailOptIn
        : messages.communicationPageEmailOptOut
    );
  }, [communicationData, intl]);

  const getSmsValue = useCallback(() => {
    const smsCommunication = getInitialCommunication(ChannelType.SMS);

    if (
      !smsCommunication?.value ||
      smsCommunication?.value === ChannelValue.UNKNOWN
    )
      return intl.formatMessage(messages.communicationPageMakeSelection);

    return intl.formatMessage(
      smsCommunication.value === ChannelValue.OPT_IN
        ? messages.communicationPageSmsOptIn
        : messages.communicationPageSmsOptOut
    );
  }, [communicationData, intl]);

  return (
    <BlueHeaderContent
      blueHeaderTitle={intl.formatMessage(messages.communicationPageBackButton)}
      backArrowHeaderRoute={routes.profile}
    >
      {!profileDataIsLoading && profileData && (
        <>
          <InfoCard
            titleText={intl.formatMessage(
              messages.communicationPagePrimaryTitle
            )}
            titleEditButtonLinkRoute={routes.updateCommunicationPreferences}
          >
            <InfoCardField
              label={intl.formatMessage(messages.communicationPageEmail)}
              value={getEmailValue()}
              data-testid="emailCommunicationValue"
            />
            <InfoCardField
              label={intl.formatMessage(messages.communicationPageSMS)}
              value={getSmsValue()}
              data-testid="smsCommunicationValue"
            />
          </InfoCard>
        </>
      )}
      <SpinnerView isLoading={profileDataIsLoading} />
    </BlueHeaderContent>
  );
};

export default CommunicationPreferencesPage;
