import React, { FC, useContext, useEffect, useState } from "react";
import LauncherIcon from "@app/neva/components/launcher/icon";
import LauncherPopup from "@app/neva/components/launcher/popup";
import "./launcherContainer.scss";
import { APIContext, EventsContext, NevaContext } from "@app/neva/provider";
import { IntlContext } from "@app/provider";

type PopupDataType = {
  text: string;
  delay: number;
};

const LauncherContainer: FC = () => {
  const { currentLanguage } = useContext(IntlContext);
  const { setIsWidgetOpen } = useContext(NevaContext);
  const {
    apiProviderState: { chatId, widgetSettings },
  } = useContext(APIContext);
  const {
    eventsProviderState: { isEndOfConversation },
  } = useContext(EventsContext);

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState<PopupDataType | null>(null);

  useEffect(() => {
    if (!widgetSettings) {
      return;
    }

    const languageConfig = widgetSettings.languageConfigs[currentLanguage];
    if (!chatId) {
      const greeting = languageConfig.greeting;
      setPopupData({
        text: greeting.greetingText,
        delay: greeting.delaySeconds,
      });
    } else {
      const widgetPopupMessage = languageConfig.widgetPopup.widgetPopupMessage;
      if (widgetPopupMessage.showOnClosing) {
        const text = isEndOfConversation
          ? widgetPopupMessage.onClosingEndChatText
          : widgetPopupMessage.onClosingText;
        setPopupData({
          text,
          delay: widgetPopupMessage.delayOnClosing,
        });
      }
    }
  }, [widgetSettings, chatId, isEndOfConversation, currentLanguage]);

  useEffect(() => {
    if (popupData) {
      const timeoutId = setTimeout(
        () => setShowPopup(true),
        popupData.delay * 1000
      );
      return () => clearTimeout(timeoutId);
    }
  }, [popupData]);

  const handleOpen = () => {
    setIsWidgetOpen(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <div className="launcher_container" data-testid="launcher-container">
      {showPopup && popupData && (
        <LauncherPopup
          text={popupData.text}
          handleSubmit={handleOpen}
          handleClose={handleClose}
        />
      )}
      <LauncherIcon handleClick={handleOpen} />
    </div>
  );
};

export default LauncherContainer;
