import React, { FC } from "react";
import { Spinner } from "@natera/material/lib/progress";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  loaderLabel: {
    id: "loaderLabel",
    defaultMessage: "Loading",
  },
});

const SpinnerLoader: FC = () => {
  const intl = useIntl();

  return (
    <Spinner
      indeterminate
      aria-label={intl.formatMessage(messages.loaderLabel)}
    />
  );
};

export default SpinnerLoader;
