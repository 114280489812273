export const maskPhone = (phone?: string): string => {
  if (!phone) return "";
  if (phone[0] !== "+") {
    phone = "+" + phone;
  }
  phone = `${phone.slice(0, 2)} XXX-XXX-${phone.slice(-4)}`;

  return phone;
};

export default maskPhone;
