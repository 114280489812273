import React, { FC } from "react";
import classNames from "classnames";

import "./progress.scss";

interface ProgressProps {
  value: number;
  className?: string;
}

const Progress: FC<ProgressProps> = ({ value, className }) => {
  return (
    <div className={classNames("progress__container", className)}>
      <div className="progress" style={{ width: `${value}%` }}></div>
    </div>
  );
};

export default Progress;
