import React, { FC, useContext, useEffect } from "react";
import {
  APIProvider,
  DialogsProvider,
  EventsProvider,
  NevaContext,
} from "@app/neva/provider";
import NevaBotWrapper from "@app/neva/components/nevaBotWrapper";
import { useLogEvent } from "@app/neva/hooks";
import { NEVA_HEAP_EVENTS } from "@app/neva/models/constants";

const Neva: FC = () => {
  const { isNevaDisabled, isFirst } = useContext(NevaContext);

  const { logEvent } = useLogEvent();

  useEffect(() => {
    initialization();
    sendIsFirstEvent();
  }, []);

  const initialization = () => {
    logEvent({
      eventAction: "bot_loader_start",
      eventLabel: "System Action",
    });

    logEvent({
      eventAction: "bot_loader_create_iframe",
      eventLabel: "System Action",
    });
  };

  const sendIsFirstEvent = () => {
    const eventName = isFirst
      ? NEVA_HEAP_EVENTS.BOT_LOADED_FIRST
      : NEVA_HEAP_EVENTS.BOT_LOADED_NTH;
    logEvent({
      eventAction: eventName,
      eventLabel: "User Action",
    });
  };

  if (isNevaDisabled) {
    return null;
  }

  return (
    <APIProvider>
      <DialogsProvider>
        <EventsProvider>
          <NevaBotWrapper />
        </EventsProvider>
      </DialogsProvider>
    </APIProvider>
  );
};

export default Neva;
