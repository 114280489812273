import { MultipleInsurancesForm } from "./multipleInsurancesForm";
import { StepperContent } from "@app/components/ui/layout";
import { MultipleInsurance } from "@app/components/ui/inputs/multipleInsurancesSelector/multipleInsurancesSelector";
import {
  PaymentType,
  SubSteps,
} from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { StepperContext } from "@natera/stepper";
import React, { useContext, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  CommercialStep,
  GovernmentStep,
} from "@app/components/simpleOrder/payment/steps";
import {
  MultiplePaymentMethodType,
  PaymentMethodType,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import { Steps } from "@app/pages/private/simpleOrder";

const messages = defineMessages({
  multipleInsurancesStepHeaderTitle: {
    id: "multipleInsurancesStepHeaderTitle",
    defaultMessage: "Payment Method",
  },
  multipleInsurancesStepHeaderSubTitle: {
    id: "multipleInsurancesStepHeaderSubTitle",
    defaultMessage: "More than one insurance",
  },
  multipleInsurancesStepHeaderTextSecondType: {
    id: "multipleInsurancesStepHeaderTextSecondType",
    defaultMessage:
      "Select the <b>second type</b> of insurance that you would like to use to cover the cost of your test.",
  },
  multipleInsurancesFormSecondInsurancesTitle: {
    id: "multipleInsurancesFormSecondInsurancesTitle",
    defaultMessage: "Second insurance type",
  },
});

type Props = {
  onConfirm: (data: unknown) => void;
};

const MultipleInsurancesSecondStep: React.FC<Props> = ({ onConfirm }) => {
  const intl = useIntl();
  const {
    goBack,
    resolve,
    getData,
    createStep,
    removeStep,
    getCurrentStep,
  } = React.useContext(StepperContext);

  const stepData = getData(SubSteps.MULTIPLE_SECOND);
  const currentStepIdx = getCurrentStep()?.index;

  const { getSubStepData, resolveSubStep } = useContext(
    SimpleOrderStepperContext
  );
  const subStepsData = getSubStepData(
    Steps.PAYMENT_METHOD
  ) as MultiplePaymentMethodType;

  const getSelectedInsurance = () => {
    const paymentMethod =
      subStepsData?.paymentMethodInfo?.second?.paymentMethod;
    if (paymentMethod === PaymentType.COMMERCIAL) {
      return MultipleInsurance.Commercial;
    } else if (paymentMethod === PaymentType.GOVERNMENT_ISSUED) {
      return MultipleInsurance.Government;
    }
    return stepData;
  };

  const [selectedInsurance, setSelectedInsurance] = React.useState<
    MultipleInsurance | undefined
  >(getSelectedInsurance());

  useEffect(() => {
    if (selectedInsurance) {
      handleSelectInsurance(selectedInsurance);
    }
  }, []);

  const addSteps = (insurance: MultipleInsurance) => {
    if (insurance === MultipleInsurance.Commercial) {
      createStep(SubSteps.SELECT_COMMERCIAL_COMPANY_SECOND, {
        children: <CommercialStep onConfirm={onConfirm} />,
        index: currentStepIdx ? currentStepIdx + 1 : undefined,
      });
    } else {
      createStep(SubSteps.SELECT_GOVERNMENT_COMPANY_SECOND, {
        children: <GovernmentStep onConfirm={onConfirm} />,
        index: currentStepIdx ? currentStepIdx + 1 : undefined,
      });
    }
  };

  const removeSteps = (insurance: MultipleInsurance | undefined) => {
    if (insurance === MultipleInsurance.Commercial) {
      removeStep(SubSteps.SELECT_COMMERCIAL_COMPANY_SECOND);
      removeStep(SubSteps.PROVIDE_COMMERCIAL_INSURANCE_SECOND);
      removeStep(SubSteps.ENTER_OTHER_COMMERCIAL_COMPANY_SECOND);
    }
    if (insurance === MultipleInsurance.Government) {
      removeStep(SubSteps.SELECT_GOVERNMENT_COMPANY_SECOND);
      removeStep(SubSteps.PROVIDE_GOVERNMENT_INSURANCE_SECOND);
      removeStep(SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY_SECOND);
    }
  };

  const handleSelectInsurance = (insurance: MultipleInsurance) => {
    removeSteps(selectedInsurance);
    addSteps(insurance);

    setSelectedInsurance(insurance);
  };

  const handleSubmit = (insurance: string) => {
    const paymentMethod =
      insurance === MultipleInsurance.Commercial
        ? PaymentType.COMMERCIAL
        : PaymentType.GOVERNMENT_ISSUED;

    const paymentMethodInfoSecond: PaymentMethodType = { paymentMethod };

    if (
      subStepsData?.paymentMethodInfo?.second?.paymentMethod !== paymentMethod
    )
      paymentMethodInfoSecond.paymentMethodInfo = undefined;

    resolveSubStep({
      stepId: Steps.PAYMENT_METHOD,
      subStepData: {
        paymentMethodInfo: { second: { ...paymentMethodInfoSecond } },
      },
    });
    resolve(insurance);
  };

  const handleBack = () => {
    goBack();
  };

  return (
    <StepperContent
      title={intl.formatMessage(messages.multipleInsurancesStepHeaderTitle)}
      subTitle={intl.formatMessage(
        messages.multipleInsurancesStepHeaderSubTitle
      )}
      description={intl.formatMessage(
        messages.multipleInsurancesStepHeaderTextSecondType,
        { b: (...chunks) => <b>{chunks}</b> }
      )}
    >
      <MultipleInsurancesForm
        selectedInsurance={selectedInsurance}
        onSelect={handleSelectInsurance}
        onSubmit={handleSubmit}
        goBack={handleBack}
        label={intl.formatMessage(
          messages.multipleInsurancesFormSecondInsurancesTitle
        )}
      />
    </StepperContent>
  );
};

export default MultipleInsurancesSecondStep;
