import React, { FC, useEffect, useRef } from "react";
import closeSvg from "@app/neva/assets/svg/close.svg";
import "./launcherPopup.scss";
import Svg from "@natera/material/lib/svg";

export interface LauncherPopupProps {
  text: string;
  handleSubmit: () => void;
  handleClose: () => void;
}

const LauncherPopup: FC<LauncherPopupProps> = ({
  text,
  handleSubmit,
  handleClose,
}) => {
  return (
    <div className="launcher-popup__container" data-testid="launcher-popup">
      <button
        className="launcher-popup__close-icon-button"
        onClick={handleClose}
      >
        <Svg
          className="launcher-popup__close_icon"
          content={closeSvg}
          role="img"
        />
      </button>
      <div className="launcher-popup__text-container" onClick={handleSubmit}>
        <p className="launcher-popup__text">{text}</p>
      </div>
    </div>
  );
};

export default LauncherPopup;
