import { SubSteps } from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { StepId } from "@natera/stepper";

export const isFirstMultipleStep = (currentStepId?: StepId): boolean =>
  Boolean(
    currentStepId &&
      [
        SubSteps.SELECT_COMMERCIAL_COMPANY_FIRST,
        SubSteps.SELECT_GOVERNMENT_COMPANY_FIRST,
      ].includes(currentStepId as SubSteps)
  );

export const isSecondMultipleStep = (currentStepId?: StepId): boolean =>
  Boolean(
    currentStepId &&
      [
        SubSteps.SELECT_COMMERCIAL_COMPANY_SECOND,
        SubSteps.SELECT_GOVERNMENT_COMPANY_SECOND,
      ].includes(currentStepId as SubSteps)
  );

export const isCommercialOrGovernmentInsuranceFirst = (
  currentStepId?: StepId
): boolean =>
  Boolean(
    currentStepId &&
      [
        SubSteps.PROVIDE_COMMERCIAL_INSURANCE_FIRST,
        SubSteps.PROVIDE_GOVERNMENT_INSURANCE_FIRST,
        SubSteps.ENTER_OTHER_COMMERCIAL_COMPANY_FIRST,
        SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY_FIRST,
      ].includes(currentStepId as SubSteps)
  );

export const isCommercialOrGovernmentInsuranceSecond = (
  currentStepId?: StepId
): boolean =>
  Boolean(
    currentStepId &&
      [
        SubSteps.PROVIDE_COMMERCIAL_INSURANCE_SECOND,
        SubSteps.PROVIDE_GOVERNMENT_INSURANCE_SECOND,
        SubSteps.ENTER_OTHER_COMMERCIAL_COMPANY_SECOND,
        SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY_SECOND,
      ].includes(currentStepId as SubSteps)
  );
