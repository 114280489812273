import { logMsg } from "@app/neva/helpers";
import { useContext } from "react";
import { ConfigContext } from "@app/provider";
import { NevaContext } from "@app/neva/provider";

export type UseLogEventController = {
  logEvent: (event: EventInterface) => void;
};

type HeapTrackInterface = (
  eventName: string,
  params: Record<string, unknown>
) => void;

// ToDo: eliminate the need for opera checking
declare global {
  interface Window {
    opera: unknown;
    heap: {
      track: HeapTrackInterface;
    };
  }
}

interface EventInterface {
  eventAction: string;
  eventLabel: string;
}

export const useLogEvent = (): UseLogEventController => {
  const {
    config: {
      nevaConfig: { widgetLogsEnabled },
    },
  } = useContext(ConfigContext);
  const { startDate, botId, pcop } = useContext(NevaContext);

  const logEvent = (event: EventInterface) => {
    if (!heap || !window) {
      return;
    }

    const nevaTime = Math.abs(+new Date() - +startDate);
    const eventCategory = [botId, pcop].filter(Boolean).join("_");
    const eventData = {
      event_category: eventCategory,
      event_label: event.eventLabel,
      event_action: event.eventAction,
      event_time_ms: nevaTime,
    };

    logMsg(widgetLogsEnabled, "logEvent" + JSON.stringify(eventData));
    window.dispatchEvent(new CustomEvent("nevaLogEvent"));

    window.heap.track(`UPP_NEVA_${event.eventAction}`, eventData);
  };

  return {
    logEvent,
  };
};
