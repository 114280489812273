import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";

import "../welcome/welcomeScheduleBloodDraw.scss";
import NoContentView from "../../../private/noContentView";
import { LinkHeap } from "@app/components";
import acknowledgements from "@etc/acknowledgements.json";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";

const messages = defineMessages({
  orderInWrongStateScreenTitle: {
    id: "orderInWrongStateScreenTitle",
    defaultMessage:
      "We're sorry but we can't schedule a{br} blood draw at this time.",
  },
  orderInWrongStateScreenDescription: {
    id: "orderInWrongStateScreenDescription",
    defaultMessage:
      "If you would like to schedule a draw or get more information you can {contactUs}",
  },
  orderInWrongStateScreenContactUs: {
    id: "orderInWrongStateScreenContactUs",
    defaultMessage: "Contact Us",
  },
});

const OrderInWrongStateScreen: FC = () => {
  const intl = useIntl();

  return (
    <NoContentView
      title={intl.formatMessage(messages.orderInWrongStateScreenTitle, {
        br: <br />,
      })}
      message={intl.formatMessage(messages.orderInWrongStateScreenDescription, {
        contactUs: (
          <LinkHeap
            target="_blank"
            key="privacyPolicy"
            rel="noreferrer"
            to={{ pathname: acknowledgements.links.contactUs }}
            heapEventName={HEAP_EVENTS.upp_click_contactnatera}
            heapEventProps={{
              location: HeapEventLocation.unable_to_schedule_page,
            }}
          >
            {intl.formatMessage(messages.orderInWrongStateScreenContactUs)}
          </LinkHeap>
        ),
      })}
    />
  );
};

export default OrderInWrongStateScreen;
