import React, { FC, KeyboardEvent, MouseEvent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDialog } from "@natera/platform/lib/hooks";
import { ChatMessageDialog } from "@app/neva/components/dialogs";
import { EventDataMessageType, KeyTypes } from "@app/neva/models";
import "./imageMessage.scss";

interface Props {
  message: EventDataMessageType;
  scrollToBottom: () => void;
}

const messages = defineMessages({
  imageMessageAltText: {
    id: "imageMessageAltText",
    defaultMessage: "Expand image",
  },
});

const ImageMessage: FC<Props> = ({ message, scrollToBottom }) => {
  const intl = useIntl();
  const chatMessageDialog = useDialog(ChatMessageDialog);
  const { imageUrl, imageAltText } = message;

  const handleImageClick = (
    e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    chatMessageDialog.open({
      onClose: chatMessageDialog.close,
      data: {
        imageUrl,
        imageAltText,
      },
    });
  };

  const keyDownHandler = (e: KeyboardEvent<HTMLButtonElement>) => {
    const key = e.key.toLowerCase();
    switch (key) {
      case KeyTypes.ENTER:
      case KeyTypes.SPACE:
        handleImageClick(e);
        break;
    }
  };

  return (
    <>
      <button
        className="image-message__wrapper"
        onClick={handleImageClick}
        onKeyDown={keyDownHandler}
      >
        <img
          className="image-message__template-image"
          src={imageUrl}
          alt={intl.formatMessage(messages.imageMessageAltText)}
          onLoad={() => scrollToBottom()}
        />
      </button>
      {chatMessageDialog.getDialog()}
    </>
  );
};

export default ImageMessage;
