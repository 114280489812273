import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { useDialog } from "@natera/platform/lib/hooks";
import { Communication } from "@app/provider/profile/types";
import { InfoCard } from "@app/components";
import UpdateCommunicationDialog from "./updateCommunicationDialog";
import * as R from "ramda";
import { routes } from "@app/routing";
import { HEAP_EVENTS } from "@app/provider/types";
import "./updateCommunicationForm.scss";
import { CommunicationsPreferencesForm } from "@app/components/forms";

const messages = defineMessages({
  updateCommunicationFormTitle: {
    id: "updateCommunicationFormTitle",
    defaultMessage: "Adjust Communication Preferences",
  },
});

export interface UpdateCommunicationFormProps {
  communicationData?: Communication[];
  handleSubmit: (communicationData: Communication[]) => void;
  handleCancel?: () => void;
  notification?: React.ReactNode;
  className?: string;
}

const UpdateCommunicationForm: FC<UpdateCommunicationFormProps> = ({
  communicationData,
  handleSubmit,
  handleCancel,
  notification,
  className,
}) => {
  const intl = useIntl();
  const updateCommunicationDialog = useDialog(UpdateCommunicationDialog);
  const history = useHistory();

  const closeDialog = useCallback(() => updateCommunicationDialog.close(), [
    updateCommunicationDialog,
  ]);

  const isAccountCreation = R.includes(history.location.pathname, [
    routes.confirmation,
    routes.setupProfile,
  ]);

  const onSubmit = (communications: Communication[]) => {
    if (isAccountCreation) {
      heap.track(HEAP_EVENTS.upp_communication_pref);
    }

    updateCommunicationDialog.open({
      closeDialog,
      update: () => handleSubmit(communications),
    });
  };
  return (
    <InfoCard
      titleText={intl.formatMessage(messages.updateCommunicationFormTitle)}
      notification={notification}
      className={className}
    >
      {updateCommunicationDialog.getDialog()}
      <CommunicationsPreferencesForm
        communicationData={communicationData}
        onSubmit={onSubmit}
        onCancel={handleCancel}
      />
    </InfoCard>
  );
};

export default UpdateCommunicationForm;
