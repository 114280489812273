import * as React from "react";
import classNames from "classnames";
import { defineMessages, useIntl } from "react-intl";

import { PasswordComplexityKeys } from "@app/provider/passwordPolicy";
import { Notification } from "@app/components";
import Svg from "@natera/material/lib/svg";

import CheckmarkIcon from "@assets/svg/icons/checkmark.svg";
import PointIcon from "@assets/svg/icons/point.svg";
import LockIcon from "@assets/svg/icons/lock.svg";
import "./passwordNotice.scss";

const messages = defineMessages({
  passwordNoticeTitle: {
    id: "passwordNoticeTitle",
    defaultMessage: "Password must include:",
  },
  passwordRuleNumbers: {
    id: "passwordRuleNumbers",
    defaultMessage: "must contain numbers",
  },
  passwordRuleUppercase: {
    id: "passwordRuleUppercase",
    defaultMessage: "must contain uppercase letters",
  },
  passwordRuleLowercase: {
    id: "passwordRuleLowercase",
    defaultMessage: "must contain lowercase letters",
  },
  passwordRuleSymbol: {
    id: "passwordRuleSymbol",
    defaultMessage: "must have at lest one @#$ symbol",
  },
  passwordRuleLength: {
    id: "passwordRuleLength",
    defaultMessage: "must be greater than {length} characters",
  },
  passwordRuleExcludeUsername: {
    id: "passwordRuleExcludeUsername",
    defaultMessage: "must exclude user name",
  },
});

interface PasswordPolicyComplexityValidationStateRule {
  isValid: boolean;
  value: number | boolean;
}

export type PasswordPolicyComplexityValidationState = Record<
  PasswordComplexityKeys,
  PasswordPolicyComplexityValidationStateRule | undefined
>;

interface PasswordNoticeProps {
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
  passwordPolicyComplexity: PasswordPolicyComplexityValidationState;
}

const PasswordNotice = React.forwardRef<HTMLDivElement, PasswordNoticeProps>(
  ({ className, passwordPolicyComplexity, ...props }, ref) => {
    const intl = useIntl();

    const renderRule = (
      title: string,
      isValid?: boolean
    ): React.ReactElement => (
      <li
        className={classNames("password-notice__rule", {
          "password-notice__rule_invalid": !isValid,
        })}
        key={title}
      >
        <Svg content={isValid ? CheckmarkIcon : PointIcon} />{" "}
        <span>{title}</span>
      </li>
    );

    const renderRules = () => {
      const keys = Object.keys(
        passwordPolicyComplexity
      ) as PasswordComplexityKeys[];

      const rules: React.ReactElement[] = [];

      for (const key of keys) {
        switch (key) {
          case "minLength": {
            if (passwordPolicyComplexity[key]?.value) {
              rules.push(
                renderRule(
                  intl.formatMessage(messages.passwordRuleLength, {
                    length: passwordPolicyComplexity[key]?.value,
                  }),
                  passwordPolicyComplexity[key]?.isValid
                )
              );
            }
            break;
          }
          case "minLowerCase": {
            if (passwordPolicyComplexity[key] !== undefined) {
              rules.push(
                renderRule(
                  intl.formatMessage(messages.passwordRuleLowercase),
                  passwordPolicyComplexity[key]?.isValid
                )
              );
            }
            break;
          }
          case "minUpperCase": {
            if (passwordPolicyComplexity[key] !== undefined) {
              rules.push(
                renderRule(
                  intl.formatMessage(messages.passwordRuleUppercase),
                  passwordPolicyComplexity[key]?.isValid
                )
              );
            }
            break;
          }
          case "minNumber": {
            if (passwordPolicyComplexity[key] !== undefined) {
              rules.push(
                renderRule(
                  intl.formatMessage(messages.passwordRuleNumbers),
                  passwordPolicyComplexity[key]?.isValid
                )
              );
            }
            break;
          }
          case "minSymbol": {
            if (passwordPolicyComplexity[key] !== undefined) {
              rules.push(
                renderRule(
                  intl.formatMessage(messages.passwordRuleSymbol),
                  passwordPolicyComplexity[key]?.isValid
                )
              );
            }
            break;
          }
          case "excludeUsername": {
            if (passwordPolicyComplexity[key] !== undefined) {
              rules.push(
                renderRule(
                  intl.formatMessage(messages.passwordRuleExcludeUsername),
                  passwordPolicyComplexity[key]?.isValid
                )
              );
            }
            break;
          }
        }
      }

      return rules;
    };

    return (
      <Notification
        className={classNames("password-notice__container", className)}
        type="note"
        icon={LockIcon}
        ref={ref}
        {...props}
      >
        <div className="password-notice__content">
          <div className="password-notice__content__list">
            <h2 className="password-notice__title">
              {intl.formatMessage(messages.passwordNoticeTitle)}
            </h2>
            <ul>{renderRules()}</ul>
          </div>
          <div className="whitespace" />
        </div>
      </Notification>
    );
  }
);

PasswordNotice.displayName = "PasswordNotice";

export default PasswordNotice;
