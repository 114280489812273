import React, { useState } from "react";
import { HeapVideoContentType, HeapVideoOrderData } from "@app/provider/types";
import { useWistiaScript } from "@app/hooks";
import { bindHeapVideo } from "@app/utils/bindHeapVideo";
import { LoadingContext } from "@natera/platform/lib/components/context";
import classNames from "classnames";

import "./videoPlayer.scss";

export enum VideoType {
  SALIVA = "SALIVA",
  HORIZON = "HORIZON",
  PANORAMA = "PANORAMA",
}

export interface IVideo {
  percentWatched(): number;

  unbind(): void;

  bind(arg0: string, arg1: (arg?: unknown) => void): unknown;

  duration(): number;
}

type Props = {
  id: string;
  thumbnailAltText: string;
  heapVideoContentType?: HeapVideoContentType;
  heapVideoOrderData?: HeapVideoOrderData;
};

const VideoPlayer: React.FC<Props> = ({
  id,
  thumbnailAltText,
  heapVideoContentType,
  heapVideoOrderData,
}) => {
  useWistiaScript();
  const [videoIsLoading, setVideoIsLoading] = useState<boolean>(true);

  React.useEffect(() => {
    window._wq = window._wq || [];

    const bindObj = {
      id,
      options: {
        thumbnailAltText: thumbnailAltText,
      },
      onReady: (video$: IVideo) => {
        setVideoIsLoading(false);
        bindHeapVideo(video$, heapVideoContentType, heapVideoOrderData);
      },
    };

    window._wq.push(bindObj);

    return () => {
      window._wq.push({ revoke: bindObj });
    };
  }, [
    heapVideoContentType,
    heapVideoOrderData,
    thumbnailAltText,
    id,
    setVideoIsLoading,
  ]);

  React.useEffect(() => {
    return () => {
      window._wq.push({ replaceWith: () => id });
    };
  }, [id]);

  const wistiaId = `wistia_embed wistia_async_${id}`;

  return (
    <div className="wrap">
      <LoadingContext isLoading={videoIsLoading} />
      <div
        className={classNames("video-wrap", {
          "video-wrap--loading": videoIsLoading,
        })}
      >
        <div className="wistia_responsive_padding">
          <div
            id="wistia_responsive_wrapper"
            className="wistia_responsive_wrapper"
            data-testid="wistia_responsive_wrapper"
          >
            <div key={wistiaId} className={wistiaId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
