import React from "react";
import R from "ramda";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/platform/lib/components/form";
import { DateRange, Timeslots } from "@natera/mobile-phlebotomy/lib";
import {
  TimezoneSelector,
  timezoneValidationName,
} from "@app/components/ui/inputs/timezoneSelector";
import { ErrorProvider } from "@app/provider";
import { getLastSunday, getTimeZones, getDisabledSlots } from "@app/utils";
import TimeslotsWarning from "../../sampleDraw/mobilePhlebotomy/timeslots/timeslotsWarning";
import { useErrorController } from "@natera/platform/lib/hooks";

import "@natera/mobile-phlebotomy/lib/theme/main.scss";
import {
  renderChipTime,
  renderSelectedSlot,
  setRemoveTimeslotAriaLabel,
} from "@app/utils/timeslotsUtils";

const DAYS_AVAILABLE_FROM_GAP = 5;
const DAYS_AVAILABLE_TO_GAP = 15;

const messages = defineMessages({
  mobilePhlebotomyTimeslotsBack: {
    id: "mobilePhlebotomyTimeslotsBack",
    defaultMessage: "Back",
  },
  mobilePhlebotomyTimeslotsValidationMessage: {
    id: "mobilePhlebotomyTimeslotsValidationMessage",
    defaultMessage: "This information is required.",
  },
  mobilePhlebotomyTimeslotsPlaceholder: {
    id: "mobilePhlebotomy.timeslots.placeholder",
    defaultMessage: "Time slot #{number}",
  },
});

export type TimeslotsFormData = {
  timeSlots: { [x: string]: string };
  timeZone: string;
};

type Props = {
  handleConfirm: (data: TimeslotsFormData) => void;
  handleBack: () => void;
  confirmButtonLabel: string;
  isLoading?: boolean;
  isModify?: boolean;
};

const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const now = new Date();
const today = new Date(now.setUTCHours(0, 0, 0, 0));

const availableFrom = new Date(today);
availableFrom.setDate(availableFrom.getDate() + DAYS_AVAILABLE_FROM_GAP);

const availableTo = new Date(today);
availableTo.setDate(availableTo.getDate() + DAYS_AVAILABLE_TO_GAP);

const availableFromUTCDate = new Date(
  new Date(availableFrom).setUTCHours(0, 0, 0, 0)
);
const startWeek =
  availableFromUTCDate.getUTCDay() === 0
    ? availableFromUTCDate
    : getLastSunday(new Date(availableFrom));
const endWeek = getLastSunday(new Date(availableTo));
const disableSlots = getDisabledSlots(new Date(availableTo));

const TimeslotsForm: React.FC<Props> = ({
  handleConfirm,
  handleBack,
  confirmButtonLabel,
  isLoading,
  isModify,
}) => {
  const intl = useIntl();
  const [selectedSlots, setSelectedSlots] = React.useState<DateRange[]>([]);

  const selectedTimeZone = R.find(R.propEq("browserValue", browserTimeZone))(
    getTimeZones(intl)
  );

  const [timeZone, setTimeZone] = React.useState<string | undefined>(
    selectedTimeZone?.browserValue
  );
  const [showNotification, setShowNotification] = React.useState<boolean>(
    false
  );
  const errorController = useErrorController();

  const selectedSlotsHandler = (value: DateRange[]) => {
    setSelectedSlots(value);
  };

  const onConfirm = () => {
    setShowNotification(false);
    errorController.clearValidationError(timezoneValidationName);

    const minNumberOfSelectedSlots = 3;

    if (!timeZone) {
      errorController.setValidationError(
        timezoneValidationName,
        intl.formatMessage(messages.mobilePhlebotomyTimeslotsValidationMessage)
      );

      return;
    }

    if (selectedSlots.length < minNumberOfSelectedSlots) {
      setShowNotification(true);
    }

    if (timeZone && selectedSlots.length >= minNumberOfSelectedSlots) {
      const formattedTimeSlots = R.mergeAll(
        selectedSlots.map((timeSlot, index) => ({
          [`start${index + 1}`]: timeSlot["start"].toISOString(),
          [`end${index + 1}`]: timeSlot["end"].toISOString(),
        }))
      );

      handleConfirm({
        timeSlots: formattedTimeSlots,
        timeZone: timeZone,
      });
    }
  };

  const handleTimezoneSelect = (timezone: string) => {
    errorController.clearValidationError(timezoneValidationName);
    setTimeZone(timezone);
  };

  const setRemoveTimeslotAriaLabelForm = (slot: DateRange) => {
    return setRemoveTimeslotAriaLabel(slot, intl);
  };

  return (
    <>
      <div className="timeslots_container" data-testid="timeslots_container">
        <ErrorProvider controller={errorController}>
          <TimezoneSelector value={timeZone} onSelect={handleTimezoneSelect} />
        </ErrorProvider>
        {showNotification && <TimeslotsWarning />}
        <Timeslots
          onChange={selectedSlotsHandler}
          startWeek={startWeek}
          currentDate={now}
          selectedSlots={selectedSlots}
          showSlotPlaceholder
          availableFrom={availableFrom}
          endWeek={endWeek}
          disabledSlots={disableSlots}
          mobile
          oneSlotPerDay
          renderSelectedSlotTime={renderSelectedSlot}
          renderSlotChipTime={renderChipTime}
          setRemoveTimeslotAriaLabel={setRemoveTimeslotAriaLabelForm}
          data-testid="timeslots_selector"
        />
      </div>

      <div className="form-buttons">
        <Button
          onClick={onConfirm}
          raised
          loading={isLoading}
          data-testid="submit"
        >
          {confirmButtonLabel}
        </Button>
        <Button onClick={handleBack}>
          {intl.formatMessage(messages.mobilePhlebotomyTimeslotsBack)}
        </Button>
      </div>
    </>
  );
};

export default TimeslotsForm;
