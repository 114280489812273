import { DocumentNode, gql } from "@apollo/client";

class PasswordPolicyService {
  public static GetPasswordPolicy = (): DocumentNode => gql`
    query GetPasswordPolicy {
      getPasswordPolicy {
        complexity {
          minLength
          minLowerCase
          minUpperCase
          minNumber
          minSymbol
          excludeUsername
        }
      }
    }
  `;
}

export default PasswordPolicyService;
