import React from "react";
import { SelectAddressForm } from "@app/components/forms";
import { Address } from "@app/provider";
import { Button } from "@natera/platform/lib/components/form";
import { defineMessages, useIntl } from "react-intl";

export const messages = defineMessages({
  sampleDrawKitSelectAddressNextButtonText: {
    id: "sampleDrawKitSelectAddressNextButtonText",
    defaultMessage: "Next",
  },
  sampleDrawKitSelectAddressBackButtonText: {
    id: "sampleDrawKitSelectAddressBackButtonText",
    defaultMessage: "Back",
  },
  sampleDrawKitSelectAddressAddNewAddressButton: {
    id: "sampleDrawKitSelectAddressAddNewAddressButton",
    defaultMessage: "Add a New Address",
  },
});

type Props = {
  addresses: Address[];
  onAddNewAddress: () => void;
  onSubmit: (address: Partial<Address>) => void;
  onBack: () => void;
};

const SampleDrawKitSelectAddress: React.FC<Props> = ({
  addresses,
  onAddNewAddress,
  onSubmit,
  onBack,
}) => {
  const intl = useIntl();

  return (
    <SelectAddressForm
      addresses={addresses}
      onSubmit={onSubmit}
      actions={
        <>
          <Button
            onClick={onAddNewAddress}
            type="button"
            outlined
            data-testid="enter-new-address-button"
          >
            {intl.formatMessage(
              messages.sampleDrawKitSelectAddressAddNewAddressButton
            )}
          </Button>
          <Button type="submit" data-testid="submit" raised>
            {intl.formatMessage(
              messages.sampleDrawKitSelectAddressNextButtonText
            )}
          </Button>
          <Button type="reset" data-testid="cancel" onClick={onBack}>
            {intl.formatMessage(
              messages.sampleDrawKitSelectAddressBackButtonText
            )}
          </Button>
        </>
      }
    />
  );
};

export default SampleDrawKitSelectAddress;
