import React, { FC, useContext, useState } from "react";
import {
  RadioButton,
  RadioGroup,
} from "@natera/platform/lib/components/form/field";
import { Button } from "@natera/platform/lib/components/form";
import { defineMessages, useIntl } from "react-intl";
import { StepperContext } from "@natera/stepper";
import { Address } from "@app/provider/profile";
import { FormField } from "@app/components";
import { getAddressAsString } from "@app/utils";
import {
  KitShippingDetailsStepResolve,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import { Steps } from "@app/pages/private/simpleOrder";
import "./patientAddresses.scss";

const messages = defineMessages({
  mobilePhlebotomyPatientAddressesEnterNewAddressButton: {
    id: "mobilePhlebotomyPatientAddressesEnterNewAddressButton",
    defaultMessage: "Enter a New Address",
  },
  mobilePhlebotomyPatientAddressesNext: {
    id: "mobilePhlebotomyPatientAddressesNext",
    defaultMessage: "Next",
  },
  mobilePhlebotomyPatientAddressesBack: {
    id: "mobilePhlebotomyPatientAddressesBack",
    defaultMessage: "Back",
  },
  kitShippingAddNewAddressButton: {
    id: "kitShippingAddNewAddressButton",
    defaultMessage: "Add a New Address",
  },
});

export interface MobilePhlebotomyPatientAddressesProps {
  addresses: Address[];
  handleEnterPatientAddressButtonClick: () => void;
  handleKitShippingSubmit?: (address: Address | undefined) => void;
  handleKitShippingBack?: () => void;
  isKitShippingStep?: boolean;
}

const PatientAddresses: FC<MobilePhlebotomyPatientAddressesProps> = ({
  addresses,
  handleEnterPatientAddressButtonClick,
  handleKitShippingSubmit,
  handleKitShippingBack,
  isKitShippingStep,
}) => {
  const intl = useIntl();
  const { getCurrentStep, getData, goBack, resolve } = React.useContext(
    StepperContext
  );
  const { getStepData, resolveStep } = useContext(SimpleOrderStepperContext);

  const currentStepId = getCurrentStep()?.stepId;
  const stepData = currentStepId && getData(currentStepId);

  const simpleOrderStepData = getStepData(
    Steps.KIT_SHIPPING_DETAILS
  ) as KitShippingDetailsStepResolve;

  const initialPatientAddressUid =
    stepData?.address?.uid ||
    simpleOrderStepData?.address?.uid ||
    addresses[0].uid ||
    "";

  const [patientAddressUid, setPatientAddressUid] = useState<string>(
    initialPatientAddressUid
  );

  const handlePatientAddressSelect = (addressUid: string) =>
    setPatientAddressUid(addressUid);

  const getSelectedAddress = () =>
    addresses.find(({ uid }) => uid === patientAddressUid);

  const submit = (address?: Address) => {
    resolve({
      address,
    });
  };

  const handleClickSubmit = (address?: Address) => {
    resolveStep({
      stepId: Steps.KIT_SHIPPING_DETAILS,
      stepData: { address },
    });
    handleKitShippingSubmit
      ? handleKitShippingSubmit(address)
      : submit(address);
  };

  const handleBackButtonClick = () => goBack();
  const handleBackButton = handleKitShippingBack ?? handleBackButtonClick;

  const handleNextButtonClick = () => {
    const selectedAddress = getSelectedAddress();
    handleClickSubmit(selectedAddress);
  };

  const enterNewAddressButtonText = intl.formatMessage(
    isKitShippingStep
      ? messages.kitShippingAddNewAddressButton
      : messages.mobilePhlebotomyPatientAddressesEnterNewAddressButton
  );

  return (
    <>
      <RadioGroup
        name="patientAddressesList"
        value={patientAddressUid}
        onChange={handlePatientAddressSelect}
      >
        {addresses.map((address) => (
          <FormField key={address.uid} htmlFor={address.uid}>
            <RadioButton
              name={address.uid}
              data-testid={address.uid}
              className="stepper__item"
              label={
                <div className="address-item-desc">
                  {getAddressAsString(address)}
                </div>
              }
              value={address.uid}
            />
          </FormField>
        ))}
      </RadioGroup>

      <div className="stepper__sub-actions patient-addresses__sub-actions">
        <Button
          onClick={handleEnterPatientAddressButtonClick}
          type="button"
          outlined
          data-testid="enter-new-address-button"
        >
          {enterNewAddressButtonText}
        </Button>
      </div>

      <div
        data-testid="patientAddressesActions"
        className="patient-addresses__actions"
      >
        <Button
          raised
          onClick={handleNextButtonClick}
          data-testid="handle-next-button"
        >
          {intl.formatMessage(messages.mobilePhlebotomyPatientAddressesNext)}
        </Button>
        <Button onClick={handleBackButton} data-testid="handle-back-button">
          {intl.formatMessage(messages.mobilePhlebotomyPatientAddressesBack)}
        </Button>
      </div>
    </>
  );
};

export default PatientAddresses;
