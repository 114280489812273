import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, useContext, useState, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { LinkButton } from "@natera/platform/lib/components/form";
import { ConfigContext, NotificationContext, UserContext } from "@app/provider";
import { Button } from "@natera/material/lib/button";
import { SpinnerView } from "@app/components";
import { routes } from "@app/routing";

import VerifiedAccountIcon from "@assets/svg/account-created.svg";
import Svg from "@natera/material/lib/svg";
import "./resendUpdateEmail.scss";

const messages = defineMessages({
  resendUpdateEmailResendLinkTitle: {
    id: "resendUpdateEmailResendLinkTitle",
    defaultMessage: "Verification Email Sent.",
  },
  resendUpdateEmailResendLinkMessage: {
    id: "resendUpdateEmailResendLinkMessage",
    defaultMessage:
      "We’ve sent you another reset link. Please follow the instructions",
  },
  resendUpdateEmailVerifyEmail: {
    id: "resendUpdateEmailVerifyEmail",
    defaultMessage: "Please verify your new email address",
  },
  resendUpdateEmailInstructions: {
    id: "resendUpdateEmailInstructions",
    defaultMessage: "We've sent you a verification email to",
  },
  resendUpdateEmailVerifyAccount: {
    id: "resendUpdateEmailVerifyAccount",
    defaultMessage: "Click the link to verify your account.",
  },
  resendUpdateEmailResendLink: {
    id: "resendUpdateEmailResendLink",
    defaultMessage: "Resend Verification Link",
  },
  resendUpdateEmailFinish: {
    id: "resendUpdateEmailFinish",
    defaultMessage: "Go to My Account",
  },
});

const ResendUpdateEmail: FC = () => {
  const { updateEmail, updateEmailData, isLoading } = useContext(UserContext);
  const { addNotification, clear } = useContext(NotificationContext);
  const { config } = useContext(ConfigContext);
  const [resendIsDisabled, setResendIsDisabled] = useState(false);
  const resendTimeoutRef = React.useRef<NodeJS.Timeout>();
  const [newEmail, setNewEmail] = useState("");
  const history = useHistory();
  const intl = useIntl();

  const resendEmail = () => {
    clear();
    setResendIsDisabled(true);

    updateEmail(newEmail).then((resp) => {
      if (resp?.newEmail) {
        addNotification({
          title: intl.formatMessage(messages.resendUpdateEmailResendLinkTitle),
          message: intl.formatMessage(
            messages.resendUpdateEmailResendLinkMessage
          ),
        });
      }
    });

    resendTimeoutRef.current = setTimeout(() => {
      setResendIsDisabled(false);

      if (resendTimeoutRef.current) {
        clearTimeout();
      }
    }, config.timeouts.resendTimeout);
  };

  useEffect(() => {
    if (updateEmailData?.newEmail && !newEmail) {
      setNewEmail(updateEmailData.newEmail);
    } else {
      history.replace(routes.contactDetails);
    }
  }, []);

  return (
    <form className="resend-update-email__container">
      <div className="image-container">
        <Svg content={VerifiedAccountIcon} />
      </div>

      <div className="description-container">
        <h2>{intl.formatMessage(messages.resendUpdateEmailVerifyEmail)}</h2>

        <p>{intl.formatMessage(messages.resendUpdateEmailInstructions)}</p>

        <p>
          <HeapPHIDecorator protectText inline>
            <span>{newEmail}</span>
          </HeapPHIDecorator>
        </p>

        <p>{intl.formatMessage(messages.resendUpdateEmailVerifyAccount)}</p>
      </div>

      <div className="buttons-container">
        <Button
          onClick={resendEmail}
          type="button"
          outlined
          disabled={resendIsDisabled}
        >
          {intl.formatMessage(messages.resendUpdateEmailResendLink)}
        </Button>

        <LinkButton to={routes.contactDetails} raised>
          {intl.formatMessage(messages.resendUpdateEmailFinish)}
        </LinkButton>
      </div>

      <SpinnerView isLoading={isLoading} />
    </form>
  );
};

export default ResendUpdateEmail;
