import { TestType } from "@app/provider/testData/types";

// We don't support "empowerpre" yet, nor many of the other testTypes
export const testTypeToBotId = (testType?: TestType): string => {
  switch (testType) {
    case TestType.HORIZON:
      return "horizonpost";
    case TestType.PANORAMA:
      return "panoramapost";
    case TestType.EMPOWER:
      return "empowerpost";
    default:
      return "";
  }
};
