import React, { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { IconButton } from "@natera/material/lib/button";
import { Button } from "@natera/platform/lib/components/form";
import { LoadingContext } from "@natera/platform/lib/components";
import Svg from "@natera/material/lib/svg";
import { ScrollContext } from "@natera/platform/lib/components/scrollbar";
import { TestType } from "@app/provider/testData/types";
import { routes } from "@app/routing";
import { TestResultContext } from "@app/provider";
import Image from "@assets/images/understanding_your_results.png";
import Close from "@assets/svg/icons/close.svg";
import DocumentsMagnifyingGlass from "@assets/svg/documents-magnifying-glass.svg";
import "./understandingYourResults.scss";
import UnderstandingYourResultsContent from "./understandingYourResultsContent";
import useTestResultsTexts from "@app/hooks/useTestResultsTexts";
import getSupplementalVideoUid from "@app/utils/getSupplementalVideoUid";
import { TestResult } from "@app/provider/testResult";
import { BusinessUnits } from "@app/provider/drawRequest";
import { UnderstandingYourResultsOncologyContent } from "./understandingYourResultsOncologyContent";

const messages = defineMessages({
  understandingYourResultsHeadline: {
    id: "understandingYourResultsHeadline",
    defaultMessage: "Understanding Your Results",
  },
  understandingYourResultsCloseLabel: {
    id: "understandingYourResultsCloseLabel",
    defaultMessage: "Close",
  },
  understandingYourResultsContinueButton: {
    id: "understandingYourResultsContinueButton",
    defaultMessage: "Continue to Results",
  },
  understandingYourResultsBackButton: {
    id: "understandingYourResultsBackButton",
    defaultMessage: "Back",
  },
  understandingYourResultsImage: {
    id: "understandingYourResultsImage",
    defaultMessage: "Understanding your results image",
  },
  understandingYourResultsEmpowerFirstParagraph: {
    id: "understandingYourResultsEmpowerFirstParagraph",
    defaultMessage:
      "The Empower Hereditary Cancer Test screens for variants, or mutations, in genes associated with an increased risk for common hereditary cancers. Three results are possible:",
  },
  understandingYourResultsVistaraFirstParagraph: {
    id: "understandingYourResultsVistaraFirstParagraph",
    defaultMessage:
      "Vistara is a non-invasive prenatal test that screens your pregnancy for conditions caused by small changes, or mutations, in individual genes.",
  },
  understandingYourResultsEmpowerFirstCard: {
    id: "understandingYourResultsEmpowerFirstCard",
    defaultMessage:
      "<b>Negative:</b> No variant known to increase risk for cancers was found in the genes tested.",
  },
  understandingYourResultsVistaraFirstCard: {
    id: "understandingYourResultsVistaraFirstCard",
    defaultMessage:
      "Your results will show whether a disease-causing or likely disease-causing single-gene mutation has been detected (“Screen Positive”) or not detected (“Screen Negative”).",
  },
  understandingYourResultsEmpowerSecondCard: {
    id: "understandingYourResultsEmpowerSecondCard",
    defaultMessage:
      "<b>Positive:</b> A variant was found that is associated with an increased risk for certain cancer types.",
  },
  understandingYourResultsEmpowerThirdCard: {
    id: "understandingYourResultsEmpowerThirdCard",
    defaultMessage:
      "<b>Variant of Uncertain Significance (VUS):</b> A variant has been identified, but more research is needed to know if it is associated with an increased risk for cancer. VUS status can change over time.",
  },
});

const UnderstandingYourResultsPage: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const { orderUid, testUid, testDocumentUid } = useParams<{
    orderUid: string;
    testUid: string;
    testDocumentUid: string;
  }>();

  const {
    testResult,
    getTestResult,
    getSupplementalMaterials,
  } = React.useContext(TestResultContext);

  React.useEffect(() => {
    const orderUidAndTestUidExist = orderUid && testUid;
    const getSuppsIfTestResultsExist = async (
      testResult$: TestResult | undefined
    ) => {
      if (testResult$) {
        const uid = getSupplementalVideoUid(testResult$);
        uid && (await getSupplementalMaterials(orderUid, testUid, uid));
      }
      setIsLoading(false);
    };

    if (orderUidAndTestUidExist) {
      getTestResult(orderUid, testUid).then(getSuppsIfTestResultsExist);
    }
  }, []);

  const testResultsTexts = useTestResultsTexts();

  const headlineByTestType =
    testResult?.testType &&
    testResultsTexts.understandingResultsHeadline(
      testResult?.testType,
      testResult?.businessUnit
    );
  const secondParagraphByTestType =
    testResult?.testType &&
    testResultsTexts.understandingResultsSecondParagraph(testResult?.testType);

  const testTypeIsEmpower = testResult?.testType === TestType.EMPOWER;
  const testTypeIsVistara = testResult?.testType === TestType.VISTARA;
  const testTypeIsProspera = testResult?.testType === TestType.PROSPERA;
  const testTypeIsRenasight = testResult?.testType === TestType.RENASIGHT;
  const isOncologeTestType =
    testResult?.businessUnit === BusinessUnits.ONCOLOGY;

  const openTestResult = () => {
    history.push(routes.testResultPdfPage(orderUid, testUid, testDocumentUid));
  };

  const handleBack = () =>
    history.length > 2 ? history.goBack() : history.push(routes.home);

  return (
    <div
      className="understanding-your-results"
      data-testid="understanding-your-results-page"
    >
      <div className="understanding-your-results__header">
        <h2>{intl.formatMessage(messages.understandingYourResultsHeadline)}</h2>
        <IconButton
          onClick={handleBack}
          aria-label={intl.formatMessage(
            messages.understandingYourResultsCloseLabel
          )}
        >
          {Close}
        </IconButton>
      </div>
      <section className="understanding-your-results__container">
        <LoadingContext isLoading={isLoading}>
          <ScrollContext component="section" isStatic={false}>
            <div className="understanding-your-results__container__header">
              <h3 className="understanding-your-results__container__title">
                {headlineByTestType}
              </h3>
              <div className="understanding-your-results__container__buttons-wrapper">
                <Button onClick={handleBack}>
                  {intl.formatMessage(
                    messages.understandingYourResultsBackButton
                  )}
                </Button>
                <Button raised onClick={openTestResult}>
                  {intl.formatMessage(
                    messages.understandingYourResultsContinueButton
                  )}
                </Button>
              </div>
            </div>
            {isOncologeTestType ? (
              <UnderstandingYourResultsOncologyContent
                testResult={testResult}
              />
            ) : (
              <div className="understanding-your-results__content">
                <h3>{headlineByTestType}</h3>
                {(testTypeIsProspera || testTypeIsRenasight) && (
                  <Svg content={DocumentsMagnifyingGlass} />
                )}
                {(testTypeIsEmpower || testTypeIsVistara) && (
                  <div className="understanding-your-results__content__card-wrapper">
                    <>
                      <p>
                        {intl.formatMessage(
                          testTypeIsEmpower
                            ? messages.understandingYourResultsEmpowerFirstParagraph
                            : messages.understandingYourResultsVistaraFirstParagraph
                        )}
                      </p>
                      <img
                        src={Image}
                        alt={intl.formatMessage(
                          messages.understandingYourResultsImage
                        )}
                      />
                      <div className="understanding-your-results__content__card-wrapper__card">
                        {intl.formatMessage(
                          testTypeIsEmpower
                            ? messages.understandingYourResultsEmpowerFirstCard
                            : messages.understandingYourResultsVistaraFirstCard,
                          { b: (...chunks) => <b>{chunks}</b> }
                        )}
                      </div>
                    </>

                    {testTypeIsEmpower && (
                      <>
                        <div className="understanding-your-results__content__card-wrapper__card">
                          {intl.formatMessage(
                            messages.understandingYourResultsEmpowerSecondCard,
                            { b: (...chunks) => <b>{chunks}</b> }
                          )}
                        </div>
                        <div className="understanding-your-results__content__card-wrapper__card">
                          {intl.formatMessage(
                            messages.understandingYourResultsEmpowerThirdCard,
                            { b: (...chunks) => <b>{chunks}</b> }
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}

                <div className="understanding-your-results__content__wrapper">
                  <p>{secondParagraphByTestType}</p>
                  {testResult && (
                    <UnderstandingYourResultsContent testResult={testResult} />
                  )}
                </div>
              </div>
            )}
            <div className="understanding-your-results__container__footer">
              <Button
                className="understanding-your-results__container__action-button"
                raised
                onClick={openTestResult}
              >
                {intl.formatMessage(
                  messages.understandingYourResultsContinueButton
                )}
              </Button>
              <Button
                className="understanding-your-results__container__action-button"
                onClick={handleBack}
              >
                {intl.formatMessage(
                  messages.understandingYourResultsBackButton
                )}
              </Button>
            </div>
          </ScrollContext>
        </LoadingContext>
      </section>
    </div>
  );
};

export default UnderstandingYourResultsPage;
