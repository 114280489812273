import React, { FC, useMemo } from "react";
import { TestDetailsRecord } from "@app/provider/types";
import { defineMessages, useIntl } from "react-intl";
import { GridCell, GridRow } from "@natera/material/lib/layout";
import { TestDescription, TestTitle } from "@app/components/common";
import "./testInformationCard.scss";
import { Utils } from "@natera/material/lib/date";

const messages = defineMessages({
  testInformationCardOrderingPhysician: {
    id: "testInformationCardOrderingPhysician",
    defaultMessage: "Ordering Physician",
  },
  testInformationCardOrderingClinic: {
    id: "testInformationCardOrderingClinic",
    defaultMessage: "Ordering Clinic",
  },
  testInformationCardOrderCreated: {
    id: "testInformationCardOrderCreated",
    defaultMessage: "Order Created",
  },
  testInformationCardOrderID: {
    id: "testInformationCardOrderID",
    defaultMessage: "Order ID",
  },
  testInformationCardCaseID: {
    id: "testInformationCardCaseID",
    defaultMessage: "Case ID",
  },
});

interface TestInformationCardProps {
  testDetailsInformation: TestDetailsRecord;
}
const TestInformationCard: FC<TestInformationCardProps> = ({
  testDetailsInformation,
}) => {
  const intl = useIntl();

  const patientName = useMemo(() => {
    if (testDetailsInformation.physician) {
      if (
        testDetailsInformation.physician.firstName &&
        testDetailsInformation.physician.lastName
      ) {
        return `${testDetailsInformation.physician.firstName} ${testDetailsInformation.physician.lastName}`;
      } else if (testDetailsInformation.physician.displayName) {
        return testDetailsInformation.physician.displayName;
      }
    }

    return undefined;
  }, [testDetailsInformation]);

  const limsIds = useMemo(() => {
    return testDetailsInformation.limsIds?.length
      ? testDetailsInformation.limsIds
      : undefined;
  }, [testDetailsInformation]);

  return (
    <GridRow className="test-information-card">
      <GridCell className="test-information-card--title" span={12}>
        <h3>
          <TestTitle testType={testDetailsInformation.testType} />
        </h3>
        <p>
          <TestDescription testType={testDetailsInformation.testType} />
        </p>
      </GridCell>

      {patientName && (
        <GridCell className="info-row" span={12}>
          <div className="info-row--title">
            {intl.formatMessage(messages.testInformationCardOrderingPhysician)}
          </div>
          <div className="info-row--content">{patientName}</div>
        </GridCell>
      )}
      {testDetailsInformation.clinic?.name && (
        <GridCell className="info-row" span={12}>
          <div className="info-row--title">
            {intl.formatMessage(messages.testInformationCardOrderingClinic)}
          </div>
          <div className="info-row--content">
            {testDetailsInformation.clinic.name}
          </div>
        </GridCell>
      )}
      {testDetailsInformation.createdAt && (
        <GridCell className="info-row" span={12}>
          <div className="info-row--title">
            {intl.formatMessage(messages.testInformationCardOrderCreated)}
          </div>
          <div className="info-row--content">
            {Utils.getUSLocaleDate(new Date(testDetailsInformation.createdAt))}
          </div>
        </GridCell>
      )}
      {testDetailsInformation.orderUid && (
        <GridCell className="info-row" span={12}>
          <div className="info-row--title">
            {intl.formatMessage(messages.testInformationCardOrderID)}
          </div>
          <div className="info-row--content">
            {testDetailsInformation.orderUid}
          </div>
        </GridCell>
      )}
      {limsIds && (
        <GridCell className="info-row" span={12}>
          <div className="info-row--title">
            {intl.formatMessage(messages.testInformationCardCaseID)}
          </div>
          <div className="info-row--content">{limsIds.join(", ")}</div>
        </GridCell>
      )}
    </GridRow>
  );
};

export default TestInformationCard;
