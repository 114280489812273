export const GENERATED_PASSWORD_LENGTH = 12;
export const ALLOWED = {
  numbers: "0123456789",
  uppercase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  lowercase: "abcdefghijklmnopqrstuvwxyz",
  symbols: "!@#$%^&?*_-+=.:;",
};

const getRandomIndex = (length: number) => {
  const crypto = window.crypto || window.msCrypto;
  const buffer = new Uint32Array(length);
  const randomValue = crypto.getRandomValues(buffer)[0];
  return randomValue % length;
};

const getRandomCharFromString = (string: string) => {
  return Array.from(string)[getRandomIndex(string.length)];
};

function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const randomIndex = getRandomIndex(array.length);
    [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
  }
  return array;
}

export const generatePassword = () => {
  const passwordItems: string[] = [];

  passwordItems.push(getRandomCharFromString(ALLOWED.numbers));
  passwordItems.push(getRandomCharFromString(ALLOWED.uppercase));
  passwordItems.push(getRandomCharFromString(ALLOWED.lowercase));
  passwordItems.push(getRandomCharFromString(ALLOWED.symbols));

  for (let i = passwordItems.length; i < GENERATED_PASSWORD_LENGTH; i++) {
    passwordItems.push(
      getRandomCharFromString(Object.values(ALLOWED).join(""))
    );
  }
  return shuffleArray(passwordItems).join("");
};
