import { DocumentNode, gql } from "@apollo/client";

class ProfileService {
  public static getProfile = (): DocumentNode => gql`
    query GetProfile {
      getProfile {
        uid
        firstName
        middleName
        lastName
        alternativeLastName
        dateOfBirth
        email
        phone
        ethnicities {
          ethnicityTypes {
            code
            description
          }
          subEthnicity
        }
        addresses {
          uid
          countryCode
          city
          state
          street1
          street2
          zipCode
        }
        communicationPreferences {
          channelType
          value
        }
      }
    }
  `;

  public static verifyProfile = (): DocumentNode => gql`
    mutation VerifyProfile($dateOfBirth: String!) {
      verifyProfile(dateOfBirth: $dateOfBirth) {
        isVerified
        profile {
          firstName
          middleName
          lastName
          phone
        }
      }
    }
  `;

  public static setupProfile = (): DocumentNode => gql`
    mutation SetupProfile($profileData: SetupProfileDTO!) {
      setupProfile(profileData: $profileData) {
        uid
        firstName
        middleName
        lastName
        alternativeLastName
        dateOfBirth
        birthGender
        email
        phone
      }
    }
  `;

  public static confirmProfile = (): DocumentNode => gql`
    mutation ConfirmProfile($profileData: SetupProfileDTO!) {
      confirmProfile(profileData: $profileData) {
        uid
        firstName
        middleName
        lastName
        dateOfBirth
      }
    }
  `;

  public static updatePersonalInformation = (): DocumentNode => gql`
    mutation UpdatePersonalInformation($personalInformation: SetupProfileDTO!) {
      updatePersonalInformation(personalInformation: $personalInformation) {
        uid
        firstName
        middleName
        lastName
        alternativeLastName
      }
    }
  `;

  public static addPatientAddress = (): DocumentNode => gql`
    mutation AddPatientAddress(
      $patientAddress: AddressCommonDTO!
      $heapEventLocation: String
    ) {
      addPatientAddress(
        patientAddress: $patientAddress
        heapEventLocation: $heapEventLocation
      ) {
        uid
        countryCode
        city
        state
        street1
        street2
        zipCode
      }
    }
  `;

  public static updatePatientAddress = (): DocumentNode => gql`
    mutation UpdatePatientAddress($patientAddress: AddressDTO!) {
      updatePatientAddress(patientAddress: $patientAddress) {
        uid
        countryCode
        city
        state
        street1
        street2
        zipCode
      }
    }
  `;

  public static addPatientEthnicities = (): DocumentNode => gql`
    mutation addPatientEthnicities(
      $patientEthnicities: PatientEthnicitiesDTO!
    ) {
      addPatientEthnicities(patientEthnicities: $patientEthnicities) {
        uid
      }
    }
  `;

  public static updatePatientCommunication = (): DocumentNode => gql`
    mutation UpdatePatientCommunication(
      $patientCommunication: [CommunicationDTO!]!
      $heapEventLocation: String
    ) {
      updatePatientCommunication(
        patientCommunication: $patientCommunication
        heapEventLocation: $heapEventLocation
      ) {
        channelType
        value
      }
    }
  `;

  public static sendPhoneNumberVerificationCode = () => gql`
    mutation SendPhoneNumberVerificationCode($phoneNumber: String!) {
      sendPhoneNumberVerificationCode(phoneNumber: $phoneNumber) {
        success
      }
    }
  `;

  public static updatePatientPhoneNumber = () => gql`
    mutation UpdatePatientPhoneNumber($verificationCode: String!) {
      updatePatientPhoneNumber(verificationCode: $verificationCode) {
        success
      }
    }
  `;
}

export default ProfileService;
