import Idle from "@app/components/idle";
import { SecurityContext } from "@natera/platform/lib/provider/security";
import React, { useContext } from "react";
import { ConfigContext } from "@app/provider";

const defaultDelays = {
  timeoutDelay: 1000 * 60 * 28,
  openedDialogDelay: 1000 * 60 * 2,
};

const IdleWrapper: React.FC = ({ children }) => {
  const { config } = useContext(ConfigContext);
  const { isAuthorized } = useContext(SecurityContext);

  const delays = {
    timeoutDelay: config.timers.idleTimer.timeoutDelay,
    openedDialogDelay: config.timers.idleTimer.openedDialogDelay,
  };

  return (
    <>
      {isAuthorized() && (
        <Idle
          timeoutDelay={
            delays.timeoutDelay
              ? delays.timeoutDelay
              : defaultDelays.timeoutDelay
          }
          openedDialogDelay={
            delays.openedDialogDelay
              ? delays.openedDialogDelay
              : defaultDelays.openedDialogDelay
          }
        />
      )}
      {children}
    </>
  );
};

export default IdleWrapper;
