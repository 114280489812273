import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ConfigContext } from "@app/provider";
import { openNewWindow } from "@app/utils";
import { Button } from "@natera/platform/lib/components/form";
import { Utils } from "@natera/material/lib/date";

import Svg from "@natera/material/lib/svg";
import Box from "@assets/svg/icons/box.svg";
import "./kitDetailsSection.scss";

export interface KitDetailsProps {
  trackingNumber: string;
  kitShippedDate: string;
}

const messages = defineMessages({
  kitDetailsTitle: {
    id: "kitDetailsTitle",
    defaultMessage: "Kit Details",
  },
  kitDetailsShipped: {
    id: "kitDetailsShipped",
    defaultMessage: "Shipped on {date}",
  },
  kitDetailsTrack: {
    id: "kitDetailsTrack",
    defaultMessage: "Track Shipment",
  },
  kitTrackingNotAvailable: {
    id: "kitTrackingNotAvailable",
    defaultMessage:
      "We're sorry. Kit tracking information is not available at the moment.",
  },
});

const KitDetailsSection: FC<KitDetailsProps> = ({
  trackingNumber,
  kitShippedDate,
}) => {
  const intl = useIntl();
  const { config } = useContext(ConfigContext);
  const shippedDate = Utils.getUSLocaleDate(new Date(kitShippedDate));

  const trackKitStatus = () => {
    openNewWindow(`${config.links.TRACKING}${trackingNumber}`);
  };

  return (
    <div className="kit-details">
      <div className="kit-details-wrp">
        <Svg content={Box} />
        <div className="kit-details-info">
          <span className="kit-details-info-title">
            {intl.formatMessage(messages.kitDetailsTitle)}
          </span>
          <div className="kit-details-info-details">
            {intl.formatMessage(messages.kitDetailsShipped, {
              date: shippedDate,
            })}
          </div>
        </div>
      </div>
      <Button className="kit-details-btn" raised onClick={trackKitStatus}>
        {intl.formatMessage(messages.kitDetailsTrack)}
      </Button>
    </div>
  );
};

export default KitDetailsSection;
