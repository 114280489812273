import * as React from "react";
import SimpleOrderStepper from "@app/pages/private/simpleOrder/simpleOrderStepper";
import { FlowHeader } from "@app/components";
import { SimpleOrderContext } from "@app/provider";
import { ServiceDisruptionView } from "./serviceDisruptionView";

import "./simpleOrder.scss";

const MAX_COMPLETE_ATTEMPTS = 3;

const SimpleOrderPage: React.FC = () => {
  const { failedCompleteAttempts } = React.useContext(SimpleOrderContext);

  return (
    <div className="simple-order-page stepper-page__container">
      {failedCompleteAttempts < MAX_COMPLETE_ATTEMPTS ? (
        <>
          <FlowHeader />
          <SimpleOrderStepper />
        </>
      ) : (
        <ServiceDisruptionView />
      )}
    </div>
  );
};

export default SimpleOrderPage;
