import React from "react";

import { SecurityProvider } from "@natera/platform/lib/provider/security";
import { getUserContext } from "@natera/platform/lib/provider/user";

const SecurityProviderWrapper: React.FC = ({ children }) => {
  const { getCurrentUser } = React.useContext(getUserContext());
  const currentUser = getCurrentUser();

  return (
    <SecurityProvider getPermissions={() => []} currentUser={currentUser}>
      {children}
    </SecurityProvider>
  );
};

export default SecurityProviderWrapper;
