import { ServiceContext, UppAuthContext } from "@app/provider";
import Queue from "yocto-queue";

import React, { useContext, useEffect, useState, useCallback } from "react";
import { useDialog } from "@natera/platform/lib/hooks";
import IdleDialog from "./idleDialog";
import { useTimers, useEventListeners } from "@app/hooks";
interface IdleWrapperProps {
  timeoutDelay?: number;
  openedDialogDelay?: number;
}

const IdleWrapperComponent: React.FC<IdleWrapperProps> = ({
  timeoutDelay = 1000 * 60 * 28,
  openedDialogDelay = 1000 * 60 * 2,
}) => {
  const queue = new Queue();

  const { logout } = useContext(UppAuthContext);
  const { sessionService } = useContext(ServiceContext);

  const [openedDialog, setOpenedDialog] = useState(false);

  const dialog = useDialog(IdleDialog);

  const [loading, setLoading] = useState(false);

  const handleOpenDialog = () => {
    setOpenedDialog(true);
    dialog.open({
      handleCloseDialog,
      handleLogout,
      loading,
      openedDialogDelay,
    });
  };

  const handleCloseDialog = () => {
    setOpenedDialog(false);
    dialog.close();
    addListeners();
    resetTimers();
  };

  const handleLogout = () => {
    setLoading(true);
    logout();
  };

  const renewToken = async () => {
    const estimate = await sessionService.getToken().then((token) => {
      if (!token) {
        return;
      }
      return token?.expiresAt;
    });

    if (!estimate) {
      logout();
    }

    if (estimate) {
      const timeDifference =
        new Date(estimate).getTime() - new Date().getTime();
      const delaySum = timeoutDelay + openedDialogDelay;
      delaySum < timeDifference && sessionService.renewToken();
    }
    queue.clear();
  };

  const { setTimer: addTimers, clearTimer: removeTimers } = useTimers(
    handleOpenDialog,
    timeoutDelay
  );

  const resetTimers = useCallback(() => {
    removeTimers();

    queue.enqueue("Renew token");
    if (queue.size === 1) {
      renewToken();
    }

    addTimers();
  }, []);

  const { addListeners, removeListeners } = useEventListeners(
    resetTimers,
    ["mousemove", "mousedown", "keydown", "touchstart", "nevaLogEvent"],
    window,
    { passive: true }
  );

  useEffect(() => {
    if (openedDialog) {
      removeListeners();
      removeTimers();
    }
  }, [openedDialog]);

  return <>{dialog.getDialog()}</>;
};

export default IdleWrapperComponent;
