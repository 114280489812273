import { HttpService } from "@natera/platform/lib/service";
import { DocumentNode, gql } from "@apollo/client";
import { Language } from "@app/provider";

interface PasswordChangeData {
  oldPassword: string;
  newPassword: string;
}

type UserUID = number;
type OktaUID = string;

export enum IDP_TYPE {
  EMAIL = "EMAIL",
  APPLE = "APPLE",
  GOOGLE = "GOOGLE",
}

export enum FactorStatus {
  NOT_SETUP = "NOT_SETUP",
  ACTIVE = "ACTIVE",
  PENDING_ACTIVATION = "PENDING_ACTIVATION",
}

export enum FACTORS_TO_SHOW {
  call = "call",
  email = "email",
  sms = "sms",
}

export enum STAGE {
  BLANK = "BLANK",
  PROFILE_SETUP = "PROFILE_SETUP",
  DATE_OF_BIRTH_VERIFICATION = "DATE_OF_BIRTH_VERIFICATION",
  CONFIRMATION = "CONFIRMATION",
  MFA_SETUP = "MFA_SETUP",
  COMMUNICATION_PREFERENCES = "COMMUNICATION_PREFERENCES",
  FILLED = "FILLED",
}

export interface Invite {
  patientUID: string;
}

export interface UppUser {
  id: UserUID;
  oktaUID: OktaUID;
  askForMfa: boolean;
  email: string;
  patientUID?: string;
  language?: Language;
  stage?: STAGE;
  invite?: Invite;
  idpType: IDP_TYPE;
  phone?: string;
  createdAt: string;
}

export interface Factor {
  id?: string;
  factorType: FACTORS_TO_SHOW;
  provider: string;
  vendorName: string;
  status: FactorStatus;
  enrollment: string;
  profile?: EnrollProfile;
}

export interface EnrollProfile {
  email?: string;
  phoneNumber?: string;
}

export interface EnrollFactorResponse {
  id: string;
  factorType: FACTORS_TO_SHOW;
  provider: string;
  status: FactorStatus;
  profile?: EnrollProfile;
}
export interface SuccessStatusResponse {
  success: boolean;
}

type ChangePassword = (id: string, data: PasswordChangeData) => Promise<void>;

export interface UserServiceInterface extends HttpService {
  changePassword: ChangePassword;
}

export default class UserService {
  public static loadProfile = (): DocumentNode => gql`
    query Profile {
      profile {
        id
        oktaUID
        email
        askForMfa
        patientUID
        language
        stage
        invite {
          patientUID
        }
        idpType
        createdAt
      }
    }
  `;

  public static createUser = (): DocumentNode => gql`
    mutation CreateUser($newUserData: NewUserDTO!) {
      createUser(newUserData: $newUserData) {
        id
        oktaUID
        email
        askForMfa
        patientUID
        language
        stage
        idpType
      }
    }
  `;

  public static updateUser = (): DocumentNode => gql`
    mutation UpdateUser($updateUserData: UpdateUserDTO!) {
      updateUser(updateUserData: $updateUserData) {
        id
        stage
        oktaUID
        email
        askForMfa
        patientUID
        language
        idpType
      }
    }
  `;

  public static updateUserLanguageNonAuth = (): DocumentNode => gql`
    mutation UpdateUserLanguageNonAuth(
      $updateUserLanguageData: UpdateUserLanguageDTO!
    ) {
      updateUserLanguageNonAuth(
        updateUserLanguageData: $updateUserLanguageData
      ) {
        success
      }
    }
  `;

  public static getUserFactorList = (): DocumentNode => gql`
    query FactorList {
      factorList {
        factorType
        provider
        vendorName
        status
        enrollment
      }
    }
  `;

  public static getEnrolledUserFactorList = (): DocumentNode => gql`
    query EnrolledFactorList {
      enrolledFactorList {
        id
        factorType
        provider
        vendorName
        status
        profile {
          email
          phoneNumber
        }
      }
    }
  `;

  public static enrollUserFactor = (): DocumentNode => gql`
    mutation EnrollUserFactor($enrollFactorData: EnrollFactorDTO!) {
      enrollUserFactor(enrollFactorData: $enrollFactorData) {
        id
        factorType
        provider
        status
        profile {
          email
          phoneNumber
        }
      }
    }
  `;

  public static resendUserFactorCode = (): DocumentNode => gql`
    mutation ResendUserFactorCode($resendFactorCodeData: ResendFactorCodeDTO!) {
      resendUserFactorCode(resendFactorCodeData: $resendFactorCodeData) {
        id
        factorType
        provider
        status
        profile {
          email
          phoneNumber
        }
      }
    }
  `;

  public static activateUserFactor = (): DocumentNode => gql`
    mutation ActivateUserFactor(
      $activateFactorData: ActivateFactorDTO!
      $heapEventLocation: String
      $askForCommunicationPreferences: Boolean
    ) {
      activateUserFactor(
        activateFactorData: $activateFactorData
        heapEventLocation: $heapEventLocation
        askForCommunicationPreferences: $askForCommunicationPreferences
      ) {
        success
      }
    }
  `;

  public static disableUserFactors = (): DocumentNode => gql`
    mutation DisableUserFactors {
      disableUserFactors {
        success
      }
    }
  `;

  public static CheckUserExisted = (): DocumentNode => gql`
    query CheckUserExisted($email: String!) {
      checkUserExisted(email: $email) {
        isExisted
      }
    }
  `;

  public static CheckUserInactive = (): DocumentNode => gql`
    query CheckUserInactive($email: String!) {
      checkUserInactive(email: $email) {
        isInactive
      }
    }
  `;

  public static GetUserByOktaUID = (): DocumentNode => gql`
    query GetUserByOktaUID($oktaUID: String!) {
      getUserByOktaUID(oktaUID: $oktaUID) {
        id
        patientUID
        stage
        language
      }
    }
  `;

  public static ResetPassword = (): DocumentNode => gql`
    query ResetPassword($email: String!) {
      resetPassword(email: $email) {
        email
      }
    }
  `;

  public static CheckResetToken = (): DocumentNode => gql`
    query CheckResetToken($token: String!) {
      checkResetToken(token: $token) {
        email
        language
      }
    }
  `;

  public static SetPassword = (): DocumentNode => gql`
    mutation SetPassword($props: UserSetPasswordDTO!) {
      setPassword(props: $props) {
        success
      }
    }
  `;

  public static UpdateUser = (): DocumentNode => gql`
    mutation UpdateUser($userData: UpdateUserDTO!) {
      updateUser(userData: $userData) {
        id
        stage
      }
    }
  `;

  public static UpdateEmail = (): DocumentNode => gql`
    mutation UpdateEmail($newEmail: String!) {
      updateEmail(newEmail: $newEmail) {
        newEmail
      }
    }
  `;

  public static LogUserLogin = (): DocumentNode => gql`
    mutation LogUserLogin($userLoginData: UserLoginLogDTO!) {
      logUserLogin(userLoginData: $userLoginData) {
        attemptsLeft
        isAccountActivated
      }
    }
  `;

  public static UnlockUser = (): DocumentNode => gql`
    query UnlockUser($unlockUser: UnlockUserDTO!) {
      unlockUser(unlockUser: $unlockUser) {
        success
        email
      }
    }
  `;

  public static ChangePassword = (): DocumentNode => gql`
    mutation ChangePassword($props: ChangePasswordDTO!) {
      changePassword(props: $props) {
        success
      }
    }
  `;

  public static DeleteUser = (): DocumentNode => gql`
    mutation DeleteUser($deleteUser: DeleteUserDTO!) {
      deleteUser(deleteUser: $deleteUser) {
        success
        email
      }
    }
  `;

  public static GetShowUnlockForm = (): DocumentNode => gql`
    query GetShowUnlockForm($email: String!) {
      getShowUnlockForm(email: $email)
    }
  `;

  public static AddPatientAcknowledgement = (): DocumentNode => gql`
    mutation AddPatientAcknowledgement(
      $acknowledgementData: PatientAcknowledgementDTO!
    ) {
      addPatientAcknowledgement(acknowledgementData: $acknowledgementData) {
        email
        accepted
      }
    }
  `;

  public static validatePhoneNumber = (): DocumentNode => gql`
    query ValidatePhoneNumber($phone: String!) {
      validatePhoneNumber(phone: $phone) {
        isValid
      }
    }
  `;
}
