import { DocumentNode, gql } from "@apollo/client";

class HeapAnalyticDataService {
  public static getDrawRequestDataForHeapEvent = (): DocumentNode => gql`
    query GetDrawRequestDataForHeapEvent($token: String!) {
      getDrawRequestDataForHeapEvent(token: $token) {
        identity
        test_name
        order_uid
        lims_clinic_id
        business_unit
        tests_ordered
      }
    }
  `;
}

export default HeapAnalyticDataService;
