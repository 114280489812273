import {
  ActionTypes,
  EventDataActionType,
  PersistentMenuDTOType,
} from "@app/neva/models";
import { Dispatch, SetStateAction, useContext } from "react";
import {
  DialogsContext,
  EventsProviderState,
  NevaContext,
} from "@app/neva/provider";
import {
  IntlContext,
  Language,
  UppAuthContext,
  UserContext,
} from "@app/provider";
import { useLogEvent } from "@app/neva/hooks/useLogEvent";

type UseActionProcessorType = {
  processAction: (action: EventDataActionType) => void;
};

export const useActionProcessor = (
  setEventsProviderState: Dispatch<SetStateAction<EventsProviderState>>
): UseActionProcessorType => {
  const { completeNevaFlow, setIsWidgetOpen } = useContext(NevaContext);
  const { profile } = useContext(UppAuthContext);
  const { updateUser } = useContext(UserContext);
  const { changeLanguage } = useContext(IntlContext);
  const { closeWidgetDialog, changeLanguageDialog } = useContext(
    DialogsContext
  );

  const { logEvent } = useLogEvent();

  const processAction = (action: EventDataActionType) => {
    const { type } = action;
    switch (type) {
      case ActionTypes.WIDGET_LANGUAGE_SWITCHED:
        action.languageCode && switchLanguage(action.languageCode);
        break;
      case ActionTypes.UPDATE_PERSISTENT_MENU:
        setPersistentMenu(action.persistentMenuDTO as PersistentMenuDTOType);
        break;
      case ActionTypes.END_CONVERSATION:
        setIsEndOfConversation(true);
        break;
      case ActionTypes.UNDO_END_CONVERSATION:
        setIsEndOfConversation(false);
        break;
      case ActionTypes.CLOSE_WIDGET_WITH_CONFIRMATION: {
        const { shouldShowClosePopupScheduleGCText = false } = action;
        closeWidgetDialog.open({
          onClose: () => closeWidgetDialog.close(),
          shouldShowClosePopupScheduleGCText,
        });
        break;
      }
      case ActionTypes.SWITCH_LANGUAGE:
        changeLanguageDialog.open({
          onClose: () => changeLanguageDialog.close(),
        });
        break;
      case ActionTypes.CLOSE_WIDGET:
      case ActionTypes.CLOSE_WIDGET_WITHOUT_CONFIRMATION:
        setIsWidgetOpen(false);
        completeNevaFlow();
        break;
      case ActionTypes.USER_ACTION_EVENT:
        logEvent({
          eventAction: action.connectEvent?.chat_event_data ?? "",
          eventLabel: "User Action",
        });
        break;
    }
  };

  const switchLanguage = (code: Language) => {
    if (profile) {
      updateUser({
        language: code,
      });

      changeLanguage(code);
    }
  };

  const setPersistentMenu = (persistentMenu: PersistentMenuDTOType) => {
    setEventsProviderState((prevState) => ({
      ...prevState,
      persistentMenu,
    }));
  };

  const setIsEndOfConversation = (isEndOfConversation: boolean) => {
    setEventsProviderState((prevState) => ({
      ...prevState,
      isEndOfConversation,
    }));
  };

  return {
    processAction,
  };
};
