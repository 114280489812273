import React, { FC } from "react";
import { Link } from "@natera/platform/lib/components";
import Svg from "@natera/material/lib/svg";
import "./detailInformationButton.scss";

export interface DetailInformationButtonProps {
  route: string;
  name: string;
  startIcon: string;
  endIcon: string;
}

const DetailInformationButton: FC<DetailInformationButtonProps> = ({
  route,
  name,
  startIcon,
  endIcon,
}) => {
  return (
    <Link className="detail-information-button" to={route}>
      <Svg content={startIcon} />
      <div>{name}</div>
      <Svg content={endIcon} />
    </Link>
  );
};

export default DetailInformationButton;
