import React, { FC, useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button, Form } from "@natera/platform/lib/components/form";
import { StepperContext } from "@natera/stepper";
import { Textarea } from "@natera/platform/lib/components/form/field";
import {
  DrawRequestContext,
  HeapAnalyticDataContext,
  TestDetailsContext,
  UppAuthContext,
} from "@app/provider";
import { FormField } from "@app/components";
import { SAMPLE_DRAW_STEP_IDS } from "@app/pages/private/sampleDraw/sampleDraw";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import * as R from "ramda";
import { HEAP_EVENTS, ShippingAddress } from "@app/provider/types";
import { StepperContent } from "@app/components/ui/layout";

import "./additionalInstructionsStep.scss";
import { TestType } from "@app/provider/testData/types";
import { capitalizeFirstLetter } from "@app/utils";
import { businessUnitMapper } from "@app/utils/businessUnitMapper";

const messages = defineMessages({
  additionalInstructionsStepTitle: {
    id: "additionalInstructionsStepTitle",
    defaultMessage: "Mobile Blood Draw",
  },
  additionalInstructionsStepDescriptionTitle: {
    id: "additionalInstructionsStepDescriptionTitle",
    defaultMessage:
      "Would you like to add any comments or instructions for your blood draw appointment?",
  },
  additionalInstructionsFormLabel: {
    id: "additionalInstructionsFormLabel",
    defaultMessage: "Comments",
  },
  additionalInstructionsFormInputPlaceholder: {
    id: "additionalInstructionsFormInputPlaceholder",
    defaultMessage:
      "Examples: Look for the green house, ring doorbell at the side door, use code #104 to open the parking gate, etc.",
  },
  additionalInstructionsSubmitAppointmentRequest: {
    id: "additionalInstructionsSubmitAppointmentRequest",
    defaultMessage: "Submit",
  },
  additionalInstructionsBack: {
    id: "additionalInstructionsBack",
    defaultMessage: "Back",
  },
});

type AdditionalInstructionsStepProps = {
  handleCreateDrawRequestError?: () => void;
  testType?: TestType;
  orderUid?: string;
  testUid?: string;
  token?: string;
};

const AdditionalInstructionsStep: FC<AdditionalInstructionsStepProps> = ({
  handleCreateDrawRequestError,
  ...props
}) => {
  const intl = useIntl();
  const [additionalInstructions, setAdditionalInstructions] = useState<string>(
    ""
  );

  const { getTestDetails } = useContext(TestDetailsContext);

  const testDetails = getTestDetails();

  const {
    isLoading,
    createDrawRequest,
    openCreateDrawErrorDialog,
  } = useContext(DrawRequestContext);

  const { profile } = React.useContext(UppAuthContext);
  const { drawRequestDataForHeapEventData } = useContext(
    HeapAnalyticDataContext
  );

  const { getData, resolve, goBack } = useContext(StepperContext);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const shippingAddress = getData(SAMPLE_DRAW_STEP_IDS.MP1)?.address;

    const timeSlots = getData(SAMPLE_DRAW_STEP_IDS.MP2)?.timeSlots;
    const timeZone = getData(SAMPLE_DRAW_STEP_IDS.MP2)?.timeZone;

    try {
      await createDrawRequest({
        timeZone,
        shippingAddress: R.omit(["uid"], shippingAddress) as ShippingAddress,
        timeSlots,
        notes: additionalInstructions,
        ...props,
      });

      resolve({});
    } catch {
      heap.track(HEAP_EVENTS.upp_sampledraw_mp_appt_failure, {
        business_unit: testDetails
          ? businessUnitMapper(testDetails.businessUnit)
          : drawRequestDataForHeapEventData?.business_unit,
        test_name: testDetails
          ? capitalizeFirstLetter(testDetails.testType)
          : capitalizeFirstLetter(drawRequestDataForHeapEventData?.test_name),
        lims_clinic_id: testDetails
          ? testDetails.clinic?.limsId
          : drawRequestDataForHeapEventData?.lims_clinic_id,
        flow: profile ? "account" : "guest",
      });
      handleCreateDrawRequestError
        ? handleCreateDrawRequestError()
        : openCreateDrawErrorDialog();
    }
  };

  const handleBackButtonClick = () => goBack();

  const handleChangeAdditionalInstructions: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) =>
    event.target.value.length <= 255 &&
    setAdditionalInstructions(event.target.value);

  return (
    <>
      <StepperHeaderProgress />
      <StepperContent
        title={intl.formatMessage(messages.additionalInstructionsStepTitle)}
        subTitle={intl.formatMessage(
          messages.additionalInstructionsStepDescriptionTitle
        )}
      >
        <Form
          className="additionalInstructionsForm"
          noValidate
          onSubmit={handleSubmit}
          buttons={
            <>
              <Button
                type="submit"
                data-testid="submit"
                loading={isLoading}
                raised
              >
                {intl.formatMessage(
                  messages.additionalInstructionsSubmitAppointmentRequest
                )}
              </Button>
              <Button onClick={handleBackButtonClick}>
                {intl.formatMessage(messages.additionalInstructionsBack)}
              </Button>
            </>
          }
        >
          <FormField
            label={intl.formatMessage(messages.additionalInstructionsFormLabel)}
            htmlFor="additional-instructions"
          >
            <Textarea
              data-testid="additional-instructions-text"
              id="additional-instructions"
              name="additional-instructions-text"
              placeholder={intl.formatMessage(
                messages.additionalInstructionsFormInputPlaceholder
              )}
              className="notes"
              onChange={handleChangeAdditionalInstructions}
              value={additionalInstructions}
              outline
            />
          </FormField>
        </Form>
      </StepperContent>
    </>
  );
};
export default AdditionalInstructionsStep;
