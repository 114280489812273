import React, { FC, useContext, useState, useEffect, useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";
import { routes } from "@app/routing";
import { Profile, ProfileContext, UppAuthContext } from "@app/provider";
import {
  InfoCard,
  SpinnerView,
  Notification,
  FormField,
} from "@app/components";
import { IDP_TYPE, UppUser } from "@app/service/user";
import PenIcon from "@assets/svg/icons/pen.svg";
import { Button, IconButton } from "@natera/material/lib/button";
import { DisplayField } from "@natera/platform/lib/components/form/field";
import { Address } from "@app/provider/profile";
import { useHistory } from "react-router-dom";
import { BlueHeaderContent } from "@app/components/ui/layout";
import { checkFirstEmptyAddress } from "@app/utils/checkFirstEmptyAddress";
import ProviderType from "@app/components/providerType";
import { getAddressAsString } from "@app/utils";

import "./contactDetailsInfo.scss";

const messages = defineMessages({
  contactDetailsBackButton: {
    id: "contactDetailsBackButton",
    defaultMessage: "Contact Details",
  },
  contactDetailsTitle: {
    id: "contactDetailsTitle",
    defaultMessage: "Contact Details",
  },
  contactDetailsSignUpMethod: {
    id: "contactDetailsSignUpMethod",
    defaultMessage: "Sign Up Method",
  },
  contactDetailsEmail: {
    id: "contactDetailsEmail",
    defaultMessage: "Email",
  },
  contactDetailsMobilePhone: {
    id: "contactDetailsMobilePhone",
    defaultMessage: "Mobile Phone",
  },
  contactDetailsEditMobilePhone: {
    id: "contactDetailsEditMobilePhone",
    defaultMessage: "Edit mobile phone",
  },
  contactDetailsAddress: {
    id: "contactDetailsAddress",
    defaultMessage: "Address",
  },
  contactDetailsEditAddress: {
    id: "contactDetailsEditAddress",
    defaultMessage: "Edit Address",
  },
  contactDetailsEmailProvider: {
    id: "contactDetailsEmailProvider",
    defaultMessage: "Email",
  },
  contactDetailsEditEmailProvider: {
    id: "contactDetailsEditEmailProvider",
    defaultMessage: "Edit Email",
  },
  contactDetailsAppleProvider: {
    id: "contactDetailsAppleProvider",
    defaultMessage: "Apple",
  },
  contactDetailsGoogleProvider: {
    id: "contactDetailsGoogleProvider",
    defaultMessage: "Google",
  },
  contactDetailsAddressAddNewAddress: {
    id: "contactDetailsAddressAddNewAddress",
    defaultMessage: "Add a New Address",
  },
  contactDetailsIdentityProviderWarningTitle: {
    id: "contactDetailsIdentityProviderWarningTitle",
    defaultMessage: "Email or password cannot be edited",
  },
  contactDetailsIdentityProviderWarningDescription: {
    id: "contactDetailsIdentityProviderWarningDescription",
    defaultMessage:
      "Your email and password can only be edited if you created your account using your email address.",
  },
  contactDetailsPhoneUpdatedNotificationTitle: {
    id: "contactDetailsPhoneUpdatedNotificationTitle",
    defaultMessage: "Mobile phone has been verified",
  },
  contactDetailsPhoneUpdatedNotificationContent: {
    id: "contactDetailsPhoneUpdatedNotificationContent",
    defaultMessage: "Your mobile phone has been successfully updated.",
  },
});

const patientWithoutAddress = (patientProfileData: Profile | undefined) => {
  const addresses = patientProfileData?.addresses;
  switch (true) {
    case !addresses:
    case addresses && addresses.length === 0:
      return true;
    case addresses && addresses.length > 1:
      return false;
    default:
      return addresses && checkFirstEmptyAddress(addresses);
  }
};

const ContactDetailsInfo: FC = () => {
  const intl = useIntl();
  const history = useHistory<{
    phoneUpdated?: boolean;
  } | null>();

  const { profile } = useContext(UppAuthContext);
  const {
    profileData: patientProfileData,
    profileDataIsLoading,
    setCurrentPatientAddress,
  } = useContext(ProfileContext);

  const [showIdentityWarningMsg, setShowIdentityWarningMsg] = useState(false);

  useEffect(() => {
    if (profile && profile?.idpType !== IDP_TYPE.EMAIL)
      setShowIdentityWarningMsg(true);
  }, [profile]);

  const getProviderTypeElement = (profile: UppUser | null) => {
    let providerTypeElement = null;

    switch (profile?.idpType) {
      case IDP_TYPE.EMAIL:
        providerTypeElement = (
          <ProviderType idpType={IDP_TYPE.EMAIL} showEmailIcon />
        );
        break;
      case IDP_TYPE.APPLE:
        providerTypeElement = <ProviderType idpType={IDP_TYPE.APPLE} />;
        break;
      case IDP_TYPE.GOOGLE:
        providerTypeElement = <ProviderType idpType={IDP_TYPE.GOOGLE} />;
        break;
    }

    return providerTypeElement;
  };
  const getProviderTypeElementCallback = useCallback(
    () => getProviderTypeElement(profile),
    [profile]
  );

  const identityProviderWarning = (
    <Notification className="identity-provider-warning" type="note">
      <div>
        <p>
          {intl.formatMessage(
            messages.contactDetailsIdentityProviderWarningTitle
          )}
        </p>
        <p>
          {intl.formatMessage(
            messages.contactDetailsIdentityProviderWarningDescription
          )}
        </p>
      </div>
    </Notification>
  );

  const phoneUpdatedNotification = history?.location?.state?.phoneUpdated ? (
    <Notification type="success">
      <div>
        <p>
          {intl.formatMessage(
            messages.contactDetailsPhoneUpdatedNotificationTitle
          )}
        </p>
        <p>
          {intl.formatMessage(
            messages.contactDetailsPhoneUpdatedNotificationContent
          )}
        </p>
      </div>
    </Notification>
  ) : null;

  useEffect(() => {
    return () => {
      if (phoneUpdatedNotification) {
        history.replace({ ...history.location, state: null });
      }
    };
  }, [history, phoneUpdatedNotification]);

  const handleUpdateEmail = () => history.push(routes.updateEmail);

  const handleUpdatePhone = () =>
    history.push(routes.contactDetailsUpdatePhone);

  const handleCreateAddress = () =>
    history.push(routes.contactDetailsCreateOrUpdateAddress);

  const handleUpdateAddress = (address: Address) => () => {
    setCurrentPatientAddress(address);
    history.push(routes.contactDetailsCreateOrUpdateAddress);
  };

  const isFirstEmptyAddress =
    patientProfileData?.addresses &&
    checkFirstEmptyAddress(patientProfileData?.addresses);

  patientWithoutAddress(patientProfileData);

  const handleAddNewAddressButton =
    isFirstEmptyAddress && patientProfileData?.addresses
      ? handleUpdateAddress(patientProfileData?.addresses[0])
      : handleCreateAddress;

  return (
    <BlueHeaderContent
      blueHeaderTitle={intl.formatMessage(messages.contactDetailsBackButton)}
      backArrowHeaderRoute={routes.profile}
    >
      {!profileDataIsLoading && patientProfileData && (
        <>
          <InfoCard
            titleText={intl.formatMessage(messages.contactDetailsTitle)}
            notification={phoneUpdatedNotification}
          >
            <FormField
              label={intl.formatMessage(messages.contactDetailsSignUpMethod)}
              isEditable={false}
            >
              {profile && getProviderTypeElementCallback()}
              {showIdentityWarningMsg && identityProviderWarning}
            </FormField>

            <FormField
              label={intl.formatMessage(messages.contactDetailsEmail)}
              isEditable={false}
            >
              <DisplayField
                className="with-edit"
                value={
                  <>
                    <div className="contact-details__content--text">
                      {profile?.email}
                    </div>
                    {profile?.idpType === IDP_TYPE.EMAIL && (
                      <IconButton
                        onClick={handleUpdateEmail}
                        aria-label={intl.formatMessage(
                          messages.contactDetailsEditEmailProvider
                        )}
                      >
                        {PenIcon}
                      </IconButton>
                    )}
                  </>
                }
              />
            </FormField>
          </InfoCard>

          <InfoCard>
            <FormField
              label={intl.formatMessage(messages.contactDetailsMobilePhone)}
              isEditable={false}
            >
              <DisplayField
                className="with-edit"
                value={
                  <>
                    <div className="contact-details__content--text">
                      {patientProfileData?.phone}
                    </div>
                    <IconButton
                      onClick={handleUpdatePhone}
                      aria-label={intl.formatMessage(
                        messages.contactDetailsEditMobilePhone
                      )}
                    >
                      {PenIcon}
                    </IconButton>
                  </>
                }
              />
            </FormField>
          </InfoCard>

          <InfoCard>
            <FormField
              label={intl.formatMessage(messages.contactDetailsAddress)}
              isEditable={false}
            >
              {!patientWithoutAddress(patientProfileData) &&
                patientProfileData?.addresses?.map((address) => (
                  <DisplayField
                    key={address.uid}
                    className="with-edit with-divider"
                    value={
                      <>
                        <div className="contact-details__content--text">
                          {getAddressAsString(address)}
                        </div>
                        <IconButton
                          onClick={handleUpdateAddress(address)}
                          aria-label={intl.formatMessage(
                            messages.contactDetailsEditAddress
                          )}
                        >
                          {PenIcon}
                        </IconButton>
                      </>
                    }
                  />
                ))}
            </FormField>
            {patientWithoutAddress(patientProfileData) && (
              <div className="contact-details__content--action">
                <Button onClick={handleAddNewAddressButton} outlined>
                  {intl.formatMessage(
                    messages.contactDetailsAddressAddNewAddress
                  )}
                </Button>
              </div>
            )}
          </InfoCard>
        </>
      )}
      <SpinnerView isLoading={profileDataIsLoading} />
    </BlueHeaderContent>
  );
};

export default ContactDetailsInfo;
