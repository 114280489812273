import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { HeapPHIDecorator } from "@app/components/analytics";
import { messages } from "../messages";
import { Textfield } from "@natera/platform/lib/components/form/field";
import { GetValidationError } from "@natera/platform/lib/hooks/useError";
import { ConfigContext } from "@app/provider";
import { FormField } from "@app/components";

const CityField: React.FC<{
  getValidationError: GetValidationError;
  defaultValue?: string;
}> = ({ getValidationError, defaultValue }) => {
  const intl = useIntl();
  const { config } = useContext(ConfigContext);

  return (
    <FormField
      title={intl.formatMessage(messages.addressFormCity)}
      label={intl.formatMessage(messages.addressFormCity)}
      error={getValidationError("city")}
      htmlFor="city"
      required
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <Textfield
          placeholder={intl.formatMessage(messages.addressFormCityPlaceholder)}
          id="city"
          name="city"
          data-testid="city"
          defaultValue={defaultValue}
          autoComplete="address-level2"
          outline
          required
          maxLength={config.TEXT_FIELD_CITY_AND_COUNTRY_LENGTH}
        />
      </HeapPHIDecorator>
    </FormField>
  );
};

export { CityField };
