import React, { FC, useContext, useEffect, useRef } from "react";
import { sanitize } from "dompurify";
import { APIContext } from "@app/neva/provider";
import { Dialog } from "@natera/material/lib/dialog";
import "./patientReviewDialog.scss";
import Button from "@natera/material/lib/button";
import { IntlContext } from "@app/provider";
import { useLinkHandler } from "@app/neva/hooks/useLinkHandler";
import { useLogEvent } from "@app/neva/hooks";
import { NEVA_HEAP_EVENTS } from "@app/neva/models/constants";

interface Props {
  onClose: () => void;
}

const PatientReviewDialog: FC<Props> = ({ onClose }) => {
  const output = useRef<HTMLDivElement>(null);
  const { linkEditorListener } = useLinkHandler();
  const { logEvent } = useLogEvent();
  const {
    apiProviderState: { widgetSettings },
  } = useContext(APIContext);
  const { currentLanguage } = useContext(IntlContext);
  const patientReview =
    widgetSettings?.languageConfigs[currentLanguage].widgetPopup.patientReview;

  useEffect(() => {
    logEvent({
      eventAction: NEVA_HEAP_EVENTS.EF_REVIEW,
      eventLabel: "User Action",
    });
    linkEditorListener(output, onClose, true);
  }, []);

  const handlePatientReviewDialogClose = () => {
    logEvent({
      eventAction: NEVA_HEAP_EVENTS.EF_REVIEW_CANCEL,
      eventLabel: "User Action",
    });
    onClose();
  };

  const handlePatientReviewDialogProceed = () => {
    logEvent({
      eventAction: NEVA_HEAP_EVENTS.EF_REVIEW_PROCEED,
      eventLabel: "User Action",
    });
    const url = patientReview?.proceedButtonUrl;
    window.open(url, "_blank");
    onClose();
  };

  const actions = (
    <>
      <Button onClick={handlePatientReviewDialogClose} outlined>
        {patientReview?.cancelButtonText}
      </Button>
      <Button onClick={handlePatientReviewDialogProceed} raised>
        {patientReview?.proceedButtonText}
      </Button>
    </>
  );

  return (
    <Dialog
      className="patient-review-dialog"
      onClose={onClose}
      actions={actions}
      title={patientReview?.popupTitle}
      focusTrapOptions={{ skipInitialFocus: true }}
    >
      <div ref={output} className="patient-review__wrapper">
        <div
          className="dialog-container"
          dangerouslySetInnerHTML={{
            __html: sanitize(patientReview?.popupText as string),
          }}
        />
      </div>
    </Dialog>
  );
};

export default PatientReviewDialog;
