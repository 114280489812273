import React, { FC } from "react";
import "./overlayBackground.scss";

const OverlayBackground: FC = () => {
  return (
    <div className="widget__background" data-testid="overlay-background" />
  );
};

export default OverlayBackground;
