import { DocumentNode, gql } from "@apollo/client";

class FetalSexService {
  public static getFetalSex = (): DocumentNode => gql`
    query GetFetalSexIsShown($orderUid: String!) {
      getFetalSexIsShown(orderUid: $orderUid) {
        fetalSexIsShown
      }
    }
  `;

  public static updateFetalSexIsShown = (): DocumentNode => gql`
    mutation UpdateFetalSexIsShown(
      $orderUid: String!
      $testUid: String!
      $fetalSexIsShown: Boolean!
    ) {
      updateFetalSexIsShown(
        orderUid: $orderUid
        testUid: $testUid
        fetalSexIsShown: $fetalSexIsShown
      ) {
        orderUid
        fetalSexIsShown
        documentUid
      }
    }
  `;
}

export default FetalSexService;
