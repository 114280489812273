import { StepperContent } from "@app/components/ui/layout/stepperContent";
import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import SampleDrawKitAddAddress from "./sampleDrawKitAddAddress";
import {
  Address,
  KitContext,
  ProfileContext,
  TestDetailsContext,
} from "@app/provider";
import SampleDrawKitSelectAddress from "./sampleDrawKitSelectAddress";
import { getListOfAddresses } from "@app/utils";
import { useDialog } from "@natera/platform/lib/hooks";
import { SpinnerView } from "@app/components";
import KitSuccessDialog from "./kitSuccessDialog";
import KitErrorDialog from "./kitErrorDialog";
import TimingInfoDialog from "./timingInfoDialog";
import {
  AddressFormType,
  POBoxErrorNotification,
} from "@app/components/poBoxErrorNotification/poBoxErrorNotification";
import { isPOBoxOrPMB } from "@app/utils/isPOBoxOrPMB";
import { BusinessUnits } from "@app/provider/drawRequest";
import { routes } from "@app/routing";
import { useHistory } from "react-router-dom";

const messages = defineMessages({
  sampleDrawKitStepTitle: {
    id: "sampleDrawKitStepTitle",
    defaultMessage: "Kit Shipping Details",
  },
  sampleDrawKitStepDescriptionTitle: {
    id: "sampleDrawKitStepDescriptionTitle",
    defaultMessage: "Where should we send your kit?",
  },
  kitShippingAddressSelectDescriptionContent: {
    id: "kitShippingAddressSelectDescriptionContent",
    defaultMessage:
      "A test kit is needed to get your sample drawn. To have your kit shipped to a different address from the one shown, add a new address.",
  },
  kitShippingAddressEnterNewAddressDescriptionContent: {
    id: "kitShippingAddressEnterNewAddressDescriptionContent",
    defaultMessage:
      "A test kit is needed to get your sample drawn. To have your kit shipped, please enter a new address.",
  },
});

export enum ADDRESS_VIEW_TYPE {
  CURRENT = "CURRENT",
  NEW = "NEW",
}

export type SampleDrawKitProps = {
  onSubmit: () => Promise<void>;
  onBack: () => Promise<void>;
  profileAddresses?: Address[];
  orderUid?: string;
  testUid?: string;
  token?: string;
  businessUnitForGuestFlow?: BusinessUnits;
};

const SampleDrawKitStep: React.FC<SampleDrawKitProps> = ({
  onSubmit,
  onBack,
  orderUid: orderUidProp,
  testUid: testUidProp,
  profileAddresses,
  token,
  businessUnitForGuestFlow,
}) => {
  const intl = useIntl();
  const successDialog = useDialog(KitSuccessDialog);
  const errorDialog = useDialog(KitErrorDialog);
  const timingDialog = useDialog(TimingInfoDialog);
  const history = useHistory();

  const { getProfile, getProfileData: profile } = React.useContext(
    ProfileContext
  );
  const { addKit, addKitIsLoading } = React.useContext(KitContext);
  const { getOrderUid, getTestUid, getTestDetails } = React.useContext(
    TestDetailsContext
  );

  const orderUid = orderUidProp ?? getOrderUid();
  const testUid = testUidProp ?? getTestUid();
  const addresses: Address[] = getListOfAddresses(
    profileAddresses ? profileAddresses : profile?.addresses ?? []
  );
  const testDetails = getTestDetails();
  const businessUnit = testDetails?.businessUnit ?? businessUnitForGuestFlow;

  const hasAddresses = addresses.length > 0;

  const [selectedAddressView, setSelectedAddressView] = React.useState<
    ADDRESS_VIEW_TYPE
  >(hasAddresses ? ADDRESS_VIEW_TYPE.CURRENT : ADDRESS_VIEW_TYPE.NEW);
  const [showPOBoxError, setShowPOBoxError] = React.useState<boolean>(false);
  const isOncology = businessUnit === BusinessUnits.ONCOLOGY;

  const closeTimingDialogAndRedirect = () => {
    timingDialog.close();
    if (businessUnitForGuestFlow) {
      onBack();
    } else {
      history.push(routes.home);
    }
  };

  React.useEffect(() => {
    if (isOncology) {
      timingDialog.open({
        onClose: closeTimingDialogAndRedirect,
        onContinue: timingDialog.close,
      });
    }
  }, []);

  const closeStep = (closeDialog: () => void) => () => {
    closeDialog();
    onSubmit();
  };

  const handleSubmit = async (address: Partial<Address>) => {
    if (isPOBoxOrPMB(address.street1, address?.street2)) {
      setShowPOBoxError(true);
      return;
    }

    if (!address.countryCode) {
      address.countryCode = "US";
    }

    try {
      const res = await addKit(orderUid, testUid, address, token);
      await getProfile();

      if (res) {
        successDialog.open({ onClose: closeStep(successDialog.close) });
      } else {
        errorDialog.open({
          onClose: closeStep(errorDialog.close),
          businessUnit,
        });
      }
    } catch (error) {
      errorDialog.open({ onClose: closeStep(errorDialog.close), businessUnit });
    }
  };

  const handleBack = () => {
    if (profile || !hasAddresses) {
      onBack();
    } else {
      setSelectedAddressView(ADDRESS_VIEW_TYPE.CURRENT);
    }
  };

  const onAddNewAddress = () => setSelectedAddressView(ADDRESS_VIEW_TYPE.NEW);

  useEffect(() => {
    setShowPOBoxError(false);
  }, [selectedAddressView]);

  return (
    <>
      {showPOBoxError && (
        <POBoxErrorNotification formType={AddressFormType.KIT} />
      )}
      <StepperContent
        title={intl.formatMessage(messages.sampleDrawKitStepTitle)}
        subTitle={intl.formatMessage(
          messages.sampleDrawKitStepDescriptionTitle
        )}
        description={
          selectedAddressView === ADDRESS_VIEW_TYPE.CURRENT
            ? intl.formatMessage(
                messages.kitShippingAddressSelectDescriptionContent
              )
            : intl.formatMessage(
                messages.kitShippingAddressEnterNewAddressDescriptionContent
              )
        }
      >
        <>
          {timingDialog.getDialog()}
          {successDialog.getDialog()}
          {errorDialog.getDialog()}
          <SpinnerView isLoading={addKitIsLoading} />
          {selectedAddressView === ADDRESS_VIEW_TYPE.CURRENT ? (
            <SampleDrawKitSelectAddress
              addresses={addresses}
              onAddNewAddress={onAddNewAddress}
              onSubmit={handleSubmit}
              onBack={onBack}
            />
          ) : (
            <SampleDrawKitAddAddress
              onSubmit={handleSubmit}
              onBack={handleBack}
            />
          )}
        </>
      </StepperContent>
    </>
  );
};

export default SampleDrawKitStep;
