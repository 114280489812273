import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "@app/routing";
import CommunicationPreferencesPage from "./communicationPreferencesPage";
import UpdateCommunicationPreferences from "./updateCommunicationPreferences";

const CommunicationPreferences: FC = () => {
  return (
    <Switch>
      <Route
        path={routes.updateCommunicationPreferences}
        component={UpdateCommunicationPreferences}
      />
      <Route path="/" component={CommunicationPreferencesPage} />
    </Switch>
  );
};

export default CommunicationPreferences;
