import { HeapPHIDecorator } from "@app/components/analytics";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import { Button } from "@natera/material/lib/button";
import { SpinnerView } from "@app/components";
import {
  ConfigContext,
  NotificationContext,
  ResetPasswordContext,
} from "@app/provider";
import { maskEmail, useQuery, validateEmail } from "@app/utils";
import { routes } from "@app/routing";

import Email from "@assets/svg/icons/email.svg";
import Svg from "@natera/material/lib/svg";
import "./sendEmail.scss";

const messages = defineMessages({
  sendEmailDone: {
    id: "sendEmailDone",
    defaultMessage: "Done",
  },
  sendEmailResendLink: {
    id: "sendEmailResendLink",
    defaultMessage: "Resend Reset Link",
  },
  sendEmailCheckMail: {
    id: "sendEmailCheckMail",
    defaultMessage: "Check your mail",
  },
  sendEmailInstructions: {
    id: "sendEmailInstructions",
    defaultMessage:
      "We have sent password recovery instructions to your email.",
  },
  sendEmailValidLink: {
    id: "sendEmailValidLink",
    defaultMessage: "The link is valid only for {time} minutes.",
  },
  sendEmailCheckSpam: {
    id: "sendEmailCheckSpam",
    defaultMessage:
      "Did not receive the email? Check your spam filter, or {link}",
  },
  sendEmailTryAnotherEmail: {
    id: "sendEmailTryAnotherEmail",
    defaultMessage: "Try another email address",
  },
  sendEmailResetLinkSent: {
    id: "sendEmailResetLinkSent",
    defaultMessage:
      "We’ve sent you another reset link. Please follow the instructions",
  },
  sendEmailResendLinkTitle: {
    id: "sendEmailResendLinkTitle",
    defaultMessage: "Reset link sent",
  },
});

const SendEmail: React.FC = () => {
  const {
    resetPassword,
    resetPasswordData,
    resetPasswordIsLoading,
  } = React.useContext(ResetPasswordContext);
  const { addNotification, clear } = React.useContext(NotificationContext);
  const { config } = React.useContext(ConfigContext);
  const [resendIsDisabled, setResendIsDisabled] = React.useState(false);
  const resendTimeoutRef = React.useRef<NodeJS.Timeout>();
  const query = useQuery();
  const [email, setEmail] = React.useState(query.get("email") || "");
  const history = useHistory();
  const intl = useIntl();

  const usePlusSignInEmailAddress =
    config.test.usePlusSignInEmailAddress.enabled;

  const resendEmail = () => {
    clear();
    setResendIsDisabled(true);

    resetPassword(email).then((resp) => {
      if (resp?.email) {
        addNotification({
          title: intl.formatMessage(messages.sendEmailResendLinkTitle),
          message: intl.formatMessage(messages.sendEmailResetLinkSent),
          type: "info",
        });
      }
    });

    resendTimeoutRef.current = setTimeout(() => {
      setResendIsDisabled(false);

      if (resendTimeoutRef.current) {
        clearTimeout();
      }
    }, config.timeouts.resendTimeout);
  };

  React.useEffect(() => {
    if (resetPasswordData?.email) {
      setEmail(resetPasswordData.email);
    } else if (
      !query.get("email") ||
      !validateEmail(query.get("email") || "", usePlusSignInEmailAddress)
    ) {
      history.replace(routes.root);
    }
  }, []);

  return (
    <form className="send-email__container">
      <div className="image-container">
        <Svg content={Email} />
      </div>

      <div className="description-container">
        <h2>{intl.formatMessage(messages.sendEmailCheckMail)}</h2>

        <p>{intl.formatMessage(messages.sendEmailInstructions)}</p>

        <p>
          <HeapPHIDecorator protectText noWrap>
            <span>{maskEmail(email || "")}</span>
          </HeapPHIDecorator>
        </p>

        <p>
          {intl.formatMessage(messages.sendEmailValidLink, {
            time: "60",
          })}
        </p>
      </div>

      <div className="buttons-container">
        <Button
          onClick={resendEmail}
          type="button"
          outlined
          disabled={resendIsDisabled}
        >
          {intl.formatMessage(messages.sendEmailResendLink)}
        </Button>

        <Link to={routes.root}>
          {intl.formatMessage(messages.sendEmailDone)}
        </Link>

        <p>
          {intl.formatMessage(messages.sendEmailCheckSpam, {
            link: (
              <Link to={routes.resetPassword}>
                {intl.formatMessage(messages.sendEmailTryAnotherEmail)}
              </Link>
            ),
          })}
        </p>
      </div>

      <SpinnerView isLoading={resetPasswordIsLoading} />
    </form>
  );
};

export default SendEmail;
