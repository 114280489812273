import React, { RefObject, useMemo, forwardRef } from "react";
import classNames from "classnames";

import { Svg } from "@natera/material/lib/svg";
import InfoIcon from "@assets/svg/icons/info.svg";
import WarningIcon from "@assets/svg/icons/warning.svg";
import ErrorIcon from "@assets/svg/icons/error.svg";
import SuccessIcon from "@assets/svg/icons/success.svg";
import "./notification.scss";

export type NotificationType =
  | "info"
  | "warning"
  | "error"
  | "note"
  | "success";

export interface NotificationProps
  extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  icon?: string;
  type?: NotificationType;
  ref?: RefObject<HTMLDivElement>;
  titleElem?: React.ReactNode;
  actions?: React.ReactNode;
}

const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  ({ className, icon, titleElem, actions, type, children, ...props }, ref) => {
    const iconElement = useMemo(() => {
      if (icon) {
        return <Svg content={icon} />;
      }

      switch (type) {
        case "info":
          return <Svg content={InfoIcon} />;
        case "warning":
          return <Svg content={WarningIcon} />;
        case "error":
          return <Svg content={ErrorIcon} />;
        case "success":
          return <Svg content={SuccessIcon} />;
        default:
          return <Svg content={InfoIcon} />;
      }
    }, [icon, type]);

    return (
      <section
        className={classNames(
          "notification__container",
          className,
          `notification__container_${type}`
        )}
        ref={ref}
        {...props}
      >
        {iconElement}
        <div className="notification__content">
          {titleElem && <div className="notification__title">{titleElem}</div>}
          {children}
          {actions && <div className="notification__actions">{actions}</div>}
        </div>
      </section>
    );
  }
);

Notification.displayName = "Notification";

export default Notification;
