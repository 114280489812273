import { DocumentNode, gql } from "@apollo/client";

class SignUpService {
  public static signUpByPassword = (): DocumentNode => gql`
    mutation SignUpByPassword($signUpByPasswordData: SignUpByPasswordDTO!) {
      signUpByPassword(signUpByPasswordData: $signUpByPasswordData) {
        id
        email
        isTokenValid
      }
    }
  `;
}

export default SignUpService;
