type Attribute = {
  id?: number;
  name: string;
  value: string;
};

export const base64Utf16Decode = (input: string) => {
  const text = window.atob(input);
  const length = text.length;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = text.charCodeAt(i);
  }
  const decoder = new TextDecoder("utf-16be");
  return decoder.decode(bytes);
};

export const parseAttributes = (payload: string): Attribute[] => {
  let base64Value;

  try {
    const url = new URL(payload);
    base64Value = url.searchParams.get("attributes");
  } catch (e) {
    base64Value = payload?.split(";?attributes=")[1];
  }

  if (base64Value) {
    const base64DecodedValue = base64Utf16Decode(base64Value);
    return JSON.parse(base64DecodedValue);
  }
  return [];
};

export const hasAttributeValue = (
  payload: string,
  attributeName: string,
  attributeValue: string
): boolean => {
  return parseAttributes(payload).some(
    (item) => item.name === attributeName && item.value === attributeValue
  );
};

export const updatePayloadAttributes = (
  payload: string,
  attributes: string
): string => {
  return payload.split(";?attributes=")[0] + attributes;
};
