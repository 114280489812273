import React, { FC, useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import {
  CommonErrorNotification,
  SpinnerView,
  UpdateCommunicationForm,
} from "@app/components";
import { BlueHeaderContent } from "@app/components/ui/layout";
import { ProfileContext } from "@app/provider";
import { Communication } from "@app/provider/profile/types";
import { HeapEventLocation } from "@app/provider/types";

const messages = defineMessages({
  updateCommunicationBackButton: {
    id: "updateCommunicationBackButton",
    defaultMessage: "Communication Preferences",
  },
});

const UpdatePersonalInformation: FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const {
    profileData,
    profileDataIsLoading,
    updatePatientCommunication,
    updatePatientCommunicationIsLoading,
    updatePatientCommunicationError,
  } = useContext(ProfileContext);

  const [submited, setSubmited] = useState(false);

  const handleSubmit = async (communicationData: Communication[]) => {
    setSubmited(true);
    await updatePatientCommunication(
      communicationData,
      HeapEventLocation.patientProfile
    );
    history.goBack();
  };

  const handleCancel = () => history.goBack();

  const notification =
    submited && updatePatientCommunicationError ? (
      <CommonErrorNotification />
    ) : null;

  return (
    <BlueHeaderContent
      blueHeaderTitle={intl.formatMessage(
        messages.updateCommunicationBackButton
      )}
      backArrowHeaderRoute={routes.communicationPreferences}
    >
      <UpdateCommunicationForm
        communicationData={profileData?.communicationPreferences}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        notification={notification}
      />
      <SpinnerView
        isLoading={profileDataIsLoading || updatePatientCommunicationIsLoading}
      />
    </BlueHeaderContent>
  );
};

export default UpdatePersonalInformation;
