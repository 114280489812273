import React, { FC, useContext } from "react";
import Container from "@app/neva/components/container";
import OverlayBackground from "@app/neva/components/overlayBackground";
import LauncherContainer from "@app/neva/components/launcher/container";
import { NevaContext } from "@app/neva/provider";

const NevaBotWrapper: FC = () => {
  const { isWidgetOpen } = useContext(NevaContext);

  return (
    <>
      {isWidgetOpen ? (
        <>
          <Container />
          <OverlayBackground />
        </>
      ) : (
        <LauncherContainer />
      )}
    </>
  );
};

export default NevaBotWrapper;
