import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { MaterialOption, MaterialSelect } from "@natera/material/lib/select";
import { DropdownItemType } from "@app/neva/models";
import "./dropdownSelect.scss";

interface Props {
  items: DropdownItemType[];
  isDisabled: boolean;
  onSelect: (i: DropdownItemType) => void;
  selectedValue: string[];
}

const messages = defineMessages({
  dropdownSelectTitle: {
    id: "dropdownSelectTitle",
    defaultMessage: "Select",
  },
});

const DropdownSelect: FC<Props> = ({
  items,
  isDisabled,
  onSelect,
  selectedValue,
}) => {
  const intl = useIntl();

  const onChangeHandler = (item: string) => {
    const itemsArray = item.split(",");
    const selectedItem = {
      option: itemsArray[0],
      attributePayload: itemsArray[1],
    };
    onSelect(selectedItem);
  };

  return (
    <MaterialSelect
      className="widget__message-dropdown"
      placeholder={intl.formatMessage(messages.dropdownSelectTitle)}
      onValueChange={onChangeHandler}
      disabled={isDisabled}
      data-testid="dropdown-button"
      outlined={true}
      value={selectedValue}
    >
      {items.map((value, index) => (
        <MaterialOption
          key={index}
          value={[value.option, value.attributePayload]}
          data-testid="dropdown-item"
        >
          {value.option}
        </MaterialOption>
      ))}
    </MaterialSelect>
  );
};

export default DropdownSelect;
