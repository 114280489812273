import React, { FC } from "react";
import R from "ramda";

import { TestType } from "@app/provider/testData/types";
import { TestResultContext } from "@app/provider";
import UnderstandingYourResultsVideo from "./undestandingYourResultsVideo";
import UnderstandingYourResultsAdditionalInfo from "./understandingYourResultsAdditionalInfo";
import { HeapVideoContentType } from "@app/provider/types";
import "./understandingYourResults.scss";
import { TestResult } from "@app/provider/testResult";

const UNDERSTANDING_RESULTS_VIDEO_NAMES = {
  carrier: "https://natera.wistia.com/medias/exrffwb96m",
  negative: "https://natera.wistia.com/medias/a7j955mjnt",
  positive: "https://natera.wistia.com/medias/1gxjfyr3bt",
  increased: "https://natera.wistia.com/medias/2rlw9f5m04",
  decreased: "https://natera.wistia.com/medias/dke4dyducw",
  prosperaHeartOrLung: "https://natera.wistia.com/medias/kra0ssgdc9",
};
interface Props {
  testResult: TestResult;
}

const UnderstandingYourResultsContent: FC<Props> = ({ testResult }) => {
  const { supplementalMaterials } = React.useContext(TestResultContext);
  const [videoName, setVideoName] = React.useState<string>();
  const [url, setUrl] = React.useState<string | undefined>(undefined);

  const testTypeIsProspera = testResult.testType === TestType.PROSPERA;
  const testTypeIsRenasight = testResult.testType === TestType.RENASIGHT;

  React.useEffect(() => {
    setVideoName(
      supplementalMaterials?.url ? supplementalMaterials.url : undefined
    );
    setUrl(
      supplementalMaterials?.url
        ? R.last(supplementalMaterials.url.split("/")) || ""
        : ""
    );
  }, [supplementalMaterials]);

  const getHeapVideoContentType = () => {
    if (!testTypeIsRenasight && !testTypeIsProspera) return;

    switch (videoName) {
      case UNDERSTANDING_RESULTS_VIDEO_NAMES.carrier:
        return HeapVideoContentType.renasightCarrier;
      case UNDERSTANDING_RESULTS_VIDEO_NAMES.negative:
        return HeapVideoContentType.renasightNegative;
      case UNDERSTANDING_RESULTS_VIDEO_NAMES.positive:
        return HeapVideoContentType.renasightPositive;
      case UNDERSTANDING_RESULTS_VIDEO_NAMES.increased:
        return HeapVideoContentType.prosperaIncreasedRisk;
      case UNDERSTANDING_RESULTS_VIDEO_NAMES.decreased:
        return HeapVideoContentType.prosperaDecreasedRisk;
      case UNDERSTANDING_RESULTS_VIDEO_NAMES.prosperaHeartOrLung:
        return HeapVideoContentType.prosperaHeartOrLung;
      default:
        return undefined;
    }
  };

  return url ? (
    <UnderstandingYourResultsVideo
      url={url}
      heapVideoContentType={getHeapVideoContentType()}
    />
  ) : (
    <UnderstandingYourResultsAdditionalInfo />
  );
};

export default UnderstandingYourResultsContent;
