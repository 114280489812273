import React from "react";
import { useIntl } from "react-intl";
import { HeapPHIDecorator } from "@app/components/analytics";
import STATES from "@etc/states.json";
import { messages } from "../messages";
import { Option, Select } from "@natera/platform/lib/components/form/field";
import { GetValidationError } from "@natera/platform/lib/hooks/useError";
import { FormField } from "@app/components";

const StateField: React.FC<{
  getValidationError: GetValidationError;
  defaultValue?: string;
}> = ({ getValidationError, defaultValue }) => {
  const intl = useIntl();

  return (
    <FormField
      title={intl.formatMessage(messages.addressFormState)}
      label={intl.formatMessage(messages.addressFormState)}
      error={getValidationError("state")}
      required
      id="state"
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <Select
          outlined
          required
          labelId="state"
          name="state"
          data-testid="state"
          role="combobox"
          placeholder={intl.formatMessage(messages.addressFormStatePlaceholder)}
          defaultValue={defaultValue}
        >
          {STATES.map((item) => (
            <Option key={item.abbreviation} value={item.abbreviation}>
              {item.name}
            </Option>
          ))}
        </Select>
      </HeapPHIDecorator>
    </FormField>
  );
};

export { StateField };
