import React, { FC, useContext, useEffect } from "react";
import { StepperContext } from "@natera/stepper";
import { Steps } from "../simpleOrderStepper";

import {
  PartnerInfoStepResolve,
  SimpleOrderStepperContext,
  SimpleOrderSubSteps,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import PartnerAcknowledgment from "./partnerAcknowledgment/partnerAcknowledgment";
import { PartnerInfo } from "@app/components";
import PartnerSignature from "./partherSignature/partnerSignature";

const PartnerInfoController: FC = () => {
  const { getSubStep, setSubStep, resolveSubStep, getSubStepData } = useContext(
    SimpleOrderStepperContext
  );
  const { goBack, resolve } = React.useContext(StepperContext);

  const subStep = getSubStep();

  useEffect(() => {
    if (
      subStep !== SimpleOrderSubSteps.PARTNER_INFO &&
      subStep !== SimpleOrderSubSteps.PARTNER_ACKNOWLEDGMENT &&
      subStep !== SimpleOrderSubSteps.PARTNER_SIGNATURE
    ) {
      const initialPartnerInfo = getSubStepData(
        Steps.PARTNER_INFO
      ) as PartnerInfoStepResolve;

      initialPartnerInfo?.signature
        ? setSubStep(SimpleOrderSubSteps.PARTNER_SIGNATURE)
        : setSubStep(SimpleOrderSubSteps.PARTNER_INFO);
    }
  }, []);

  const isShowAcknowledgment = (value: PartnerInfoStepResolve) => {
    return !!(
      value.partner?.firstName &&
      value.partner?.lastName &&
      value.partner?.dateOfBirth &&
      value.partner?.phone
    );
  };

  const goBack$ = () => {
    switch (subStep) {
      case SimpleOrderSubSteps.PARTNER_INFO:
        return goBack();
      case SimpleOrderSubSteps.PARTNER_ACKNOWLEDGMENT:
        return setSubStep(SimpleOrderSubSteps.PARTNER_INFO);
      case SimpleOrderSubSteps.PARTNER_SIGNATURE:
        return setSubStep(SimpleOrderSubSteps.PARTNER_ACKNOWLEDGMENT);
      default:
        return goBack();
    }
  };

  const resolve$ = (value: PartnerInfoStepResolve) => {
    switch (subStep) {
      case SimpleOrderSubSteps.PARTNER_INFO:
        if (isShowAcknowledgment(value)) {
          setSubStep(SimpleOrderSubSteps.PARTNER_ACKNOWLEDGMENT);
          return resolveSubStep({
            stepId: Steps.PARTNER_INFO,
            subStepData: value,
          });
        } else {
          resolveSubStep({ stepId: Steps.PARTNER_INFO, subStepData: value });
          return resolve(value);
        }
      case SimpleOrderSubSteps.PARTNER_ACKNOWLEDGMENT:
        setSubStep(SimpleOrderSubSteps.PARTNER_SIGNATURE);
        resolveSubStep({ stepId: Steps.PARTNER_INFO, subStepData: value });
        return;
      case SimpleOrderSubSteps.PARTNER_SIGNATURE:
        resolveSubStep({ stepId: Steps.PARTNER_INFO, subStepData: value });
        return resolve(value);
      default:
        return resolve(value);
    }
  };

  const renderSubStep = () => {
    switch (subStep) {
      case SimpleOrderSubSteps.PARTNER_ACKNOWLEDGMENT:
        return <PartnerAcknowledgment resolve={resolve$} goBack={goBack$} />;
      case SimpleOrderSubSteps.PARTNER_SIGNATURE:
        return <PartnerSignature goBack={goBack$} resolve={resolve$} />;
      default:
        return (
          <PartnerInfo
            initValue={
              getSubStepData(Steps.PARTNER_INFO) as PartnerInfoStepResolve
            }
            resolve={resolve$}
          />
        );
    }
  };

  return <>{renderSubStep()}</>;
};

export default PartnerInfoController;
