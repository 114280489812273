import React, { FC, useContext, useMemo, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { FACTORS_TO_SHOW, STAGE } from "@app/service/user";
import { Button } from "@natera/platform/lib/components/form";
import { Textfield } from "@natera/platform/lib/components/form/field";
import {
  ConfigContext,
  ErrorContext,
  MfaErrorContext,
  MfaSetupContext,
  NotificationContext,
  ProfileContext,
  UserContext,
} from "@app/provider";
import { getProfileCredentials, verifyCodeLength } from "@app/utils";
import { Link } from "@natera/platform/lib/components/link";
import Svg from "@natera/material/lib/svg";
import Logo from "@assets/svg/natera-portal-logo.svg";
import { useDialog, useErrorController } from "@natera/platform/lib/hooks";
import UpdateMobileNumberDialog from "@app/components/verifyCodeForm/updateMobileNumberDialog";
import { SpinnerView, FormField } from "@app/components";

import "./verifyCodeForm.scss";

const messages = defineMessages({
  verifyCodeFormTitle: {
    id: "verifyCodeFormTitle",
    defaultMessage: "Enter Your Verification Code",
  },
  verifyCodeFormSentTo: {
    id: "verifyCodeFormSentTo",
    defaultMessage: "We sent a verification code to",
  },
  verifyCodeFormInputLabel: {
    id: "verifyCodeFormInputLabel",
    defaultMessage: "Verification Code",
  },
  verifyCodeFormResendButton: {
    id: "verifyCodeFormResendButton",
    defaultMessage: "Resend Code",
  },
  verifyCodeFormUpdateMobileNumber: {
    id: "verifyCodeFormUpdateMobileNumber",
    defaultMessage: "Update Mobile Number",
  },
  verifyCodeFormResendIn: {
    id: "verifyCodeFormResendIn",
    defaultMessage: "Resend in {timeLeft}s",
  },
  verifyCodeFormVerifyButton: {
    id: "verifyCodeFormVerifyButton",
    defaultMessage: "Verify",
  },
});

interface VerifyCodeFormProps extends React.HTMLAttributes<HTMLDivElement> {
  type: FACTORS_TO_SHOW;
  profile?: {
    email?: string;
    phoneNumber?: string;
  };
  handleCode: (code: string) => void;
  handleResend: () => void;
}

const VerifyCodeForm: FC<VerifyCodeFormProps> = ({
  type,
  profile,
  handleCode,
  handleResend,
}) => {
  const intl = useIntl();
  const timer = useRef<number>();
  const { config } = useContext(ConfigContext);
  const { uppUser } = React.useContext(UserContext);
  const { notifications } = useContext(NotificationContext);
  const { isLoading: profileIsLoading, getProfile } = useContext(
    ProfileContext
  );

  const { parseError } = useContext(MfaErrorContext);
  const errorController = useErrorController();

  const {
    isLoading: mfaIsLoading,
    disableUserFactors,
    enrollUserFactor,
  } = useContext(MfaSetupContext);

  const {
    profileDataIsLoading,
    updatePhone,
    updatePatientIsLoading,
  } = useContext(ProfileContext);

  const { getValidationError, clearValidationError } = useContext(ErrorContext);

  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const DEFAULT_COUNTER_TIME = config.DEFAULT_COUNTER_TIME;
  const DEFAULT_TIME_LEFT = config.DEFAULT_TIME_LEFT;

  const [lastResendTime, setLastResendTime] = useState(Date.now());
  const [timeLeft, setTimeLeft] = useState(DEFAULT_TIME_LEFT);
  const [value, setValue] = useState("");

  const [isResendCodeClicked, setIsResendCodeClicked] = useState<boolean>(
    false
  );

  const updateMobileNumberDialog = useDialog(UpdateMobileNumberDialog);

  const checkCounter = () => {
    const now = Date.now();
    const diff = now - lastResendTime;

    if (diff > DEFAULT_COUNTER_TIME) {
      setTimeLeft(0);
      setLastResendTime(0);
      return;
    }
    setTimeLeft(Math.floor((DEFAULT_COUNTER_TIME - diff) / 1000));
    timer.current = window.setTimeout(checkCounter, 100);
  };

  React.useEffect(() => {
    if (lastResendTime !== 0) {
      timer.current = window.setTimeout(checkCounter, 100);
      return () => clearTimeout(timer.current);
    }
  }, [lastResendTime]);

  const handleCodeSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    clearValidationError("code");
    const formData = e.currentTarget;
    handleCode(formData.code.value);
  };

  const handleResendCodeClick = () => {
    clearValidationError("code");
    setIsResendCodeClicked(true);
    handleResend();
    setLastResendTime(Date.now());
    setValue("");
  };

  const handleUpdatePhoneNumber = async (newPhoneNumber: string) => {
    try {
      await disableUserFactors();
      await enrollUserFactor(FACTORS_TO_SHOW.sms, "OKTA", {
        phoneNumber: newPhoneNumber,
      });

      await updatePhone(newPhoneNumber);
      await getProfile();

      setPhoneNumber(newPhoneNumber);
    } catch (error) {
      await parseError(error.errorCode, error.errorSummary, errorController);
    } finally {
      setLastResendTime(Date.now());
      setTimeLeft(DEFAULT_TIME_LEFT);

      updateMobileNumberDialog.close();
    }
  };

  const handleUpdateMobileNumberClick = () => {
    updateMobileNumberDialog.open({
      currentPhone: profile?.phoneNumber || "",
      handleSave: handleUpdatePhoneNumber,
      handleBack: updateMobileNumberDialog.close,
    });
  };

  const handleChangeCode: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    clearValidationError("code");
    setValue(e.currentTarget.value.length > 6 ? value : e.currentTarget.value);
  };

  const showUpdateMobileNumberButton = useMemo(() => {
    const showButton =
      isResendCodeClicked &&
      uppUser?.stage === STAGE.MFA_SETUP &&
      !uppUser?.invite &&
      type === FACTORS_TO_SHOW.sms;

    return showButton;
  }, [isResendCodeClicked, uppUser?.stage, uppUser?.invite, type]);

  const isLoading = useMemo(
    () =>
      profileIsLoading ||
      mfaIsLoading ||
      profileDataIsLoading ||
      updatePatientIsLoading,
    [
      profileIsLoading,
      mfaIsLoading,
      profileDataIsLoading,
      updatePatientIsLoading,
    ]
  );

  return (
    <div className="verify-code-container">
      <div className="verify-code-logo">
        <Link to="/">
          <Svg content={Logo} />
        </Link>
      </div>
      {notifications}
      <div className="verify-code-content">
        <h3>{intl.formatMessage(messages.verifyCodeFormTitle)}</h3>

        <div className="verify-code-to">
          <span>{`${intl.formatMessage(
            messages.verifyCodeFormSentTo
          )} ${getProfileCredentials(type, profile, phoneNumber)}`}</span>
        </div>
        <form
          onSubmit={handleCodeSubmit}
          className="verify-code-form"
          data-testid="verify-code-form"
        >
          <FormField
            error={getValidationError("code")}
            label={intl.formatMessage(messages.verifyCodeFormInputLabel)}
            required
            htmlFor="code"
            withPadding
          >
            <Textfield
              id="code"
              name="code"
              type="text"
              outline
              required
              onChange={handleChangeCode}
              placeholder="XXX-XXX"
              value={value}
            />
          </FormField>

          <div className="verify-code-resend">
            {showUpdateMobileNumberButton && (
              <Button
                className="update-mobile-number-button"
                outlined
                type="button"
                onClick={handleUpdateMobileNumberClick}
              >
                {intl.formatMessage(messages.verifyCodeFormUpdateMobileNumber)}
              </Button>
            )}
            {timeLeft === 0 ? (
              <>
                <Button outlined type="button" onClick={handleResendCodeClick}>
                  {intl.formatMessage(messages.verifyCodeFormResendButton)}
                </Button>
              </>
            ) : (
              <div className="resend-button">
                {intl.formatMessage(messages.verifyCodeFormResendIn, {
                  timeLeft,
                })}
              </div>
            )}
          </div>

          <Button
            raised
            type="submit"
            disabled={value.length < verifyCodeLength(type)}
          >
            {intl.formatMessage(messages.verifyCodeFormVerifyButton)}
          </Button>
        </form>
      </div>
      {updateMobileNumberDialog.getDialog()}
      <SpinnerView isLoading={isLoading} />
    </div>
  );
};

export default VerifyCodeForm;
