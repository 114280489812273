import { DocumentNode, gql } from "@apollo/client";

export default class OrderService {
  public static getOrder = (): DocumentNode => gql`
    query GetOrder($orderUid: String!) {
      getOrder(orderUid: $orderUid) {
        uid
        items {
          uid
          testType
          panels {
            name
            microdelPanel
          }
          product {
            configuration {
              sampleCollectionManagedBy
              caPnsSupplemental
            }
          }
        }
        clinic {
          limsId
          name
          settings {
            testSettings {
              ... on ClinicTestSetting {
                testType
                nevaEnabled
              }
            }
          }
          address {
            state
          }
        }
        workflows {
          uid
          type
          state
          sampleType
        }
      }
    }
  `;
}
