import React from "react";
import "./stepperContent.scss";

type Props = {
  title: string;
  subTitle?: string;
  description?: React.ReactNode;
  children: React.ReactNode;
};

const StepperContent: React.FC<Props> = ({
  title,
  subTitle,
  description,
  children,
}) => {
  return (
    <div className="step">
      <div className="step__head">
        <h3 className="step__head-title" tabIndex={0}>
          {title}
        </h3>
        <>
          {subTitle && (
            <h4 className="step__head-subtitle" tabIndex={0}>
              {subTitle}
            </h4>
          )}
          {description && (
            <p className="step__head-text" tabIndex={0}>
              {description}
            </p>
          )}
        </>
      </div>
      <div className="step__content">{children}</div>
    </div>
  );
};

export default StepperContent;
