import * as R from "ramda";

interface Storage<T> {
  get: () => T | null;
  remove: () => void;
  set: (value: T) => void;
}

class StorageValue<T> implements Storage<T> {
  private readonly storageKey: string = "";

  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  public get() {
    const storageValue = localStorage.getItem(this.storageKey);
    if (R.isNil(storageValue)) {
      return storageValue;
    }
    return JSON.parse(storageValue).value;
  }

  public set(value: T) {
    localStorage.setItem(this.storageKey, JSON.stringify({ value }));
  }

  public remove() {
    localStorage.removeItem(this.storageKey);
  }
}

// tslint:disable-next-line:max-classes-per-file
class StorageServiceBuilder {
  private storageKey = "";

  public withUppKey(): StorageServiceBuilder {
    this.storageKey = `UPP`;
    return this;
  }

  public withProfileKey(): StorageServiceBuilder {
    this.storageKey = `upp-profile`;
    return this;
  }

  public build<T>(): Storage<T> {
    return new StorageValue<T>(this.storageKey);
  }
}

export default StorageServiceBuilder;
