export const snakeCaseToCapitalizedWords = (
  text: string | undefined
): string | undefined =>
  text
    ? text
        .toLowerCase()
        .split("_")
        .map((word) =>
          word.length > 1 ? word.charAt(0).toUpperCase() + word.slice(1) : word
        )
        .join(" ")
    : text;

export default snakeCaseToCapitalizedWords;
