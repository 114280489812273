import React, { FC, useContext, useRef } from "react";
import Header from "@app/neva/components/header";
import Main from "@app/neva/components/main";
import Iframe from "@app/neva/components/iframe";
import { DialogsContext } from "@app/neva/provider";
import "./chat.scss";

const Chat: FC = () => {
  const {
    closeWidgetDialog,
    changeLanguageDialog,
    patientReviewDialog,
    iframeSettings,
  } = useContext(DialogsContext);
  const chatContainerRef = useRef<HTMLElement>(null);

  return (
    <article ref={chatContainerRef} className="widget-chat__container">
      <Header />
      <Main />
      {iframeSettings.isOpenIframe && (
        <Iframe chatContainerRef={chatContainerRef} />
      )}
      {closeWidgetDialog.getDialog()}
      {changeLanguageDialog.getDialog()}
      {patientReviewDialog.getDialog()}
    </article>
  );
};

export default Chat;
