import { ZipSelectorForm } from "@app/components/forms";
import { StepperContent } from "@app/components/ui/layout";
import { BloodDrawContext, ErrorProvider } from "@app/provider";
import { defineMessages } from "react-intl";
import { useErrorController } from "@natera/platform/lib/hooks";
import { StepperContext } from "@natera/stepper";
import React from "react";
import { useIntl } from "react-intl";
import { SpinnerView } from "@app/components";
import { Button } from "@natera/platform/lib/components/form";

const messages = defineMessages({
  sampleDrawLabTitle: {
    id: "sampleDrawLabTitle",
    defaultMessage: "Lab Locator",
  },
  labCollectionZipSelectorFind: {
    id: "labCollectionZipSelectorFind",
    defaultMessage: "Choose a location to get your blood drawn.",
  },
  labCollectionZipSelectorLocation: {
    id: "labCollectionZipSelectorLocation",
    defaultMessage:
      "Please enter your zip code to find nearby labs. \n Labs within 100 miles will be shown.",
  },
  labCollectionZipSelectorNoLabs: {
    id: "labCollectionZipSelectorNoLabs",
    defaultMessage: "No labs found in the specified location.",
  },
  labCollectionZipSelectorShow: {
    id: "labCollectionZipSelectorShow",
    defaultMessage: "Show labs near me",
  },
  labCollectionZipSelectorBack: {
    id: "labCollectionZipSelectorBack",
    defaultMessage: "Back",
  },
});

const ZipSelectorStep: React.FC = () => {
  const intl = useIntl();
  const errorController = useErrorController();

  const { goBack, resolve } = React.useContext(StepperContext);
  const { getBloodDrawSites, isLoading } = React.useContext(BloodDrawContext);

  const onSubmit = async (zipCode: string) => {
    const data = await getBloodDrawSites({ location: zipCode });
    if (!data?.length) {
      errorController.setError(
        intl.formatMessage(messages.labCollectionZipSelectorNoLabs)
      );
      return;
    }
    resolve({});
  };

  return (
    <div>
      <StepperContent
        title={intl.formatMessage(messages.sampleDrawLabTitle)}
        subTitle={intl.formatMessage(messages.labCollectionZipSelectorFind)}
        description={intl.formatMessage(
          messages.labCollectionZipSelectorLocation
        )}
      >
        <ErrorProvider controller={errorController}>
          <SpinnerView isLoading={isLoading} />
          <ZipSelectorForm
            onSubmit={onSubmit}
            actions={
              <>
                <Button type="submit" raised>
                  {intl.formatMessage(messages.labCollectionZipSelectorShow)}
                </Button>

                <Button onClick={goBack}>
                  {intl.formatMessage(messages.labCollectionZipSelectorBack)}
                </Button>
              </>
            }
          />
        </ErrorProvider>
      </StepperContent>
    </div>
  );
};

export default ZipSelectorStep;
