import React, { FC, useCallback, useEffect, createContext } from "react";

import { useResource } from "@natera/platform/lib/hooks";
import { ConfigService } from "@natera/platform/lib/service";
import { LoadingContext } from "@natera/platform/lib/components/context";
import defaultConfig from "@etc/config.json";
import defaultOktaConfig from "@etc/okta.json";
import defaultHeapConfig from "@etc/heap.json";
import { initializeDatadogRum } from "@app/utils/ddRumInitializer";

export type OktaConfig = typeof defaultOktaConfig;
export type HeapConfig = typeof defaultHeapConfig;
export type ProsperaOrgans = {
  KIDNEY: string;
  HEART: string;
  SPK: string;
  LUNG: string;
  LIVER: string;
  OTHER: string;
};
export interface ProductLinks {
  RENASIGHT: string;
  VISTARA: string;
  EMPOWER: {
    EN: string;
    ES: string;
  };
  EMPOWER_ONCOLOGY: string;
  SIGNATERA: string;
  ALTERA: string;
  HORIZON: string;
  PANORAMA: string;
  WOMENS_HEALTH: string;
  PROSPERA: ProsperaOrgans;
}

export type ClientConfig = {
  timers?: {
    idleTimer?: {
      timeoutDelay?: number;
      openedDialogDelay?: number;
    };
  };
  timeouts?: {
    resendTimeout?: number;
  };
  link: {
    product: ProductLinks;
    PATIENT_PORTAL_LINK: string;
    SCHEDULE_SESSION: string;
    CONTACT_US: string;
    COMPASSIONATE_CARE: string;
    TRACKING: string;
    TERMS: string;
    PRIVACY_POLICY: string;
    LICENSES: string;
    NOTICE_OF_PRIVACY_PRACTICES: string;
    WOMENS_HEALTH_PRICING_BILLING: string;
  };
  videoHashId: {
    SALIVA: {
      ES: string;
      EN: string;
    };
    HORIZON: {
      ES: string;
      EN: string;
    };
    PANORAMA: {
      ES: string;
      EN: string;
    };
  };
};

export type NevaConfig = {
  enabled: boolean;
  isAutoExpandEnabled: boolean;
  apiVersion: string;
  timeout: number;
  widgetLogsEnabled: boolean;
};

export type DDRumConfig = {
  applicationId: string;
  clientToken: string;
  env: string;
  version?: string;
  sessionSampleRate?: number;
  traceSampleRate?: number;
};

type RedirectionConfig = {
  redirectionEnabled: boolean;
  url: string;
};

export type Config = typeof defaultConfig & { okta: OktaConfig } & {
  heap?: HeapConfig;
} & {
  clientConfig?: ClientConfig;
} & {
  nevaConfig: NevaConfig;
} & {
  ddRum: DDRumConfig;
} & {
  patientPortal: RedirectionConfig;
};

export const Context = createContext<ConfigService<Config>>(
  {} as ConfigService<Config>
);

interface ConfigProviderProps {
  children: React.ReactNode;
  config: Config;
}

const ConfigProvider: FC<ConfigProviderProps> = ({ children, config }) => {
  const configResourceController = useResource<Config>({
    load: () =>
      fetch("/config")
        .then((response) => response.json())
        .then((runtimeConfig) => {
          const configs: Config = {
            ...config,
            ...runtimeConfig.clientConfig,
            okta: { ...defaultOktaConfig, ...runtimeConfig.okta },
            heap: { ...defaultHeapConfig, ...runtimeConfig.heap },
            patientPortal: runtimeConfig.patientPortal,
            buildNumber: runtimeConfig.buildNumber,
          };
          const heapId = configs.heap?.app_id;
          if (heapId) {
            heap.load(heapId);
          }
          configs.ddRum && initializeDatadogRum(configs.ddRum);
          return configs;
        })
        .catch(() => {
          const configs = {
            ...defaultConfig,
            okta: defaultOktaConfig,
            heap: defaultHeapConfig,
          };
          const heapId = configs.heap?.app_id;
          if (heapId) {
            heap.load(heapId);
          }
          configs.ddRum && initializeDatadogRum(configs.ddRum);
          return configs;
        }),
  });

  useEffect(() => {
    configResourceController.load();
  }, []);

  const portalConfig = configResourceController.getResource();

  const createConfigService = useCallback(() => {
    if (!portalConfig) {
      throw new Error("Can't load runtime configuration");
    }

    return new ConfigService(portalConfig);
  }, [portalConfig]);

  return (
    <LoadingContext
      isLoading={!portalConfig || configResourceController.isLoading()}
    >
      {portalConfig && (
        <Context.Provider value={createConfigService()}>
          {children}
        </Context.Provider>
      )}
    </LoadingContext>
  );
};

export default ConfigProvider;
