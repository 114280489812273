import {
  RadioGroup,
  RadioButton,
} from "@natera/platform/lib/components/form/field";
import classNames from "classnames";
import React from "react";

import "./companySelector.scss";
import { defineMessages, useIntl } from "react-intl";
import { Payor } from "@app/provider/payor";

const messages = defineMessages({
  insuranceCompanyLabelOther: {
    id: "insuranceCompanyLabelOther",
    defaultMessage: "Other",
  },
});

export enum PredefinedCompanyName {
  Aetna = "Aetna",
  BlueCrossBlueShield = "Blue Cross Blue Shield",
  Cigna = "Cigna",
  Humana = "Humana",
  Medicaid = "Medicaid",
  Tricare = "Tricare",
  UnitedHealthcareGroup = "United Healthcare Group",
  Other = "Other",
}

type PredefinedCompaniesType = { [key: string]: Payor };

export const PredefinedCompanies: PredefinedCompaniesType = {
  Aetna: {
    displayName: PredefinedCompanyName.Aetna,
    groupId: "AETNA",
  },
  BlueCrossBlueShield: {
    displayName: PredefinedCompanyName.BlueCrossBlueShield,
    groupId: "BCBS",
  },
  Cigna: {
    displayName: PredefinedCompanyName.Cigna,
    groupId: "CIGNA",
  },
  Humana: {
    displayName: PredefinedCompanyName.Humana,
    groupId: "HUMANA",
  },
  Medicaid: {
    displayName: PredefinedCompanyName.Medicaid,
    groupId: "STATE MEDICAID",
  },
  Tricare: {
    displayName: PredefinedCompanyName.Tricare,
    groupId: "TRICARE",
  },
  UnitedHealthcareGroup: {
    displayName: PredefinedCompanyName.UnitedHealthcareGroup,
    groupId: "UHC",
  },
  Other: {
    displayName: PredefinedCompanyName.Other,
  },
};

type Props = {
  value?: Payor;
  onChange: (company?: Payor) => void;
  companies: Payor[];
};

const CompanySelector: React.FC<Props> = ({ value, onChange, companies }) => {
  const intl = useIntl();

  const getCompanyLabel = (company: Payor) =>
    company.displayName === PredefinedCompanyName.Other
      ? intl.formatMessage(messages.insuranceCompanyLabelOther)
      : company.displayName;

  const changeHandler = (companyName: string) => {
    const selectedCompany = Object.values(PredefinedCompanies).find(
      (company) => company.displayName === companyName
    );
    onChange(selectedCompany);
  };

  return (
    <RadioGroup
      name="company-selector"
      value={value?.displayName}
      onChange={changeHandler}
      aria-required
    >
      {companies.map((company) => (
        <RadioButton
          id={company.displayName}
          key={company.displayName}
          value={company.displayName}
          label={getCompanyLabel(company)}
          aria-checked={company.displayName === value?.displayName}
          className={classNames("company-selector__radio_button", {
            selected: company.displayName === value?.displayName,
          })}
        />
      ))}
    </RadioGroup>
  );
};

export default CompanySelector;
