import React, { FC } from "react";
import { TestDetailsContext, TestResultContext } from "@app/provider";
import { defineMessages, useIntl } from "react-intl";
import { TestType } from "@app/provider/testData/types";
import { VideoPlayer } from "@app/components";
import { HeapVideoContentType, HeapVideoOrderData } from "@app/provider/types";

export const messages = defineMessages({
  understandingYourResultsWatchVideoFirstParagraph: {
    id: "understandingYourResultsWatchVideoFirstParagraph",
    defaultMessage:
      "To better help you understand your results, watch this short video before viewing your test results.",
  },
  understandingYourResultsWatchVideoRenasightParagraph: {
    id: "understandingYourResultsWatchVideoRenasightParagraph",
    defaultMessage:
      "If you still have questions, you'll have the opportunity to schedule time to talk to a genetic counselor after viewing your results.",
  },
  understandingYourResultsWatchVideoRenasightSecondParagraph: {
    id: "understandingYourResultsWatchVideoRenasightSecondParagraph",
    defaultMessage: "Watch this short video to view your results.",
  },
  understandingYourResultsWatchVideoAltText: {
    id: "understandingYourResultsWatchVideoAltText",
    defaultMessage: "Informational test results video",
  },
  understandingYourResultsWatchVideoProsperaParagraph: {
    id: "understandingYourResultsWatchVideoProsperaParagraph",
    defaultMessage:
      "Your doctor will consider these results along with your other regular lab tests to monitor the health of your transplanted organ.",
  },
});

interface Props {
  url: string;
  heapVideoContentType?: HeapVideoContentType;
}

const UnderstandingYourResultsVideo: FC<Props> = ({
  url,
  heapVideoContentType,
}) => {
  const intl = useIntl();
  const { testResult } = React.useContext(TestResultContext);
  const { getTestDetails } = React.useContext(TestDetailsContext);

  const testType = testResult?.testType;

  const testTypeIsRenasight = testType === TestType.RENASIGHT;
  const testTypeIsProspera = testType === TestType.PROSPERA;
  const isVideoAvailable = (testType?: TestType) => {
    const showVideoForTests = [TestType.PROSPERA, TestType.RENASIGHT];
    return testType && showVideoForTests.includes(testType);
  };

  const testDetails = getTestDetails();

  const [heapVideoOrderData, setHeapVideoOrderData] = React.useState<
    HeapVideoOrderData
  >();

  React.useEffect(() => {
    if (testDetails) {
      setHeapVideoOrderData({
        order_uid: testDetails.orderUid,
        lims_clinic_id: testDetails.clinic?.limsId,
      });
    }
  }, [testDetails]);

  return isVideoAvailable(testType) ? (
    <>
      {testTypeIsProspera && (
        <p>
          {intl.formatMessage(
            messages.understandingYourResultsWatchVideoProsperaParagraph
          )}
        </p>
      )}
      <p>
        {intl.formatMessage(
          messages.understandingYourResultsWatchVideoFirstParagraph
        )}
      </p>
      {testTypeIsRenasight && (
        <>
          <p>
            {intl.formatMessage(
              messages.understandingYourResultsWatchVideoRenasightParagraph
            )}
          </p>
          <p>
            {intl.formatMessage(
              messages.understandingYourResultsWatchVideoRenasightSecondParagraph
            )}
          </p>
        </>
      )}
      <VideoPlayer
        id={url}
        thumbnailAltText={intl.formatMessage(
          messages.understandingYourResultsWatchVideoAltText
        )}
        heapVideoContentType={heapVideoContentType}
        heapVideoOrderData={heapVideoOrderData}
      />
    </>
  ) : (
    <></>
  );
};

export default UnderstandingYourResultsVideo;
