import { datadogRum } from "@datadog/browser-rum";
import { DDRumConfig } from "@app/provider/config";
import { APP_VERSION } from "@app/version"; // if you don't have this file, run 'npm run extractVersion' to generate it

export const initializeDatadogRum = ({
  applicationId,
  clientToken,
  env,
  version = APP_VERSION,
  sessionSampleRate = 100,
  traceSampleRate = 0,
}: DDRumConfig): void => {
  if (applicationId && clientToken) {
    datadogRum.init({
      applicationId,
      clientToken,
      site: "datadoghq.com",
      service: "upp",
      env,
      version: version,
      sessionSampleRate: sessionSampleRate > 100 ? 100 : sessionSampleRate,
      sessionReplaySampleRate: 0,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      allowedTracingUrls: [
        /https:\/\/.*\.natera\.com.*/,
        /https:\/\/.*\.nateralab\.com.*/,
      ],
      traceSampleRate: traceSampleRate > 100 ? 100 : traceSampleRate,
    });
  }
};
