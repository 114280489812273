import React, { FC } from "react";
import classNames from "classnames";
import Svg from "@natera/material/lib/svg";
import bubbleTail from "@app/neva/assets/svg/bubble-tail.svg";
import "./bubbleTail.scss";

interface Props {
  isShowAvatar: boolean;
  isSendByUser: boolean;
}

const BubbleTail: FC<Props> = ({ isShowAvatar, isSendByUser }) => {
  const bubbleTailClass = classNames("bubble-tail", {
    "bubble-tail__received": isShowAvatar,
    "bubble-tail__send-by-user": isSendByUser,
  });

  return <Svg className={bubbleTailClass} content={bubbleTail} />;
};

export default BubbleTail;
