import React from "react";
import { SubSteps } from "./paymentMethod";
import {
  MultipleInsurancesFirstStep,
  MultipleInsurancesSecondStep,
} from "@app/components/simpleOrder/payment/steps";
import { CommercialSteps } from "@app/components/simpleOrder/payment/steps/selectCommercial/commercialStep";
import { GovernmentSteps } from "@app/components/simpleOrder/payment/steps/selectGovernment/governmentStep";

export type onConfirmType = (data: unknown) => void;
export type MultipleStepsIds =
  | SubSteps.MULTIPLE_FIRST
  | SubSteps.MULTIPLE_SECOND;

interface MultipleSubStepsIds {
  multipleStepChildComponent: (onConfirm: onConfirmType) => React.ReactChild;
  //Commercial:
  selectCommercialCompanyStepId: CommercialSteps;
  enterCommercialOtherCompanyStepId: SubSteps;
  provideCommercialInsuranceStepId: SubSteps;
  //Government:
  selectGovernmentCompanyStepId: GovernmentSteps;
  enterGovernmentOtherCompanyStepId: SubSteps;
  provideGovernmentInsuranceStepId: SubSteps;
}

export interface MultipleStep {
  stepId: string;
  index: number;
  childComponent: React.ReactChild;
  data: unknown;
}

export const stepsMapper = (stepId: MultipleStepsIds): MultipleSubStepsIds => {
  const multipleStepsMap: Record<MultipleStepsIds, MultipleSubStepsIds> = {
    [SubSteps.MULTIPLE_FIRST]: {
      multipleStepChildComponent: (onConfirm) => (
        <MultipleInsurancesFirstStep onConfirm={onConfirm} />
      ),
      //Commercial:
      selectCommercialCompanyStepId: SubSteps.SELECT_COMMERCIAL_COMPANY_FIRST,
      enterCommercialOtherCompanyStepId:
        SubSteps.ENTER_OTHER_COMMERCIAL_COMPANY_FIRST,
      provideCommercialInsuranceStepId:
        SubSteps.PROVIDE_COMMERCIAL_INSURANCE_FIRST,
      //Government:
      selectGovernmentCompanyStepId: SubSteps.SELECT_GOVERNMENT_COMPANY_FIRST,
      enterGovernmentOtherCompanyStepId:
        SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY_FIRST,
      provideGovernmentInsuranceStepId:
        SubSteps.PROVIDE_GOVERNMENT_INSURANCE_FIRST,
    },
    [SubSteps.MULTIPLE_SECOND]: {
      multipleStepChildComponent: (onConfirm) => (
        <MultipleInsurancesSecondStep onConfirm={onConfirm} />
      ),
      //Commercial:
      selectCommercialCompanyStepId: SubSteps.SELECT_COMMERCIAL_COMPANY_SECOND,
      enterCommercialOtherCompanyStepId:
        SubSteps.ENTER_OTHER_COMMERCIAL_COMPANY_SECOND,
      provideCommercialInsuranceStepId:
        SubSteps.PROVIDE_COMMERCIAL_INSURANCE_SECOND,
      //Government:
      selectGovernmentCompanyStepId: SubSteps.SELECT_GOVERNMENT_COMPANY_SECOND,
      enterGovernmentOtherCompanyStepId:
        SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY_SECOND,
      provideGovernmentInsuranceStepId:
        SubSteps.PROVIDE_GOVERNMENT_INSURANCE_SECOND,
    },
  };

  return multipleStepsMap[stepId];
};
