import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "@app/routing";
import PersonalInformationPage from "./personalInformationPage";
import UpdatePersonalInformation from "./updatePersonalInformation";
import "./personalInformation.scss";

const PersonalInformation: FC = () => {
  return (
    <Switch>
      <Route
        path={routes.updatePersonalInformation}
        component={UpdatePersonalInformation}
      />
      <Route path="/" component={PersonalInformationPage} />
    </Switch>
  );
};

export default PersonalInformation;
