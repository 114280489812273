import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, ReactElement, useContext, useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Link } from "react-router-dom";

import { Form, Button } from "@natera/platform/lib/components/form";
import { Tooltip } from "@natera/material/lib/tooltip";
import { Textfield } from "@natera/platform/lib/components/form/field";
import { ErrorContext, ResetPasswordContext } from "@app/provider";
import { FormField } from "@app/components";
import { routes } from "@app/routing";

const messages = defineMessages({
  resendPasswordFormEmailAddress: {
    id: "resendPasswordFormEmailAddress",
    defaultMessage: "Email Address",
  },
  resendPasswordFormEmailAddressPlaceholder: {
    id: "resendPasswordFormEmailAddressPlaceholder",
    defaultMessage: "Enter your email address",
  },
  resendPasswordFormResetPassword: {
    id: "resendPasswordFormResetPassword",
    defaultMessage: "Reset Password",
  },
  resendPasswordFormBackToLogIn: {
    id: "resendPasswordFormBackToLogIn",
    defaultMessage: "Back to Log In",
  },
  resendPasswordFormEmailFormat: {
    id: "resendPasswordFormEmailFormat",
    defaultMessage: "email format (username@domain)",
  },
});

export const emailValidationFieldName = "email";

interface ResetPasswordFormProps {
  onSubmit: (email: string) => void;
  onChangeEmail: (value: string) => void;
  helpText?: React.ReactNode;
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  onSubmit,
  onChangeEmail,
  helpText,
}) => {
  const { resetPasswordIsLoading } = useContext(ResetPasswordContext);
  const { getValidationError } = useContext(ErrorContext);
  const intl = useIntl();
  const [emailTooltipIsOpened, setEmailTooltipIsOpened] = useState(false);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const formData = e.currentTarget;
    const email = formData.email.value;

    onSubmit(email);
  };

  const emailAddressFormFieldElement: ReactElement = (
    <FormField
      className="email-field"
      label={intl.formatMessage(messages.resendPasswordFormEmailAddress)}
      required={true}
      error={getValidationError(emailValidationFieldName) || Boolean(helpText)}
      helpText={helpText}
      htmlFor="email"
    >
      <Tooltip
        content={intl.formatMessage(messages.resendPasswordFormEmailFormat)}
        opened={emailTooltipIsOpened}
        position={{ xPos: 2, yPos: 1 }}
        id="reset_email-tooltip-id"
      >
        <div id="reset_email-textfield">
          <HeapPHIDecorator protectAttr={["value"]} noWrap>
            <Textfield
              outline
              type="email"
              id="email"
              name="email"
              required
              onChange={(e) => onChangeEmail(e.target.value)}
              onFocus={() => setEmailTooltipIsOpened(true)}
              onBlur={() => setEmailTooltipIsOpened(false)}
              placeholder={intl.formatMessage(
                messages.resendPasswordFormEmailAddressPlaceholder
              )}
              autoComplete="email"
            />
          </HeapPHIDecorator>
        </div>
      </Tooltip>
    </FormField>
  );

  return (
    <Form onSubmit={handleSubmit} noValidate>
      {emailAddressFormFieldElement}
      <Button type="submit" raised={true} loading={resetPasswordIsLoading}>
        {intl.formatMessage(messages.resendPasswordFormResetPassword)}
      </Button>
      <Link to={routes.signIn}>
        <Button outlined={true}>
          {intl.formatMessage(messages.resendPasswordFormBackToLogIn)}
        </Button>
      </Link>
    </Form>
  );
};

export default ResetPasswordForm;
