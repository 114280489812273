import React, { FC, useContext, useMemo } from "react";
import classNames from "classnames";
import { APIContext } from "@app/neva/provider";
import {
  AvatarLogo,
  WelcomeMessage,
  TextMessage,
  ImageMessage,
  QuickReply,
} from "@app/neva/components/message";
import { EventDataMessageType, MessageTypes } from "@app/neva/models";
import "./messageBody.scss";

interface MessageTypesProps {
  message: EventDataMessageType;
  isShowAvatar: boolean;
  isLastMessage: boolean;
  scrollToBottom: () => void;
}

interface MessageTypesAliasKeys {
  [key: string]: FC<MessageTypesProps>;
}

const MessageType = {
  [MessageTypes.INTRO]: WelcomeMessage,
  [MessageTypes.TEXT]: TextMessage,
  [MessageTypes.ATTACHMENT]: ImageMessage,
} as MessageTypesAliasKeys;

interface Props {
  message: EventDataMessageType;
  messageIndex: number;
  isShowLogo: boolean;
  isLastMessage: boolean;
  scrollToBottom: () => void;
}

const MessageBody: FC<Props> = ({
  message,
  messageIndex,
  isShowLogo,
  isLastMessage,
  scrollToBottom,
}) => {
  const { apiProviderState } = useContext(APIContext);
  const isShowAvatar = useMemo(
    () => (message.type === MessageTypes.INTRO ? false : isShowLogo),
    [isShowLogo, message.type]
  );
  const CurrentMessage = MessageType[message.type];
  const messageBodyClassNames = classNames("message-body__container", {
    "message-body__without-logo": !isShowAvatar,
    "send-by-user": message.isSendByUser,
    "message-body__welcome-message": message.type === MessageTypes.INTRO,
    "first-message": messageIndex === 0,
  });

  return (
    <div className={messageBodyClassNames}>
      {isShowAvatar && <AvatarLogo logoSrc={apiProviderState.avatarLogo} />}
      <CurrentMessage
        message={message}
        isShowAvatar={isShowAvatar}
        isLastMessage={isLastMessage}
        scrollToBottom={scrollToBottom}
      />
      {isLastMessage && message.quickReplies && (
        <QuickReply replies={message.quickReplies} />
      )}
    </div>
  );
};

export default MessageBody;
