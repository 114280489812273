import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, useEffect, useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import { PhoneField as BasePhoneField } from "@natera/platform/lib/components/form/field";
import Tooltip from "@natera/material/lib/tooltip";
import { GetValidationError } from "@natera/platform/lib/hooks/useError";
import { FormField } from "@app/components";

const messages = defineMessages({
  phoneFieldTitle: {
    id: "phoneFieldTitle",
    defaultMessage: "Mobile Number",
  },
  phoneFieldTooltip: {
    id: "phoneFieldTooltip",
    defaultMessage: "10 or 11 digits number",
  },
  phoneFieldPlaceholder: {
    id: "phoneFieldPlaceholder",
    defaultMessage: "(xxx) xxx-xxxx",
  },
});

interface PhoneFieldProps {
  currentPhone?: string;
  getValidationError: GetValidationError;
  withPadding?: boolean;
  required?: boolean;
}

const PhoneField: FC<PhoneFieldProps> = ({
  currentPhone,
  getValidationError,
  withPadding = false,
  required = true,
}) => {
  const intl = useIntl();
  const [mobileNumberField, setMobileNumberField] = useState("");

  useEffect(() => {
    currentPhone && setMobileNumberField(currentPhone);
  }, [currentPhone]);

  const handleMobileNumberFieldChange = (phone: string) =>
    setMobileNumberField(phone);

  return (
    <FormField
      label={intl.formatMessage(messages.phoneFieldTitle)}
      required={required}
      error={getValidationError("mobileNumber")}
      htmlFor="mobile"
      withPadding={withPadding}
    >
      <Tooltip content={intl.formatMessage(messages.phoneFieldTooltip)}>
        <div>
          <HeapPHIDecorator protectAttr={["value"]} noWrap>
            <BasePhoneField
              id="mobile"
              name="mobileNumber"
              data-testid="mobileNumberId"
              placeholder={intl.formatMessage(messages.phoneFieldPlaceholder)}
              value={mobileNumberField}
              onPhoneChange={handleMobileNumberFieldChange}
              autoComplete="tel"
              required
              outline
            />
          </HeapPHIDecorator>
        </div>
      </Tooltip>
    </FormField>
  );
};

export default PhoneField;
