import { EnrollProfile, FACTORS_TO_SHOW } from "@app/service/user";
import { maskEmail, maskPhone } from "@app/utils/index";

export const getProfileCredentials = (
  type: FACTORS_TO_SHOW,
  profile?: EnrollProfile,
  phoneNumber?: string
): string => {
  switch (type) {
    case FACTORS_TO_SHOW.email:
      return maskEmail(profile?.email);
    case FACTORS_TO_SHOW.call:
    case FACTORS_TO_SHOW.sms:
    default:
      return maskPhone(phoneNumber || profile?.phoneNumber);
  }
};
