import { EventDataMessageType } from "@app/neva/models";

export const checkShowLogo = (
  messages: EventDataMessageType[],
  index: number
): boolean => {
  return (
    Boolean(!messages[index].isSendByUser) &&
    (messages[index + 1]
      ? Boolean(!messages[index].isSendByUser) &&
        Boolean(messages[index + 1].isSendByUser)
      : messages.length === index + 1 && Boolean(!messages[index].isSendByUser))
  );
};
