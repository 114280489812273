import React, { useContext } from "react";
import { Step, Stepper, StepperContext } from "@natera/stepper";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import PaymentMethodStep from "@app/components/simpleOrder/payment/paymentMethodStep";
import PaymentStepperController from "./paymentStepperController";
import {
  PaymentMethodType,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import { Steps } from "@app/pages/private/simpleOrder";

import "./paymentMethod.scss";

export enum SubSteps {
  SELECT_METHOD = "SELECT_METHOD",
  SELECT_COMMERCIAL_COMPANY = "SELECT_COMMERCIAL_COMPANY",
  ENTER_OTHER_COMMERCIAL_COMPANY = "ENTER_OTHER_COMMERCIAL_COMPANY",
  PROVIDE_COMMERCIAL_INSURANCE = "PROVIDE_COMMERCIAL_INSURANCE",
  SELECT_GOVERNMENT_COMPANY = "SELECT_GOVERNMENT_COMPANY",
  ENTER_OTHER_GOVERNMENT_COMPANY = "ENTER_OTHER_GOVERNMENT_COMPANY",
  PROVIDE_GOVERNMENT_INSURANCE = "PROVIDE_GOVERNMENT_INSURANCE",
  COST_ESTIMATION = "COST_ESTIMATION",
  DO_NOT_HAVE = "DO_NOT_HAVE",
  SELF_PAY = "SELF_PAY",

  // Multiple insurances:
  MULTIPLE_FIRST = "MULTIPLE_FIRST",
  MULTIPLE_SECOND = "MULTIPLE_SECOND",
  // Commercial
  SELECT_COMMERCIAL_COMPANY_FIRST = "SELECT_COMMERCIAL_COMPANY_FIRST",
  SELECT_COMMERCIAL_COMPANY_SECOND = "SELECT_COMMERCIAL_COMPANY_SECOND",
  ENTER_OTHER_COMMERCIAL_COMPANY_FIRST = "ENTER_OTHER_COMMERCIAL_COMPANY_FIRST",
  ENTER_OTHER_COMMERCIAL_COMPANY_SECOND = "ENTER_OTHER_COMMERCIAL_COMPANY_SECOND",
  PROVIDE_COMMERCIAL_INSURANCE_FIRST = "PROVIDE_COMMERCIAL_INSURANCE_FIRST",
  PROVIDE_COMMERCIAL_INSURANCE_SECOND = "PROVIDE_COMMERCIAL_INSURANCE_SECOND",
  // Government
  SELECT_GOVERNMENT_COMPANY_FIRST = "SELECT_GOVERNMENT_COMPANY_FIRST",
  SELECT_GOVERNMENT_COMPANY_SECOND = "SELECT_GOVERNMENT_COMPANY_SECOND",
  ENTER_OTHER_GOVERNMENT_COMPANY_FIRST = "ENTER_OTHER_GOVERNMENT_COMPANY_FIRST",
  ENTER_OTHER_GOVERNMENT_COMPANY_SECOND = "ENTER_OTHER_GOVERNMENT_COMPANY_SECOND",
  PROVIDE_GOVERNMENT_INSURANCE_FIRST = "PROVIDE_GOVERNMENT_INSURANCE_FIRST",
  PROVIDE_GOVERNMENT_INSURANCE_SECOND = "PROVIDE_GOVERNMENT_INSURANCE_SECOND",
}

export enum PaymentType {
  COMMERCIAL = "COMMERCIAL",
  GOVERNMENT_ISSUED = "GOVERNMENT_ISSUED",
  MULTIPLE = "MULTIPLE",
  DO_NOT_HAVE = "DO_NOT_HAVE",
  SELF_PAY = "SELF_PAY",
}

export enum PaymentTypeForHeap {
  COMMERCIAL = "commercial_insurance",
  GOVERNMENT = "government_insurance",
  TWO_INSURANCES = "two_insurances",
  NO_INSURANCE = "no_insurance",
  DONT_USE_INSURANCE = "dont_use_insurance",
}

export type PaymentInfo =
  | Government
  | Commercial
  | MultipleInsuranceData
  | DoNotHaveInsurance
  | SelfPay;
export type PaymentMethodData = PaymentInfo & { firstStep?: boolean };

type GovernmentInfo = {
  companyName: string;
  insuranceNumber: string;
  companyGroupId?: string;
};

export type Government = {
  paymentMethod: PaymentType.GOVERNMENT_ISSUED;
  paymentMethodInfo: GovernmentInfo;
};

type CommercialInfo = {
  companyName: string;
  memberId: string;
  companyGroupId?: string;
};

export type Commercial = {
  paymentMethod: PaymentType.COMMERCIAL;
  paymentMethodInfo: CommercialInfo;
};

type SelfPay = {
  paymentMethod: PaymentType.SELF_PAY;
};

export type MultipleStepType = Government | Commercial;

export type MultipleInfo = {
  first: MultipleStepType;
  second: MultipleStepType;
};

export type MultipleInsuranceData = {
  paymentMethod: PaymentType.MULTIPLE;
  paymentMethodInfo: MultipleInfo;
};

export type DoNotHaveInsurance = {
  paymentMethod: PaymentType.DO_NOT_HAVE;
};

const PaymentMethod: React.FC = () => {
  const {
    goBack,
    getCurrentStep,
    getData,
    resolve,
    updateStep,
  } = React.useContext(StepperContext);

  const { getSubStepData } = useContext(SimpleOrderStepperContext);

  const currentStepId = getCurrentStep()?.stepId;
  const stepData = currentStepId && getData(currentStepId);
  const subStepsData = getSubStepData(
    Steps.PAYMENT_METHOD
  ) as PaymentMethodType;

  const paymentMethod = subStepsData?.paymentMethod;

  const onSubmit = (data: unknown) => {
    resolve(data);
  };

  const onBack = () => {
    if (currentStepId) updateStep(currentStepId, { data: { firstStep: true } });
    goBack();
  };

  return (
    <>
      <StepperHeaderProgress />
      <div className="stepper__container">
        <Stepper
          initialStep={SubSteps.SELECT_METHOD}
          progressStepper
          className="sub-stepper"
        >
          <PaymentStepperController value={stepData} onConfirm={onSubmit}>
            <>
              <Step
                stepId={SubSteps.SELECT_METHOD}
                data={{ paymentMethod }}
                index={0}
              >
                <PaymentMethodStep onSubmit={onSubmit} goBack={onBack} />
              </Step>
            </>
          </PaymentStepperController>
        </Stepper>
      </div>
    </>
  );
};

export default PaymentMethod;
