import React, { FC } from "react";
import { Button, Form } from "@natera/platform/lib/components/form";
import { defineMessages, useIntl } from "react-intl";
import { PhoneField } from "@app/components";
import {
  useErrorController,
  usePhoneValidation,
} from "@natera/platform/lib/hooks";
import { Notification } from "@app/components";
import MailIcon from "@assets/svg/icons/mail.svg";
import { getPhoneValidationErrorMessage } from "@app/utils/getPhoneValidationErrorMessage";
import { convertPhoneNumberToE164 } from "@app/utils/convertPhoneNumber";

import "./updatePhoneForm.scss";
import { UserContext } from "@app/provider";

const messages = defineMessages({
  updatePhoneFormCancelButton: {
    id: "updatePhoneCancelButton",
    defaultMessage: "Cancel",
  },
  updatePhoneFormUpdateButton: {
    id: "updatePhoneFormUpdateButton",
    defaultMessage: "Update",
  },
  updatePhoneFormNote: {
    id: "updatePhoneFormNote",
    defaultMessage:
      "A verification code will be sent to this mobile phone number",
  },
});

interface Props {
  currentPhone?: string;
  handleSubmit: (newMobileNumber: string) => void;
  handleCancel: () => void;
}

const UpdatePhoneForm: FC<Props> = ({
  currentPhone,
  handleSubmit,
  handleCancel,
}) => {
  const intl = useIntl();

  const {
    clearErrors,
    getValidationError,
    setValidationError,
  } = useErrorController();
  const phoneValidation = usePhoneValidation();
  const { validatePhoneNumber: validateFromPS } = React.useContext(UserContext);

  const validatePhone = async (mobileNumber: string) => {
    const phoneValidationErrorMessage = await getPhoneValidationErrorMessage({
      isMandatory: true,
      mobileNumber,
      intl,
      phoneValidation,
      validateFromPS,
    });

    if (phoneValidationErrorMessage) {
      setValidationError("mobileNumber", phoneValidationErrorMessage);
      return false;
    }

    return true;
  };

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearErrors();

    const newMobileNumber = e.currentTarget.mobileNumber?.value || "";

    const isValidatePhone = await validatePhone(newMobileNumber);
    if (!isValidatePhone) {
      return;
    }

    handleSubmit(convertPhoneNumberToE164(newMobileNumber));
  };

  return (
    <>
      <Form
        className="update-phone-form"
        noValidate
        onSubmit={handleSubmitForm}
        data-testid="update-phone-form"
      >
        <PhoneField
          currentPhone={currentPhone}
          getValidationError={getValidationError}
        />
        <Notification
          className="update-phone-notification"
          icon={MailIcon}
          type="note"
        >
          {intl.formatMessage(messages.updatePhoneFormNote)}
        </Notification>
        <div className="buttons-container">
          <Button onClick={handleCancel} outlined>
            {intl.formatMessage(messages.updatePhoneFormCancelButton)}
          </Button>
          <Button type="submit" raised>
            {intl.formatMessage(messages.updatePhoneFormUpdateButton)}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UpdatePhoneForm;
