import React from "react";
import { defineMessages, useIntl } from "react-intl";

import { HeapPHIDecorator } from "@app/components/analytics";
import { ShippingAddress } from "@app/provider/types";

import "./appointmentAddress.scss";
import { getAddressAsString } from "@app/utils";

const messages = defineMessages({
  appointmentAddressHeader: {
    id: "appointmentAddressHeader",
    defaultMessage: "Address",
  },
});

type Props = {
  shippingAddress?: ShippingAddress;
};

const AppointmentAddress: React.FC<Props> = ({ shippingAddress }) => {
  const intl = useIntl();

  return (
    <div className="appointment-address">
      <h4>{intl.formatMessage(messages.appointmentAddressHeader)}</h4>
      <p>
        {shippingAddress?.street && (
          <>
            <HeapPHIDecorator protectText inline>
              <span>{shippingAddress?.street}</span>
            </HeapPHIDecorator>
            <br />
          </>
        )}
        {(shippingAddress?.city ||
          shippingAddress?.state ||
          shippingAddress?.zipCode) && (
          <>
            <HeapPHIDecorator protectText inline>
              <span>{getAddressAsString(shippingAddress)}</span>
            </HeapPHIDecorator>
          </>
        )}
      </p>
    </div>
  );
};

export default AppointmentAddress;
