import { ProsperaOrganType, TestType } from "@app/provider/testData/types";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  testCardDetailsLinkAriaLabelHorizon: {
    id: "testCardDetailsLinkAriaLabelHorizon",
    defaultMessage: "Horizon test details",
  },
  testCardDetailsLinkAriaLabelPanorama: {
    id: "testCardDetailsLinkAriaLabelPanorama",
    defaultMessage: "Panorama test details",
  },
  testCardDetailsLinkAriaLabelEmpower: {
    id: "testCardDetailsLinkAriaLabelEmpower",
    defaultMessage: "Empower test details",
  },
  testCardDetailsLinkAriaLabelVistara: {
    id: "testCardDetailsLinkAriaLabelVistara",
    defaultMessage: "Vistara test details",
  },
  testCardDetailsLinkAriaLabelRenasight: {
    id: "testCardDetailsLinkAriaLabelRenasight",
    defaultMessage: "Renasight test details",
  },
  testCardDetailsLinkAriaLabelProspera: {
    id: "testCardDetailsLinkAriaLabelProspera",
    defaultMessage: "Prospera test details",
  },
  testCardDetailsLinkAriaLabelProsperaHeart: {
    id: "testCardDetailsLinkAriaLabelProsperaHeart",
    defaultMessage: "Prospera for heart test details",
  },
  testCardDetailsLinkAriaLabelProsperaKidney: {
    id: "testCardDetailsLinkAriaLabelProsperaKidney",
    defaultMessage: "Prospera for kidney test details",
  },
  testCardDetailsLinkAriaLabelProsperaLung: {
    id: "testCardDetailsLinkAriaLabelProsperaLung",
    defaultMessage: "Prospera for lung test details",
  },
});

const getProsperaTestCardLinkAriaLabel = (
  organType: ProsperaOrganType | undefined,
  intl: IntlShape
): string => {
  switch (organType) {
    case ProsperaOrganType.HEART:
      return intl.formatMessage(
        messages.testCardDetailsLinkAriaLabelProsperaHeart
      );
    case ProsperaOrganType.KIDNEY:
      return intl.formatMessage(
        messages.testCardDetailsLinkAriaLabelProsperaKidney
      );
    case ProsperaOrganType.LUNG:
      return intl.formatMessage(
        messages.testCardDetailsLinkAriaLabelProsperaLung
      );
    default:
      return intl.formatMessage(messages.testCardDetailsLinkAriaLabelProspera);
  }
};

export const getTestCardLinkAriaLabel = (
  testType: TestType,
  organType: ProsperaOrganType | undefined,
  intl: IntlShape
): string | undefined => {
  switch (testType) {
    case TestType.HORIZON:
      return intl.formatMessage(messages.testCardDetailsLinkAriaLabelHorizon);
    case TestType.PANORAMA:
      return intl.formatMessage(messages.testCardDetailsLinkAriaLabelPanorama);
    case TestType.EMPOWER:
      return intl.formatMessage(messages.testCardDetailsLinkAriaLabelEmpower);
    case TestType.VISTARA:
      return intl.formatMessage(messages.testCardDetailsLinkAriaLabelVistara);
    case TestType.RENASIGHT:
      return intl.formatMessage(messages.testCardDetailsLinkAriaLabelRenasight);
    case TestType.PROSPERA:
      return getProsperaTestCardLinkAriaLabel(organType, intl);
    default:
      return undefined;
  }
};
