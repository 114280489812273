import React, { FC } from "react";
import BubbleTail from "@app/neva/components/bubbleTail";
import "./typing.scss";

const Typing: FC = () => {
  return (
    <div className="typing__container">
      <BubbleTail isSendByUser={false} isShowAvatar={true} />
      <div className="typing__dots">
        <div className="typing__dot first" />
        <div className="typing__dot second" />
        <div className="typing__dot third" />
      </div>
    </div>
  );
};

export default Typing;
