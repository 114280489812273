import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, ReactElement, useContext } from "react";
import { useIntl, defineMessages } from "react-intl";
import { FormField, PhoneField } from "@app/components";
import { Form, Button } from "@natera/platform/lib/components/form";
import { Textfield } from "@natera/platform/lib/components/form/field";
import {
  SetValidationError,
  ClearErrors,
  ClearValidationError,
  GetValidationError,
} from "@natera/platform/lib/hooks/useError";
import { usePhoneValidation } from "@natera/platform/lib/hooks";
import { Profile } from "@app/provider/profile";
import { ConfigContext } from "@app/provider";

const messages = defineMessages({
  confirmationFormFirstName: {
    id: "confirmationFormFirstName",
    defaultMessage: "First Name",
  },
  confirmationFormMiddleName: {
    id: "confirmationFormMiddleName",
    defaultMessage: "Middle Name / Initial",
  },
  confirmationFormLastName: {
    id: "confirmationFormLastName",
    defaultMessage: "Last Name",
  },
  confirmationFormDateOfBirth: {
    id: "confirmationFormDateOfBirth",
    defaultMessage: "Date of Birth",
  },
  confirmationFormMobileNumber: {
    id: "confirmationFormMobileNumber",
    defaultMessage: "Mobile Number",
  },
  confirmationFormZipCode: {
    id: "confirmationFormZipCode",
    defaultMessage: "Zip Code",
  },
  confirmationFormFirstNamePlaceholder: {
    id: "confirmationFormFirstNamePlaceholder",
    defaultMessage: "Enter Your First Name",
  },
  confirmationFormMiddleNamePlaceholder: {
    id: "confirmationFormMiddleNamePlaceholder",
    defaultMessage: "Enter Your Middle Name",
  },
  confirmationFormLastNamePlaceholder: {
    id: "confirmationFormLastNamePlaceholder",
    defaultMessage: "Enter Your Last Name",
  },
  confirmationFormDateOfBirthPlaceholder: {
    id: "confirmationFormDateOfBirthPlaceholder",
    defaultMessage: "Enter your Date of Birth",
  },
  confirmationFormMobileNumberPlaceholder: {
    id: "confirmationFormMobileNumberPlaceholder",
    defaultMessage: "Mobile Number",
  },
  confirmationFormZipCodePlaceholder: {
    id: "confirmationFormZipCodePlaceholder",
    defaultMessage: "Enter Your Zip Code",
  },
  confirmationFormConfirm: {
    id: "confirmationFormConfirm",
    defaultMessage: "Confirm",
  },
  confirmationFormInvalidFirstName: {
    id: "confirmationFormInvalidFirstName",
    defaultMessage: "Invalid First Name",
  },
  confirmationFormInvalidLastName: {
    id: "confirmationFormInvalidLastName",
    defaultMessage: "Invalid Last Name",
  },
  confirmationFormInvalidDateOfBirth: {
    id: "confirmationFormInvalidDateOfBirth",
    defaultMessage: "Invalid Date of Birth",
  },
  confirmationFormInvalidPhoneNumber: {
    id: "confirmationFormInvalidPhoneNumber",
    defaultMessage: "Please enter a valid phone number.",
  },
  confirmationFormUSPhoneNumber: {
    id: "confirmationFormUSPhoneNumber",
    defaultMessage: "You must enter a US based number to create an account",
  },
});

interface ConfirmationFormProps {
  isLoading: boolean;
  profile: Profile;
  onSubmit: (profileData: Profile) => void;
  setValidationError: SetValidationError;
  getValidationError: GetValidationError;
  clearValidationError: ClearValidationError;
  clearErrors: ClearErrors;
  setPhone: (phone: string) => void;
}

const ConfirmationForm: FC<ConfirmationFormProps> = ({
  isLoading,
  profile,
  clearErrors,
  onSubmit,
  setValidationError,
  getValidationError,
  setPhone,
}) => {
  const intl = useIntl();
  const { config } = useContext(ConfigContext);

  const DEFAULT_PHONE_NUMBER_LENGTH = config.DEFAULT_PHONE_NUMBER_LENGTH;

  const { validatePhone } = usePhoneValidation();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    clearErrors();

    const formData = e.currentTarget;

    const firstName = formData.firstName?.value.trim();
    const middleName = formData.middleName?.value.trim();
    const lastName = formData.lastName?.value.trim();
    const mobileNumber = formData.mobileNumber?.value;

    setPhone(mobileNumber);

    if (!firstName) {
      setValidationError(
        "firstName",
        intl.formatMessage(messages.confirmationFormInvalidFirstName)
      );

      return;
    }

    if (!lastName) {
      setValidationError(
        "lastName",
        intl.formatMessage(messages.confirmationFormInvalidLastName)
      );

      return;
    }

    if (mobileNumber.length < DEFAULT_PHONE_NUMBER_LENGTH) {
      setValidationError(
        "mobileNumber",
        intl.formatMessage(messages.confirmationFormInvalidPhoneNumber)
      );

      return;
    }

    if (!mobileNumber.startsWith("1")) {
      setValidationError(
        "mobileNumber",
        intl.formatMessage(messages.confirmationFormUSPhoneNumber)
      );

      return;
    }

    if (!validatePhone(mobileNumber, "US")) {
      setValidationError(
        "mobileNumber",
        intl.formatMessage(messages.confirmationFormInvalidPhoneNumber)
      );

      return;
    }

    onSubmit({ firstName, middleName, lastName, phone: mobileNumber });
  };

  const firstNameFieldElement: ReactElement = (
    <FormField
      label={intl.formatMessage(messages.confirmationFormFirstName)}
      required
      error={getValidationError("firstName")}
      htmlFor="firstName"
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <Textfield
          placeholder={intl.formatMessage(
            messages.confirmationFormFirstNamePlaceholder
          )}
          id="firstName"
          name="firstName"
          outline
          required
          defaultValue={profile?.firstName}
          maxLength={255}
        />
      </HeapPHIDecorator>
    </FormField>
  );

  const middleNameFieldElement: ReactElement = (
    <FormField
      label={intl.formatMessage(messages.confirmationFormMiddleName)}
      error={getValidationError("middleName")}
      htmlFor="middleName"
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <Textfield
          placeholder={intl.formatMessage(
            messages.confirmationFormMiddleNamePlaceholder
          )}
          id="middleName"
          name="middleName"
          outline
          maxLength={255}
        />
      </HeapPHIDecorator>
    </FormField>
  );

  const lastNameFieldElement: ReactElement = (
    <FormField
      label={intl.formatMessage(messages.confirmationFormLastName)}
      required
      error={getValidationError("lastName")}
      disabled
      htmlFor="lastName"
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <Textfield
          placeholder={intl.formatMessage(
            messages.confirmationFormLastNamePlaceholder
          )}
          id="lastName"
          name="lastName"
          outline
          required
          defaultValue={profile?.lastName}
          maxLength={255}
        />
      </HeapPHIDecorator>
    </FormField>
  );

  return (
    <Form
      className="setup-profile-form__container"
      noValidate
      onSubmit={handleSubmit}
    >
      {firstNameFieldElement}
      {middleNameFieldElement}
      {lastNameFieldElement}
      <PhoneField
        currentPhone={profile?.phone}
        getValidationError={getValidationError}
      />
      <Button type="submit" raised loading={isLoading}>
        {intl.formatMessage(messages.confirmationFormConfirm)}
      </Button>
    </Form>
  );
};

export default ConfirmationForm;
