import { DocumentNode, gql } from "@apollo/client";

class LanguageService {
  public static QUERY_WRAPPER_NAME = {
    PATIENT: "GetLanguageQuery",
  };

  public static getLanguages = (): DocumentNode => gql`
    query languages {
      languages {
        code
        description
      }
    }
  `;
}

export default LanguageService;
