import React from "react";
import { Profile } from "@app/provider";

export const getProfileFullName = (profile?: Profile) => {
  const fullName = [profile?.firstName, profile?.middleName, profile?.lastName]
    .filter(Boolean)
    .join(" ");
  return fullName.trim() || <>&mdash;</>;
};

export const getFormattedDOB = (dateOfBirth?: string): string =>
  dateOfBirth
    ? new Date(dateOfBirth).toLocaleDateString("en-US", {
        timeZone: "UTC",
      })
    : "";
