import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ConfigContext, TestHistoryContext } from "@app/provider";
import { useIsMobileDimension } from "@app/hooks";
import { SpinnerView } from "@app/components";
import ListItem from "@app/pages/private/scheduleAGeneticInformationSession/listItem";
import OncologySvg from "@assets/svg/icons/oncology-icon.svg";
import OHSvg from "@assets/svg/icons/OH-icon.svg";
import WHSvg from "@assets/svg/icons/WH-icon.svg";
import Image from "@assets/images/scheduleAGis_image.png";
import MobileImage from "@assets/images/scheduleAGis_image-mobile.png";
import "./scheduleAGeneticInformationPage.scss";
import { titles } from "@app/components/common/testTitle";

const messages = defineMessages({
  scheduleAGeneticInformationTitle: {
    id: "scheduleAGeneticInformationTitle",
    defaultMessage: "Schedule a Genetic Information Session",
  },
  scheduleAGeneticInformationDescription: {
    id: "scheduleAGeneticInformationDescription",
    defaultMessage: "Choose the test for which you want to schedule a session.",
  },
  scheduleAGeneticInformationOncologyDescription: {
    id: "scheduleAGeneticInformationOncologyDescription",
    defaultMessage:
      "Schedule a session for {boldSignatera} (circulating tumor DNA test), {boldAltera} (tumor genetic profiling), or {boldEmpower} (hereditary cancer screening ordered by a medical oncologist).",
  },
  scheduleAGeneticInformationOHDecription: {
    id: "scheduleAGeneticInformationOHDecription",
    defaultMessage:
      "Schedule a session for {boldRenasight} (kidney gene panel).",
  },
  scheduleAGeneticInformationWHDecription: {
    id: "scheduleAGeneticInformationWHDecription",
    defaultMessage:
      "Schedule a session for {boldPanorama} (NIPT), {boldHorizon} (carrier screening), {boldEmpower} (hereditary cancer screening ordered through your OBGYN), and other reproductive health tests.",
  },
  scheduleAGeneticInformationImage: {
    id: "scheduleAGeneticInformationImage",
    defaultMessage: "Schedule a Genetic Information Session image",
  },
  scheduleAGeneticInformationInfoHeader: {
    id: "scheduleAGeneticInformationInfoHeader",
    defaultMessage: "What to expect",
  },
  scheduleAGeneticInformationInfoText: {
    id: "scheduleAGeneticInformationInfoText",
    defaultMessage:
      "Genetic Information Sessions are pre-test and post-test patient sessions to discuss Natera tests and results. For questions beyond the specific details of Natera's tests and test results, please reach out to your healthcare provider who may consider referring you to a genetic counselor or clinical geneticist for comprehensive genetic counseling.",
  },
});

const ScheduleAGeneticInformationSession: FC = () => {
  const intl = useIntl();
  const { config } = React.useContext(ConfigContext);
  const { isLoading } = useContext(TestHistoryContext);
  const isMobile = useIsMobileDimension();

  return (
    <div className="schedule-a-gis">
      {!isMobile && <SpinnerView isLoading={isLoading} />}
      <section className="schedule-a-gis__navigation">
        <div className="schedule-a-gis__navigation__header">
          <h1>
            {intl.formatMessage(messages.scheduleAGeneticInformationTitle)}
          </h1>
          <p>
            {intl.formatMessage(
              messages.scheduleAGeneticInformationDescription
            )}
          </p>
        </div>
        <ul className="schedule-a-gis__navigation__list">
          <ListItem
            icon={OncologySvg}
            title={intl.formatMessage(titles.commonOncologyTitle)}
            target="_blank"
            rel="noopener noreferrer"
            to={{ pathname: config.links.SCHEDULE_SESSION_ONCOLOGY }}
          >
            {intl.formatMessage(
              messages.scheduleAGeneticInformationOncologyDescription,
              {
                boldSignatera: (
                  <b>{intl.formatMessage(titles.testSignateraTitle)}</b>
                ),
                boldAltera: <b>{intl.formatMessage(titles.testAlteraTitle)}</b>,
                boldEmpower: (
                  <b>{intl.formatMessage(titles.testEmpowerTitle)}</b>
                ),
              }
            )}
          </ListItem>
          <ListItem
            icon={OHSvg}
            title={intl.formatMessage(titles.commonOHTitle)}
            target="_blank"
            rel="noopener noreferrer"
            to={{
              pathname: `${config.links.PATIENT_PORTAL_LINK}${config.links.SCHEDULE_SESSION}`,
            }}
          >
            {intl.formatMessage(
              messages.scheduleAGeneticInformationOHDecription,
              {
                boldRenasight: (
                  <b>{intl.formatMessage(titles.testRenasightTitle)}</b>
                ),
              }
            )}
          </ListItem>
          <ListItem
            icon={WHSvg}
            title={intl.formatMessage(titles.commonWHTitle)}
            target="_blank"
            rel="noopener noreferrer"
            to={{
              pathname: `${config.links.PATIENT_PORTAL_LINK}${config.links.SCHEDULE_SESSION}`,
            }}
          >
            {intl.formatMessage(
              messages.scheduleAGeneticInformationWHDecription,
              {
                boldPanorama: (
                  <b>{intl.formatMessage(titles.testPanoramaTitle)}</b>
                ),
                boldHorizon: (
                  <b>{intl.formatMessage(titles.testHorizonTitle)}</b>
                ),
                boldEmpower: (
                  <b>{intl.formatMessage(titles.testEmpowerTitle)}</b>
                ),
              }
            )}
          </ListItem>
        </ul>
      </section>
      <section className="schedule-a-gis__info">
        <img
          src={isMobile ? MobileImage : Image}
          alt={intl.formatMessage(messages.scheduleAGeneticInformationImage)}
          className="schedule-a-gis__info__image"
        />
        <div className="schedule-a-gis__info__header">
          <h2>
            {intl.formatMessage(messages.scheduleAGeneticInformationInfoHeader)}
          </h2>
          <p>
            {intl.formatMessage(messages.scheduleAGeneticInformationInfoText)}
          </p>
        </div>
      </section>
    </div>
  );
};

export default ScheduleAGeneticInformationSession;
