import React, { FC, useContext, useState } from "react";
import { DrawRequestContext } from "@app/provider";
import { LoadingContext } from "@natera/platform/lib/components/context";
import { AppointmentDetails } from "@app/components";
import ModifyAppointment from "@app/components/sampleDraw/mobilePhlebotomy/appointmentTimeStep/modifyAppointment";

import "./viewBloodDrawDetails.scss";

type ViewBloodDrawDetailsProps = {
  token: string;
};

const ViewBloodDrawDetails: FC<ViewBloodDrawDetailsProps> = ({ token }) => {
  const {
    verifiedSampleDrawData,
    getDrawRequestDetails,
    isLoading,
  } = useContext(DrawRequestContext);

  const [modifyAppointment, setModifyAppointment] = useState<boolean>(false);

  const orderUid = verifiedSampleDrawData?.sampleInfo.sample?.orderUid;
  const testUid = verifiedSampleDrawData?.sampleInfo.sample?.testUid;
  const testType = verifiedSampleDrawData?.sampleInfo.sample?.testType;
  const testCardStatus =
    verifiedSampleDrawData?.sampleInfo.sample?.testCardStatus;

  const handleModifyAppointment = () => setModifyAppointment(true);
  const handleBackToTimeConfirmation = () => setModifyAppointment(false);

  React.useEffect(() => {
    if (orderUid && testUid) {
      getDrawRequestDetails(orderUid, testUid, token);
    }
  }, []);

  return (
    <LoadingContext isLoading={isLoading}>
      <div className="guest-view-blood-draw">
        <div className="guest-view-blood-draw__content">
          {modifyAppointment ? (
            <ModifyAppointment
              handleBackToTimeConfirmation={handleBackToTimeConfirmation}
              orderUid={orderUid}
              testUid={testUid}
              token={token}
              testType={testType}
            />
          ) : (
            <>
              <AppointmentDetails
                handleModifyAppointment={handleModifyAppointment}
                testCardStatus={testCardStatus}
                hideCancelButton={true}
              />
            </>
          )}
        </div>
      </div>
    </LoadingContext>
  );
};

export default ViewBloodDrawDetails;
