import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, ReactElement, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { AppUser } from "@natera/platform/lib/provider/user";
import { Svg } from "@natera/material/lib/svg";
import { Button } from "@natera/platform/lib/components/form";
import {
  ServiceContext,
  NotificationContext,
  UserContext,
  SignUpContext,
  IntlContext,
} from "@app/provider";
import { routes } from "@app/routing";
import useSendActivation from "@app/hooks/useSendActivation";

import AccountCreatedSvg from "@assets/svg/account-created.svg";
import "./activation.scss";
import { SpinnerView } from "@app/components";

const messages = defineMessages({
  activationConfirmNewAccount: {
    id: "activationConfirmNewAccount",
    defaultMessage: "Please confirm your new account via email",
  },
  activationWeSentYouEmail: {
    id: "activationWeSentYouEmail",
    defaultMessage: "We’ve sent you an activation email to",
  },
  activationNext: {
    id: "activationNext",
    defaultMessage:
      "Click on the link we emailed you to activate your account.",
  },
  activationResend: {
    id: "activationResend",
    defaultMessage: "Resend Activation Email",
  },
  activationDone: {
    id: "activationDone",
    defaultMessage: "Done",
  },
  activationTimeoutError: {
    id: "activationTimeoutError",
    defaultMessage: "You can send once a {timeout} seconds",
  },
  activationPending: {
    id: "activationPending",
    defaultMessage: "Activation Pending",
  },
  activationPendingText: {
    id: "activationPendingText",
    defaultMessage:
      "You are required to activate your account. Please check your email or request for a new activation email.",
  },
});

const Activation: FC = () => {
  const intl = useIntl();

  const history = useHistory<{
    email: string;
    password: string;
    currentUser?: AppUser;
  }>();

  const activationController = useSendActivation();
  const { signUpByPasswordData } = useContext(SignUpContext);
  const { sessionService } = useContext(ServiceContext);
  const { addNotification, clear } = useContext(NotificationContext);
  const { loadProfile } = useContext(UserContext);
  const { currentLanguage } = useContext(IntlContext);

  if (!signUpByPasswordData && !history.location.state?.currentUser) {
    history.replace(routes.root);
  }

  const handleActivation = () => {
    if (signUpByPasswordData?.email) {
      activationController.handleActivation(
        signUpByPasswordData.email,
        currentLanguage
      );
    }
  };

  const handleDone = async () => {
    if (history.location.state.email && history.location.state.password) {
      try {
        if (await sessionService.getToken()) {
          await sessionService.clearToken();
        }

        await sessionService.login(history.location.state);
        await loadProfile();
      } catch (e) {
        clear();
        addNotification({
          title: intl.formatMessage(messages.activationPending),
          message: intl.formatMessage(messages.activationPendingText),
          type: "error",
        });
      }
    } else {
      history.replace(routes.signIn);
    }
  };

  const title: ReactElement = (
    <section className="title__container">
      <h1>{intl.formatMessage(messages.activationConfirmNewAccount)}</h1>
    </section>
  );

  const text: ReactElement = (
    <section className="activation__text">
      <p>{intl.formatMessage(messages.activationWeSentYouEmail)}</p>
      <p className="activation__text__email">
        <HeapPHIDecorator protectText inline>
          <b>{signUpByPasswordData?.email}</b>
        </HeapPHIDecorator>
      </p>
      <p>{intl.formatMessage(messages.activationNext)}</p>
    </section>
  );

  const actions: ReactElement = (
    <section className="actions__container">
      <Button
        outlined={true}
        disabled={activationController.resendIsDisabled}
        onClick={handleActivation}
      >
        {intl.formatMessage(messages.activationResend)}
      </Button>
      <div className="whitespace" />
      <Button raised={true} onClick={handleDone}>
        {intl.formatMessage(messages.activationDone)}
      </Button>
    </section>
  );

  return (
    <article className="activation__container">
      {title}
      <Svg content={AccountCreatedSvg} />
      {text}
      {actions}
      <SpinnerView isLoading={activationController.isLoading} />
    </article>
  );
};
export default Activation;
