import { useContext, useEffect, useState } from "react";
import { logMsg } from "../helpers";
import { ConfigContext } from "@app/provider";
import { datadogRum } from "@datadog/browser-rum";

export type UseLoaderTimerController = {
  startLoaderTimer: () => void;
  nevaOnline: () => void;
};

const DEFAULT_TIME = 1000 * 10;
let BOT_OFFLINE_TIMER: NodeJS.Timeout | undefined;

export const useLoaderTimer = (
  callback?: () => void
): UseLoaderTimerController => {
  const {
    config: {
      nevaConfig: { widgetLogsEnabled, timeout },
    },
  } = useContext(ConfigContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const timeUntilNevaOffline = timeout ?? DEFAULT_TIME;

  const startLoaderTimer = () => {
    BOT_OFFLINE_TIMER = setTimeout(nevaOffline, timeUntilNevaOffline);
  };

  const nevaOnline = () => {
    setIsLoaded(true);
    logMsg(widgetLogsEnabled, "NEVA: online");
    if (BOT_OFFLINE_TIMER) {
      clearTimeout(BOT_OFFLINE_TIMER);
    }
    if (document.body.classList.contains("bot-loading")) {
      document.body.classList.remove("bot-loading");
    }
    if (document.body.classList.contains("bot-offline")) {
      document.body.classList.remove("bot-offline");
    }
  };

  const nevaOffline = () => {
    logMsg(widgetLogsEnabled, "NEVA: offline");
    if (document.body.classList.contains("bot-loading")) {
      document.body.classList.remove("bot-loading");
    }
    document.body.classList.add("bot-offline");
    if (!isLoaded) {
      logMsg(widgetLogsEnabled, "nevaTimeout");
      // send datadog RUM error
      datadogRum.addError(new Error("Neva loading timed out"), {
        timeout: timeout,
      });
      callback?.();
    }
  };

  useEffect(() => {
    return () => {
      if (BOT_OFFLINE_TIMER) {
        clearTimeout(BOT_OFFLINE_TIMER);
      }
    };
  }, []);

  return {
    startLoaderTimer,
    nevaOnline,
  };
};
