import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { PartnerInfoData } from "@app/provider/order";
import { Form, Button } from "@natera/platform/lib/components/form";
import { HeapPHIDecorator } from "@app/components/analytics";
import {
  useDialog,
  useErrorController,
  usePhoneValidation,
} from "@natera/platform/lib/hooks";
import { isPatientMinor, validateEmail, weekDayFormatter } from "@app/utils";
import {
  FormField,
  Textfield,
  DateInput,
} from "@natera/platform/lib/components/form/field";
import { PhoneField } from "@app/components";
import PartnerInfoProvidingDialog from "@app/components/partnerInfo/partnerInfoProvidingDialog";
import CalendarIcon from "@assets/svg/icons/calendar.svg";
import Tooltip from "@natera/material/lib/tooltip";
import { getPhoneValidationErrorMessage } from "@app/utils/getPhoneValidationErrorMessage";
import { ConfigContext, UserContext } from "@app/provider";

const messages = defineMessages({
  partnerInfoNext: {
    id: "partnerInfoNext",
    defaultMessage: "Next",
  },
  partnerInfoBack: {
    id: "partnerInfoBack",
    defaultMessage: "Back",
  },
  partnerInfoFormFirstName: {
    id: "partnerInfoFormFirstName",
    defaultMessage: "First Name",
  },
  setupPartnerInfoFormFirstNamePlaceholder: {
    id: "setupPartnerInfoFormFirstNamePlaceholder",
    defaultMessage: "Enter Your Partner’s First Name",
  },
  partnerInfoFormMiddleName: {
    id: "partnerInfoFormMiddleName",
    defaultMessage: "Middle Name",
  },
  setupPartnerInfoFormMiddleNamePlaceholder: {
    id: "setupPartnerInfoFormMiddleNamePlaceholder",
    defaultMessage: "Enter Your Partner’s Middle Name",
  },
  partnerInfoFormLastName: {
    id: "partnerInfoFormLastName",
    defaultMessage: "Last Name",
  },
  setupPartnerInfoFormLastNamePlaceholder: {
    id: "setupPartnerInfoFormLastNamePlaceholder",
    defaultMessage: "Enter Your Partner’s Last Name",
  },
  partnerInfoFormDOB: {
    id: "partnerInfoFormDOB",
    defaultMessage: "Date of Birth",
  },
  setupPartnerInfoFormDOBPlaceholder: {
    id: "setupPartnerInfoFormDOBPlaceholder",
    defaultMessage: "MM/DD/YYYY",
  },
  partnerInfoFormEmail: {
    id: "partnerInfoFormEmail",
    defaultMessage: "Email",
  },
  setupPartnerInfoFormEmaiPlaceholder: {
    id: "setupPartnerInfoFormEmaiPlaceholder",
    defaultMessage: "Enter Your Partner’s Email",
  },
  partnerInfoFormUnder18DateOfBirth: {
    id: "partnerInfoFormUnder18DateOfBirth",
    defaultMessage: "Your partner must be 18 years old or older",
  },
  partnerInfoFormInvalidName: {
    id: "partnerInfoFormInvalidName",
    defaultMessage: "Please enter a valid name",
  },
  partnerInfoFormInvalidLastName: {
    id: "partnerInfoFormInvalidLastName",
    defaultMessage: "Please enter a valid last name",
  },
  partnerInfoFormInvalidEmail: {
    id: "partnerInfoFormInvalidEmail",
    defaultMessage: "Please enter a valid email address. (username@domain)",
  },
  partnerInfoFormEmailFormat: {
    id: "partnerInfoFormEmailFormat",
    defaultMessage: "email format (username@domain)",
  },
});

export interface PartnerInfoFormProps {
  initialPartnerInfo?: PartnerInfoData;
  onSubmit: (partner: PartnerInfoData) => Promise<void>;
  onBack: () => void;
}

const PartnerInfoForm: FC<PartnerInfoFormProps> = ({
  initialPartnerInfo,
  onSubmit,
  onBack,
}) => {
  const intl = useIntl();
  const { config } = React.useContext(ConfigContext);
  const usePlusSignInEmailAddress =
    config.test.usePlusSignInEmailAddress.enabled;

  const errorController = useErrorController();
  const phoneValidation = usePhoneValidation();
  const { validatePhoneNumber: validateFromPS } = React.useContext(UserContext);
  const partnerInfoProvidingDialog = useDialog(PartnerInfoProvidingDialog);

  const containsInvalidNameFields = (
    firstName: string | undefined,
    middleName: string | undefined,
    lastName: string | undefined
  ) => {
    if (firstName && firstName !== firstName.trim()) {
      errorController.setValidationError(
        "firstName",
        intl.formatMessage(messages.partnerInfoFormInvalidName)
      );
      return true;
    }

    if (middleName && middleName !== middleName.trim()) {
      errorController.setValidationError(
        "middleName",
        intl.formatMessage(messages.partnerInfoFormInvalidName)
      );
      return true;
    }

    if (lastName && lastName !== lastName.trim()) {
      errorController.setValidationError(
        "lastName",
        intl.formatMessage(messages.partnerInfoFormInvalidLastName)
      );
      return true;
    }

    return false;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    errorController.clearErrors();

    const formData = new FormData(e.currentTarget);

    const firstName = formData.get("firstName")?.toString();
    const middleName = formData.get("middleName")?.toString();
    const lastName = formData.get("lastName")?.toString();
    const dateOfBirth = formData.get("dateOfBirth")?.toString().trim();
    const mobileNumber = formData.get("mobileNumber")?.toString().trim();
    const email = formData.get("email")?.toString().trim();

    const requiredFields = [firstName, lastName, dateOfBirth, mobileNumber];

    const submitData = () =>
      onSubmit({
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        email,
        phone: mobileNumber,
      });

    if (containsInvalidNameFields(firstName, middleName, lastName)) {
      return;
    }

    if (dateOfBirth && isPatientMinor(new Date(dateOfBirth))) {
      errorController.setValidationError(
        "dateOfBirth",
        intl.formatMessage(messages.partnerInfoFormUnder18DateOfBirth)
      );

      return;
    }

    if (mobileNumber) {
      const phoneValidationErrorMessage = await getPhoneValidationErrorMessage({
        isMandatory: false,
        mobileNumber,
        intl,
        phoneValidation,
        validateFromPS,
      });

      if (phoneValidationErrorMessage) {
        errorController.setValidationError(
          "mobileNumber",
          phoneValidationErrorMessage
        );
        return;
      }
    }

    if (email && !validateEmail(email, usePlusSignInEmailAddress)) {
      errorController.setValidationError(
        "email",
        intl.formatMessage(messages.partnerInfoFormInvalidEmail)
      );
      return;
    }

    for (const field of requiredFields) {
      if (!field) {
        partnerInfoProvidingDialog.open({
          onClose: () => partnerInfoProvidingDialog.close(),
          onContinue: submitData,
        });
        return;
      }
    }

    submitData();
  };

  return (
    <>
      {partnerInfoProvidingDialog.getDialog()}
      <Form
        className="partner-info-form__container"
        noValidate
        onSubmit={handleSubmit}
      >
        <FormField
          label={intl.formatMessage(messages.partnerInfoFormFirstName)}
          error={errorController.getValidationError("firstName")}
          htmlFor="firstName"
        >
          <HeapPHIDecorator protectAttr={["value"]}>
            <Textfield
              placeholder={intl.formatMessage(
                messages.setupPartnerInfoFormFirstNamePlaceholder
              )}
              id="firstName"
              name="firstName"
              defaultValue={initialPartnerInfo?.firstName}
              outline
              maxLength={255}
              autoComplete="given-name"
            />
          </HeapPHIDecorator>
        </FormField>
        <FormField
          label={intl.formatMessage(messages.partnerInfoFormMiddleName)}
          error={errorController.getValidationError("middleName")}
          htmlFor="middleName"
        >
          <HeapPHIDecorator protectAttr={["value"]}>
            <Textfield
              placeholder={intl.formatMessage(
                messages.setupPartnerInfoFormMiddleNamePlaceholder
              )}
              id="middleName"
              name="middleName"
              defaultValue={initialPartnerInfo?.middleName}
              outline
              maxLength={255}
              autoComplete="additional-name"
            />
          </HeapPHIDecorator>
        </FormField>
        <FormField
          label={intl.formatMessage(messages.partnerInfoFormLastName)}
          error={errorController.getValidationError("lastName")}
          htmlFor="lastName"
        >
          <HeapPHIDecorator protectAttr={["value"]}>
            <Textfield
              placeholder={intl.formatMessage(
                messages.setupPartnerInfoFormLastNamePlaceholder
              )}
              id="lastName"
              name="lastName"
              defaultValue={initialPartnerInfo?.lastName}
              outline
              maxLength={255}
              autoComplete="family-name"
            />
          </HeapPHIDecorator>
        </FormField>
        <FormField
          label={intl.formatMessage(messages.partnerInfoFormDOB)}
          error={errorController.getValidationError("dateOfBirth")}
          htmlFor="date"
        >
          <HeapPHIDecorator protectAttr={["value"]}>
            <DateInput
              id="date"
              name="dateOfBirth"
              maxDate={new Date()}
              defaultDate={
                initialPartnerInfo?.dateOfBirth
                  ? new Date(initialPartnerInfo.dateOfBirth)
                  : undefined
              }
              onDateChange={() =>
                errorController.clearValidationError("dateOfBirth")
              }
              placeholder={intl.formatMessage(
                messages.setupPartnerInfoFormDOBPlaceholder
              )}
              outline
              trailingIcon={CalendarIcon}
              autoComplete="bday"
              formatWeekdayName={weekDayFormatter}
            />
          </HeapPHIDecorator>
        </FormField>
        <PhoneField
          currentPhone={initialPartnerInfo?.phone}
          getValidationError={errorController.getValidationError}
          required={false}
        />
        <FormField
          label={intl.formatMessage(messages.partnerInfoFormEmail)}
          error={errorController.getValidationError("email")}
          htmlFor="email"
          className="partner-info-email"
        >
          <Tooltip
            content={intl.formatMessage(messages.partnerInfoFormEmailFormat)}
          >
            <Textfield
              id="email"
              name="email"
              type="email"
              placeholder={intl.formatMessage(
                messages.setupPartnerInfoFormEmaiPlaceholder
              )}
              defaultValue={initialPartnerInfo?.email}
              autoComplete="email"
              outline
            />
          </Tooltip>
        </FormField>
        <div className="stepper__actions">
          <Button type="submit" data-testid="submit" raised>
            {intl.formatMessage(messages.partnerInfoNext)}
          </Button>
          <Button onClick={onBack}>
            {intl.formatMessage(messages.partnerInfoBack)}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default PartnerInfoForm;
