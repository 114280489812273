import React, { FC, useContext, useState } from "react";
import { StepperContext } from "@natera/stepper";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { defineMessages, useIntl } from "react-intl";
import {
  RadioButton,
  RadioGroup,
} from "@natera/platform/lib/components/form/field";
import { Button } from "@natera/platform/lib/components/form";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { HEAP_EVENTS } from "@app/provider/types";
import { getTestNameForHeap } from "@app/utils";
import { OrderContext } from "@app/provider/order";
import { Steps } from "@app/pages/private/simpleOrder";
import {
  FetalSexStepResolve,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import "./fetalSexInResults.scss";

const messages = defineMessages({
  fetalSexInResultsTitle: {
    id: "fetalSexInResultsTitle",
    defaultMessage: "Fetal Sex In Results Selection",
  },
  fetalSexInResultsSubTitle: {
    id: "fetalSexInResultsSubTitle",
    defaultMessage: "Would you like to see your results with fetal sex?",
  },
  fetalSexInResultsText: {
    id: "fetalSexInResultsText",
    defaultMessage:
      "Please select whether you would like to include the fetal sex of your baby in your results.",
  },
  fetalSexInResultsInclude: {
    id: "fetalSexInResultsInclude",
    defaultMessage: "Yes, include fetal sex in my results",
  },
  fetalSexInResultsNotInclude: {
    id: "fetalSexInResultsNotInclude",
    defaultMessage: "No, do not include fetal sex in my results",
  },
  fetalSexInResultsNext: {
    id: "fetalSexInResultsNext",
    defaultMessage: "Next",
  },
  fetalSexInResultsBack: {
    id: "fetalSexInResultsBack",
    defaultMessage: "Back",
  },
});

export enum FetalSexSelection {
  INCLUDE = "INCLUDE",
  DO_NOT_INCLUDE = "DO_NOT_INCLUDE",
}
const FetalSex: FC = () => {
  const intl = useIntl();
  const { getCurrentStep, getData, goBack, resolve } = React.useContext(
    StepperContext
  );
  const { getStepData, resolveStep } = useContext(SimpleOrderStepperContext);

  const currentStepId = getCurrentStep()?.stepId;
  const stepData = currentStepId && getData(currentStepId);

  const simpleOrderStepData = getStepData(
    Steps.FETAL_SEX
  ) as FetalSexStepResolve;

  const initialReportGenderValue =
    stepData?.reportGender ?? simpleOrderStepData?.reportGender;

  const [reportGender, setReportGender] = React.useState<FetalSexSelection>(
    initialReportGenderValue
  );
  const [nextButtonIsDisabled, setNextButtonIsDisabled] = useState(
    !initialReportGenderValue
  );

  const { orderData } = React.useContext(OrderContext);
  const orderUid = orderData?.uid;
  const limsId = orderData?.clinic.limsId;

  const handleSelectionChange = (includeFetalSex: FetalSexSelection) => {
    setNextButtonIsDisabled(false);
    setReportGender(includeFetalSex);
  };
  const handleSubmit = () => {
    resolveStep({ stepId: Steps.FETAL_SEX, stepData: { reportGender } });
    resolve({
      reportGender,
    });
    heap.track(HEAP_EVENTS.upp_simpleorder_fetalsex, {
      tests_ordered: getTestNameForHeap(orderData),
      lims_clinic_id: limsId,
      order_uid: orderUid,
      includefetalsex:
        reportGender === FetalSexSelection.INCLUDE ? "yes" : "no",
    });
  };

  const handleBack = () => {
    goBack();
  };

  return (
    <>
      <StepperHeaderProgress />
      <StepperContent
        title={intl.formatMessage(messages.fetalSexInResultsTitle)}
        subTitle={intl.formatMessage(messages.fetalSexInResultsSubTitle)}
        description={intl.formatMessage(messages.fetalSexInResultsText)}
      >
        <div className="sample-draw-preference-list">
          <RadioGroup
            name="reportGender"
            value={reportGender}
            onChange={handleSelectionChange}
          >
            <RadioButton
              className="stepper__item-button"
              name="radio-button"
              label={
                <div className="stepper__item-title">
                  {intl.formatMessage(messages.fetalSexInResultsInclude)}
                </div>
              }
              value={FetalSexSelection.INCLUDE}
            />
            <RadioButton
              className="stepper__item-button"
              name="radio-button"
              label={
                <div className="stepper__item-title">
                  {intl.formatMessage(messages.fetalSexInResultsNotInclude)}
                </div>
              }
              value={FetalSexSelection.DO_NOT_INCLUDE}
            />
          </RadioGroup>
        </div>
      </StepperContent>
      <div className="stepper__actions">
        <Button raised onClick={handleSubmit} disabled={nextButtonIsDisabled}>
          {intl.formatMessage(messages.fetalSexInResultsNext)}
        </Button>
        <Button onClick={handleBack}>
          {intl.formatMessage(messages.fetalSexInResultsBack)}
        </Button>
      </div>
    </>
  );
};

export default FetalSex;
