import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/platform/lib/components/form";
import { ResultCodes } from "@app/service/resultCodes";

import "./welcomeBackDialog.scss";

const messages = defineMessages({
  welcomeBackDialogButton: {
    id: "welcomeBackDialogButton",
    defaultMessage: "Got it",
  },
  welcomeBackDialogInfoGoogle: {
    id: "welcomeBackDialogInfoGoogle",
    defaultMessage:
      'You previously logged in with the "Continue with Google" option using your Google username and password',
  },
  welcomeBackDialogInfoApple: {
    id: "welcomeBackDialogInfoApple",
    defaultMessage:
      'You previously logged in with the "Continue with Apple" option using your Apple username and password',
  },
});

export interface WelcomeBackDialogProps {
  idpType:
    | ResultCodes.USER_REGISTERED_VIA_GOOGLE
    | ResultCodes.USER_REGISTERED_VIA_APPLE;
  onCloseDialog: () => void;
}

const WelcomeBackDialog: FC<WelcomeBackDialogProps> = ({
  idpType,
  onCloseDialog,
}) => {
  const intl = useIntl();

  return (
    <div className="welcome-back-dialog">
      <Dialog
        title={
          <div className="welcome-back-dialog__title">
            {idpType === ResultCodes.USER_REGISTERED_VIA_GOOGLE
              ? intl.formatMessage(messages.welcomeBackDialogInfoGoogle)
              : intl.formatMessage(messages.welcomeBackDialogInfoApple)}
          </div>
        }
        showCrossButton
        actions={
          <Button raised={true} onClick={onCloseDialog}>
            {intl.formatMessage(messages.welcomeBackDialogButton)}
          </Button>
        }
        onClose={onCloseDialog}
      >
        <></>
      </Dialog>
    </div>
  );
};

export default WelcomeBackDialog;
