import React, { FC, useContext, useEffect, useMemo } from "react";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import { routes } from "@app/routing";
import { ResultCodes } from "@app/service/resultCodes";
import ResetPassword from "./resetPassword";
import ResetPasswordError from "./resetPasswordError";
import SignUp from "./signUp";
import SignIn from "./signIn";
import Activation from "./activation";
import Main from "./main";
import {
  IntlContext,
  NotificationContext,
  RedirectionContext,
  RoutingType,
} from "@app/provider";
import SetPassword from "./setPassword";
import sendEmail from "./sendEmail";
import Unlock from "./unlock";
import UpdateEmailError from "./updateEmailError";
import ScheduleBloodDraw from "./scheduleBloodDraw";
import { ResendActivationLink } from "./resendActivationLink";
import acknowledgements from "@etc/acknowledgements.json";
import { useTokenizedLinks } from "@app/hooks";
import { LinkHeap } from "@app/components";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";

const messages = defineMessages({
  publicTokenIsInvalid: {
    id: "publicTokenIsInvalid",
    defaultMessage: "This link is broken.",
  },
  publicTokenIsExpired: {
    id: "publicTokenIsExpired",
    defaultMessage: "This link has expired.",
  },
  publicAccountAlreadyActivated: {
    id: "publicAccountAlreadyActivated",
    defaultMessage: "Your account has already been created.",
  },
  publicAccountSuccessfullyActivated: {
    id: "publicAccountSuccessfullyActivated",
    defaultMessage: "Account was successfully activated",
  },
  publicAccountDeleted: {
    id: "publicAccountDeleted",
    defaultMessage: "Your account has been deleted.",
  },
  publicUpdateEmailNotificationTitle: {
    id: "publicUpdateEmailNotificationTitle",
    defaultMessage: "Email Verified",
  },
  publicUpdateEmailNotification: {
    id: "publicUpdateEmailNotification",
    defaultMessage:
      "Email successfully updated. Please log in with your updated email address.",
  },
  publicSignOutlNotificationTitle: {
    id: "publicSignOutlNotificationTitle",
    defaultMessage: "Sign Out",
  },
  publicSignOutlNotification: {
    id: "publicSignOutlNotification",
    defaultMessage: "You have been successfully signed out",
  },
  publicResetPasswordNotificationTitle: {
    id: "publicResetPasswordNotificationTitle",
    defaultMessage: "Password Updated",
  },
  publicResetPasswordNotification: {
    id: "publicResetPasswordNotification",
    defaultMessage:
      "Your password has been updated.\nRemember to save your new password.",
  },
  publicContactSupport: {
    id: "publicContactSupport",
    defaultMessage: "Contact support",
  },
  publicActivationResend: {
    id: "publicActivationResend",
    defaultMessage: "Get a new activation link",
  },
  publicLogin: {
    id: "publicLogin",
    defaultMessage: "Log in",
  },
  publicOr: {
    id: "publicOr",
    defaultMessage: " or ",
  },
});

export const PUSH_ACTION = "PUSH";

const PublicRouting: FC = () => {
  const history = useHistory();
  const { search } = useLocation();

  const intl = useIntl();
  useTokenizedLinks();

  const { notifications, addNotification, clear } = useContext(
    NotificationContext
  );
  const { currentLanguage } = useContext(IntlContext);

  const { setRedirection, redirectionTrigger } = useContext(RedirectionContext);

  useEffect(() => {
    setRedirection(RoutingType.PUBLIC);
  }, []);

  useEffect(() => {
    if (redirectionTrigger === "email_verified") {
      addNotification({
        title: intl.formatMessage(messages.publicUpdateEmailNotificationTitle),
        message: intl.formatMessage(messages.publicUpdateEmailNotification),
        type: "success",
      });
    }
  }, [redirectionTrigger]);

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    return history.listen((location, action) => {
      if (action === PUSH_ACTION) {
        clear();
      }
    });
  }, [history]);

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      clear();
      switch (code) {
        case ResultCodes.TOKEN_EXPIRED_ERROR: {
          addNotification({
            message: intl.formatMessage(messages.publicTokenIsExpired),
            actions: (
              <a href={routes.resendActivationLink}>
                {intl.formatMessage(messages.publicActivationResend)}
              </a>
            ),
            type: "error",
          });
          break;
        }
        case ResultCodes.TOKEN_INVALID_SIGNATURE_ERROR: {
          addNotification({
            message: intl.formatMessage(messages.publicTokenIsInvalid),
            actions: (
              <>
                <Link to={routes.signIn}>
                  {intl.formatMessage(messages.publicLogin)}
                </Link>
                {intl.formatMessage(messages.publicOr)}
                <LinkHeap
                  target="_blank"
                  key="privacyPolicy"
                  rel="noreferrer"
                  to={{ pathname: acknowledgements.links.contactUs }}
                  heapEventName={HEAP_EVENTS.upp_click_contactnatera}
                  heapEventProps={{
                    location: HeapEventLocation.broken_link_error,
                  }}
                >
                  {intl.formatMessage(messages.publicContactSupport)}
                </LinkHeap>
              </>
            ),
            type: "error",
          });

          break;
        }
        case ResultCodes.ACCOUNT_ALREADY_ACTIVATED_ERROR:
        case ResultCodes.TOKEN_IS_USED: {
          addNotification({
            message: intl.formatMessage(messages.publicAccountAlreadyActivated),
            actions: (
              <LinkHeap
                target="_blank"
                key="privacyPolicy"
                rel="noreferrer"
                to={{ pathname: acknowledgements.links.contactUs }}
                heapEventName={HEAP_EVENTS.upp_click_contactnatera}
                heapEventProps={{
                  location: HeapEventLocation.account_activated_error,
                }}
              >
                {intl.formatMessage(messages.publicContactSupport)}
              </LinkHeap>
            ),
            type: "error",
          });

          break;
        }
        case ResultCodes.ACCOUNT_SUCCESSFULLY_ACTIVATED: {
          addNotification({
            message: intl.formatMessage(
              messages.publicAccountSuccessfullyActivated
            ),
            type: "success",
          });

          break;
        }
        case ResultCodes.USER_IS_DELETED: {
          addNotification({
            message: intl.formatMessage(messages.publicAccountDeleted),
            type: "warning",
            hasTimeout: true,
          });

          break;
        }
        case ResultCodes.EMAIL_VERIFIED:
          {
            addNotification({
              title: intl.formatMessage(
                messages.publicUpdateEmailNotificationTitle
              ),
              message: intl.formatMessage(
                messages.publicUpdateEmailNotification
              ),
              type: "success",
            });
          }
          break;

        case ResultCodes.PASSWORD_UPDATED:
          {
            addNotification({
              title: intl.formatMessage(
                messages.publicResetPasswordNotificationTitle
              ),
              message: (
                <span className="ws-pre-wrap">
                  {intl.formatMessage(messages.publicResetPasswordNotification)}
                </span>
              ),
              type: "success",
            });
          }
          break;

        case ResultCodes.SIGN_OUT:
          {
            addNotification({
              title: intl.formatMessage(
                messages.publicSignOutlNotificationTitle
              ),
              message: intl.formatMessage(messages.publicSignOutlNotification),
              type: "success",
            });
          }
          break;
      }
    }
  }, [searchParams, currentLanguage]);

  return (
    <Main>
      {notifications}
      <Switch>
        <Route component={SignUp} path={routes.signUpInvite} />
        <Route component={SignIn} path={routes.signInInvite} />
        <Route component={sendEmail} path={routes.sendEmail} />
        <Route component={SetPassword} path={routes.setPassword} />
        <Route component={ResetPassword} path={routes.resetPassword} />
        <Route
          component={ResetPasswordError}
          path={routes.resetPasswordError}
        />
        <Route
          component={ResendActivationLink}
          path={routes.resendActivationLink}
        />
        <Route component={Unlock} path={routes.unlock} />
        <Route component={SignUp} path={routes.signUp} />
        <Route component={Activation} path={routes.activation} />
        <Route component={SignIn} path={routes.signIn} />
        <Route component={UpdateEmailError} path={routes.updateEmailError} />
        <Route component={ScheduleBloodDraw} path={routes.scheduleBloodDraw} />
        <Route component={SignIn} path={routes.root} />
      </Switch>
    </Main>
  );
};

export default PublicRouting;
