import React, { FC } from "react";
import { Chat } from "@app/neva/components";
import "./container.scss";

const Container: FC = () => {
  return (
    <div className="widget-container" data-testid="widget-container">
      <Chat />
    </div>
  );
};

export default Container;
