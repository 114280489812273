export const validateEmail = (
  email: string,
  usePlusSignInEmailAddress?: boolean
): boolean => {
  const re = usePlusSignInEmailAddress
    ? /^\w+([.-]\w+)*(\+\w+)*@\w+([.-]\w+)*(\.\w{2,4})+$/
    : /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,4})+$/;
  return Boolean(email.match(re));
};

export default validateEmail;
