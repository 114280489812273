import { DocumentNode, gql } from "@apollo/client";

class DrawRequestService {
  public static createDrawRequest = (): DocumentNode => gql`
    mutation CreateDrawRequest(
      $orderUid: String!
      $testUid: String!
      $requestData: DrawRequestCreateInputDTO!
      $token: String
    ) {
      createDrawRequest(
        orderUid: $orderUid
        testUid: $testUid
        requestData: $requestData
        token: $token
      ) {
        success
        reqId
      }
    }
  `;

  public static updateDrawRequest = (): DocumentNode => gql`
    mutation UpdateDrawRequest(
      $testUid: String!
      $requestData: DrawRequestUpdateInputDTO!
      $token: String
    ) {
      updateDrawRequest(
        testUid: $testUid
        requestData: $requestData
        token: $token
      ) {
        success
        reqId
      }
    }
  `;

  public static getDrawRequestDetails = (): DocumentNode => gql`
    query GetDrawRequestDetails(
      $orderUid: String!
      $testUid: String!
      $token: String
    ) {
      getDrawRequestDetails(
        orderUid: $orderUid
        testUid: $testUid
        token: $token
      ) {
        clinic {
          limsId
          name
        }
        drawRequest {
          uid
          shippingAddress {
            city
            street
            state
            zipCode
          }
          jobTimezone
          jobStatus
          start1
          start2
          start3
          end1
          end2
          end3
          note
          jobStart
          jobEnd
        }
      }
    }
  `;

  public static cancelDrawRequest = (): DocumentNode => gql`
    mutation CancelDrawRequest(
      $orderUid: String!
      $testUid: String!
      $requestUid: String!
    ) {
      cancelDrawRequest(
        orderUid: $orderUid
        testUid: $testUid
        requestUid: $requestUid
      ) {
        success
      }
    }
  `;

  public static checkPhoneIsRequired = (): DocumentNode => gql`
    query CheckPhoneIsRequired($token: String!) {
      checkPhoneIsRequired(token: $token) {
        isRequired
      }
    }
  `;

  public static checkSampleDrawRequestPossibility = (): DocumentNode => gql`
    query CheckSampleDrawRequestPossibility(
      $testData: DrawRequestCheckDTO!
      $token: String
    ) {
      checkSampleDrawRequestPossibility(testData: $testData, token: $token) {
        isValid
        needsKitShippingStep
      }
    }
  `;

  public static verifySampleDrawRequestPossibilityNonAuth = (): DocumentNode => gql`
    query verifySampleDrawRequestPossibilityNonAuth(
      $token: String!
      $dateOfBirth: String!
      $phone: String
    ) {
      verifySampleDrawRequestPossibilityNonAuth(
        token: $token
        dateOfBirth: $dateOfBirth
        phone: $phone
      ) {
        profileInfo {
          isValid
          profile {
            uid
            firstName
            middleName
            lastName
            dateOfBirth
            addresses {
              uid
              countryCode
              city
              state
              street1
              street2
              zipCode
            }
          }
        }
        businessUnit
        sampleInfo {
          isDrawRequested
          sample {
            orderUid
            testUid
            testType
            creationDate
            testCardStatus
            isOncologyOrder
          }
        }
      }
    }
  `;
}

export default DrawRequestService;
