import { DocumentNode, gql } from "@apollo/client";

class BloodDrawService {
  public static getBloodDrawSites = (): DocumentNode => gql`
    query GetBloodDrawSites($bloodDrawSitesData: BloodDrawSitesData!) {
      getBloodDrawSites(bloodDrawSitesData: $bloodDrawSitesData) {
        id
        clinic_id
        name
        full_address
        distance
        phone
        zip
        site_hours {
          id
          day_of_week
          close
          open
          phlebotomy_site_id
        }
      }
    }
  `;
}

export default BloodDrawService;
