import { BusinessUnits } from "@app/provider/drawRequest";

export const businessUnitMapper = (
  businessUnit: BusinessUnits | null = null
): string | undefined => {
  const mapper = {
    [BusinessUnits.ORGAN_HEALTH]: "Organ Health",
    [BusinessUnits.ONCOLOGY]: "Oncology",
    [BusinessUnits.WOMENS_HEALTH]: "Women's Health",
  };

  if (businessUnit === null) {
    return undefined;
  }
  return mapper[businessUnit];
};
