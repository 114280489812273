import React, { FC } from "react";
import launcherIcon from "@app/neva/assets/neva_launcher.png";
import "./launcherIcon.scss";

export interface LauncherIconProps {
  handleClick: () => void;
}
const LauncherIcon: FC<LauncherIconProps> = ({ handleClick }) => {
  return (
    <button
      className="launcher_icon__button"
      data-testid="launcher-icon"
      onClick={handleClick}
      id="widget-button"
    >
      <img className="launcher_icon" src={launcherIcon} alt={"avatar"} />
    </button>
  );
};

export default LauncherIcon;
