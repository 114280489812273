import {
  MultipleInfo,
  PaymentType,
  SubSteps,
  MultipleStepType,
} from "./paymentMethod";
import { getMultipleGovernmentSteps } from "./getMultipleGovernmentSteps";
import { getMultipleCommercialSteps } from "./getMultipleCommercialSteps";
import {
  MultipleStep,
  onConfirmType,
  MultipleStepsIds,
} from "./multipleStepsMapper";

interface GetInsuranceStepsForMultipleStepProps {
  stepData: MultipleStepType;
  onConfirm: onConfirmType;
  initialIndex: number;
  stepId: MultipleStepsIds;
}

type GetMultipleSteps = (
  multipleInfo: MultipleInfo,
  onConfirm: onConfirmType
) => MultipleStep[];

type GetInsuranceStepsForMultipleStep = (
  props: GetInsuranceStepsForMultipleStepProps
) => MultipleStep[];

const getInsuranceStepsForMultipleStep: GetInsuranceStepsForMultipleStep = ({
  stepData,
  onConfirm,
  initialIndex,
  stepId,
}) => {
  if (stepData.paymentMethod === PaymentType.COMMERCIAL) {
    return getMultipleCommercialSteps(
      stepId,
      initialIndex,
      stepData,
      onConfirm
    );
  } else {
    return getMultipleGovernmentSteps(
      stepId,
      initialIndex,
      stepData,
      onConfirm
    );
  }
};

const getFirstInsuranceSteps = (
  stepData: MultipleStepType,
  onConfirm: onConfirmType
) => {
  return getInsuranceStepsForMultipleStep({
    stepData,
    onConfirm,
    initialIndex: 1,
    stepId: SubSteps.MULTIPLE_FIRST,
  });
};

const getSecondInsuranceSteps = (
  stepData: MultipleStepType,
  onConfirm: onConfirmType
) => {
  return getInsuranceStepsForMultipleStep({
    stepData,
    onConfirm,
    initialIndex: 5,
    stepId: SubSteps.MULTIPLE_SECOND,
  });
};

export const getMultipleSteps: GetMultipleSteps = (
  { first, second },
  onConfirm
) => {
  const steps: MultipleStep[] = [];

  const firstInsuranceSteps = getFirstInsuranceSteps(first, onConfirm);
  steps.push(...firstInsuranceSteps);

  const secondInsuranceSteps = getSecondInsuranceSteps(second, onConfirm);
  steps.push(...secondInsuranceSteps);

  return steps;
};
