import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { Button } from "@natera/material/lib/button";
import {
  AttributeNames,
  AttributeValues,
  ButtonTypes,
  iframeDefaultConfig,
  MessageButtonType,
} from "@app/neva/models";
import {
  generatePostbackUserEvent,
  hasAttributeValue,
} from "@app/neva/helpers";
import "./messageButton.scss";
import { EventsContext } from "@app/neva/provider";
import { useUrlActivityEventSender } from "@app/neva/hooks";
import { DialogsContext } from "@app/neva/provider";
import { ServiceContext } from "@app/provider";
import { useTranscriptFileName } from "@app/neva/hooks/useTranscriptFileName";

interface Props {
  onClick: () => void;
  button: MessageButtonType;
  isButtonDisabled: boolean;
  setIsDownloadButtonDisabled: Dispatch<SetStateAction<boolean>>;
}

const MessageButton: FC<Props> = ({
  onClick,
  button,
  isButtonDisabled,
  setIsDownloadButtonDisabled,
}) => {
  const { fileService } = useContext(ServiceContext);
  const { userEventHandler } = useContext(EventsContext);
  const { setIframeSettings } = useContext(DialogsContext);
  const { sendActivityEventFromUrl } = useUrlActivityEventSender();
  const [isLoading, setIsLoading] = useState(false);
  const { formatFileName } = useTranscriptFileName();

  const { url, payload, title, type, isWebview } = button;

  const postbackButton = type === ButtonTypes.POSTBACK;
  const dropdownButton = type === ButtonTypes.DROPDOWN;
  const ratingButton = type === ButtonTypes.RATING;

  const isPrimaryButton = useMemo(() => {
    const attributePayload =
      postbackButton || dropdownButton || ratingButton ? payload : url;
    return hasAttributeValue(
      attributePayload,
      AttributeNames.BUTTON_STYLE_TYPE,
      AttributeValues.PRIMARY
    );
  }, [url, type]);

  const enableDownloadButton = (showLoaderTimeout: NodeJS.Timeout) => {
    clearTimeout(showLoaderTimeout);
    setIsDownloadButtonDisabled(false);
    setIsLoading(false);
  };

  const onClickHandler = async () => {
    switch (type) {
      case ButtonTypes.POSTBACK:
        userEventHandler(
          generatePostbackUserEvent({
            title: button.title,
            payload: button.payload,
          })
        );
        break;
      case ButtonTypes.WEB_URL:
        sendActivityEventFromUrl(url);
        if (isWebview) {
          setIframeSettings(iframeDefaultConfig);
          setTimeout(() => {
            setIframeSettings((prevState) => ({
              ...prevState,
              isOpenIframe: true,
              url,
            }));
          }, 0);
        } else {
          window.open(url, "_blank");
        }
        break;
      case ButtonTypes.TRANSCRIPT: {
        sendActivityEventFromUrl(url);
        const formattedFileName = formatFileName();

        setIsDownloadButtonDisabled(true);
        const showLoaderTimeout = setTimeout(() => setIsLoading(true), 1000);
        fileService
          .getFile(url)
          .then((file) => {
            enableDownloadButton(showLoaderTimeout);
            fileService.download(file, formattedFileName);
          })
          .catch(() => {
            enableDownloadButton(showLoaderTimeout);
          });
        break;
      }
      case ButtonTypes.DROPDOWN:
      case ButtonTypes.RATING:
        onClick && onClick();
        break;
    }
  };

  return (
    <Button
      data-testid="message-button"
      className="widget__message-button"
      disabled={isButtonDisabled}
      unelevated={isPrimaryButton}
      outlined={!isPrimaryButton}
      onClick={onClickHandler}
      loading={isLoading}
    >
      {title}
    </Button>
  );
};

export default MessageButton;
