import React, {
  FC,
  createContext,
  useState,
  useMemo,
  useContext,
  useEffect,
} from "react";
import { WebviewType, WidgetInfoData } from "@app/neva/models";
import { IntlContext, ProfileContext } from "@app/provider";
import { useApiController } from "@app/neva/hooks";
import { CreateUserInterface } from "@app/neva/hooks/useApiController";
import { NevaContext } from "@app/neva/provider/index";

type APIProviderState = {
  chatId: number;
  widgetSettings: WidgetInfoData | null;
  avatarLogo: string;
};

export interface APIController {
  apiProviderState: APIProviderState;
  getUserChatId: (data: CreateUserInterface) => void;
  getWebViewData: (webviewId: string) => Promise<WebviewType>;
}

export const Context = createContext<APIController>({
  apiProviderState: {
    chatId: 0,
    widgetSettings: null,
    avatarLogo: "",
  },
  getUserChatId: () => undefined,
  getWebViewData: async () => Promise.reject(),
});

Context.displayName = "APIContext";

const APIProvider: FC = ({ children }) => {
  const { currentLanguage } = useContext(IntlContext);
  const { getProfileData: profile } = useContext(ProfileContext);
  const { nevaPreSessionToken, isWidgetOpen, onReady } = useContext(
    NevaContext
  );
  const [apiProviderState, setApiProviderState] = useState<APIProviderState>({
    chatId: 0,
    widgetSettings: null,
    avatarLogo: "",
  });
  const { getWidgetInfo, createUser, getWebview } = useApiController();

  useEffect(() => {
    getWidgetSettings();
  }, []);

  useEffect(() => {
    if (!apiProviderState.chatId && isWidgetOpen) {
      const userBody: CreateUserInterface = {
        nevaPreSessionToken,
        language: currentLanguage,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        heapInfo: {
          heapIoIdentity: profile?.uid ?? "",
          heapIoPrefix: "UPP_NEVA",
        },
      };
      getUserChatId(userBody);
    }
  }, [apiProviderState.chatId, isWidgetOpen]);

  const getUserChatId = (data: CreateUserInterface) => {
    createUser(data)
      .then((response) => {
        if (response.chatId) {
          setApiProviderState((prevState) => ({
            ...prevState,
            ...response,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getWidgetSettings = () => {
    getWidgetInfo()
      .then((widgetSettings) => {
        setApiProviderState((prevState) => ({
          ...prevState,
          widgetSettings,
        }));
        onReady();

        if (widgetSettings.languageConfigs) {
          return widgetSettings.languageConfigs[currentLanguage].logo;
        }
      })
      .then((logo) => {
        logo && saveAvatarImageToState(logo);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getWebViewData = (webviewId: string) => {
    return getWebview(webviewId, apiProviderState.chatId);
  };

  const saveAvatarImageToState = (logoUrl: string) => {
    if (!apiProviderState.avatarLogo) {
      fetch(logoUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new FileReader();
          file.onload = () => {
            setApiProviderState((prevState) => ({
              ...prevState,
              avatarLogo: file.result as string,
            }));
          };
          file.readAsDataURL(blob);
        })
        .catch((error) => {
          console.log("Error to fetch image", error);
        });
    }
  };

  const value = useMemo(
    () => ({
      apiProviderState,
      getUserChatId,
      getWebViewData,
    }),
    [apiProviderState]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default APIProvider;
