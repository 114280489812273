import { useEffect, useState } from "react";

const MOBILE_DIMENSION = 430;

const useIsMobileDimension = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth < MOBILE_DIMENSION
  );

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < MOBILE_DIMENSION);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return isMobile;
};

export default useIsMobileDimension;
