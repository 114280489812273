import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import DateOfBirthVerificationFailed from "@app/pages/private/dateOfBirthVerification/dateOfBirthVerificationFailed";

import "./welcomeScheduleBloodDraw.scss";

const messages = defineMessages({
  scheduleBloodDrawDobFailedTitle: {
    id: "scheduleBloodDrawDobFailed",
    defaultMessage: "We are unable{br}to verify your identity",
  },
  scheduleBloodDrawDobFailedDescription: {
    id: "scheduleBloodDrawDobFailedDescription",
    defaultMessage:
      "We are having trouble verifying your account at this time. Please contact our customer support for further assistance.",
  },
});

const WelcomeDobVerificationFailedScreen: FC = () => {
  const intl = useIntl();

  return (
    <div className="schedule-blood-draw__dob-failed">
      <DateOfBirthVerificationFailed
        title={intl.formatMessage(messages.scheduleBloodDrawDobFailedTitle, {
          br: <br />,
        })}
        description={intl.formatMessage(
          messages.scheduleBloodDrawDobFailedDescription
        )}
      />
    </div>
  );
};

export default WelcomeDobVerificationFailedScreen;
