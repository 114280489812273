import React, { FC, useContext } from "react";
import { APIContext } from "@app/neva/provider";
import { EventDataMessageType } from "@app/neva/models";
import NateraLogo from "@app/neva/assets/natera_logo.png";
import "./welcomeMessage.scss";

interface Props {
  message: EventDataMessageType;
}

const WelcomeMessage: FC<Props> = ({ message }) => {
  const { apiProviderState } = useContext(APIContext);
  const { title, text, avatarAtlText, logoAtlText } = message;

  return (
    <div className="welcome-message__container">
      <img
        className="welcome-message__avatar"
        src={apiProviderState.avatarLogo}
        alt={avatarAtlText}
      />
      <img
        className="welcome-message__natera-logo"
        src={NateraLogo}
        alt={logoAtlText}
      />
      <h2 className="welcome-message__title">{title}</h2>
      <p className="welcome-message__subtitle">{text}</p>
    </div>
  );
};

export default WelcomeMessage;
