import React, { FC } from "react";
import { Link, LinkProps } from "@natera/platform/lib/components";

export type HeapEventProps = { [key: string]: unknown };

interface LinkHeapProps extends LinkProps {
  heapEventName?: string;
  heapEventProps?: HeapEventProps;
}

const LinkHeap: FC<LinkHeapProps> = ({
  heapEventName,
  heapEventProps,
  onClick,
  children,
  ...props
}) => {
  const clickHandler: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    // Assure that the click handler is called before heap tracking - in case heap fails.
    // Keep in mind, that the timing of heap.track may be wrong.  Perhaps we should
    // capture the timestamp before calling onClick, and then send that as a Heap parameter
    // (if that capability exists)
    onClick && onClick(e);
    heapEventName && heap.track(heapEventName, heapEventProps);
  };

  return (
    <Link onClick={clickHandler} {...props}>
      {children}
    </Link>
  );
};

export default LinkHeap;
