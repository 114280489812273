import React, { FC } from "react";
import Svg from "@natera/material/lib/svg";
import CloseIcon from "@assets/svg/icons/close.svg";
import { defineMessages, useIntl } from "react-intl";

import "./highlightWrapper.scss";

const messages = defineMessages({
  highlightedTooltipTitle: {
    id: "highlightedTooltipTitle",
    defaultMessage: "Use the same login method",
  },
});

export type TooltipPosition = "bottom" | "top";

export interface CustomTooltipProps {
  onClose: () => void;
  topPosition: number;
  description: string;
  updateSize: (size: number) => void;
  tooltipPosition: TooltipPosition;
}

export const CustomTooltip: FC<CustomTooltipProps> = ({
  onClose,
  topPosition,
  description,
  updateSize,
  tooltipPosition,
}) => {
  const intl = useIntl();

  const tooltipRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (tooltipRef.current) {
      const { height } = tooltipRef.current.getBoundingClientRect();
      updateSize(height + 8); // 8 - delta
    }
  }, [updateSize]);

  return (
    <div
      ref={tooltipRef}
      className="custom-tooltip"
      style={{ top: topPosition }}
    >
      <div className="custom-tooltip__container">
        <div className="custom-tooltip__close" onClick={onClose}>
          <Svg color="black" content={CloseIcon} />
        </div>
        <div className="custom-tooltip__content">
          <div className="custom-tooltip__title">
            {intl.formatMessage(messages.highlightedTooltipTitle)}
          </div>
          <div className="custom-tooltip__description">{description}</div>
        </div>
      </div>
      <div className={`custom-tooltip__arrow ${tooltipPosition}`}></div>
    </div>
  );
};
