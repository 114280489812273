import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { StepperContext } from "@natera/stepper";

import TimeslotsForm from "@app/components/forms/timeslotsForm/timeslotsForm";
import { StepperContent } from "@app/components/ui/layout";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";

const messages = defineMessages({
  mobilePhlebotomyTimeslotsTitle: {
    id: "mobilePhlebotomyTimeslotsTitle",
    defaultMessage: "Mobile Blood Draw",
  },
  mobilePhlebotomyTimeslotsDescriptionTitle: {
    id: "mobilePhlebotomyTimeslotsDescriptionTitle",
    defaultMessage: "When are some good times for your blood draw?",
  },
  mobilePhlebotomyTimeslotsDescription: {
    id: "mobilePhlebotomyTimeslotsDescription",
    defaultMessage:
      "Please select three time slots across three different dates to ensure that we can match you with an appointment.",
  },
  mobilePhlebotomyTimeslotsNext: {
    id: "mobilePhlebotomyTimeslotsNext",
    defaultMessage: "Next",
  },
});

const Timeslots: React.FC = () => {
  const intl = useIntl();
  const { goBack, resolve } = React.useContext(StepperContext);

  return (
    <>
      <StepperHeaderProgress />
      <StepperContent
        title={intl.formatMessage(messages.mobilePhlebotomyTimeslotsTitle)}
        subTitle={intl.formatMessage(
          messages.mobilePhlebotomyTimeslotsDescriptionTitle
        )}
        description={intl.formatMessage(
          messages.mobilePhlebotomyTimeslotsDescription
        )}
      >
        <TimeslotsForm
          handleBack={goBack}
          handleConfirm={resolve}
          confirmButtonLabel={intl.formatMessage(
            messages.mobilePhlebotomyTimeslotsNext
          )}
        />
      </StepperContent>
    </>
  );
};

export default Timeslots;
