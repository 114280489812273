import React, { FC } from "react";
import { BlueHeader } from "@app/components";
import "./blueHeaderContent.scss";
import { ScrollContext } from "@natera/platform/lib/components/scrollbar";
import classNames from "classnames";

interface Props {
  blueHeaderTitle: string;
  backArrowHeaderRoute?: string;
  crossHeaderRoute?: string;
  crossHeaderButton?: () => void;
  fullScreen?: boolean;
}
const BlueHeaderContent: FC<Props> = ({
  blueHeaderTitle,
  backArrowHeaderRoute,
  crossHeaderRoute,
  crossHeaderButton,
  children,
  fullScreen = false,
}) => {
  return (
    <div
      className={classNames("blue-header-content", { fullScreen: fullScreen })}
    >
      <BlueHeader
        arrowRoute={backArrowHeaderRoute}
        crossRoute={crossHeaderRoute}
        crossButton={crossHeaderButton}
        title={blueHeaderTitle}
      />
      <div className="blue-header-content__children">
        <ScrollContext component="section" isStatic={false}>
          {children}
        </ScrollContext>
      </div>
    </div>
  );
};

export default BlueHeaderContent;
