import React, { ReactNode } from "react";

import { defineMessages, useIntl } from "react-intl";

import Email from "@assets/svg/icons/email.svg";
import Svg from "@natera/material/lib/svg";
import { SpinnerView } from "@app/components";

import "./mailSentDialog.scss";

const messages = defineMessages({
  MailSentDialogCheckMail: {
    id: "MailSentDialogCheckMail",
    defaultMessage: "Check your mail",
  },
});

export interface MailSentDialogInterface {
  description?: ReactNode;
  isLoading?: boolean;
  actions?: ReactNode;
}
const MailSentDialog: React.FC<MailSentDialogInterface> = ({
  description,
  isLoading,
  actions,
}) => {
  const intl = useIntl();

  return (
    <div className="mail-sent__container">
      <div className="image-container">
        <Svg content={Email} />
      </div>

      <div className="description-container">
        <h2>{intl.formatMessage(messages.MailSentDialogCheckMail)}</h2>
        {description}
      </div>

      <div>{actions}</div>

      <SpinnerView isLoading={!!isLoading} />
    </div>
  );
};

export default MailSentDialog;
