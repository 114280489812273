import {
  useLazyQuery,
  useMutation,
  FetchResult,
  ApolloError,
} from "@apollo/client";
import { SupplementalType } from "@app/components/supplementButton/supplementalButton";
import TestResultService from "@app/service/testResults";
import { LoadingContext } from "@natera/platform/lib/components";
import React from "react";
import { ProsperaOrganType, TestStatus, TestType } from "./testData/types";
import { BusinessUnits } from "./drawRequest";

export enum GenderOption {
  MALE = "MALE",
  FEMALE = "FEMALE",
  NOT_REPORTED = "NOT_REPORTED",
  UNKNOWN = "UNKNOWN",
  MASKED = "MASKED",
  TWO_MALES = "TWO_MALES",
  TWO_FEMALES = "TWO_FEMALES",
  A_MALE_AND_A_FEMALE = "A_MALE_AND_A_FEMALE",
}

enum ContentType {
  FILE = "FILE",
  VIDEO = "VIDEO",
  IMAGE = "IMAGE",
  RESOURCE = "RESOURCE",
}

enum DocumentType {
  CLINICAL_NOTE = "CLINICAL_NOTE",
  DEMOGRAPHICS = "DEMOGRAPHICS",
  EMAIL_COMMUNICATION = "EMAIL_COMMUNICATION",
  EMR_REQUISITION = "EMR_REQUISITION",
  GC_NOTES = "GC_NOTES",
  GC_PEDIGREE_DIAGRAM = "GC_PEDIGREE_DIAGRAM",
  INSURANCE = "INSURANCE",
  PATHOLOGY_REPORT = "PATHOLOGY_REPORT",
  PEDIGREE = "PEDIGREE",
  PROACTIVE_REQUISITION = "PROACTIVE_REQUISITION",
  QUEST_REQ_FORM = "QUEST_REQ_FORM",
  REQ_FORM = "REQ_FORM",
  SAMPLE_IMAGES = "SAMPLE_IMAGES",
  SUPPLEMENTS = "SUPPLEMENTS",
  TEST_RESULTS = "TEST_RESULTS",
  TEST_RESULTS_WITHOUT_FETAL_SEX = "TEST_RESULTS_WITHOUT_FETAL_SEX",
  TRL_FORM = "TRL_FORM",
  OTHER = "OTHER",
}

type Document = {
  uid: string;
  url?: string;
  documentName?: string;
  documentType: DocumentType;
  contentType: ContentType;
  content?: string | null;
  documentReferenceId: number;
};

export type TestResultDocument = {
  document?: Document | null;
  releasedToPatientDate?: string | null;
  releasedToProviderDate?: string | null;
  resultDate?: string | null;
  providerNotes?: string | null;
};

export type TestResult = {
  orderUid: string;
  testUid: string;
  testType: TestType;
  reportGender?: boolean;
  caPnsSupplemental?: boolean;
  resultCode?: string;
  fetalSex?: GenderOption;
  releasedToPatientDate?: string | null;
  organ?: ProsperaOrganType;
  documents?: [
    {
      uid: string;
      documentName: string;
      contentType: SupplementalType;
    }
  ];
  resultDocuments?: TestResultDocument[];
  latestResultDocumentUid?: string;
  businessUnit: BusinessUnits;
};

export type SupplementalMaterial = {
  uid: string;
  url?: string | null;
  documentName: string;
  contentType: string;
};

export interface TestResultController {
  isLoading: boolean;
  testResult?: TestResult;
  testResultInfoToken?: string;
  getTestResultInfoFromToken: (
    token: string
  ) => Promise<{ orderUid: string; testUid: string } | undefined>;
  setTestResultInfoToken: (token: string) => void;
  getTestResult: (
    orderUid: string,
    testUid: string
  ) => Promise<TestResult | undefined>;
  getSupplementalMaterials: (
    orderUid: string,
    testUid: string,
    uid: string
  ) => Promise<SupplementalMaterial | undefined>;
  getSupplementMaterialIsLoading: boolean;
  getSupplementMaterialError?: string;
  accessTestResultsError?: ApolloError;
  accessTestResultsData?: { success: boolean; orderUid: string };
  accessTestResults: (
    orderUid: string,
    testUid: string,
    viewStatus?: TestStatus
  ) => Promise<FetchResult<{ success: boolean }>>;
  supplementalMaterials?: SupplementalMaterial;
}
export const Context = React.createContext<TestResultController>({
  isLoading: false,
  testResult: undefined,
  getTestResult: () => Promise.reject(),
  getTestResultInfoFromToken: () => Promise.reject(),
  setTestResultInfoToken: () => undefined,
  getSupplementalMaterials: () => Promise.reject(),
  getSupplementMaterialIsLoading: false,
  accessTestResultsData: undefined,
  accessTestResults: async () => ({}),
  supplementalMaterials: undefined,
});

Context.displayName = "TestResultContext";

const TestResultProvider: React.FC = ({ children }) => {
  const [testResult, setTestResult] = React.useState<TestResult | undefined>();
  const [supplementalMaterials, setSupplementalMaterials] = React.useState<
    SupplementalMaterial | undefined
  >();
  const [testResultInfoToken, setTestResultInfoToken] = React.useState("");
  const [
    getTestResultQuery,
    { loading: getTestResultIsLoading },
  ] = useLazyQuery<{
    getTestResult: TestResult;
  }>(TestResultService.getTestResult());

  const [
    getTestResultInfoFromToken,
    { loading: getTestResultInfoFromTokenIsLoading },
  ] = useLazyQuery<{
    getTestResultInfoFromToken: { orderUid: string; testUid: string };
  }>(TestResultService.getTestResultInfoFromToken());

  const getTestResult = async (orderUid: string, testUid: string) => {
    const result = await getTestResultQuery({
      variables: { orderUid, testUid },
    });
    setTestResult(result.data?.getTestResult);
    return result.data?.getTestResult;
  };

  const handleGetTestResultInfoFromToken = async (token: string) => {
    const response = await getTestResultInfoFromToken({ variables: { token } });
    return response.data?.getTestResultInfoFromToken;
  };

  const handleSetTestResultInfoToken = (token: string) => {
    setTestResultInfoToken(token);
  };

  const [
    getSupplementMaterial,
    {
      loading: getSupplementMaterialIsLoading,
      error: getSupplementMaterialError,
    },
  ] = useLazyQuery<{
    getSupplementalMaterial: SupplementalMaterial;
  }>(TestResultService.getSupplementMaterial());

  const getSupplementalMaterials = async (
    orderUid: string,
    testUid: string,
    uid: string
  ) => {
    const result = await getSupplementMaterial({
      variables: { orderUid, testUid, documentUid: uid },
    });
    setSupplementalMaterials(result.data?.getSupplementalMaterial);

    return result.data?.getSupplementalMaterial;
  };

  const [
    accessTestResults,
    {
      data: accessTestResultsData,
      loading: accessTestResultsLoading,
      error: accessTestResultsError,
    },
  ] = useMutation(TestResultService.AccessTestResults());

  const handleAccessTestResults = async (
    orderUid: string,
    testUid: string,
    viewStatus?: TestStatus
  ) => {
    const response = await accessTestResults({
      variables: {
        accessTestResults: { orderUid, testUid, viewStatus },
      },
    });

    if (response && response.data) {
      return response.data.accessTestResults;
    }
    throw Error();
  };

  const isLoading =
    getTestResultIsLoading ||
    getTestResultInfoFromTokenIsLoading ||
    accessTestResultsLoading;

  return (
    <Context.Provider
      value={{
        isLoading,
        testResult,
        testResultInfoToken,
        getTestResult,
        getTestResultInfoFromToken: handleGetTestResultInfoFromToken,
        setTestResultInfoToken: handleSetTestResultInfoToken,
        getSupplementalMaterials,
        accessTestResultsData: accessTestResultsData?.accessTestResults,
        accessTestResults: handleAccessTestResults,
        getSupplementMaterialIsLoading,
        accessTestResultsError: accessTestResultsError,
        getSupplementMaterialError:
          getSupplementMaterialError?.networkError?.message ||
          getSupplementMaterialError?.graphQLErrors[0].message,
        supplementalMaterials,
      }}
    >
      <LoadingContext
        data-testid="result-details-page-loader"
        isLoading={isLoading}
      >
        {children}
      </LoadingContext>
    </Context.Provider>
  );
};

export default TestResultProvider;
