import React, { FC, useContext } from "react";
import { Dialog } from "@natera/material/lib/dialog";
import Button from "@natera/material/lib/button";
import "./changeLanguageDialog.scss";
import { APIContext, EventsContext } from "@app/neva/provider";
import { ActionTypes } from "@app/neva/models";
import { generateActionUserEvent } from "@app/neva/helpers";
import { IntlContext } from "@app/provider";

interface Props {
  onClose: () => void;
}

const ChangeLanguageDialog: FC<Props> = ({ onClose }) => {
  const { userEventHandler } = useContext(EventsContext);
  const {
    apiProviderState: { widgetSettings },
  } = useContext(APIContext);
  const { currentLanguage } = useContext(IntlContext);
  const switchLanguage =
    widgetSettings?.languageConfigs[currentLanguage].widgetPopup.switchLanguage;

  const handleStayLanguage = () => {
    userEventHandler(
      generateActionUserEvent(ActionTypes.CONTINUE_LANGUAGE_SWITCHING)
    );
    onClose();
  };

  const handleCancelSwitch = () => {
    userEventHandler(
      generateActionUserEvent(ActionTypes.CANCEL_LANGUAGE_SWITCHING)
    );
    onClose();
  };
  const actions = (
    <>
      <Button onClick={handleCancelSwitch} outlined>
        {switchLanguage?.cancelButtonText}
      </Button>
      <Button onClick={handleStayLanguage} raised>
        {switchLanguage?.continueButtonText}
      </Button>
    </>
  );

  return (
    <Dialog
      className="change-language-dialog"
      onClose={onClose}
      actions={actions}
      title={switchLanguage?.popupTitle}
      focusTrapOptions={{ skipInitialFocus: true }}
    >
      <div className="dialog-container">{switchLanguage?.popupText}</div>
    </Dialog>
  );
};

export default ChangeLanguageDialog;
