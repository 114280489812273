import { useContext, useEffect, useState } from "react";
import { PasswordComplexityKeys } from "@app/provider/passwordPolicy";
import { Language, PasswordPolicyContext } from "@app/provider";

export interface PasswordPolicyComplexityValidationStateRule {
  isValid: boolean;
  value: number | boolean;
}

export type PasswordPolicyComplexityValidationState = Record<
  PasswordComplexityKeys,
  PasswordPolicyComplexityValidationStateRule | undefined
>;

type UsePasswordPolicy = (props: {
  password: string;
  email: string;
  currentLanguage?: Language;
}) => {
  passwordPolicyComplexity: PasswordPolicyComplexityValidationState;
  invalidPasswordRequirement: string | undefined;
};

export const usePasswordPolicy: UsePasswordPolicy = ({
  password,
  email,
  currentLanguage,
}) => {
  const [
    passwordPolicyComplexityValidationState,
    setPasswordPolicyComplexityValidationState,
  ] = useState<PasswordPolicyComplexityValidationState>(
    {} as PasswordPolicyComplexityValidationState
  );

  const [invalidPasswordRequirement, setInvalidPasswordRequirement] = useState<
    string
  >();

  const { passwordPolicy, getPasswordPolicy } = useContext(
    PasswordPolicyContext
  );

  useEffect(() => {
    getPasswordPolicy();
  }, []);

  useEffect(() => {
    if (passwordPolicy) {
      const complexity = passwordPolicy.complexity;

      if (complexity) {
        setPasswordPolicyComplexityValidationState(
          Object.keys(complexity).reduce(
            (acc, key: PasswordComplexityKeys) =>
              complexity[key]
                ? {
                    ...acc,
                    [key]: {
                      isValid: false,
                      value: complexity[key],
                    },
                  }
                : acc,
            {} as PasswordPolicyComplexityValidationState
          )
        );
      }
    }
  }, [passwordPolicy]);

  useEffect(() => {
    const {
      excludeUsername,
      minLength,
      minLowerCase,
      minNumber,
      minSymbol,
      minUpperCase,
    } = passwordPolicyComplexityValidationState;

    if (minLength?.value && minLength.value > 0) {
      if (password.length >= minLength.value) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              minLength: {
                ...oldPasswordPolicyComplexity.minLength,
                isValid: true,
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
      if (password.length < minLength.value) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              ...{
                minLength: {
                  ...oldPasswordPolicyComplexity.minLength,
                  isValid: false,
                },
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
    }

    if (minUpperCase?.value && minUpperCase.value > 0) {
      const upperCaseLettersCount = password?.match(/[A-Z]/g)?.length;

      if (
        upperCaseLettersCount &&
        upperCaseLettersCount >= minUpperCase.value
      ) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              minUpperCase: {
                ...oldPasswordPolicyComplexity.minUpperCase,
                isValid: true,
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
      if (
        !upperCaseLettersCount ||
        upperCaseLettersCount < minUpperCase.value
      ) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              ...{
                minUpperCase: {
                  ...oldPasswordPolicyComplexity.minUpperCase,
                  isValid: false,
                },
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
    }

    if (minLowerCase?.value && minLowerCase.value > 0) {
      const lowerCaseLettersCount = password?.match(/[a-z]/g)?.length;

      if (
        lowerCaseLettersCount &&
        lowerCaseLettersCount >= minLowerCase.value
      ) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              minLowerCase: {
                ...oldPasswordPolicyComplexity.minLowerCase,
                isValid: true,
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
      if (
        !lowerCaseLettersCount ||
        lowerCaseLettersCount < minLowerCase.value
      ) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              ...{
                minLowerCase: {
                  ...oldPasswordPolicyComplexity.minLowerCase,
                  isValid: false,
                },
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
    }

    if (minNumber?.value && minNumber.value > 0) {
      const numbersCount = password?.match(/[0-9]/g)?.length;

      if (numbersCount && numbersCount >= minNumber.value) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              minNumber: {
                ...oldPasswordPolicyComplexity.minNumber,
                isValid: true,
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
      if (!numbersCount || numbersCount < minNumber.value) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              ...{
                minNumber: {
                  ...oldPasswordPolicyComplexity.minNumber,
                  isValid: false,
                },
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
    }

    if (excludeUsername) {
      const includesUsername = password?.includes(email);

      if (includesUsername) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              excludeUsername: {
                ...oldPasswordPolicyComplexity.excludeUsername,
                isValid: false,
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
      if (!includesUsername) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              ...{
                excludeUsername: {
                  ...oldPasswordPolicyComplexity.excludeUsername,
                  isValid: true,
                },
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
    }

    if (minSymbol?.value && minSymbol.value > 0) {
      const symbolsCount = password?.match(/[@#$]/g)?.length;

      if (symbolsCount && symbolsCount >= minSymbol.value) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              minSymbol: {
                ...oldPasswordPolicyComplexity.minSymbol,
                isValid: true,
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
      if (!symbolsCount || symbolsCount < minSymbol.value) {
        setPasswordPolicyComplexityValidationState(
          (oldPasswordPolicyComplexity) =>
            ({
              ...oldPasswordPolicyComplexity,
              ...{
                minSymbol: {
                  ...oldPasswordPolicyComplexity.minSymbol,
                  isValid: false,
                },
              },
            } as PasswordPolicyComplexityValidationState)
        );
      }
    }
  }, [password, email, currentLanguage]);

  useEffect(() => {
    setInvalidPasswordRequirement(
      Object.keys(passwordPolicyComplexityValidationState).find(
        (key: PasswordComplexityKeys) =>
          !passwordPolicyComplexityValidationState[key]?.isValid
      )
    );
  }, [passwordPolicyComplexityValidationState]);

  return {
    passwordPolicyComplexity: passwordPolicyComplexityValidationState,
    invalidPasswordRequirement,
  };
};

export default usePasswordPolicy;
