export const maskEmail = (email?: string): string => {
  if (!email) return "";
  const splitIndex = email.indexOf("@");

  if (splitIndex === -1) {
    return "";
  }

  if (splitIndex < 3) {
    return email;
  }

  email = email.replace(
    email.slice(1, splitIndex - 1),
    new Array(splitIndex - 2).fill("*").join("")
  );

  return email;
};

export default maskEmail;
