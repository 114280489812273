import React, { FC } from "react";
import "./acknowledgmentContent.scss";

type AcknowledgmentContentProps = {
  children: React.ReactNode;
  actions: React.ReactNode;
  checkbox?: React.ReactNode;
};

const AcknowledgmentContent: FC<AcknowledgmentContentProps> = ({
  children,
  actions,
  checkbox,
}) => {
  return (
    <section className="acknowledgment__container">
      <div
        id="acknowledgment-info"
        className="acknowledgment__container__content"
      >
        {children}
      </div>
      {checkbox}
      <div className="acknowledgment__container__footer">{actions}</div>
    </section>
  );
};

export default AcknowledgmentContent;
