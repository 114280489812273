import { BusinessUnits } from "@app/provider/drawRequest";
import { TestType } from "@app/provider/testData/types";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  contactPhoneOH: {
    id: "contactPhoneOH",
    defaultMessage: "650-273-4468",
  },
  contactPhoneWH: {
    id: "contactPhoneWH",
    defaultMessage: "844-399-2879, press 0",
  },
  contactPhoneOncology: {
    id: "contactPhoneOncology",
    defaultMessage: "650-489-9050",
  },
});

export const contactPhoneMapper = (
  businessUnit: BusinessUnits | undefined = BusinessUnits.ORGAN_HEALTH
): string => {
  const intl = useIntl();

  const mapper = {
    [BusinessUnits.ORGAN_HEALTH]: intl.formatMessage(messages.contactPhoneOH),
    [BusinessUnits.ONCOLOGY]: intl.formatMessage(messages.contactPhoneOncology),
    [BusinessUnits.WOMENS_HEALTH]: intl.formatMessage(messages.contactPhoneWH),
  };
  return mapper[businessUnit];
};
