import React, { FC } from "react";
import { useIntl, defineMessages } from "react-intl";

import "./flowHeader.scss";

const messages = defineMessages({
  flowHeaderTitle: {
    id: "flowHeaderTitle",
    defaultMessage: "Natera’s Patient Portal",
  },
});

const FlowHeader: FC = () => {
  const intl = useIntl();

  return (
    <section className="flow-header__container">
      <h1 className="flow-header__container--title">
        {intl.formatMessage(messages.flowHeaderTitle)}
      </h1>
    </section>
  );
};

export default FlowHeader;
