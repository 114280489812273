import React, { FC } from "react";
import classNames from "classnames";
import { MessageContainer } from "@app/neva/components/message";
import "./main.scss";

const Main: FC = () => {
  return (
    <main
      className={classNames("widget-main", {
        without_scroll: false,
      })}
    >
      <MessageContainer />
    </main>
  );
};

export default Main;
