import React, { useContext } from "react";
import { useIntl, defineMessages } from "react-intl";
import { routes } from "@app/routing";
import FactorList from "@app/components/factorList";
import { ProfileContext } from "@app/provider";
import "./editMfa.scss";
import { BlueHeader } from "@app/components";

const messages = defineMessages({
  editMfaBackButton: {
    id: "editMfaBackButton",
    defaultMessage: "Security Settings",
  },
});

const EditMfa: React.FC = () => {
  const intl = useIntl();

  React.useEffect(() => {
    getProfile();
  }, []);

  const { getProfile, getProfileData } = useContext(ProfileContext);

  return (
    <div className="edit-mfa">
      <BlueHeader
        arrowRoute={routes.securitySettings}
        title={intl.formatMessage(messages.editMfaBackButton)}
      />

      <div className="edit-mfa__title">
        <FactorList profile={getProfileData} />
      </div>
    </div>
  );
};

export default EditMfa;
