import { TestResult } from "@app/provider/testResult";
import { SupplementalType } from "@app/components/supplementButton/supplementalButton";

const getSupplementalVideoUid = (
  testResult: TestResult
): string | undefined => {
  const supplementalVideo = testResult?.documents?.filter(
    (doc) => doc.contentType === SupplementalType.VIDEO
  );
  return supplementalVideo && supplementalVideo[0]
    ? supplementalVideo[0].uid
    : undefined;
};
export default getSupplementalVideoUid;
