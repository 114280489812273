import React, { FC } from "react";
import "./avatarLogo.scss";

interface Props {
  logoSrc: string;
}

const AvatarLogo: FC<Props> = ({ logoSrc }) => {
  return (
    <div className="avatar-logo__container">
      <img
        className="avatar-logo__image"
        src={logoSrc}
        aria-hidden={true}
        alt=""
      />
    </div>
  );
};

export default AvatarLogo;
