import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { PayorContext } from "@app/provider";
import { Payor } from "@app/provider/payor";
import {
  TypeAhead,
  TypeAheadOption,
} from "@natera/platform/lib/components/form/field";
import { ErrorController } from "@natera/platform/lib/hooks";

import "./payorsTypeAhead.scss";

const messages = defineMessages({
  payorsTypeAheadPlaceholder: {
    id: "payorsTypeAheadPlaceholder",
    defaultMessage: "Enter insurance company",
  },
});

const SEARCHING_DELAY_TIME = 600;
const PAYORS_LIMIT = 100;

export const payorFieldId = "payorFieldId";

export interface PayorsTypeAheadProps {
  selectedPayor?: Payor;
  onSelect: (item?: Payor) => void;
  errorController?: ErrorController;
}

const PayorsTypeAhead: React.FC<PayorsTypeAheadProps> = ({
  selectedPayor,
  onSelect,
  errorController,
}) => {
  const intl = useIntl();
  const { getPayors } = React.useContext(PayorContext);

  const handlePayorSearch = (value: string) =>
    getPayors(value.trim(), PAYORS_LIMIT);

  const handleSelect = (item?: Payor) => {
    errorController && errorController.clearValidationError(payorFieldId);
    onSelect(item);
  };

  const displayPayorValue = (option: Payor) => option.displayName;

  return (
    <TypeAhead
      id={payorFieldId}
      name={payorFieldId}
      className="payors-type-ahead"
      getOptions={handlePayorSearch}
      placeholder={intl.formatMessage(messages.payorsTypeAheadPlaceholder)}
      onSelect={handleSelect}
      selectedOption={selectedPayor}
      displayValue={displayPayorValue}
      searchEmptyValue
      delayTime={SEARCHING_DELAY_TIME}
      pageLimit={PAYORS_LIMIT}
      outline
    >
      {(options: Payor[]) =>
        options.map((option) => (
          <TypeAheadOption optionValue={option} key={option.displayName}>
            {option.displayName}
          </TypeAheadOption>
        ))
      }
    </TypeAhead>
  );
};

export default PayorsTypeAhead;
