import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, ReactElement, useContext, useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Tooltip } from "@natera/material/lib/tooltip";
import { Form, Button } from "@natera/platform/lib/components/form";
import CalendarIcon from "@assets/svg/icons/calendar.svg";
import {
  DateInput,
  Textfield,
} from "@natera/platform/lib/components/form/field";
import { ErrorContext } from "@app/provider";
import { FormField } from "@app/components";
import { weekDayFormatter } from "@app/utils";

const messages = defineMessages({
  unlockEmailAddress: {
    id: "unlockEmailAddress",
    defaultMessage: "Email Address",
  },
  unlockEnterEmailAddress: {
    id: "unlockEnterEmailAddress",
    defaultMessage: "Enter your email address",
  },
  unlockVerify: {
    id: "unlockVerify",
    defaultMessage: "Verify",
  },
  unlockLastName: {
    id: "unlockLastName",
    defaultMessage: "Last Name",
  },
  unlockEnterYourLastName: {
    id: "unlockEnterYourLastName",
    defaultMessage: "Enter Your Last Name",
  },
  unlockDateOfBirth: {
    id: "unlockDateOfBirth",
    defaultMessage: "Date of Birth",
  },
  unlockDateOfBirthPlaceholder: {
    id: "unlockDateOfBirthPlaceholder",
    defaultMessage: "MM/DD/YYYY",
  },
  unlockUserFormEmailFormat: {
    id: "unlockUserFormEmailFormat",
    defaultMessage: "email format (username@domain)",
  },
});

interface UnlockUserFormProps {
  onSubmit: (email: string, dateOfBirth: string, lastName: string) => void;
  preselectedEmail?: string;
}

const UnlockUserForm: FC<UnlockUserFormProps> = ({
  onSubmit,
  preselectedEmail,
}) => {
  const { getValidationError } = useContext(ErrorContext);
  const intl = useIntl();

  const [email, setEmail] = useState(preselectedEmail);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const formData = e.currentTarget;
    const email = formData.email.value;
    const lastName = formData.lastName.value.trim();
    const dateOfBirth = formData.dateOfBirth.value;
    onSubmit(email, lastName, dateOfBirth);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const emailAddressFormFieldElement: ReactElement = (
    <FormField
      className="email-field"
      label={intl.formatMessage(messages.unlockEmailAddress)}
      required
      error={getValidationError("email")}
      htmlFor="email"
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <Tooltip
          content={intl.formatMessage(messages.unlockUserFormEmailFormat)}
          position={{ xPos: 1 }}
        >
          <Textfield
            outline
            type="email"
            id="email"
            name="email"
            required
            onChange={handleEmailChange}
            placeholder={intl.formatMessage(messages.unlockEnterEmailAddress)}
            value={email}
            autoComplete="email"
          />
        </Tooltip>
      </HeapPHIDecorator>
    </FormField>
  );

  const lastNameFormFieldElement: ReactElement = (
    <FormField
      label={intl.formatMessage(messages.unlockLastName)}
      required
      htmlFor="lastName"
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <Textfield
          outline
          type="text"
          id="lastName"
          name="lastName"
          required
          placeholder={intl.formatMessage(messages.unlockEnterYourLastName)}
        />
      </HeapPHIDecorator>
    </FormField>
  );

  const dateOfBirthFormFieldElement: ReactElement = (
    <FormField
      label={intl.formatMessage(messages.unlockDateOfBirth)}
      required={true}
      htmlFor="date"
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <DateInput
          id="date"
          name="dateOfBirth"
          placeholder={intl.formatMessage(
            messages.unlockDateOfBirthPlaceholder
          )}
          maxDate={new Date()}
          required
          outline
          trailingIcon={CalendarIcon}
          formatWeekdayName={weekDayFormatter}
        />
      </HeapPHIDecorator>
    </FormField>
  );

  return (
    <Form onSubmit={handleSubmit} noValidate>
      {emailAddressFormFieldElement}
      {lastNameFormFieldElement}
      {dateOfBirthFormFieldElement}
      <Button type="submit" raised={true}>
        {intl.formatMessage(messages.unlockVerify)}
      </Button>
    </Form>
  );
};

export default UnlockUserForm;
