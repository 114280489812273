import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLoaderTimer } from "@app/neva/hooks";
import { ConfigContext, TestDetailsContext } from "@app/provider";
import { testTypeToBotId } from "@app/neva/helpers";
import { TestStatus, TestType } from "@app/provider/testData/types";

type NevaProviderProps = {
  children: React.ReactNode;
  hideIfResultViewed: boolean;
};

export interface NevaController {
  startDate: Date;
  botId: string;
  nevaPreSessionToken: string;
  isNevaDisabled: boolean;
  isFirst: boolean;
  pcop: string;
  isWidgetOpen: boolean;
  completeNevaFlow: () => void;
  onReady: () => void;
  setIsWidgetOpen: Dispatch<SetStateAction<boolean>>;
}

export const Context = createContext<NevaController>({
  startDate: new Date(),
  botId: "",
  nevaPreSessionToken: "",
  isNevaDisabled: true,
  isFirst: false,
  pcop: "",
  isWidgetOpen: false,
  completeNevaFlow: () => undefined,
  onReady: () => undefined,
  setIsWidgetOpen: () => undefined,
});

Context.displayName = "NevaContext";

const NevaProvider: FC<NevaProviderProps> = ({
  children,
  hideIfResultViewed,
}) => {
  const startDate = useRef(new Date());
  const {
    config: {
      nevaConfig: { enabled: isNevaGloballyEnabled, isAutoExpandEnabled },
    },
  } = useContext(ConfigContext);
  const { isLoading, getTestDetails, refetchTest } = useContext(
    TestDetailsContext
  );

  const [hasCompletedNevaFlow, setHasCompletedNevaFlow] = useState(false);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  let testCard = getTestDetails();
  const hasViewedResult = testCard?.viewStatus === TestStatus.VIEWED;
  const autoExpand = isAutoExpandEnabled && !hasViewedResult;
  const isFirst = !hasCompletedNevaFlow && !hasViewedResult;
  //define logic
  const pcop = "";
  const botId = testTypeToBotId(testCard?.testType);
  const nevaPreSessionToken = `lims_id_${testCard?.limsId?.toString()}`;

  useEffect(() => {
    startLoaderTimer();
  }, []);

  useEffect(() => {
    if (autoExpand) {
      setIsWidgetOpen(true);
    }
  }, [autoExpand]);

  const handleNevaTimeout = () => {
    setIsWidgetOpen(false);
    if (!hasViewedResult) {
      completeNevaFlow();
    }
  };

  const { startLoaderTimer, nevaOnline } = useLoaderTimer(handleNevaTimeout);

  const completeNevaFlow = () => {
    setHasCompletedNevaFlow(true);
    refetchTest().then((testDetail) => {
      testCard = testDetail;
    });
  };

  const isNevaDisabled =
    !isNevaGloballyEnabled ||
    isLoading ||
    !testCard?.isNevaEnabled ||
    (hideIfResultViewed && (hasCompletedNevaFlow || hasViewedResult)) ||
    ![TestType.HORIZON, TestType.PANORAMA, TestType.EMPOWER].includes(
      testCard.testType
    );

  const value = useMemo(
    () => ({
      startDate: startDate.current,
      botId,
      nevaPreSessionToken,
      isNevaDisabled,
      isFirst,
      pcop,
      isWidgetOpen,
      completeNevaFlow,
      onReady: nevaOnline,
      setIsWidgetOpen,
    }),
    [
      startDate,
      botId,
      nevaPreSessionToken,
      isNevaDisabled,
      isFirst,
      pcop,
      isWidgetOpen,
      completeNevaFlow,
      nevaOnline,
      setIsWidgetOpen,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default NevaProvider;
