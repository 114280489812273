import {
  MultiplePaymentMethodType,
  PaymentMethodInfoType,
  PaymentMethodType,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";

type GetPaymentMethodInfoSubStepsData = (
  subStepsData: PaymentMethodType | MultiplePaymentMethodType,
  isInsuranceFirstStep: boolean,
  isInsuranceSecondStep: boolean
) => PaymentMethodInfoType | undefined;

export const getPaymentMethodInfoSubStepsData: GetPaymentMethodInfoSubStepsData = (
  subStepsData,
  isInsuranceFirstStep,
  isInsuranceSecondStep
) => {
  if (subStepsData) {
    if (isInsuranceFirstStep) {
      return (subStepsData as MultiplePaymentMethodType)?.paymentMethodInfo
        ?.first?.paymentMethodInfo;
    } else if (isInsuranceSecondStep) {
      return (subStepsData as MultiplePaymentMethodType)?.paymentMethodInfo
        ?.second?.paymentMethodInfo;
    } else {
      return (subStepsData as PaymentMethodType).paymentMethodInfo;
    }
  }
};
