import React, { FC, useContext, useMemo, useState } from "react";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import ModifyAppointment from "@app/components/sampleDraw/mobilePhlebotomy/appointmentTimeStep/modifyAppointment";
import { DrawRequestContext } from "@app/provider";
import { AppointmentTimeConfirmation } from "@app/components/sampleDraw/mobilePhlebotomy/appointmentTimeStep/appointmentTimeConfirmation";
import { TestType } from "@app/provider/testData/types";

type AppointmentTimeStepProps = {
  onBack?: () => void;
  testType?: TestType;
  orderUid?: string;
  testUid?: string;
  token?: string;
};

const AppointmentTimeStep: FC<AppointmentTimeStepProps> = ({
  onBack,
  ...props
}) => {
  const { drawRequestId: reqId } = useContext(DrawRequestContext);

  const [modifyAppointment, setModifyAppointment] = useState<boolean>(false);

  const handleModifyAppointment = () => setModifyAppointment(true);

  const showModifyAppointmentView = useMemo(() => modifyAppointment && reqId, [
    modifyAppointment,
    reqId,
  ]);

  const handleBackToTimeConfirmation = () => setModifyAppointment(false);

  return (
    <>
      {showModifyAppointmentView ? (
        <ModifyAppointment
          handleBackToTimeConfirmation={handleBackToTimeConfirmation}
          {...props}
        />
      ) : (
        <>
          {!modifyAppointment && <StepperHeaderProgress />}
          <AppointmentTimeConfirmation
            handleModifyAppointment={handleModifyAppointment}
            onBack={onBack}
          />
        </>
      )}
    </>
  );
};

export default AppointmentTimeStep;
