import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";

import { DrawRequestProvider, KitProvider } from "@app/provider";
import { routes } from "@app/routing";
import { ModifyMobilePhlebotomy } from "./modify";
import { CreateMobilePhlebotomy } from "./create";
import { TestDetailsProvider } from "@app/provider/testData";

export enum DRAW_PREFERENCE_TO_SHOW {
  clinic = "clinic",
  lab = "lab",
  mobilePhlebotomy = "mobilePhlebotomy",
}

export enum SAMPLE_DRAW_STEP_IDS {
  PREFERENCE = "PREFERENCE",
  LAB1 = "LAB1",
  LAB2 = "LAB2",
  MP1 = "MP1",
  MP2 = "MP2",
  MP3 = "MP3",
  MP4 = "MP4",
}

const MobilePhlebotomy: FC = () => {
  return (
    <TestDetailsProvider>
      <DrawRequestProvider>
        <KitProvider>
          <Switch>
            <Route
              path={routes.sampleDrawModify}
              component={ModifyMobilePhlebotomy}
            />
            <Route
              path={routes.sampleDraw}
              component={CreateMobilePhlebotomy}
              exact
            />
          </Switch>
        </KitProvider>
      </DrawRequestProvider>
    </TestDetailsProvider>
  );
};

export default MobilePhlebotomy;
