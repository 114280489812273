import React from "react";
import { Commercial, SubSteps } from "./paymentMethod";
import { MultipleInsurance } from "@app/components/ui/inputs/multipleInsurancesSelector/multipleInsurancesSelector";
import { PredefinedCompanyName } from "@app/components/companySelector/companySelector";
import {
  CommercialStep,
  ProvideCommercialStep,
  EnterOtherInsuranceCompanyStep,
  InsuranceCostEstimationStep,
} from "@app/components/simpleOrder/payment/steps";
import {
  stepsMapper,
  MultipleStepsIds,
  onConfirmType,
  MultipleStep,
} from "./multipleStepsMapper";

export const getMultipleCommercialSteps = (
  multipleStepId: MultipleStepsIds,
  initIdx: number,
  multipleStepData: Commercial,
  onConfirm: onConfirmType
): MultipleStep[] => {
  const otherCompanyStep = !Object.values(PredefinedCompanyName).includes(
    multipleStepData.paymentMethodInfo.companyName as PredefinedCompanyName
  );
  const isSecondMultipleStep = multipleStepId === SubSteps.MULTIPLE_SECOND;
  const confirmSubstepsHandler = () => undefined;

  const commercialSteps: MultipleStep[] = [
    {
      stepId: multipleStepId,
      index: initIdx,
      childComponent: stepsMapper(multipleStepId).multipleStepChildComponent(
        onConfirm
      ),
      data: MultipleInsurance.Commercial,
    },
    {
      stepId: stepsMapper(multipleStepId).selectCommercialCompanyStepId,
      index: initIdx + 1,
      childComponent: <CommercialStep onConfirm={confirmSubstepsHandler} />,
      data: {
        companyName: otherCompanyStep
          ? PredefinedCompanyName.Other
          : multipleStepData.paymentMethodInfo.companyName,
        companyGroupId: otherCompanyStep
          ? undefined
          : multipleStepData.paymentMethodInfo.companyGroupId,
      },
    },
    {
      stepId: stepsMapper(multipleStepId).provideCommercialInsuranceStepId,
      index: initIdx + 3,
      childComponent: (
        <ProvideCommercialStep onConfirm={confirmSubstepsHandler} />
      ),
      data: { memberId: multipleStepData.paymentMethodInfo.memberId },
    },
  ];

  if (otherCompanyStep) {
    commercialSteps.push({
      stepId: stepsMapper(multipleStepId).enterCommercialOtherCompanyStepId,
      index: initIdx + 2,
      childComponent: (
        <EnterOtherInsuranceCompanyStep
          previousStepId={
            stepsMapper(multipleStepId).selectCommercialCompanyStepId
          }
        />
      ),
      data: {
        otherCompanyName: multipleStepData.paymentMethodInfo.companyName,
        otherCompanyGroupId: multipleStepData.paymentMethodInfo.companyGroupId,
      },
    });
  }

  if (isSecondMultipleStep) {
    commercialSteps.push({
      stepId: SubSteps.COST_ESTIMATION,
      index: initIdx + 4,
      childComponent: <InsuranceCostEstimationStep onSubmit={onConfirm} />,
      data: {},
    });
  }

  return commercialSteps;
};
