import React, { FC, ReactNode } from "react";
import { DisplayField } from "@natera/platform/lib/components/form/field";
import { FormField } from "@app/components";
import "./infoCardField.scss";

interface Props {
  label: ReactNode | string;
  value?: ReactNode | string;
}

const InfoCardField: FC<Props> = ({ label, value, ...props }) => {
  return (
    <FormField
      className="info-card-field"
      label={label}
      isEditable={false}
      {...props}
    >
      <DisplayField
        value={
          <>
            <div className="info-card-field__value--text">{value}</div>
          </>
        }
      />
    </FormField>
  );
};

export default InfoCardField;
