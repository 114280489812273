import React, { createContext, FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ErrorController } from "@natera/platform/lib/hooks";
import { ResultCodes } from "@app/service/resultCodes";

const errorMessages = defineMessages({
  invalidPasscode: {
    id: "invalidPasscode",
    defaultMessage: "Incorrect code.",
    description: "Error message if passcode is invalid",
  },
});

export enum MFAErrorCodes {
  PASSCODE_INVALID = "E0000068",
}

const POLL_STOPPED = "The poll was stopped by the sdk";

export interface MfaErrorController {
  parseError: (
    errorCode: string,
    errorMessage: string,
    errorController: ErrorController
  ) => Promise<void>;
}

export const Context = createContext<MfaErrorController>({
  parseError: () => Promise.reject(),
});

Context.displayName = "MfaErrorContext";

const MfaErrorProvider: FC = ({ children }) => {
  const intl = useIntl();

  const parseError = async (
    errorCode: string,
    errorMessage: string,
    errorController: ErrorController
  ) => {
    switch (errorCode) {
      case MFAErrorCodes.PASSCODE_INVALID:
      case ResultCodes.INCORRECT_ACTIVATION_CODE:
        errorController.setValidationError(
          "code",
          intl.formatMessage(errorMessages.invalidPasscode)
        );
        break;
      default:
        if (errorMessage !== POLL_STOPPED) {
          errorController.setValidationError("code", errorMessage);
        }
        break;
    }
  };

  return (
    <Context.Provider
      value={{
        parseError,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default MfaErrorProvider;
