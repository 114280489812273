import React, { FC, ReactNode } from "react";
import Svg from "@natera/material/lib/svg";
import "./informationCard.scss";

export interface InformationCardProps {
  headerIcon?: string;
  headerText?: string;
  content?: ReactNode;
}

const InformationCard: FC<InformationCardProps> = ({
  headerIcon,
  headerText,
  content,
}) => {
  return (
    <div className="information-card">
      {headerIcon && headerText && (
        <div className="information-card-header">
          <Svg content={headerIcon} />
          <div>{headerText}</div>
        </div>
      )}
      <div className="information-card-content">{content}</div>
    </div>
  );
};

export default InformationCard;
