import React, { FC, useContext, useRef, useEffect } from "react";
import { MessageBody, Typing } from "@app/neva/components/message";
import { DialogsContext, EventsContext } from "@app/neva/provider";
import { checkShowLogo } from "./helpers/checkShowLogo";
import "./messageContainer.scss";

const MessageContainer: FC = () => {
  const messagesEnd = useRef<HTMLDivElement>(null);
  const {
    eventsProviderState: { messages, showTyping },
  } = useContext(EventsContext);
  const { chatMessageDialog } = useContext(DialogsContext);

  useEffect(() => {
    scrollToBottom();
  }, [messages?.length, showTyping]);

  const scrollToBottom = () => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView();
    }
  };
  return (
    <div className="message-container__wrap-container">
      {messages.map((message, index) => (
        <MessageBody
          key={message.id}
          message={message}
          messageIndex={index}
          isShowLogo={checkShowLogo(messages, index)}
          isLastMessage={messages.length === index + 1}
          scrollToBottom={scrollToBottom}
        />
      ))}
      {chatMessageDialog.getDialog()}
      {showTyping && <Typing />}
      <div ref={messagesEnd} />
    </div>
  );
};

export default MessageContainer;
