import React, { FC } from "react";
import { Dialog } from "@natera/material/lib/dialog";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/platform/lib/components/form";
import { routes } from "@app/routing";
import { useHistory } from "react-router-dom";
import "./testNotFound.scss";

const messages = defineMessages({
  testNotFoundDialogTitle: {
    id: "testNotFoundDialogTitle",
    defaultMessage: "Test not found",
  },
  testNotFoundDialogSubmit: {
    id: "testNotFoundDialogSubmit",
    defaultMessage: "Back To Home",
  },
});

const TestNotFoundModal: FC = () => {
  const intl = useIntl();

  const history = useHistory();

  const handleClick = () => history.replace(routes.home);
  return (
    <Dialog
      className="test_not_found-modal"
      title={
        <span>{intl.formatMessage(messages.testNotFoundDialogTitle)}</span>
      }
      onClose={handleClick}
      showCrossButton
      actions={
        <Button className="back_home_button" raised onClick={handleClick}>
          {intl.formatMessage(messages.testNotFoundDialogSubmit)}
        </Button>
      }
    >
      <></>
    </Dialog>
  );
};

export default TestNotFoundModal;
