import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SecurityContext } from "@natera/platform/lib/provider/security";
import { ResultCodes } from "@app/service/resultCodes";
import { routes } from "@app/routing";
import { UppAuthContext } from "@app/provider";
import { SpinnerView } from "@app/components";

const LogOut: FC = () => {
  const history = useHistory();
  const { logout } = useContext(UppAuthContext);
  const { search } = useLocation();
  const { isAuthorized } = useContext(SecurityContext);
  const [code, setCode] = useState<string | null>(null);
  const isAuth = isAuthorized();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    const code = searchParams.get("code");
    setCode(code);
    logout();
  }, []);

  useEffect(() => {
    if (isAuth) return;

    let url = code ? `${routes.signIn}?code=${code}` : routes.signIn;

    if (code === ResultCodes.USER_IS_DELETED) {
      url = `${routes.signUp}?code=${code}`;
    }

    history.push(url);
  }, [isAuth]);

  return <SpinnerView isLoading />;
};
export default LogOut;
