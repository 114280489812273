import { OrderProvider, SimpleOrderProvider } from "@app/provider";
import { routes } from "@app/routing";
import React, { FC } from "react";
import { useParams, useHistory } from "react-router-dom";
import SimpleOrderRouting from "./simpleOrderRouting";

const SimpleOrder: FC = () => {
  const { orderUid } = useParams<{
    orderUid: string;
  }>();
  const history = useHistory();

  if (!orderUid) {
    history.replace(routes.home);
  }

  //ToDo Need to use SimpleOrderProvider only (refactoring)
  return (
    <OrderProvider orderUid={orderUid}>
      <SimpleOrderProvider orderUid={orderUid}>
        <SimpleOrderRouting />
      </SimpleOrderProvider>
    </OrderProvider>
  );
};

export default SimpleOrder;
