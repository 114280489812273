import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { PaymentMethodForm } from "@app/components/simpleOrder/payment";
import { StepperContent } from "@app/components/ui/layout/stepperContent";

const messages = defineMessages({
  paymentMethodStepHeaderTitle: {
    id: "paymentMethodStepHeaderTitle",
    defaultMessage: "Payment Method",
  },
  paymentMethodStepHeaderSubTitle: {
    id: "paymentMethodStepHeaderSubTitle",
    defaultMessage: "Which situation best describes you?",
  },
  paymentMethodStepHeaderText: {
    id: "paymentMethodStepHeaderText",
    defaultMessage: "Select the payment method that works best for you.",
  },
});

type Props = {
  onSubmit: (data: unknown) => void;
  goBack: () => void;
};

const PaymentMethodStep: React.FC<Props> = ({ onSubmit, goBack }) => {
  const intl = useIntl();

  return (
    <StepperContent
      title={intl.formatMessage(messages.paymentMethodStepHeaderTitle)}
      subTitle={intl.formatMessage(messages.paymentMethodStepHeaderSubTitle)}
      description={intl.formatMessage(messages.paymentMethodStepHeaderText)}
    >
      <PaymentMethodForm onConfirm={onSubmit} goBack={goBack} />
    </StepperContent>
  );
};

export default PaymentMethodStep;
