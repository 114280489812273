import React, { FC, useContext, useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Link } from "react-router-dom";
import "./unlock.scss";
import { NotificationContext, UserContext } from "@app/provider";
import MailSentDialog from "@app/components/mailSentDialog";
import Button from "@natera/material/lib/button";
import { routes } from "@app/routing";
import { maskEmail } from "@app/utils";

export interface CredentialsInterface {
  email: string;
  dateOfBirth?: string;
  lastName?: string;
}

const messages = defineMessages({
  unlockResendLink: {
    id: "unlockResendLink",
    defaultMessage: "Resend Reset Link",
  },
  unlockDone: {
    id: "unlockDone",
    defaultMessage: "Done",
  },
  unlockCheckSpam: {
    id: "unlockCheckSpam",
    defaultMessage:
      "Did not receive the email? Check your spam filter, or {link}",
  },
  unlockTryAnotherEmail: {
    id: "unlockTryAnotherEmail",
    defaultMessage: "Try another email address",
  },
  unlockInstructions: {
    id: "unlockInstructions",
    defaultMessage:
      "We have sent password recovery instructions to your email.",
  },
  unlockValidLink: {
    id: "unlockValidLink",
    defaultMessage: "The link is valid only for {time} minutes.",
  },
  unlockResentMessage: {
    id: "unlockResentText",
    defaultMessage:
      "We’ve sent you another reset link. Please follow the instructions",
  },
  unlockResentHeader: {
    id: "unlockResentHeader",
    defaultMessage: "Reset link sent",
  },
});

interface MailSentViewProps {
  email: string;
  credentials?: CredentialsInterface;
}

export const MailSentView: FC<MailSentViewProps> = ({ email, credentials }) => {
  const intl = useIntl();

  const { clear, addNotification } = useContext(NotificationContext);
  const { unlockUser, isLoading, clearUnlockUserData } = useContext(
    UserContext
  );

  const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(false);

  const disableResendButton = () => {
    const millisecondsInOneMinute = 60 * 1000;

    setResendButtonIsDisabled(true);
    setTimeout(() => setResendButtonIsDisabled(false), millisecondsInOneMinute);
  };

  const resendUnlockMail = () => {
    clear();
    credentials &&
      unlockUser(
        credentials.email,
        credentials.dateOfBirth,
        credentials.lastName
      ).then((response) => {
        if (response?.data?.success) {
          addNotification({
            title: intl.formatMessage(messages.unlockResentHeader),
            message: intl.formatMessage(messages.unlockResentMessage),
            type: "info",
          });
        }
      });
    disableResendButton();
  };

  const mailSentActions = (
    <div className="buttons-container">
      <Button
        onClick={resendUnlockMail}
        type="button"
        outlined
        disabled={isLoading || resendButtonIsDisabled}
      >
        {intl.formatMessage(messages.unlockResendLink)}
      </Button>
      <Link to={routes.root}>
        <Button outlined={true}>
          {intl.formatMessage(messages.unlockDone)}
        </Button>
      </Link>
      <p>
        {intl.formatMessage(messages.unlockCheckSpam, {
          link: (
            <Link to={routes.unlock} key={1} onClick={clearUnlockUserData}>
              {intl.formatMessage(messages.unlockTryAnotherEmail)}
            </Link>
          ),
        })}
      </p>
    </div>
  );

  const mailSentDescription = (
    <>
      <p>{intl.formatMessage(messages.unlockInstructions)}</p>
      <p>
        <span>{maskEmail(email)}</span>
      </p>
      <p>
        {intl.formatMessage(messages.unlockValidLink, {
          time: "60",
        })}
      </p>
    </>
  );

  return (
    <MailSentDialog
      description={mailSentDescription}
      isLoading={isLoading}
      actions={mailSentActions}
    />
  );
};

export default MailSentView;
