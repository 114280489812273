import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Button } from "@natera/platform/lib/components/form";
import Dialog from "@natera/material/lib/dialog";

import { UserContext } from "@app/provider";

interface UpdateEmailDialog {
  closeDialog: () => void;
  update: () => void;
}

const messages = defineMessages({
  updateEmailDialogTitle: {
    id: "updateEmailDialogTitle",
    defaultMessage:
      "Changing your email will require you to verify the new email and re-login",
  },
  updateEmailDialogDescription: {
    id: "updateEmailDialogDescription",
    defaultMessage: "Are you sure you want to proceed?",
  },
  updateEmailDialogCancelButton: {
    id: "updateEmailDialogCancelButton",
    defaultMessage: "Cancel",
  },
  updateEmailDialogUpdateButton: {
    id: "updateEmailDialogUpdateButton",
    defaultMessage: "Update",
  },
});

const UpdateEmailDialog: FC<UpdateEmailDialog> = ({ closeDialog, update }) => {
  const intl = useIntl();
  const { isLoading } = useContext(UserContext);

  return (
    <Dialog
      className="update-email-dialog"
      onClose={closeDialog}
      showCrossButton
      title={intl.formatMessage(messages.updateEmailDialogTitle)}
    >
      <div className="update-email-dialog__container">
        <p>{intl.formatMessage(messages.updateEmailDialogDescription)}</p>

        <Button onClick={closeDialog} outlined disabled={isLoading}>
          {intl.formatMessage(messages.updateEmailDialogCancelButton)}
        </Button>

        <Button onClick={update} raised loading={isLoading}>
          {intl.formatMessage(messages.updateEmailDialogUpdateButton)}
        </Button>
      </div>
    </Dialog>
  );
};

export default UpdateEmailDialog;
