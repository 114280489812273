import {
  TestResultDetailsPage,
  TestResultPdfPage,
  DetailRecordPage,
  UnderstandingYourResultsPage,
  ShareFetalSexPage,
} from "@app/pages";
import { DrawRequestProvider, TestResultProvider } from "@app/provider";
import { routes } from "@app/routing";
import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import SupplementalPdf from "./supplemental/pdf/supplementalPdf";
import SupplementalVideo from "./supplemental/video/supplementalVideo";
import { TestInformationPage } from "@app/pages/private/testResult/testInformation";
import { TestDetailsProvider } from "@app/provider/testData";

const TestResultPage: FC = () => {
  return (
    <Switch>
      <TestDetailsProvider>
        <TestResultProvider>
          <Route
            path={routes.understandingYourResults}
            component={UnderstandingYourResultsPage}
          />
          <Route
            path={routes.testResultDetails}
            component={TestResultDetailsPage}
          />
          <Route path={routes.shareFetalSex} component={ShareFetalSexPage} />
          <Route path={routes.testResultPdf} component={TestResultPdfPage} />
          <Route path={routes.supplementalPdf} component={SupplementalPdf} />
          <Route
            path={routes.supplementalVideo}
            component={SupplementalVideo}
          />
        </TestResultProvider>

        <Route
          exact
          path={routes.testDetailsInformation}
          component={TestInformationPage}
        />
        <DrawRequestProvider>
          <Route exact path={routes.testDetails} component={DetailRecordPage} />
        </DrawRequestProvider>
      </TestDetailsProvider>
    </Switch>
  );
};

export default TestResultPage;
