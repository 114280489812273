import {
  ManagedBy,
  ONCOLOGY_TEST_TYPES,
  SampleType,
  TestCardStatus,
  TestType,
} from "@app/provider/testData/types";
import { TestDetailsRecord } from "@app/provider/types";

export const ALLOWED_TEST_STATUSES = [
  TestCardStatus.APPT_CONFIRMED,
  TestCardStatus.APPT_REQUESTED,
];

export const ALLOWED_TEST_TYPES = [
  TestType.EMPOWER,
  TestType.HORIZON,
  TestType.PANORAMA,
  TestType.RENASIGHT,
  TestType.VISTARA,
];

export const showAppointmentDetails = (testDetails?: TestDetailsRecord) => {
  if (!testDetails) return false;

  const isOncologyMobilePhlebotomyTest =
    ONCOLOGY_TEST_TYPES.includes(testDetails.testType) &&
    (testDetails.status === TestCardStatus.UPCOMING_TEST ||
      testDetails.status === TestCardStatus.TEST_ORDERED) &&
    testDetails.managedBy === ManagedBy.NATERA;

  return (
    testDetails.sampleType === SampleType.BLOOD &&
    (isOncologyMobilePhlebotomyTest ||
      ALLOWED_TEST_STATUSES.includes(testDetails.status) ||
      (testDetails.status === TestCardStatus.KIT_SHIPPED &&
        ALLOWED_TEST_TYPES.includes(testDetails.testType)) ||
      (testDetails.managedBy === ManagedBy.NATERA &&
        ((testDetails.status === TestCardStatus.TEST_ORDERED &&
          testDetails.testType === TestType.RENASIGHT) ||
          (testDetails.status === TestCardStatus.UPCOMING_TEST &&
            testDetails.testType === TestType.PROSPERA &&
            !testDetails.isProReach))))
  );
};
