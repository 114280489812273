import React, { FC } from "react";
import { IDP_TYPE } from "@app/service/user";
import Svg from "@natera/material/lib/svg";
import LetterIcon from "@assets/svg/icons/letter.svg";
import AppleLogo from "@assets/svg/apple-logo.svg";
import GoogleLogo from "@assets/svg/google-logo.svg";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  providerTypeEmailProvider: {
    id: "providerTypeEmailProvider",
    defaultMessage: "Email",
  },
  providerTypeAppleProvider: {
    id: "providerTypeAppleProvider",
    defaultMessage: "Apple",
  },
  providerTypeGoogleProvider: {
    id: "providerTypeGoogleProvider",
    defaultMessage: "Google",
  },
});

type Props = {
  idpType: IDP_TYPE | undefined;
  showEmailIcon?: boolean;
};

const ProviderType: FC<Props> = ({ idpType, showEmailIcon }) => {
  const intl = useIntl();

  return (
    <>
      {idpType === IDP_TYPE.EMAIL && (
        <div className="contact-details__content--sign-up contact-details__content--text">
          {showEmailIcon && (
            <Svg data-testid="emailIconTestId" content={LetterIcon} />
          )}
          {intl.formatMessage(messages.providerTypeEmailProvider)}
        </div>
      )}

      {idpType === IDP_TYPE.APPLE && (
        <div className="contact-details__content--sign-up contact-details__content--text">
          <Svg content={AppleLogo} />
          {intl.formatMessage(messages.providerTypeAppleProvider)}
        </div>
      )}

      {idpType === IDP_TYPE.GOOGLE && (
        <div className="contact-details__content--sign-up contact-details__content--text">
          <Svg content={GoogleLogo} />
          {intl.formatMessage(messages.providerTypeGoogleProvider)}
        </div>
      )}
    </>
  );
};

export default ProviderType;
