import React, { useContext, useMemo, useState } from "react";
import R from "ramda";
import { useLazyQuery } from "@apollo/client";
import TestHistoryService from "@app/service/testHistory";
import { NotificationContext, TestCardUtilsContext } from "@app/provider";
import { ParsedTestCard, TestCard } from "@app/provider/testData/types";
import { useIsMobileDimension } from "@app/hooks";
import { useIntl } from "react-intl";

export enum SORT_BY {
  ASC = "ASC",
  DESC = "DESC",
}

export interface TestHistoryController {
  isLoading: boolean;
  testCards: ParsedTestCard[] | undefined;
  sortBy: SORT_BY;
  pageNum: number;
  currentPage: number;
  getTestHistory: (from?: Date, to?: Date, page?: number) => void;
  loadPage: (
    page?: number,
    from?: Date,
    to?: Date,
    isLoadMore?: boolean
  ) => void;
  toggleSort: (from?: Date, to?: Date) => void;
  reset: () => void;
}
export const Context = React.createContext<TestHistoryController>({
  isLoading: false,
  testCards: [],
  sortBy: SORT_BY.DESC,
  pageNum: 0,
  currentPage: 0,
  getTestHistory: () => Promise.reject(),
  loadPage: () => Promise.reject(),
  toggleSort: () => Promise.reject(),
  reset: () => Promise.reject(),
});

const TEST_LIMIT = {
  MOBILE: 2,
  DESKTOP: 3,
};

Context.displayName = "TestHistoryContext";

const TestHistoryProvider: React.FC = ({ children }) => {
  const intl = useIntl();
  const isMobile = useIsMobileDimension();
  const { addNotification } = useContext(NotificationContext);
  const { getTestCardData } = useContext(TestCardUtilsContext);
  const [currentDesktopPage, setCurrentDesktopPage] = useState(0);
  const [currentMobilePage, setCurrentMobilePage] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [sortBy, setSortBy] = useState<SORT_BY>(SORT_BY.DESC);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [testCardData, setTestCardData] = useState<TestCard[]>([]);
  const [currentDesktopTestCards, setCurrentDesktopTestCards] = useState<
    TestCard[]
  >();
  const [parsedMobileTestCards, setParsedMobileTestCards] = useState<
    TestCard[]
  >();

  const perPage = useMemo(
    () => (isMobile ? TEST_LIMIT.MOBILE : TEST_LIMIT.DESKTOP),
    [isMobile]
  );

  const currentPage = isMobile ? currentMobilePage : currentDesktopPage;
  const setCurrentPage = isMobile
    ? setCurrentMobilePage
    : setCurrentDesktopPage;

  const [getTestHistoryCardsQuery] = useLazyQuery(
    TestHistoryService.getTestHistoryCards(),
    {
      fetchPolicy: "network-only",
    }
  );

  const reset = () => {
    setCurrentDesktopPage(0);
    setCurrentMobilePage(0);
    setPageNum(0);
    setCurrentDesktopTestCards(undefined);
    setParsedMobileTestCards(undefined);
    setTestCardData([]);
  };

  const toggleSort = (from?: Date, to?: Date) => {
    const nextSort = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC;
    setSortBy(nextSort);
    reset();
    handleLoadHistory(from, to, 0, nextSort);
  };

  const addTestCards = (testCards: TestCard[], isLoadMore?: boolean) => {
    if (!testCards) return [];

    if (isMobile) {
      if (isLoadMore) {
        setParsedMobileTestCards([
          ...(parsedMobileTestCards || []),
          ...testCards,
        ]);
      } else {
        setParsedMobileTestCards(testCards);
      }
    } else {
      setCurrentDesktopTestCards(testCards);
    }
    return testCards;
  };

  const handleLoadHistory = async (
    from?: Date,
    to?: Date,
    page?: number,
    sort = sortBy,
    isLoadMore?: boolean
  ) => {
    if (page !== undefined) {
      setCurrentPage(page);
    }
    setIsLoading(true);
    const { data, error } = await getTestHistoryCardsQuery({
      variables: {
        limit: perPage,
        offset: page ? page * perPage : 0,
        sort,
        from,
        to,
      },
    });

    setIsLoading(false);

    if (error) {
      addNotification({
        type: "error",
      });
      return [];
    }
    const testCards: TestCard[] = data?.getTestHistoryCards?.tests;

    const total: number = data?.getTestHistoryCards?.total;
    const pageCount = Math.ceil(total / perPage);

    setPageNum(pageCount);

    const oldAndNewlyFetchedCards = testCardData.concat(testCards);
    setTestCardData(oldAndNewlyFetchedCards);

    return addTestCards(testCards, isLoadMore);
  };

  const handleLoadPage = async (
    page?: number,
    from?: Date,
    to?: Date,
    isLoadMore?: boolean
  ) => {
    const nextPage = page !== undefined ? page : currentPage + 1;
    if (nextPage >= pageNum) return;
    return handleLoadHistory(from, to, nextPage, sortBy, isLoadMore);
  };

  const parsedTestCards = useMemo(() => {
    const isNotEmpty =
      (isMobile ? parsedMobileTestCards : currentDesktopTestCards) || [];

    const result: ParsedTestCard[] = R.map(
      (testCard) => getTestCardData(testCard),
      isNotEmpty
    );

    return result;
  }, [isMobile, parsedMobileTestCards, currentDesktopTestCards, intl.locale]);

  return (
    <Context.Provider
      value={{
        isLoading,
        sortBy,
        pageNum,
        currentPage,
        testCards: parsedTestCards,
        loadPage: handleLoadPage,
        getTestHistory: handleLoadHistory,
        toggleSort,
        reset,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default TestHistoryProvider;
