import React, { FC, createContext } from "react";
import { FetchResult, useLazyQuery } from "@apollo/client";

import VerificationService from "@app/service/verification";
import { ResultCodes } from "@app/service/resultCodes";
import { ErrorBody } from "@app/provider/user";
import { Language } from "@app/provider/intlWrapper";

interface VerificationData {
  success: boolean;
  error?: {
    summary?: string;
  };
}

export interface VerificationController {
  isLoading: boolean;
  verificationData?: VerificationData | null;
  verificationError?: ResultCodes;
  activation: (email: string, language: Language) => Promise<FetchResult>;
}

export const Context = createContext<VerificationController>({
  isLoading: false,
  activation: async () => ({}),
});

Context.displayName = "VerificationContext";

const VerificationProvider: FC = ({ children }) => {
  const [activate, { loading, data, error }] = useLazyQuery<{
    activate: VerificationData;
  }>(VerificationService.activation(), { fetchPolicy: "no-cache" });

  const handleActivation = async (email: string, language: Language) =>
    activate({ variables: { verificationData: { email, language } } });

  return (
    <Context.Provider
      value={{
        isLoading: loading,
        verificationData: data?.activate,
        verificationError: (error?.graphQLErrors[0].extensions
          ?.exception as ErrorBody)?.code as ResultCodes,
        activation: handleActivation,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default VerificationProvider;
