import { MultipleInsurancesSelector } from "@app/components";
import { Form } from "@natera/platform/lib/components";
import { FormField } from "@natera/platform/lib/components/form/field";
import { Button } from "@natera/platform/lib/components/form";
import { defineMessages, useIntl } from "react-intl";
import React from "react";
import { MultipleInsurance } from "@app/components/ui/inputs/multipleInsurancesSelector/multipleInsurancesSelector";

import "./multipleInsurancesForm.scss";

const messages = defineMessages({
  multipleInsurancesFormButtonNext: {
    id: "multipleInsurancesFormButtonNext",
    defaultMessage: "Next",
  },
  multipleInsurancesFormButtonBack: {
    id: "multipleInsurancesFormButtonBack",
    defaultMessage: "Back",
  },
});

type Props = {
  selectedInsurance?: MultipleInsurance;
  onSelect: (insurance: MultipleInsurance) => void;
  onSubmit: (insurance: MultipleInsurance) => void;
  goBack: () => void;
  label: string;
};

const MultipleInsurancesForm: React.FC<Props> = ({
  selectedInsurance,
  onSelect,
  onSubmit,
  goBack,
  label,
}) => {
  const intl = useIntl();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!selectedInsurance) return;

    onSubmit(selectedInsurance);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      buttons={
        <>
          <Button raised type="submit" disabled={!selectedInsurance}>
            {intl.formatMessage(messages.multipleInsurancesFormButtonNext)}
          </Button>
          <Button onClick={goBack}>
            {intl.formatMessage(messages.multipleInsurancesFormButtonBack)}
          </Button>
        </>
      }
      className="multiple-insurance-form"
    >
      <FormField label={label}>
        <MultipleInsurancesSelector
          value={selectedInsurance}
          onChange={onSelect}
        />
      </FormField>
    </Form>
  );
};

export default MultipleInsurancesForm;
