import PdfView from "@app/components/pdf/pdfView";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { LoadingContext } from "@natera/platform/lib/components";

import { routes } from "@app/routing";
import { TestResultContext } from "@app/provider";
import { BlueHeaderContent } from "@app/components/ui/layout";

const messages = defineMessages({
  supplementalPdfMoreAboutResult: {
    id: "supplementalPdfMoreAboutResult",
    defaultMessage: "More about your result",
  },
  testResultCloseResults: {
    id: "testResultCloseResults",
    defaultMessage: "Close",
  },
});

const SupplementalPdf: React.FC = () => {
  const intl = useIntl();
  const history = useHistory<string | undefined>();

  const { orderUid, testUid, uid } = useParams<{
    orderUid: string;
    testUid: string;
    uid: string;
  }>();
  const [url, setUrl] = React.useState<string>("");

  const {
    getSupplementalMaterials,
    getSupplementMaterialIsLoading,
  } = React.useContext(TestResultContext);

  const getUrl = async () => {
    const material = await getSupplementalMaterials(orderUid, testUid, uid);

    if (material && material.url) {
      setUrl(material.url);
    }
  };

  React.useEffect(() => {
    getUrl();
  }, []);

  return (
    <div className="test-result">
      <BlueHeaderContent
        blueHeaderTitle={
          history.location?.state ||
          intl.formatMessage(messages.supplementalPdfMoreAboutResult)
        }
        crossHeaderRoute={routes.testResultDetailsPage(orderUid, testUid)}
        fullScreen
      >
        <LoadingContext isLoading={getSupplementMaterialIsLoading}>
          <PdfView
            url={url}
            fileName={intl.formatMessage(
              messages.supplementalPdfMoreAboutResult
            )}
            downloadUrl={`/result/supplemental/${orderUid}/${testUid}/${uid}`}
          />
        </LoadingContext>
      </BlueHeaderContent>
    </div>
  );
};

export default SupplementalPdf;
