import { DocumentNode, gql } from "@apollo/client";

class TestResultService {
  public static getTestResult = (): DocumentNode => gql`
    query GetTestResult($orderUid: String!, $testUid: String!) {
      getTestResult(orderUid: $orderUid, testUid: $testUid) {
        orderUid
        testUid
        testType
        organ
        documents {
          uid
          documentName
          contentType
        }
        reportGender
        caPnsSupplemental
        resultCode
        fetalSex
        releasedToPatientDate
        latestResultDocumentUid
        resultDocuments {
          releasedToProviderDate
          releasedToPatientDate
          resultDate
          providerNotes
          document {
            uid
            url
            documentName
            documentType
            contentType
            content
            documentReferenceId
          }
        }
        businessUnit
      }
    }
  `;

  public static getTestResultInfoFromToken = (): DocumentNode => gql`
    query GetTestResultInfoFromToken($token: String!) {
      getTestResultInfoFromToken(token: $token) {
        orderUid
        testUid
      }
    }
  `;

  public static getSupplementMaterial = (): DocumentNode => gql`
    query GetSupplementalMaterial(
      $orderUid: String!
      $testUid: String!
      $documentUid: String!
    ) {
      getSupplementalMaterial(
        orderUid: $orderUid
        testUid: $testUid
        documentUid: $documentUid
      ) {
        uid
        documentName
        url
      }
    }
  `;

  public static AccessTestResults = (): DocumentNode => gql`
    mutation AccessTestResults($accessTestResults: AccessTestResultsDTO!) {
      accessTestResults(accessTestResults: $accessTestResults) {
        orderUid
        success
      }
    }
  `;
}

export default TestResultService;
