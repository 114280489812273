const url_pattern = /(https?:\/\/)?(www\.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/i;
const email_pattern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
const example_phone_number = /555\s555\s5555/gi;
const us_phone_number = /(1\s?)?[(]?\d{3}[)]?[(\s)?-]\d{3}[\s-]\d{4}/gi;

export const wrapTextAddLinks = (
  text: string,
  externalLinkIconAriaLabel: string,
  isEmpower = false,
  isLoadingIframeData = false
): string => {
  text = text.replace(/[\r\n]+/g, "\n");
  if (!isEmpower) text = findPhoneLink(text, isLoadingIframeData);
  if (isLoadingIframeData)
    text = text.replace(/tabindex="0"/gm, 'tabindex="-1"');
  text = findUrlLink(text, externalLinkIconAriaLabel);
  return text;
};

const isEmail = (value: string) => email_pattern.test(value);
const isUrl = (value: string) => url_pattern.test(value);
const isExamplePhoneNumber = (value: string) =>
  example_phone_number.test(value);
const isPhoneNumber = (value: string) => us_phone_number.test(value);

const findPhoneLink = (text: string, isLoadingIframeData: boolean) => {
  if (isExamplePhoneNumber(text) || !isPhoneNumber(text)) {
    return text;
  }

  return text.replace(
    us_phone_number,
    (url) =>
      `<a class="link" tabindex=${
        isLoadingIframeData ? -1 : 0
      } href="tel:${url}">${url}</a>`
  );
};

export const ExternalLinkIcon = (externalLinkIconAriaLabel: string) =>
  `<svg role="img" aria-label="${externalLinkIconAriaLabel}" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.1442 7.80378L6.81147 17.1269C6.673 17.2653 6.49897 17.333 6.28937 17.3298C6.07975 17.3266 5.90572 17.2557 5.76727 17.1173C5.6288 16.9788 5.55957 16.8064 5.55957 16.6C5.55957 16.3936 5.6288 16.2211 5.76727 16.0827L15.0904 6.74995H6.89417C6.68167 6.74995 6.50355 6.67805 6.3598 6.53425C6.21606 6.39043 6.1442 6.21223 6.1442 5.99965C6.1442 5.78705 6.21606 5.60896 6.3598 5.46538C6.50355 5.32179 6.68167 5.25 6.89417 5.25H16.7403C16.9964 5.25 17.2111 5.33662 17.3843 5.50985C17.5575 5.68308 17.6441 5.89774 17.6441 6.15383V16C17.6441 16.2125 17.5722 16.3906 17.4284 16.5343C17.2846 16.6781 17.1064 16.75 16.8938 16.75C16.6812 16.75 16.5032 16.6781 16.3596 16.5343C16.216 16.3906 16.1442 16.2125 16.1442 16V7.80378Z" fill="#0377C4" fill-opacity="0.87"/>
  </svg>`;

const findUrlLink = (text: string, externalLinkIconAriaLabel: string) => {
  const textArr = text.split(" ");
  textArr.forEach((word, index) => {
    if (!isEmail(word) && isUrl(word) && !word.includes("data-href")) {
      word = word.replace(
        url_pattern,
        (url) =>
          `<span class="linkCreated">${url}${ExternalLinkIcon(
            externalLinkIconAriaLabel
          )}</span>`
      );
      textArr[index] = word;
    }
  });
  return textArr.join(" ");
};
