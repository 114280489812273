import React, { useState } from "react";
import { Form } from "@natera/platform/lib/components/form";
import { FormField } from "@app/components";
import { useErrorController } from "@natera/platform/lib/hooks";
import { defineMessages, useIntl } from "react-intl";
import { isValidZip } from "@app/utils";
import Tooltip from "@natera/material/lib/tooltip";
import { HeapPHIDecorator } from "@app/components/analytics";
import { Textfield } from "@natera/material/lib/textfield";
import { ErrorContext } from "@app/provider";
import { IntlMessage } from "@app/provider/types";

import "./zipSelectorForm.scss";

const messages: IntlMessage = defineMessages({
  labCollectionZipSelectorPlaceholder: {
    id: "labCollectionZipSelectorPlaceholder",
    defaultMessage: "Enter Your Zip Code",
  },
  labCollectionZipSelectorTooltip: {
    id: "labCollectionZipSelectorTooltip",
    defaultMessage:
      "Required format: 5-digit number, or 9-digit number with a dash after the 5th digit XXXXX or XXXXX-XXXX",
  },
  labCollectionZipSelectorZipCodeLabel: {
    id: "labCollectionZipSelectorZipCodeLabel",
    defaultMessage: "Zip Code",
  },
  labCollectionZipSelectorInvalidZipCode: {
    id: "labCollectionZipSelectorInvalidZipCode",
    defaultMessage: "Please enter a valid zip code",
  },
  labCollectionZipSelectorNoLabs: {
    id: "labCollectionZipSelectorNoLabs",
    defaultMessage: "No labs found in the specified location.",
  },
});

enum ValidationErrors {
  INVALID_ZIP_CODE = "INVALID_ZIP_CODE",
}

type Props = {
  onSubmit: (zipCode: string) => void;
  actions: React.ReactNode;
};

const ZipSelectorForm: React.FC<Props> = ({ onSubmit, actions }) => {
  const intl = useIntl();
  const errorController = useErrorController();
  const [zipCode, setZipCode] = React.useState("");

  const [zipValidationErrorKey, setZipValidationErrorKey] = useState<
    string | null
  >(null);

  React.useEffect(() => {
    if (zipValidationErrorKey) {
      errorController.setValidationError(
        ValidationErrors.INVALID_ZIP_CODE,
        intl.formatMessage(messages[zipValidationErrorKey])
      );
    }
  }, [intl.locale]);

  const { getErrorMessage, clearErrors } = React.useContext(ErrorContext);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    errorController.clearValidationError(ValidationErrors.INVALID_ZIP_CODE);
    setZipValidationErrorKey(null);
    setZipCode(event.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    errorController.clearErrors();
    setZipValidationErrorKey(null);

    clearErrors();

    if (isValidZip(zipCode)) {
      onSubmit(zipCode);
    } else {
      errorController.setValidationError(
        ValidationErrors.INVALID_ZIP_CODE,
        intl.formatMessage(messages.labCollectionZipSelectorInvalidZipCode)
      );
      setZipValidationErrorKey("labCollectionZipSelectorInvalidZipCode");
    }
  };

  console.log(getErrorMessage());

  return (
    <Form noValidate onSubmit={handleSubmit} buttons={actions}>
      <FormField
        error={
          getErrorMessage() ||
          errorController.getValidationError(ValidationErrors.INVALID_ZIP_CODE)
        }
        fullWidth={true}
        label={intl.formatMessage(
          messages.labCollectionZipSelectorZipCodeLabel
        )}
        htmlFor="zip"
        className="zipSelectorForm"
        withPadding
      >
        <Tooltip
          content={intl.formatMessage(messages.labCollectionZipSelectorTooltip)}
        >
          <div>
            <HeapPHIDecorator protectAttr={["value"]} inline>
              <Textfield
                className="zip-selector__form__input"
                placeholder={intl.formatMessage(
                  messages.labCollectionZipSelectorPlaceholder
                )}
                id="zip"
                name="zip"
                outline
                value={zipCode}
                onChange={onChange}
                invalid={
                  !!errorController.getValidationError(
                    ValidationErrors.INVALID_ZIP_CODE
                  )
                }
                formNoValidate
              />
            </HeapPHIDecorator>
          </div>
        </Tooltip>
      </FormField>
    </Form>
  );
};

export default ZipSelectorForm;
