import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";

import { GridRow, GridCell } from "@natera/material/lib/layout";
import { Link } from "@natera/platform/lib/components";
import { ConfigContext, ProfileContext, UserContext } from "@app/provider";

import LinkArrowIcon from "@assets/svg/icons/link-arrow.svg";
import ContactIcon from "@assets/svg/icons/contact.svg";
import MessageIcon from "@assets/svg/icons/message.svg";
import AccountIcon from "@assets/svg/icons/account.svg";
import LockIcon from "@assets/svg/icons/lock.svg";
import { routes } from "@app/routing";
import "./profile.scss";

import ProfileDeleteDialog from "./profileDeleteDialog";
import { useDialog } from "@natera/platform/lib/hooks";
import { Button } from "@natera/platform/lib/components/form";
import { DetailInformationButton, SpinnerView } from "@app/components";
import { getFormattedDOB } from "@app/utils";

const messages = defineMessages({
  profileBirthDay: {
    id: "profileBirthDay",
    defaultMessage: "Date of Birth: {birthday}",
  },
  profileDetailsLink: {
    id: "profileDetailsLink",
    defaultMessage: "Personal Information",
  },
  profileContactLink: {
    id: "profileContactLink",
    defaultMessage: "Contact Details",
  },
  profileSecurityLink: {
    id: "profileSecurityLink",
    defaultMessage: "Security Settings",
  },
  profileCommunicationLink: {
    id: "profileCommunicationLink",
    defaultMessage: "Communication Preferences",
  },
  profileTerms: {
    id: "profileTerms",
    defaultMessage: "Terms of Use",
  },
  profilePrivacyPolicy: {
    id: "profilePrivacyPolicy",
    defaultMessage: "Privacy Policy",
  },
  profileLicenses: {
    id: "profileLicenses",
    defaultMessage: "Licenses",
  },
  profileNoticeOfPrivacyPractices: {
    id: "profileNoticeOfPrivacyPractices",
    defaultMessage: "Notice of Privacy Practices",
  },
  profileDeleteAccount: {
    id: "profileDeleteAccount",
    defaultMessage: "Delete Account",
  },
});

const Profile: FC = () => {
  const intl = useIntl();
  const profileDeleteDialog = useDialog(ProfileDeleteDialog);
  const {
    getProfileData: profile,
    getProfile,
    getProfileIsLoading,
  } = useContext(ProfileContext);
  const { uppUser, isLoading } = useContext(UserContext);
  const { config } = useContext(ConfigContext);

  React.useEffect(() => {
    getProfile();
  }, []);

  if (!profile || !uppUser) {
    return null;
  }

  const { firstName, lastName, dateOfBirth } = profile;
  const { email } = uppUser;

  const handleOpenDeleteDialog = () => {
    profileDeleteDialog.open({ onClose: handleCloseDeleteDialog });
  };

  const handleCloseDeleteDialog = () => {
    profileDeleteDialog.close();
  };

  return (
    <div className="profile">
      <SpinnerView isLoading={getProfileIsLoading || isLoading} />
      {profileDeleteDialog.getDialog()}
      <GridRow className="profile__patient">
        <GridCell span={12}>
          <div className="patient-card">
            <HeapPHIDecorator protectText>
              <div className="patient-card__name">
                {`${firstName?.[0]}${lastName?.[0]}`}
              </div>
            </HeapPHIDecorator>

            <div className="patient-card__info">
              <HeapPHIDecorator protectText inline>
                <h3>
                  {firstName} {lastName}
                </h3>
              </HeapPHIDecorator>

              <div className="patient-card__info-wrp">
                <HeapPHIDecorator protectText inline>
                  <p>
                    {email}
                    <span className="patient-card__info-bullet">&#8226;</span>
                  </p>
                </HeapPHIDecorator>
                <HeapPHIDecorator protectText inline>
                  <p>
                    {intl.formatMessage(messages.profileBirthDay, {
                      birthday: getFormattedDOB(dateOfBirth),
                    })}
                  </p>
                </HeapPHIDecorator>
              </div>
            </div>
          </div>
        </GridCell>
      </GridRow>

      <GridRow className="profile__routes">
        <GridCell className="profile__routes__link" span={12}>
          <DetailInformationButton
            route={routes.personalInformation}
            name={intl.formatMessage(messages.profileDetailsLink)}
            startIcon={AccountIcon}
            endIcon={LinkArrowIcon}
          />
        </GridCell>

        <GridCell className="profile__routes__link" span={12}>
          <DetailInformationButton
            route={routes.contactDetails}
            name={intl.formatMessage(messages.profileContactLink)}
            startIcon={ContactIcon}
            endIcon={LinkArrowIcon}
          />
        </GridCell>

        <GridCell className="profile__routes__link" span={12}>
          <DetailInformationButton
            route={routes.securitySettings}
            name={intl.formatMessage(messages.profileSecurityLink)}
            startIcon={LockIcon}
            endIcon={LinkArrowIcon}
          />
        </GridCell>

        <GridCell className="profile__routes__link" span={12}>
          <DetailInformationButton
            route={routes.communicationPreferences}
            name={intl.formatMessage(messages.profileCommunicationLink)}
            startIcon={MessageIcon}
            endIcon={LinkArrowIcon}
          />
        </GridCell>
      </GridRow>

      <div className="profile__separator" />

      <GridRow className="profile__footer">
        <GridCell span={12}>
          <Link to={{ pathname: config.links.TERMS }} target="_blank">
            {intl.formatMessage(messages.profileTerms)}
          </Link>
        </GridCell>

        <GridCell span={12}>
          <Link to={{ pathname: config.links.PRIVACY_POLICY }} target="_blank">
            {intl.formatMessage(messages.profilePrivacyPolicy)}
          </Link>
        </GridCell>

        <GridCell span={12}>
          <Link to={{ pathname: config.links.LICENSES }} target="_blank">
            {intl.formatMessage(messages.profileLicenses)}
          </Link>
        </GridCell>

        <GridCell span={12}>
          <Link
            to={{ pathname: config.links.NOTICE_OF_PRIVACY_PRACTICES }}
            target="_blank"
          >
            {intl.formatMessage(messages.profileNoticeOfPrivacyPractices)}
          </Link>
        </GridCell>

        <GridCell span={12}>
          <Button
            disabled
            onClick={handleOpenDeleteDialog}
            className="profile__footer__open-delete-dialog"
          >
            {intl.formatMessage(messages.profileDeleteAccount)}
          </Button>
        </GridCell>
      </GridRow>
    </div>
  );
};
export default Profile;
