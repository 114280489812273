import React, { FC } from "react";
import { StepperContext } from "@natera/stepper";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { defineMessages, useIntl } from "react-intl";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { Steps } from "../../simpleOrderStepper";
import { Order, OrderWorkflowType } from "@app/provider/order";
import { ManagedBy, SampleType } from "@app/provider/testData/types";
import { routes } from "@app/routing";
import { useHistory } from "react-router-dom";
import {
  PartnerInfoStepResolve,
  PatientAcknowledgmentStepResolve,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import SignatureForm, {
  SignatureFormType,
} from "@app/components/simpleOrder/signature/signatureForm";
import { Address, ProfileContext, SimpleOrderContext } from "@app/provider";
import { getTestNameForHeap } from "@app/utils";
import { SimpleOrderCompleteDto } from "@app/provider/simpleOrder";
import { PaymentInfo } from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { getPaymentInfoDto } from "@app/utils";
import { FetalSexSelection } from "@app/components/fetalSexInResults/fetalSexInResults";
import { Notification } from "@app/components";
import {
  getSampleCollectionWorkflow,
  getSimpleOrderWorkflow,
} from "@app/utils/orderHelpers";
import "@app/components/simpleOrder/signature/signature.scss";

const messages = defineMessages({
  patientSignatureTitle: {
    id: "patientSignatureTitle",
    defaultMessage: "Sign and agree to proceed with test",
  },
  patientSignatureText_1: {
    id: "patientSignatureText_1",
    defaultMessage:
      "An authorized representative cannot agree and sign on behalf of the patient.",
  },
  patientSignatureText_2: {
    id: "patientSignatureText_2",
    defaultMessage:
      "By providing my first name and last name, I acknowledge that I am electronically signing.",
  },
  patientSignatureNotificationTitle: {
    id: "patientSignatureNotificationTitle",
    defaultMessage: "Something went wrong",
  },
  patientSignatureNotificationMessage: {
    id: "patientSignatureNotificationMessage",
    defaultMessage: "Please try submitting your signature again.",
  },
});

interface PatientSignatureProps {
  orderData: Order;
  goBack: () => void;
}

const PatientSignature: FC<PatientSignatureProps> = ({ orderData, goBack }) => {
  const intl = useIntl();
  const history = useHistory();

  const { profileData } = React.useContext(ProfileContext);
  const { getData } = React.useContext(StepperContext);
  const { getSubStepData } = React.useContext(SimpleOrderStepperContext);
  const {
    completeSimpleOrder,
    completeSimpleOrderLoading,
    failedCompleteAttempts,
    setFailedCompleteAttempts,
  } = React.useContext(SimpleOrderContext);

  const limsClinicId = orderData?.clinic?.limsId?.toString();
  const testNameForHeap = getTestNameForHeap(orderData);

  const getSimpleOrderInfoDto = (patientSignature: SignatureFormType) => {
    const kitShippingDetails = getData(Steps.KIT_SHIPPING_DETAILS)
      .address as Address;
    const panoramaFetalSex = getData(Steps.FETAL_SEX)
      ?.reportGender as FetalSexSelection;
    const partnerInfo = getData(Steps.PARTNER_INFO) as PartnerInfoStepResolve;
    const paymentInfo = getData(Steps.PAYMENT_METHOD) as PaymentInfo;
    const patientAcknowlegementInfo = getSubStepData(
      Steps.ACKNOWLEDGMENT
    ) as PatientAcknowledgmentStepResolve;

    const simpleOrderCompleteDto: SimpleOrderCompleteDto = {
      shippingAddress: {
        city: kitShippingDetails.city,
        state: kitShippingDetails.state,
        street1: kitShippingDetails.street1,
        street2: kitShippingDetails.street2,
        zipCode: kitShippingDetails.zipCode,
      },
      reportGender: getReportGender(panoramaFetalSex),
      partnerInfo,
      payment: getPaymentInfoDto(paymentInfo),
      patientAcknowledgment: {
        ...patientAcknowlegementInfo,
        consentTimestamp: new Date().toISOString(),
        signature: patientSignature,
      },
    };

    return simpleOrderCompleteDto;
  };

  const getReportGender = (panoramaFetalSex?: FetalSexSelection) => {
    if (panoramaFetalSex) {
      return panoramaFetalSex === FetalSexSelection.INCLUDE;
    }

    return panoramaFetalSex;
  };

  const handleSubmit = async (patientSignature: SignatureFormType) => {
    try {
      const simpleOrderWorkflow = getSimpleOrderWorkflow(orderData?.workflows);

      if (!simpleOrderWorkflow) {
        throw new Error(
          `Can't find workflow with type: ${OrderWorkflowType.SIMPLE_ORDER} for orderUid: ${orderData?.uid}`
        );
      }

      const simpleOrderInfoDto = getSimpleOrderInfoDto(patientSignature);

      await completeSimpleOrder(
        simpleOrderWorkflow.uid,
        simpleOrderInfoDto,
        testNameForHeap,
        limsClinicId
      );

      if (
        orderData.items?.[0]?.product?.configuration
          ?.sampleCollectionManagedBy === ManagedBy.NATERA &&
        getSampleCollectionWorkflow(orderData.workflows)?.sampleType ===
          SampleType.BLOOD
      ) {
        history.push(
          routes.sampleDrawPage(orderData.uid, orderData.items[0]?.uid),
          {
            fromSimpleOrderFlow: true,
          }
        );
      } else {
        history.push(routes.home);
      }
    } catch (error) {
      setFailedCompleteAttempts((attempt) => attempt + 1);
    }
  };

  return (
    <>
      {Boolean(failedCompleteAttempts) && (
        <Notification
          className="patient-signature-notification__container"
          type="warning"
          titleElem={intl.formatMessage(
            messages.patientSignatureNotificationTitle
          )}
        >
          {intl.formatMessage(messages.patientSignatureNotificationMessage)}
        </Notification>
      )}
      <StepperHeaderProgress />
      <div className="signature">
        <StepperContent
          title={intl.formatMessage(messages.patientSignatureTitle)}
          description={
            <>
              <span>{intl.formatMessage(messages.patientSignatureText_1)}</span>
              <span>{intl.formatMessage(messages.patientSignatureText_2)}</span>
            </>
          }
        >
          <SignatureForm
            onSubmit={handleSubmit}
            loading={completeSimpleOrderLoading}
            goBack={goBack}
            profile={profileData}
            isPatientSignature
          />
        </StepperContent>
      </div>
    </>
  );
};

export default PatientSignature;
