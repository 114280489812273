import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { TestResult } from "@app/provider/testResult";

import { TestType, TestCardStatus } from "@app/provider/testData/types";
import { routes } from "@app/routing";
import SubCard from "@app/components/subCard/subCard";
import { isNeedPreTestInfo } from "@app/provider/viewResult";
import { SupplementalType } from "@app/components/supplementButton/supplementalButton";
import Documents from "@assets/svg/documents.svg";
import ViewVideo from "@assets/svg/view-video.svg";
import "./testResultDetails.scss";

const messages = defineMessages({
  rewatchVideo: {
    id: "rewatchVideo",
    defaultMessage: "Re-watch video",
  },
  testCardPostTestInfo: {
    id: "testCardPostTestInfo",
    defaultMessage: "Pre-results information",
  },
});

interface HistoryWithState {
  from?: string;
}

interface Props {
  testResult: TestResult;
}

const PreTestInfo: FC<Props> = ({ testResult }) => {
  const intl = useIntl();
  const history = useHistory<HistoryWithState | undefined>();

  const onClickPreTestInfo = () => {
    if (isNeedPreTestInfo(testResult.testType)) {
      const testWithOrganRoute = testResult.organ
        ? `?organType=${testResult.organ}`
        : "";
      const understandingYourResultsRoute = `${routes.understandingYourResultsPage(
        testResult.orderUid,
        testResult.testUid,
        TestCardStatus.TEST_COMPLETE,
        testResult.latestResultDocumentUid ?? ""
      )}${testWithOrganRoute}`;

      history.push(understandingYourResultsRoute);
    }
  };

  const supplementalVideo = testResult.documents?.filter(
    (doc) => doc.contentType === SupplementalType.VIDEO
  );

  const testTypesWithEducationalVideo = [TestType.RENASIGHT, TestType.PROSPERA];

  const displayRewatchVideo = Boolean(
    testTypesWithEducationalVideo.includes(testResult.testType) &&
      supplementalVideo?.length
  );

  const preTestInfoIcon = displayRewatchVideo ? ViewVideo : Documents;
  const preTestInfoTitle = displayRewatchVideo
    ? messages.rewatchVideo
    : messages.testCardPostTestInfo;

  return isNeedPreTestInfo(testResult.testType) ? (
    <SubCard
      onClick={onClickPreTestInfo}
      icon={preTestInfoIcon}
      title={intl.formatMessage(preTestInfoTitle)}
    />
  ) : (
    <></>
  );
};

export default PreTestInfo;
