import { PaymentType } from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { IntlShape, defineMessages } from "react-intl";

const messages = defineMessages({
  paymentMethodSelectionLabelCommercial: {
    id: "paymentMethodSelectionLabelCommercial",
    defaultMessage: "I have a commercial health insurance",
  },
  paymentMethodSelectionLabelGovernment: {
    id: "paymentMethodSelectionLabelGovernment",
    defaultMessage: "I have Medicaid or government issued insurance",
  },
  paymentMethodSelectionLabelMultiple: {
    id: "paymentMethodSelectionLabelMultiple",
    defaultMessage: "I have more than one form of health insurance",
  },
  paymentMethodSelectionLabelDontHave: {
    id: "paymentMethodSelectionLabelDontHave",
    defaultMessage: "I don't have health insurance",
  },
  paymentMethodSelectionLabelDontWantToBill: {
    id: "paymentMethodSelectionLabelDontWantToBill",
    defaultMessage: "I don't want to bill my insurance",
  },
});

export const getPaymentMethodLabel = (
  type: PaymentType,
  intl: IntlShape
): string => {
  const getLabel = {
    [PaymentType.COMMERCIAL]: intl.formatMessage(
      messages.paymentMethodSelectionLabelCommercial
    ),
    [PaymentType.GOVERNMENT_ISSUED]: intl.formatMessage(
      messages.paymentMethodSelectionLabelGovernment
    ),
    [PaymentType.MULTIPLE]: intl.formatMessage(
      messages.paymentMethodSelectionLabelMultiple
    ),
    [PaymentType.DO_NOT_HAVE]: intl.formatMessage(
      messages.paymentMethodSelectionLabelDontHave
    ),
    [PaymentType.SELF_PAY]: intl.formatMessage(
      messages.paymentMethodSelectionLabelDontWantToBill
    ),
  };

  return getLabel[type];
};
