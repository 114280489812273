import { ShippingAddress } from "@app/provider/types";

export const showAddressSection = (
  shippingAddress?: ShippingAddress
): boolean =>
  Boolean(
    shippingAddress &&
      (shippingAddress?.state ||
        shippingAddress?.city ||
        shippingAddress?.street ||
        shippingAddress?.zipCode)
  );
