import Button from "@natera/material/lib/button";
import Dialog from "@natera/material/lib/dialog";
import React, { useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useTimers, useEventListeners } from "@app/hooks";
import "./idle.scss";

const messages = defineMessages({
  idleDialogStillThere: {
    id: "idleDialogStillThere",
    defaultMessage: "Are you still there?",
  },
  idleDialogSessionOut: {
    id: "idleDialogSessionOut",
    defaultMessage:
      "Your session will time out soon. Do you wish to stay logged in?",
  },
  idleDialogLogOut: {
    id: "idleDialogLogOut",
    defaultMessage: "Log out",
  },
  idleDialogStay: {
    id: "idleDialogStay",
    defaultMessage: "Stay",
  },
});

interface IdleDialogI {
  loading: boolean;
  handleLogout: () => void;
  handleCloseDialog: () => void;
  openedDialogDelay: number;
}

const IdleDialog: React.FC<IdleDialogI> = ({
  loading,
  handleLogout,
  handleCloseDialog,
  openedDialogDelay,
}) => {
  const intl = useIntl();
  const { setTimer: addTimers, clearTimer: removeTimers } = useTimers(
    handleLogout,
    openedDialogDelay
  );

  const resetTimers = useCallback(() => {
    removeTimers();
    addTimers();
  }, []);

  useEventListeners(
    resetTimers,
    ["mousemove", "mousedown", "keydown", "touchstart", "nevaLogEvent"],
    window,
    { passive: true }
  );

  return (
    <Dialog
      className="idle-dialog"
      title={intl.formatMessage(messages.idleDialogStillThere)}
      onClose={handleCloseDialog}
      actions={
        <>
          <Button
            className="idle-dialog__logoutButton"
            outlined
            onClick={handleLogout}
            loading={loading}
          >
            {intl.formatMessage(messages.idleDialogLogOut)}
          </Button>
          <Button onClick={handleCloseDialog} raised>
            {intl.formatMessage(messages.idleDialogStay)}
          </Button>
        </>
      }
      showCrossButton
    >
      <span>{intl.formatMessage(messages.idleDialogSessionOut)}</span>
    </Dialog>
  );
};

export default IdleDialog;
