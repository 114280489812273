import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";

import Button from "@natera/material/lib/button";
import Dialog from "@natera/material/lib/dialog";
import { UserContext } from "@app/provider";
import { ResultCodes } from "@app/service/resultCodes";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";

const messages = defineMessages({
  profileDialogTitle: {
    id: "profileDialogTitle",
    defaultMessage: "Are you sure you want to delete your account?",
  },
  profileDialogBodyText: {
    id: "profileDialogBodyText",
    defaultMessage:
      "Deleting your account will permanently delete all your account information. This action cannot be undone.",
  },
  profileDialogCancelButton: {
    id: "profileDialogCancelButton",
    defaultMessage: "Cancel",
  },
  profileDialogDeleteButton: {
    id: "profileDialogDeleteButton",
    defaultMessage: "Delete",
  },
});

interface ProfileDeleteDialogProps {
  onClose: () => void;
}

const ProfileDeleteDialog: React.FC<ProfileDeleteDialogProps> = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const { onClose } = props;

  const {
    isLoading,
    deleteUser,
    deletedUserData,
    uppUser,
    loadProfile,
  } = React.useContext(UserContext);

  const handleDeleteUser = async () => {
    if (uppUser?.email) {
      await deleteUser(uppUser?.email);
    }
  };

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    if (deletedUserData?.success) {
      history.push(`${routes.logOut}?code=${ResultCodes.USER_IS_DELETED}`);
    }
  }, [deletedUserData]);

  return (
    <Dialog
      className="profile-delete-dialog"
      title={<span>{intl.formatMessage(messages.profileDialogTitle)}</span>}
      onClose={onClose}
      actions={
        <>
          <Button
            onClick={handleDeleteUser}
            raised={true}
            className="profile-delete-dialog__delete-button"
            loading={isLoading}
          >
            {intl.formatMessage(messages.profileDialogDeleteButton)}
          </Button>
          <Button onClick={onClose} outlined>
            {intl.formatMessage(messages.profileDialogCancelButton)}
          </Button>
        </>
      }
    >
      <span>{intl.formatMessage(messages.profileDialogBodyText)}</span>
    </Dialog>
  );
};

export default ProfileDeleteDialog;
