import { DateRange } from "@natera/mobile-phlebotomy/lib";

export const getDisabledSlots = (availableTo: Date): DateRange[] => {
  availableTo.setUTCHours(0, 0, 0, 0);

  let day = availableTo.getUTCDay();
  const disabledSlots: DateRange[] = [];
  const SUNDAY_NUMBER = 6;

  while (day <= SUNDAY_NUMBER) {
    const disabledDate = new Date(availableTo);
    disabledDate.setUTCDate(disabledDate.getUTCDate() + SUNDAY_NUMBER - day);

    disabledSlots.push(
      {
        start: new Date(disabledDate.setUTCHours(9, 0, 0, 0)),
        end: new Date(disabledDate.setUTCHours(12, 0, 0, 0)),
      },
      {
        start: new Date(disabledDate.setUTCHours(12, 0, 0, 0)),
        end: new Date(disabledDate.setUTCHours(15, 0, 0, 0)),
      },
      {
        start: new Date(disabledDate.setUTCHours(15, 0, 0, 0)),
        end: new Date(disabledDate.setUTCHours(18, 0, 0, 0)),
      }
    );

    day++;
  }

  return disabledSlots;
};
