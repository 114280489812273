import React, { FC, useContext, useEffect, useState } from "react";
import { useErrorController } from "@natera/platform/lib/hooks";
import SpinnerView from "../spinnerView";
import { ErrorProvider, MfaErrorContext, ProfileContext } from "@app/provider";
import VerifyCodeForm from "@app/components/verifyCodeForm";
import { FACTORS_TO_SHOW } from "@app/service/user";

interface VerificationPhoneModalProps
  extends React.HTMLAttributes<HTMLDivElement> {
  phoneNumber: string;
}

const VerificationPhoneModal: FC<VerificationPhoneModalProps> = ({
  phoneNumber,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const errorController = useErrorController();
  const {
    sendPhoneNumberVerificationCode,
    updatePatientPhoneNumber,
  } = useContext(ProfileContext);

  const { parseError } = useContext(MfaErrorContext);

  useEffect(() => {
    if (phoneNumber) {
      sendPhoneNumberVerificationCode(phoneNumber);
    }
  }, [phoneNumber]);

  const handleCode = async (code: string) => {
    errorController.clearValidationError("code");

    try {
      setIsLoading(true);
      await updatePatientPhoneNumber(code);
    } catch (error) {
      await parseError(
        error.errorCode || error.code,
        error.errorSummary || error.message,
        errorController
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async () => {
    errorController.clearValidationError("code");
    try {
      setIsLoading(true);
      sendPhoneNumberVerificationCode(phoneNumber);
    } catch (error) {
      await parseError(
        error.errorCode || error.code,
        error.errorSummary || error.message,
        errorController
      );
    } finally {
      setIsLoading(false);
    }
  };

  const profile = {
    phoneNumber,
  };

  return (
    <>
      <SpinnerView isLoading={isLoading} />
      <ErrorProvider controller={errorController}>
        <VerifyCodeForm
          type={FACTORS_TO_SHOW.sms}
          profile={profile}
          handleCode={handleCode}
          handleResend={handleResend}
        />
      </ErrorProvider>
    </>
  );
};

export default VerificationPhoneModal;
