import React, { FC, useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { DateTime } from "luxon";
import Button from "@natera/material/lib/button";
import { Dialog } from "@natera/material/lib/dialog";
import { DayPicker } from "@natera/material/lib/date";
import { DatePickerSettingsType } from "@app/neva/models";
import { IntlContext } from "@app/provider";

interface Props {
  onClose: () => void;
  datePickerSettings: DatePickerSettingsType;
}

const messages = defineMessages({
  selectDateDialogTitle: {
    id: "selectDateDialogTitle",
    defaultMessage: "Select date",
  },
  selectDateDialogTitleBackButton: {
    id: "selectDateDialogTitleBackButton",
    defaultMessage: "Select",
  },
});

const SelectDateDialog: FC<Props> = ({ onClose, datePickerSettings }) => {
  const intl = useIntl();
  const { currentLanguage } = useContext(IntlContext);
  const [buttonTitle, setButtonTitle] = useState(
    intl.formatMessage(messages.selectDateDialogTitleBackButton)
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const defaultMinDate = DateTime.local().minus({ year: 150 }).toJSDate();
  const defaultMaxDate = DateTime.local().plus({ year: 150 }).toJSDate();

  const actions = (
    <Button onClick={onClose} raised disabled={isButtonDisabled}>
      {buttonTitle}
    </Button>
  );

  const onDateChange = (date: Date) => {
    setIsButtonDisabled(false);
    setButtonTitle(
      DateTime.fromJSDate(date).toFormat("ccc, LLL dd, yyyy", {
        locale: currentLanguage,
      })
    );
  };

  return (
    <Dialog
      onClose={onClose}
      showCrossButton
      actions={actions}
      title={intl.formatMessage(messages.selectDateDialogTitle)}
      focusTrapOptions={{ skipInitialFocus: true }}
    >
      <DayPicker
        date={new Date()}
        maxDate={datePickerSettings.maxDate || defaultMaxDate}
        minDate={datePickerSettings.minDate || defaultMinDate}
        onDateChange={onDateChange}
      />
    </Dialog>
  );
};

export default SelectDateDialog;
