import React from "react";
import Svg from "@natera/material/lib/svg";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "@natera/platform/lib/components/form";
import { defineMessages, useIntl } from "react-intl";

import { ServiceContext } from "@app/provider";
import { TestDetailsContext } from "@app/provider/testData";

import CloudError from "@assets/svg/icons/cloud-error.svg";
import { TestStatus } from "@app/provider/testData/types";
import "./pdf.scss";
import { HEAP_EVENTS } from "@app/provider/types";
import { ScrollContext } from "@natera/platform/lib/components/scrollbar";
import { capitalizeFirstLetter } from "@app/utils";
import { businessUnitMapper } from "@app/utils/businessUnitMapper";

// https://github.com/wojtekmaj/react-pdf/issues/97
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const messages = defineMessages({
  pdfResultsDocument: {
    id: "pdfResultsDocument",
    defaultMessage: "Result Details",
  },
  pdfYourResults: {
    id: "pdfyourResults",
    defaultMessage: "Your Results",
  },
  pdfDownload: {
    id: "pdfDownload",
    defaultMessage: "Download",
  },
  pdfLoadError: {
    id: "pdfLoadError",
    defaultMessage: "Sorry, we cannot retrieve results right now.",
  },
  pdfSubLoadError: {
    id: "pdfSubLoadError",
    defaultMessage: "Please try again later.",
  },
  pdfCloseResults: {
    id: "pdfCloseResults",
    defaultMessage: "Close results",
  },
});

enum Occurrence {
  firstTime = "first_time",
  repeat = "repeat",
}

type onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => Promise<void>;
type onPageLoadSuccess = (pdf: pdfjs.PDFPageProxy) => Promise<void>;

type Props = {
  url: string;
  downloadUrl: string;
  fileName: string;
};

const PdfView: React.FC<Props> = ({ url, downloadUrl, fileName }) => {
  const intl = useIntl();
  const { sessionService, fileService } = React.useContext(ServiceContext);
  const { getTestDetails } = React.useContext(TestDetailsContext);
  const [token, setToken] = React.useState<string>("");
  const [isError, setError] = React.useState<boolean>(false);
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [pdfWidth, setPdfWidth] = React.useState<number>();

  const refPdfContainer = React.useRef<HTMLDivElement>(null);

  const testDetails = getTestDetails();

  React.useEffect(() => {
    setError(false);
    sessionService
      .getToken()
      .then((authToken) => {
        if (authToken) {
          setToken(authToken.accessToken);
        }
      })
      .catch(() => setError(true));
  }, [url]);

  React.useEffect(() => {
    if (isError && testDetails) {
      heap.track(HEAP_EVENTS.upp_viewresultpdf_failure, {
        test_name: capitalizeFirstLetter(testDetails.testType),
        lims_clinic_id: testDetails.clinic?.limsId,
        order_uid: testDetails.orderUid,
        occurrence:
          testDetails.viewStatus === TestStatus.VIEWED
            ? Occurrence.repeat
            : Occurrence.firstTime,
        business_unit: businessUnitMapper(testDetails.businessUnit),
        lims_document_id: testDetails.documentReferenceId,
      });
    }
  }, [isError, testDetails]);

  const file = React.useMemo(
    () => ({
      url,
      httpHeaders: {
        Authorization: `Bearer ${token ? token : ""}`,
      },
    }),
    [url, token]
  );

  const onDownload = async () => {
    const result = await fileService.getFile(downloadUrl);
    fileService.download(result, fileName);
  };

  const onDocumentLoadSuccess: onDocumentLoadSuccess = async ({
    numPages: nextNumPages,
  }) => {
    setError(false);
    setNumPages(nextNumPages);

    if (testDetails) {
      heap.track(HEAP_EVENTS.upp_viewresultpdf_success, {
        test_name: capitalizeFirstLetter(testDetails.testType),
        lims_clinic_id: testDetails.clinic?.limsId,
        order_uid: testDetails.orderUid,
        occurrence:
          testDetails.viewStatus === TestStatus.VIEWED
            ? Occurrence.repeat
            : Occurrence.firstTime,
        business_unit: businessUnitMapper(testDetails.businessUnit),
        lims_document_id: testDetails.documentReferenceId,
      });
    }
  };

  const onPageLoadSuccess: onPageLoadSuccess = async (page) => {
    const containerWidth = refPdfContainer.current?.clientWidth;

    if (!containerWidth) {
      return;
    }

    setPdfWidth(containerWidth);
  };

  const onError = () => {
    setError(true);
  };

  return (
    <section className="pdf__container" ref={refPdfContainer}>
      <div className="pdf__container__header">
        {!isError && (
          <div className="pdf__container__header__title">
            <Button
              className="pdf__container__header__download"
              raised
              onClick={onDownload}
            >
              {intl.formatMessage(messages.pdfDownload)}
            </Button>
          </div>
        )}
      </div>
      <ScrollContext
        component="div"
        isStatic={false}
        settings={{ suppressScrollX: true }}
      >
        {!isError && (
          <div>
            <div aria-hidden={true}>
              <Document
                className="pdf__container__header__result-pdf"
                file={file}
                onLoadError={onError}
                onLoadSuccess={onDocumentLoadSuccess}
                onSourceError={onError}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    className="pdf__container__header__result-pdf-page"
                    pageNumber={index + 1}
                    renderTextLayer
                    renderAnnotationLayer
                    width={pdfWidth}
                    onLoadSuccess={onPageLoadSuccess}
                  />
                ))}
              </Document>
            </div>
          </div>
        )}
        {isError && (
          <div className="pdf__container__error">
            <div className="image">
              <Svg content={CloudError} />
            </div>
            <div className="main-text">
              <span>{intl.formatMessage(messages.pdfLoadError)}</span>
            </div>
            <div className="sub-text">
              <span>{intl.formatMessage(messages.pdfSubLoadError)}</span>
            </div>
          </div>
        )}
        <div className="pdf__container__footer">
          {!isError && (
            <Button
              className="pdf__container__footer__download"
              raised
              onClick={onDownload}
            >
              {intl.formatMessage(messages.pdfDownload)}
            </Button>
          )}
        </div>
      </ScrollContext>
    </section>
  );
};

export default PdfView;
