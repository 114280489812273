import { useRef, useCallback, useEffect } from "react";

type TimerCallback = () => void | Promise<void>;

const useTimers = (
  callback: TimerCallback,
  delay: number
): { setTimer: () => void; clearTimer: () => void } => {
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const setTimer = useCallback(() => {
    timer.current = setTimeout(callback, delay); // NOSONAR (typescript:S6544)
  }, [callback, delay]);

  const clearTimer = useCallback(() => {
    clearTimeout(timer.current);
  }, []);

  useEffect(() => {
    setTimer();
    return clearTimer;
  }, [setTimer, clearTimer]);

  return { setTimer, clearTimer };
};

export default useTimers;
