import React from "react";
import { createRoot } from "react-dom/client";
import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-numberformat";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";

import App from "./app";
import "normalize.css";
import "./style.scss";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

root.render(<App />);
