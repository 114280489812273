import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/platform/lib/components/form";

import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import "./drawRequestDialog.scss";

const messages = defineMessages({
  drawRequestBack: {
    id: "drawRequestBack",
    defaultMessage: "Back To Home",
  },
});

interface DrawRequestDialogProps {
  title: string;
  description?: string;
}

const DrawRequestDialog: FC<DrawRequestDialogProps> = ({
  title,
  description,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const handleBack = () => {
    history.push(routes.home);
  };

  return (
    <div className="draw-request-dialog">
      <Dialog
        title={title}
        actions={
          <Button raised onClick={handleBack}>
            {intl.formatMessage(messages.drawRequestBack)}
          </Button>
        }
        onClose={handleBack}
      >
        <div className="draw-request-dialog__content">{description}</div>
      </Dialog>
    </div>
  );
};

export default DrawRequestDialog;
