import React, { FC, createContext } from "react";
import { useLazyQuery } from "@apollo/client";

import LanguageService from "@app/service/language";

interface Language {
  code: string;
  description: string;
}

export interface LanguageController {
  isLoading: boolean;
  loadLanguages: () => void;
  getLanguages: () => Language[];
}

export const Context = createContext<LanguageController>({
  isLoading: false,
  loadLanguages: () => Promise.reject(),
  getLanguages: () => [],
});

Context.displayName = "LanguageContext";

const LanguageProvider: FC = ({ children }) => {
  const [getLanguagesQuery, { data, loading: isLoading }] = useLazyQuery(
    LanguageService.getLanguages(),
    {
      fetchPolicy: "no-cache",
    }
  );

  const loadLanguages = () => {
    getLanguagesQuery({
      variables: {},
    });
  };

  const getLanguages = () => {
    return data?.languages || [];
  };

  return (
    <Context.Provider
      value={{
        isLoading: isLoading,
        loadLanguages,
        getLanguages,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default LanguageProvider;
