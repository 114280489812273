import React, { useContext, useEffect } from "react";
import { StepperContext } from "@natera/stepper";
import { SubSteps } from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import SelectInsuranceCompanyForm from "@app/components/forms/selectInsuranceCompanyForm";
import { EnterOtherInsuranceCompanyStep } from "../enterOtherInsuranceCompanyStep";
import { InsuranceCostEstimationStep } from "../insuranceCostEstimationStep";
import { Payor } from "@app/provider/payor";
import {
  PredefinedCompanies,
  PredefinedCompanyName,
} from "@app/components/companySelector/companySelector";
import {
  MultiplePaymentMethodType,
  PaymentMethodInfoType,
  PaymentMethodType,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import { Steps } from "@app/pages/private/simpleOrder";
import { isFirstMultipleStep, isSecondMultipleStep } from "@app/utils";

export type GetProvideInsuranceStep = (
  onConfirm?: (data: unknown) => void
) => React.ReactChild;

type Props = {
  companies: Payor[];
  otherSubstepId: SubSteps;
  insuranceSubstepId: SubSteps;
  getProvideInsuranceStep: GetProvideInsuranceStep;
  onConfirm: (data: unknown) => void;
};

const SelectInsuranceCompany: React.FC<Props> = ({
  companies,
  otherSubstepId,
  insuranceSubstepId,
  getProvideInsuranceStep,
  onConfirm,
}) => {
  const {
    goBack,
    resolve,
    createStep,
    removeStep,
    getCurrentStep,
  } = React.useContext(StepperContext);

  const { getSubStepData, resolveSubStep } = useContext(
    SimpleOrderStepperContext
  );

  const currentStepIdx = getCurrentStep()?.index;
  const currentStepId = getCurrentStep()?.stepId;

  const isMultipleInsuranceFirstStep = isFirstMultipleStep(currentStepId);
  const isMultipleInsuranceSecondStep = isSecondMultipleStep(currentStepId);

  const subStepsData = getSubStepData(Steps.PAYMENT_METHOD);

  const getSubStepsData = () => {
    if (subStepsData) {
      if (isMultipleInsuranceFirstStep) {
        return (subStepsData as MultiplePaymentMethodType)?.paymentMethodInfo
          ?.first?.paymentMethodInfo;
      } else if (isMultipleInsuranceSecondStep) {
        return (subStepsData as MultiplePaymentMethodType)?.paymentMethodInfo
          ?.second?.paymentMethodInfo;
      } else {
        return (subStepsData as PaymentMethodType).paymentMethodInfo;
      }
    }
  };

  const getInitialCompany = () => {
    const data = getSubStepsData();

    if (!data) {
      return undefined;
    }

    return Object.values(PredefinedCompanyName).includes(
      data.companyName as PredefinedCompanyName
    )
      ? ({
          displayName: data.companyName,
          groupId: data.companyGroupId,
        } as Payor)
      : PredefinedCompanies.Other;
  };

  const [selectedCompany, setSelectedCompany] = React.useState<
    Payor | undefined
  >(getInitialCompany());

  useEffect(() => {
    if (selectedCompany) {
      handleSelectCompany(selectedCompany);
    }
  }, []);

  const addSteps = (company: Payor) => {
    if (!currentStepId) return;

    const isOtherCompany = company.displayName === PredefinedCompanyName.Other;

    if (isOtherCompany) {
      createStep(otherSubstepId, {
        index: currentStepIdx ? currentStepIdx + 1 : undefined,
        children: (
          <EnterOtherInsuranceCompanyStep previousStepId={currentStepId} />
        ),
      });
    }

    createStep(insuranceSubstepId, {
      index: currentStepIdx ? currentStepIdx + 2 : undefined,
      children: getProvideInsuranceStep(
        isMultipleInsuranceFirstStep ? onConfirm : undefined
      ),
    });

    if (!isMultipleInsuranceFirstStep) {
      createStep(SubSteps.COST_ESTIMATION, {
        index: currentStepIdx ? currentStepIdx + 3 : undefined,
        children: <InsuranceCostEstimationStep onSubmit={onConfirm} />,
      });
    }
  };

  const removeSteps = () => {
    removeStep(otherSubstepId);
    removeStep(insuranceSubstepId);
  };

  const handleSelectCompany = (company: Payor) => {
    removeSteps();
    addSteps(company);
    setSelectedCompany(company);
  };

  const handleSubmit = (company: Payor) => {
    const companyData: PaymentMethodInfoType = {
      companyName: company.displayName,
      companyGroupId: company.groupId,
    };

    let subStepData: PaymentMethodType | MultiplePaymentMethodType;

    if (isMultipleInsuranceFirstStep) {
      subStepData = {
        paymentMethodInfo: {
          first: {
            paymentMethodInfo: companyData,
          },
        },
      };
    } else if (isMultipleInsuranceSecondStep) {
      subStepData = {
        paymentMethodInfo: {
          second: {
            paymentMethodInfo: companyData,
          },
        },
      };
    } else {
      subStepData = {
        paymentMethodInfo: companyData,
      };
    }

    resolve(companyData);

    if (company.displayName !== getSubStepsData()?.companyName) {
      companyData.memberId = undefined;
      companyData.insuranceNumber = undefined;
    }

    resolveSubStep({ stepId: Steps.PAYMENT_METHOD, subStepData });
  };

  const handleBack = () => {
    goBack();
  };

  return (
    <SelectInsuranceCompanyForm
      selectedCompany={selectedCompany}
      companies={companies}
      onSelect={handleSelectCompany}
      onSubmit={handleSubmit}
      goBack={handleBack}
      isFirstMultipleStep={isMultipleInsuranceFirstStep}
      isSecondMultipleStep={isMultipleInsuranceSecondStep}
    />
  );
};

export default SelectInsuranceCompany;
