import { AppUser } from "@natera/platform/lib/provider/user/user";
import {
  OktaAuthOptions,
  OktaIdentity,
  SessionService as PlatformSessionService,
  SessionServiceInterface,
} from "@natera/platform/lib/service";

export interface AuthToken {
  accessToken: string;
}

export interface Credentials {
  email: string;
  password: string;
}

const patientRoleClaim = "patient-service_role";
const scopes = ["patient-service_api"];

export interface PatientPortalUser extends OktaIdentity, AppUser {
  email: string;
  role: string;
}

export default class SessionService
  extends PlatformSessionService<PatientPortalUser>
  implements SessionServiceInterface<PatientPortalUser> {
  constructor(readonly oktaConfig: OktaAuthOptions) {
    super(oktaConfig, scopes);
  }

  public getUserData = async (): Promise<PatientPortalUser | null> => {
    const payload = await this.getTokenData();

    if (!payload) {
      return null;
    }

    return {
      ...payload,
      uid: payload.uid,
      email: payload?.sub,
      firstName: payload.first_name,
      lastName: payload.family_name,
      role: payload[patientRoleClaim],
    };
  };
}
