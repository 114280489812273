import React, { FC, createContext } from "react";
import { useLazyQuery } from "@apollo/client";

import EthnicityService from "@app/service/ethnicity";

interface Ethnicity {
  code: string;
  description: string;
}

export interface EthnicityController {
  isLoading: boolean;
  loadEthnicities: () => void;
  getEthnicities: () => Ethnicity[];
}

export const Context = createContext<EthnicityController>({
  isLoading: false,
  loadEthnicities: () => Promise.reject(),
  getEthnicities: () => [],
});

Context.displayName = "EthnicityContext";

const EthnicityProvider: FC = ({ children }) => {
  const [getEthnicitiesQuery, { data, loading: isLoading }] = useLazyQuery(
    EthnicityService.getEthnicities(),
    {
      fetchPolicy: "no-cache",
    }
  );

  const getEthnicities = () => {
    return data?.ethnicities || [];
  };

  const loadEthnicities = () => {
    getEthnicitiesQuery({
      variables: {},
    });
  };

  return (
    <Context.Provider
      value={{
        isLoading: isLoading,
        loadEthnicities,
        getEthnicities,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default EthnicityProvider;
