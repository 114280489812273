import React, { FC } from "react";
import * as R from "ramda";
import { Steps } from "@app/pages/private/simpleOrder";
import { PartnerInfoData } from "../order";
import { SignatureFormType } from "@app/components/simpleOrder/signature/signatureForm";
import { PaymentType } from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { Address } from "@app/provider";
import { FetalSexSelection } from "@app/components/fetalSexInResults/fetalSexInResults";

export enum SimpleOrderSteps {
  KIT_SHIPPING_DETAILS = "KIT_SHIPPING_DETAILS",
  FETAL_SEX = "FETAL_SEX",
}

export enum SimpleOrderSubSteps {
  //Acknowledgment
  ACKNOWLEDGMENT = "ACKNOWLEDGMENT",
  SIGNATURE = "SIGNATURE",
  //Partner info
  PARTNER_INFO = "PARTNER_INFO",
  PARTNER_ACKNOWLEDGMENT = "PARTNER_ACKNOWLEDGMENT",
  PARTNER_SIGNATURE = "PARTNER_SIGNATURE",
}

export type KitShippingDetailsStepResolve = {
  address: Address | undefined;
};

export type FetalSexStepResolve = {
  reportGender: FetalSexSelection | undefined;
};

export type PatientAcknowledgmentStepResolve = {
  consentNYResident?: boolean;
  acknowledgmentText?: string;
  consentTimestamp?: string;
  signature?: SignatureFormType;
};

export type PartnerInfoStepResolve = {
  partner?: PartnerInfoData;
  acknowledgmentText?: string;
  sharingConsentAgreement?: boolean;
  consentTimestamp?: string;
  signature?: SignatureFormType;
};

export type PaymentMethodInfoType = {
  companyName?: string;
  memberId?: string;
  insuranceNumber?: string;
  companyGroupId?: string;
  otherCompanyName?: string;
  otherCompanyGroupId?: string;
};

export type MultiplePaymentMethodInfoType = {
  first?: PaymentMethodType;
  second?: PaymentMethodType;
};

export type PaymentMethodType = {
  paymentMethod?: PaymentType;
  paymentMethodInfo?: PaymentMethodInfoType;
};

export type MultiplePaymentMethodType = {
  paymentMethod?: PaymentType;
  paymentMethodInfo?: MultiplePaymentMethodInfoType;
};

export interface SimpleOrderStepperController {
  getSubStep: () => SimpleOrderSubSteps | undefined;
  setSubStep: (subStep: SimpleOrderSubSteps) => void;
  resolveStep: ({ stepId, stepData }: ResolvedStep) => void;
  resolveSubStep: ({ stepId, subStepData }: ResolvedSubStep) => void;
  getStepData: (stepId: Steps) => unknown;
  getSubStepData: (stepId: Steps) => unknown;
}

const Context = React.createContext<SimpleOrderStepperController>({
  getSubStep: () => undefined,
  setSubStep: () => undefined,
  resolveStep: () => undefined,
  resolveSubStep: () => undefined,
  getStepData: () => undefined,
  getSubStepData: () => undefined,
});

type ResolvedStep =
  | {
      stepId: Steps.KIT_SHIPPING_DETAILS;
      stepData: KitShippingDetailsStepResolve;
    }
  | {
      stepId: Steps.FETAL_SEX;
      stepData: FetalSexStepResolve;
    };

type ResolvedSubStep =
  | {
      stepId: Steps.ACKNOWLEDGMENT;
      subStepData: PatientAcknowledgmentStepResolve;
    }
  | {
      stepId: Steps.PARTNER_INFO;
      subStepData: PartnerInfoStepResolve;
    }
  | {
      stepId: Steps.PAYMENT_METHOD;
      subStepData: PaymentMethodType | MultiplePaymentMethodType;
    };

interface SimpleOrderStepperProviderProps {
  children: React.ReactNode;
}

type StorageData = Record<string, unknown>;

const SimpleOrderStepperProvider: FC<SimpleOrderStepperProviderProps> = ({
  children,
}) => {
  const [stepStorage, setStepStorage] = React.useState<StorageData>({});

  const [subStep, setSubStep] = React.useState<SimpleOrderSubSteps>();
  const [subStepStorage, setSubStepStorage] = React.useState<StorageData>({});

  const setSubStep$ = (subStep: SimpleOrderSubSteps) => {
    setSubStep(subStep);
  };

  const transformToStepObject = R.converge(R.objOf, [
    R.path(["stepId"]),
    R.path(["stepData"]),
  ]);

  const transformToSubStepObject = R.converge(R.objOf, [
    R.path(["stepId"]),
    R.path(["subStepData"]),
  ]);

  const resolveStep = ({ stepId, stepData }: ResolvedStep) => {
    const result = R.mergeDeepLeft(
      transformToStepObject({ stepId, stepData }),
      stepStorage
    );
    setStepStorage(result);
  };

  const resolveSubStep = ({ stepId, subStepData }: ResolvedSubStep) => {
    const result = R.mergeDeepLeft(
      transformToSubStepObject({ stepId, subStepData }),
      subStepStorage
    );
    setSubStepStorage(result);
  };

  const getStepData = (stepId: Steps) => {
    return R.prop(stepId, stepStorage);
  };

  const getSubStepData = (stepId: Steps) => {
    return R.prop(stepId, subStepStorage);
  };

  return (
    <Context.Provider
      value={{
        getSubStep: () => subStep,
        setSubStep: setSubStep$,
        resolveStep,
        resolveSubStep,
        getStepData,
        getSubStepData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { SimpleOrderStepperProvider, Context as SimpleOrderStepperContext };
