import { PaymentMethodSelector } from "@app/components/paymentMethod";
import {
  PaymentType,
  PaymentTypeForHeap,
  SubSteps,
} from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { Button, Form } from "@natera/platform/lib/components/form";
import { StepperContext } from "@natera/stepper";
import React, { useContext, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { GovernmentStep } from "./steps/selectGovernment";
import { CommercialStep } from "./steps/selectCommercial";
import { MultipleInsurancesFirstStep } from "./steps/multipleInsurances";
import { SelfPayStep } from "@app/components/simpleOrder/payment/steps";
import { HEAP_EVENTS } from "@app/provider/types";
import { getTestNameForHeap } from "@app/utils";
import { OrderContext } from "@app/provider/order";
import { Steps } from "@app/pages/private/simpleOrder";
import {
  PaymentMethodType,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";

const messages = defineMessages({
  paymentMethodHeaderTitle: {
    id: "paymentMethodHeaderTitle",
    defaultMessage: "Payment Method",
  },
  paymentMethodHeaderSubTitle: {
    id: "paymentMethodHeaderSubTitle",
    defaultMessage: "Which situation best describes you?",
  },
  paymentMethodHeaderText: {
    id: "paymentMethodHeaderText",
    defaultMessage: "Select the payment method that works best for you.",
  },
  paymentMethodButtonNext: {
    id: "paymentMethodButtonNext",
    defaultMessage: "Next",
  },
  paymentMethodButtonBack: {
    id: "paymentMethodButtonBack",
    defaultMessage: "Back",
  },
});

type Props = {
  onConfirm: (data: unknown) => void;
  goBack: () => void;
};

const PaymentMethodForm: React.FC<Props> = ({ onConfirm, goBack }) => {
  const intl = useIntl();
  const {
    resetStep,
    resolve,
    getData,
    createStep,
    removeStep,
  } = React.useContext(StepperContext);

  const { getSubStepData, resolveSubStep } = useContext(
    SimpleOrderStepperContext
  );

  const data = getData(SubSteps.SELECT_METHOD);
  const { orderData } = React.useContext(OrderContext);
  const orderUid = orderData?.uid;
  const limsId = orderData?.clinic.limsId;

  const subStepData = getSubStepData(Steps.PAYMENT_METHOD);

  const [selectedPaymentType, setSelectedPaymentType] = React.useState<
    PaymentType | undefined
  >((subStepData as PaymentMethodType)?.paymentMethod ?? data?.paymentMethod);

  const paymentTypeHeap = () => {
    switch (selectedPaymentType) {
      case PaymentType.COMMERCIAL:
        return PaymentTypeForHeap.COMMERCIAL;
      case PaymentType.GOVERNMENT_ISSUED:
        return PaymentTypeForHeap.GOVERNMENT;
      case PaymentType.MULTIPLE:
        return PaymentTypeForHeap.TWO_INSURANCES;
      case PaymentType.DO_NOT_HAVE:
        return PaymentTypeForHeap.NO_INSURANCE;
      case PaymentType.SELF_PAY:
        return PaymentTypeForHeap.DONT_USE_INSURANCE;
    }
  };

  useEffect(() => {
    if (selectedPaymentType) {
      addSteps(selectedPaymentType);
    }
  }, []);

  const addSteps = (paymentMethod: PaymentType) => {
    if (paymentMethod === PaymentType.COMMERCIAL) {
      createStep(SubSteps.SELECT_COMMERCIAL_COMPANY, {
        index: 1,
        children: <CommercialStep onConfirm={onConfirm} />,
      });
    }

    if (paymentMethod === PaymentType.GOVERNMENT_ISSUED) {
      createStep(SubSteps.SELECT_GOVERNMENT_COMPANY, {
        index: 1,
        children: <GovernmentStep onConfirm={onConfirm} />,
      });
    }

    if (paymentMethod === PaymentType.MULTIPLE) {
      createStep(SubSteps.MULTIPLE_FIRST, {
        index: 1,
        children: <MultipleInsurancesFirstStep onConfirm={onConfirm} />,
      });
    }

    if (paymentMethod === PaymentType.DO_NOT_HAVE) {
      createStep(SubSteps.DO_NOT_HAVE, {
        index: 1,
        children: (
          <SelfPayStep paymentType={paymentMethod} onConfirm={onConfirm} />
        ),
      });
    }

    if (paymentMethod === PaymentType.SELF_PAY) {
      createStep(SubSteps.SELF_PAY, {
        index: 1,
        children: (
          <SelfPayStep paymentType={paymentMethod} onConfirm={onConfirm} />
        ),
      });
    }
  };

  const removesSteps = () => {
    const subStepsForRemove = Object.values(SubSteps).filter(
      (key) => key !== SubSteps.SELECT_METHOD
    );

    subStepsForRemove.forEach((subStepKey) => removeStep(subStepKey));
  };

  const handleSelectPaymentType = (paymentMethod: PaymentType) => {
    removesSteps();
    addSteps(paymentMethod);

    setSelectedPaymentType(paymentMethod);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const data: PaymentMethodType = { paymentMethod: selectedPaymentType };

    if (
      subStepData &&
      selectedPaymentType !== (subStepData as PaymentMethodType)?.paymentMethod
    ) {
      data.paymentMethodInfo = undefined;
    }

    resolveSubStep({ stepId: Steps.PAYMENT_METHOD, subStepData: data });
    resolve(data);

    heap.track(HEAP_EVENTS.upp_simpleorder_payment, {
      tests_ordered: getTestNameForHeap(orderData),
      lims_clinic_id: limsId,
      order_uid: orderUid,
      payment_type: paymentTypeHeap(),
    });
  };

  const handleBack = () => {
    resetStep(SubSteps.SELECT_METHOD);
    goBack();
  };

  return (
    <Form
      className="payment-method__form"
      onSubmit={handleSubmit}
      buttons={
        <>
          <Button raised type="submit" disabled={!selectedPaymentType}>
            {intl.formatMessage(messages.paymentMethodButtonNext)}
          </Button>
          <Button onClick={handleBack}>
            {intl.formatMessage(messages.paymentMethodButtonBack)}
          </Button>
        </>
      }
    >
      <div className="payment-method__selector">
        <PaymentMethodSelector
          value={selectedPaymentType}
          onChange={handleSelectPaymentType}
        />
      </div>
    </Form>
  );
};

export default PaymentMethodForm;
