import React, { FC, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/platform/lib/components/form";
import { Svg } from "@natera/material/lib/svg";
import AccountNotAvailable from "@assets/svg/account_not_available.svg";
import { LinkHeap } from "@app/components";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";
import acknowledgements from "@etc/acknowledgements.json";
import "./dateOfBirthVerificationFailed.scss";

const messages = defineMessages({
  dateOfBirthVerificationFailedTitle: {
    id: "dateOfBirthVerificationFailedTitle",
    defaultMessage: "We are unable to verify your date of birth",
  },
  dateOfBirthVerificationFailedText: {
    id: "dateOfBirthVerificationFailedText",
    defaultMessage:
      "The date of birth you entered does not match our records. Please contact your ordering clinic or Natera support.",
  },
  dateOfBirthVerificationFailedClose: {
    id: "dateOfBirthVerificationFailedClose",
    defaultMessage: "Close",
  },
  dateOfBirthVerificationFailedIssues: {
    id: "dateOfBirthVerificationFailedIssues",
    defaultMessage: "Having issues with verification? {contactUs}",
  },
  dateOfBirthVerificationFailedContactUs: {
    id: "dateOfBirthVerificationFailedContactUs",
    defaultMessage: "Contact Us",
  },
});

interface Props {
  onSubmit?: () => void;
  title?: React.ReactNode;
  description?: string;
}

const DateOfBirthVerificationFailed: FC<Props> = ({
  onSubmit,
  title,
  description,
}) => {
  const intl = useIntl();

  const handleClick = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  const getDobFailedTitle = () =>
    title
      ? title
      : intl.formatMessage(messages.dateOfBirthVerificationFailedTitle);

  const heapEventPropsLocation = useMemo(
    () =>
      title
        ? HeapEventLocation.unable_to_verify_mp_page
        : HeapEventLocation.unable_to_verify_account_creation_page,
    [title]
  );

  const getDobFailedDescription = () =>
    description
      ? description
      : intl.formatMessage(messages.dateOfBirthVerificationFailedText);

  return (
    <div className="date-of-birth-verification-failed__wrapper">
      <section className="date-of-birth-verification-failed__content">
        <Svg content={AccountNotAvailable} />
        <h2>{getDobFailedTitle()}</h2>
        <p
          className="date-of-birth-verification-failed-text"
          data-testid="date-of-birth__description"
        >
          {getDobFailedDescription()}
        </p>
        {onSubmit && (
          <Button
            className="date-of-birth-verification-failed__close-button"
            raised
            onClick={handleClick}
            data-testid="handle-close-button"
          >
            {intl.formatMessage(messages.dateOfBirthVerificationFailedClose)}
          </Button>
        )}
        <p className="date-of-birth-verification-failed__bottom-text">
          {intl.formatMessage(messages.dateOfBirthVerificationFailedIssues, {
            contactUs: (
              <LinkHeap
                target="_blank"
                key="privacyPolicy"
                rel="noreferrer"
                to={{ pathname: acknowledgements.links.contactUs }}
                heapEventName={HEAP_EVENTS.upp_click_contactnatera}
                heapEventProps={{
                  location: heapEventPropsLocation,
                }}
              >
                {intl.formatMessage(
                  messages.dateOfBirthVerificationFailedContactUs
                )}
              </LinkHeap>
            ),
          })}
        </p>
      </section>
    </div>
  );
};

export default DateOfBirthVerificationFailed;
