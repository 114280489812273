import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { datadogRum } from "@datadog/browser-rum";
import { NoContentView } from "@app/pages";
import { HEAP_EVENTS } from "@app/provider/types";
import { routes } from "@app/routing";
import { LinkButton } from "@natera/platform/lib/components/form";

const messages = defineMessages({
  noActiveTestsViewTitle: {
    id: "NoActiveTestsViewTitle",
    defaultMessage:
      "There are no active tests that match with your profile at this time.",
  },
  noActiveTestsViewSubTitle: {
    id: "NoActiveTestsViewSubTitle",
    defaultMessage:
      "If you've provided a sample to the lab, it normally takes 3-5 days before your test status can be shown. We will notify you once your sample has been received.",
  },
  noActiveTestsViewHistoryButton: {
    id: "noActiveTestsViewHistoryButton",
    defaultMessage: "See Previous Tests",
  },
});

interface NoActiveTestsViewProps {
  isTestsHistoryExists?: boolean;
}

const NoActiveTestsView: FC<NoActiveTestsViewProps> = ({
  isTestsHistoryExists,
}) => {
  const intl = useIntl();

  const action = isTestsHistoryExists ? (
    <LinkButton
      className="no-active-tests_content-button"
      outlined
      to={routes.testHistory}
      onClick={() => heap.track(HEAP_EVENTS.upp_click_testhistory)}
    >
      {intl.formatMessage(messages.noActiveTestsViewHistoryButton)}
    </LinkButton>
  ) : undefined;

  // Create datadogRum action if no tests found
  // This is to help identify user sessions in cases where
  // user expects to see tests but none are found.
  datadogRum.addAction("noActiveTestsFound");

  return (
    <NoContentView
      title={intl.formatMessage(messages.noActiveTestsViewTitle)}
      message={intl.formatMessage(messages.noActiveTestsViewSubTitle)}
      action={action}
    />
  );
};

export default NoActiveTestsView;
