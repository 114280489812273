import React, { FC, createContext } from "react";
import {
  ApolloError,
  useMutation,
  useLazyQuery,
  FetchResult,
} from "@apollo/client";

import SignUpService from "@app/service/signUp";
import UserService from "@app/service/user";
import { ErrorBody, User } from "@app/provider/user";
import { Language } from "@app/provider";

interface SignUpByPasswordProps {
  email: string;
  password: string;
  language: Language;
}

interface SignUpResponse extends User {
  isTokenValid?: boolean;
}

export interface SignUpController {
  signUpByPasswordIsLoading: boolean;
  checkUserExistedIsLoading: boolean;
  signUpByPasswordData?: User | null;
  checkUserExistedData?: { isExisted: boolean };
  signUpByPasswordError?: string;
  signUpByPasswordNetworkError?: string;
  checkUserExitedError?: ApolloError;
  signUpByPassword: (
    props: SignUpByPasswordProps
  ) => Promise<FetchResult<{ signUpByPassword: SignUpResponse }>>;
  checkUserExisted: (email: string) => Promise<FetchResult>;
}

export const Context = createContext<SignUpController>({
  signUpByPasswordIsLoading: false,
  checkUserExistedIsLoading: false,
  signUpByPassword: async () => ({}),
  checkUserExisted: async () => ({}),
});

Context.displayName = "SignUpContext";

const SignUpProvider: FC = ({ children }) => {
  const [
    signUpByPassword,
    {
      loading: signUpByPasswordIsLoading,
      data: signUpByPasswordData,
      error: signUpByPasswordError,
    },
  ] = useMutation<{
    signUpByPassword: User;
  }>(SignUpService.signUpByPassword());

  const [
    checkUserExisted,
    {
      loading: checkUserExistedIsLoading,
      data: checkUserExistedData,
      error: checkUserExitedError,
    },
  ] = useLazyQuery(UserService.CheckUserExisted(), { fetchPolicy: "no-cache" });

  const handleSignUpByPassword = async (props: SignUpByPasswordProps) =>
    signUpByPassword({
      variables: {
        signUpByPasswordData: props,
      },
    });

  const handleCheckUserExisted = async (email: string) =>
    checkUserExisted({
      variables: { email },
    });

  return (
    <Context.Provider
      value={{
        signUpByPasswordIsLoading,
        checkUserExistedIsLoading,
        signUpByPasswordData: signUpByPasswordData?.signUpByPassword,
        checkUserExistedData: checkUserExistedData?.checkUserExisted,
        signUpByPasswordError: (signUpByPasswordError?.graphQLErrors[0]
          ?.extensions?.exception as ErrorBody)?.code,
        signUpByPasswordNetworkError: signUpByPasswordError?.networkError?.name,
        checkUserExitedError,
        signUpByPassword: handleSignUpByPassword,
        checkUserExisted: handleCheckUserExisted,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default SignUpProvider;
