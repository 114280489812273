import React, { FC, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Textfield } from "@natera/platform/lib/components/form/field";
import { TextfieldProps } from "@natera/platform/lib/components/form/field/textfield";
import Svg from "@natera/material/lib/svg";
import EyeIcon from "@assets/svg/icons/eye.svg";
import EyeCrossIcon from "@assets/svg/icons/eye-cross.svg";
import "./passwordField.scss";

const messages = defineMessages({
  passwordFieldHidePassword: {
    id: "passwordFieldHidePassword",
    defaultMessage: "Hide password",
  },
  passwordFieldShowPassword: {
    id: "passwordFieldShowPassword",
    defaultMessage: "Show password",
  },
});

const PasswordField: FC<TextfieldProps> = (props) => {
  const intl = useIntl();
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const changePasswordIcon: React.KeyboardEventHandler = (e) => {
    if (e.key === "Enter") setPasswordIsVisible((value) => !value);
  };

  return (
    <Textfield
      {...props}
      type={passwordIsVisible ? "text" : "password"}
      trailingIcon={
        <Svg
          role="img"
          className="passwordField-icon"
          tabIndex={0}
          content={passwordIsVisible ? EyeIcon : EyeCrossIcon}
          title={
            passwordIsVisible
              ? intl.formatMessage(messages.passwordFieldHidePassword)
              : intl.formatMessage(messages.passwordFieldShowPassword)
          }
          onClick={() => setPasswordIsVisible((value) => !value)}
          onKeyDown={changePasswordIcon}
        />
      }
      trailingIconTabIndex={-0}
      className="passwordField"
    />
  );
};

export default PasswordField;
