import React, { FC, ReactElement } from "react";
import { useIntl, defineMessages } from "react-intl";
import UnlockUserForm from "./unlockForm";
import acknowledgements from "@etc/acknowledgements.json";
import { Progress, LinkHeap } from "@app/components";
import { UserContext } from "@app/provider";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";

const messages = defineMessages({
  unlockVerifyYourIdentity: {
    id: "unlockVerifyYourIdentity",
    defaultMessage: "Verify Your Identity",
  },
  unlockVerifyYourIdentityInstructions: {
    id: "unlockVerifyYourIdentityInstructions",
    defaultMessage: "We will send you an email with reset instructions.",
  },
  unlockIssuesVerification: {
    id: "unlockIssuesVerification",
    defaultMessage: "Having issues with verification?",
  },
  unlockContactUs: {
    id: "unlockContactUs",
    defaultMessage: "Contact Us",
  },
});

interface UnlockViewProps {
  onSubmit: (email: string, dateOfBirth?: string, lastName?: string) => void;
  email: string;
  errorNotification?: ReactElement;
}

const UnlockView: FC<UnlockViewProps> = ({
  onSubmit,
  errorNotification,
  email,
}) => {
  const intl = useIntl();

  const [showUnlockForm, setShowUnlockForm] = React.useState(false);
  const { getShowUnlockForm, showError } = React.useContext(UserContext);

  const checkAccountFillingStage = () =>
    getShowUnlockForm(email)
      .then((showUnlockForm) => {
        setShowUnlockForm(showUnlockForm);
        !showUnlockForm && onSubmit(email);
      })
      .catch(() => {
        showError();
      });

  React.useEffect(() => {
    email ? checkAccountFillingStage() : setShowUnlockForm(true);
  }, []);

  const title: ReactElement = (
    <section className="title__container">
      <h1>{intl.formatMessage(messages.unlockVerifyYourIdentity)}</h1>
      <span>
        {intl.formatMessage(messages.unlockVerifyYourIdentityInstructions)}
      </span>
    </section>
  );
  const unlockForm: ReactElement = (
    <section className="form__container">
      <UnlockUserForm onSubmit={onSubmit} preselectedEmail={email} />
    </section>
  );

  const issuesVerification: ReactElement = (
    <section className="issues-verification__container">
      <span>{intl.formatMessage(messages.unlockIssuesVerification)} </span>
      <LinkHeap
        to={{ pathname: acknowledgements.links.contactUs }}
        key="contactUs"
        target="_blank"
        rel="noreferrer"
        heapEventName={HEAP_EVENTS.upp_click_contactnatera}
        heapEventProps={{
          location: HeapEventLocation.verification_unlock_page,
        }}
      >
        {intl.formatMessage(messages.unlockContactUs)}
      </LinkHeap>
    </section>
  );

  return showUnlockForm ? (
    <>
      <Progress className="unlock-progress" value={50} />
      {title}
      {errorNotification}
      {unlockForm}
      {issuesVerification}
    </>
  ) : null;
};

export default UnlockView;
