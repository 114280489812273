import { StepperContent } from "@app/components/ui/layout/stepperContent";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { StepperContext } from "@natera/stepper";
import { SubSteps } from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { PredefinedCompanies } from "@app/components/companySelector/companySelector";
import { SelectInsuranceCompany } from "../selectInsuranceCompany";
import { ProvideGovernmentStep } from "../provideGovernment";
import { GetProvideInsuranceStep } from "../selectInsuranceCompany/selectInsuranceCompany";

const messages = defineMessages({
  selectGovernmentStepHeaderTitle: {
    id: "selectGovernmentStepHeaderTitle",
    defaultMessage: "Payment Method",
  },
  selectGovernmentStepHeaderSubTitle: {
    id: "selectGovernmentStepHeaderSubTitle",
    defaultMessage: "Select government-issued insurance",
  },
});

const governmentCompanies = [
  PredefinedCompanies.Medicaid,
  PredefinedCompanies.Tricare,
  PredefinedCompanies.Other,
];

interface SubstepsMap {
  other: SubSteps;
  insurance: SubSteps;
}
export type GovernmentSteps =
  | SubSteps.SELECT_GOVERNMENT_COMPANY
  | SubSteps.SELECT_GOVERNMENT_COMPANY_FIRST
  | SubSteps.SELECT_GOVERNMENT_COMPANY_SECOND;

type Props = {
  onConfirm: (data: unknown) => void;
};

const SelectGovernmentStep: React.FC<Props> = ({ onConfirm }) => {
  const intl = useIntl();
  const { getCurrentStep } = React.useContext(StepperContext);

  const currentStepId = getCurrentStep()?.stepId as GovernmentSteps;

  const governmentStepsMapper: Record<GovernmentSteps, SubstepsMap> = {
    [SubSteps.SELECT_GOVERNMENT_COMPANY]: {
      other: SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY,
      insurance: SubSteps.PROVIDE_GOVERNMENT_INSURANCE,
    },
    [SubSteps.SELECT_GOVERNMENT_COMPANY_FIRST]: {
      other: SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY_FIRST,
      insurance: SubSteps.PROVIDE_GOVERNMENT_INSURANCE_FIRST,
    },
    [SubSteps.SELECT_GOVERNMENT_COMPANY_SECOND]: {
      other: SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY_SECOND,
      insurance: SubSteps.PROVIDE_GOVERNMENT_INSURANCE_SECOND,
    },
  };

  const otherSubstepId = currentStepId
    ? governmentStepsMapper[currentStepId].other
    : SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY;
  const insuranceSubstepId = currentStepId
    ? governmentStepsMapper[currentStepId].insurance
    : SubSteps.PROVIDE_GOVERNMENT_INSURANCE;

  const getProvideGovernmentStep: GetProvideInsuranceStep = (onConfirm) => (
    <ProvideGovernmentStep onConfirm={onConfirm} />
  );

  return (
    <StepperContent
      title={intl.formatMessage(messages.selectGovernmentStepHeaderTitle)}
      subTitle={intl.formatMessage(messages.selectGovernmentStepHeaderSubTitle)}
    >
      <SelectInsuranceCompany
        companies={governmentCompanies}
        otherSubstepId={otherSubstepId}
        insuranceSubstepId={insuranceSubstepId}
        getProvideInsuranceStep={getProvideGovernmentStep}
        onConfirm={onConfirm}
      />
    </StepperContent>
  );
};

export default SelectGovernmentStep;
