import React from "react";
import { PaymentInfo, Commercial, SubSteps } from "./paymentMethod";
import { PredefinedCompanyName } from "@app/components/companySelector/companySelector";
import {
  CommercialStep,
  ProvideCommercialStep,
  EnterOtherInsuranceCompanyStep,
  InsuranceCostEstimationStep,
} from "@app/components/simpleOrder/payment/steps";
import { MultipleStep } from "./multipleStepsMapper";

export const getCommercialSteps = (
  commercialStepData: Commercial,
  onConfirm: (data: PaymentInfo) => void
): MultipleStep[] => {
  const otherCompanyStep = !Object.values(PredefinedCompanyName).includes(
    commercialStepData.paymentMethodInfo.companyName as PredefinedCompanyName
  );

  const commercialSteps: MultipleStep[] = [
    {
      stepId: SubSteps.SELECT_COMMERCIAL_COMPANY,
      index: 1,
      childComponent: <CommercialStep onConfirm={onConfirm} />,
      data: {
        companyName: otherCompanyStep
          ? PredefinedCompanyName.Other
          : commercialStepData.paymentMethodInfo.companyName,
        companyGroupId: otherCompanyStep
          ? undefined
          : commercialStepData.paymentMethodInfo.companyGroupId,
      },
    },
    {
      stepId: SubSteps.PROVIDE_COMMERCIAL_INSURANCE,
      index: 3,
      childComponent: <ProvideCommercialStep />,
      data: { memberId: commercialStepData.paymentMethodInfo.memberId },
    },
    {
      stepId: SubSteps.COST_ESTIMATION,
      index: 4,
      childComponent: <InsuranceCostEstimationStep onSubmit={onConfirm} />,
      data: {},
    },
  ];

  if (otherCompanyStep) {
    commercialSteps.push({
      stepId: SubSteps.ENTER_OTHER_COMMERCIAL_COMPANY,
      index: 2,
      childComponent: <EnterOtherInsuranceCompanyStep />,
      data: {
        otherCompanyName: commercialStepData.paymentMethodInfo.companyName,
        otherCompanyGroupId:
          commercialStepData.paymentMethodInfo.companyGroupId,
      },
    });
  }

  return commercialSteps;
};
