import React from "react";
import classNames from "classnames";

import "./dropdownWrapper.scss";

export interface DropdownWrapperProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;
}

export const DropdownWrapper: React.FC<DropdownWrapperProps> = ({
  isOpen = false,
  children,
  ...props
}) => {
  return (
    <div
      className={classNames("dropdown-wrapper", {
        "dropdown-wrapper--visible": isOpen,
      })}
      {...props}
    >
      {children}
    </div>
  );
};

export default DropdownWrapper;
