import React, { FC } from "react";
import { Dialog } from "@natera/material/lib/dialog";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/platform/lib/components/form";

const messages = defineMessages({
  cancelAppointmentDialogSuccessTitle: {
    id: "cancelAppointmentDialogSuccessTitle",
    defaultMessage: "Your appointment has been successfully cancelled",
  },
  cancelAppointmentDialogSuccessBackButton: {
    id: "cancelAppointmentDialogSuccessBackButton",
    defaultMessage: "Back",
  },
});

interface CancelAppointmentDialogSuccessProps {
  onClose: () => void;
}

const CancelAppointmentDialogSuccess: FC<CancelAppointmentDialogSuccessProps> = ({
  onClose,
}) => {
  const intl = useIntl();

  const header = intl.formatMessage(
    messages.cancelAppointmentDialogSuccessTitle
  );

  const actions = (
    <Button onClick={onClose} raised>
      {intl.formatMessage(messages.cancelAppointmentDialogSuccessBackButton)}
    </Button>
  );

  return (
    <Dialog
      className="cancel-appointment-success-dialog"
      onClose={onClose}
      title={header}
      showCrossButton
      actions={actions}
    >
      <></>
    </Dialog>
  );
};

export default CancelAppointmentDialogSuccess;
