import React, { FC } from "react";
import { Notification } from "@app/components";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  commonErrorNotificationText: {
    id: "commonErrorNotificationText",
    defaultMessage: "We're sorry. Something went wrong.",
  },
});

const CommonErrorNotification: FC = () => {
  const intl = useIntl();

  return (
    <Notification type="error">
      {intl.formatMessage(messages.commonErrorNotificationText)}
    </Notification>
  );
};
export default CommonErrorNotification;
