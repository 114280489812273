import { DocumentNode, gql } from "@apollo/client";

class TestHistoryService {
  public static getTestHistoryCards = (): DocumentNode => gql`
    query GetTestHistoryCards(
      $limit: Float
      $offset: Float
      $sort: String
      $from: String
      $to: String
    ) {
      getTestHistoryCards(
        limit: $limit
        offset: $offset
        sort: $sort
        from: $from
        to: $to
      ) {
        tests {
          uid
          orderUid
          testType
          status
          nextStatus
          managedBy
          sampleType
          PTPOption
          viewStatus
          organType
          createdAt
          trackingNumber
          reportGender
          caPnsSupplemental
          resultCode
          resultReleasedAt
          cancelledAt
          latestResultDocumentUid
          clinic {
            limsId
          }
        }
        total
      }
    }
  `;
}

export default TestHistoryService;
