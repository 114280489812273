import React, { FC, ReactElement } from "react";
import { Dialog } from "@natera/material/lib/dialog";
import "./baseModalDialog.scss";
import { defineMessages } from "react-intl";

// overrides @natera/material/lib/dialog component translations
const messages = defineMessages({
  dialogCloseButtonAriaLabel: {
    id: "material.dialog.ariaLabelCloseButton",
    defaultMessage: "Close",
  },
});

interface BaseModalDialogProps {
  header: ReactElement | string;
  content?: ReactElement;
  actions?: ReactElement;
  handleClose?: () => void;
  altText?: string;
}

const BaseModalDialog: FC<BaseModalDialogProps> = ({
  header,
  content,
  actions,
  handleClose,
  altText,
}) => {
  const handleCloseDialog = () => handleClose && handleClose();
  return (
    <Dialog
      className="base-modal-dialog"
      onClose={handleCloseDialog}
      title={header}
      showCrossButton
      actions={actions}
      altText={altText}
    >
      <div className="base-modal-dialog__container">
        {content && (
          <div className="base-modal-dialog__content base-modal-dialog__element">
            {content}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default BaseModalDialog;
