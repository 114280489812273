export const convertPhoneNumberToE164 = (phoneNumber: string): string => {
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  return `+${cleanedNumber}`;
};

export const convertPhoneNumberToDisplayValue = (phone?: string) => {
  //RegExp finds 1st digit + next 3 digits + next 3 digits + rest digits
  const match = phone?.match(/^([\d*])([\d*]{3})([\d*]{3})([\d*]+)$/);
  return match ? `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}` : phone;
};
