const reEscapedHtml = /&(?:amp|lt|gt|quot|#39);/g;
const reHasEscapedHtml = RegExp(reEscapedHtml.source);
const htmlUnescapes = {
  "&amp;": "&",
  "&lt;": "<",
  "&gt;": ">",
  "&quot;": '"',
  "&#39;": "'",
};

const basePropertyOf = (object: Record<string, string>) => {
  return function (key: string) {
    return object == null ? "" : object[key];
  };
};

const unescapeHtmlChar = basePropertyOf(htmlUnescapes);

export const unescape = (string: string): string => {
  return string && reHasEscapedHtml.test(string)
    ? string.replace(reEscapedHtml, unescapeHtmlChar)
    : string;
};
