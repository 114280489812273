import { isHorizonOrPanorama } from "@app/utils/orderHelpers";
import {
  ManagedBy,
  ONCOLOGY_TEST_TYPES,
  PrimaryAction,
  SampleType,
  TestCardStatus,
  TestType,
} from "../types";
import { SimpleOrderWorkflowState } from "@app/provider/order";

type Props = {
  status: TestCardStatus;
  testType: TestType;
  sampleType?: SampleType;
  nextStatus?: TestCardStatus;
  managedBy?: ManagedBy;
  isProReach?: boolean;
  isOrderInSignedOrdersTable?: boolean;
  simpleOrderWorkflowState?: SimpleOrderWorkflowState;
};

export const getPrimaryAction = (
  testCard: Props
): PrimaryAction | undefined => {
  switch (testCard.status) {
    case TestCardStatus.UPCOMING_TEST:
      return getPrimaryActionForUpcomingTest(testCard);
    case TestCardStatus.TEST_ORDERED:
      return getPrimaryActionForTestOrdered(testCard);
    case TestCardStatus.KIT_SHIPPED:
      return getPrimaryActionForKitShipped(testCard);
    case TestCardStatus.APPT_REQUESTED:
    case TestCardStatus.APPT_CONFIRMED:
      return PrimaryAction.APPOINTMENT_DETAIL;
    case TestCardStatus.RESULT_READY:
      return PrimaryAction.VIEW_YOUR_RESULTS;
    case TestCardStatus.TEST_COMPLETE:
      return PrimaryAction.VIEW_RESULT_PAGE;
    default:
      return undefined;
  }
};

const getPrimaryActionForUpcomingTest = (
  testCard: Props
): PrimaryAction | undefined => {
  if (
    testCard.testType === TestType.PROSPERA &&
    testCard.managedBy === ManagedBy.NATERA &&
    !testCard.isProReach
  ) {
    return PrimaryAction.SCHEDULE_A_DRAW;
  }
  if (
    testCard.testType === TestType.SIGNATERA &&
    testCard.managedBy === ManagedBy.NATERA
  ) {
    return PrimaryAction.SCHEDULE_A_DRAW;
  }
  return undefined;
};

const getPrimaryActionForKitShipped = (
  testCard: Props
): PrimaryAction | undefined => {
  if (
    testCard.sampleType === SampleType.SALIVA &&
    (testCard.testType === TestType.HORIZON ||
      testCard.testType === TestType.EMPOWER)
  ) {
    return undefined;
  }
  if (
    testCard.sampleType === SampleType.SALIVA ||
    testCard.testType === TestType.PROSPERA
  ) {
    return PrimaryAction.TRACK_KIT_STATUS;
  }
  if (testCard.sampleType === SampleType.BLOOD) {
    return PrimaryAction.SCHEDULE_A_DRAW;
  }
};

const getPrimaryActionForTestOrdered = (
  testCard: Props
): PrimaryAction | undefined => {
  if (testCard.testType === TestType.EMPOWER) {
    if (
      testCard.managedBy === ManagedBy.NATERA &&
      testCard.sampleType === SampleType.BLOOD
    ) {
      return PrimaryAction.SCHEDULE_A_DRAW;
    }
    return undefined;
  }

  if (
    testCard.testType === TestType.RENASIGHT &&
    testCard.sampleType === SampleType.BLOOD
  ) {
    return testCard.managedBy === ManagedBy.CLINIC
      ? undefined
      : PrimaryAction.SCHEDULE_A_DRAW;
  }
  if (testCard.testType === TestType.RENASIGHT) {
    return undefined;
  }

  if (testCard.testType === TestType.ALTERA) {
    return testCard.managedBy === ManagedBy.NATERA
      ? PrimaryAction.SCHEDULE_A_DRAW
      : undefined;
  }

  if (
    testCard.simpleOrderWorkflowState &&
    isHorizonOrPanorama(testCard.testType) &&
    testCard.managedBy === ManagedBy.CLINIC &&
    ((testCard.simpleOrderWorkflowState ===
      SimpleOrderWorkflowState.PATIENT_ACCESSED &&
      testCard.isOrderInSignedOrdersTable) ||
      [
        SimpleOrderWorkflowState.PATIENT_SIGNED,
        SimpleOrderWorkflowState.KIT_ORDERED,
        SimpleOrderWorkflowState.COMPLETED,
      ].includes(testCard.simpleOrderWorkflowState))
  ) {
    return undefined;
  }

  if (testCard.nextStatus === TestCardStatus.SAMPLE_RECEIVED) {
    return PrimaryAction.VIEW_TEST_DETAILS;
  }

  if (
    testCard.simpleOrderWorkflowState &&
    [
      SimpleOrderWorkflowState.COMMUNICATION_SENT,
      SimpleOrderWorkflowState.PATIENT_ACCESSED,
    ].includes(testCard.simpleOrderWorkflowState) &&
    !testCard.isOrderInSignedOrdersTable
  ) {
    return PrimaryAction.COMPLETE_ORDER;
  }
};
