import { ShippingAddress } from "@app/provider/types";

export const getAddressAsString = ({
  street1,
  street2,
  city,
  state,
  zipCode,
}: ShippingAddress): string => {
  const fullStreetPart = [street1, street2].filter(Boolean).join(" ");
  return [fullStreetPart, city, state, zipCode].filter(Boolean).join(", ");
};
