import React, { FC, useContext, useEffect, useState } from "react";
import { EnrollFactorResponse, Factor, IDP_TYPE } from "@app/service/user";
import VerifyCodeForm from "@app/components/verifyCodeForm";
import {
  ErrorProvider,
  MfaErrorContext,
  MfaSetupContext,
  ServiceContext,
  UserContext,
} from "@app/provider";
import SpinnerView from "@app/components/spinnerView";
import { useErrorController } from "@natera/platform/lib/hooks";
import "./style.scss";
import { saveLoginMethodToCookie } from "@app/utils/cookiesHelper";

interface VerifyModalProps extends React.HTMLAttributes<HTMLDivElement> {
  factor: Factor | EnrollFactorResponse;
  setFactor: (factor?: EnrollFactorResponse) => void;
  update?: boolean;
}

const VerifyModal: FC<VerifyModalProps> = ({ factor, setFactor, update }) => {
  const { sessionService } = useContext(ServiceContext);
  const { parseError } = useContext(MfaErrorContext);
  const {
    activateUserFactor,
    disableUserFactors,
    enrollUserFactor,
    resendUserFactorCode,
  } = useContext(MfaSetupContext);

  const { loadProfile, handleUserLogin } = useContext(UserContext);
  const errorController = useErrorController();

  const [isLoading, setIsLoading] = useState(false);

  const activateMFA = async () => {
    try {
      setIsLoading(true);
      await sessionService.activateMFA({
        factorType: factor.factorType,
        provider: factor.provider,
      });
    } catch (error) {
      parseError(error.errorCode, error.errorSummary, errorController);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePhoneFactor = async () => {
    const updatedPhone = factor.profile?.phoneNumber;
    if (updatedPhone) {
      await disableUserFactors();
      await enrollUserFactor(
        factor.factorType,
        factor.provider,
        factor.profile
      );
    }
  };

  useEffect(() => {
    if (update) {
      (async () => {
        await updatePhoneFactor();
      })();
    } else {
      activateMFA();
    }
  }, [factor]);

  const handleActivateSuccess = async () => {
    saveLoginMethodToCookie(IDP_TYPE.EMAIL);
    await loadProfile();
    setFactor(undefined);
  };

  const handleCode = async (code: string) => {
    errorController.clearValidationError("code");
    try {
      setIsLoading(true);
      let response;
      if (update) {
        response = await activateUserFactor(code);
        if (response?.data?.activateUserFactor.success) {
          await handleActivateSuccess();
        }
      } else {
        response = await sessionService.challengeMFA(code);
        if (response?.transaction?.status === "SUCCESS") {
          await handleUserLogin();
          await handleActivateSuccess();
        }
      }
    } catch (error) {
      parseError(
        error.errorCode || error.code,
        error.errorSummary || error.message,
        errorController
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async () => {
    errorController.clearValidationError("code");
    try {
      setIsLoading(true);
      if (update) {
        resendUserFactorCode();
      } else {
        await sessionService.resendFactor(factor.factorType);
      }
    } catch (error) {
      parseError(error.errorCode, error.errorSummary, errorController);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SpinnerView isLoading={isLoading} />
      <ErrorProvider controller={errorController}>
        <VerifyCodeForm
          type={factor.factorType}
          profile={factor.profile}
          handleCode={handleCode}
          handleResend={handleResend}
        />
      </ErrorProvider>
    </>
  );
};

export default VerifyModal;
