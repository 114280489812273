import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { StepperContext } from "@natera/stepper";
import { LoadingContext } from "@natera/platform/lib/components/context";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { OrderContext, ProfileContext, UserContext } from "@app/provider";
import PersonalInfoForm from "@app/components/personalInfo/personalInfoForm";
import { Notification } from "@app/components";
import { useDialog } from "@natera/platform/lib/hooks";
import PersonalInfoUpdateDialog from "@app/components/personalInfo/personalInfoDialog";
import { useHistory } from "react-router-dom";

import "./personalInfo.scss";
import { HEAP_EVENTS } from "@app/provider/types";
import { getTestNameForHeap } from "@app/utils";

const messages = defineMessages({
  personalInfoTitle: {
    id: "personalInfoTitle",
    defaultMessage: "Personal Information",
  },
  personalInfoUpdateNote: {
    id: "personalInfoUpdateNote",
    defaultMessage: "You can update your personal information {profileLink}",
  },
  profileLink: {
    id: "profileLink",
    defaultMessage: "here",
  },
});

const PersonalInfo: FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const personalInfoUpdateDialog = useDialog(PersonalInfoUpdateDialog);

  const { resolve } = React.useContext(StepperContext);
  const { uppUser, isLoading } = React.useContext(UserContext);
  const { profileData, profileDataIsLoading } = React.useContext(
    ProfileContext
  );
  const { orderData } = React.useContext(OrderContext);
  const orderUid = orderData?.uid;
  const limsId = orderData?.clinic.limsId;

  const handleNextButtonClick = () => {
    resolve(profileData);
  };

  const handleBackButtonClick = () => {
    history.goBack();
  };

  const handleDialog = () => {
    personalInfoUpdateDialog.open({
      onCloseDialog: () => personalInfoUpdateDialog.close(),
    });
    heap.track(HEAP_EVENTS.upp_simpleorder_editpersonalinfo, {
      tests_ordered: getTestNameForHeap(orderData),
      lims_clinic_id: limsId,
      order_uid: orderUid,
    });
  };

  return (
    <>
      {personalInfoUpdateDialog.getDialog()}
      <LoadingContext isLoading={profileDataIsLoading || isLoading}>
        <Notification
          className="update-info-notification__container"
          type="note"
        >
          {intl.formatMessage(messages.personalInfoUpdateNote, {
            profileLink: (
              <a onClick={handleDialog} key="profileLink">
                {intl.formatMessage(messages.profileLink)}
              </a>
            ),
          })}
        </Notification>

        <StepperHeaderProgress />
        <div className="stepper__container">
          <div className="stepper__head">
            <div className="stepper__head-title__container">
              <h3 className="stepper__head-title_text">
                {intl.formatMessage(messages.personalInfoTitle)}
              </h3>
            </div>
          </div>
          <PersonalInfoForm
            onSubmit={handleNextButtonClick}
            onBack={handleBackButtonClick}
            profileData={profileData}
            uppUser={uppUser}
          />
        </div>
      </LoadingContext>
    </>
  );
};

export default PersonalInfo;
