import React, { useMemo } from "react";
import * as R from "ramda";
import { useQuery } from "@apollo/client";
import { ErrorBody } from "@app/provider/user";
import TestCardService from "@app/service/testCard";
import { NotificationContext } from "@app/provider";
import { TestDetailsRecord } from "@app/provider/types";
import { ResultCodes } from "@app/service/resultCodes";
import { useHistory, useParams } from "react-router-dom";
import { useDialog } from "@natera/platform/lib/hooks";
import { LoadingContext } from "@natera/platform/lib/components/context";
import TestNotFoundModal from "@app/components/testNotFoundDialog/testNotFound";
import { getTestDetailsWithBrowserTimezone } from "./utils/getTestDetailsWithBrowserTimezone";
import { getDescription } from "@app/provider/testData/utils";
import { useIntl } from "react-intl";

type TestIndetifyer = {
  orderUid: string;
  testUid: string;
};

export interface TestProviderController {
  isLoading: boolean;
  getTestDetails: () => TestDetailsRecord | undefined;
  getOrderUid: () => string;
  getTestUid: () => string;
  refetchTest: () => Promise<TestDetailsRecord>;
}

export const Context = React.createContext<TestProviderController>({
  isLoading: false,
  getTestDetails: () => undefined,
  getOrderUid: R.always(""),
  getTestUid: R.always(""),
  refetchTest: () => Promise.reject(),
});

Context.displayName = "TestDetailsRecordContext";

const TestProvider: React.FC = ({ children }) => {
  const intl = useIntl();

  const history = useHistory();
  const testNotFoundDialog = useDialog(TestNotFoundModal);
  const { addNotification } = React.useContext(NotificationContext);

  const { orderUid, testUid } = useParams<TestIndetifyer>();

  const {
    data: testDetails,
    loading: isLoading,
    error: getTestCardDetailsError,
    refetch,
  } = useQuery<{
    getTestCardDetails: TestDetailsRecord;
  }>(TestCardService.getTestCardDetails(), {
    fetchPolicy: "network-only",
    variables: { orderUid, testUid },
  });

  const isResultPdfPage = React.useMemo(() => {
    return history.location.pathname.includes("result-pdf");
  }, [history.location.pathname]);

  React.useEffect(() => {
    handleGetTestDetails();
  }, [testDetails]);

  React.useEffect(() => {
    handleError();
  }, [getTestCardDetailsError]);

  const handleError = () => {
    if (
      (getTestCardDetailsError?.graphQLErrors?.[0]?.extensions
        ?.exception as ErrorBody)?.code === ResultCodes.TEST_NOT_FOUND
    ) {
      testNotFoundDialog.open({});
    }

    if (getTestCardDetailsError?.networkError && !isResultPdfPage) {
      addNotification({
        type: "error",
      });
    }
  };

  const handleGetTestDetails = () => {
    try {
      const testData = testDetails?.getTestCardDetails;

      if (!testData) {
        throw new Error();
      }
      testData.description = getDescription(intl, testData);
      return getTestDetailsWithBrowserTimezone(testData);
    } catch (error) {
      //
    }
  };

  const refetchTest = async () => {
    const testData = await refetch({ testUid, orderUid });
    return testData.data.getTestCardDetails;
  };

  const testProviderController: TestProviderController = useMemo(
    () => ({
      isLoading,
      getTestDetails: handleGetTestDetails,
      getOrderUid: R.always(orderUid),
      getTestUid: R.always(testUid),
      refetchTest,
    }),
    [isLoading, handleGetTestDetails, orderUid, testUid, refetchTest]
  );

  return (
    <Context.Provider value={testProviderController}>
      <LoadingContext isLoading={isLoading}>
        {testNotFoundDialog.getDialog()}
        {testDetails?.getTestCardDetails || isResultPdfPage ? children : <></>}
      </LoadingContext>
    </Context.Provider>
  );
};

export default TestProvider;
