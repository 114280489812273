import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/platform/lib/components/form";
import { SignUpContext } from "@app/provider";
import "./sendActivationEmailDialog.scss";

const messages = defineMessages({
  sendActivationEmailDialogTitle: {
    id: "sendActivationEmailDialogTitle",
    defaultMessage: "We’ll send you an email to activate your account",
  },
  sendActivationEmailDialogContent: {
    id: "sendActivationEmailDialogContent",
    defaultMessage: "Click the link in the email to activate your account.",
  },
  sendActivationEmailDialogButton: {
    id: "sendActivationEmailDialogButton",
    defaultMessage: "Send Activation Email",
  },
});

interface SendActivationEmailDialogProps {
  onSend: () => void;
  onClose: () => void;
}

const SendActivationEmailDialog: FC<SendActivationEmailDialogProps> = ({
  onSend,
  onClose,
}) => {
  const intl = useIntl();

  const { signUpByPasswordIsLoading } = useContext(SignUpContext);

  return (
    <div className="send-activation-email-dialog">
      <Dialog
        title={intl.formatMessage(messages.sendActivationEmailDialogTitle)}
        showCrossButton
        actions={
          <Button
            raised={true}
            loading={signUpByPasswordIsLoading}
            onClick={onSend}
          >
            {intl.formatMessage(messages.sendActivationEmailDialogButton)}
          </Button>
        }
        onClose={onClose}
      >
        <div className="send-activation-email-dialog__content">
          <span>
            {intl.formatMessage(messages.sendActivationEmailDialogContent)}
          </span>
        </div>
      </Dialog>
    </div>
  );
};

export default SendActivationEmailDialog;
