import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  billingCenterTitle: {
    id: "billingCenterTitle",
    defaultMessage: "Billing Center",
  },
});

const BillingCenter: FC = () => {
  const intl = useIntl();

  return (
    <div className="billing-center">
      <h2>{intl.formatMessage(messages.billingCenterTitle)}</h2>
    </div>
  );
};

export default BillingCenter;
