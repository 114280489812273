import React, { FC, createContext, useMemo } from "react";
import { useMutation, useLazyQuery, ApolloError } from "@apollo/client";
import { ManagedBy } from "@app/provider/testData/types";
import SimpleOrderService, {
  MarkOrderAsAccessedByPatientResponse,
} from "@app/service/simpleOrder";
import {
  PartnerInfoStepResolve,
  PatientAcknowledgmentStepResolve,
} from "./simpleOrder/SimpleOrderStepperProvider";
import { ShippingAddress } from "./types";

export enum SimpleOrderStage {
  START = "START",
  PERSONAL_INFO = "PERSONAL_INFO",
  KIT_SHIPPING_DETAILS = "KIT_SHIPPING_DETAILS",
  PARTNER_INFO = "PARTNER_INFO",
  BABY_GENDER = "BABY_GENDER",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  SAMPLE_DRAW_PREFERENCE = "SAMPLE_DRAW_PREFERENCE",
  CONFIRMATION = "CONFIRMATION",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export enum SimpleOrderPaymentMethod {
  COMMERCIAL = "COMMERCIAL",
  MEDICAID_GOVERNMENT = "MEDICAID_GOVERNMENT",
  SEVERAL_INSURANCES = "SEVERAL_INSURANCES",
  NO_PAYMENT = "NO_PAYMENT",
  SELF_PAID = "SELF_PAID",
}

export interface SimpleOrder {
  completionStage: SimpleOrderStage;
  watchedVideoPercentage: number;
  patient: {
    firstName: string;
    middleName?: string;
    lastName: string;
  };
  shippingAddress: {
    countryCode: string;
    city: string;
    state: string;
    street: string;
    zipCode: string;
  };
  partnerInfo: PartnerInfoStepResolve;
  reportGender: boolean;
  caPnsSupplemental: boolean;
  billingInfo: {
    paymentMethod: SimpleOrderPaymentMethod;
    primaryInsurance: {
      insuranceCompany: string;
      healthPlan: string;
      medicare: boolean;
      memberId: string;
      groupId: string;
      state: string;
    };
    secondaryInsurance?: {
      insuranceCompany: string;
      healthPlan: string;
      medicare: boolean;
      memberId: string;
      groupId: string;
      state: string;
    };
  };
  sampleManagedBy: ManagedBy;
  patientAcknowledgment: PatientAcknowledgmentStepResolve;
}

export enum InsuranceTypeDto {
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
}

type PaymentMethodInfoDto = {
  companyName: string;
  insuranceId: string;
};

type InsuranceInfoDto = {
  paymentMethod: string;
  paymentMethodInfo?: PaymentMethodInfoDto;
};

export type PaymentInfoDto = {
  insuranceType: InsuranceTypeDto;
  first: InsuranceInfoDto;
  second?: InsuranceInfoDto;
};

export interface SimpleOrderCompleteDto {
  shippingAddress: ShippingAddress;
  reportGender?: boolean;
  partnerInfo?: PartnerInfoStepResolve;
  payment: PaymentInfoDto;
  patientAcknowledgment: PatientAcknowledgmentStepResolve;
}

interface SimpleOrderProviderProps {
  orderUid: string;
}

interface CancelSimpleOrderResponse {
  success: boolean;
}

export interface SimpleOrderController {
  simpleOrderIsLoading: boolean;
  simpleOrderData?: SimpleOrder;
  updateSimpleOrder: (simpleOrder: Partial<SimpleOrder>) => Promise<void>;
  getSimpleOrder: () => Promise<SimpleOrder | undefined>;
  completeSimpleOrder: (
    workflowUid: string,
    simpleOrder: SimpleOrderCompleteDto,
    testName?: string,
    limsId?: string
  ) => Promise<void>;
  completeSimpleOrderLoading: boolean;
  failedCompleteAttempts: number;
  setFailedCompleteAttempts: React.Dispatch<React.SetStateAction<number>>;
  cancelSimpleOrder: (testName?: string, limsId?: string) => Promise<void>;
  cancelSimpleOrderData?: CancelSimpleOrderResponse;
  cancelSimpleOrderError?: ApolloError;
  cancelSimpleOrderIsLoading?: boolean;
  markOrderAsAccessedByPatient: (
    orderUid: string,
    workflowUid: string,
    testName?: string,
    limsClinicId?: string
  ) => Promise<void>;
}

export const Context = createContext<SimpleOrderController>({
  simpleOrderIsLoading: false,
  simpleOrderData: undefined,
  updateSimpleOrder: () => Promise.reject(),
  getSimpleOrder: () => Promise.reject(),
  completeSimpleOrder: () => Promise.reject(),
  completeSimpleOrderLoading: false,
  failedCompleteAttempts: 0,
  setFailedCompleteAttempts: () => undefined,
  cancelSimpleOrder: () => Promise.reject(),
  cancelSimpleOrderIsLoading: false,
  markOrderAsAccessedByPatient: () => Promise.reject(),
});

Context.displayName = "SimpleOrderContext";

const SimpleOrderProvider: FC<SimpleOrderProviderProps> = ({
  orderUid,
  children,
}) => {
  const [simpleOrderData, setSimpleOrderData] = React.useState<
    SimpleOrder | undefined
  >();

  const [failedCompleteAttempts, setFailedCompleteAttempts] = React.useState(0);

  const [
    getSimpleOrderQuery,
    { loading: simpleOrderIsLoading },
  ] = useLazyQuery<{
    getSimpleOrder: SimpleOrder;
  }>(SimpleOrderService.getSimpleOrder());

  const getSimpleOrder = async () => {
    const result = await getSimpleOrderQuery({
      variables: { orderUid },
    });
    setSimpleOrderData(result.data?.getSimpleOrder);
    return result.data?.getSimpleOrder;
  };

  const [updateSimpleOrder] = useMutation<{
    updateSimpleOrder: SimpleOrder;
  }>(SimpleOrderService.updateSimpleOrder());

  const [
    completeSimpleOrderQuery,
    { loading: completeSimpleOrderLoading },
  ] = useMutation<{
    simpleOrder: SimpleOrder;
  }>(SimpleOrderService.completeSimpleOrder());

  const [markOrderAsAccessedByPatientQuery] = useMutation<{
    markOrderAsAccessedByPatient: MarkOrderAsAccessedByPatientResponse;
  }>(SimpleOrderService.markOrderAsAccessedByPatient());

  const handleUpdateSimpleOrder = async (
    simpleOrderInfo: Partial<SimpleOrder>
  ) => {
    const response = await updateSimpleOrder({
      variables: {
        simpleOrderInfo,
        orderUid,
      },
    });

    if (response.data) {
      setSimpleOrderData(response.data.updateSimpleOrder);
    }
  };

  const completeSimpleOrder = async (
    workflowUid: string,
    simpleOrderInfo: SimpleOrderCompleteDto,
    testName: string,
    limsClinicId: string
  ) => {
    await completeSimpleOrderQuery({
      variables: {
        orderUid,
        workflowUid,
        simpleOrderInfo,
        testName,
        limsClinicId,
      },
    });
  };

  const [
    cancelSimpleOrder,
    {
      error: cancelSimpleOrderError,
      data: cancelSimpleOrderData,
      loading: cancelSimpleOrderIsLoading,
    },
  ] = useMutation<{
    cancelSimpleOrder: CancelSimpleOrderResponse;
  }>(SimpleOrderService.cancelSimpleOrder());

  const handleCancelSimpleOrder = async (
    testName: string,
    limsClinicId: string
  ) => {
    await cancelSimpleOrder({
      variables: { orderUid, testName, limsClinicId },
    });
  };

  const markOrderAsAccessedByPatient = async (
    orderUid: string,
    workflowUid: string,
    testName: string,
    limsClinicId: string
  ) => {
    await markOrderAsAccessedByPatientQuery({
      variables: {
        orderUid,
        workflowUid,
        testName,
        limsClinicId,
      },
    });
  };

  const simpleOrderController: SimpleOrderController = useMemo(
    () => ({
      simpleOrderIsLoading,
      simpleOrderData,
      getSimpleOrder,
      updateSimpleOrder: handleUpdateSimpleOrder,
      completeSimpleOrder,
      completeSimpleOrderLoading,
      failedCompleteAttempts,
      setFailedCompleteAttempts,
      cancelSimpleOrder: handleCancelSimpleOrder,
      cancelSimpleOrderData: cancelSimpleOrderData?.cancelSimpleOrder,
      cancelSimpleOrderError,
      cancelSimpleOrderIsLoading,
      markOrderAsAccessedByPatient,
    }),
    [
      simpleOrderIsLoading,
      simpleOrderData,
      getSimpleOrder,
      handleUpdateSimpleOrder,
      completeSimpleOrder,
      completeSimpleOrderLoading,
      failedCompleteAttempts,
      setFailedCompleteAttempts,
      handleCancelSimpleOrder,
      cancelSimpleOrderData,
      cancelSimpleOrderError,
      cancelSimpleOrderIsLoading,
      markOrderAsAccessedByPatient,
    ]
  );

  return (
    <Context.Provider value={simpleOrderController}>
      {children}
    </Context.Provider>
  );
};

export default SimpleOrderProvider;
