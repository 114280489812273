export const convertDate = (
  date: string | Date | undefined
): string | undefined => {
  if (!date) return;

  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const convertSampleCollectionDate = (
  sampleCollectionDate: string
): string => {
  const [y, m, d] = sampleCollectionDate.split("T")[0].split("-");
  return `${m}/${d}/${y}`;
};
