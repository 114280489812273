import React, { FC } from "react";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { defineMessages, useIntl } from "react-intl";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { Steps } from "../../simpleOrderStepper";
import {
  PartnerInfoStepResolve,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import SignatureForm, {
  SignatureFormType,
} from "@app/components/simpleOrder/signature/signatureForm";
import "@app/components/simpleOrder/signature/signature.scss";
import { ProfileContext } from "@app/provider";

const messages = defineMessages({
  partnerSignatureTitle: {
    id: "partnerSignatureTitle",
    defaultMessage: "Sign and agree to share results with partner",
  },
  partnerSignatureText_1: {
    id: "partnerSignatureText_1",
    defaultMessage:
      "An authorized representative cannot agree and sign on behalf of the patient.",
  },
  partnerSignatureText_2: {
    id: "partnerSignatureText_2",
    defaultMessage:
      "By providing my first name and last name, I acknowledge that I am electronically signing.",
  },
});

interface PartnerSignatureProps {
  goBack: () => void;
  resolve: (subStepData: PartnerInfoStepResolve) => void;
}

const PartnerSignature: FC<PartnerSignatureProps> = ({ goBack, resolve }) => {
  const intl = useIntl();
  const { profileData } = React.useContext(ProfileContext);
  const { getSubStepData } = React.useContext(SimpleOrderStepperContext);

  const subStepsData = getSubStepData(
    Steps.PARTNER_INFO
  ) as PartnerInfoStepResolve;

  const initValues = {
    lastName: subStepsData?.signature?.lastName,
    firstName: subStepsData?.signature?.firstName,
  } as SignatureFormType;

  const handleSubmit = (formData: SignatureFormType) => {
    resolve({
      ...subStepsData,
      consentTimestamp: new Date().toISOString(),
      signature: formData,
    });
  };

  return (
    <>
      <StepperHeaderProgress />
      <div className="signature">
        <StepperContent
          title={intl.formatMessage(messages.partnerSignatureTitle)}
          description={
            <>
              <span>{intl.formatMessage(messages.partnerSignatureText_1)}</span>
              <span>{intl.formatMessage(messages.partnerSignatureText_2)}</span>
            </>
          }
        >
          <SignatureForm
            onSubmit={handleSubmit}
            goBack={goBack}
            initValues={initValues}
            profile={profileData}
          />
        </StepperContent>
      </div>
    </>
  );
};

export default PartnerSignature;
