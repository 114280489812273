import React, { FC, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/platform/lib/components/form";
import "./comboOrderModal.scss";

const messages = defineMessages({
  comboOrderModalMessageTitle: {
    id: "comboOrderModalMessageTitle",
    defaultMessage: "Combo order",
  },
  comboOrderModalMessageItem1: {
    id: "comboOrderModalMessageItem1",
    defaultMessage: "Your order includes multiple tests:\n",
  },
  comboOrderModalMessageItem2: {
    id: "comboOrderModalMessageItem2",
    defaultMessage:
      "You only need to schedule one appointment for all tests in your order.",
  },

  comboOrderModalMessageContinue: {
    id: "comboOrderModalMessageContinue",
    defaultMessage: "Continue",
  },
  comboOrderModalMessageItemsAnd: {
    id: "comboOrderModalMessageItemsAnd",
    defaultMessage: " and ",
  },
});

type Props = {
  orderTypes: string[];
  onClose: () => void;
};

const ComboOrderModal: FC<Props> = ({ orderTypes, onClose }) => {
  const intl = useIntl();

  const orderTypesList = useMemo(
    () =>
      orderTypes.slice(0, -1).join(", ") +
      ` ${intl.formatMessage(messages.comboOrderModalMessageItemsAnd)} ` +
      orderTypes.slice(-1),
    [orderTypes]
  );

  return (
    <Dialog
      className="mobile-phlebotomy-modal combo-order-modal"
      onClose={onClose}
      title={intl.formatMessage(messages.comboOrderModalMessageTitle)}
      showCrossButton
      actions={
        <Button name="Continue" raised onClick={onClose}>
          {intl.formatMessage(messages.comboOrderModalMessageContinue)}
        </Button>
      }
    >
      <div>
        <p>
          <span>
            {intl.formatMessage(messages.comboOrderModalMessageItem1)}
          </span>
          <br />
          <span>{orderTypesList}</span>
        </p>
        <p>
          <span>
            {intl.formatMessage(messages.comboOrderModalMessageItem2)}
          </span>
        </p>
      </div>
    </Dialog>
  );
};

export default ComboOrderModal;
