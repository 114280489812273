import React, { FC, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDialog } from "@natera/platform/lib/hooks";
import {
  RedirectionContext,
  TestCardContext,
  TestResultContext,
  UserContext,
} from "@app/provider";
import {
  SpinnerView,
  TestCard,
  SecondaryActionCard,
  FinancialAidCard,
  FlowHeader,
} from "@app/components";
import { routes } from "@app/routing";
import { STAGE } from "@app/service/user";
import SetUpProfileDialog from "./setUpProfileDialog";
import "./home.scss";
import NoActiveTestsView from "./noActiveTestsView";
import classNames from "classnames";
import { useTokenizedLinks } from "@app/hooks";
import { SecondaryActionCardType } from "@app/provider/testData/types";

const Home: FC = () => {
  const history = useHistory();
  const { isLoading: isLoadingLink } = useTokenizedLinks();
  const { redirectionTrigger, handleRedirection } = useContext(
    RedirectionContext
  );

  useEffect(() => {
    if (redirectionTrigger) {
      handleRedirection();
    }
  }, [redirectionTrigger]);

  const { isLoading: isUppUserLoading, uppUser, loadProfile } = useContext(
    UserContext
  );

  const {
    isLoading: isTestCardLoading,
    getTestCards,
    testCards,
    secondaryActionCards,
    isTestsHistoryExists,
  } = useContext(TestCardContext);

  const {
    testResultInfoToken,
    getTestResultInfoFromToken,
    setTestResultInfoToken,
    isLoading: isTestResultLoading,
  } = useContext(TestResultContext);

  const setUpProfileDialog = useDialog(SetUpProfileDialog);

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    if (
      uppUser?.patientUID &&
      !isTestCardLoading &&
      uppUser?.stage === STAGE.FILLED
    ) {
      getTestCards();
    }
  }, [uppUser?.patientUID]);

  useEffect(() => {
    switch (uppUser?.stage) {
      case STAGE.BLANK: {
        setUpProfileDialog.open({
          isLoading: isUppUserLoading,
          onSubmit: () => {
            if (uppUser.invite) {
              history.replace(
                routes.dateOfBirthVerification + history.location.search
              );
            } else {
              history.replace(routes.setupProfile + history.location.search);
            }
          },
        });

        break;
      }

      case STAGE.PROFILE_SETUP: {
        setUpProfileDialog.open({
          isLoading: isUppUserLoading,
          onSubmit: () => {
            history.replace(routes.setupProfile);
          },
        });

        break;
      }

      case STAGE.MFA_SETUP:
      case STAGE.COMMUNICATION_PREFERENCES: {
        if (uppUser.invite) {
          history.replace(routes.confirmation + history.location.search);
        } else {
          history.replace(routes.setupProfile);
        }
        break;
      }

      case STAGE.FILLED: {
        if (testResultInfoToken) {
          getTestResultInfoFromToken(testResultInfoToken)
            .then((info) => {
              if (info) {
                history.replace(
                  routes.testResultDetailsPage(info.orderUid, info.testUid)
                );
              }
            })
            .finally(() => {
              setTestResultInfoToken("");
            });
        }

        break;
      }
    }
  }, [uppUser]);

  const renderTestCards = () => (
    <div className="home__test-cards-column">
      {testCards?.map((testCard) => (
        <TestCard key={testCard.uid} testCard={testCard} leftSideClickable />
      ))}
    </div>
  );

  const renderSecondaryActionCards = () => {
    return (
      <div className="home__test-cards-column">
        {secondaryActionCards.map((card) =>
          card.type === SecondaryActionCardType.COMPASSIONATE_CARE ? (
            <FinancialAidCard
              key={card.type}
              limsClinicId={card.limsClinicId}
              title={card.title!}
              description={card.description!}
              note={card.note}
              showIcon
              showDialog
            />
          ) : (
            <SecondaryActionCard key={card.type} card={card} />
          )
        )}
      </div>
    );
  };

  const isLoading =
    isUppUserLoading ||
    isTestCardLoading ||
    isTestResultLoading ||
    isLoadingLink;

  const noActiveTestsView =
    uppUser?.stage === STAGE.FILLED &&
    testCards &&
    testCards.length === 0 &&
    !isLoading;

  return (
    <section
      className={classNames("home__container", {
        no_active_tests_view: noActiveTestsView,
      })}
      data-testid="home-page"
    >
      <SpinnerView isLoading={isLoading} />
      {uppUser?.stage !== STAGE.FILLED && <FlowHeader />}
      {setUpProfileDialog.getDialog()}
      <div className="home__test-cards">
        {testCards && renderTestCards()}
        {renderSecondaryActionCards()}
      </div>
      {noActiveTestsView && (
        <NoActiveTestsView isTestsHistoryExists={isTestsHistoryExists} />
      )}
    </section>
  );
};

export default Home;
