import Svg from "@natera/material/lib/svg";
import React from "react";

import "./subCard.scss";

type Props = {
  onClick?: () => void;
  title: string;
  icon: string;
};

const SubCard: React.FC<Props> = ({ onClick, title, icon }) => {
  return (
    <div className="sub-card" onClick={onClick}>
      <Svg content={icon} />
      <span className="title">{title}</span>
    </div>
  );
};

export default SubCard;
