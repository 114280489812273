import { DrawRequest, TestDetailsRecord } from "@app/provider/types";
import { TimeZoneType, getLocalForTimezone } from "@app/utils";

export const getTestDetailsWithBrowserTimezone = (
  testDetailsData: TestDetailsRecord
): TestDetailsRecord => {
  if (testDetailsData?.drawRequest) {
    return {
      ...testDetailsData,
      drawRequest: getDrawRequestWithBrowserTimezone(
        testDetailsData?.drawRequest
      ),
    };
  }

  return testDetailsData;
};

export const getDrawRequestWithBrowserTimezone = (
  drawRequest?: DrawRequest
): DrawRequest | undefined => {
  if (drawRequest?.jobTimezone) {
    const mappedTimezone =
      getLocalForTimezone[drawRequest.jobTimezone as TimeZoneType];

    return {
      ...drawRequest,
      jobTimezone: mappedTimezone,
    };
  }

  return drawRequest;
};
