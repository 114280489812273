import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";

import NoContentView from "../../../private/noContentView";
import { DrawRequestContext } from "@app/provider";
import { contactPhoneMapper } from "@app/utils/contactPhoneMapper";

const messages = defineMessages({
  createBloodDrawFailedScreenTitle: {
    id: "createBloodDrawFailedScreenTitle",
    defaultMessage: "We're sorry.",
  },
  createBloodDrawFailedScreenDescription: {
    id: "createBloodDrawFailedScreenDescription",
    defaultMessage:
      "We are unable to submit your appointment{br}request at this time.",
  },
  createBloodDrawFailedScreenContactUs: {
    id: "createBloodDrawFailedScreenContactUs",
    defaultMessage: "Please call{br}{phone}",
  },
});

const CreateBloodDrawFailedScreen: FC = () => {
  const intl = useIntl();

  const { verifiedSampleDrawData } = React.useContext(DrawRequestContext);

  const phone = contactPhoneMapper(verifiedSampleDrawData?.businessUnit);

  return (
    <NoContentView
      title={intl.formatMessage(messages.createBloodDrawFailedScreenTitle)}
      message={intl.formatMessage(
        messages.createBloodDrawFailedScreenDescription,
        {
          br: <br />,
        }
      )}
      contactInfo={intl.formatMessage(
        messages.createBloodDrawFailedScreenContactUs,
        {
          br: <br />,
          phone,
        }
      )}
    />
  );
};

export default CreateBloodDrawFailedScreen;
