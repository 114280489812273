import { StepId, StepperContext } from "@natera/stepper";
import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { EnterOtherInsuranceCompanyForm } from "../enterOtherInsuranceCompanyStep/enterOtherInsuranceCompanyForm";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { SubSteps } from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { Payor } from "@app/provider/payor";
import {
  MultiplePaymentMethodType,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import { Steps } from "@app/pages/private/simpleOrder";
import {
  isCommercialOrGovernmentInsuranceFirst,
  isCommercialOrGovernmentInsuranceSecond,
} from "@app/utils";

const messages = defineMessages({
  enterOtherInsuranceCompanyStepHeaderTitle: {
    id: "enterOtherInsuranceCompanyStepHeaderTitle",
    defaultMessage: "Payment Method",
  },
  enterOtherCommercialStepHeaderSubTitle: {
    id: "enterOtherCommercialStepHeaderSubTitle",
    defaultMessage: "Enter insurance company",
  },
  enterOtherGovernmentStepHeaderSubTitle: {
    id: "enterOtherGovernmentStepHeaderSubTitle",
    defaultMessage: "Enter government-issued insurance information",
  },
});

interface Props {
  previousStepId?: StepId;
}

const EnterOtherInsuranceCompanyStep: React.FC<Props> = ({
  previousStepId = SubSteps.SELECT_COMMERCIAL_COMPANY,
}) => {
  const intl = useIntl();
  const { goAt, resolve, getCurrentStep } = React.useContext(StepperContext);

  const currentStepId = getCurrentStep()?.stepId;

  const { getSubStepData, resolveSubStep } = useContext(
    SimpleOrderStepperContext
  );
  const subStepsData = getSubStepData(Steps.PAYMENT_METHOD);

  const isInsuranceFirstStep = isCommercialOrGovernmentInsuranceFirst(
    currentStepId
  );
  const isInsuranceSecondStep = isCommercialOrGovernmentInsuranceSecond(
    currentStepId
  );

  const governmentSteps = [
    SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY,
    SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY_FIRST,
    SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY_SECOND,
  ];
  const subTitle =
    currentStepId && governmentSteps.includes(currentStepId as SubSteps)
      ? intl.formatMessage(messages.enterOtherGovernmentStepHeaderSubTitle)
      : intl.formatMessage(messages.enterOtherCommercialStepHeaderSubTitle);

  const handleSubmit = (company: Payor) => {
    const enterOtherStepData = {
      otherCompanyName: company.displayName,
      otherCompanyGroupId: company.groupId,
    };

    let subStepDataWithOtherCompany;

    if (isInsuranceFirstStep) {
      subStepDataWithOtherCompany = {
        paymentMethodInfo: {
          first: {
            paymentMethodInfo: {
              ...(subStepsData as MultiplePaymentMethodType)?.paymentMethodInfo
                ?.first?.paymentMethodInfo,
              ...enterOtherStepData,
            },
          },
        },
      };
    } else if (isInsuranceSecondStep) {
      subStepDataWithOtherCompany = {
        paymentMethodInfo: {
          second: {
            paymentMethodInfo: {
              ...(subStepsData as MultiplePaymentMethodType)?.paymentMethodInfo
                ?.second?.paymentMethodInfo,
              ...enterOtherStepData,
            },
          },
        },
      };
    } else {
      subStepDataWithOtherCompany = {
        paymentMethodInfo: {
          ...enterOtherStepData,
        },
      };
    }

    resolveSubStep({
      stepId: Steps.PAYMENT_METHOD,
      subStepData: subStepDataWithOtherCompany,
    });

    resolve(enterOtherStepData);
  };

  const handleBack = () => {
    goAt(previousStepId);
  };

  return (
    <StepperContent
      title={intl.formatMessage(
        messages.enterOtherInsuranceCompanyStepHeaderTitle
      )}
      subTitle={subTitle}
    >
      <EnterOtherInsuranceCompanyForm
        onSubmit={handleSubmit}
        goBack={handleBack}
      />
    </StepperContent>
  );
};

export default EnterOtherInsuranceCompanyStep;
