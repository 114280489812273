import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, ReactElement, useContext, useEffect } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { ResultCodes } from "@app/service/resultCodes";
import { useErrorController } from "@natera/platform/lib/hooks";
import { Form, Button } from "@natera/platform/lib/components/form";
import { DateInput } from "@natera/platform/lib/components/form/field";
import { FlowHeader, FormField, Progress, LinkHeap } from "@app/components";
import {
  ProfileContext,
  NotificationContext,
  UppAuthContext,
} from "@app/provider";
import { routes } from "@app/routing";
import { isPatientMinor, weekDayFormatter } from "@app/utils";
import acknowledgements from "@etc/acknowledgements.json";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";
import "./dateOfBirthVerification.scss";
import DateOfBirthVerificationFailed from "@app/pages/private/dateOfBirthVerification/dateOfBirthVerificationFailed";
import { ErrorBody } from "@app/provider/user";

const messages = defineMessages({
  dateOfBirthVerificationStep: {
    id: "dateOfBirthVerificationStep",
    defaultMessage: "Step {number} of {count}",
  },
  dateOfBirthVerificationTitle: {
    id: "dateOfBirthVerificationTitle",
    defaultMessage: "Verify Your Date of Birth",
  },
  dateOfBirthVerificationIssues: {
    id: "dateOfBirthVerificationIssues",
    defaultMessage: "Having issues with verification? {contactUs}",
  },
  dateOfBirthVerificationDateOfBirth: {
    id: "dateOfBirthVerificationDateOfBirth",
    defaultMessage: "Date of Birth",
  },
  dateOfBirthVerificationVerify: {
    id: "dateOfBirthVerificationVerify",
    defaultMessage: "Verify",
  },
  dateOfBirthVerificationVerifyContactUs: {
    id: "dateOfBirthVerificationVerifyContactUs",
    defaultMessage: "Contact Us",
  },
  dateOfBirthVerificationInvalidDateOfBirth: {
    id: "dateOfBirthVerificationInvalidDateOfBirth",
    defaultMessage: "Invalid Date of Birth",
  },
  dateOfBirthVerificationIncorrectDateOfBirth: {
    id: "dateOfBirthVerificationIncorrectDateOfBirth",
    defaultMessage: "Incorrect date of birth. Please re-enter",
  },
  dateOfBirthVerificationUnder18DateOfBirth: {
    id: "dateOfBirthVerificationUnder18DateOfBirth",
    defaultMessage: "You must be 18 years or older to create an account",
  },
  dateOfBirthVerificationProfileAlreadyLinked: {
    id: "dateOfBirthVerificationProfileAlreadyLinked",
    defaultMessage:
      "Our records show that you already have an account with us. Please log in with the existing account.",
  },
  dateOfBirthVerificationProfileLogin: {
    id: "dateOfBirthVerificationProfileLogin",
    defaultMessage: "Log In",
  },
  dateOfBirthPlaceholder: {
    id: "dateOfBirthPlaceholder",
    defaultMessage: "MM/DD/YYYY",
  },
});

const ATTEMPTS = 5;

const DateOfBirthVerification: FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const {
    getValidationError,
    clearErrors,
    setValidationError,
  } = useErrorController();

  const { logout } = useContext(UppAuthContext);

  const [attemptsLeft, setAttemptsLeft] = React.useState<number>(ATTEMPTS);

  const {
    verifyProfileIsLoading,
    verifyProfile,
    verifyProfileError,
  } = useContext(ProfileContext);
  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (verifyProfileError) {
      if (
        (verifyProfileError.graphQLErrors[0]?.extensions
          ?.exception as ErrorBody)?.code ===
        ResultCodes.PROFILE_ALREADY_LINKED_ERROR
      ) {
        addNotification({
          type: "error",
          message: intl.formatMessage(
            messages.dateOfBirthVerificationProfileAlreadyLinked
          ),
          actions: (
            <Link to={`${routes.logOut}?code=${ResultCodes.SIGN_OUT}`}>
              {intl.formatMessage(messages.dateOfBirthVerificationProfileLogin)}
            </Link>
          ),
        });
      } else {
        addNotification({ type: "error" });
      }
    }
  }, [verifyProfileError]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    clearErrors();

    const formData = e.currentTarget;

    const dateOfBirth = formData.dateOfBirth.value;

    if (!dateOfBirth) {
      setValidationError(
        "dateOfBirth",
        intl.formatMessage(messages.dateOfBirthVerificationInvalidDateOfBirth)
      );

      return;
    }

    const dOB = new Date(dateOfBirth);

    if (isPatientMinor(dOB)) {
      setValidationError(
        "dateOfBirth",
        intl.formatMessage(messages.dateOfBirthVerificationUnder18DateOfBirth)
      );

      return;
    }

    const verifyProfileData = await verifyProfile(dateOfBirth);

    if (verifyProfileData?.isVerified) {
      history.replace(routes.confirmation + history.location.search);
    }

    setAttemptsLeft((prev) => prev - 1);

    if (!verifyProfileData?.isVerified) {
      setValidationError(
        "dateOfBirth",
        intl.formatMessage(messages.dateOfBirthVerificationIncorrectDateOfBirth)
      );
    }
  };

  const formElement: ReactElement = (
    <Form
      className="date-of-birth-verification__form__container"
      noValidate
      onSubmit={handleSubmit}
    >
      <FormField
        label={intl.formatMessage(messages.dateOfBirthVerificationDateOfBirth)}
        required
        error={getValidationError("dateOfBirth")}
        htmlFor="date"
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <DateInput
            id="date"
            name="dateOfBirth"
            maxDate={new Date()}
            required
            outline
            placeholder={intl.formatMessage(messages.dateOfBirthPlaceholder)}
            formatWeekdayName={weekDayFormatter}
          />
        </HeapPHIDecorator>
      </FormField>
      <Button
        type="submit"
        raised
        loading={verifyProfileIsLoading}
        disabled={attemptsLeft === 0}
      >
        {intl.formatMessage(messages.dateOfBirthVerificationVerify)}
      </Button>
    </Form>
  );

  return attemptsLeft > 0 ? (
    <>
      <article className="date-of-birth-verification__container">
        <FlowHeader />
        <Progress className="date-of-birth-verification__progress" value={33} />
        <div className="date-of-birth-verification__wrapper">
          <section className="date-of-birth-verification__content">
            <h2 className="step-number">
              {intl.formatMessage(messages.dateOfBirthVerificationStep, {
                number: 1,
                count: 3,
              })}
            </h2>
            <h3 className="date-of-birth-verification__title">
              {intl.formatMessage(messages.dateOfBirthVerificationTitle)}
            </h3>
            {formElement}
            <p className="date-of-birth-verification__bottom-text">
              {intl.formatMessage(messages.dateOfBirthVerificationIssues, {
                contactUs: (
                  <LinkHeap
                    target="_blank"
                    key="privacyPolicy"
                    rel="noreferrer"
                    to={{ pathname: acknowledgements.links.contactUs }}
                    heapEventName={HEAP_EVENTS.upp_click_contactnatera}
                    heapEventProps={{
                      location:
                        HeapEventLocation.verification_account_creation_page,
                    }}
                  >
                    {intl.formatMessage(
                      messages.dateOfBirthVerificationVerifyContactUs
                    )}
                  </LinkHeap>
                ),
              })}
            </p>
          </section>
        </div>
      </article>
    </>
  ) : (
    <DateOfBirthVerificationFailed onSubmit={logout} />
  );
};

export default DateOfBirthVerification;
