import React, { FC } from "react";

import { Route, Switch } from "react-router-dom";
import ContactDetailsInfo from "@app/pages/private/contactDetails/contactDetailsInfo";
import { routes } from "@app/routing";
import CreateOrUpdateAddress from "@app/pages/private/contactDetails/createOrUpdateAddress";
import UpdatePhone from "@app/pages/private/contactDetails/updatePhone";

const ContactDetails: FC = () => {
  return (
    <Switch>
      <Route
        path={routes.contactDetailsCreateOrUpdateAddress}
        component={CreateOrUpdateAddress}
      />
      <Route path={routes.contactDetailsUpdatePhone} component={UpdatePhone} />
      <Route path="/" component={ContactDetailsInfo} />
    </Switch>
  );
};

export default ContactDetails;
