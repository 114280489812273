import React from "react";

const WISTIA_ASSETS_URL = "https://fast.wistia.com/assets/external/E-v1.js";

export const useWistiaScript = (): null => {
  React.useEffect(() => {
    const container = document.head || document.querySelector("head");
    const script = document.querySelector(`script[src="${WISTIA_ASSETS_URL}"]`);

    if (!script) {
      const scriptElement = document.createElement("script");
      scriptElement.async = true;
      scriptElement.src = WISTIA_ASSETS_URL;

      container.appendChild(scriptElement);
      return scriptElement.remove();
    }

    return script.remove();
  }, []);

  return null;
};
