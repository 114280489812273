import React, { FC, createContext, useContext, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import FetalSexService from "@app/service/fetalSex";
import { NotificationContext } from "@app/provider";

interface UpdateFetalSex {
  testUid: string;
  fetalSexIsShown: boolean;
  documentUid: string;
}

export interface FetalSexController {
  getFetalSexIsShown: (
    testUid: string
  ) => Promise<Record<string, string | boolean> | undefined>;
  handleUpdateFetalSexIsShown: (
    orderUid: string,
    testUid: string,
    fetalSexIsShown: boolean
  ) => Promise<UpdateFetalSex | undefined>;
  updateFetalSexIsShownIsLoading: boolean;
}
export const Context = createContext<FetalSexController>({
  getFetalSexIsShown: () => Promise.reject(),
  handleUpdateFetalSexIsShown: () => Promise.reject(),
  updateFetalSexIsShownIsLoading: false,
});

Context.displayName = "FetalSexContext";

const FetalSexProvider: FC = ({ children }) => {
  const { addNotification } = useContext(NotificationContext);

  const [
    updateFetalSexIsShown,
    {
      error: updateFetalSexIsShownError,
      loading: updateFetalSexIsShownIsLoading,
    },
  ] = useMutation<{ updateFetalSexIsShown: UpdateFetalSex | undefined }>(
    FetalSexService.updateFetalSexIsShown()
  );

  useEffect(() => {
    if (updateFetalSexIsShownError) {
      addNotification({ type: "error" });
    }
  }, [updateFetalSexIsShownError]);

  const handleUpdateFetalSexIsShown = async (
    orderUid: string,
    testUid: string,
    fetalSexIsShown: boolean
  ) => {
    try {
      const res = await updateFetalSexIsShown({
        variables: {
          orderUid,
          testUid,
          fetalSexIsShown,
        },
      });

      if (!res.data) {
        throw new Error();
      }

      return res.data.updateFetalSexIsShown;
    } catch (error) {
      throw new Error(error);
    }
  };

  const [getFetalSexQuery] = useLazyQuery<{
    getFetalSexIsShown: Record<string, string | boolean>;
  }>(FetalSexService.getFetalSex(), {
    fetchPolicy: "no-cache",
  });

  const getFetalSexIsShown = async (orderUid: string) => {
    const result = await getFetalSexQuery({
      variables: {
        orderUid,
      },
    });

    return result.data?.getFetalSexIsShown;
  };

  return (
    <Context.Provider
      value={{
        getFetalSexIsShown,
        handleUpdateFetalSexIsShown,
        updateFetalSexIsShownIsLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default FetalSexProvider;
