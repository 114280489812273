import React, { FC, useState, useEffect, useContext, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";

import UserService, { STAGE, UppUser, IDP_TYPE } from "@app/service/user";
import {
  useMutation,
  useLazyQuery,
  FetchResult,
  isApolloError,
} from "@apollo/client";
import {
  UppAuthContext,
  Language,
  IntlContext,
  NotificationContext,
} from "@app/provider";
import { ResultCodes } from "@app/service/resultCodes";

export interface User {
  id?: string;
  patientUID?: string;
  stage?: STAGE;
  email?: string;
}

export enum LoginStatus {
  FAIL = "FAIL",
  SUCCESS = "SUCCESS",
}

interface newUserDTO {
  oktaUID: string;
  email: string;
  invite: string | null;
}

export interface UpdateUserDTO {
  language?: Language;
  askForMfa?: boolean;
  stage?: STAGE;
}

export interface UpdateUserLanguageDTO {
  email: string | null;
  language: Language;
}

interface ErrorResponse {
  message: string;
}

export interface ErrorBody {
  code: string;
  message: string;
}

export interface UpdateEmailData {
  newEmail: string;
}

export interface LogUserLoginResponse {
  attemptsLeft?: number;
  isAccountActivated?: boolean;
}

interface ChangePasswordDTO {
  oldPassword: string;
  newPassword: string;
}

export interface ChangePasswordData {
  success: boolean;
}

interface UnlockUserData {
  unlockedUser?: { success: boolean; email: string };
  unlockError?: string;
}

export interface AddPatientAcknowledgementDTO {
  email: string;
  accepted: boolean;
  acknowledgementText: string;
  consentTimestamp: string;
}

interface AddPatientAcknowledgementResponse {
  email: string;
  accepted: boolean;
}

export interface UserController {
  isLoading: boolean;
  uppUser: UppUser | undefined;
  uppUserError: ErrorBody[];
  showError: (err?: ErrorResponse) => void;
  createUser: (newUser: newUserDTO) => Promise<void>;
  createUserError?: string;
  resetCreateUserError: () => void;
  loadProfile: () => Promise<UppUser | undefined>;
  updateUser: (userData: UpdateUserDTO) => Promise<void>;
  updatedUser?: UppUser | null;
  updateUserLanguageNonAuth: (
    userLanguageData: UpdateUserLanguageDTO
  ) => Promise<void>;
  updateEmail: (newEmail: string) => Promise<UpdateEmailData | undefined>;
  updateEmailData?: UpdateEmailData;
  updateEmailError?: ErrorBody;
  logUserLogin: (
    email: string,
    status: LoginStatus,
    token?: string | null
  ) => Promise<LogUserLoginResponse | undefined>;
  handleUserLogin: () => Promise<void>;
  unlockUser: (
    email: string,
    dateOfBirth?: string,
    lastName?: string
  ) => Promise<FetchResult<{ success: boolean; email: string }>>;
  unlockedUser?: { success: boolean; email: string };
  changePassword: (
    props: ChangePasswordDTO
  ) => Promise<ChangePasswordData | undefined>;
  changePasswordError?: ResultCodes;
  resetChangePasswordError: () => void;
  deleteUser: (
    email: string
  ) => Promise<FetchResult<{ success: boolean; email: string }>>;
  deletedUserData?: { success: boolean; email: string };
  unlockError?: string;
  canEdit?: boolean;
  getShowUnlockForm: (email: string) => Promise<boolean>;
  clearUnlockUserData: () => void;
  addPatientAcknowledgement: (
    acknowledgementData: AddPatientAcknowledgementDTO
  ) => Promise<void>;
  addPatientAcknowledgementLoading?: boolean;
  validatePhoneNumber: (
    phone: string
  ) => Promise<{ isValid: boolean } | undefined>;
}

export const Context = React.createContext<UserController>({
  isLoading: false,
  uppUser: undefined,
  uppUserError: [],
  unlockedUser: undefined,
  deletedUserData: undefined,
  unlockError: undefined,
  canEdit: undefined,
  showError: () => undefined,
  createUser: async () => undefined,
  resetCreateUserError: () => undefined,
  loadProfile: async () => undefined,
  updateUser: async () => undefined,
  updateUserLanguageNonAuth: () => Promise.reject(),
  updateEmail: async () => undefined,
  logUserLogin: async () => undefined,
  handleUserLogin: async () => undefined,
  unlockUser: async () => ({}),
  changePassword: async () => undefined,
  resetChangePasswordError: () => undefined,
  deleteUser: async () => ({}),
  getShowUnlockForm: async () => true,
  clearUnlockUserData: () => undefined,
  addPatientAcknowledgement: () => Promise.reject(),
  validatePhoneNumber: async () => undefined,
});

const messages = defineMessages({
  userFactorIsAlreadySetup: {
    id: "userFactorIsAlreadySetup",
    defaultMessage: "Factor is already set up",
  },
});

Context.displayName = "UserContext";

const UserProvider: FC = ({ children }) => {
  const intl = useIntl();
  const [profileErrors, setProfileErrors] = useState<ErrorBody[]>([]);
  const [unlockUserData, setUnlockUserData] = useState<UnlockUserData>({});
  const { addNotification, clear } = useContext(NotificationContext);
  const { currentLanguage, changeLanguage } = useContext(IntlContext);
  const { setProfile } = useContext(UppAuthContext);

  const showError = (err?: Error) => {
    if (err && isApolloError(err) && err.graphQLErrors?.length > 0) {
      const code = (err.graphQLErrors[0].extensions?.exception as ErrorBody)
        ?.code;
      switch (code) {
        case ResultCodes.FACTOR_IS_ALREADY_SETUP:
          addNotification({
            message: intl.formatMessage(messages.userFactorIsAlreadySetup),
            type: "error",
          });
          break;
        default:
          addNotification({
            type: "error",
          });
      }
    } else {
      addNotification({
        type: "error",
      });
    }
  };

  const [
    createUser,
    {
      loading: createUserIsLoading,
      error: createUserError,
      reset: resetCreateUserError,
    },
  ] = useMutation<{
    createUser: UppUser;
  }>(UserService.createUser(), {
    update: (cache, { data }) => {
      const { createUser } = data || {};
      const { profile } =
        cache.readQuery({ query: UserService.loadProfile() }) || {};
      cache.writeQuery({
        query: UserService.loadProfile(),
        data: { profile: { ...profile, ...createUser } },
      });
    },
  });

  const [
    loadProfile,
    { loading: profileIsLoading, data: loadProfileData },
  ] = useLazyQuery<{ profile: UppUser }>(UserService.loadProfile(), {
    fetchPolicy: "network-only",
  });

  const [
    updateUser,
    { loading: updateUserIsLoading, data: updateUserData },
  ] = useMutation<{ updateUser: UppUser }>(UserService.updateUser(), {
    onError: (error) => {
      console.error(error);
      addNotification({ type: "error" });
    },
  });

  const [updateUserLanguage] = useMutation<{
    updateUserLanguage: { success: boolean };
  }>(UserService.updateUserLanguageNonAuth(), {
    onError: () => {
      addNotification({ type: "error" });
    },
  });

  const [logUserLogin, { loading: isLogUserLoginLoading }] = useMutation<{
    logUserLogin: LogUserLoginResponse;
  }>(UserService.LogUserLogin());

  const [
    updateEmail,
    {
      loading: updateEmailIsLoading,
      data: updateEmailData,
      error: updateEmailError,
    },
  ] = useMutation<{ updateEmail: UpdateEmailData }>(UserService.UpdateEmail(), {
    fetchPolicy: "no-cache",
  });

  const [unlockUser, { loading: unlockLoader }] = useLazyQuery(
    UserService.UnlockUser(),
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [
    changePassword,
    { loading: changePasswordIsLoading, error: changePasswordError, reset },
  ] = useMutation<{ changePassword: { success: boolean } }>(
    UserService.ChangePassword()
  );

  const [
    deleteUser,
    {
      error: deleteUserError,
      data: deletedUserData,
      loading: deleteUserLoader,
    },
  ] = useMutation(UserService.DeleteUser());

  const [
    getShowUnlockForm,
    { loading: getShowUnlockFormIsLoading },
  ] = useLazyQuery(UserService.GetShowUnlockForm(), {
    fetchPolicy: "no-cache",
  });

  const [
    validatePhoneNumber,
    { loading: validatePhoneNumberLoading },
  ] = useLazyQuery<{
    validatePhoneNumber: { isValid: boolean };
  }>(UserService.validatePhoneNumber(), {
    fetchPolicy: "no-cache",
  });

  const [
    addPatientAcknowledgement,
    { loading: addPatientAcknowledgementLoading },
  ] = useMutation<{
    addPatientAcknowledgement: AddPatientAcknowledgementResponse;
  }>(UserService.AddPatientAcknowledgement());

  const handleProfileLoaded = (profile: UppUser | undefined) => {
    if (profile) {
      setProfile(profile);

      if (profile.language) {
        changeLanguage(profile.language);
      }
    }
  };

  useEffect(() => {
    if (deleteUserError) {
      showError(deleteUserError);
    }
  }, [deleteUserError]);

  const handleLoadProfile = async () => {
    try {
      setProfileErrors([]);
      const response = await loadProfile();
      if (response.error) {
        throw response.error;
      }
      const profile = response.data?.profile;

      handleProfileLoaded(profile);
      return profile;
    } catch (error) {
      if (isApolloError(error) && error.graphQLErrors?.length > 0) {
        setProfileErrors(
          error.graphQLErrors.map((e) => e.extensions?.exception as ErrorBody)
        );
      } else {
        showError(error);
      }
    }
    return;
  };

  const handleUserCreate = async (newUser: newUserDTO) => {
    clear();
    setProfileErrors([]);
    await createUser({
      variables: {
        newUserData: newUser,
      },
    });
  };

  const handleUpdateUser = async (updateUserData: UpdateUserDTO) => {
    await updateUser({ variables: { updateUserData } }).then((res) => {
      if (res.data) {
        setProfile(res.data.updateUser);
      }
    });
  };

  const handleUpdateUserLanguageNonAuth = async (
    updateUserLanguageData: UpdateUserLanguageDTO
  ) => {
    await updateUserLanguage({
      variables: { updateUserLanguageData },
    });
  };

  const handleUpdateEmail = async (newEmail: string) => {
    const response = await updateEmail({ variables: { newEmail } });
    return response.data?.updateEmail;
  };

  const handleUserLoginTry = async (
    email: string,
    status: LoginStatus,
    token: string
  ) => {
    try {
      const userLoginLog = await logUserLogin({
        variables: { userLoginData: { email, status, token } },
      });
      return userLoginLog.data?.logUserLogin;
    } catch (error) {
      const errorCode = error?.graphQLErrors[0]?.extensions?.exception?.code;
      if (!errorCode || errorCode !== ResultCodes.USER_NOT_FOUND_ERROR) {
        throw error;
      }
    }
  };

  const handleUserLogin = async () => {
    try {
      await handleUpdateUser({
        language: currentLanguage,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const clearUnlockUserData = () => {
    setUnlockUserData({});
  };

  const handleUnlockUser = async (
    email: string,
    lastName: string,
    dateOfBirth: string
  ) => {
    clear();

    const response = await unlockUser({
      variables: {
        unlockUser: { email, dateOfBirth, lastName },
      },
    });

    if (response.error) {
      setUnlockUserData({
        unlockError: (response.error.graphQLErrors[0]?.extensions
          ?.exception as ErrorBody)?.code,
      });
      throw response.error;
    }

    if (response && response.data) {
      setUnlockUserData({
        unlockedUser: { email, ...response.data.unlockUser },
      });

      return { data: { email, ...response.data.unlockUser } };
    }

    setUnlockUserData({ unlockError: "Unlock can't be performed" });
    throw new Error("Unlock can't be performed");
  };

  const handleChangePassword = async ({
    oldPassword,
    newPassword,
  }: ChangePasswordDTO) => {
    const response = await changePassword({
      variables: { props: { oldPassword, newPassword } },
    });

    return response.data?.changePassword;
  };

  const handleDeleteUser = async (email: string) => {
    const response = await deleteUser({
      variables: {
        deleteUser: { email },
      },
    });

    if (response && response.data) {
      return { email, ...response.data.deleteUser };
    }
    throw Error();
  };

  const handleGetShowUnlockForm = async (email: string) => {
    const response = await getShowUnlockForm({
      variables: {
        email,
      },
    });

    if (response?.error) {
      throw response.error;
    }

    return response?.data?.getShowUnlockForm;
  };

  const canEdit = loadProfileData?.profile?.idpType === IDP_TYPE.EMAIL;

  const handleAddPatientAcknowledgement = async (
    acknowledgementData: AddPatientAcknowledgementDTO
  ) => {
    await addPatientAcknowledgement({
      variables: {
        acknowledgementData: {
          email: acknowledgementData.email,
          accepted: acknowledgementData.accepted,
          acknowledgementText: acknowledgementData.acknowledgementText,
          consentTimestamp: acknowledgementData.consentTimestamp,
        },
      },
    });
  };

  const handleValidatePhoneNumber = async (
    phone: string
  ): Promise<{ isValid: boolean } | undefined> => {
    const result = await validatePhoneNumber({ variables: { phone } });

    return result.data?.validatePhoneNumber;
  };

  const isLoading =
    profileIsLoading ||
    createUserIsLoading ||
    updateUserIsLoading ||
    updateEmailIsLoading ||
    isLogUserLoginLoading ||
    unlockLoader ||
    changePasswordIsLoading ||
    deleteUserLoader ||
    getShowUnlockFormIsLoading ||
    validatePhoneNumberLoading;

  const userController: UserController = useMemo(
    () => ({
      isLoading,
      uppUser: loadProfileData?.profile,
      uppUserError: profileErrors,
      updatedUser: updateUserData?.updateUser,
      unlockedUser: unlockUserData?.unlockedUser,
      deletedUserData: deletedUserData?.deleteUser,
      showError,
      loadProfile: handleLoadProfile,
      createUser: handleUserCreate,
      createUserError: (createUserError?.graphQLErrors[0]?.extensions
        ?.exception as ErrorBody)?.code,
      resetCreateUserError,
      updateUser: handleUpdateUser,
      updateUserLanguageNonAuth: handleUpdateUserLanguageNonAuth,
      updateEmail: handleUpdateEmail,
      updateEmailData: updateEmailData?.updateEmail,
      updateEmailError: updateEmailError?.graphQLErrors?.[0].extensions
        ?.exception as ErrorBody,
      logUserLogin: handleUserLoginTry,
      handleUserLogin,
      unlockUser: handleUnlockUser,
      changePassword: handleChangePassword,
      changePasswordError: (changePasswordError?.graphQLErrors?.[0].extensions
        ?.exception as ErrorBody)?.code as ResultCodes,
      resetChangePasswordError: reset,
      deleteUser: handleDeleteUser,
      unlockError: unlockUserData?.unlockError,
      canEdit,
      getShowUnlockForm: handleGetShowUnlockForm,
      clearUnlockUserData,
      addPatientAcknowledgement: handleAddPatientAcknowledgement,
      addPatientAcknowledgementLoading,
      validatePhoneNumber: handleValidatePhoneNumber,
    }),
    [
      isLoading,
      loadProfileData,
      profileErrors,
      updateUserData,
      unlockUserData,
      deletedUserData,
      showError,
      handleLoadProfile,
      handleUserCreate,
      createUserError,
      resetCreateUserError,
      handleUpdateUser,
      handleUpdateUserLanguageNonAuth,
      handleUpdateEmail,
      updateEmailData,
      updateEmailError,
      handleUserLoginTry,
      handleUserLogin,
      handleUnlockUser,
      handleChangePassword,
      changePasswordError,
      reset,
      handleDeleteUser,
      canEdit,
      handleGetShowUnlockForm,
      clearUnlockUserData,
      handleAddPatientAcknowledgement,
      addPatientAcknowledgementLoading,
      handleValidatePhoneNumber,
    ]
  );

  return <Context.Provider value={userController}>{children}</Context.Provider>;
};

export default UserProvider;
