import { useCallback, useEffect } from "react";

type ListenerElement = HTMLElement | Document | Window;
const DEFAULT_ELEMENT = window;

const useEventListeners = (
  callback: EventListenerOrEventListenerObject,
  eventTypes: string[],
  element: ListenerElement = DEFAULT_ELEMENT,
  options?: AddEventListenerOptions
): {
  addListeners: () => void;
  removeListeners: () => void;
} => {
  const addListeners = useCallback(() => {
    eventTypes.forEach((eventType) => {
      element.addEventListener(eventType, callback, options);
    });
  }, [callback, eventTypes, element, options]);

  const removeListeners = useCallback(() => {
    eventTypes.forEach((eventType) => {
      element.removeEventListener(eventType, callback, options);
    });
  }, [callback, eventTypes, element, options]);

  useEffect(() => {
    addListeners();
    return removeListeners;
  }, [addListeners, removeListeners]);

  return { addListeners, removeListeners };
};

export default useEventListeners;
