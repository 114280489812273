import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { HandleUserEventInterface } from "@app/neva/hooks/useApiController";
import { ActionTypes, ActionMetadataType } from "@app/neva/models";

type PostbackType = {
  title: string;
  payload: string;
};

export const generateActionUserEvent = (
  actionType: ActionTypes,
  metadata?: ActionMetadataType
): HandleUserEventInterface => {
  return {
    action: {
      type: actionType,
      metadata: metadata,
    },
  };
};

export const generatePostbackUserEvent = (
  postback: PostbackType
): HandleUserEventInterface => {
  return generatePostbackUserEventWithType(postback, "postback");
};

export const generatePostbackUserEventWithType = (
  postback: PostbackType,
  type: string
): HandleUserEventInterface => {
  return {
    message: {
      id: uuidv4(),
      type: type,
      timestamp: DateTime.utc().toFormat("yyyy-LL-dd'T'HH:mm:ss"),
      postback: {
        title: postback.title,
        payload: postback.payload,
      },
    },
  };
};
