import React, { FC, useEffect } from "react";
import * as R from "ramda";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { ProfileContext } from "@app/provider";
import { StepperContext } from "@natera/stepper";
import { Address } from "@app/provider/profile";
import PatientAddresses from "./patientAddresses/patientAddresses";
import MobilePhlebotomyAddressForm from "./addressForm";
import { useHistory } from "react-router-dom";
import { SpinnerView } from "@app/components";
import { routes } from "@app/routing";
import {
  AddressFormType,
  POBoxErrorNotification,
} from "../poBoxErrorNotification/poBoxErrorNotification";
import { isPOBoxOrPMB } from "@app/utils/isPOBoxOrPMB";
import "./addressStep.scss";

const messages = defineMessages({
  mobilePhlebotomyAddressTitle: {
    id: "mobilePhlebotomyAddressTitle",
    defaultMessage: "Mobile Blood Draw",
  },
  mobilePhlebotomyAddressDescriptionTitle: {
    id: "mobilePhlebotomyAddressDescriptionTitle",
    defaultMessage: "Where would you like to have your blood drawn?",
  },
  mobilePhlebotomyAddressDescriptionContent: {
    id: "mobilePhlebotomyAddressDescriptionContent",
    defaultMessage: "Please select or add an address.",
  },
});

const messagesKitShipping = defineMessages({
  kitShippingAddressSelectTitle: {
    id: "kitShippingAddressSelectTitle",
    defaultMessage: "Kit Shipping Details",
  },
  kitShippingAddressSelectDescriptionTitle: {
    id: "kitShippingAddressSelectDescriptionTitle",
    defaultMessage: "Where should we send your kit?",
  },
  kitShippingAddressSelectDescriptionContent: {
    id: "kitShippingAddressSelectDescriptionContent",
    defaultMessage:
      "A test kit is needed to get your sample drawn. To have your kit shipped to a different address from the one shown, add a new address.",
  },
  kitShippingAddressEnterNewAddressDescriptionContent: {
    id: "kitShippingAddressEnterNewAddressDescriptionContent",
    defaultMessage:
      "A test kit is needed to get your sample drawn. To have your kit shipped, please enter a new address.",
  },
});

export enum ADDRESS_VIEW_TYPE {
  CURRENT = "CURRENT",
  NEW = "NEW",
}

type HistoryType = ReturnType<typeof useHistory>;

const historyGoBack = (history: HistoryType) => {
  history.length > 1 ? history.goBack() : history.push(routes.home);
};

const addressSelectTitle = (intl: IntlShape, isKitShippingStep?: boolean) =>
  intl.formatMessage(
    isKitShippingStep
      ? messagesKitShipping.kitShippingAddressSelectTitle
      : messages.mobilePhlebotomyAddressTitle
  );

const addressSelectSubtitle = (intl: IntlShape, isKitShippingStep?: boolean) =>
  intl.formatMessage(
    isKitShippingStep
      ? messagesKitShipping.kitShippingAddressSelectDescriptionTitle
      : messages.mobilePhlebotomyAddressDescriptionTitle
  );

const addressSelectText = (
  intl: IntlShape,
  selectedAddressView: ADDRESS_VIEW_TYPE,
  isKitShippingStep?: boolean
) =>
  intl.formatMessage(
    isKitShippingStep
      ? selectedAddressView === ADDRESS_VIEW_TYPE.CURRENT
        ? messagesKitShipping.kitShippingAddressSelectDescriptionContent
        : messagesKitShipping.kitShippingAddressEnterNewAddressDescriptionContent
      : messages.mobilePhlebotomyAddressDescriptionContent
  );

interface AddressStepProps {
  isKitShippingStep?: boolean;

  onSubmit?: (address: Address) => Promise<void>;
  onCancel?: () => void;
  isLoading?: boolean;
}

const AddressStep: FC<AddressStepProps> = ({
  isKitShippingStep,
  onSubmit,
  onCancel,
  isLoading = false,
}) => {
  const intl = useIntl();

  const history = useHistory();

  const { goBack, resolve } = React.useContext(StepperContext);
  const { getProfileData: profile } = React.useContext(ProfileContext);

  const patientAddresses: Address[] = profile?.addresses || [];

  const addresses: Address[] = patientAddresses
    .filter(
      ({ city, state, street1, zipCode }: Address) =>
        city && state && street1 && zipCode
    )
    .map((address: Address) => R.omit(["__typename"], address));

  const hasAddresses = addresses.length > 0;

  const [selectedAddressView, setSelectedAddressView] = React.useState<
    ADDRESS_VIEW_TYPE
  >(hasAddresses ? ADDRESS_VIEW_TYPE.CURRENT : ADDRESS_VIEW_TYPE.NEW);
  const [showPOBoxError, setShowPOBoxError] = React.useState<boolean>(false);

  const handleKitShippingSubmit = async (address: Address) => {
    if (isPOBoxOrPMB(address.street1, address?.street2)) {
      setShowPOBoxError(true);
      return;
    }
    onSubmit && (await onSubmit(address));

    resolve({ address });
  };

  const handleEnterPatientAddressButtonClick = () =>
    setSelectedAddressView(ADDRESS_VIEW_TYPE.NEW);

  const handleBackToAddressSelection = () => {
    if (!isKitShippingStep) {
      goBack();
    } else {
      addresses.length
        ? setSelectedAddressView(ADDRESS_VIEW_TYPE.CURRENT)
        : handleKitShippingBack();
    }
  };

  const handleKitShippingBack = () => {
    if (selectedAddressView === ADDRESS_VIEW_TYPE.CURRENT && onCancel) {
      onCancel();
    } else {
      historyGoBack(history);
    }
  };

  useEffect(() => {
    setShowPOBoxError(false);
  }, [selectedAddressView]);

  return (
    <>
      {showPOBoxError && (
        <POBoxErrorNotification
          formType={
            isKitShippingStep
              ? AddressFormType.KIT
              : AddressFormType.MOBILE_BLOOD_DRAW
          }
        />
      )}
      <div data-testid="addressStep" className="stepper__container">
        <div className="stepper__head">
          <h3 className="stepper__head-title">
            {addressSelectTitle(intl, isKitShippingStep)}
          </h3>
          <h4 className="stepper__head-subtitle">
            {addressSelectSubtitle(intl, isKitShippingStep)}
          </h4>
          <p className="stepper__head-text">
            {addressSelectText(intl, selectedAddressView, isKitShippingStep)}
          </p>
        </div>

        <SpinnerView isLoading={isLoading} />

        {selectedAddressView === ADDRESS_VIEW_TYPE.CURRENT ? (
          <PatientAddresses
            addresses={addresses}
            handleEnterPatientAddressButtonClick={
              handleEnterPatientAddressButtonClick
            }
            handleKitShippingSubmit={handleKitShippingSubmit}
            handleKitShippingBack={handleKitShippingBack}
            isKitShippingStep={isKitShippingStep}
          />
        ) : (
          <MobilePhlebotomyAddressForm
            handleSubmit={handleKitShippingSubmit}
            handleCancel={handleBackToAddressSelection}
            isKitShippingStep={isKitShippingStep}
          />
        )}
      </div>
    </>
  );
};

export default AddressStep;
