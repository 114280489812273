import React, { FC } from "react";
import { StepperContext } from "@natera/stepper";
import PartnerInfoForm from "@app/components/partnerInfo/partnerInfoForm";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { PartnerInfoData } from "@app/provider/order";
import { defineMessages, useIntl } from "react-intl";
import { Notification } from "@app/components";

import "./partnerInfo.scss";
import {
  PartnerInfoStepResolve,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import { Steps } from "@app/pages/private/simpleOrder";

const messages = defineMessages({
  partnerInfoTitle: {
    id: "partnerInfoTitle",
    defaultMessage: "Partner Information",
  },
  partnerInfoNotification: {
    id: "partnerInfoNotification",
    defaultMessage:
      "Providing partner information is highly recommended as it will allow Natera to coordinate partner testing if requested by your medical provider.",
  },
});

interface PartnerInfoProps {
  initValue?: PartnerInfoStepResolve;
  resolve: (value: PartnerInfoStepResolve) => void;
}

const PartnerInfo: FC<PartnerInfoProps> = ({ resolve, initValue }) => {
  const intl = useIntl();
  const { goBack } = React.useContext(StepperContext);
  const { getSubStepData } = React.useContext(SimpleOrderStepperContext);

  const subStepsData = getSubStepData(
    Steps.PARTNER_INFO
  ) as PartnerInfoStepResolve;

  const handleSubmit = async (partner: PartnerInfoData) => {
    resolve({
      ...subStepsData,
      partner,
    });
  };

  const handleBack = () => {
    goBack();
  };

  return (
    <>
      <Notification
        className="partner-info-notification__container"
        type="note"
      >
        {intl.formatMessage(messages.partnerInfoNotification)}
      </Notification>
      <StepperHeaderProgress />
      <div className="stepper__container">
        <div className="stepper__head">
          <div className="stepper__head-title__container">
            <h3 className="stepper__head-title_text">
              {intl.formatMessage(messages.partnerInfoTitle)}
            </h3>
          </div>
        </div>
        <PartnerInfoForm
          initialPartnerInfo={initValue?.partner}
          onSubmit={handleSubmit}
          onBack={handleBack}
        />
      </div>
    </>
  );
};

export default PartnerInfo;
