import React, { FC, useEffect, useState } from "react";
import PaginationArrowIcon from "@assets/svg/icons/pagination-arrow.svg";
import Button from "@natera/material/lib/button";
import "./pagination.scss";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  previousPageButton: {
    id: "previousPageButton",
    defaultMessage: "Previous page",
  },
  nextPageButton: {
    id: "nextPageButton",
    defaultMessage: "Next page",
  },
});

interface Props {
  handlePage: (page: number, from?: Date, to?: Date) => void;
  currentPage: number;
  total: number;
  pagesToShow?: number;
  rangeDate?: { from?: Date; to?: Date };
}

const PAGES_TO_SHOW = 3;

const Pagination: FC<Props> = ({
  handlePage,
  total,
  currentPage,
  pagesToShow = PAGES_TO_SHOW,
  rangeDate,
}) => {
  const [middleElement, setMiddleElement] = useState(1);
  const intl = useIntl();

  useEffect(() => {
    if (currentPage === 0 && middleElement !== 1) {
      setMiddleElement(1);
    }
  }, [currentPage, total]);

  const handlePageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const pageNum = e.currentTarget.getAttribute("data-page");
    const targetPage = Number(pageNum);
    handlePage(targetPage, rangeDate?.from, rangeDate?.to);
    if (targetPage > 0 && targetPage < total - 1) {
      setMiddleElement(targetPage);
    }
  };
  const handleNextClick = () => {
    handlePage(currentPage + 1, rangeDate?.from, rangeDate?.to);
    if (currentPage === middleElement + 1 && middleElement < total - 1) {
      setMiddleElement(middleElement + 1);
    }
  };
  const handlePrevClick = () => {
    handlePage(currentPage - 1, rangeDate?.from, rangeDate?.to);
    if (currentPage === middleElement - 1 && middleElement > 0) {
      setMiddleElement(middleElement - 1);
    }
  };

  if (!total || total < 2) return null;

  const renderPageNums = () => {
    const nums = [];
    for (let i = 0; i < pagesToShow && i < total; i++) {
      const currentElement = i + middleElement - 1;
      nums.push(
        <Button
          key={currentElement}
          className={`${
            currentPage === currentElement ? "pagination__pages--current" : ""
          } pagination-btn`}
          disabled={currentPage === currentElement}
          data-page={currentElement}
          onClick={handlePageClick}
        >
          {currentElement + 1}
        </Button>
      );
    }
    return nums;
  };

  return (
    <div className="pagination">
      <Button
        className="pagination-btn"
        icon={PaginationArrowIcon}
        onClick={handlePrevClick}
        disabled={currentPage === 0}
        aria-label={intl.formatMessage(messages.previousPageButton)}
      />
      <div className="pagination__pages">{renderPageNums()}</div>
      <Button
        className="pagination-btn pagination__next-btn"
        icon={PaginationArrowIcon}
        onClick={handleNextClick}
        disabled={currentPage === total - 1}
        aria-label={intl.formatMessage(messages.nextPageButton)}
      />
    </div>
  );
};

export default Pagination;
