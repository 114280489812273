import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Form, Button } from "@natera/platform/lib/components/form";
import { DisplayField } from "@natera/platform/lib/components/form/field";
import { FormField, LinkHeap, Notification } from "@app/components";
import { ConfigContext, DrawRequestContext } from "@app/provider";
import { getFormattedDOB, getProfileFullName } from "@app/utils/profile";

import { ScheduleBloodDrawScreens } from "../scheduleBloodDraw";

import "./personalInformation.scss";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";
import { snakeCaseToCapitalizedWords } from "@app/utils";

const messages = defineMessages({
  scheduleBloodPersonalInfoNotification: {
    id: "scheduleBloodPersonalInfoUpdateNote",
    defaultMessage:
      "If you need to make changes to your personal information {contactUsLink}",
  },
  scheduleBloodPersonalInfoContactUs: {
    id: "scheduleBloodPersonalInfoContactUs",
    defaultMessage: "contact us",
  },
  scheduleBloodPersonalInfoTitle: {
    id: "scheduleBloodPersonalInfoTitle",
    defaultMessage: "Personal Information",
  },
  scheduleBloodPersonalInfoFormFullName: {
    id: "scheduleBloodPersonalInfoFormFullName",
    defaultMessage: "Full Name",
  },
  scheduleBloodPersonalInfoFormDateOfBirth: {
    id: "scheduleBloodPersonalInfoFormDateOfBirth",
    defaultMessage: "Date of Birth",
  },
  scheduleBloodPersonalInfoTestType: {
    id: "scheduleBloodPersonalInfoTestType",
    defaultMessage: "Test Type / Name",
  },
  scheduleBloodPersonalInfoOrderedDate: {
    id: "scheduleBloodPersonalInfoOrderedDate",
    defaultMessage: "Ordered Date",
  },
  scheduleBloodPersonalInfoNext: {
    id: "personalInfoNext",
    defaultMessage: "Next",
  },
});

interface PersonalInformationProps {
  setBloodDrawScreen: React.Dispatch<
    React.SetStateAction<ScheduleBloodDrawScreens>
  >;
}

export const PersonalInformation: FC<PersonalInformationProps> = ({
  setBloodDrawScreen,
}) => {
  const intl = useIntl();
  const { config } = React.useContext(ConfigContext);
  const { verifiedSampleDrawData } = React.useContext(DrawRequestContext);

  const profileInfo = verifiedSampleDrawData?.profileInfo.profile;
  const sampleInfo = verifiedSampleDrawData?.sampleInfo.sample;

  const onSubmit = () => {
    setBloodDrawScreen(ScheduleBloodDrawScreens.KIT_DETAILS_SCREEN);
  };

  return (
    <div className="schedule-blood-draw-personal-info">
      <div className="personal-info-content">
        <Notification
          className="personal-info-content__notification"
          type="note"
        >
          {intl.formatMessage(messages.scheduleBloodPersonalInfoNotification, {
            contactUsLink: (
              <LinkHeap
                target="_blank"
                key="privacyPolicy"
                rel="noopener noreferrer"
                to={{ pathname: config.links.CONTACT_US }}
                heapEventName={HEAP_EVENTS.upp_click_contactnatera}
                heapEventProps={{
                  location: HeapEventLocation.personal_info_mp_page,
                }}
              >
                {intl.formatMessage(
                  messages.scheduleBloodPersonalInfoContactUs
                )}
              </LinkHeap>
            ),
          })}
        </Notification>
        <h3 className="personal-info-content__title">
          {intl.formatMessage(messages.scheduleBloodPersonalInfoTitle)}
        </h3>
        <Form
          className="personal-info-content__form"
          noValidate
          onSubmit={onSubmit}
        >
          <FormField
            label={intl.formatMessage(
              messages.scheduleBloodPersonalInfoFormFullName
            )}
            htmlFor="fullname"
            withPadding
          >
            <HeapPHIDecorator protectText>
              <DisplayField
                id="fullname"
                value={getProfileFullName(profileInfo)}
              />
            </HeapPHIDecorator>
          </FormField>
          <FormField
            label={intl.formatMessage(
              messages.scheduleBloodPersonalInfoFormDateOfBirth
            )}
            htmlFor="dateOfBirth"
            withPadding
          >
            <HeapPHIDecorator protectText>
              <DisplayField
                id="dateOfBirth"
                value={getFormattedDOB(profileInfo?.dateOfBirth)}
              />
            </HeapPHIDecorator>
          </FormField>
          <FormField
            label={intl.formatMessage(
              messages.scheduleBloodPersonalInfoTestType
            )}
            htmlFor="testType"
            withPadding
          >
            <HeapPHIDecorator protectText>
              <DisplayField
                id="testType"
                value={
                  sampleInfo?.testType ? (
                    snakeCaseToCapitalizedWords(sampleInfo?.testType)
                  ) : (
                    <>&mdash;</>
                  )
                }
              />
            </HeapPHIDecorator>
          </FormField>

          <FormField
            label={intl.formatMessage(
              messages.scheduleBloodPersonalInfoOrderedDate
            )}
            htmlFor="orderedDate"
            withPadding
          >
            <HeapPHIDecorator protectText>
              <DisplayField
                id="orderedDate"
                value={
                  intl.formatDate(sampleInfo?.creationDate) || <>&mdash;</>
                }
              />
            </HeapPHIDecorator>
          </FormField>
          <div className="personal-info-content__form-actions">
            <Button type="submit" data-testid="submit" raised>
              {intl.formatMessage(messages.scheduleBloodPersonalInfoNext)}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
