import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/platform/lib/components/form";
import { CommonFormActionsView } from "@app/components";

export const messages = defineMessages({
  kitShippingAddressFormActionsNextButtonText: {
    id: "kitShippingAddressFormActionsNextButtonText",
    defaultMessage: "Save",
  },
  mobilePhlebotomyAddressFormActionsNextButtonText: {
    id: "mobilePhlebotomyAddressFormActionsNextButtonText",
    defaultMessage: "Add",
  },
  mobilePhlebotomyAddressFormActionsBackButtonText: {
    id: "mobilePhlebotomyAddressFormActionsBackButtonText",
    defaultMessage: "Back",
  },
});

interface Props {
  handleCancel: () => void;
  isKitShippingStep?: boolean;
}
const MobilePhlebotomyAddressFormActions: FC<Props> = ({
  handleCancel,
  isKitShippingStep,
}) => {
  const intl = useIntl();
  const submitButtonText = isKitShippingStep
    ? messages.kitShippingAddressFormActionsNextButtonText
    : messages.mobilePhlebotomyAddressFormActionsNextButtonText;
  return (
    <CommonFormActionsView>
      <Button type="submit" data-testid="submit" raised>
        {intl.formatMessage(submitButtonText)}
      </Button>
      <Button type="reset" data-testid="cancel" onClick={handleCancel}>
        {intl.formatMessage(
          messages.mobilePhlebotomyAddressFormActionsBackButtonText
        )}
      </Button>
    </CommonFormActionsView>
  );
};

export default MobilePhlebotomyAddressFormActions;
