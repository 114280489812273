import React, { FC } from "react";
import { useLazyQuery } from "@apollo/client";

import PatientService from "@app/service/patient";
import { PatientEthnicityOutput } from "@app/components/selectEthnicity/ethnicityStep";

interface Patient {
  id: number;
  firstName?: string;
  lastName?: string;
  clinic: { limsId: number; name: string; territoryName?: string };
  dateOfBirth: Date | null | undefined;
  ethnicities: PatientEthnicityOutput;
}
export interface PatientController {
  isLoading: boolean;
  loadPatient: (uid: string) => void;
  getPatient: () => Patient | undefined;
}

export const Context = React.createContext<PatientController>({
  isLoading: false,
  loadPatient: () => Promise.reject(),
  getPatient: () => undefined,
});

Context.displayName = "PatientContext";

const PatientProvider: FC = ({ children }) => {
  const [getPatientById, { data, loading: isLoading }] = useLazyQuery(
    PatientService.getPatient(),
    {
      fetchPolicy: "no-cache",
    }
  );

  const loadPatient = (uid: string) => {
    getPatientById({
      variables: {
        uid,
      },
    });
  };

  const getPatient = () => {
    if (data) {
      return data.patient;
    }
    return undefined;
  };

  return (
    <Context.Provider
      value={{
        isLoading: isLoading,
        loadPatient,
        getPatient,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default PatientProvider;
