import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TestResult } from "@app/provider/testResult";
import { TestType } from "@app/provider/testData/types";
import Svg from "@natera/material/lib/svg";
import DocumentsMagnifyingGlass from "@assets/svg/documents-magnifying-glass-filled.svg";

import "./understandingYourResults.scss";

const messages = defineMessages({
  understandingYourResultsOncologySignateraHeadline: {
    id: "understandingYourResultsOncologySignateraHeadline",
    defaultMessage: "Signatera",
  },
  understandingYourResultsOncologyEmpowerHeadline: {
    id: "understandingYourResultsOncologyEmpowerHeadline",
    defaultMessage: "Empower",
  },
  understandingYourResultsOncologyAlteraHeadline: {
    id: "understandingYourResultsOncologyAlteraHeadline",
    defaultMessage: "Altera",
  },
  understandingYourResultsOncologySignateraFirstParagraph: {
    id: "understandingYourResultsOncologySignateraFirstParagraph",
    defaultMessage:
      "The Signatera test uses genetic signatures from your tumor to create a personalized test that can look for that tumor’s DNA in your blood. The results can help to determine if there are signs of cancer still in the body after surgery or cancer therapy, to determine if the treatment is working, or to find out if your cancer may be coming back. The following results are possible:",
  },
  understandingYourResultsOncologyEmpowerFirstParagraph: {
    id: "understandingYourResultsOncologyEmpowerFirstParagraph",
    defaultMessage:
      "The Empower Hereditary Cancer Test screens for variants, or mutations, in genes associated with having an increased risk for hereditary cancers. The following results are possible:",
  },
  understandingYourResultsOncologyAlteraFirstParagraph: {
    id: "understandingYourResultsOncologyAlteraFirstParagraph",
    defaultMessage:
      "The Altera tumor genomic profile is a test that looks for specific genetic changes in your tumor. This information, along with your personal cancer history, can be used by healthcare providers to help guide treatment decisions.{br}Your test results will include the following sections below. These results should be reviewed with your healthcare provider.",
  },
  understandingYourResultsOncologyEmpowerFirstCard: {
    id: "understandingYourResultsOncologyEmpowerFirstCard",
    defaultMessage:
      "<b>Positive:</b> A variant was found that may be associated with an increased risk for certain cancer types. These are called pathogenic or likely pathogenic variants. People with a positive result may have a higher risk for certain cancers than the average person. Talk to your healthcare provider about options for cancer screening and prevention.",
  },
  understandingYourResultsOncologyEmpowerSecondCard: {
    id: "understandingYourResultsOncologyEmpowerSecondCard",
    defaultMessage:
      "<b>Negative:</b> No variant known to increase risk for cancers was found in the genes tested. If your test result is negative, your healthcare provider will consider your personal and family history to estimate your cancer risk and decide whether you should have additional cancer screening beyond what is recommended for the general population.",
  },
  understandingYourResultsOncologyEmpowerThirdCard: {
    id: "understandingYourResultsOncologyEmpowerThirdCard",
    defaultMessage:
      "<b>Variant of Uncertain Significance (VUS):</b> A variant has been identified, but more research is needed to know if it is associated with an increased risk for cancer. VUS status can change over time as more information becomes available. If this happens, you and your healthcare provider will receive an updated report.",
  },
  understandingYourResultsOncologyEmpowerFourthCard: {
    id: "understandingYourResultsOncologyEmpowerFourthCard",
    defaultMessage:
      "<b>Test Not Performed (TNP):</b> A TNP test result means the test was not able to be performed and there is no result. Your report will contain details about the reason the testing was not performed. Examples may include sample stability or the quality of the sample.",
  },
  understandingYourResultsOncologyAlteraFirstCard: {
    id: "understandingYourResultsOncologyAlteraFirstCard",
    defaultMessage:
      "<b>Tumor Genomic Alterations:</b> This includes any genetic variants identified in your tumor that may be associated with FDA-approved cancer therapies and/or potential clinical trials. These variants are called genomic alterations or genomic targets.",
  },
  understandingYourResultsOncologyAlteraSecondCard: {
    id: "understandingYourResultsOncologyAlteraSecondCard",
    defaultMessage:
      "<b>Genomic Alterations Detail:</b> This includes more information about the genomic alterations identified in your tumor and how they work in your body.",
  },
  understandingYourResultsOncologyAlteraThirdCard: {
    id: "understandingYourResultsOncologyAlteraThirdCard",
    defaultMessage:
      "<b>Drug Evidence Detail:</b> This includes available cancer therapies for the genomic alterations identified in your tumor and their instructions for your healthcare provider’s reference.",
  },
  understandingYourResultsOncologyAlteraFourthCard: {
    id: "understandingYourResultsOncologyAlteraFourthCard",
    defaultMessage:
      "<b>Clinical Trials Report:</b> This includes potential clinical trials that are available based on the genomic alterations identified in your tumor. There may be additional trials available or other criteria that determine whether or not you are eligible. All clinical trial considerations should be discussed with your healthcare provider.",
  },
  understandingYourResultsOncologyAlteraFifthCard: {
    id: "understandingYourResultsOncologyAlteraFifthCard",
    defaultMessage:
      "<b>Variant of Uncertain Significance (VUS):</b> This lists all the other variants identified in your tumor, but more research is needed to know the impact of these variants and if there is a cancer therapy that could be beneficial.",
  },
  understandingYourResultsOncologyAlteraSixthCard: {
    id: "understandingYourResultsOncologyAlteraSixthCard",
    defaultMessage:
      "<b>Test Not Performed (TNP):</b> A TNP test result means the test was not able to be performed and there is no result. Your report will contain details about the reason the testing was not performed. Examples may include sample stability, not enough tissue or the quality of the sample.",
  },
  understandingYourResultsOncologySignateraFirstCard: {
    id: "understandingYourResultsOncologySignateraFirstCard",
    defaultMessage:
      "<b>Positive:</b> A positive test result means your tumor’s DNA was detected in your blood. These results should be reviewed with your healthcare provider to discuss options for cancer monitoring or treatment. What this means for you will depend on where you are in your cancer journey.",
  },
  understandingYourResultsOncologySignateraSecondCard: {
    id: "understandingYourResultsOncologySignateraSecondCard",
    defaultMessage:
      "<b>Negative:</b> A negative test result means your tumor’s DNA was not detected in your blood. Negative results may change over time. A negative Signatera result doesn’t guarantee that tumor DNA was not in your blood, nor that it will never be detected in the future.",
  },
  understandingYourResultsOncologySignateraThirdCard: {
    id: "understandingYourResultsOncologySignateraThirdCard",
    defaultMessage:
      "<b>Test Not Performed (TNP):</b> A TNP test result means the test was not able to be performed and there is no result. Your report will contain details about the reason the testing was not performed. Examples may include sample stability, not enough tissue or the quality of the sample.",
  },
  understandingYourResultsOncologySignateraFooterParagraph: {
    id: "understandingYourResultsOncologySignateraFooterParagraph",
    defaultMessage:
      "Because results can change over time, Signatera testing is designed to be repeated over the course of your cancer care as directed by your healthcare provider. The overall result as well as the trend in the amount of tumor DNA detected over time can be used by your healthcare provider to make a treatment plan personalized to you.{br}Test results should be interpreted in the context of your medical and cancer history. Discuss your results and your history with your healthcare provider to understand next steps in your cancer treatment journey.",
  },
  understandingYourResultsOncologyAlteraFooterParagraph: {
    id: "understandingYourResultsOncologyAlteraFooterParagraph",
    defaultMessage:
      "Test results should be interpreted in the context of your medical and cancer history. Discuss your results and your history with your healthcare provider to understand next steps in your cancer treatment journey.",
  },
  understandingYourResultsOncologyEmpowerFooterParagraph: {
    id: "understandingYourResultsOncologyEmpowerFooterParagraph",
    defaultMessage:
      "Test results should be interpreted in the context of your medical and family history. Discuss your results and your history with your healthcare provider to understand your personal risk of cancer. You may be eligible for more cancer screening and prevention options.",
  },
});

type understandingYourResultsOncologyOncologyContentProps = {
  testResult: TestResult;
};

export const UnderstandingYourResultsOncologyContent: FC<understandingYourResultsOncologyOncologyContentProps> = ({
  testResult,
}) => {
  const intl = useIntl();

  const getHeadlineByTestType = () => {
    switch (testResult.testType) {
      case TestType.ALTERA:
        return intl.formatMessage(
          messages.understandingYourResultsOncologyAlteraHeadline
        );
      case TestType.EMPOWER:
        return intl.formatMessage(
          messages.understandingYourResultsOncologyEmpowerHeadline
        );
      default:
        return intl.formatMessage(
          messages.understandingYourResultsOncologySignateraHeadline
        );
    }
  };

  const getFirstParagraphByTestType = () => {
    switch (testResult.testType) {
      case TestType.ALTERA:
        return intl.formatMessage(
          messages.understandingYourResultsOncologyAlteraFirstParagraph,
          { br: <br className="br" /> }
        );
      case TestType.EMPOWER:
        return intl.formatMessage(
          messages.understandingYourResultsOncologyEmpowerFirstParagraph
        );
      default:
        return intl.formatMessage(
          messages.understandingYourResultsOncologySignateraFirstParagraph
        );
    }
  };

  const getTestCardDescriptionByTestType = () => {
    switch (testResult.testType) {
      case TestType.ALTERA:
        return getAlteraDescriptionTestCard();
      case TestType.EMPOWER:
        return getEmpowerDescriptionTestCard();
      default:
        return getSignateraDescriptionTestCard();
    }
  };

  const getFooterParagraphByTestType = () => {
    switch (testResult.testType) {
      case TestType.ALTERA:
        return intl.formatMessage(
          messages.understandingYourResultsOncologyAlteraFooterParagraph
        );
      case TestType.EMPOWER:
        return intl.formatMessage(
          messages.understandingYourResultsOncologyEmpowerFooterParagraph
        );
      default:
        return intl.formatMessage(
          messages.understandingYourResultsOncologySignateraFooterParagraph,
          { br: <br className="br" /> }
        );
    }
  };

  const getAlteraDescriptionTestCard = () => {
    const alteraMessages = [
      messages.understandingYourResultsOncologyAlteraFirstCard,
      messages.understandingYourResultsOncologyAlteraSecondCard,
      messages.understandingYourResultsOncologyAlteraThirdCard,
      messages.understandingYourResultsOncologyAlteraFourthCard,
      messages.understandingYourResultsOncologyAlteraFifthCard,
      messages.understandingYourResultsOncologyAlteraSixthCard,
    ];
    return generateTestCardDescription(alteraMessages);
  };

  const getEmpowerDescriptionTestCard = () => {
    const empowerMessages = [
      messages.understandingYourResultsOncologyEmpowerFirstCard,
      messages.understandingYourResultsOncologyEmpowerSecondCard,
      messages.understandingYourResultsOncologyEmpowerThirdCard,
      messages.understandingYourResultsOncologyEmpowerFourthCard,
    ];
    return generateTestCardDescription(empowerMessages);
  };

  const getSignateraDescriptionTestCard = () => {
    const signateraMessages = [
      messages.understandingYourResultsOncologySignateraFirstCard,
      messages.understandingYourResultsOncologySignateraSecondCard,
      messages.understandingYourResultsOncologySignateraThirdCard,
    ];
    return generateTestCardDescription(signateraMessages);
  };

  const generateTestCardDescription = (
    messages: {
      id: string;
      defaultMessage: string;
    }[]
  ) => {
    return (
      <>
        {messages.map((message, index) => (
          <div key={index}>
            {intl.formatMessage(message, { b: (chunks) => <b>{chunks}</b> })}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="understanding-your-results__content-oncology">
      <Svg content={DocumentsMagnifyingGlass} />
      <h2>{getHeadlineByTestType()}</h2>
      <div className="understanding-your-results__content-oncology-card-wrapper">
        <p>{getFirstParagraphByTestType()}</p>
        <div className="understanding-your-results__content-oncology-card-description">
          {getTestCardDescriptionByTestType()}
        </div>
      </div>
      <div className="understanding-your-results__content-oncology-footer">
        {getFooterParagraphByTestType()}
      </div>
    </div>
  );
};
