import React, { FC, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import { routes } from "@app/routing";
import { useQuery } from "@app/utils";
import { NotificationContext } from "@app/provider";
import "./resetPasswordError.scss";

const messages = defineMessages({
  resetPasswordErrorResend: {
    id: "resetPasswordErrorResend",
    defaultMessage: "Resend Password Reset Link",
  },
  resetPasswordErrorResendTitle: {
    id: "resetPasswordErrorResendTitle",
    defaultMessage: "This link has expired.",
  },
  resetPasswordErrorLoginTitle: {
    id: "resetPasswordErrorLoginTitle",
    defaultMessage: "This link is broken.",
  },
  resetPasswordErrorLogin: {
    id: "resetPasswordErrorLogin",
    defaultMessage: "Log In",
  },
});

type TypeError = "resend" | "login";

const ResetPasswordError: FC = () => {
  const history = useHistory();
  const intl = useIntl();
  const query = useQuery();
  const type = query.get("type") as TypeError | null;

  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (type) {
      switch (type) {
        case "resend": {
          addNotification({
            message: intl.formatMessage(messages.resetPasswordErrorResendTitle),
            actions: (
              <Link to={routes.resetPassword}>
                {intl.formatMessage(messages.resetPasswordErrorResend)}
              </Link>
            ),
            type: "error",
          });
          break;
        }
        case "login": {
          addNotification({
            message: intl.formatMessage(messages.resetPasswordErrorLoginTitle),
            actions: (
              <Link to={routes.signIn}>
                {intl.formatMessage(messages.resetPasswordErrorLogin)}
              </Link>
            ),
            type: "error",
          });
          break;
        }
      }
    }
  }, [type]);

  useEffect(() => {
    if (!type) {
      history.replace(routes.root);
    }
  }, []);

  return <div className="reset-password-error" />;
};

export default ResetPasswordError;
