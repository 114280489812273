import { defineMessages } from "react-intl";

export const messages = defineMessages({
  updatePersonalInformationFormBackButton: {
    id: "updatePersonalInformationFormBackButton",
    defaultMessage: "Personal Information",
  },
  updatePersonalInformationFormFirstName: {
    id: "updatePersonalInformationFormFirstName",
    defaultMessage: "First Name",
  },
  updatePersonalInformationFormFirstNamePlaceholder: {
    id: "updatePersonalInformationFormFirstNamePlaceholder",
    defaultMessage: "Enter Your First Name",
  },
  updatePersonalInformationFormMiddleName: {
    id: "updatePersonalInformationFormMiddleName",
    defaultMessage: "Middle Name / Initial",
  },
  updatePersonalInformationFormMiddleNamePlaceholder: {
    id: "updatePersonalInformationFormMiddleNamePlaceholder",
    defaultMessage: "Enter Your Middle Name",
  },
  updatePersonalInformationFormLastName: {
    id: "updatePersonalInformationFormLastName",
    defaultMessage: "Last Name",
  },
  updatePersonalInformationFormAlternativeLastName: {
    id: "updatePersonalInformationFormAlternativeLastName",
    defaultMessage: "Alternate Last Name",
  },
  updatePersonalInformationFormLastNamePlaceholder: {
    id: "updatePersonalInformationFormLastNamePlaceholder",
    defaultMessage: "Enter Your Last Name",
  },
  updatePersonalInformationFormAlternativeLastNamePlaceholder: {
    id: "updatePersonalInformationFormAlternativeLastNamePlaceholder",
    defaultMessage: "Enter Your Alternate Last Name",
  },
  updatePersonalInformationFormUpdateButton: {
    id: "updatePersonalInformationFormUpdateButton",
    defaultMessage: "Update",
  },
  updatePersonalInformationFormRequired: {
    id: "updatePersonalInformationFormRequired",
    defaultMessage: "Please fill in this field",
  },
});
