import React, { FC, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import classNames from "classnames";
import { sanitize } from "dompurify";
import { APIContext } from "@app/neva/provider";
import { Dialog } from "@natera/material/lib/dialog";
import { unescape, wrapTextAddLinks } from "@app/neva/helpers";
import { WebviewType } from "@app/neva/models";
import "./chatMessageDialog.scss";
import { useLogEvent } from "@app/neva/hooks";
import SpinnerLoader from "@app/neva/components/spinnerLoader/spinnerLoader";

interface Props {
  onClose: () => void;
  data?: WebviewType;
  url?: string;
}

const messages = defineMessages({
  chatMessageDialogExternalLink: {
    id: "chatMessageDialogExternalLink",
    defaultMessage: "Open the external resource link",
  },
});

const ChatMessageDialog: FC<Props> = ({ onClose, data = {}, url }) => {
  const intl = useIntl();
  const [messageDialogData, setMessageDialogData] = useState<WebviewType>(data);
  const { getWebViewData } = useContext(APIContext);
  const [isLoading, setIsLoading] = useState(true);
  const { logEvent } = useLogEvent();
  const [closeEventName, setCloseEventName] = useState("");

  useEffect(() => {
    const webviewId = url && url.split("/").pop();
    if (webviewId) {
      getWebViewData(webviewId).then((webViewData) => {
        const openEventName = webViewData.eventInfo?.openEventName;
        const closeEventName = webViewData.eventInfo?.closeEventName;

        closeEventName && setCloseEventName(closeEventName);
        openEventName &&
          logEvent({
            eventAction: openEventName,
            eventLabel: "User Action",
          });

        setMessageDialogData(webViewData);
        !webViewData.imageUrl && setIsLoading(false);
      });
    }
  }, []);

  const handleCloseWebview = () => {
    closeEventName &&
      logEvent({
        eventAction: closeEventName,
        eventLabel: "User Action",
      });
    onClose();
  };

  return (
    <Dialog
      className="chat-message-dialog"
      onClose={handleCloseWebview}
      showCrossButton
      focusTrapOptions={{ skipInitialFocus: true }}
    >
      <div
        className={classNames("dialog-container", {
          hidden: isLoading,
        })}
      >
        {messageDialogData.imageUrl && (
          <img
            className="chat-message-dialog__image"
            src={messageDialogData.imageUrl}
            alt={messageDialogData.imageAltText}
            onLoad={() => setIsLoading(false)}
          />
        )}
        {messageDialogData.text && (
          <p
            className="chat-message-dialog__text"
            role="textbox"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                wrapTextAddLinks(
                  unescape(messageDialogData.text),
                  intl.formatMessage(messages.chatMessageDialogExternalLink)
                )
              ),
            }}
          />
        )}
      </div>
      {isLoading && (
        <div className="loader-container">
          <SpinnerLoader />
        </div>
      )}
    </Dialog>
  );
};

export default ChatMessageDialog;
