import { ProsperaOrganType, TestType } from "@app/provider/testData/types";
import { TestResult } from "@app/provider/testResult";
import { ProductLinks } from "@app/provider/config";

type ProductLinkProps = {
  testResult: TestResult;
  productLinks: ProductLinks;
};
type GetProductLink = (props: ProductLinkProps) => string | undefined;

export const getProductLink: GetProductLink = ({
  testResult,
  productLinks,
}) => {
  let productLink;
  switch (testResult.testType) {
    case TestType.PROSPERA:
      productLink =
        productLinks[testResult.testType][
          testResult.organ || ProsperaOrganType.OTHER
        ];
      break;
    case TestType.EMPOWER:
      productLink = productLinks.EMPOWER_ONCOLOGY;
      break;
    case TestType.ALTERA:
    case TestType.SIGNATERA:
    case TestType.RENASIGHT:
    case TestType.HORIZON:
    case TestType.PANORAMA:
    case TestType.VISTARA:
      productLink = productLinks[testResult.testType];
      break;
    default:
      break;
  }

  return productLink;
};
