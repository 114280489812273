import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import {
  ChangeLanguageDialog,
  CloseWidgetDialog,
  ChatMessageDialog,
  PatientReviewDialog,
} from "@app/neva/components/dialogs";
import { useDialog } from "@natera/platform/lib/hooks";

type IframeSettingsType = {
  isOpenIframe: boolean;
  isLoading: boolean;
  isShowIframe: boolean;
  url: string;
  isQuestionnaireIframeOpened: boolean;
  opportunityToManualClose: boolean;
  widgetIframeSource: MessageEventSource | null;
};

export type useDialogType = ReturnType<typeof useDialog>;
export interface DialogsController {
  closeWidgetDialog: useDialogType;
  changeLanguageDialog: useDialogType;
  chatMessageDialog: useDialogType;
  patientReviewDialog: useDialogType;
  iframeSettings: IframeSettingsType;
  setIframeSettings: Dispatch<SetStateAction<IframeSettingsType>>;
}

export const Context = createContext<DialogsController>(
  {} as DialogsController
);

Context.displayName = "DialogsContext";

const DialogsProvider: FC = ({ children }) => {
  const closeWidgetDialog = useDialog(CloseWidgetDialog);
  const changeLanguageDialog = useDialog(ChangeLanguageDialog);
  const chatMessageDialog = useDialog(ChatMessageDialog);
  const patientReviewDialog = useDialog(PatientReviewDialog);
  const [iframeSettings, setIframeSettings] = useState({
    isOpenIframe: false,
    isLoading: true,
    isShowIframe: false,
    url: "",
    isQuestionnaireIframeOpened: false,
    opportunityToManualClose: true,
    widgetIframeSource: null,
  });

  const value = useMemo(
    () => ({
      closeWidgetDialog,
      changeLanguageDialog,
      chatMessageDialog,
      patientReviewDialog,
      iframeSettings,
      setIframeSettings,
    }),
    [
      closeWidgetDialog,
      changeLanguageDialog,
      chatMessageDialog,
      patientReviewDialog,
      iframeSettings,
      setIframeSettings,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default DialogsProvider;
