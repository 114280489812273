import React, { FC } from "react";
import Svg from "@natera/material/lib/svg";
import { RatingItemType } from "@app/neva/models";
import StarRatingFill from "@app/neva/assets/svg/star-rating-fill.svg";
import StarRatingEmpty from "@app/neva/assets/svg/star-rating-empty.svg";
import "./rating.scss";

interface Props {
  item: RatingItemType;
  isDisabled: boolean;
  rating: number;
  onClickHandler: (props: RatingItemType) => void;
}

const StarsRating: FC<Props> = ({
  item,
  isDisabled,
  rating,
  onClickHandler,
}) => {
  const starView = (value: number) =>
    value <= rating ? (
      <Svg content={StarRatingFill} />
    ) : (
      <Svg content={StarRatingEmpty} />
    );

  return (
    <li className="stars-rating__item">
      <button
        className="stars-rating__button"
        role="checkbox"
        aria-label={item.ariaLabel}
        aria-checked={item.scoreNumber <= rating}
        onClick={() => onClickHandler(item)}
        disabled={isDisabled}
      >
        {starView(item.scoreNumber)}
      </button>
    </li>
  );
};

export default StarsRating;
