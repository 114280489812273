import React, { FC } from "react";
import { StepperContext } from "@natera/stepper";
import KitShippingStep from "@app/components/addressStep/addressStep";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { Address } from "@app/provider/profile";
import { ProfileContext } from "@app/provider";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";

interface KitShippingDetailsProps {
  isInitialStep?: boolean;
}

const KitShippingDetails: FC<KitShippingDetailsProps> = ({ isInitialStep }) => {
  const history = useHistory();
  const { goBack } = React.useContext(StepperContext);
  const { addPatientAddress, addPatientAddressIsLoading } = React.useContext(
    ProfileContext
  );
  const handleSubmit = async (address: Address) => {
    if (!address.uid) {
      await addPatientAddress(address);
    }
  };

  const routerBack = () => {
    history.length > 1 ? history.goBack() : history.push(routes.home);
  };

  const handleBack = () => {
    isInitialStep ? routerBack() : goBack();
  };

  return (
    <>
      <StepperHeaderProgress />
      <KitShippingStep
        isKitShippingStep
        onCancel={handleBack}
        onSubmit={handleSubmit}
        isLoading={addPatientAddressIsLoading}
      />
    </>
  );
};

export default KitShippingDetails;
