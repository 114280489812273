import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { TestCardStatus, TestType } from "@app/provider/testData/types";
import { routes } from "@app/routing";
import { Grid, GridCell } from "@natera/material/lib/layout";
import {
  AppointmentDetails,
  BlueHeader,
  DetailInformationButton,
  InformationCard,
  KitDetailsSection,
  SpinnerView,
  TestCard,
  TestInformationCard,
  MyResultsSection,
} from "@app/components";
import InformationIcon from "@assets/svg/icons/information.svg";
import LinkArrowIcon from "@assets/svg/icons/link-arrow.svg";
import { messages } from "./messages";
import { ScrollContext } from "@natera/platform/lib/components/scrollbar";
import { TestDetailsContext } from "@app/provider/testData";
import { showAppointmentDetails } from "@app/utils";
import "./detailsRecord.scss";
import { useHistory } from "react-router-dom";
import { BusinessUnits } from "@app/provider/drawRequest";

const DetailRecordPage: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { isLoading, getTestDetails, getOrderUid, getTestUid } = useContext(
    TestDetailsContext
  );

  const testDetails = getTestDetails();
  const orderUid = getOrderUid();
  const testUid = getTestUid();

  const content = useMemo(
    () =>
      testDetails ? (
        <TestInformationCard testDetailsInformation={testDetails} />
      ) : null,
    [testDetails]
  );

  const [isKitSectionActive, setKitSectionActive] = useState(false);
  const [isAppointmentDetailsNeeded, setIsAppointmentDetailsNeeded] = useState(
    false
  );
  const [areMyResultsReady, setMyResultsReady] = useState(false);

  const kitSectionAllowedStatuses = [
    TestCardStatus.KIT_SHIPPED,
    TestCardStatus.APPT_CONFIRMED,
    TestCardStatus.APPT_REQUESTED,
  ];

  const extendKitSectionAllowedStatuses = (statuses: TestCardStatus[]) => {
    statuses.forEach((status) => {
      if (!kitSectionAllowedStatuses.includes(status)) {
        kitSectionAllowedStatuses.push(status);
      }
    });
  };

  useEffect(() => {
    if (testDetails?.businessUnit === BusinessUnits.ONCOLOGY) {
      extendKitSectionAllowedStatuses([
        TestCardStatus.TEST_ORDERED,
        TestCardStatus.UPCOMING_TEST,
      ]);
    } else if (
      testDetails?.businessUnit === BusinessUnits.WOMENS_HEALTH &&
      testDetails?.testType === TestType.EMPOWER
    ) {
      extendKitSectionAllowedStatuses([TestCardStatus.TEST_ORDERED]);
    }

    if (testDetails && kitSectionAllowedStatuses.includes(testDetails.status)) {
      setKitSectionActive(true);
    }

    if (
      testDetails &&
      [TestCardStatus.RESULT_READY, TestCardStatus.TEST_COMPLETE].includes(
        testDetails.status
      )
    ) {
      setMyResultsReady(true);
    }

    const isShowAppointmentDetails = showAppointmentDetails(testDetails);
    setIsAppointmentDetailsNeeded(isShowAppointmentDetails);
  }, [testDetails]);

  const handleModifyAppointment = () => {
    history.push(routes.sampleDrawModifyPage(orderUid, testUid), {
      prevPage: history.location.pathname,
    });
  };

  const handleScheduleDraw = () => {
    history.push(routes.sampleDrawPage(orderUid, testUid));
  };

  return (
    <>
      <div className="details-record">
        <BlueHeader
          arrowRoute={routes.home}
          title={intl.formatMessage(messages.detailsRecordBackButton)}
        />
        <div className="details-record__container">
          <ScrollContext component="div" isStatic={false}>
            <Grid>
              <SpinnerView isLoading={isLoading} />
              {testDetails && (
                <GridCell className="details-record__test-cards" span={12}>
                  <div className="details-record__column">
                    <TestCard
                      testCard={testDetails}
                      showPrimaryAction={false}
                    />
                    <div className="details-record__wrapper">
                      <div className="details-record__test-information-button">
                        <DetailInformationButton
                          name={intl.formatMessage(
                            messages.detailsRecordTestInformationButton
                          )}
                          route={routes.testDetailsInformationPage(
                            testDetails.orderUid,
                            testDetails.uid
                          )}
                          startIcon={InformationIcon}
                          endIcon={LinkArrowIcon}
                        />
                      </div>

                      {isKitSectionActive &&
                        testDetails?.trackingNumber &&
                        testDetails?.kitShippedDate && (
                          <div className="details-record__kit-details">
                            <KitDetailsSection
                              trackingNumber={testDetails.trackingNumber}
                              kitShippedDate={testDetails.kitShippedDate}
                            />
                          </div>
                        )}

                      {isAppointmentDetailsNeeded && (
                        <div className="details-record__appointment-details">
                          <AppointmentDetails
                            handleModifyAppointment={handleModifyAppointment}
                            handleScheduleDraw={handleScheduleDraw}
                          />
                        </div>
                      )}

                      {areMyResultsReady && (
                        <div className="details-record__view-results">
                          <MyResultsSection testCard={testDetails} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="details-record__test-information-card">
                    <InformationCard
                      headerIcon={InformationIcon}
                      headerText={intl.formatMessage(
                        messages.detailsRecordTestInformationHeader
                      )}
                      content={content}
                    />
                  </div>
                </GridCell>
              )}
            </Grid>
          </ScrollContext>
        </div>
      </div>
    </>
  );
};

export default DetailRecordPage;
