import React, { FC } from "react";
import { TestType } from "@app/provider/testData/types";
import { defineMessages } from "react-intl";
import useTestTitles from "@app/hooks/useTestTitles";

export const titles = defineMessages({
  testRenasightTitle: {
    id: "common.testcards.renasight",
    defaultMessage: "Renasight",
  },
  testProsperaTitle: {
    id: "common.testcards.prospera",
    defaultMessage: "Prospera",
  },
  testHorizonTitle: {
    id: "common.testcards.horizon",
    defaultMessage: "Horizon",
  },
  testPanoramaTitle: {
    id: "common.testcards.panorama",
    defaultMessage: "Panorama",
  },
  testEmpowerTitle: {
    id: "common.testcards.empower",
    defaultMessage: "Empower",
  },
  testSignateraTitle: {
    id: "common.testcards.signatera",
    defaultMessage: "Signatera",
  },
  testAlteraTitle: {
    id: "common.testcards.altera",
    defaultMessage: "Altera",
  },
  testVistaraTitle: {
    id: "common.testcards.vistara",
    defaultMessage: "Vistara",
  },
  testSpectrumTitle: {
    id: "common.testcards.spectrum",
    defaultMessage: "Spectrum",
  },
  testAnoraTitle: {
    id: "common.testcards.anora",
    defaultMessage: "Anora",
  },
  commonOncologyTitle: {
    id: "common.titles.oncology",
    defaultMessage: "Oncology",
  },
  commonOHTitle: {
    id: "common.titles.OH",
    defaultMessage: "Organ Health",
  },
  commonWHTitle: {
    id: "common.titles.WH",
    defaultMessage: "Women’s Health",
  },
});

interface Props {
  testType: TestType;
}
const TestTitle: FC<Props> = ({ testType }) => {
  const testTitles = useTestTitles();

  return <>{testTitles[testType]}</>;
};

export default TestTitle;
