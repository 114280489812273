import React, { FC, createContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { omit } from "ramda";

import PasswordPolicyService from "@app/service/passwordPolicy";

export type PasswordComplexityKeys =
  | "minLength"
  | "minLowerCase"
  | "minUpperCase"
  | "minNumber"
  | "minSymbol"
  | "excludeUsername";

export type PasswordPolicyComplexity = Record<
  PasswordComplexityKeys,
  number | boolean
> & { __typename: string };

interface PasswordPolicy {
  complexity: PasswordPolicyComplexity;
}

export interface PasswordPolicyController {
  isLoading: boolean;
  passwordPolicy?: PasswordPolicy | null;
  getPasswordPolicy: () => Promise<void>;
}

export const Context = createContext<PasswordPolicyController>({
  isLoading: false,
  getPasswordPolicy: async () => undefined,
});

Context.displayName = "PasswordPolicyContext";

const PasswordPolicyProvider: FC = ({ children }) => {
  const [getPasswordPolicy, { loading, data }] = useLazyQuery<{
    getPasswordPolicy: PasswordPolicy;
  }>(PasswordPolicyService.GetPasswordPolicy());

  const handleGetPasswordPolicy = async () => {
    getPasswordPolicy();
  };

  const omittedTypenameData: PasswordPolicy = {
    ...data?.getPasswordPolicy,
    complexity: omit(
      ["__typename"],
      data?.getPasswordPolicy.complexity
    ) as PasswordPolicyComplexity,
  };

  return (
    <Context.Provider
      value={{
        isLoading: loading,
        passwordPolicy: omittedTypenameData,
        getPasswordPolicy: handleGetPasswordPolicy,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default PasswordPolicyProvider;
