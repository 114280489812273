import { useIntl } from "react-intl";

export const weekDayFormatter = (date: Date | null): string => {
  const intl = useIntl();

  if (!date || isNaN(date.getTime())) {
    return "";
  }

  return new Intl.DateTimeFormat(intl.locale, { weekday: "short" }).format(
    date
  );
};
