import React from "react";
import { Government, SubSteps } from "./paymentMethod";
import {
  EnterOtherInsuranceCompanyStep,
  GovernmentStep,
  ProvideGovernmentStep,
  InsuranceCostEstimationStep,
} from "@app/components/simpleOrder/payment/steps";
import { MultipleStep, onConfirmType } from "./multipleStepsMapper";
import { PredefinedCompanyName } from "@app/components/companySelector/companySelector";
export const getGovernmentSteps = (
  governmentStepData: Government,
  onConfirm: onConfirmType
): MultipleStep[] => {
  const otherCompanyStep = !Object.values(PredefinedCompanyName).includes(
    governmentStepData.paymentMethodInfo.companyName as PredefinedCompanyName
  );

  const governmentSteps: MultipleStep[] = [
    {
      stepId: SubSteps.SELECT_GOVERNMENT_COMPANY,
      index: 1,
      childComponent: <GovernmentStep onConfirm={onConfirm} />,
      data: {
        companyName: otherCompanyStep
          ? PredefinedCompanyName.Other
          : governmentStepData.paymentMethodInfo.companyName,
        companyGroupId: otherCompanyStep
          ? undefined
          : governmentStepData.paymentMethodInfo.companyGroupId,
      },
    },
    {
      stepId: SubSteps.PROVIDE_GOVERNMENT_INSURANCE,
      index: 3,
      childComponent: <ProvideGovernmentStep />,
      data: {
        insuranceNumber: governmentStepData.paymentMethodInfo.insuranceNumber,
      },
    },
    {
      stepId: SubSteps.COST_ESTIMATION,
      index: 4,
      childComponent: <InsuranceCostEstimationStep onSubmit={onConfirm} />,
      data: {},
    },
  ];

  if (otherCompanyStep) {
    governmentSteps.push({
      stepId: SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY,
      index: 2,
      childComponent: (
        <EnterOtherInsuranceCompanyStep
          previousStepId={SubSteps.SELECT_GOVERNMENT_COMPANY}
        />
      ),
      data: {
        otherCompanyName: governmentStepData.paymentMethodInfo.companyName,
        otherCompanyGroupId:
          governmentStepData.paymentMethodInfo.companyGroupId,
      },
    });
  }

  return governmentSteps;
};
