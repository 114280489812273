import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addressFormStreetAddress: {
    id: "addressFormStreetAddress",
    defaultMessage: "Street Address",
  },
  addressFormStreetAddressPlaceholder: {
    id: "addressFormStreetAddressPlaceholder",
    defaultMessage: "Enter Street Address",
  },
  addressFormApartmentUnit: {
    id: "addressFormApartmentUnit",
    defaultMessage: "Apartment/Unit",
  },
  addressFormApartmentUnitPlaceholder: {
    id: "addressFormApartmentUnitPlaceholder",
    defaultMessage: "Enter Apartment or Unit Number",
  },
  addressFormCity: {
    id: "addressFormCity",
    defaultMessage: "City",
  },
  addressFormCityPlaceholder: {
    id: "addressFormCityPlaceholder",
    defaultMessage: "Enter City",
  },
  addressFormState: {
    id: "addressFormState",
    defaultMessage: "State",
  },
  addressFormStatePlaceholder: {
    id: "addressFormStatePlaceholder",
    defaultMessage: "Select State",
  },
  addressFormZipCode: {
    id: "addressFormZipCode",
    defaultMessage: "Zip Code",
  },
  addressFormZipCodePlaceholder: {
    id: "addressFormZipCodePlaceholder",
    defaultMessage: "Enter Your Zip Code",
  },
  addressFormInvalidZipCode: {
    id: "addressFormInvalidZipCode",
    defaultMessage: "Please enter a valid zip code.",
  },
  addressFormRequired: {
    id: "addressFormRequired",
    defaultMessage: "Please fill in this field.",
  },
  addressFormZipTooltip: {
    id: "addressFormZipTooltip",
    defaultMessage:
      "Required format: 5-digit number, or 9-digit number with a dash after the 5th digit XXXXX or XXXXX-XXXX",
  },
});
