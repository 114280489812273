import { useMemo } from "react";
import { useIntl } from "react-intl";
import { TestType } from "@app/provider/testData/types";
import { titles } from "../components/common/testTitle";
const useTestTitles = () => {
  const intl = useIntl();

  return useMemo(
    () => ({
      [TestType.RENASIGHT]: intl.formatMessage(titles.testRenasightTitle),
      [TestType.PROSPERA]: intl.formatMessage(titles.testProsperaTitle),
      [TestType.HORIZON]: intl.formatMessage(titles.testHorizonTitle),
      [TestType.PANORAMA]: intl.formatMessage(titles.testPanoramaTitle),
      [TestType.EMPOWER]: intl.formatMessage(titles.testEmpowerTitle),
      [TestType.SIGNATERA]: intl.formatMessage(titles.testSignateraTitle),
      [TestType.ALTERA]: intl.formatMessage(titles.testAlteraTitle),
      [TestType.VISTARA]: intl.formatMessage(titles.testVistaraTitle),
      [TestType.SPECTRUM]: intl.formatMessage(titles.testSpectrumTitle),
      [TestType.ANORA]: intl.formatMessage(titles.testAnoraTitle),
    }),
    [intl]
  );
};

export default useTestTitles;
