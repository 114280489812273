import React, { FC, useContext, useEffect } from "react";
import classNames from "classnames";
import { useIntl, defineMessages } from "react-intl";

import { useDialog } from "@natera/platform/lib/hooks";
import { Button } from "@natera/platform/lib/components/form";
import { Svg } from "@natera/material/lib/svg";
import { CredentialsIdentityProvider } from "@natera/platform/lib/service/session";
import GoogleLogoSvg from "@assets/svg/google-logo.svg";
import AppleLogoSvg from "@assets/svg/apple-logo.svg";
import {
  ServiceContext,
  ConfigContext,
  UppAuthContext,
  UserContext,
  NotificationContext,
} from "@app/provider";
import { ResultCodes } from "@app/service/resultCodes";
import AcknowledgementsDialog from "../acknowledgmentsDialog";
import "./signInWithAuthenticationProviders.scss";
import { PatientPortalUser } from "@app/service/session";
import { IDP_TYPE } from "@app/service/user";
import { HEAP_EVENTS } from "@app/provider/types";
import { LinkSource } from "@app/hooks/useTokenizedLinks";
import HighlightWrapper from "../highlightWrapper/highlightWrapper";
import {
  getLoginMethodFromCookie,
  saveLoginMethodToCookie,
} from "@app/utils/cookiesHelper";
import { Link } from "react-router-dom";
import { routes } from "@app/routing";

const messages = defineMessages({
  signInWithAuthenticationProvidersContinueWithGoogle: {
    id: "signInWithAuthenticationProvidersContinueWithGoogle",
    defaultMessage: "Continue with Google",
  },
  signInWithAuthenticationProvidersContinueWithApple: {
    id: "signInWithAuthenticationProvidersContinueWithApple",
    defaultMessage: "Continue with Apple",
  },
  highlightedTooltipDescriptionGoogle: {
    id: "highlightedTooltipDescriptionGoogle",
    defaultMessage: "You previously logged in with your Google account.",
  },
  highlightedTooltipDescriptionApple: {
    id: "highlightedTooltipDescriptionApple",
    defaultMessage: "You previously logged in with your Apple account.",
  },
  signInWithAuthenticationProvidersUserExistedNotification: {
    id: "signInWithAuthenticationProvidersUserExistedNotification",
    defaultMessage: "This email address is already in use.",
  },
  signInWithAuthenticationProvidersSignInTitle: {
    id: "signInWithAuthenticationProvidersSignInTitle",
    defaultMessage: "Log In",
  },
  signInWithAuthenticationProvidersUnrecognizedError: {
    id: "signInWithAuthenticationProvidersUnrecognizedError",
    defaultMessage: "We're sorry. Something went wrong.",
  },
});

interface SignInWithIdentityProvidersProps {
  className?: string;
  invite: string | null;
  tokenSource?: LinkSource;
  isSignInFlow?: boolean;
}

const SignInWithIdentityProviders: FC<SignInWithIdentityProvidersProps> = ({
  className,
  invite,
  tokenSource,
  isSignInFlow,
}) => {
  const intl = useIntl();

  const { clear, addNotification } = useContext(NotificationContext);
  const { sessionService } = useContext(ServiceContext);
  const { config } = useContext(ConfigContext);
  const {
    startSignInByAuthenticationProvider,
    endSignInByAuthenticationProvider,
    logout,
    signInByAuthenticationProviderInProgress,
  } = useContext(UppAuthContext);
  const {
    uppUserError,
    uppUser,
    deletedUserData,
    loadProfile,
    createUser,
    createUserError,
    resetCreateUserError,
  } = useContext(UserContext);

  const acknowdledgementsDialog = useDialog(AcknowledgementsDialog);

  const handleAgreeAcknowledgements = (
    currentUser: PatientPortalUser
  ) => async () => {
    try {
      await createUser({
        oktaUID: currentUser.uid,
        email: currentUser.sub,
        invite,
      });

      await loadProfile();
    } finally {
      acknowdledgementsDialog.close();
      endSignInByAuthenticationProvider();
    }
  };

  const handleCloseAcknowledgementsDialog = async () => {
    await logout();
    acknowdledgementsDialog.close();
    endSignInByAuthenticationProvider();
  };

  useEffect(() => {
    if (uppUserError) {
      sessionService.getUserData().then((value) => {
        if (
          uppUserError.find(
            (e) => e.code === ResultCodes.USER_NOT_FOUND_ERROR
          ) &&
          value
        ) {
          acknowdledgementsDialog.open({
            userEmail: value.email,
            onAgreeAcknowledgements: handleAgreeAcknowledgements(value),
            onCloseDialog: () => handleCloseAcknowledgementsDialog(),
          });
        }
      });
    }

    if (createUserError) {
      switch (createUserError) {
        case ResultCodes.USER_ALREADY_CREATED_ERROR: {
          addNotification({
            title: intl.formatMessage(
              messages.signInWithAuthenticationProvidersUserExistedNotification
            ),
            message: (
              <Link to={routes.signIn}>
                {intl.formatMessage(
                  messages.signInWithAuthenticationProvidersSignInTitle
                )}
              </Link>
            ),
            type: "error",
          });

          break;
        }

        default: {
          addNotification({
            type: "error",
            message: intl.formatMessage(
              messages.signInWithAuthenticationProvidersUnrecognizedError
            ),
          });
        }
      }
      resetCreateUserError();
    }
  }, [uppUserError, createUserError]);

  const sendTokenizedOrOrganicHeapEvent = async (
    invite: string | null,
    tokenizedEventName: HEAP_EVENTS,
    organicEventName: HEAP_EVENTS,
    method: IDP_TYPE
  ) => {
    if (invite) {
      heap.track(tokenizedEventName, {
        method,
        source: tokenSource,
      });
    } else {
      heap.track(organicEventName, { method });
    }
  };

  useEffect(() => {
    if (uppUser) {
      endSignInByAuthenticationProvider();
      signInByAuthenticationProviderInProgress &&
        sendTokenizedOrOrganicHeapEvent(
          invite,
          HEAP_EVENTS.upp_tokenizedlogin_success,
          HEAP_EVENTS.upp_organiclogin_success,
          uppUser.idpType
        );
    }
  }, [uppUser]);

  useEffect(() => {
    if (deletedUserData?.success) {
      window.location.reload();
    }
  }, []);

  const handleAuth = async (
    credentials: CredentialsIdentityProvider,
    idp: IDP_TYPE
  ) => {
    clear();
    try {
      if (await sessionService.getToken()) {
        await sessionService.clearToken();
      }

      startSignInByAuthenticationProvider();
      await sessionService.loginIdentityProvider(credentials);
      saveLoginMethodToCookie(idp);
      await loadProfile();
    } catch (error) {
      await sendTokenizedOrOrganicHeapEvent(
        invite,
        HEAP_EVENTS.upp_tokenizedlogin_failure,
        HEAP_EVENTS.upp_organiclogin_failure,
        idp
      );
      console.error(error);
    }
  };

  const isHighlightedGoogle =
    IDP_TYPE.GOOGLE === getLoginMethodFromCookie() && !!isSignInFlow;
  const isHighlightedApple =
    IDP_TYPE.APPLE === getLoginMethodFromCookie() && !!isSignInFlow;

  return (
    <section
      className={classNames("authentication-providers__container", className)}
    >
      {acknowdledgementsDialog.getDialog()}
      <HighlightWrapper
        isHighlighted={isHighlightedGoogle}
        highlightedElem={"button"}
        tooltipDescription={intl.formatMessage(
          messages.highlightedTooltipDescriptionGoogle
        )}
        tooltipPosition="bottom"
      >
        <Button
          outlined={true}
          onClick={() =>
            handleAuth(
              {
                clientId: config.okta.clientId,
                idp: config.okta.identityProvider.googleIdp,
                issuer: config.okta.issuer,
                scope: config.okta.scopes,
              },
              IDP_TYPE.GOOGLE
            )
          }
        >
          <Svg
            className={classNames(
              "autentication-providers__icon",
              "autentication-providers__icon-google"
            )}
            content={GoogleLogoSvg}
          />
          {intl.formatMessage(
            messages.signInWithAuthenticationProvidersContinueWithGoogle
          )}
        </Button>
      </HighlightWrapper>
      <div className="whitespace" />
      <HighlightWrapper
        isHighlighted={isHighlightedApple}
        highlightedElem={"button"}
        tooltipDescription={intl.formatMessage(
          messages.highlightedTooltipDescriptionApple
        )}
        tooltipPosition="bottom"
      >
        <Button
          className="authentication-providers__apple_ipd"
          outlined={true}
          onClick={() =>
            handleAuth(
              {
                clientId: config.okta.clientId,
                idp: config.okta.identityProvider.appleIdp,
                issuer: config.okta.issuer,
                scope: config.okta.scopes,
              },
              IDP_TYPE.APPLE
            )
          }
        >
          <Svg
            className="autentication-providers__icon"
            content={AppleLogoSvg}
          />
          {intl.formatMessage(
            messages.signInWithAuthenticationProvidersContinueWithApple
          )}
        </Button>
      </HighlightWrapper>
    </section>
  );
};

export default SignInWithIdentityProviders;
