import { useContext } from "react";
import { TestDetailsContext } from "@app/provider";
import { TestType } from "@app/provider/testData/types";

type UseTranscriptFileName = {
  formatFileName: () => string;
};

export const useTranscriptFileName = (): UseTranscriptFileName => {
  const { getTestDetails } = useContext(TestDetailsContext);

  const getProductName = () => {
    const testCard = getTestDetails();

    switch (testCard?.testType) {
      case TestType.HORIZON:
        return "Horizon";
      case TestType.PANORAMA:
        return "Panorama";
      case TestType.EMPOWER:
        return "Empower";
      default:
        return "";
    }
  };

  const formatFileName = () => {
    const productName = getProductName();
    const formattedDate = new Date().toISOString().split("T")[0];
    return `NEVA_session_${productName}_${formattedDate}.pdf`;
  };

  return { formatFileName };
};
