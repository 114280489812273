import React, { FC, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import { routes } from "@app/routing";
import { useQuery } from "@app/utils";
import { NotificationContext, UppAuthContext } from "@app/provider";
import "./updateEmailError.scss";

const messages = defineMessages({
  updateEmailErrorExpired: {
    id: "updateEmailErrorExpired",
    defaultMessage: "This link has expired.",
  },
  updateEmailErrorBroken: {
    id: "updateEmailErrorBroken",
    defaultMessage: "This link is broken.",
  },
  updateEmailErrorLogin: {
    id: "updateEmailErrorLogin",
    defaultMessage: "Log In",
  },
});

type TypeError = "expired" | "invalid";

const UpdateEmailError: FC = () => {
  const history = useHistory();
  const intl = useIntl();
  const query = useQuery();
  const type = query.get("type") as TypeError | null;

  const { addNotification } = useContext(NotificationContext);
  const { profile } = useContext(UppAuthContext);

  useEffect(() => {
    if (type) {
      switch (type) {
        case "expired": {
          addNotification({
            message: intl.formatMessage(messages.updateEmailErrorExpired),
            actions: (
              <Link to={routes.signIn}>
                {intl.formatMessage(messages.updateEmailErrorLogin)}
              </Link>
            ),
            type: "error",
          });
          break;
        }
        case "invalid": {
          addNotification({
            message: intl.formatMessage(messages.updateEmailErrorBroken),
            actions: (
              <Link to={routes.signIn}>
                {intl.formatMessage(messages.updateEmailErrorLogin)}
              </Link>
            ),
            type: "error",
          });
          break;
        }
      }
    }
  }, [type]);

  useEffect(() => {
    if (!profile) {
      history.replace(routes.root);
    } else {
      history.replace(routes.logOut);
    }
  }, []);

  return <div className="update-email-error" />;
};

export default UpdateEmailError;
