import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/platform/lib/components/form";
import { ConfigContext } from "@app/provider";
import { openNewWindow } from "@app/utils";
import "./compassionateCareDialog.scss";

const messages = defineMessages({
  compassionateCareDialogTitle: {
    id: "compassionateCareDialogTitle",
    defaultMessage: "Our Compassionate Care Program",
  },
  compassionateCareDialogDesc: {
    id: "compassionateCareDialogDesc",
    defaultMessage:
      "We understand genetic testing may pose a financial hardship to some people. Patients who qualify for our compassionate care program receive a reduced genetic testing bill and may owe nothing, depending on their financial situation.",
  },
  compassionateCareDialogBack: {
    id: "compassionateCareDialogBack",
    defaultMessage: "Go Back",
  },
  compassionateCareDialogApply: {
    id: "compassionateCareDialogApply",
    defaultMessage: "Apply Now",
  },
  compassionateCareDialogFooterText: {
    id: "compassionateCareDialogFooterText",
    defaultMessage:
      "Our program criteria is based on U.S. Federal Poverty guidelines published by the Department of Health and Human Services, and applies to patient families with annual household income up to 4x the Federal Poverty Line.",
  },
});

interface CompassionateCareDialogProps {
  onCloseDialog: () => void;
}

const CompassionateCareDialog: FC<CompassionateCareDialogProps> = ({
  onCloseDialog,
}) => {
  const intl = useIntl();
  const { config } = useContext(ConfigContext);

  const handleApply = () => {
    openNewWindow(
      `${config.links.PATIENT_PORTAL_LINK}${config.links.COMPASSIONATE_CARE}`
    );
    onCloseDialog();
  };

  return (
    <Dialog
      className="compassionate-dialog"
      title={intl.formatMessage(messages.compassionateCareDialogTitle)}
      actions={
        <div className="compassionate-dialog__footer">
          <Button outlined onClick={onCloseDialog}>
            {intl.formatMessage(messages.compassionateCareDialogBack)}
          </Button>
          <Button raised onClick={handleApply}>
            {intl.formatMessage(messages.compassionateCareDialogApply)}
          </Button>
          <div className="compassionate-dialog__footer__text">
            {intl.formatMessage(messages.compassionateCareDialogFooterText)}
          </div>
        </div>
      }
      onClose={onCloseDialog}
      showCrossButton
    >
      <div className="compassionate-dialog__content">
        <span>{intl.formatMessage(messages.compassionateCareDialogDesc)}</span>
      </div>
    </Dialog>
  );
};

export default CompassionateCareDialog;
