import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import AccountNotAvailableSvg from "@assets/svg/account_not_available.svg";
import { Svg } from "@natera/material/lib/svg";
import "./unableVerifyAccount.scss";
import { Button } from "@natera/platform/lib/components/form";
import { ConfigContext } from "@app/provider";
import { LinkHeap } from "@app/components";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";

const messages = defineMessages({
  unableVerifyAccountTitle: {
    id: "unableVerifyAccountTitle",
    defaultMessage: "We are unable to verify your identity",
  },
  unableVerifyAccountSubTitle: {
    id: "unableVerifyAccountSubTitle",
    defaultMessage:
      "We are having trouble verifying your account at this time. Please contact our customer support for further assistance.",
  },
  unableVerifyAccountCloseButton: {
    id: "unableVerifyAccountCloseButton",
    defaultMessage: "Close",
  },
  unableVerifyAccountFooter: {
    id: "unlockIssuesVerification",
    defaultMessage: "Having issues with verification? ",
  },
  unableVerifyAccountContactUs: {
    id: "unlockContactUs",
    defaultMessage: "Contact Us",
  },
});

interface UnableVerifyAccountProps {
  onClose: () => void;
}

const UnableVerifyAccount: FC<UnableVerifyAccountProps> = ({ onClose }) => {
  const intl = useIntl();

  const { config } = React.useContext(ConfigContext);

  return (
    <div className="unable_verify_account_content">
      <Svg
        className="unable_verify_account_content-icon"
        content={AccountNotAvailableSvg}
      />
      <div className="unable_verify_account_content-title">
        {intl.formatMessage(messages.unableVerifyAccountTitle)}
      </div>
      <div className="unable_verify_account_content-subtitle">
        {intl.formatMessage(messages.unableVerifyAccountSubTitle)}
      </div>

      <Button
        className="unable_verify_account_content-button"
        raised
        onClick={onClose}
      >
        {intl.formatMessage(messages.unableVerifyAccountCloseButton)}
      </Button>
      <div className="unable_verify_account_content-subtitle">
        {intl.formatMessage(messages.unableVerifyAccountFooter)}
        <LinkHeap
          target="_blank"
          rel="noopener noreferrer"
          to={{ pathname: config.links.CONTACT_US }}
          heapEventName={HEAP_EVENTS.upp_click_contactnatera}
          heapEventProps={{
            location: HeapEventLocation.unable_to_verify_unlock_page,
          }}
        >
          {intl.formatMessage(messages.unableVerifyAccountContactUs)}
        </LinkHeap>
      </div>
    </div>
  );
};

export default UnableVerifyAccount;
