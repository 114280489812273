import React from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  PaymentInfo,
  PaymentType,
} from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";

import "./insuranceEstimationInformation.scss";
import { getCommercialInsurancePriceEstimation } from "@app/utils";
import { OrderContext } from "@app/provider";

export const messages = defineMessages({
  insuranceEstimationInformationPersonalizedEstimateTitle: {
    id: "insuranceEstimationInformationPersonalizedEstimateTitle",
    defaultMessage: "Personalized estimate",
  },
  insuranceEstimationInformationPersonalizedEstimateContent: {
    id: "insuranceEstimationInformationPersonalizedEstimateContent",
    defaultMessage:
      "After Natera received your sample, we will generate a personalized estimate, based on your insurance information.",
  },
  insuranceEstimationInformationOutOfPocketCostTitle: {
    id: "insuranceEstimationInformationOutOfPocketCostTitle",
    defaultMessage: "Out-of-pocket cost",
  },
  insuranceEstimationInformationOutOfPocketCostContent: {
    id: "insuranceEstimationInformationOutOfPocketCostContent",
    defaultMessage:
      "Your out-of-pocket cost for this test depends on your insurance coverage and deductible. If you’ve met your deductible, what you pay will go towards that amount, after which insurance begins to contribute to your care. If your insurance plan denies the claim, you will be eligible for our cash price.",
  },
  insuranceEstimationInformationPaymentOptionsTitle: {
    id: "insuranceEstimationInformationPaymentOptionsTitle",
    defaultMessage: "Payment options",
  },
  insuranceEstimationInformationPaymentOptionsContent: {
    id: "insuranceEstimationInformationPaymentOptionsContent",
    defaultMessage:
      "If we estimate your cost to exceed {price}, we’ll attempt to contact you and you choose how you pay: insurance or cash",
  },
  insuranceEstimationInformationNoOutOfPocketCostTitle: {
    id: "insuranceEstimationInformationNoOutOfPocketCostTitle",
    defaultMessage: "No out-of-pocket cost",
  },
  insuranceEstimationInformationGovernmentCostContent: {
    id: "insuranceEstimationInformationGovernmentCostContent",
    defaultMessage:
      "Because you have indicated that you are covered by a government insurance plan, we are unable to estimate your out-of-pocket cost at this time. Please contact your insurance plan for your plan benefits.",
  },
});

type EstimationInformationType = {
  title?: string;
  content: string;
  note?: string;
};

export type InsuranceEstimationInformationProps = {
  paymentMethodData: PaymentInfo;
};

const InsuranceEstimationInformation: React.FC<InsuranceEstimationInformationProps> = ({
  paymentMethodData,
}) => {
  const intl = useIntl();

  const { orderData } = React.useContext(OrderContext);

  const estimationInformation = {
    getPersonalizedEstimate: (): EstimationInformationType => ({
      title: intl.formatMessage(
        messages.insuranceEstimationInformationPersonalizedEstimateTitle
      ),
      content: intl.formatMessage(
        messages.insuranceEstimationInformationPersonalizedEstimateContent
      ),
    }),
    getOutOfPocketCost: (): EstimationInformationType => ({
      title: intl.formatMessage(
        messages.insuranceEstimationInformationOutOfPocketCostTitle
      ),
      content: intl.formatMessage(
        messages.insuranceEstimationInformationOutOfPocketCostContent
      ),
    }),
    getPaymentOptions: (): EstimationInformationType => ({
      title: intl.formatMessage(
        messages.insuranceEstimationInformationPaymentOptionsTitle
      ),
      content: intl.formatMessage(
        messages.insuranceEstimationInformationPaymentOptionsContent,
        {
          price: getCommercialInsurancePriceEstimation(orderData),
        }
      ),
    }),
    getNoOutOfPocketCost: (): EstimationInformationType => ({
      title: intl.formatMessage(
        messages.insuranceEstimationInformationNoOutOfPocketCostTitle
      ),
      content: intl.formatMessage(
        messages.insuranceEstimationInformationGovernmentCostContent
      ),
    }),
  };

  const getCommercialOrGovermentInformationItem = (
    info: EstimationInformationType
  ) => (
    <li className="information-item" key={info.title}>
      <div className="information">
        {info.title && <span className="title">{info.title}</span>}
        <span className="content">{info.content}</span>
        {info.note && <span className="note">{info.note}</span>}
      </div>
    </li>
  );

  const renderInformationList = (
    informationList: EstimationInformationType[]
  ) => (
    <ul className="insurance-estimation-information__list">
      {informationList.map((info) =>
        getCommercialOrGovermentInformationItem(info)
      )}
    </ul>
  );

  const commercialInformationList = [
    estimationInformation.getPersonalizedEstimate(),
    estimationInformation.getOutOfPocketCost(),
    estimationInformation.getPaymentOptions(),
  ];

  const governmentInformationList = [
    estimationInformation.getNoOutOfPocketCost(),
  ];

  const renderInformation = () => {
    if (paymentMethodData.paymentMethod === PaymentType.COMMERCIAL) {
      return renderInformationList(commercialInformationList);
    }

    if (paymentMethodData.paymentMethod === PaymentType.GOVERNMENT_ISSUED) {
      return renderInformationList(governmentInformationList);
    }

    if (paymentMethodData.paymentMethod === PaymentType.MULTIPLE) {
      const firstInsurance = paymentMethodData.paymentMethodInfo.first;
      const secondInsurance = paymentMethodData.paymentMethodInfo.second;

      if (
        firstInsurance.paymentMethod === PaymentType.COMMERCIAL &&
        secondInsurance.paymentMethod === PaymentType.COMMERCIAL
      ) {
        return renderInformationList(commercialInformationList);
      } else {
        return renderInformationList(governmentInformationList);
      }
    }
  };

  return (
    <section className="insurance-estimation-information">
      {renderInformation()}
    </section>
  );
};

export default InsuranceEstimationInformation;
