import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TestType } from "@app/provider/testData/types";
import { BusinessUnits } from "@app/provider/drawRequest";
import useTestTitles from "./useTestTitles";
import { titles } from "../components/common/testTitle";

export const messages = defineMessages({
  testResultSubTitleHorizon: {
    id: "Horizon",
    defaultMessage: "Carrier Screening",
  },
  testResultSubTitlePanorama: {
    id: "Panorama",
    defaultMessage: "Non-Invasive Prenatal Testing-NIPT",
  },
  testResultSubTitleEmpower: {
    id: "Empower",
    defaultMessage: "Hereditary Cancer Test",
  },
  testResultSubTitleVistara: {
    id: "Vistara",
    defaultMessage: "Single-Gene NIPT",
  },
  testResultSubTitleSignatera: {
    id: "testResultSubTitleSignatera",
    defaultMessage: "Molecular Residual Disease Test",
  },
  testResultSubTitleAltera: {
    id: "testResultSubTitleAltera",
    defaultMessage: "Tumor Genomic Profile Test",
  },
  testResultSubTitleProspera: {
    id: "Prospera",
    defaultMessage: "Transplant Assessment",
  },
  testResultSubTitleRenasight: {
    id: "Renasight",
    defaultMessage: "Kidney Gene Panel",
  },
  understandingYourResultsSignateraHeadline: {
    id: "understandingYourResultsSignateraHeadline",
    defaultMessage:
      "Learn about Signatera test results before reviewing your test results",
  },
  understandingYourResultsAlteraHeadline: {
    id: "understandingYourResultsAlteraHeadline",
    defaultMessage:
      "Learn about Altera test results before reviewing your test results",
  },
  understandingYourResultsEmpowerOncologyHeadline: {
    id: "understandingYourResultsEmpowerOncologyHeadline",
    defaultMessage:
      "Learn about Empower test results before reviewing your test results",
  },
  understandingYourResultsEmpowerHeadline: {
    id: "understandingYourResultsEmpowerHeadline",
    defaultMessage: "Learn more about Empower",
  },
  understandingYourResultsVistaraHeadline: {
    id: "understandingYourResultsVistaraHeadline",
    defaultMessage: "Learn more about Vistara",
  },
  understandingYourResultsOrganHealthHeadline: {
    id: "understandingYourResultsOrganHealthHeadline",
    defaultMessage: "Be sure to review results with your provider",
  },
  understandingYourResultsEmpowerSecondParagraph: {
    id: "understandingYourResultsEmpowerSecondParagraph",
    defaultMessage:
      "Test results should be interpreted in the context of the patient’s clinical and family history. Discuss your results and your family history of cancer with your provider to understand your personal risk profile.",
  },
  understandingYourResultsVistaraSecondParagraph: {
    id: "understandingYourResultsVistaraSecondParagraph",
    defaultMessage:
      "Vistara is not a diagnostic test and does not provide a definitive diagnosis. People with Screen Positive results should be offered further diagnostic testing and genetic counseling.",
  },
  understandingYourResultsOrganHealthSecondParagraph: {
    id: "understandingYourResultsOrganHealthSecondParagraph",
    defaultMessage:
      "Results are meant to be reviewed with your doctor before reviewing on your own.",
  },
});

export interface TestResultsTextsController {
  getTestResultDetailsTitle: (testType: TestType) => string;
  getTestResultDetailsSubTitle: (testType: TestType) => string;
  understandingResultsHeadline: (
    testType: TestType,
    businessUnit?: BusinessUnits
  ) => string;
  understandingResultsSecondParagraph: (testType: TestType) => string;
}

const useTestResultsTexts = (): TestResultsTextsController => {
  const intl = useIntl();

  const testTitle = useTestTitles();

  const subTestTitle = useMemo(
    () => ({
      [TestType.RENASIGHT]: intl.formatMessage(
        messages.testResultSubTitleRenasight
      ),
      [TestType.PROSPERA]: intl.formatMessage(
        messages.testResultSubTitleProspera
      ),
      [TestType.HORIZON]: intl.formatMessage(
        messages.testResultSubTitleHorizon
      ),
      [TestType.PANORAMA]: intl.formatMessage(
        messages.testResultSubTitlePanorama
      ),
      [TestType.EMPOWER]: intl.formatMessage(
        messages.testResultSubTitleEmpower
      ),
      [TestType.SIGNATERA]: intl.formatMessage(
        messages.testResultSubTitleSignatera
      ),
      [TestType.ALTERA]: intl.formatMessage(messages.testResultSubTitleAltera),
      [TestType.VISTARA]: intl.formatMessage(
        messages.testResultSubTitleVistara
      ),
      [TestType.SPECTRUM]: intl.formatMessage(titles.testSpectrumTitle),
      [TestType.ANORA]: intl.formatMessage(titles.testAnoraTitle),
    }),
    [intl]
  );

  const understandingResultsHeadline = (
    testType: TestType,
    businessUnit?: BusinessUnits
  ): string => {
    switch (testType) {
      case TestType.SIGNATERA:
        return intl.formatMessage(
          messages.understandingYourResultsSignateraHeadline
        );
      case TestType.ALTERA:
        return intl.formatMessage(
          messages.understandingYourResultsAlteraHeadline
        );
      case TestType.EMPOWER:
        return intl.formatMessage(
          businessUnit === BusinessUnits.ONCOLOGY
            ? messages.understandingYourResultsEmpowerOncologyHeadline
            : messages.understandingYourResultsEmpowerHeadline
        );
      case TestType.VISTARA:
        return intl.formatMessage(
          messages.understandingYourResultsVistaraHeadline
        );
      case TestType.PROSPERA:
      case TestType.RENASIGHT:
      default:
        return intl.formatMessage(
          messages.understandingYourResultsOrganHealthHeadline
        );
    }
  };

  const understandingResultsSecondParagraph = (testType: TestType): string => {
    switch (testType) {
      case TestType.EMPOWER:
        return intl.formatMessage(
          messages.understandingYourResultsEmpowerSecondParagraph
        );
      case TestType.VISTARA:
        return intl.formatMessage(
          messages.understandingYourResultsVistaraSecondParagraph
        );
      case TestType.PROSPERA:
      case TestType.RENASIGHT:
      default:
        return intl.formatMessage(
          messages.understandingYourResultsOrganHealthSecondParagraph
        );
    }
  };

  const getTestResultDetailsTitle = (testType: TestType) => testTitle[testType];
  const getTestResultDetailsSubTitle = (testType: TestType) =>
    subTestTitle[testType];

  return {
    getTestResultDetailsTitle,
    getTestResultDetailsSubTitle,
    understandingResultsHeadline,
    understandingResultsSecondParagraph,
  };
};

export default useTestResultsTexts;
