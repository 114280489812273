import { DateRange } from "@natera/mobile-phlebotomy/lib";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  mobilePhlebotomyTimeslotsRemoveAriaLabel: {
    id: "mobilePhlebotomy.timeslots.timeslotRemove",
    defaultMessage: "Remove {timeSlot}",
  },
});

export const setRemoveTimeslotAriaLabel = (
  slot: DateRange,
  intl: IntlShape
): string => {
  return intl.formatMessage(messages.mobilePhlebotomyTimeslotsRemoveAriaLabel, {
    timeSlot: renderSelectedSlot(slot),
  });
};

export const renderSelectedSlot = (slot: DateRange): string => {
  const date = slot.end.toLocaleString("en-US", {
    timeZone: "UTC",
    day: "numeric",
    month: "numeric",
  });

  return `${date}, ${renderChipTime(slot)}`;
};

export const renderChipTime = (slot: DateRange): string => {
  const slotStart = slot.start.toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
    timeZone: "UTC",
  });

  const slotEnd = slot.end.toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
    timeZone: "UTC",
  });

  return `${slotStart} — ${slotEnd}`;
};
