import Svg from "@natera/material/lib/svg";
import { Button } from "@natera/platform/lib/components/form";
import React, { useEffect, useContext, FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "@natera/platform/lib/components/link";
import { ServiceContext, TestResultContext, UserContext } from "@app/provider";
import { routes } from "@app/routing";
import { messages as headerMessages } from "@app/components/ui/layout/blueHeader/blueHeader";
import { TestDetailsContext } from "@app/provider/testData";
import { IconButton } from "@natera/material/lib/button";
import { Notification } from "@app/components";
import { generatePassword } from "@app/utils";
import { LoadingContext } from "@natera/platform/lib/components/context";
import { HEAP_EVENTS } from "@app/provider/types";
import { saveAs } from "file-saver";

import Arrow from "@assets/svg/icons/arrow.svg";
import CopySvg from "@assets/svg/icons/copy.svg";

import "./shareFetalSex.scss";

const messages = defineMessages({
  shareFetalSexHeader: {
    id: "shareFetalSexHeader",
    defaultMessage: "Share Fetal Sex",
  },
  shareFetalSexTitle: {
    id: "shareFetalSexTitle",
    defaultMessage: "Share Fetal Sex",
  },
  shareFetalSexSubTitle: {
    id: "shareFetalSexSubTitle",
    defaultMessage:
      "You can share the fetal sex with someone else by downloading and sending this password protected document.",
  },
  shareFetalSexPassword: {
    id: "shareFetalSexPassword",
    defaultMessage: "Password",
  },
  shareFetalSexCopyPasswordBtnAriaLabel: {
    id: "shareFetalSexCopyPasswordBtnAriaLabel",
    defaultMessage: "Copy password",
  },
  shareFetalSexDownloadPdfBtn: {
    id: "shareFetalSexDownloadPdfBtn",
    defaultMessage: "Download PDF & Copy Password",
  },
  shareFetalSexNote: {
    id: "shareFetalSexNote",
    defaultMessage:
      "A new password is generated every time you open this screen. Please make sure you share the correct password.",
  },
  shareFetalSexFooter: {
    id: "shareFetalSexFooter",
    defaultMessage:
      "By sharing this document and password with others,you understand that the personal health information contained in the document may no longer be protected by HIPAA or other applicable privacy or confidentiality laws. Please exercise caution when sharing as Natera is not responsible for your further sharing of this document.",
  },
});

const ShareFetalSexPage: FC = () => {
  const intl = useIntl();
  const { getTestDetails, getOrderUid, getTestUid } = useContext(
    TestDetailsContext
  );
  const { testResult, getTestResult } = useContext(TestResultContext);
  const { fileService } = useContext(ServiceContext);
  const { isLoading } = useContext(UserContext);

  const testCard = getTestDetails();
  const testUid = getTestUid();
  const orderUid = getOrderUid();

  const generatedPassword = generatePassword();

  useEffect(() => {
    if (orderUid && testUid) {
      getTestResult(orderUid, testUid);
    }
  }, []);

  if (!testResult || !testCard) {
    return null;
  }

  const backButtonRoute = () => {
    return routes.testResultDetailsPage(testCard.orderUid, testCard.uid);
  };

  const downloadPdf = async () => {
    const body = { password: generatedPassword };

    const fileData = await fileService.retrieveProtectedFile(
      `/result/fetal-sex-pdf/${orderUid}`,
      body
    );

    saveAs(fileData, `fetal-sex-report.pdf`);
  };

  const copyPassword = async () => {
    await navigator.clipboard.writeText(generatedPassword);
    heap.track(HEAP_EVENTS.upp_fetalsexreporting_copy, {
      lims_clinic_id: testCard.clinic?.limsId,
      order_uid: testCard.orderUid,
    });
  };

  const downloadPdfAndCopyPassword = async () => {
    await downloadPdf();
    await navigator.clipboard.writeText(generatedPassword);

    heap.track(HEAP_EVENTS.upp_fetalsexreporting_downloadcta, {
      lims_clinic_id: testCard.clinic?.limsId,
      order_uid: testCard.orderUid,
    });
  };

  return (
    <LoadingContext isLoading={isLoading}>
      <div className="share-fetal-sex_page" data-testid="share-fetal-sex-page">
        <div className="share-fetal-sex_header">
          <div className="share-fetal-sex_header-title">
            <Link
              to={backButtonRoute}
              role="button"
              aria-label={intl.formatMessage(headerMessages.blueHeaderBack)}
            >
              <Svg content={Arrow} />
            </Link>
            <span>{intl.formatMessage(messages.shareFetalSexHeader)}</span>
          </div>
        </div>
        <section className="share-fetal-sex_card">
          <div className="share-fetal-sex_card-header">
            <h2 className="share-fetal-sex_test-title">
              {intl.formatMessage(messages.shareFetalSexTitle)}
            </h2>
            <h3 className="share-fetal-sex_sub-title">
              {intl.formatMessage(messages.shareFetalSexSubTitle)}
            </h3>
          </div>
          <div className="share-fetal-sex_card-main">
            <div className="password">
              <span className="password_label">{`${intl.formatMessage(
                messages.shareFetalSexPassword
              )}:`}</span>
              <span className="password_content">{generatedPassword}</span>
              <IconButton
                className="copy-password-btn"
                onClick={copyPassword}
                aria-label={intl.formatMessage(
                  messages.shareFetalSexCopyPasswordBtnAriaLabel
                )}
              >
                {CopySvg}
              </IconButton>
            </div>
            <Button onClick={downloadPdfAndCopyPassword} raised>
              {intl.formatMessage(messages.shareFetalSexDownloadPdfBtn)}
            </Button>

            <div className="information-container">
              <Notification className="note" type="note">
                {intl.formatMessage(messages.shareFetalSexNote)}
              </Notification>

              <div className="disclaimer">
                {intl.formatMessage(messages.shareFetalSexFooter)}
              </div>
            </div>
          </div>
        </section>
      </div>
    </LoadingContext>
  );
};
export default ShareFetalSexPage;
