import React, { FC } from "react";
import classNames from "classnames";
import { CustomTooltip, TooltipPosition } from "./customTooltip";

import "./highlightWrapper.scss";

type TypeOfElem = "button" | "form";

export interface HighlightWrapperProps {
  isHighlighted: boolean;
  highlightedElem: TypeOfElem;
  tooltipDescription: string;
  tooltipPosition: TooltipPosition;
}

const HighlightWrapper: FC<HighlightWrapperProps> = ({
  children,
  highlightedElem,
  isHighlighted,
  tooltipDescription,
  tooltipPosition,
}) => {
  const childRef = React.useRef<HTMLDivElement>(null);

  const [isActive, setIsActive] = React.useState<boolean>(isHighlighted);
  const [tooltipMargin, setTooltipMargin] = React.useState<number>(0);
  const [tooltipHeight, setTooltipHeight] = React.useState<number>(0);

  const handleClose = () => {
    setIsActive(false);
  };

  const updateTooltipSize = (size: number) => {
    setTooltipHeight(size);
  };

  React.useEffect(() => {
    if (childRef.current) {
      const rect = childRef.current.getBoundingClientRect();
      tooltipPosition === "top"
        ? setTooltipMargin(rect.top + window.scrollY - tooltipHeight)
        : setTooltipMargin(rect.bottom + window.scrollY + 8);
    }
  }, [children]);

  return (
    <div className="highlight-wrapper" ref={childRef}>
      <div
        className={classNames("highlight-wrapper__tooltip", {
          hidden: !isActive,
        })}
      >
        <CustomTooltip
          updateSize={updateTooltipSize}
          onClose={handleClose}
          topPosition={tooltipMargin}
          tooltipPosition={tooltipPosition}
          description={tooltipDescription}
        />
      </div>
      <div
        className={classNames("highlight-wrapper__background", {
          hidden: !isActive,
        })}
      ></div>

      <div
        className={classNames(`highlight-wrapper__${highlightedElem}`, {
          "highlight-wrapper__content": isActive,
        })}
        onClick={handleClose}
      >
        {children}
      </div>
    </div>
  );
};

export default HighlightWrapper;
