import React from "react";

import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/platform/lib/components/form";
import { defineMessages, useIntl } from "react-intl";
import { contactPhoneMapper } from "@app/utils/contactPhoneMapper";
import { BusinessUnits } from "@app/provider/drawRequest";

const messages = defineMessages({
  kitErrorDialogHeader: {
    id: "kitErrorDialogHeader",
    defaultMessage: "Kit cannot be ordered at this time",
  },
  kitErrorDialogDescription: {
    id: "kitErrorDialogDescription",
    defaultMessage:
      "We’re unable to complete your kit order. Please give us a call at {phone} to request kit shipment.",
  },
  kitErrorDialogButtonError: {
    id: "kitErrorDialogButtonError",
    defaultMessage: "Done",
  },
});

type Props = {
  onClose: () => void;
  businessUnit?: BusinessUnits;
};

const KitErrorDialog: React.FC<Props> = ({
  onClose,
  businessUnit = BusinessUnits.ORGAN_HEALTH,
}) => {
  const intl = useIntl();

  return (
    <Dialog
      className="mobile-phlebotomy-modal"
      onClose={onClose}
      showCrossButton
      title={intl.formatMessage(messages.kitErrorDialogHeader)}
      actions={
        <Button raised onClick={onClose}>
          {intl.formatMessage(messages.kitErrorDialogButtonError)}
        </Button>
      }
    >
      <div className="mobile-phlebotomy-modal__container">
        <p>
          {intl.formatMessage(messages.kitErrorDialogDescription, {
            phone: contactPhoneMapper(businessUnit),
          })}
        </p>
      </div>
    </Dialog>
  );
};

export default KitErrorDialog;
