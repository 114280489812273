import { HeapPHIDecorator } from "@app/components/analytics";
import { Form, Button } from "@natera/platform/lib/components/form";
import { Link } from "react-router-dom";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Textfield } from "@natera/platform/lib/components/form/field";
import { Tooltip } from "@natera/material/lib/tooltip";
import classNames from "classnames";
import { routes } from "@app/routing";
import { FormField, Notification, Progress } from "@app/components";
import { validateEmail } from "@app/utils";
import { useSendActivation } from "@app/hooks";
import { ConfigContext, IntlContext } from "@app/provider";

import "./resendActivationLink.scss";

const messages = defineMessages({
  resendActivationLinkTitle: {
    id: "resendActivationLinkTitle",
    defaultMessage: "Resend Activation Link",
  },
  resendLinkInstructions: {
    id: "resendLinkInstructions",
    defaultMessage: "No worries, we’ll send you new activation link.",
  },
  resendLinkBackToLogIn: {
    id: "resendLinkBackToLogIn",
    defaultMessage: "Back to Log In",
  },
  resendLinkFormEmailAddress: {
    id: "resendLinkFormEmailAddress",
    defaultMessage: "Email Address",
  },
  resendLinkFormEmailAddressPlaceholder: {
    id: "resendLinkFormEmailAddressPlaceholder",
    defaultMessage: "Enter your email address",
  },
  resendLinkFormResendLink: {
    id: "resendLinkFormResendLink",
    defaultMessage: "Resend Activation Link",
  },
  resendLinkFormEmailFormat: {
    id: "resendLinkFormEmailFormat",
    defaultMessage: "email format (username@domain)",
  },
  resendLinkInvalidEmailError: {
    id: "resendLinkInvalidEmailError",
    defaultMessage: "Please enter a valid email address. (username@domain)",
  },
});

const ResendActivationLink: React.FC = () => {
  const intl = useIntl();

  const { config } = React.useContext(ConfigContext);
  const activationController = useSendActivation();

  const [email, setEmail] = React.useState("");
  const [isInvalidEmail, setIsInvalidEmail] = React.useState(false);

  const { currentLanguage } = React.useContext(IntlContext);

  const usePlusSignInEmailAddress =
    config.test.usePlusSignInEmailAddress.enabled;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    setIsInvalidEmail(false);
    if (validateEmail(email, usePlusSignInEmailAddress)) {
      activationController.handleActivation(email, currentLanguage);
    } else {
      setIsInvalidEmail(true);
    }
  };

  const getErrorMessage = () => {
    if (isInvalidEmail) {
      return intl.formatMessage(messages.resendLinkInvalidEmailError);
    }
  };

  const onChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setEmail(e.target.value);

  return (
    <article className="resend-link__container">
      <Progress className="resend-link-progress" value={50} />
      <Notification
        role="alert"
        type="error"
        className={classNames("resend-error", {
          "resend-error--visible": isInvalidEmail,
        })}
      >
        <div>{getErrorMessage()}</div>
      </Notification>
      <section className="title__container">
        <h1>{intl.formatMessage(messages.resendActivationLinkTitle)}</h1>
        <span>{intl.formatMessage(messages.resendLinkInstructions)}</span>
      </section>
      <section className="form__container">
        <Form
          noValidate
          onSubmit={handleSubmit}
          data-tooltip-id="resend-activation-link-tooltip"
        >
          <FormField
            className="email-field"
            label={intl.formatMessage(messages.resendLinkFormEmailAddress)}
            required
            htmlFor="email"
          >
            <Tooltip
              id="resend-activation-link-tooltip"
              content={intl.formatMessage(messages.resendLinkFormEmailFormat)}
            >
              <HeapPHIDecorator protectAttr={["value"]} noWrap>
                <Textfield
                  type="email"
                  id="email"
                  name="email"
                  required
                  outline
                  value={email}
                  onChange={onChangeEmail}
                  placeholder={intl.formatMessage(
                    messages.resendLinkFormEmailAddressPlaceholder
                  )}
                  autoComplete="email"
                />
              </HeapPHIDecorator>
            </Tooltip>
          </FormField>
          <Button
            type="submit"
            raised
            loading={activationController.isLoading}
            disabled={activationController.resendIsDisabled}
          >
            {intl.formatMessage(messages.resendLinkFormResendLink)}
          </Button>
          <Link to={routes.signIn}>
            <Button outlined={true}>
              {intl.formatMessage(messages.resendLinkBackToLogIn)}
            </Button>
          </Link>
        </Form>
      </section>
    </article>
  );
};

export default ResendActivationLink;
