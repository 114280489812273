import React, { FC } from "react";
import { Dialog } from "@natera/material/lib/dialog";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/platform/lib/components/form";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import { BusinessUnits } from "@app/provider/drawRequest";
import { contactPhoneMapper } from "@app/utils/contactPhoneMapper";

import "./apologiesModal.scss";
import { TestType } from "@app/provider/testData/types";

const messages = defineMessages({
  apologiesModalMessageTitle: {
    id: "apologiesModalMessageTitle",
    defaultMessage: "We're sorry.",
  },
  apologiesModalMessageModify: {
    id: "apologiesModalMessageModify",
    defaultMessage:
      "We are unable to modify your appointment request at this time.",
  },
  apologiesModalMessageCreate: {
    id: "apologiesModalMessageCreate",
    defaultMessage:
      "We are unable to submit your appointment request at this time.",
  },
  apologiesModalMessageTextContact: {
    id: "apologiesModalMessageTextContact",
    defaultMessage: "Please call",
  },
  apologiesModalMessageBackToHome: {
    id: "apologiesModalMessageBackToHome",
    defaultMessage: "Back To Home",
  },
  apologiesModalMessageDone: {
    id: "apologiesModalMessageDone",
    defaultMessage: "Done",
  },
  apologiesModalMessageBack: {
    id: "apologiesModalMessageBack",
    defaultMessage: "Back",
  },
});

interface ApologiesModalLocationState {
  prevPage: string;
}

type Props = {
  isModify: boolean;
  onClose: () => void;
  isGuest?: boolean;
  businessUnit?: BusinessUnits;
};

const ApologiesModal: FC<Props> = ({
  isModify,
  onClose,
  isGuest,
  businessUnit = BusinessUnits.ORGAN_HEALTH,
}) => {
  const intl = useIntl();

  const history = useHistory();
  const state = history.location.state as
    | ApologiesModalLocationState
    | undefined;
  const isBackToHome = !isModify || (isModify && !state?.prevPage);

  const handleClick = () => {
    if (isGuest) {
      onClose();
    } else {
      history.length > 1 ? history.goBack() : history.push(routes.home);
    }
  };

  return (
    <Dialog
      className="mobile-phlebotomy-modal"
      onClose={handleClick}
      title={intl.formatMessage(messages.apologiesModalMessageTitle)}
      showCrossButton
      actions={
        <Button raised onClick={handleClick}>
          {isGuest
            ? intl.formatMessage(messages.apologiesModalMessageDone)
            : isBackToHome
            ? intl.formatMessage(messages.apologiesModalMessageBackToHome)
            : intl.formatMessage(messages.apologiesModalMessageBack)}
        </Button>
      }
    >
      <div className="mobile-phlebotomy-modal__container">
        <p>
          {isModify
            ? intl.formatMessage(messages.apologiesModalMessageModify)
            : intl.formatMessage(messages.apologiesModalMessageCreate)}
        </p>
        <p>
          <span>
            {intl.formatMessage(messages.apologiesModalMessageTextContact)}
          </span>
          <br />
          <span>{contactPhoneMapper(businessUnit)}</span>
        </p>
      </div>
    </Dialog>
  );
};

export default ApologiesModal;
