import React, { FC, createContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { ManagedBy, SampleType, TestType } from "@app/provider/testData/types";
import OrderService from "@app/service/order";
import { Clinic } from "@app/provider/types";

export interface PartnerInfoData {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dateOfBirth?: string;
  email?: string;
  phone?: string;
}

export enum MicrodelPanel {
  FULL_PANEL = "FULL_PANEL",
  DIGEORGE_ONLY = "DIGEORGE_ONLY",
  FAMILIAL_VARIANT = "FAMILIAL_VARIANT",
  NONE = "NONE",
}
interface Panel {
  name: string;
  microdelPanel: MicrodelPanel | null;
}

interface ProductConfiguration {
  sampleCollectionManagedBy?: ManagedBy;
  caPnsSupplemental?: boolean;
}

interface Product {
  configuration?: ProductConfiguration;
}

export interface OrderItem {
  uid: string;
  testType: TestType;
  panels: Panel[];
  product?: Product;
}

export enum SimpleOrderWorkflowState {
  DRAFT = "DRAFT",
  CLINIC_COMPLETED = "CLINIC_COMPLETED",
  COMMUNICATION_SENT = "COMMUNICATION_SENT",
  PATIENT_ACCESSED = "PATIENT_ACCESSED",
  PATIENT_SIGNED = "PATIENT_SIGNED",
  KIT_ORDERED = "KIT_ORDERED",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
}

export enum OrderWorkflowType {
  SIMPLE_ORDER = "SIMPLE_ORDER",
  SAMPLE_COLLECTION = "SAMPLE_COLLECTION",
}

export interface Workflow {
  uid: string;
  type: OrderWorkflowType;
  state?: SimpleOrderWorkflowState;
  sampleType?: SampleType;
}

export interface Order {
  uid: string;
  items: OrderItem[];
  clinic: Clinic;
  workflows: Workflow[];
}

interface OrderProviderProps {
  orderUid: string;
}

export interface OrderController {
  orderIsLoading: boolean;
  orderData?: Order;
  getOrder: () => Promise<Order | undefined>;
}

export const OrderContext = createContext<OrderController>({
  orderIsLoading: false,
  orderData: undefined,
  getOrder: () => Promise.reject(),
});

const OrderProvider: FC<OrderProviderProps> = ({ orderUid, children }) => {
  const [orderData, setOrderData] = React.useState<Order | undefined>();

  const [getOrderQuery, { loading: orderIsLoading }] = useLazyQuery<{
    getOrder: Order;
  }>(OrderService.getOrder(), {
    fetchPolicy: "no-cache",
  });

  const getOrder = async () => {
    const result = await getOrderQuery({
      variables: { orderUid },
    });
    setOrderData(result.data?.getOrder);
    return result.data?.getOrder;
  };

  return (
    <OrderContext.Provider
      value={{
        orderIsLoading,
        orderData,
        getOrder,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;
