import React, { FC, useContext, useMemo } from "react";
import { TestType } from "@app/provider/testData/types";
import { defineMessages, useIntl } from "react-intl";
import { IntlContext } from "@app/provider";

const messages = defineMessages({
  testRenasightDescription: {
    id: "testRenasightDescription",
    defaultMessage: "Kidney Gene Panel",
  },
  testProsperaDescription: {
    id: "testProsperaDescription",
    defaultMessage: "Transplant Assessment",
  },
  testHorizonDescription: {
    id: "testHorizonDescription",
    defaultMessage: "Carrier Screening",
  },
  testPanoramaDescription: {
    id: "testPanoramaDescription",
    defaultMessage: "Non-Invasive Prenatal Testing-NIPT",
  },
  testEmpowerDescription: {
    id: "testEmpowerDescription",
    defaultMessage: "Hereditary Cancer Test",
  },
  testVistaraDescription: {
    id: "testVistaraDescription",
    defaultMessage: "Single-Gene NIPT",
  },
  testAlteraDescription: {
    id: "testAlteraDescription",
    defaultMessage: "Tumor Genomic Profile Test",
  },
  testSignateraDescription: {
    id: "testSignateraDescription",
    defaultMessage: "Molecular Residual Disease Test",
  },
});

interface Props {
  testType: TestType;
}

const TestDescription: FC<Props> = ({ testType }) => {
  const intl = useIntl();
  const { currentLanguage } = useContext(IntlContext);

  const descriptions: { [key: string]: string } = useMemo(
    () => ({
      [TestType.RENASIGHT]: intl.formatMessage(
        messages.testRenasightDescription
      ),
      [TestType.PROSPERA]: intl.formatMessage(messages.testProsperaDescription),
      [TestType.HORIZON]: intl.formatMessage(messages.testHorizonDescription),
      [TestType.PANORAMA]: intl.formatMessage(messages.testPanoramaDescription),
      [TestType.EMPOWER]: intl.formatMessage(messages.testEmpowerDescription),
      [TestType.VISTARA]: intl.formatMessage(messages.testVistaraDescription),
      [TestType.ALTERA]: intl.formatMessage(messages.testAlteraDescription),
      [TestType.SIGNATERA]: intl.formatMessage(
        messages.testSignateraDescription
      ),
    }),
    [currentLanguage]
  );

  return <>{descriptions[testType] || ""}</>;
};

export default TestDescription;
