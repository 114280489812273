import React, {
  FC,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import classNames from "classnames";
import { iframeDefaultConfig, PostMessageEventTypes } from "@app/neva/models";
import { IntlContext } from "@app/provider";
import { APIContext, DialogsContext, EventsContext } from "@app/neva/provider";
import { generatePostbackUserEventWithType } from "@app/neva/helpers";
import "./iframe.scss";
import { useLogEvent } from "@app/neva/hooks";
import SpinnerLoader from "@app/neva/components/spinnerLoader/spinnerLoader";

const messages = defineMessages({
  iframeTitle: {
    id: "iframeTitle",
    defaultMessage: "External page window",
  },
});

interface Props {
  chatContainerRef: RefObject<HTMLElement>;
}

const Iframe: FC<Props> = ({ chatContainerRef }) => {
  const intl = useIntl();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const {
    iframeSettings: { url, isLoading },
    setIframeSettings,
  } = useContext(DialogsContext);
  const {
    apiProviderState: { chatId },
  } = useContext(APIContext);
  const { userEventHandler } = useContext(EventsContext);
  const { currentLanguage } = useContext(IntlContext);
  const isQuestionnaire = useMemo(() => url.includes("questionnaire"), [url]);
  const { logEvent } = useLogEvent();

  useEffect(() => {
    window.addEventListener("message", messageEventListener);
    window.addEventListener("blur", windowBlurListener);

    return () => {
      window.removeEventListener("message", messageEventListener);
      window.removeEventListener("blur", windowBlurListener);
      setIframeSettings(iframeDefaultConfig);
    };
  }, []);

  const messageEventListener = (event: MessageEvent) => {
    const {
      source,
      origin,
      data: {
        type,
        isBackButtonPresent,
        isQuestionnaireIframeOpened,
        endPayload,
        activityEvent,
      },
    } = event;

    switch (type) {
      case PostMessageEventTypes.QUESTIONNAIRE_CHAT_ID:
        source && initializeQuestionnaire(source, type, origin);
        break;
      case PostMessageEventTypes.OPPORTUNITY_TO_RETURN:
        setIframeSettings((prevState) => ({
          ...prevState,
          opportunityToManualClose: isBackButtonPresent,
        }));
        break;
      case PostMessageEventTypes.QUESTIONNAIRE_CONTENT_LOADED:
        setIframeSettings((prevState) => ({
          ...prevState,
          isLoading: false,
          isShowIframe: true,
        }));
        break;
      case PostMessageEventTypes.QUESTIONNAIRE_IFRAME_STATE:
        setIframeSettings((prevState) => ({
          ...prevState,
          isQuestionnaireIframeOpened,
        }));
        break;
      case PostMessageEventTypes.QUESTIONNAIRE_CLOSE_IFRAME_SUCCESSFULLY:
        setIframeSettings(iframeDefaultConfig);
        endPayload &&
          userEventHandler(
            generatePostbackUserEventWithType(
              { title: "", payload: endPayload },
              "QUESTIONNAIRE_POSTBACK"
            )
          );
        break;
      case PostMessageEventTypes.QUESTIONNAIRE_ACTIVITY_EVENT:
        logEvent({
          eventAction: activityEvent,
          eventLabel: "User Action",
        });
        break;
    }
  };

  const initializeQuestionnaire = (
    source: MessageEventSource,
    type: string,
    origin: string
  ) => {
    setIframeSettings((prevState) => ({
      ...prevState,
      widgetIframeSource: source,
    }));

    source.postMessage(
      {
        type,
        chatId,
        language: currentLanguage,
      },
      origin as WindowPostMessageOptions
    );
  };

  const onLoadIframeData = () => {
    if (!isQuestionnaire) {
      setIframeSettings((prevState) => ({
        ...prevState,
        isLoading: false,
        isShowIframe: true,
      }));
    }
  };

  const windowBlurListener = () => {
    chatContainerRef?.current?.click();
  };

  return (
    <div className="widget-iframe__container">
      <iframe
        data-testid="iframe"
        ref={iframeRef}
        className={classNames("widget-iframe__iframe", {
          "show-iframe": !isLoading,
        })}
        title={intl.formatMessage(messages.iframeTitle)}
        src={url}
        name={`${url}_iframe`}
        onLoad={onLoadIframeData}
      />
      <div
        className={classNames("widget-iframe__loader-container", {
          "hide-loader": !isLoading,
        })}
      >
        <SpinnerLoader />
      </div>
    </div>
  );
};

export default Iframe;
