import React, { FC, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import { ProfileContext } from "@app/provider/profile";
import {
  BaseModalDialog,
  InfoCard,
  SpinnerView,
  UpdatePhoneForm,
  VerificationPhoneModal,
} from "@app/components";
import { BlueHeaderContent } from "@app/components/ui/layout";
import { useDialog } from "@natera/platform/lib/hooks";
import { Button } from "@natera/platform/lib/components/form";
import { MfaErrorProvider, MfaSetupContext } from "@app/provider";
import { EnrollFactorResponse, Factor } from "@app/service/user";
import VerifyModal from "@app/components/verifyModal";

const messages = defineMessages({
  updatePhoneBackButton: {
    id: "updatePhoneBackButton",
    defaultMessage: "Contact Details",
  },
  updatePhoneTitle: {
    id: "updatePhoneTitle",
    defaultMessage: "Mobile Phone",
  },
  updatePhoneDialogTitle: {
    id: "updatePhoneDialogTitle",
    defaultMessage:
      "Changing your mobile number will require you to verify your new mobile number",
  },
  updatePhoneDialogContent: {
    id: "updatePhoneDialogContent",
    defaultMessage: "Are you sure you want to proceed?",
  },
  updatePhoneDialogSubmit: {
    id: "updatePhoneDialogSubmit",
    defaultMessage: "Yes, Continue With Update",
  },
  updatePhoneDialogCancelButton: {
    id: "updatePhoneDialogCancelButton",
    defaultMessage: "Cancel",
  },
});

const UpdatePhone: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const [factor, setFactor] = useState<Factor | EnrollFactorResponse>();
  const [showVerificationCodeModal, setShowVerificationCodeModal] = useState<
    boolean
  >(false);

  const {
    profileData: patientProfileData,
    profileDataIsLoading,
    updatePhone,
    updatePatientIsLoading,
  } = useContext(ProfileContext);

  const {
    isFactorActivated,
    isUpdatePhoneAction,
    setIsUpdatePhoneAction,
  } = useContext(MfaSetupContext);

  const { getActivePhoneFactors } = useContext(MfaSetupContext);

  const [phoneNumber, setPhoneNumber] = useState("");

  const updatePhoneDialog = useDialog(BaseModalDialog);

  useEffect(() => {
    const isPhoneUpdated =
      phoneNumber && isUpdatePhoneAction && isFactorActivated;
    if (isPhoneUpdated) {
      (async () => {
        await updatePhone(phoneNumber);
        setIsUpdatePhoneAction(false);
      })();
    }
  }, [isUpdatePhoneAction, isFactorActivated, setIsUpdatePhoneAction]);

  useEffect(() => {
    if (phoneNumber) {
      updatePhoneDialog.open(updatePhoneDialogProps());
    }
  }, [phoneNumber]);

  const updatePhoneDialogProps = () => {
    return {
      header: intl.formatMessage(messages.updatePhoneDialogTitle),
      content: <p>{intl.formatMessage(messages.updatePhoneDialogContent)}</p>,
      actions: (
        <>
          <Button onClick={handleDialogCancel} outlined>
            {intl.formatMessage(messages.updatePhoneDialogCancelButton)}
          </Button>
          <Button raised onClick={handleDialogSubmit}>
            {intl.formatMessage(messages.updatePhoneDialogSubmit)}
          </Button>
        </>
      ),
      handleClose: handleDialogCancel,
    };
  };

  const handleDialogSubmit = async () => {
    const activeFactors = getActivePhoneFactors();
    if (activeFactors.length) {
      const activeFactor = { ...activeFactors[0], profile: { phoneNumber } };
      setFactor(activeFactor);
    } else {
      setShowVerificationCodeModal(true);
    }
    updatePhoneDialog.close();
  };

  const handleDialogCancel = () => {
    setPhoneNumber("");
    updatePhoneDialog.close();
  };

  const handleSubmit = async (phone: string) => {
    if (phone === patientProfileData?.phone?.slice(1)) {
      history.replace(routes.contactDetails);
    }
    setPhoneNumber(phone);
  };

  const handleCancel = () => history.push(routes.contactDetails);

  const renderView = () => {
    if (factor) {
      return <VerifyModal factor={factor} setFactor={setFactor} update />;
    } else if (showVerificationCodeModal) {
      return <VerificationPhoneModal phoneNumber={phoneNumber} />;
    } else {
      return (
        <BlueHeaderContent
          blueHeaderTitle={intl.formatMessage(messages.updatePhoneBackButton)}
          backArrowHeaderRoute={routes.contactDetails}
        >
          <InfoCard titleText={intl.formatMessage(messages.updatePhoneTitle)}>
            <UpdatePhoneForm
              currentPhone={patientProfileData?.phone}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
            />
          </InfoCard>
          <SpinnerView
            isLoading={profileDataIsLoading || updatePatientIsLoading}
          />
          {updatePhoneDialog.getDialog()}
        </BlueHeaderContent>
      );
    }
  };

  return <MfaErrorProvider>{renderView()}</MfaErrorProvider>;
};

export default UpdatePhone;
