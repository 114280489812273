import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { isConnectPortal } from "@app/utils";
import { SupplementalButton } from "@app/components/supplementButton";
import { SupplementalType } from "@app/components/supplementButton/supplementalButton";
import "./testResultDetails.scss";
import { TestResult } from "@app/provider/testResult";

const messages = defineMessages({
  testResultSupplementalMaterials: {
    id: "testResultSupplementalMaterials",
    defaultMessage: "Additional Information",
  },
});

interface Props {
  testResult: TestResult;
}

const SupplementalMaterialsForWH: FC<Props> = ({ testResult }) => {
  const intl = useIntl();

  const supplementalPdf = testResult.documents?.filter(
    (doc) => doc.contentType !== SupplementalType.VIDEO
  );
  const supplementalVideo = testResult.documents?.filter(
    (doc) => doc.contentType === SupplementalType.VIDEO
  );

  const displaySupplementalMaterialsForWH = Boolean(
    isConnectPortal(testResult.testType) &&
      (supplementalVideo?.length || supplementalPdf?.length)
  );

  return displaySupplementalMaterialsForWH ? (
    <>
      <h3>{intl.formatMessage(messages.testResultSupplementalMaterials)}</h3>

      <div className="block__supplemental">
        {supplementalPdf?.map((doc, index) => (
          <SupplementalButton
            key={`supplemental_button_pdf_${doc.uid}`}
            type={doc.contentType}
            uid={doc.uid}
            index={index}
            single={supplementalPdf && supplementalPdf.length === 1}
          />
        ))}
        {supplementalVideo?.map((doc, index) => (
          <SupplementalButton
            key={`supplemental_button_video_${doc.uid}`}
            type={doc.contentType}
            uid={doc.uid}
            index={index}
            title={doc.documentName}
          />
        ))}
      </div>
    </>
  ) : (
    <></>
  );
};

export default SupplementalMaterialsForWH;
