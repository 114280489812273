import React, { FC, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import { ProfileContext } from "@app/provider";
import {
  CommonErrorNotification,
  InfoCard,
  SpinnerView,
  UpdatePersonalInformationForm,
} from "@app/components";
import { BlueHeaderContent } from "@app/components/ui/layout";
import { PersonalInformation } from "@app/provider/profile";
import "./updatePersonalInformation.scss";

const messages = defineMessages({
  updatePersonalInformationBackButton: {
    id: "updatePersonalInformationBackButton",
    defaultMessage: "Personal Information",
  },
});

const UpdatePersonalInformation: FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const {
    profileData,
    profileDataIsLoading,
    updatePersonalInformation,
    updatePatientIsLoading,
    updatePatientError,
  } = useContext(ProfileContext);

  const [personalInformation, setPersonalInformation] = useState<
    PersonalInformation | undefined
  >();
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    if (profileData) {
      const {
        firstName,
        middleName,
        lastName,
        alternativeLastName,
      } = profileData;

      setPersonalInformation({
        firstName: firstName || "",
        middleName,
        lastName: lastName || "",
        alternativeLastName,
      });
    }
  }, [profileData]);

  const handleSubmit = async (personalInformation: PersonalInformation) => {
    setSubmited(true);
    await updatePersonalInformation(personalInformation);
    history.goBack();
  };

  const handleCancel = () => history.goBack();

  const notification =
    submited && updatePatientError ? <CommonErrorNotification /> : null;

  return (
    <BlueHeaderContent
      blueHeaderTitle={intl.formatMessage(
        messages.updatePersonalInformationBackButton
      )}
      backArrowHeaderRoute={routes.personalInformation}
    >
      {personalInformation && (
        <InfoCard notification={notification}>
          <UpdatePersonalInformationForm
            personalInformation={personalInformation}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
          />
        </InfoCard>
      )}
      <SpinnerView isLoading={profileDataIsLoading || updatePatientIsLoading} />
    </BlueHeaderContent>
  );
};

export default UpdatePersonalInformation;
