import { DocumentNode, gql } from "@apollo/client";

export interface MarkOrderAsAccessedByPatientResponse {
  success: boolean;
}

export default class SimpleOrderService {
  public static getSimpleOrder = (): DocumentNode => gql`
    query GetSimpleOrder($orderUid: String!) {
      getSimpleOrder(orderUid: $orderUid) {
        completionStage
        watchedVideoPercentage
        patient {
          firstName
          lastName
          dateOfBirth
          email
          phone
        }
        kitShipping {
          shipper
          shippingAddress {
            state
            city
            street
            zipCode
          }
        }
        partnerInfo {
          partner {
            firstName
            lastName
            dateOfBirth
            email
            phone
          }
          sharingConsentAgreement
        }
        reportGender
        caPnsSupplemental
        billingInfo {
          paymentMethod
          primaryInsurance {
            insuranceCompany
            healthPlan
            medicare
            memberId
            groupId
            state
          }
          secondaryInsurance {
            insuranceCompany
            healthPlan
            medicare
            memberId
            groupId
            state
          }
        }
        sampleManagedBy
      }
    }
  `;

  public static updateSimpleOrder = (): DocumentNode => gql`
    mutation UpdateSimpleOrder(
      $orderUid: String!
      $simpleOrderInfo: SimpleOrderUpdateDTO!
    ) {
      updateSimpleOrder(
        orderUid: $orderUid
        simpleOrderInfo: $simpleOrderInfo
      ) {
        completionStage
        watchedVideoPercentage
        patient {
          firstName
          lastName
          dateOfBirth
          email
          phone
        }
        kitShipping {
          shipper
          shippingAddress {
            state
            city
            street
            zipCode
          }
        }
        partnerInfo {
          partner {
            firstName
            lastName
            dateOfBirth
            email
            phone
          }
          sharingConsentAgreement
        }
        reportGender
        caPnsSupplemental
        billingInfo {
          paymentMethod
          primaryInsurance {
            insuranceCompany
            healthPlan
            medicare
            memberId
            groupId
            state
          }
          secondaryInsurance {
            insuranceCompany
            healthPlan
            medicare
            memberId
            groupId
            state
          }
        }
        sampleManagedBy
      }
    }
  `;

  public static completeSimpleOrder = (): DocumentNode => gql`
    mutation CompleteSimpleOrder(
      $orderUid: String!
      $workflowUid: String!
      $simpleOrderInfo: SimpleOrderCompleteDTO!
      $testName: String!
      $limsClinicId: String!
    ) {
      completeSimpleOrder(
        orderUid: $orderUid
        workflowUid: $workflowUid
        simpleOrderInfo: $simpleOrderInfo
        testName: $testName
        limsClinicId: $limsClinicId
      ) {
        uid
      }
    }
  `;

  public static cancelSimpleOrder = (): DocumentNode => gql`
    mutation CancelSimpleOrder(
      $orderUid: String!
      $testName: String!
      $limsClinicId: String!
    ) {
      cancelSimpleOrder(
        orderUid: $orderUid
        testName: $testName
        limsClinicId: $limsClinicId
      ) {
        success
      }
    }
  `;

  public static markOrderAsAccessedByPatient = (): DocumentNode => gql`
    mutation MarkOrderAsAccessedByPatient(
      $orderUid: String!
      $workflowUid: String!
      $testName: String!
      $limsClinicId: String!
    ) {
      markOrderAsAccessedByPatient(
        orderUid: $orderUid
        workflowUid: $workflowUid
        testName: $testName
        limsClinicId: $limsClinicId
      ) {
        success
      }
    }
  `;
}
