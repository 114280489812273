import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { GridCell, GridRow } from "@natera/material/lib/layout";
import { Form, Button } from "@natera/platform/lib/components/form";
import {
  Textfield,
  FormField,
} from "@natera/platform/lib/components/form/field";
import { useErrorController } from "@natera/platform/lib/hooks";
import { ValidationError } from "@natera/platform/lib/service/http";
import * as R from "ramda";
import "./signatureForm.scss";
import { OrderContext, Profile } from "@app/provider";
import { HEAP_EVENTS } from "@app/provider/types";
import { getTestNameForHeap } from "@app/utils";

const messages = defineMessages({
  signatureFormButtonNext: {
    id: "signatureFormButtonNext",
    defaultMessage: "Submit signature",
  },
  signatureFormButtonBack: {
    id: "signatureFormButtonBack",
    defaultMessage: "Back",
  },
  signatureFormFirstName: {
    id: "signatureFormFirstName",
    defaultMessage: "First Name",
  },
  signatureFormLastName: {
    id: "signatureFormLastName",
    defaultMessage: "Last Name",
  },
  validationErrorFirstName: {
    id: "validationErrorFirstName",
    defaultMessage: "The first name must match the name on file",
  },
  validationErrorLastName: {
    id: "validationErrorLastName",
    defaultMessage: "The last name must match the name on file",
  },
});

const firstName = "firstName";
const lastName = "lastName";

export type SignatureFormType = {
  firstName?: string;
  lastName?: string;
};

interface SignatureFormProps {
  profile?: Profile;
  initValues?: SignatureFormType;
  onSubmit: (formData: SignatureFormType) => void;
  loading?: boolean;
  goBack: () => void;
  isPatientSignature?: boolean;
}

const SignatureForm: FC<SignatureFormProps> = ({
  initValues,
  onSubmit,
  loading,
  goBack,
  profile,
  isPatientSignature,
}) => {
  const intl = useIntl();
  const {
    clearErrors,
    getValidationError,
    setValidationErrors,
  } = useErrorController();

  const { orderData } = React.useContext(OrderContext);
  const orderUid = orderData?.uid;
  const limsId = orderData?.clinic.limsId;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    clearErrors();
    const validationErrors: ValidationError[] = [];

    const formData = new FormData(event.currentTarget);

    const firstNameValue = formData.get(firstName)?.toString();
    const lastNameValue = formData.get(lastName)?.toString();

    if (profile && profile.firstName !== firstNameValue) {
      validationErrors.push({
        name: firstName,
        message: intl.formatMessage(messages.validationErrorFirstName),
      });
    }

    if (profile && profile.lastName !== lastNameValue) {
      validationErrors.push({
        name: lastName,
        message: intl.formatMessage(messages.validationErrorLastName),
      });
    }

    if (!R.isEmpty(validationErrors)) {
      setValidationErrors(validationErrors);
      return;
    }

    const signatureInfo = {
      firstName: firstNameValue,
      lastName: lastNameValue,
    };
    onSubmit(signatureInfo);
    !isPatientSignature &&
      heap.track(HEAP_EVENTS.upp_simpleorder_partnerconsentyes, {
        tests_ordered: getTestNameForHeap(orderData),
        lims_clinic_id: limsId,
        order_uid: orderUid,
      });
  };

  return (
    <Form
      noValidate
      className="signature-form"
      data-testid="signatureForm"
      onSubmit={handleSubmit}
      autoComplete="off"
      buttons={
        <>
          <Button type="submit" raised loading={loading}>
            {intl.formatMessage(messages.signatureFormButtonNext)}
          </Button>
          <Button onClick={goBack} disabled={loading}>
            {intl.formatMessage(messages.signatureFormButtonBack)}
          </Button>
        </>
      }
    >
      <GridRow>
        <GridCell span={12}>
          <FormField
            title={intl.formatMessage(messages.signatureFormFirstName)}
            label={intl.formatMessage(messages.signatureFormFirstName)}
            aria-label={intl.formatMessage(messages.signatureFormFirstName)}
            error={getValidationError(firstName)}
            required
            htmlFor={firstName}
            disabled={loading}
          >
            <Textfield
              id={firstName}
              name={firstName}
              type="text"
              placeholder={profile?.firstName}
              defaultValue={initValues?.firstName}
              outline
              required
              autoComplete="given-name"
            />
          </FormField>
        </GridCell>
        <GridCell span={12}>
          <FormField
            title={intl.formatMessage(messages.signatureFormLastName)}
            label={intl.formatMessage(messages.signatureFormLastName)}
            error={getValidationError(lastName)}
            aria-label={intl.formatMessage(messages.signatureFormLastName)}
            required
            htmlFor={lastName}
            disabled={loading}
          >
            <Textfield
              id={lastName}
              name={lastName}
              placeholder={profile?.lastName}
              defaultValue={initValues?.lastName}
              outline
              required
              autoComplete="family-name"
            />
          </FormField>
        </GridCell>
      </GridRow>
    </Form>
  );
};

export default SignatureForm;
