import React, { FC } from "react";
import { Dialog } from "@natera/material/lib/dialog";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/platform/lib/components/form";
import "./cancelSimpleOrderTestDialogFail.scss";

const messages = defineMessages({
  cancelSimpleOrderTestDialogFailTitle: {
    id: "cancelSimpleOrderTestDialogFailTitle",
    defaultMessage: "We are currently experiencing a service disruption.",
  },
  cancelSimpleOrderTestDialogFailText1: {
    id: "cancelSimpleOrderTestDialogFailText1",
    defaultMessage: "We apologize for the inconvenience.",
  },
  cancelSimpleOrderTestDialogFailText2: {
    id: "cancelSimpleOrderTestDialogFailText2",
    defaultMessage: "Please try again later.",
  },
  cancelSimpleOrderTestDialogFailOKButton: {
    id: "cancelSimpleOrderTestDialogFailOKButton",
    defaultMessage: "Ok",
  },
});

interface Props {
  onClose: () => void;
}

const CancelSimpleOrderTestDialogFail: FC<Props> = ({ onClose }) => {
  const intl = useIntl();

  const header = intl.formatMessage(
    messages.cancelSimpleOrderTestDialogFailTitle
  );

  const content = (
    <>
      <span>
        {intl.formatMessage(messages.cancelSimpleOrderTestDialogFailText1)}
      </span>
      <br />
      <span>
        {intl.formatMessage(messages.cancelSimpleOrderTestDialogFailText2)}
      </span>
    </>
  );

  const actions = (
    <Button onClick={onClose} raised>
      {intl.formatMessage(messages.cancelSimpleOrderTestDialogFailOKButton)}
    </Button>
  );

  return (
    <Dialog
      className="cancel-simple-order-test-dialog-fail"
      onClose={onClose}
      title={header}
      actions={actions}
    >
      <div className="cancel-simple-order-test-dialog-fail__content">
        {content}
      </div>
    </Dialog>
  );
};

export default CancelSimpleOrderTestDialogFail;
