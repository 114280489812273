import React, { FC } from "react";
import { Notification } from "@app/components";
import { useIntl, defineMessages } from "react-intl";

export const messages = defineMessages({
  poBoxValidationErrorTitle: {
    id: "poBoxValidationErrorTitle",
    defaultMessage: "PO Boxes are not permitted.",
  },
  poBoxValidationErrorKitContent: {
    id: "poBoxValidationErrorKitContent",
    defaultMessage:
      "We cannot ship a kit to a PO box. Please select or enter a physical address.",
  },
  poBoxValidationErrorBloodDrawContent: {
    id: "poBoxValidationErrorBloodDrawContent",
    defaultMessage:
      "Please select or enter a physical address at which your blood can be drawn.",
  },
});

type POBoxErrorNotificationProps = {
  formType: AddressFormType;
};

export enum AddressFormType {
  KIT,
  MOBILE_BLOOD_DRAW,
}

export const POBoxErrorNotification: FC<POBoxErrorNotificationProps> = ({
  formType,
}) => {
  const intl = useIntl();

  const errorMessage =
    formType === AddressFormType.KIT
      ? messages.poBoxValidationErrorKitContent
      : messages.poBoxValidationErrorBloodDrawContent;

  return (
    <section className="title__container">
      <Notification
        titleElem={intl.formatMessage(messages.poBoxValidationErrorTitle)}
        type="error"
      >
        <div>{intl.formatMessage(errorMessage)}</div>
      </Notification>
    </section>
  );
};
