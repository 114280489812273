import React from "react";
import { Link } from "@natera/platform/lib/components";
import { defineMessages, useIntl } from "react-intl";
import Svg from "@natera/material/lib/svg";
import ArrowIcon from "@assets/svg/icons/arrow.svg";
import CloseIcon from "@assets/svg/icons/close.svg";
import "./blueHeader.scss";
import { IconButton } from "@natera/material/lib/button";

export const messages = defineMessages({
  blueHeaderBack: {
    id: "blueHeaderBack",
    defaultMessage: "Back",
  },
  blueHeaderClose: {
    id: "blueHeaderClose",
    defaultMessage: "Close",
  },
});

interface BackArrowProps {
  title: string;
  arrowRoute?: string;
  crossRoute?: string;
  crossButton?: () => void;
}

const BlueHeader: React.FC<BackArrowProps> = ({
  title,
  arrowRoute,
  crossRoute,
  crossButton,
}) => {
  const intl = useIntl();

  return (
    <div className="blue-header">
      {arrowRoute && (
        <div className="blue-header-title">
          <Link
            to={arrowRoute}
            role="button"
            aria-label={intl.formatMessage(messages.blueHeaderBack)}
          >
            <Svg content={ArrowIcon} />
          </Link>
          <span className="blue-header-title--text">{title}</span>
        </div>
      )}

      {crossRoute && (
        <div className="blue-header_cross-content">
          <span className="blue-header_cross-content__title">{title}</span>
          <Link
            to={crossRoute}
            role="button"
            aria-label={intl.formatMessage(messages.blueHeaderClose)}
          >
            <Svg content={CloseIcon} />
          </Link>
        </div>
      )}

      {crossButton && (
        <div className="blue-header_cross-content">
          <span className="blue-header_cross-content__title">{title}</span>

          <IconButton
            onClick={crossButton}
            aria-label={intl.formatMessage(messages.blueHeaderClose)}
          >
            {CloseIcon}
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default BlueHeader;
