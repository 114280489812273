import React, { FC } from "react";
import { Address } from "@app/provider/profile";
import { AddressForm } from "@app/components/forms";
import MobilePhlebotomyAddressFormActions from "./mobilePhlebotomyAddressFormActions";

import "./mobilePhlebotomyAddressForm.scss";

interface Props {
  handleSubmit: (address: Partial<Address>) => void;
  handleCancel: () => void;
  isKitShippingStep?: boolean;
}

const MobilePhlebotomyAddressForm: FC<Props> = ({
  handleSubmit,
  handleCancel,
  isKitShippingStep,
}) => {
  return (
    <AddressForm
      onSubmit={handleSubmit}
      actions={
        <MobilePhlebotomyAddressFormActions
          handleCancel={handleCancel}
          isKitShippingStep={isKitShippingStep}
        />
      }
    />
  );
};

export default MobilePhlebotomyAddressForm;
