import { StepperContext } from "@natera/stepper";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { InsuranceCostEstimationForm } from "@app/components/forms";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import {
  Commercial,
  Government,
  PaymentInfo,
  PaymentType,
  SubSteps,
  MultipleInsuranceData,
} from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";

import { PredefinedCompanyName } from "@app/components/companySelector/companySelector";
import {
  getDataFromMultipleFirstStep,
  getDataFromMultipleSecondStep,
} from "@app/components/simpleOrder/payment/steps/multipleInsurances/getDataFromMultipleSteps";

const messages = defineMessages({
  insuranceCostEstimationStepHeaderTitle: {
    id: "insuranceCostEstimationStepHeaderTitle",
    defaultMessage: "Payment Method",
  },
});

interface Props {
  onSubmit: (data: unknown) => void;
}

const InsuranceCostEstimationStep: React.FC<Props> = ({ onSubmit }) => {
  const intl = useIntl();
  const { goBack, getData, getSteps } = React.useContext(StepperContext);

  const subSteps = getSteps();
  const stepIds = subSteps.map((step) => step.stepId);

  const isCommercialFlow = stepIds.includes(SubSteps.SELECT_COMMERCIAL_COMPANY);
  const isGovernmentFlow = stepIds.includes(SubSteps.SELECT_GOVERNMENT_COMPANY);
  const isMultipleFlow =
    stepIds.includes(SubSteps.SELECT_COMMERCIAL_COMPANY_SECOND) ||
    stepIds.includes(SubSteps.SELECT_GOVERNMENT_COMPANY_SECOND);

  const getCommercialCompanyData = () => {
    const companyStep = getData(SubSteps.SELECT_COMMERCIAL_COMPANY);
    let companyName: string = companyStep?.companyName;
    let companyGroupId: string = companyStep?.companyGroupId;

    if (companyName === PredefinedCompanyName.Other) {
      const otherCompanyStepData = getData(
        SubSteps.ENTER_OTHER_COMMERCIAL_COMPANY
      );
      companyName = otherCompanyStepData?.otherCompanyName;
      companyGroupId = otherCompanyStepData?.otherCompanyGroupId;
    }

    const memberId = getData(SubSteps.PROVIDE_COMMERCIAL_INSURANCE).memberId;

    const companyData: Commercial = {
      paymentMethod: PaymentType.COMMERCIAL,
      paymentMethodInfo: {
        companyName,
        memberId,
        companyGroupId,
      },
    };

    return companyData;
  };

  const getGovernmentCompanyData = () => {
    const companyStep = getData(SubSteps.SELECT_GOVERNMENT_COMPANY);
    let companyName: string = companyStep?.companyName;
    let companyGroupId: string = companyStep?.companyGroupId;

    if (companyName === PredefinedCompanyName.Other) {
      const otherCompanyStepData = getData(
        SubSteps.ENTER_OTHER_GOVERNMENT_COMPANY
      );
      companyName = otherCompanyStepData?.otherCompanyName;
      companyGroupId = otherCompanyStepData?.otherCompanyGroupId;
    }

    const insuranceNumber = getData(SubSteps.PROVIDE_GOVERNMENT_INSURANCE)
      .insuranceNumber;

    const companyData: Government = {
      paymentMethod: PaymentType.GOVERNMENT_ISSUED,
      paymentMethodInfo: {
        companyName,
        insuranceNumber,
        companyGroupId,
      },
    };

    return companyData;
  };

  const getInsuranceCompaniesData = () => {
    const companiesData: PaymentInfo[] = [];

    if (isCommercialFlow) {
      const companyData = getCommercialCompanyData();
      companiesData.push(companyData);
    }

    if (isGovernmentFlow) {
      const companyData = getGovernmentCompanyData();
      companiesData.push(companyData);
    }

    if (isMultipleFlow) {
      const firstCompanyData = getDataFromMultipleFirstStep(subSteps);
      const secondCompanyData = getDataFromMultipleSecondStep(subSteps);
      companiesData.push(firstCompanyData);
      companiesData.push(secondCompanyData);
    }

    return companiesData;
  };

  const getPaymentMethodData = () => {
    const companiesData = getInsuranceCompaniesData();
    const isMultipleInsurance = companiesData.length > 1;

    const paymentMethodResult = isMultipleInsurance
      ? ({
          paymentMethod: PaymentType.MULTIPLE,
          paymentMethodInfo: {
            first: companiesData[0],
            second: companiesData[1],
          },
        } as MultipleInsuranceData)
      : companiesData[0];

    return paymentMethodResult;
  };

  const handleBack = () => {
    goBack();
  };

  return (
    <StepperContent
      title={intl.formatMessage(
        messages.insuranceCostEstimationStepHeaderTitle
      )}
    >
      <InsuranceCostEstimationForm
        paymentMethodData={getPaymentMethodData()}
        onSubmit={onSubmit}
        goBack={handleBack}
      />
    </StepperContent>
  );
};

export default InsuranceCostEstimationStep;
