import { MicrodelPanel, Order } from "@app/provider/order";
import { isComboOrder, isPanoramaOrder } from "@app/utils";
import { TestType } from "@app/provider/testData/types";

enum PRICE_ESTIMATIONS {
  PRICE_249_349 = "$ 249.00 - $ 349.00",
  PRICE_249 = "$ 249.00",
  PRICE_349 = "$ 349.00",
  PRICE_449 = "$ 449.00",
  PRICE_99 = "$ 99.00",
}

export enum PanelNames {
  CARRIER_SCREENING_3_COMM_DMD = "CARRIER_SCREENING_3_COMM_DMD",
  CARRIER_SCREENING_3_COMM_CF = "CARRIER_SCREENING_3_COMM_CF",
  CARRIER_SCREENING_3_COMM_TSE = "CARRIER_SCREENING_3_COMM_TSE",
  CARRIER_SCREENING_3_COMM_SMA = "CARRIER_SCREENING_3_COMM_SMA",
  CARRIER_SCREENING_3_COMM_HCUSTOM = "CARRIER_SCREENING_3_COMM_HCUSTOM",
  CARRIER_SCREENING_3_COMM_HBASIC = "CARRIER_SCREENING_3_COMM_HBASIC",
  CARRIER_SCREENING_3_COMM_H3 = "CARRIER_SCREENING_3_COMM_H3",
  CARRIER_SCREENING_3_COMM_H4 = "CARRIER_SCREENING_3_COMM_H4",
  CARRIER_SCREENING_3_COMM_H14 = "CARRIER_SCREENING_3_COMM_H14",
  CARRIER_SCREENING_3_COMM_H22 = "CARRIER_SCREENING_3_COMM_H22",
  CARRIER_SCREENING_3_COMM_H27 = "CARRIER_SCREENING_3_COMM_H27",
  CARRIER_SCREENING_3_COMM_H28 = "CARRIER_SCREENING_3_COMM_H28",
  CARRIER_SCREENING_3_COMM_H106 = "CARRIER_SCREENING_3_COMM_H106",
  CARRIER_SCREENING_3_COMM_H112 = "CARRIER_SCREENING_3_COMM_H112",
  CARRIER_SCREENING_3_COMM_H254 = "CARRIER_SCREENING_3_COMM_H254",
  CARRIER_SCREENING_3_COMM_H274 = "CARRIER_SCREENING_3_COMM_H274",
  CARRIER_SCREENING_3_COMM_H275 = "CARRIER_SCREENING_3_COMM_H275",
  CARRIER_SCREENING_3_COMM_H421 = "CARRIER_SCREENING_3_COMM_H421",
  CARRIER_SCREENING_3_COMM_H445 = "CARRIER_SCREENING_3_COMM_H445",
}

export const panelNameToGenesMapper = (panelName: PanelNames): number => {
  const mapper = {
    [PanelNames.CARRIER_SCREENING_3_COMM_DMD]: 1,
    [PanelNames.CARRIER_SCREENING_3_COMM_CF]: 1,
    [PanelNames.CARRIER_SCREENING_3_COMM_TSE]: 1,
    [PanelNames.CARRIER_SCREENING_3_COMM_SMA]: 1,
    [PanelNames.CARRIER_SCREENING_3_COMM_HBASIC]: 4,
    [PanelNames.CARRIER_SCREENING_3_COMM_HCUSTOM]: 0,
    [PanelNames.CARRIER_SCREENING_3_COMM_H3]: 3,
    [PanelNames.CARRIER_SCREENING_3_COMM_H4]: 4,
    [PanelNames.CARRIER_SCREENING_3_COMM_H14]: 14,
    [PanelNames.CARRIER_SCREENING_3_COMM_H22]: 22,
    [PanelNames.CARRIER_SCREENING_3_COMM_H27]: 27,
    [PanelNames.CARRIER_SCREENING_3_COMM_H28]: 28,
    [PanelNames.CARRIER_SCREENING_3_COMM_H106]: 106,
    [PanelNames.CARRIER_SCREENING_3_COMM_H112]: 112,
    [PanelNames.CARRIER_SCREENING_3_COMM_H254]: 254,
    [PanelNames.CARRIER_SCREENING_3_COMM_H274]: 274,
    [PanelNames.CARRIER_SCREENING_3_COMM_H275]: 275,
    [PanelNames.CARRIER_SCREENING_3_COMM_H421]: 421,
    [PanelNames.CARRIER_SCREENING_3_COMM_H445]: 445,
  };

  return mapper[panelName];
};

export const getCommercialInsurancePriceEstimation = (
  order: Order | undefined
): string => {
  if (!order) {
    return PRICE_ESTIMATIONS.PRICE_249;
  }
  return getPriceEstimation(order);
};

export const getPriceEstimation = (order: Order): string => {
  const isCombo = isComboOrder(order);
  const isPanorama = isPanoramaOrder(order);

  const horizonItem = order.items.find(
    (item) => item.testType === TestType.HORIZON
  );
  const panoramaItem = order.items.find(
    (item) => item.testType === TestType.PANORAMA
  );
  const horizonPanel =
    horizonItem &&
    horizonItem?.panels.find((value) =>
      Object.keys(PanelNames).includes(value.name)
    );
  const microdelPanel = panoramaItem?.panels[0]?.microdelPanel;
  const isCaPnsSupplemental =
    panoramaItem?.product?.configuration?.caPnsSupplemental;

  if (isCombo) {
    if (horizonPanel && microdelPanel) {
      const name = horizonPanel.name as keyof typeof PanelNames;
      if (
        panelNameToGenesMapper(PanelNames[name]) >= 28 ||
        microdelPanel === MicrodelPanel.FULL_PANEL
      ) {
        return PRICE_ESTIMATIONS.PRICE_449;
      } else {
        return PRICE_ESTIMATIONS.PRICE_349;
      }
    }
  }

  if (isPanorama) {
    if (isCaPnsSupplemental) {
      if (microdelPanel === MicrodelPanel.NONE) {
        return PRICE_ESTIMATIONS.PRICE_99;
      } else if (microdelPanel === MicrodelPanel.DIGEORGE_ONLY) {
        return PRICE_ESTIMATIONS.PRICE_249;
      } else {
        return PRICE_ESTIMATIONS.PRICE_349;
      }
    } else {
      if (microdelPanel === MicrodelPanel.FULL_PANEL) {
        return PRICE_ESTIMATIONS.PRICE_349;
      } else {
        return PRICE_ESTIMATIONS.PRICE_249;
      }
    }
  } else {
    const name = horizonPanel?.name as keyof typeof PanelNames;
    if (name === PanelNames.CARRIER_SCREENING_3_COMM_HCUSTOM) {
      return PRICE_ESTIMATIONS.PRICE_249_349;
    }
    if (
      panelNameToGenesMapper(PanelNames[name]) < 28 ||
      (horizonItem?.panels && horizonItem.panels.length > 1)
    ) {
      return PRICE_ESTIMATIONS.PRICE_249;
    } else {
      return PRICE_ESTIMATIONS.PRICE_349;
    }
  }
};
