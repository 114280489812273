import { useLogEvent } from "@app/neva/hooks";
import { parseAttributes } from "@app/neva/helpers";

type UseUrlActivityEventSenderType = {
  sendActivityEventFromUrl: (url: string) => void;
};

export const useUrlActivityEventSender = (): UseUrlActivityEventSenderType => {
  const { logEvent } = useLogEvent();

  const sendActivityEventFromUrl = (url: string) => {
    const attributes = parseAttributes(url);
    const attribute = attributes.find(
      (attribute) => attribute.name === "activity_event"
    );

    if (attribute) {
      const activityEvent = attribute.value;
      logEvent({
        eventAction: activityEvent,
        eventLabel: "User Action",
      });
    }
  };

  return { sendActivityEventFromUrl };
};
