import React from "react";
import { useIntl } from "react-intl";
import { HeapPHIDecorator } from "@app/components/analytics";
import { messages } from "../messages";
import { Textfield } from "@natera/platform/lib/components/form/field";
import Tooltip from "@natera/material/lib/tooltip";
import { GetValidationError } from "@natera/platform/lib/hooks/useError";
import { FormField } from "@app/components";

const ZipCodeField: React.FC<{
  getValidationError: GetValidationError;
  defaultValue?: string;
}> = ({ getValidationError, defaultValue }) => {
  const intl = useIntl();

  return (
    <FormField
      title={intl.formatMessage(messages.addressFormZipCode)}
      label={intl.formatMessage(messages.addressFormZipCode)}
      error={getValidationError("zipCode")}
      htmlFor="zipCode"
      required
      withPadding
    >
      <Tooltip content={intl.formatMessage(messages.addressFormZipTooltip)}>
        <div>
          <HeapPHIDecorator protectAttr={["value"]}>
            <Textfield
              placeholder={intl.formatMessage(
                messages.addressFormZipCodePlaceholder
              )}
              id="zipCode"
              name="zipCode"
              data-testid="zipCode"
              defaultValue={defaultValue}
              autoComplete="postal-code"
              outline
              required
            />
          </HeapPHIDecorator>
        </div>
      </Tooltip>
    </FormField>
  );
};

export { ZipCodeField };
