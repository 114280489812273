import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";

import Button from "@natera/material/lib/button";
import { routes } from "@app/routing";
import { ResultCodes } from "@app/service/resultCodes";
import HomeSvg from "@assets/svg/icons/home.svg";
import BillingSvg from "@assets/svg/icons/billing.svg";
import AccountSvg from "@assets/svg/icons/account.svg";
import PhoneSvg from "@assets/svg/icons/phone.svg";
import HistorySvg from "@assets/svg/icons/history.svg";
import ScheduleSvg from "@assets/svg/icons/schedule.svg";
import ExitSvg from "@assets/svg/icons/exit.svg";
import NavItem from "./navItem";
import { Link, useHistory } from "react-router-dom";
import Logo from "@assets/svg/natera-portal-logo.svg";
import Svg from "@natera/material/lib/svg";
import { ConfigContext } from "@app/provider";
import { HeapEventLocation, HEAP_EVENTS } from "@app/provider/types";
import classNames from "classnames";
import "./navigation.scss";

const messages = defineMessages({
  navigationHome: {
    id: "navigationHome",
    defaultMessage: "Home",
  },
  navigationBillingCenter: {
    id: "navigationBillingCenter",
    defaultMessage: "Billing Center",
  },
  navigationMyAccount: {
    id: "navigationMyAccount",
    defaultMessage: "My Account",
  },
  navigationTestHistory: {
    id: "navigationTestHistory",
    defaultMessage: "Test History",
  },
  navigationSheduleGis: {
    id: "navigationSheduleGis",
    defaultMessage: "Schedule a Genetic Information Session",
  },
  navigationPayMyBill: {
    id: "navigationPayMyBill",
    defaultMessage: "Pay My Bill",
  },
  navigationContactUs: {
    id: "navigationContactUs",
    defaultMessage: "Contact Us",
  },
  navigationSignOut: {
    id: "navigationSignOut",
    defaultMessage: "Log Out",
  },
  navigationNateraHomeLogo: {
    id: "navigationNateraHomeLogo",
    defaultMessage: "Natera home",
  },
});

interface Props {
  isMenuOpen: boolean;
  toggleMenu: () => void;
}

const Navigation: FC<Props> = ({ isMenuOpen, toggleMenu }) => {
  const history = useHistory();
  const intl = useIntl();
  const { config } = React.useContext(ConfigContext);

  const navigationMainItems = () => (
    <>
      <NavItem
        icon={HomeSvg}
        title={intl.formatMessage(messages.navigationHome)}
        to={routes.home}
        heapEventName={HEAP_EVENTS.upp_click_home}
      >
        {intl.formatMessage(messages.navigationHome)}
      </NavItem>

      <NavItem
        icon={BillingSvg}
        title={intl.formatMessage(messages.navigationBillingCenter)}
        to={routes.billing}
        className="billing-center"
      >
        {intl.formatMessage(messages.navigationBillingCenter)}
      </NavItem>

      <NavItem
        icon={AccountSvg}
        title={intl.formatMessage(messages.navigationMyAccount)}
        to={routes.profile}
        isMobileMenu={isMenuOpen}
        heapEventName={HEAP_EVENTS.upp_click_patientprofile}
        heapEventProps={{ location: HeapEventLocation.burgerMenu }}
      >
        {intl.formatMessage(messages.navigationMyAccount)}
      </NavItem>
    </>
  );

  const handleLogout = () => {
    history.push(`${routes.logOut}?code=${ResultCodes.SIGN_OUT}`);
  };

  return (
    <>
      <nav
        className={classNames("navigation", {
          "navigation--closed": !isMenuOpen,
        })}
      >
        <div className="navigation__logo">
          <Link
            to="/"
            aria-label={intl.formatMessage(messages.navigationNateraHomeLogo)}
          >
            <Svg content={Logo} role="img" />
          </Link>
        </div>

        <div className="navigation__divider" />

        <ul
          className="navigation__content navigation__main--desktop"
          role="presentation"
        >
          {navigationMainItems()}
        </ul>

        <ul
          className="navigation__content navigation__second"
          role="presentation"
        >
          <NavItem
            icon={HistorySvg}
            title={intl.formatMessage(messages.navigationTestHistory)}
            to={routes.testHistory}
            heapEventName={HEAP_EVENTS.upp_click_testhistory}
          >
            {intl.formatMessage(messages.navigationTestHistory)}
          </NavItem>

          <NavItem
            icon={ScheduleSvg}
            title={intl.formatMessage(messages.navigationSheduleGis)}
            to={routes.scheduleAGeneticInformationSession}
            heapEventName={HEAP_EVENTS.upp_click_gis}
            heapEventProps={{ location: HeapEventLocation.burgerMenu }}
          >
            {intl.formatMessage(messages.navigationSheduleGis)}
          </NavItem>

          <NavItem
            icon={BillingSvg}
            title={intl.formatMessage(messages.navigationPayMyBill)}
            to={{ pathname: config.links.PAY_MY_BILL }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage(messages.navigationPayMyBill)}
          </NavItem>

          <NavItem
            icon={PhoneSvg}
            title={intl.formatMessage(messages.navigationContactUs)}
            to={{ pathname: config.links.CONTACT_US }}
            target="_blank"
            rel="noopener noreferrer"
            heapEventName={HEAP_EVENTS.upp_click_contactnatera}
            heapEventProps={{ location: HeapEventLocation.main_menu }}
          >
            {intl.formatMessage(messages.navigationContactUs)}
          </NavItem>
        </ul>

        <ul
          className="navigation__content navigation__main--mobile"
          role="presentation"
        >
          <div className="navigation__divider" />
          {navigationMainItems()}
        </ul>

        <ul
          className="navigation__content navigation__bottom-routes"
          role="presentation"
        >
          <Button
            className="nav-item logout-btn"
            type="button"
            onClick={handleLogout}
            icon={ExitSvg}
          >
            {intl.formatMessage(messages.navigationSignOut)}
          </Button>
        </ul>
      </nav>
      <div
        className={classNames("navigation__overlay", {
          "navigation__overlay--closed": !isMenuOpen,
        })}
        onClick={toggleMenu}
      />
    </>
  );
};

export default Navigation;
