import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { defineMessages, useIntl } from "react-intl";
import { RatingItemType } from "@app/neva/models";
import StarsRating from "./starsRating";
import ScoresRating from "./scoresRating";
import "./rating.scss";

enum RatingTypes {
  STAR_RATING = "star-rating",
  NPS_SCORE = "nps-score",
}

interface RatingTypesProps {
  item: RatingItemType;
  isDisabled: boolean;
  rating: number;
  onClickHandler: (props: RatingItemType) => void;
}

interface RatingTypesAliasKeys {
  [key: string]: FC<RatingTypesProps>;
}

const RatingType = {
  [RatingTypes.STAR_RATING]: StarsRating,
  [RatingTypes.NPS_SCORE]: ScoresRating,
} as RatingTypesAliasKeys;

const messages = defineMessages({
  scoreRatingList: {
    id: "scoreRatingList",
    defaultMessage: "Select answer",
  },
  starRatingList: {
    id: "starRatingList",
    defaultMessage: "Select rating",
  },
});

interface Props {
  items: RatingItemType[];
  type: string;
  rating: number;
  setSelectedRatingItem: (props: RatingItemType) => void;
  isDisabled: boolean;
}

const Rating: FC<Props> = ({
  items,
  type,
  rating,
  setSelectedRatingItem,
  isDisabled,
}) => {
  const intl = useIntl();
  const CurrentRatingType = RatingType[type];
  const isScoreType = useMemo(() => type === RatingTypes.NPS_SCORE, [type]);
  const listAriaLabel = useMemo(
    () => (isScoreType ? "scoreRatingList" : "starRatingList"),
    [isScoreType]
  );

  const onClickHandler = (ratingItem: RatingItemType) => {
    if (isDisabled) return;
    const scoreNumber = ratingItem.scoreNumber;

    setSelectedRatingItem(ratingItem);
    if (scoreNumber === rating) {
      setSelectedRatingItem({
        scoreNumber: -1,
        attributePayload: "",
        ariaLabel: "",
        title: "",
      });
    }
  };

  return (
    <div className="rating__container">
      <ul
        className={classNames("rating__list", {
          "score-type": isScoreType,
        })}
        aria-label={intl.formatMessage(messages[listAriaLabel])}
      >
        {items.map((item) => (
          <CurrentRatingType
            key={item.scoreNumber}
            item={item}
            isDisabled={isDisabled}
            rating={rating}
            onClickHandler={onClickHandler}
          />
        ))}
      </ul>
    </div>
  );
};

export default Rating;
