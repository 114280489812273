import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import UserService, {
  EnrollFactorResponse,
  EnrollProfile,
  Factor,
  FACTORS_TO_SHOW,
  FactorStatus,
  STAGE,
  SuccessStatusResponse,
  UppUser,
} from "@app/service/user";
import {
  FetchResult,
  isApolloError,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import {
  NotificationContext,
  ProfileContext,
  UserContext,
} from "@app/provider";
import { ResultCodes } from "@app/service/resultCodes";
import { useDialog } from "@natera/platform/lib/hooks";
import {
  FactorDisableModal,
  FactorSuccessModal,
} from "@app/components/factorList";
import { routes } from "@app/routing";
import R from "ramda";
import { HeapEventLocation } from "@app/provider/types";

export interface User {
  id?: string;
  patientUID?: string;
  stage?: STAGE;
  email?: string;
}

export enum LoginStatus {
  FAIL = "FAIL",
  SUCCESS = "SUCCESS",
}

interface ErrorResponse {
  message: string;
}

export interface ErrorBody {
  code: string;
  message: string;
}

interface ActivateUserFactor {
  success: boolean;
}

export type FactorsObject = {
  [key in FACTORS_TO_SHOW]?: Factor;
};

export interface MfaSetupController {
  isLoading: boolean;
  enrolledFactor: EnrollFactorResponse | undefined;
  isFactorActivated: boolean;
  isUpdatePhoneAction: boolean;
  setIsUpdatePhoneAction: Dispatch<SetStateAction<boolean>>;
  factorList: Factor[] | undefined;
  enrolledFactorList: Factor[] | undefined;
  areFactorsActive: boolean;
  isFirstSetup: boolean;
  factorsObject: FactorsObject;
  showError: (err?: ErrorResponse) => void;
  handleSkip: () => Promise<void>;
  handleFactorSetup: (
    factor: Factor | undefined,
    isActive: boolean
  ) => Promise<void>;
  enrollUserFactor: (
    factorType: string,
    provider: string,
    profile?: EnrollProfile
  ) => Promise<void>;
  resendUserFactorCode: () => void;
  activateUserFactor: (
    passCode: string,
    heapEventLocation?: HeapEventLocation
  ) => Promise<FetchResult<{ activateUserFactor: ActivateUserFactor }>>;
  disableUserFactors: () => Promise<
    FetchResult<{ disableUserFactors: SuccessStatusResponse }>
  >;
  loadFactorList: () => void;
  resetActivatedFactor: () => void;
  loadEnrolledFactorList: () => void;
  getActivePhoneFactors: () => Factor[];
}

export const Context = React.createContext<MfaSetupController>({
  isLoading: false,
  enrolledFactor: undefined,
  isFactorActivated: false,
  isUpdatePhoneAction: false,
  setIsUpdatePhoneAction: () => undefined,
  factorList: undefined,
  enrolledFactorList: undefined,
  areFactorsActive: false,
  isFirstSetup: false,
  factorsObject: {},
  showError: () => undefined,
  handleSkip: async () => undefined,
  handleFactorSetup: async () => undefined,
  enrollUserFactor: async () => undefined,
  resendUserFactorCode: () => undefined,
  activateUserFactor: async () => ({}),
  disableUserFactors: async () => ({}),
  loadFactorList: () => undefined,
  loadEnrolledFactorList: () => undefined,
  resetActivatedFactor: () => undefined,
  getActivePhoneFactors: () => [],
});

const messages = defineMessages({
  userFactorIsAlreadySetup: {
    id: "userFactorIsAlreadySetup",
    defaultMessage: "Factor is already set up",
  },
});

const FIRST_STEP_STAGES = [
  STAGE.BLANK,
  STAGE.MFA_SETUP,
  STAGE.COMMUNICATION_PREFERENCES,
];

Context.displayName = "MfaSetupContext";

const MfaSetupProvider: FC = ({ children }) => {
  const intl = useIntl();
  const history = useHistory();
  const [enrollFactorResponse, setEnrollFactorResponse] = useState<
    EnrollFactorResponse
  >();
  const { uppUser } = useContext(UserContext);
  const { getProfile, skipCommunicationPreferencesStep } = useContext(
    ProfileContext
  );
  const [isFactorActivated, setIsFactorActivated] = useState<boolean>(false);
  const [isUpdatePhoneAction, setIsUpdatePhoneAction] = useState<boolean>(
    false
  );
  const { addNotification, clear } = useContext(NotificationContext);
  const factorSuccessModal = useDialog(FactorSuccessModal);
  const factorDisableModal = useDialog(FactorDisableModal);

  const isFirstSetup = useMemo(() => {
    const stage = uppUser?.stage;
    return FIRST_STEP_STAGES.includes(stage as STAGE);
  }, [uppUser]);

  const showError = (err?: Error) => {
    if (err && isApolloError(err) && err.graphQLErrors?.length > 0) {
      const code = (err.graphQLErrors[0].extensions?.exception as ErrorBody)
        ?.code;
      switch (code) {
        case ResultCodes.FACTOR_IS_ALREADY_SETUP:
          addNotification({
            message: intl.formatMessage(messages.userFactorIsAlreadySetup),
            type: "error",
          });
          break;
        default:
          addNotification({
            type: "error",
          });
      }
    } else {
      addNotification({
        type: "error",
      });
    }
  };

  const [skipMfa, { loading: skipMfaIsLoading }] = useMutation<{
    updateUser: UppUser;
  }>(UserService.updateUser(), {
    update: (cache, { data }) => {
      const { updateUser } = data || {};
      const { profile } =
        cache.readQuery({ query: UserService.loadProfile() }) || {};
      cache.writeQuery({
        query: UserService.loadProfile(),
        data: { profile: { ...profile, ...updateUser } },
      });
    },
  });

  const [
    loadFactorList,
    { loading: factorListIsLoading, data: factorList },
  ] = useLazyQuery<{
    factorList: Factor[];
  }>(UserService.getUserFactorList(), {
    fetchPolicy: "network-only",
  });

  const [
    loadEnrolledFactorList,
    { loading: enrolledFactorListIsLoading, data: enrolledFactorList },
  ] = useLazyQuery(UserService.getEnrolledUserFactorList(), {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    loadEnrolledFactorList();
  }, []);

  const isActivePhoneFactor = (factor: Factor) =>
    factor.status === FactorStatus.ACTIVE &&
    (factor.factorType === FACTORS_TO_SHOW.sms ||
      factor.factorType === FACTORS_TO_SHOW.call);

  const getActivePhoneFactors = () =>
    enrolledFactorList?.enrolledFactorList.filter((factor: Factor) =>
      isActivePhoneFactor(factor)
    );

  const [
    enrollUserFactor,
    {
      loading: enrollUserIsLoading,
      data: enrollFactorResp,
      error: enrollFactorErr,
    },
  ] = useMutation<{
    enrollUserFactor: EnrollFactorResponse;
  }>(UserService.enrollUserFactor());

  const [
    resendUserFactorCode,
    {
      loading: resendUserFactorCodeIsLoading,
      data: resendFactorCodeResp,
      error: resendFactorErr,
    },
  ] = useMutation<{
    resendUserFactorCode: EnrollFactorResponse;
  }>(UserService.resendUserFactorCode());

  const [
    activateUserFactor,
    { loading: activateUserFactorIsLoading, data: activateFactorResponse },
  ] = useMutation<{
    activateUserFactor: SuccessStatusResponse;
  }>(UserService.activateUserFactor());

  const [
    disableUserFactors,
    { loading: disableUserFactorsIsLoading },
  ] = useMutation<{ disableUserFactors: SuccessStatusResponse }>(
    UserService.disableUserFactors()
  );

  useEffect(() => {
    if (enrollFactorResp) {
      setEnrollFactorResponse(enrollFactorResp.enrollUserFactor);
      if (enrollFactorResp.enrollUserFactor.status === FactorStatus.ACTIVE) {
        setIsFactorActivated(true);
      }
    }
  }, [enrollFactorResp]);

  useEffect(() => {
    if (resendFactorCodeResp) {
      setEnrollFactorResponse(resendFactorCodeResp.resendUserFactorCode);
    }
  }, [resendFactorCodeResp]);

  useEffect(() => {
    if (activateFactorResponse?.activateUserFactor?.success) {
      setIsFactorActivated(true);
    }
  }, [activateFactorResponse]);

  useEffect(() => {
    if (enrollFactorErr) {
      const errorCode = (enrollFactorErr.graphQLErrors?.[0]?.extensions
        ?.exception as ErrorBody)?.code;

      if (errorCode === ResultCodes.CODE_WAS_RECENTLY_SENT_ERROR) {
        const enrolledSms = enrolledFactorList?.enrolledFactorList
          ? R.find(
              (factor: Factor) => factor.factorType === FACTORS_TO_SHOW.sms,
              enrolledFactorList?.enrolledFactorList
            )
          : undefined;

        if (enrolledSms && enrolledSms.id) {
          setEnrollFactorResponse(enrolledSms as EnrollFactorResponse);
        }
      } else {
        showError(enrollFactorErr);
      }
    }
  }, [enrollFactorErr]);

  useEffect(() => {
    if (resendFactorErr) {
      showError(resendFactorErr);
    }
  }, [resendFactorErr]);

  const factorsObject = useMemo(() => {
    const factorArr = factorList?.factorList;
    const factorObj: FactorsObject = {};
    if (!factorArr) return factorObj;
    factorArr.forEach((factor) => (factorObj[factor.factorType] = factor));
    return factorObj;
  }, [factorList]);

  const areFactorsActive = useMemo(() => {
    const factorArr = factorList?.factorList;
    if (!factorArr) return false;
    const activeFactorList = R.filter(
      (factor) => factor.status === FactorStatus.ACTIVE,
      factorArr
    );
    return activeFactorList.length > 0;
  }, [factorList]);

  const handleFactorActivated = (factorType: FACTORS_TO_SHOW) => {
    if (factorType === FACTORS_TO_SHOW.email) {
      history.replace(routes.securitySettings, {
        emailFactorActivated: true,
      });
    } else if (isUpdatePhoneAction) {
      history.replace(routes.contactDetails, {
        phoneUpdated: true,
      });
    } else {
      history.replace(routes.securitySettings, {
        phoneFactorActivated: true,
      });
    }
  };

  useEffect(() => {
    if (enrollFactorResponse && isFactorActivated) {
      if (isFirstSetup) {
        const onClose = () => {
          factorSuccessModal.close();
        };
        factorSuccessModal.open({
          type: enrollFactorResponse.factorType,
          onClose,
        });
      } else {
        handleFactorActivated(enrollFactorResponse.factorType);
      }
    }
  }, [enrollFactorResponse, isFactorActivated]);

  const handleSkip = async () => {
    clear();
    try {
      await skipMfa({
        variables: {
          updateUserData: {
            askForMfa: false,
            stage: skipCommunicationPreferencesStep
              ? STAGE.FILLED
              : STAGE.COMMUNICATION_PREFERENCES,
          },
        },
      });
      const url = isFirstSetup ? routes.home : routes.securitySettings;
      skipCommunicationPreferencesStep &&
        history.replace(url + history.location.search);
    } catch (error) {
      showError(error);
    }
  };

  const handleEnrollUserFactor = async (
    factorType: string,
    provider: string,
    profile?: EnrollProfile
  ) => {
    clear();
    try {
      await enrollUserFactor({
        variables: {
          enrollFactorData: {
            factorType,
            provider,
            profile,
          },
        },
      });
      if (profile?.phoneNumber) {
        setIsUpdatePhoneAction(true);
      }
    } catch (error) {
      showError(error);
    }
  };

  const handleResendUserFactorCode = async () => {
    clear();
    if (!enrollFactorResponse) return;
    const { id: factorId, factorType, provider } = enrollFactorResponse;
    try {
      await resendUserFactorCode({
        variables: {
          resendFactorCodeData: {
            factorId,
            factorType,
            provider,
          },
        },
      });
    } catch (error) {
      showError();
    }
  };

  const handleActivateUserFactor = async (
    passCode: string,
    heapEventLocation?: HeapEventLocation
  ) => {
    clear();
    if (!enrollFactorResponse) return {};
    const { id: factorId } = enrollFactorResponse;
    const askForCommunicationPreferences =
      isFirstSetup && !skipCommunicationPreferencesStep;
    try {
      const response = await activateUserFactor({
        variables: {
          activateFactorData: {
            factorId,
            passCode,
          },
          heapEventLocation,
          askForCommunicationPreferences,
        },
      });
      return response;
    } catch (error) {
      if (isApolloError(error) && error.graphQLErrors?.length > 0) {
        const parsedErrors = error.graphQLErrors.map(
          (e) => e.extensions?.exception
        );
        const incorrectActivationCodeError = parsedErrors.find(
          (e: ErrorBody) => e.code === ResultCodes.INCORRECT_ACTIVATION_CODE
        );
        // throw it to show in code form
        if (incorrectActivationCodeError) {
          throw incorrectActivationCodeError;
        } else {
          showError();
        }
      } else {
        showError();
      }
    }
    return {};
  };

  const handleDisableUserFactors = async () => {
    clear();
    try {
      return await disableUserFactors();
    } catch (error) {
      showError(error);
    }
    return {};
  };

  const handleFactorSetup = async (
    factor: Factor | undefined,
    isActive: boolean
  ) => {
    if (!isFirstSetup) {
      const onClose = () => {
        factorDisableModal.close();
      };

      const onSubmit = async () => {
        try {
          await disableUserFactors().then(() => {
            resetActivatedFactor();
            onClose();
            history.replace(routes.securitySettings, {
              factorDisabled: true,
            });
          });
        } catch (error) {
          showError();
        }
      };
      if (!isActive && areFactorsActive) {
        factorDisableModal.open({ onClose, onSubmit });
        return;
      }
    }
    await getProfile();
    if (factor) {
      if (factor.status === FactorStatus.ACTIVE) {
        handleFactorActivated(factor.factorType);
      } else {
        handleEnrollUserFactor(factor.factorType, factor.provider);
      }
    }
  };

  const resetActivatedFactor = () => {
    setIsFactorActivated(false);
    setEnrollFactorResponse(undefined);
  };

  const isLoading =
    skipMfaIsLoading ||
    factorListIsLoading ||
    enrollUserIsLoading ||
    resendUserFactorCodeIsLoading ||
    activateUserFactorIsLoading ||
    disableUserFactorsIsLoading ||
    enrolledFactorListIsLoading;

  return (
    <Context.Provider
      value={{
        isLoading,
        enrolledFactor: enrollFactorResponse,
        isFactorActivated,
        isUpdatePhoneAction,
        setIsUpdatePhoneAction,
        factorList: factorList?.factorList,
        enrolledFactorList: enrolledFactorList?.enrolledFactorList,
        areFactorsActive,
        isFirstSetup,
        factorsObject,
        showError,
        loadFactorList,
        loadEnrolledFactorList,
        handleSkip,
        handleFactorSetup,
        enrollUserFactor: handleEnrollUserFactor,
        resendUserFactorCode: handleResendUserFactorCode,
        activateUserFactor: handleActivateUserFactor,
        disableUserFactors: handleDisableUserFactors,
        resetActivatedFactor,
        getActivePhoneFactors,
      }}
    >
      {factorDisableModal.getDialog()}
      {factorSuccessModal.getDialog()}
      {children}
    </Context.Provider>
  );
};

export default MfaSetupProvider;
