import { ProvideGovernmentIssuedForm } from "@app/components/forms";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { SubSteps } from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { StepperContext } from "@natera/stepper";
import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  MultiplePaymentMethodType,
  PaymentMethodType,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import { Steps } from "@app/pages/private/simpleOrder";
import {
  getPaymentMethodInfoSubStepsData,
  isCommercialOrGovernmentInsuranceFirst,
  isCommercialOrGovernmentInsuranceSecond,
} from "@app/utils";

const messages = defineMessages({
  provideGovernmentStepHeaderTitle: {
    id: "provideGovernmentStepHeaderTitle",
    defaultMessage: "Payment Method",
  },
  provideGovernmentStepHeaderSubTitle: {
    id: "provideGovernmentStepHeaderSubTitle",
    defaultMessage: "Enter government-issued insurance information",
  },
});

type Props = {
  onConfirm?: (data?: unknown) => void;
};

const ProvideGovernmentStep: React.FC<Props> = ({ onConfirm }) => {
  const intl = useIntl();

  const { goBack, resolve, getCurrentStep, getStep } = React.useContext(
    StepperContext
  );
  const currentStepId = getCurrentStep()?.stepId;

  const { getSubStepData, resolveSubStep } = useContext(
    SimpleOrderStepperContext
  );

  const subStepsData = getSubStepData(Steps.PAYMENT_METHOD) as
    | PaymentMethodType
    | MultiplePaymentMethodType;

  const isInsuranceFirstStep = isCommercialOrGovernmentInsuranceFirst(
    currentStepId
  );
  const isInsuranceSecondStep = isCommercialOrGovernmentInsuranceSecond(
    currentStepId
  );

  const governmentStepData = getPaymentMethodInfoSubStepsData(
    subStepsData,
    isInsuranceFirstStep,
    isInsuranceSecondStep
  );

  const handleSubmit = (insuranceNumber: string) => {
    let subStepDataWithInsuranceNumber;

    if (isInsuranceFirstStep) {
      subStepDataWithInsuranceNumber = {
        paymentMethodInfo: {
          first: {
            paymentMethodInfo: {
              ...(subStepsData as MultiplePaymentMethodType)?.paymentMethodInfo
                ?.first?.paymentMethodInfo,
              insuranceNumber,
            },
          },
        },
      };
    } else if (isInsuranceSecondStep) {
      subStepDataWithInsuranceNumber = {
        paymentMethodInfo: {
          second: {
            paymentMethodInfo: {
              ...(subStepsData as MultiplePaymentMethodType)?.paymentMethodInfo
                ?.second?.paymentMethodInfo,
              insuranceNumber,
            },
          },
        },
      };
    } else {
      subStepDataWithInsuranceNumber = {
        paymentMethodInfo: { insuranceNumber },
      };
    }

    resolveSubStep({
      stepId: Steps.PAYMENT_METHOD,
      subStepData: subStepDataWithInsuranceNumber,
    });

    resolve({ insuranceNumber });
    const needToAddSecondMultipleStep = !getStep(SubSteps.MULTIPLE_SECOND);
    if (needToAddSecondMultipleStep) {
      onConfirm && onConfirm();
    }
  };

  const handleBack = () => {
    goBack();
  };

  return (
    <StepperContent
      title={intl.formatMessage(messages.provideGovernmentStepHeaderTitle)}
      subTitle={intl.formatMessage(
        messages.provideGovernmentStepHeaderSubTitle
      )}
    >
      <ProvideGovernmentIssuedForm
        defaultValue={governmentStepData?.insuranceNumber}
        onSubmit={handleSubmit}
        goBack={handleBack}
      />
    </StepperContent>
  );
};

export default ProvideGovernmentStep;
