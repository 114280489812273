import { PasswordPolicyComplexityValidationState } from "@app/components/passwordNotice/passwordNotice";

export type PasswordValidationProps = {
  password?: string;
  email?: string;
  passwordPolicyComplexity?: PasswordPolicyComplexityValidationState;
};

const getValidationCriteria = (password?: string, email?: string) => {
  return {
    upperCaseLettersCount: password?.match(/[A-Z]/g)?.length,
    lowerCaseLettersCount: password?.match(/[a-z]/g)?.length,
    numbersCount: password?.match(/[0-9]/g)?.length,
    includesUsername: email ? password?.includes(email) : false,
    symbolsCount: password?.match(/[@#$]/g)?.length,
  };
};

export const isPasswordValid = ({
  password,
  email,
  passwordPolicyComplexity,
}: PasswordValidationProps): boolean => {
  const {
    upperCaseLettersCount,
    lowerCaseLettersCount,
    numbersCount,
    includesUsername,
    symbolsCount,
  } = getValidationCriteria(password, email);

  if (!passwordPolicyComplexity) {
    return false;
  }

  const {
    minLength,
    minUpperCase,
    minLowerCase,
    minNumber,
    excludeUsername,
    minSymbol,
  } = passwordPolicyComplexity;

  if (!password) {
    return false;
  }

  if (password.length < Number(minLength?.value)) {
    return false;
  }

  if (minUpperCase?.value && Number(minUpperCase.value) > 0) {
    if (
      !upperCaseLettersCount ||
      upperCaseLettersCount < Number(minUpperCase.value)
    ) {
      return false;
    }
  }

  if (minLowerCase?.value && Number(minLowerCase.value) > 0) {
    if (
      !lowerCaseLettersCount ||
      lowerCaseLettersCount < Number(minLowerCase.value)
    ) {
      return false;
    }
  }

  if (minNumber?.value && Number(minNumber.value) > 0) {
    if (!numbersCount || numbersCount < Number(minNumber.value)) {
      return false;
    }
  }

  if (excludeUsername && includesUsername) {
    return false;
  }

  if (minSymbol?.value && Number(minSymbol.value) > 0) {
    if (!symbolsCount || symbolsCount < Number(minSymbol.value)) {
      return false;
    }
  }

  return true;
};
