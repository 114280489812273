import { MenuController } from "@natera/material/lib/menu/controller";

export const handleKeyDownByRef = (
  ref: React.MutableRefObject<HTMLElement | null>
) => (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === "Escape" && ref.current) {
    ref.current.click();
  }
};

export const handleKeyDownByMenu = (menu: MenuController) => (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  if (e.key === "Enter") {
    return menu.toggleMenu();
  }
};
