import { DocumentNode, gql } from "@apollo/client";

class PatientService {
  public static getPatient = (): DocumentNode => gql`
    query Patient($uid: String!) {
      patient(uid: $uid) {
        uid
        firstName
        lastName
        dateOfBirth
        birthGender
        currentGender
        email
        phone
        addresses {
          uid
          countryCode
          city
          state
          street1
          street2
          zipCode
        }
      }
    }
  `;

  public static updatePatient = (): DocumentNode => gql`
    mutation UpdatePatient($patientData: PatientUpdateInputDTO!) {
      updatePatient(patientData: $patientData) {
        uid
        firstName
        middleName
        lastName
        dateOfBirth
        birthGender
        email
        phone
      }
    }
  `;
}

export default PatientService;
