import React, { FC, useContext, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { routes } from "@app/routing";
import {
  BlueHeader,
  InformationCard,
  SpinnerView,
  TestInformationCard,
} from "@app/components";
import { TestDetailsContext } from "@app/provider/testData";
import "./testInformation.scss";

const messages = defineMessages({
  testInformationPageHeader: {
    id: "testInformationPageHeader",
    defaultMessage: "Test Information",
  },
});

const TestInformationPage: FC = () => {
  const intl = useIntl();

  const { isLoading, getTestDetails, getOrderUid, getTestUid } = useContext(
    TestDetailsContext
  );

  const testDetails = getTestDetails();

  const content = useMemo(
    () =>
      testDetails ? (
        <TestInformationCard testDetailsInformation={testDetails} />
      ) : null,
    [testDetails]
  );

  return (
    <div className="test-information-page">
      <BlueHeader
        arrowRoute={routes.testDetailsPage(getOrderUid(), getTestUid())}
        title={intl.formatMessage(messages.testInformationPageHeader)}
      />
      <SpinnerView isLoading={isLoading} />
      <div className="test-information-page__content">
        {testDetails && <InformationCard content={content} />}
      </div>
    </div>
  );
};

export default TestInformationPage;
