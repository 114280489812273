import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  ErrorProvider,
  MfaSetupContext,
  ProfileContext,
  UserContext,
} from "@app/provider";
import { Factor } from "@app/service/user";
import { useErrorController } from "@natera/platform/lib/hooks";
import { Button } from "@natera/platform/lib/components/form";
import { Switch } from "@natera/material/lib/switch";
import { Link } from "@natera/platform/lib/components/link";
import SpinnerView from "@app/components/spinnerView";
import VerifyCodeForm from "@app/components/verifyCodeForm";
import { Profile } from "@app/provider/profile";
import { routes } from "@app/routing";
import { Svg } from "@natera/material/lib/svg";
import InfoIcon from "@assets/svg/icons/info.svg";
import FactorList from "@app/components/factorList/factorList";
import { HeapEventLocation } from "@app/provider/types";
import "./factorList.scss";

const messages = defineMessages({
  factorListTitle: {
    id: "factorListTitle",
    defaultMessage: "Two - Factor Authentication",
  },
  factorListSwitcher: {
    id: "factorListSwitcher",
    defaultMessage: "Turn on two - factor authentication",
  },
  factorListDescription: {
    id: "factorListDescription",
    defaultMessage:
      "If you turn on two - factor authentication, each time you log in you'll verify your identify with: ",
  },
  factorListSetUp: {
    id: "factorListSetUp",
    defaultMessage: "Set Up",
  },
  factorListUpdate: {
    id: "factorListUpdate",
    defaultMessage: "Update",
  },
  factorListCancel: {
    id: "factorListCancel",
    defaultMessage: "Cancel",
  },
  factorListSkip: {
    id: "factorListSkip",
    defaultMessage: "Skip",
  },
  factorListPassword: {
    id: "factorListPassword",
    defaultMessage: "Your password",
  },
  factorListCodeSent: {
    id: "factorListCodeSent",
    defaultMessage: "A code sent to a device, like your phone",
  },
  factorListIncorrectActivationCode: {
    id: "factorListIncorrectActivationCode",
    defaultMessage: "Incorrect code.",
  },
  factorListInfo: {
    id: "factorListInfo",
    defaultMessage: "You can update your phone number or email",
  },
  factorListInfoLink: {
    id: "factorListInfoLink",
    defaultMessage: "here",
  },
});

interface Props {
  profile?: Profile;
  isFirstSetup?: boolean;
  isConfirmation?: boolean;
  phone?: string;
}

const FactorContainer: React.FunctionComponent<Props> = ({
  profile,
  isConfirmation,
  phone,
}) => {
  const intl = useIntl();
  const { loadProfile, isLoading } = React.useContext(UserContext);

  const [isActive, setIsActive] = React.useState(false);
  const [isForcedViaPhone, setIsForcedViaPhone] = React.useState(false);
  const [selectedFactor, setSelectedFactor] = React.useState<Factor>();
  const errorController = useErrorController();

  const { getProfileIsLoading } = React.useContext(ProfileContext);

  const {
    isLoading: isFactorLoading,
    enrolledFactor,
    areFactorsActive,
    isFirstSetup,
    factorsObject,
    handleSkip,
    activateUserFactor,
    loadFactorList,
    resendUserFactorCode,
    handleFactorSetup,
    resetActivatedFactor,
  } = React.useContext(MfaSetupContext);

  useEffect(() => {
    setIsActive(areFactorsActive);
    if (areFactorsActive) {
      loadProfile();
    }
  }, [areFactorsActive]);

  useEffect(() => {
    loadFactorList();
    if (isFirstSetup && !isConfirmation) {
      setIsForcedViaPhone(true);
      setIsActive(true);
    } else {
      resetActivatedFactor();
    }
    return () => resetActivatedFactor();
  }, []);

  const setupFactor: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    handleFactorSetup(selectedFactor, isActive);
  };

  const handleCode = async (code: string) => {
    try {
      const heapEventLocation = isFirstSetup
        ? HeapEventLocation.accountCreation
        : HeapEventLocation.patientProfile;
      await activateUserFactor(code, heapEventLocation);
    } catch (error) {
      errorController.setValidationError(
        "code",
        intl.formatMessage(messages.factorListIncorrectActivationCode)
      );
    }
  };

  const handleSwitchClick = () => {
    setIsActive(!isActive);
    setSelectedFactor(undefined);
  };

  const skipBtnText = isFirstSetup
    ? messages.factorListSkip
    : messages.factorListCancel;

  const isSubmitActive = !!selectedFactor || (!isActive && areFactorsActive);

  return (
    <>
      <SpinnerView isLoading={isLoading || isFactorLoading} />
      {enrolledFactor ? (
        <ErrorProvider controller={errorController}>
          <VerifyCodeForm
            type={enrolledFactor.factorType}
            profile={enrolledFactor.profile}
            handleCode={handleCode}
            handleResend={resendUserFactorCode}
          />
        </ErrorProvider>
      ) : (
        <div className="factor-container">
          {!isFirstSetup && (
            <h2>{intl.formatMessage(messages.factorListTitle)}</h2>
          )}
          <div className="factor-switch">
            <div className="factor-switch__left-label">
              <h3>{intl.formatMessage(messages.factorListSwitcher)}</h3>
              <div>
                <p>{intl.formatMessage(messages.factorListDescription)}</p>
                <ul>
                  <li>{intl.formatMessage(messages.factorListPassword)}</li>
                  <li>{intl.formatMessage(messages.factorListCodeSent)}</li>
                </ul>
              </div>
            </div>
            <Switch
              checked={isActive}
              disabled={isForcedViaPhone}
              id="factorSwitch"
              onClick={handleSwitchClick}
            />
          </div>

          <form onSubmit={setupFactor}>
            {isActive && (
              <>
                {!isFirstSetup && (
                  <div className="factor-info">
                    <Svg className="factor-info-icon" content={InfoIcon} />
                    <span className="factor-info-text">
                      {intl.formatMessage(messages.factorListInfo)}&nbsp;
                      <Link to={routes.contactDetails}>
                        {intl.formatMessage(messages.factorListInfoLink)}
                      </Link>
                    </span>
                  </div>
                )}
                <FactorList
                  profile={profile}
                  factorsObject={factorsObject}
                  isForcedViaPhone={isForcedViaPhone}
                  setSelectedFactor={setSelectedFactor}
                  phone={phone}
                />
              </>
            )}

            <div className="factor-footer">
              {!isForcedViaPhone && (
                <Button
                  onClick={handleSkip}
                  outlined
                  disabled={getProfileIsLoading}
                  type="button"
                >
                  {intl.formatMessage(skipBtnText)}
                </Button>
              )}
              <Button raised type="submit" disabled={!isSubmitActive}>
                {areFactorsActive
                  ? intl.formatMessage(messages.factorListUpdate)
                  : intl.formatMessage(messages.factorListSetUp)}
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default FactorContainer;
