import { IntlShape, MessageDescriptor } from "react-intl";
import { messages } from "../messages";
import { SecondaryActionCardType, SecondaryCard } from "../types";

const typeToText: { [key in SecondaryActionCardType]: MessageDescriptor } = {
  [SecondaryActionCardType.SALIVA]: messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.COMPASSIONATE_CARE]:
    messages.testCardSecondaryDescNoteCompassionateCare,
  [SecondaryActionCardType.PROSPERA_HEART]:
    messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.PROSPERA_KIDNEY]:
    messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.PROSPERA_SPK]:
    messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.PROSPERA_LIVER]:
    messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.PROSPERA_LUNG]:
    messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.PROSPERA_OTHER]:
    messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.RENASIGHT_KIDNEY]:
    messages.renasightKidneySecondaryActionCardDesc,
  [SecondaryActionCardType.EMPOWER]: messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.HORIZON]: messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.PANORAMA]: messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.VISTARA]: messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.GENETIC_TESTING]:
    messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.WOMENS_HEALTH_PRICING_BILLING]:
    messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.ONCOLOGY_PRICING_BILLING]:
    messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.PANORAMA_HORIZON]:
    messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.ALTERA]: messages.testCardSecondaryDescNoteEmpty,
  [SecondaryActionCardType.SIGNATERA]: messages.testCardSecondaryDescNoteEmpty,
};

export const getSecondaryCardDescriptionNote = (
  intl: IntlShape,
  secondaryCard: SecondaryCard
): string => intl.formatMessage(typeToText[secondaryCard.type]);
