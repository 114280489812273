import { defineMessages } from "react-intl";

export const messages = defineMessages({
  detailsRecordBackButton: {
    id: "detailsRecordBackButton",
    defaultMessage: "Test Detail Record",
  },
  detailsRecordTestInformationButton: {
    id: "detailsRecordTestInformationButton",
    defaultMessage: "Test Information",
  },
  detailsRecordTestInformationHeader: {
    id: "detailsRecordTestInformationHeader",
    defaultMessage: "Test Information",
  },
});
