import React, { FC, useContext, useEffect, useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import "./unlock.scss";
import { ConfigContext, NotificationContext } from "@app/provider";
import { LinkHeap, Notification, SpinnerView } from "@app/components";
import { UserContext } from "@app/provider";
import { validateEmail } from "@app/utils";
import { ResultCodes } from "@app/service/resultCodes";
import acknowledgements from "@etc/acknowledgements.json";
import UnableVerifyAccount from "./unableVerifyAccount";
import { useLocation } from "react-router-dom";
import { MailSentView, CredentialsInterface } from "./mailSentView";
import UnlockView from "./unlockView";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";

const messages = defineMessages({
  unlockContactSupport: {
    id: "unlockContactSupport",
    defaultMessage: "Contact Support",
  },
  unlockInvalidCombination: {
    id: "unlockInvalidCombination",
    defaultMessage: "We are unable to verify your identity. {support}",
  },
  unlockInvalidEmail: {
    id: "unlockInvalidEmail",
    defaultMessage: "Please enter a valid email address. (username@domain)",
  },
});

interface PreselectedEmail {
  email: string;
}

const Unlock: FC = () => {
  const intl = useIntl();
  const location = useLocation<PreselectedEmail>();

  const { config } = React.useContext(ConfigContext);
  const { clear } = useContext(NotificationContext);
  const {
    unlockUser,
    isLoading,
    unlockedUser,
    clearUnlockUserData,
    unlockError,
  } = useContext(UserContext);

  const usePlusSignInEmailAddress =
    config.test.usePlusSignInEmailAddress.enabled;

  const [email, setEmail] = useState(location.state?.email);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [sent, setSent] = useState(false);
  const [credentials, setCredentials] = useState<CredentialsInterface>();

  useEffect(() => {
    clear();
    clearUnlockUserData();

    return () => clear();
  }, []);

  const handleSubmit = (
    email: string,
    dateOfBirth?: string,
    lastName?: string
  ) => {
    setEmailIsInvalid(false);
    setEmail(email);

    if (!validateEmail(email, usePlusSignInEmailAddress)) {
      setEmailIsInvalid(true);
    } else {
      unlockUser(email, dateOfBirth, lastName).then(() => {
        setCredentials({ email, dateOfBirth, lastName });
      });
    }
  };

  useEffect(() => {
    if (!unlockedUser) {
      setSent(false);
      setEmail(location.state?.email);
      clear();
    } else if (
      unlockedUser.success &&
      unlockedUser.email &&
      email === unlockedUser.email
    ) {
      setSent(true);
    }
  }, [unlockedUser]);

  const unlockResponseError = () => {
    if (unlockError)
      return intl.formatMessage(messages.unlockInvalidCombination, {
        support: (
          <LinkHeap
            target="_blank"
            key="privacyPolicy"
            rel="noreferrer"
            to={{ pathname: acknowledgements.links.contactUs }}
            heapEventName={HEAP_EVENTS.upp_click_contactnatera}
            heapEventProps={{
              location: HeapEventLocation.unable_to_verify_unlock_page,
            }}
          >
            {intl.formatMessage(messages.unlockContactSupport)}
          </LinkHeap>
        ),
      });

    if (emailIsInvalid) {
      return intl.formatMessage(messages.unlockInvalidEmail);
    }
  };

  const errorNotification = (
    <Notification type="error" role="alert">
      {unlockResponseError()}
    </Notification>
  );

  const onCloseUnlockError = () => {
    setEmail(location.state?.email);
    clearUnlockUserData();
  };

  return unlockError &&
    unlockError === ResultCodes.UNABLE_TO_VERIFY_IDENTITY ? (
    <UnableVerifyAccount onClose={onCloseUnlockError} />
  ) : (
    <article className="unlock__container">
      <SpinnerView isLoading={isLoading} />
      {sent ? (
        <MailSentView email={email} credentials={credentials} />
      ) : (
        <UnlockView
          onSubmit={handleSubmit}
          errorNotification={
            unlockError || emailIsInvalid ? errorNotification : undefined
          }
          email={email || ""}
        />
      )}
    </article>
  );
};

export default Unlock;
