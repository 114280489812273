import React from "react";
import { Grid, GridCell, GridRow } from "@natera/material/lib/layout";
import {
  Textfield,
  FormField,
} from "@natera/platform/lib/components/form/field";
import { Button, Form } from "@natera/platform/lib/components/form";
import { defineMessages, useIntl } from "react-intl";
import { useErrorController } from "@natera/platform/lib/hooks";

const messages = defineMessages({
  provideInsuranceFormMemberId: {
    id: "provideInsuranceFormMemberId",
    defaultMessage: "Member ID",
  },
  provideInsuranceFormMemberIdPlaceholder: {
    id: "provideInsuranceFormMemberIdPlaceholder",
    defaultMessage: "Enter Member ID",
  },
  provideInsuranceFormReEnterMemberId: {
    id: "provideInsuranceFormReEnterMemberId",
    defaultMessage: "Re-enter Member ID",
  },
  provideInsuranceFormReEnterMemberIdPlaceholder: {
    id: "provideInsuranceFormReEnterMemberIdPlaceholder",
    defaultMessage: "Enter Member ID",
  },
  provideInsuranceFormMandatory: {
    id: "provideInsuranceFormMandatory",
    defaultMessage: "This information is required.",
  },
  provideInsuranceFormMustMatch: {
    id: "provideInsuranceFormMustMatch",
    defaultMessage: "Member IDs must match",
  },
  provideInsuranceFormInvalid: {
    id: "provideInsuranceFormInvalid",
    defaultMessage: "Please enter a valid Member ID",
  },
  provideInsuranceFormButtonNext: {
    id: "provideInsuranceFormButtonNext",
    defaultMessage: "Next",
  },
  provideInsuranceFormButtonBack: {
    id: "provideInsuranceFormButtonBack",
    defaultMessage: "Back",
  },
});

type Props = {
  defaultValue?: string;
  onSubmit: (memberId: string) => void;
  goBack: () => void;
};

const memberId = "memberId";
const reMemberId = "reMemberId";

const ProvideInsuranceForm: React.FC<Props> = ({
  defaultValue,
  onSubmit,
  goBack,
}) => {
  const intl = useIntl();

  const errorController = useErrorController();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    errorController.clearErrors();

    const formData = new FormData(event.currentTarget);

    const memberIdValue = formData.get(memberId)?.toString();
    const reMemberIdValue = formData.get(reMemberId)?.toString();

    if (!memberIdValue) {
      errorController.setValidationError(
        memberId,
        intl.formatMessage(messages.provideInsuranceFormMandatory)
      );

      return;
    }

    if (memberIdValue || reMemberIdValue) {
      const isInvalidMemberId =
        memberIdValue && memberIdValue !== memberIdValue.trim();

      if (isInvalidMemberId) {
        errorController.setValidationError(
          memberId,
          intl.formatMessage(messages.provideInsuranceFormInvalid)
        );
      }

      const isInvalidReMemberId =
        reMemberIdValue && reMemberIdValue !== reMemberIdValue.trim();

      if (isInvalidReMemberId) {
        errorController.setValidationError(
          reMemberId,
          intl.formatMessage(messages.provideInsuranceFormInvalid)
        );
      }

      if (isInvalidMemberId || isInvalidReMemberId) return;
    }

    if (memberIdValue !== reMemberIdValue) {
      errorController.setValidationError(
        memberId,
        intl.formatMessage(messages.provideInsuranceFormMustMatch)
      );

      errorController.setValidationError(
        reMemberId,
        intl.formatMessage(messages.provideInsuranceFormMustMatch)
      );

      return;
    }

    onSubmit(memberIdValue);
  };

  return (
    <Form
      noValidate
      onSubmit={handleSubmit}
      buttons={
        <>
          <Button type="submit" raised>
            {intl.formatMessage(messages.provideInsuranceFormButtonNext)}
          </Button>
          <Button onClick={goBack}>
            {intl.formatMessage(messages.provideInsuranceFormButtonBack)}
          </Button>
        </>
      }
    >
      <Grid>
        <GridRow>
          <GridCell span={12}>
            <FormField
              title={intl.formatMessage(messages.provideInsuranceFormMemberId)}
              label={intl.formatMessage(messages.provideInsuranceFormMemberId)}
              aria-label={intl.formatMessage(
                messages.provideInsuranceFormMemberId
              )}
              error={errorController.getValidationError(memberId)}
              required
              htmlFor={memberId}
            >
              <Textfield
                id={memberId}
                name={memberId}
                type="text"
                placeholder={intl.formatMessage(
                  messages.provideInsuranceFormMemberIdPlaceholder
                )}
                defaultValue={defaultValue}
                outline
                required
              />
            </FormField>
          </GridCell>
          <GridCell span={12}>
            <FormField
              title={intl.formatMessage(
                messages.provideInsuranceFormReEnterMemberId
              )}
              label={intl.formatMessage(
                messages.provideInsuranceFormReEnterMemberId
              )}
              error={errorController.getValidationError(reMemberId)}
              aria-label={intl.formatMessage(
                messages.provideInsuranceFormReEnterMemberId
              )}
              required
              htmlFor={reMemberId}
            >
              <Textfield
                id={reMemberId}
                name={reMemberId}
                placeholder={intl.formatMessage(
                  messages.provideInsuranceFormReEnterMemberIdPlaceholder
                )}
                defaultValue={defaultValue}
                outline
                required
              />
            </FormField>
          </GridCell>
        </GridRow>
      </Grid>
    </Form>
  );
};

export default ProvideInsuranceForm;
