import { HeapVideoContentType, HeapVideoOrderData } from "@app/provider/types";
import { videoPercentageToHeapName } from "@app/utils/videoPercentageToHeapName";

interface TrackedProps {
  video_content?: HeapVideoContentType;
  order_uid?: string;
  lims_clinic_id?: number;
}

export const trackVideoPlaysInHeap = (
  value: number,
  heapVideoContentType: HeapVideoContentType,
  heapVideoOrderData?: HeapVideoOrderData
): void => {
  const eventName = videoPercentageToHeapName(value);

  const trackedProps: TrackedProps = {};

  if (heapVideoContentType) {
    trackedProps.video_content = heapVideoContentType;
  }

  if (heapVideoOrderData) {
    trackedProps.order_uid = heapVideoOrderData.order_uid;
    trackedProps.lims_clinic_id = heapVideoOrderData.lims_clinic_id;
  }

  if (eventName) {
    heap.track(eventName, trackedProps);
  }
};
