import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import R from "ramda";
import { MfaSetupContext, UppAuthContext, UserContext } from "@app/provider";
import Svg from "@natera/material/lib/svg";
import { routes } from "@app/routing";
import SuccessLogo from "@assets/svg/icons/success.svg";
import InfoSvg from "@assets/svg/icons/info.svg";
import {
  InfoCard,
  InfoCardField,
  Notification,
  ProviderType,
  SpinnerView,
} from "@app/components";
import { BlueHeaderContent } from "@app/components/ui/layout";
import { FactorStatus, FACTORS_TO_SHOW } from "@app/service/user";
import { FACTOR_LIST } from "@app/components/factorList/factorList";
import { maskEmail, maskPhone } from "@app/utils";
import "./securitySettings.scss";

const messages = defineMessages({
  securitySettingsTitle: {
    id: "securitySettingsTitle",
    defaultMessage: "Security Settings",
  },
  securitySettingsEditButton: {
    id: "securitySettingsEditButton",
    defaultMessage: "Edit",
  },
  securitySettingsPasswordTitle: {
    id: "securitySettingsPasswordTitle",
    defaultMessage: "Account Password",
  },
  securitySettingsSignUpMethod: {
    id: "securitySettingsSignUpMethod",
    defaultMessage: "Sign Up Method",
  },
  securitySettingsTwoFATitle: {
    id: "securitySettingsTwoFATitle",
    defaultMessage: "Two - Factor Authentication",
  },
  securitySettingsTwoFANotSetUp: {
    id: "securitySettingsTwoFANotSetUp",
    defaultMessage:
      "You have not set up two - factor authentication for this account.",
  },
  securitySettingsTwoFASetUp: {
    id: "securitySettingsTwoFASetUp",
    defaultMessage: "Your verification code will be sent to {credential}",
  },
  securitySettingsCannotEditedTitle: {
    id: "securitySettingsCannotEditedTitle",
    defaultMessage: "Email or password cannot be edited",
  },
  securitySettingsCannotEditedText: {
    id: "securitySettingsCannotEditedText",
    defaultMessage:
      "Your email and password can only be edited if you created your account using your email address.",
  },
  securitySettingsPasswordUpdatedTitle: {
    id: "securitySettingsPasswordUpdatedTitle",
    defaultMessage: "Password Updated",
  },
  securitySettingsPasswordUpdatedFirstDesc: {
    id: "securitySettingsPasswordUpdatedFirstDesc",
    defaultMessage: "Your password has been updated.",
  },
  securitySettingsPasswordUpdatedSecondDesc: {
    id: "securitySettingsPasswordUpdatedSecondDesc",
    defaultMessage: "Remember to save your new password.",
  },
  securitySettingsFactorsDisabledDesc: {
    id: "securitySettingsFactorsDisabledDesc",
    defaultMessage: "You have opted out of Two - Factor Authentication",
  },
  securitySettingsFactorSuccessMessageText: {
    id: "securitySettingsFactorSuccessMessageText",
    defaultMessage: "Two - factor authentication is now active.",
  },
  securitySettingsFactorSuccessMessageTitlePhone: {
    id: "securitySettingsFactorSuccessMessageTitlePhone",
    defaultMessage: "Phone number Verified",
  },
  securitySettingsFactorSuccessMessageTitleEmail: {
    id: "securitySettingsFactorSuccessMessageTitleEmail",
    defaultMessage: "Email address Verified",
  },
});

const SecuritySettings: FC = () => {
  const intl = useIntl();
  const history = useHistory<{
    passwordUpdated?: boolean;
    factorDisabled?: boolean;
    phoneFactorActivated?: boolean;
    emailFactorActivated?: boolean;
  } | null>();
  const { profile } = useContext(UppAuthContext);
  const { canEdit, isLoading } = React.useContext(UserContext);
  const {
    loadEnrolledFactorList,
    enrolledFactorList,
    isLoading: isFactorLoading,
  } = React.useContext(MfaSetupContext);
  const [showIdentityWarningMsg, setShowIdentityWarningMsg] = useState(false);

  useEffect(() => {
    loadEnrolledFactorList();
  }, []);

  const identityProviderWarning = () =>
    !canEdit &&
    showIdentityWarningMsg && (
      <Notification
        titleElem={intl.formatMessage(
          messages.securitySettingsCannotEditedTitle
        )}
        type="note"
      >
        <div className="notification__desc">
          <p>{intl.formatMessage(messages.securitySettingsCannotEditedText)}</p>
        </div>
      </Notification>
    );

  const emptyProvider = (
    <span className="provider-description-empty">
      {intl.formatMessage(messages.securitySettingsTwoFANotSetUp)}
    </span>
  );

  const factorLabel = (labelText: string) => {
    return (
      <div className="provider-label">
        <Svg content={SuccessLogo} />
        <div className="provider-label-text">{labelText}</div>
      </div>
    );
  };

  const renderFactorItem = (
    label: ReactNode | string,
    value: ReactNode | string
  ) => <InfoCardField label={label} value={value} />;

  const renderFactorProvider = () => {
    if (!enrolledFactorList || enrolledFactorList.length === 0) {
      return renderFactorItem("", emptyProvider);
    }
    const activeFactorList = R.filter(
      (factor) => factor.status === FactorStatus.ACTIVE,
      enrolledFactorList
    );
    if (activeFactorList.length > 0) {
      const factor = activeFactorList[0];
      const factorText =
        factor.factorType in FACTOR_LIST && FACTOR_LIST[factor.factorType];
      const credential =
        factor.factorType === FACTORS_TO_SHOW.email
          ? maskEmail(factor.profile?.email)
          : maskPhone(factor.profile?.phoneNumber);
      if (factorText) {
        const value = (
          <span className="provider-description">
            {intl.formatMessage(messages.securitySettingsTwoFASetUp, {
              credential,
            })}
          </span>
        );
        return renderFactorItem(
          factorLabel(intl.formatMessage(factorText.title)),
          value
        );
      }
    }

    return renderFactorItem("", emptyProvider);
  };

  const handleShowIdentityWarningMsg = () => setShowIdentityWarningMsg(true);

  const getNotification = () => {
    if (history.location.state?.passwordUpdated) {
      return (
        <Notification
          titleElem={intl.formatMessage(
            messages.securitySettingsPasswordUpdatedTitle
          )}
          type="success"
        >
          <div className="notification__desc">
            <p>
              {intl.formatMessage(
                messages.securitySettingsPasswordUpdatedFirstDesc
              )}
              <br />
              {intl.formatMessage(
                messages.securitySettingsPasswordUpdatedSecondDesc
              )}
            </p>
          </div>
        </Notification>
      );
    } else if (history.location.state?.factorDisabled) {
      return (
        <Notification
          titleElem={intl.formatMessage(
            messages.securitySettingsFactorsDisabledDesc
          )}
          type="success"
        />
      );
    } else if (history.location.state?.emailFactorActivated) {
      return (
        <Notification
          titleElem={intl.formatMessage(
            messages.securitySettingsFactorSuccessMessageTitleEmail
          )}
          className="security-settings-notification"
          type="success"
        >
          <div className="notification__desc">
            <p>
              {intl.formatMessage(
                messages.securitySettingsFactorSuccessMessageText
              )}
            </p>
          </div>
        </Notification>
      );
    } else if (history.location.state?.phoneFactorActivated) {
      return (
        <Notification
          titleElem={intl.formatMessage(
            messages.securitySettingsFactorSuccessMessageTitlePhone
          )}
          className="security-settings-notification"
          type="success"
        >
          <div className="notification__desc">
            <p>
              {intl.formatMessage(
                messages.securitySettingsFactorSuccessMessageText
              )}
            </p>
          </div>
        </Notification>
      );
    }

    return undefined;
  };

  const signUpMethod = () => (
    <div className="security-settings__method">
      {intl.formatMessage(messages.securitySettingsSignUpMethod)}
      {!canEdit && (
        <Svg content={InfoSvg} onClick={handleShowIdentityWarningMsg} />
      )}
    </div>
  );

  return (
    <BlueHeaderContent
      blueHeaderTitle={intl.formatMessage(messages.securitySettingsTitle)}
      backArrowHeaderRoute={routes.profile}
    >
      <InfoCard
        titleText={intl.formatMessage(messages.securitySettingsPasswordTitle)}
        titleEditButtonLinkRoute={canEdit ? routes.changePassword : undefined}
        notification={getNotification()}
      >
        <InfoCardField
          label={signUpMethod()}
          value={
            <>
              {profile && (
                <ProviderType idpType={profile?.idpType} showEmailIcon />
              )}
              {identityProviderWarning()}
            </>
          }
        />
      </InfoCard>

      <InfoCard
        titleText={intl.formatMessage(messages.securitySettingsTwoFATitle)}
        titleEditButtonLinkRoute={routes.editMfa}
      >
        {renderFactorProvider()}
      </InfoCard>
      <SpinnerView isLoading={isLoading || isFactorLoading} />
    </BlueHeaderContent>
  );
};

export default SecuritySettings;
