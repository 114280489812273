import { DocumentNode, gql } from "@apollo/client";

class PayorService {
  public static getPayors = (): DocumentNode => gql`
    query GetPayors($searchValue: String!, $maxLength: Float) {
      payors(searchValue: $searchValue, maxLength: $maxLength) {
        displayName
        groupId
      }
    }
  `;
}

export default PayorService;
