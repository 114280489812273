export enum ActionTypes {
  CLOSE_WIDGET_WITH_CONFIRMATION = "CLOSE_WIDGET_WITH_CONFIRMATION",
  DD_TIMING_EVENT_STOP = "DD_TIMING_EVENT_STOP",
  UPDATE_USER_INPUT = "UPDATE_USER_INPUT",
  WIDGET_LANGUAGE_SWITCHED = "WIDGET_LANGUAGE_SWITCHED",
  UPDATE_PERSISTENT_MENU = "UPDATE_PERSISTENT_MENU",
  CLOSE_WIDGET_WITHOUT_CONFIRMATION = "CLOSE_WIDGET_WITHOUT_CONFIRMATION",
  END_CONVERSATION = "END_CONVERSATION",
  UNDO_END_CONVERSATION = "UNDO_END_CONVERSATION",
  SWITCH_LANGUAGE = "SWITCH_LANGUAGE",
  BOT_LOADER_LANGUAGE_SWITCHED = "BOT_LOADER_LANGUAGE_SWITCHED",
  USER_ACTION_EVENT = "USER_ACTION_EVENT",
  OPEN_WIDGET = "OPEN_WIDGET",
  CLOSE_WIDGET = "CLOSE_WIDGET",
  KEEP_CHATTING = "KEEP_CHATTING",
  NATERA_START_CONVERSATION = "NATERA_START_CONVERSATION",
  CONTINUE_LANGUAGE_SWITCHING = "CONTINUE_LANGUAGE_SWITCHING",
  CANCEL_LANGUAGE_SWITCHING = "CANCEL_LANGUAGE_SWITCHING",
}

export enum MessageTypes {
  INTRO = "intro",
  TEXT = "text",
  ATTACHMENT = "attachment",
  GENERIC_TEMPLATE = "generic_template",
  TYPING = "TYPING",
}

export enum ButtonTypes {
  POSTBACK = "postback",
  WEB_URL = "web_url",
  TRANSCRIPT = "transcript",
  DROPDOWN = "dropdown",
  RATING = "rating",
}

export enum AttributeNames {
  BUTTON_STYLE_TYPE = "button_style_type",
  QUICK_REPLY_TYPE = "quick_reply_type",
  UPDATE_MESSAGES = "updateMessages",
}

export enum AttributeValues {
  TRUE = "true",
  FALSE = "false",
  PRIMARY = "PRIMARY",
  SECONDARY = "secondary",
}

export enum KeyTypes {
  ENTER = "enter",
  ESCAPE = "escape",
  TAB = "tab",
  SPACE = " ",
}

export enum NEVA_HEAP_EVENTS {
  BOT_LOADED_FIRST = "bot_loaded_first",
  BOT_LOADED_NTH = "bot_loaded_nth",
  MENU_OPEN = "menu_open",
  EF_REVIEW = "ef_review",
  EF_REVIEW_CANCEL = "ef_review_cancel",
  EF_REVIEW_PROCEED = "ef_review_proceed",
}

export enum PostMessageEventTypes {
  QUESTIONNAIRE_CHAT_ID = "questionnaire-chatId",
  OPPORTUNITY_TO_RETURN = "opportunityToReturn",
  QUESTIONNAIRE_CONTENT_LOADED = "questionnaire-content-loaded",
  QUESTIONNAIRE_CLOSE_IFRAME_SUCCESSFULLY = "questionnaire-close_iframe_successfully",
  QUESTIONNAIRE_IFRAME_STATE = "questionnaire-iframe-state",
  QUESTIONNAIRE_ACTIVITY_EVENT = "questionnaire-activity-event",
}

export const iframeDefaultConfig = {
  isOpenIframe: false,
  isLoading: true,
  isShowIframe: false,
  url: "",
  isQuestionnaireIframeOpened: false,
  opportunityToManualClose: true,
  widgetIframeSource: null,
};
