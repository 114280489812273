import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/platform/lib/components/form";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import "./clinicRequestDialog.scss";

const messages = defineMessages({
  clinicRequestDialogTitle: {
    id: "clinicRequestDialogTitle",
    defaultMessage: "Contact your clinic",
  },
  clinicRequestDialogDescription1: {
    id: "clinicRequestDialogDescription1",
    defaultMessage:
      "Reach out to your clinic directly to see if they offer on-site blood draw services.",
  },
  clinicRequestDialogDescription2: {
    id: "clinicRequestDialogDescription2",
    defaultMessage:
      "If they do not offer blood draw services, come back to the patient portal to find a lab nearby or request mobile phlebotomy service.",
  },
  clinicRequestDialogClinicRequestDone: {
    id: "clinicRequestDialogClinicRequestDone",
    defaultMessage: "Done",
  },
  clinicRequestDialogClinicRequestBack: {
    id: "clinicRequestDialogClinicRequestBack",
    defaultMessage: "Back",
  },
});

interface ClinicRequestDialogProps {
  goBack: () => void;
  isGuest: boolean;
}

const ClinicRequestDialog: FC<ClinicRequestDialogProps> = ({
  goBack,
  isGuest,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const handleDone = () => {
    isGuest ? goBack() : history.push(routes.home);
  };

  return (
    <div className="clinic-request-dialog">
      <Dialog
        showCrossButton
        title={intl.formatMessage(messages.clinicRequestDialogTitle)}
        actions={
          <>
            <Button raised onClick={handleDone}>
              {intl.formatMessage(
                messages.clinicRequestDialogClinicRequestDone
              )}
            </Button>
            {!isGuest && (
              <Button outlined onClick={goBack}>
                {intl.formatMessage(
                  messages.clinicRequestDialogClinicRequestBack
                )}
              </Button>
            )}
          </>
        }
        onClose={goBack}
      >
        <ul className="clinic-request-dialog__content">
          <li>
            {intl.formatMessage(messages.clinicRequestDialogDescription1)}
          </li>
          <li>
            {intl.formatMessage(messages.clinicRequestDialogDescription2)}
          </li>
        </ul>
      </Dialog>
    </div>
  );
};

export default ClinicRequestDialog;
