import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Svg from "@natera/material/lib/svg";
import { LinkHeap } from "@app/components";
import { routes, profileRoutes } from "@app/routing";
import classNames from "classnames";
import { HeapEventProps } from "@app/components/linkHeap/linkHeap";
import "./navItem.scss";

interface NavItemProps extends React.HTMLAttributes<HTMLLIElement> {
  to: string | { pathname: string };
  icon?: string;
  target?: string;
  rel?: string;
  activePrefix?: string;
  isMobileMenu?: boolean;
  heapEventName?: string;
  heapEventProps?: HeapEventProps;
}

const NavItem: FC<NavItemProps> = ({
  className,
  children,
  activePrefix,
  to,
  icon,
  target,
  rel,
  isMobileMenu,
  heapEventName,
  heapEventProps,
  ...props
}) => {
  const location = useLocation();
  const [profileLinkIsClickable, setProfileLinkIsClickable] = useState<boolean>(
    true
  );

  const isProfilePages = () => {
    return (
      to === routes.profile &&
      Object.values(profileRoutes).includes(location.pathname)
    );
  };

  useEffect(() => {
    const linkIsClickable = isProfilePages() && isActive() && !isMobileMenu;
    setProfileLinkIsClickable(!linkIsClickable);
  }, [location.pathname, isMobileMenu]);

  const isActive = useCallback(
    () =>
      location.pathname.startsWith(activePrefix || String(to)) ||
      isProfilePages(),
    [location]
  );

  return (
    <li
      className={classNames("nav-item", className, {
        "nav-item--active": isActive(),
      })}
      {...props}
    >
      <LinkHeap
        target={target}
        rel={rel}
        to={profileLinkIsClickable ? to : location.pathname}
        heapEventName={heapEventName}
        heapEventProps={heapEventProps}
      >
        <>
          <span className="nav-item__icon">
            {icon && <Svg content={icon} />}
          </span>

          <span className="nav-item__text">{children}</span>
        </>
      </LinkHeap>
    </li>
  );
};

export default NavItem;
