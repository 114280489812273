import { IntlShape, MessageDescriptor } from "react-intl";
import { messages } from "../messages";
import { SecondaryActionCardType, SecondaryCard } from "../types";

const typeToText: { [key in SecondaryActionCardType]: MessageDescriptor } = {
  [SecondaryActionCardType.SALIVA]: messages.salivaSecondaryActionCardDesc,
  [SecondaryActionCardType.COMPASSIONATE_CARE]:
    messages.testCardSecondaryDescCompassionateCare,
  [SecondaryActionCardType.PROSPERA_HEART]:
    messages.prosperaDNASecondaryActionCardDesc,
  [SecondaryActionCardType.PROSPERA_KIDNEY]:
    messages.prosperaDNASecondaryActionCardDesc,
  [SecondaryActionCardType.PROSPERA_SPK]:
    messages.prosperaDNASecondaryActionCardDesc,
  [SecondaryActionCardType.PROSPERA_LIVER]:
    messages.prosperaDNASecondaryActionCardDesc,
  [SecondaryActionCardType.PROSPERA_LUNG]:
    messages.prosperaDNASecondaryActionCardDesc,
  [SecondaryActionCardType.PROSPERA_OTHER]:
    messages.prosperaDNASecondaryActionCardDesc,
  [SecondaryActionCardType.RENASIGHT_KIDNEY]:
    messages.renasightKidneySecondaryActionCardDesc,
  [SecondaryActionCardType.EMPOWER]: messages.empowerInfoCardDesc,
  [SecondaryActionCardType.HORIZON]: messages.horizonInfoCardDesc,
  [SecondaryActionCardType.PANORAMA]: messages.panoramaInfoCardDesc,
  [SecondaryActionCardType.VISTARA]: messages.vistaraInfoCardDesc,
  [SecondaryActionCardType.GENETIC_TESTING]:
    messages.geneticTestingInfoCardDesc,
  [SecondaryActionCardType.WOMENS_HEALTH_PRICING_BILLING]:
    messages.pricingBillingInfoCardDesc,
  [SecondaryActionCardType.ONCOLOGY_PRICING_BILLING]:
    messages.pricingBillingInfoCardDesc,
  [SecondaryActionCardType.PANORAMA_HORIZON]: messages.panoramaHorizonDesc,
  [SecondaryActionCardType.ALTERA]: messages.alteraInfoCardDesc,
  [SecondaryActionCardType.SIGNATERA]: messages.signateraInfoCardDesc,
};

export const getSecondaryCardDescription = (
  intl: IntlShape,
  secondaryCard: SecondaryCard
): string => {
  return intl.formatMessage(typeToText[secondaryCard.type]);
};
