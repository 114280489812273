import React from "react";
import { defineMessages, useIntl } from "react-intl";
import acknowledgements from "@etc/acknowledgements.json";
import "./welcomeScheduleBloodDraw.scss";

const messages = defineMessages({
  scheduleBloodDrawTitle: {
    id: "scheduleBloodDrawTitle",
    defaultMessage: "Schedule a Blood Draw",
  },
  scheduleBloodDrawCookies: {
    id: "scheduleBloodDrawCookies",
    defaultMessage:
      "This site uses cookies to enhance your experience. Any cookies preferences selected on Natera.com will not apply.",
  },
  scheduleBloodDrawAcknowledgements: {
    id: "scheduleBloodDrawAcknowledgements",
    defaultMessage:
      "By continuing you accept our{br}{termsOfUse} and {privacyPolicy}",
  },
  scheduleBloodDrawTermsOfUse: {
    id: "scheduleBloodDrawTermsOfUse",
    defaultMessage: "Terms of Use",
  },
  scheduleBloodDrawPrivacyPolicy: {
    id: "scheduleBloodDrawPrivacyPolicy",
    defaultMessage: "Privacy Policy",
  },
});

export const WelcomeWrapper: React.FC = ({ children }) => {
  const intl = useIntl();

  return (
    <div className="schedule-blood-draw">
      <div className="schedule-blood-draw__blue-header"></div>
      <div className="schedule-blood-draw__content">
        <div className="schedule-blood-draw__content-inner">
          <div className="schedule-blood-draw__content-title">
            {intl.formatMessage(messages.scheduleBloodDrawTitle)}
          </div>
          {children}
          <div className="schedule-blood-draw__footer">
            <p className="schedule-blood-draw__footer-cookies">
              {intl.formatMessage(messages.scheduleBloodDrawCookies)}
            </p>
            <p className="schedule-blood-draw__footer-terms">
              {intl.formatMessage(messages.scheduleBloodDrawAcknowledgements, {
                termsOfUse: (
                  <a
                    href={acknowledgements.links.termsOfUse}
                    key="termsOfUse"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage(messages.scheduleBloodDrawTermsOfUse)}
                  </a>
                ),
                privacyPolicy: (
                  <a
                    href={acknowledgements.links.privacyPolicy}
                    key="privacyPolicy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage(
                      messages.scheduleBloodDrawPrivacyPolicy
                    )}
                  </a>
                ),
                br: <br />,
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
