import {
  PaymentInfo,
  PaymentType,
  Government,
  Commercial,
} from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { InsuranceTypeDto, PaymentInfoDto } from "@app/provider/simpleOrder";

export const getPaymentInfoDto = (paymentInfo: PaymentInfo) => {
  const isMultiple = paymentInfo.paymentMethod === PaymentType.MULTIPLE;
  const insuranceType = isMultiple
    ? InsuranceTypeDto.MULTIPLE
    : InsuranceTypeDto.SINGLE;

  const paymentInfoDto: PaymentInfoDto = {
    insuranceType,
    first: getFirstSecondPayment(paymentInfo, true),
    second: isMultiple ? getFirstSecondPayment(paymentInfo, false) : undefined,
  };

  return paymentInfoDto;
};

const getFirstSecondPayment = (paymentInfo: PaymentInfo, isFirst: boolean) => {
  let firstPaymentMethod;
  switch (paymentInfo.paymentMethod) {
    case PaymentType.MULTIPLE:
      firstPaymentMethod = isFirst
        ? paymentInfo.paymentMethodInfo.first.paymentMethod
        : paymentInfo.paymentMethodInfo.second.paymentMethod;
      break;
    default:
      firstPaymentMethod = paymentInfo.paymentMethod;
  }

  let firstPaymentMethodInfo;
  switch (paymentInfo.paymentMethod) {
    case PaymentType.COMMERCIAL:
    case PaymentType.GOVERNMENT_ISSUED:
      firstPaymentMethodInfo = getPaymentMethodInfoDto(paymentInfo);
      break;
    case PaymentType.MULTIPLE:
      firstPaymentMethodInfo = getPaymentMethodInfoDto(
        isFirst
          ? paymentInfo.paymentMethodInfo.first
          : paymentInfo.paymentMethodInfo.second
      );
      break;
    default:
      firstPaymentMethodInfo = undefined;
  }

  return {
    paymentMethod: firstPaymentMethod,
    paymentMethodInfo: firstPaymentMethodInfo,
  };
};

const getPaymentMethodInfoDto = (info: Government | Commercial) => {
  const isCommercial = info.paymentMethod === PaymentType.COMMERCIAL;
  return {
    companyName: info.paymentMethodInfo.companyName,
    insuranceId: isCommercial
      ? info.paymentMethodInfo.memberId
      : info.paymentMethodInfo.insuranceNumber,
    companyGroupId: info.paymentMethodInfo.companyGroupId,
  };
};
