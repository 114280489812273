import React, { FC, useContext, useEffect } from "react";
import { StepperContext } from "@natera/stepper";
import { Order } from "@app/provider/order";
import { Steps } from "../simpleOrderStepper";

import {
  PatientAcknowledgmentStepResolve,
  SimpleOrderStepperContext,
  SimpleOrderSubSteps,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import PatientSignature from "./patientSignature";
import PatientAcknowledgment from "./patientAcknowledgment";

const PatientAcknowledgmentController: FC<{
  orderData: Order;
}> = ({ orderData }) => {
  const { getSubStep, setSubStep, resolveSubStep } = useContext(
    SimpleOrderStepperContext
  );
  const { goBack, resolve } = React.useContext(StepperContext);

  const subStep = getSubStep();

  useEffect(() => {
    if (
      subStep !== SimpleOrderSubSteps.ACKNOWLEDGMENT &&
      subStep !== SimpleOrderSubSteps.SIGNATURE
    ) {
      setSubStep(SimpleOrderSubSteps.ACKNOWLEDGMENT);
    }
  }, []);

  const goBack$ = () => {
    switch (subStep) {
      case SimpleOrderSubSteps.ACKNOWLEDGMENT:
        return goBack();
      case SimpleOrderSubSteps.SIGNATURE:
        return setSubStep(SimpleOrderSubSteps.ACKNOWLEDGMENT);
      default:
        return goBack();
    }
  };

  const resolve$ = (value: PatientAcknowledgmentStepResolve) => {
    switch (subStep) {
      case SimpleOrderSubSteps.ACKNOWLEDGMENT:
        setSubStep(SimpleOrderSubSteps.SIGNATURE);
        resolveSubStep({ stepId: Steps.ACKNOWLEDGMENT, subStepData: value });
        return;
      default:
        return resolve(value);
    }
  };

  const renderSubStep = () => {
    switch (subStep) {
      case SimpleOrderSubSteps.SIGNATURE:
        return <PatientSignature goBack={goBack$} orderData={orderData} />;
      default:
        return (
          <PatientAcknowledgment
            goBack={goBack$}
            resolve={resolve$}
            orderData={orderData}
          />
        );
    }
  };

  return <>{renderSubStep()}</>;
};

export default PatientAcknowledgmentController;
