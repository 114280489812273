import { HeapVideoContentType } from "@app/provider/types";

export const SUPPLEMENTAL_VIDEO_NAMES = {
  horizonFragileX: "https://fast.wistia.net/embed/iframe/zg5gxyu2n7",
  horizonSMA: "https://fast.wistia.net/embed/iframe/qn33gi4k6r",
  horizonCysticFibrosis: "https://fast.wistia.net/embed/iframe/lts836miib",
  horizonTaySachs: "https://fast.wistia.net/embed/iframe/uq2r9ic6yj",
  horizonDBMuscularDystrophy: "https://fast.wistia.net/embed/iframe/9yge4xegfv",
};

export const getHeapVideoContentType = (
  videoName: string | undefined,
  isHorizonOrPanorama: boolean
): HeapVideoContentType | undefined => {
  if (isHorizonOrPanorama)
    switch (videoName) {
      case SUPPLEMENTAL_VIDEO_NAMES.horizonFragileX:
        return HeapVideoContentType.horizonFragileX;
      case SUPPLEMENTAL_VIDEO_NAMES.horizonSMA:
        return HeapVideoContentType.horizonSMA;
      case SUPPLEMENTAL_VIDEO_NAMES.horizonCysticFibrosis:
        return HeapVideoContentType.horizonCysticFibrosis;
      case SUPPLEMENTAL_VIDEO_NAMES.horizonTaySachs:
        return HeapVideoContentType.horizonTaySachs;
      case SUPPLEMENTAL_VIDEO_NAMES.horizonDBMuscularDystrophy:
        return HeapVideoContentType.horizonDBMuscularDystrophy;
      default:
        return undefined;
    }
};
